import React, { Component } from 'react';
import { getLangSource } from '../../../lang';
import { get, round } from 'lodash'
import { getId } from '../../../utils/url';

import CompareTable from '../components/Analytics/CompareTable';

import main from '../../../hoc/main';
import SpinnerBody from '../../../components/loaders/SpinnerBody';
import DropDown from '../../../components/forms/Dropdown';

import {
    modelLabelMap,
} from '../utils/format'; 
import moment from 'moment';

class Compare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            versions: [],
        };
        this.getModels = this.getModels.bind(this);
        this.getModelVersions = this.getModelVersions.bind(this);
        this.getModelVersionsAnalytics = this.getModelVersionsAnalytics.bind(this);
    }

    componentWillMount() {
        const { history } = this.props;
        let id = getId(history);
        this.getModels();
    }

    async getModels() {
        const models = await this.props.pricing_models_get_all({
            latest_version: true,
        }).then((data) => {
            return get(data, 'payload.data', []);
        })
        this.setState({
            models,
        });
    }

    async getModelVersions({ model_reference }) {
        const versions = await this.props.pricing_models_get_all({
            model_reference,
        }).then((data) => {
            return get(data, 'payload.data');
        })
        this.setState({
            model_reference,
            versions,
        });
    }

    async getModelVersionsAnalytics({
        version_reference,
        model_reference,
    }) {
        
        let { versions } = this.state;
        let start_at;
        let end_at;
        
        versions = versions.filter(v => v.status === 'published').map((v, i) => {
            if(v.version_reference === version_reference) {
                start_at = v.published_at;
                end_at = versions[i - 1] ? (versions[i - 1] || {}).published_at : moment().toISOString();
                return {
                    ...v,
                    is_fetching: true,
                }
            }
            return v;
        });

        this.setState({ versions });

        const compare = await this.props.pricing_compare_post({
            data: {
                model_reference,
                start_at: round(moment(start_at).format('X'), 0),
                end_at: round(moment(end_at).format('X'), 0),
            }
        }).then((data) => {
            return get(data, 'payload.data');
        });

        // Update versions
        versions = versions.map(v => {
            if (v.version_reference === version_reference) {
                return {
                    ...v,
                    analytics:compare.analytics,
                    is_fetching: false,
                }
            }
            return v;
        });

        this.setState({
            versions,
        });
    }


    render() {
        const {
            model_reference,
            models = [],
            versions = [],
        } = this.state;

        const { isFetching } = this.props.pricing_models;

        const analytics_models = modelLabelMap(models);

        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    {isFetching && 
                        <div className="row">
                            <SpinnerBody 
                                message={lang.t('rating.home.isBuilding')}
                                selectedProduct={this.props.selectedProduct}
                            />
                        </div>
                    }

                    {!isFetching &&
                        <div>

                            <div class="row mb-3">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            {lang.t('rating.compare.title')}
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-lg-12">
                                                <div class="form-label">{lang.t('rating.compare.select')}</div>
                                                <DropDown
                                                    ref='model_reference' 
                                                    label='model_reference'
                                                    value={model_reference}
                                                    update={(data) => {
                                                        this.getModelVersions(data)
                                                    }}
                                                    labelMap={analytics_models}
                                                    items={['', ...models.map(m => m.model_reference)]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {versions.length > 0 &&
                                <div class="row mb-3">
                                    <div class="col-sm-12 col-lg-12">
                                        <CompareTable 
                                            data={versions}
                                            compare={this.getModelVersionsAnalytics}
                                            lang={lang}
                                        />
                                    </div>
                                </div>
                            }

                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default main(Compare);