import React, { Component } from 'react';
import L, { divIcon } from 'leaflet';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { ArrowUpCircle, Droplet, Crosshair } from 'react-feather';
import config from '../../../../config';
import { last, first, flatten } from 'lodash';
//import packets_data from '../../data/packets.json';

import JourneyChart from './JourneyChart';

import Slider from 'rc-slider';
import moment from 'moment';

class PacketsMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vehicle_index: this.props.vehicle_index,
            selected_packet: this.props.packets[this.props.vehicle_index || 0],
        }
    }

    getPlaceIcon (colour = '#206bc4') {
        const iconMarkup = renderToStaticMarkup(
            <div className='custom-marker-container'>
                <Droplet 
                    size={24}
                    style={{
                        color: colour, 
                        'transform': 'rotate(180deg)',
                    }}
                />
            </div>
        )

        return divIcon({
            html: iconMarkup
        })
    }

    getVehicleIcon (angle = 0) {
        const iconMarkup = renderToStaticMarkup(
            <div className='custom-marker-container'>
                <Crosshair 
                    size={20}
                    style={{
                        color: `#000000`, 
                        marginTop: 10,
                    }}
                />
            </div>
        )

        return divIcon({
            html: iconMarkup
        })
    }

    render () {
        const {
            latitude,
            longitude,
            packets = [],
            height = 250,
            lang,
        } = this.props;

        const accelerometers = packets.map(p => {
            if (p.raw && p.raw.accelerometers) {
                const selected = last(p.raw.accelerometers) || {};
                return selected.accel;
                /*
                return p.raw.accelerometers.map(a => {
                    return a.accel;
                });
                */
            }
            return null;
        }).filter(n => n);

        const angles = [];

        const speeds = packets.map(p => {
            return p.speed;
        });

        const coords = packets
            .map(c => {
                return [
                    c.latitude,
                    c.longitude,
                ]
            });

        const { vehicle_index = 0, selected_packet = {} } = this.state;

        let start_location;
        let end_location;
        if (coords) {
            start_location = first(coords);
            end_location = last(coords);
        } else {
            start_location = [latitude, longitude];
            end_location = [latitude, longitude];
        }

        let vehicle_location;
        let vehicle_angle;

        if (vehicle_index !== null) {
            vehicle_location = coords[vehicle_index];
            vehicle_angle = angles ? angles[vehicle_index] : 0;
        }

        const startEnd = L.polyline([start_location, end_location]);
        const mapBounds = startEnd.getBounds();
        const mapCenter = mapBounds.getCenter();

        return (
            <div style={{ position: 'relative'}}>
                <div className='map-placeholder' style={{ height }}>
                    <MapContainer className='leaflet-map' center={[selected_packet.latitude, selected_packet.longitude]} bounds={mapBounds} attributionControl={false}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                            url={`https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=${config.map_tiler_key}`} 
                        />

                        {start_location && <Marker className='svg' position={start_location} icon={this.getPlaceIcon()}></Marker>}
                        {start_location && <Marker className='svg' position={end_location} icon={this.getPlaceIcon('red')}></Marker>}

                        {coords &&
                            <Polyline 
                                positions={coords} 
                                pathOptions={{ color: `#89a7cc` }} 
                            />
                        }
                        
                        {coords && vehicle_index !== null && <Marker position={vehicle_location} icon={this.getVehicleIcon(vehicle_angle)}></Marker>}
                    </MapContainer>
                </div>
                
                <div style={{ position: 'absolute', top: 10, right: 10, width: 240, zIndex: 10000 }} className="card">
                    <div className="card-sm">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="mb-2">
                                        {lang.t('policy.telematics.latitude')}: {selected_packet.latitude}<br/>
                                        {lang.t('policy.telematics.longitude')}: {selected_packet.longitude}<br/>
                                        {lang.t('policy.telematics.speed')}: {selected_packet.speed}<br/>
                                        {lang.t('policy.telematics.time')}: {selected_packet.timestamp_at}<br/>
                                        {lang.t('policy.telematics.index')}: {vehicle_index}
                                    </div>
                                    <button
                                        onClick={() => {
                                            this.props.fetch_packet({
                                                vehicle_index,
                                                packet_reference: selected_packet.packet_reference,
                                            })
                                        }}
                                        className="btn btn-sm btn-primary"
                                    >
                                        {lang.t('buttons.getDetail')} 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-3 mt-3">
                    <Slider 
                        min={0}
                        max={coords.length}
                        value={vehicle_index}
                        trackStyle={{
                            background: '#89a7cc'
                        }}
                        handleStyle={{
                            background: '#206bc4',
                            borderColor: 'white'
                        }}
                        step={1}
                        onChange={(val) => {
                            const vehicle_index = (val === 0 ? 0 : (val - 1));
                            this.setState({
                                vehicle_index,
                                selected_packet: this.props.packets[vehicle_index],
                            });
                        }}
                    />
                </div>

                <div className="mb-3 mt-3">
                    <JourneyChart 
                        series={[
                            {
                                name: 'Kph',
                                data: speeds,
                            },
                        ]}
                        label={'Speed'}
                        unit={''}
                        value={JSON.stringify(speeds[vehicle_index])}
                    />
                </div>

                <div className="mb-3 mt-3">
                    <JourneyChart 
                        series={[
                            {
                                name: 'X Axis',
                                data: accelerometers.map(a => a[0] || 0),
                            },
                            {
                                name: 'Y Axis',
                                data: accelerometers.map(a => a[1] || 0),
                            },
                            {
                                name: 'Z Axis',
                                data: accelerometers.map(a => a[2] || 0),
                            },
                        ]}
                        label={'Accelerometer'}
                        unit={''}
                        value={JSON.stringify(accelerometers[vehicle_index])}
                    />
                </div>
            </div>   
        )
    }
}

export default PacketsMap;