import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { parse_date } from '../Detail/Utils';
import { getOverrideDistance } from '../../../../utils/getOverrideDistance';
import SparkRoute from './SparkRoute';
import { getNumberFormat, getUsageFormat } from '../../../../utils/numberFormat';
import Pagination from '../../../../components/tables/Pagination';
import PaginationNoIndex from '../../../../components/tables/PaginationNoIndex';

function toUpper(str = '') {
    return str?.toUpperCase();
}

function getDistance(journey, currency) {
    const {
        distance_units,
        override_distance_in_metres,
        total_miles,
        total_kms,
    } = journey;
    
    const override_distance = getOverrideDistance(distance_units, override_distance_in_metres);
    const original_distance = distance_units === 'miles' ? total_miles : total_kms;
    const distance = override_distance_in_metres ? override_distance : original_distance;
    const unit = distance_units === 'miles' ? 'miles' : 'km';

    return `${getNumberFormat(currency, distance, '0,0[.]0')} ${unit}`;
}

class PolicyJourneysTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            journeys: data = [],
            page,
            pages,
            no_of_pages,
            update,
            policy_reference,
            policys_journeys_get_all,
            currency,
            timezone,
            lang
        } = this.props;
        const pageData = pages ? data.slice((page * pages) - pages, (page * pages)) : data;

        return (
            <div className="card">
                { pages ? <Pagination 
                    page={page}
                    pages={pages}
                    no_of_pages={no_of_pages}
                    data={data}
                    selectPage={update}
                    lang={lang}
                /> : <PaginationNoIndex
                    data={data}
                    page={page}
                    update={update}
                    policy_reference={policy_reference}
                    fetchData={policys_journeys_get_all}
                    lang={lang}
                />}
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.route')}</th>
                                <th>{lang.t('tableHeaders.journey')}</th>
                                <th>{lang.t('tableHeaders.policyReference')}</th>
                                <th>{lang.t('tableHeaders.rate')}</th>
                                <th>{lang.t('tableHeaders.distance')}</th>
                                <th>{lang.t('tableHeaders.premium')}</th>
                                <th colSpan={2} />
                            </tr>
                        </thead>
                        <tbody>
                            {pageData.filter((d, i) => i !== 50).map((d, i) => (
                                <tr key={`journeys-${i}`}>
                                    <td class="text-center">
                                        {d.route && <SparkRoute journey_ref={d.journey_reference} route={d.route} />}
                                    </td>
                                    <td>
                                        <p class="strong mb-1">{d.start_name} ({d.start_postcode}) to {d.end_name} ({d.end_postcode})</p>
                                        <div class="text-muted">{parse_date(d.start_at, 'llll', timezone)} - {parse_date(d.end_at, 'llll', timezone)}</div>
                                    </td>
                                    <td data-label="Ref">
                                        <div className="text-h5">{toUpper(d.policy_reference)}</div>
                                        <div className="text-muted">{d.journey_reference}</div>
                                    </td>
                                    <td data-label="Usage">
                                        <div className="text-muted text-h5">
                                            {getNumberFormat(currency, d.usage_rate, getUsageFormat(d.usage_rate))}
                                        </div>
                                    </td>
                                    <td data-label="Distance">
                                        <div className="text-muted text-h5">
                                            {getDistance(d, currency)}
                                        </div>
                                        {d.override_distance_in_metres &&
                                            <div className="d-flex align-items-center">
                                                <div 
                                                    className="text-muted text-h5 text-decoration-line-through text-nowrap mr-1"
                                                    data-toggle="tooltip"
                                                    title={lang.t('policy.journeys.originalDistance')}
                                                    data-placement="bottom"
                                                >
                                                    {d.distance_units === 'miles' ?
                                                        `(${getNumberFormat(currency, d.total_miles, '0,0[.]0')} miles)` :
                                                        `(${getNumberFormat(currency, d.total_kms, '0,0[.]0')} km)`
                                                    }
                                                </div>
                                            </div>                                            
                                        }
                                    </td>
                                    <td data-label="Premium">
                                        <div className="text-muted text-h5">
                                            {d.override_usage_premium ?
                                                getNumberFormat(currency, d.override_usage_premium, '$0,0.00') :
                                                getNumberFormat(currency, d.usage_premium, '$0,0.00')
                                            }
                                        </div>
                                        {d.override_usage_premium &&
                                            <div className="d-flex align-items-center">
                                                <div 
                                                    className="text-muted text-h5 text-decoration-line-through text-nowrap mr-1"
                                                    data-toggle="tooltip"
                                                    title={lang.t('policy.journeys.originalPremium')}
                                                    data-placement="bottom"
                                                >
                                                    {`(${getNumberFormat(currency, d.usage_premium, '$0,0.00')})`}
                                                </div>
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        <div className="btn-list flex-nowrap">
                                            <Link
                                                to={`/journeys/detail/${d.journey_reference}`}
                                                className="btn btn-sm btn-primary"
                                            >{lang.t('policy.table.view')}</Link>
                                        </div>
                                    </td>
                                    <td>
                                        {d.is_void ? <div className="badge">{lang.t('policy.journeys.void')}</div> : ''}
                                    </td>
                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                { pages ? <Pagination 
                    page={page}
                    pages={pages}
                    no_of_pages={no_of_pages}
                    data={data}
                    selectPage={update}
                    lang={lang}
                /> : <PaginationNoIndex
                    data={data}
                    page={page}
                    update={update}
                    policy_reference={policy_reference}
                    fetchData={policys_journeys_get_all}
                    lang={lang}
                />}
            </div>
        );
    }
}

export default PolicyJourneysTable;
