import React, { Component } from 'react';
import SuperTable from '../../../../../components/tables/Super';

class ExclusionsTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], lang } = this.props;

        const headers = [
            {
                label: lang.t('tableHeaders.name'),
                name: 'name',
                search: true,
            },
            {
                label: lang.t('tableHeaders.expression'),
                name: 'expression',
                search: true,
            },
            {
                label: '',
                name: 'edit',
            },
            {
                label: '',
                name: 'delete',
            },
        ];

        const Cells = (index, d) => {
            return (
                <tr key={`exclusions-${index}`}>
                    <td data-label={lang.t('tableHeaders.name')}>  
                        <div className="text-h5">{d.name}</div>
                    </td>
                    <td data-label={lang.t('tableHeaders.version')}>
                        <div className="text-muted text-h5">{d.expression.substr(0, 70)}{d.expression.length> 70 ? '...' : ''}</div>
                    </td>
                    <td className="w-1">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.props.edit(d.name)
                        }} className="btn btn-primary btn-sm" data-toggle="modal" data-target="#modal-exclusion">
                            {lang.t('buttons.edit')}
                        </a>
                    </td>
                    <td className="w-1">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.props.delete(d.name)
                        }} className="btn btn-red btn-sm">
                            {lang.t('buttons.delete')}
                        </a>
                    </td>
                </tr>
            );
        }

        return (
            <SuperTable 
                data={data}
                headers={headers}
                Cells={Cells}
                auto_sort={true}
                lang={lang}
            />
        );
    }
}

export default ExclusionsTable;
