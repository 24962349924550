import React, { Component } from 'react';
import Input from '../../components/forms/Input';

class Form_Forgot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            new_password: '',
            code: '',
            showPassword: false,
        };
        this.update = this.update.bind(this);
    }

    update(data) {
        this.setState(data);
    }

    render() {
        const { email, showPassword, code, new_password } = this.state;
        const { lang, autocratError, isFetching, autocrat } = this.props;
        return (
            <form 
                className="card card-md" 
                style={{ boxShadow: '0px 0px 26px 12px rgba(0,0,0,0.15)'}}
            >
                <div className="card-body">

                    {autocrat.type === 'FORGOT_PASSWORD' &&
                        <div className="mb-3">
                            <p className="text-muted">{lang.t('login.resetPassword')}</p>
                            <label className="form-label">{lang.t('login.username')}</label>
                            <Input
                                label={'email'}
                                value={email}
                                update={this.update}
                                validation={(value) => {
                                    return value.length > 3 && autocratError !== '';
                                }}
                            />
                        </div>
                    }

                    {autocrat.type === 'FORGOT_PASSWORD_CODE' &&
                        <div className="mb-3">
                            <p className="text-muted">{lang.t('login.verificationCode')}</p>
                            <label className="form-label">{lang.t('login.enterCode')}</label>
                            <Input
                                label={'code'}
                                value={code}
                                update={this.update}
                                validation={(value) => {
                                    return value.length === 6;
                                }}
                            />
                            <label className="form-label">{lang.t('login.newPassword')}</label>
                            <div className="input-group input-group-flat">
                                <Input
                                    label={'new_password'}
                                    value={new_password}
                                    type={showPassword === true ? 'text' : 'password'}
                                    update={this.update}
                                    validation={(value) => {
                                        return value.length > 100;
                                    }}
                                />
                                <span className="input-group-text">
                                    <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            this.update({
                                                showPassword: !showPassword,
                                            });
                                        }} className="link-secondary" title="Show password" data-toggle="tooltip">
                                        <i className="fe fe-eye" />
                                    </a>
                                </span>
                            </div>
                        </div>
                    }

                    {autocrat.type === 'RESEND_PASSWORD' && (
                        <div className="mb-3">
                            <p className="text-muted">{lang.t('login.newTempPasswordSent')}</p>
                        </div>
                    )}

                    

                    <div className="form-footer" style={{ textAlign: 'right'}}>

                        {!isFetching && autocrat.type === 'FORGOT_PASSWORD' &&
                            <button onClick={(e) => {
                                e.preventDefault();
                                this.props.submitAutocrat({
                                    username: email,
                                    type: 'FORGOT_PASSWORD'
                                });
                            }} className="skew-button black_primary small">
                                <span><span>{lang.t('login.sendPassword')}</span></span>
                            </button>
                        }

                        {!isFetching && autocrat.type === 'FORGOT_PASSWORD_CODE' &&
                            <button onClick={(e) => {
                                e.preventDefault();
                                this.props.submitAutocrat({
                                    code,
                                    new_password,
                                    username: email,
                                    type: 'FORGOT_PASSWORD_CODE'
                                });
                            }} className="skew-button black_primary small">
                                <span><span>{lang.t('login.sendPassword')}</span></span>
                            </button>
                        }

                        {!isFetching && autocrat.type === 'RESEND_PASSWORD' && (
                            <button 
                                className="skew-button black_primary small" 
                                onClick={e => {
                                    e.preventDefault();
                                    this.props.setAutocratType('USER_PASSWORD_AUTH');
                                }}
                            >
                                <span><span>{lang.t('login.loginPage')}</span></span>
                            </button>
                        )}

                        {isFetching &&
                            <div 
                                className="spinner-border text-black" 
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                    marginLeft: 'calc(50% - 10px)'
                            }} role="status" />
                        }

                        <div className="mb-3 mt-3" />

                        {autocrat.type !== 'RESEND_PASSWORD' && (
                            <span className="form-label-description">
                                {lang.t('login.takeBack')}
                                <a style={{marginLeft: 10}} href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.props.setAutocratType('USER_PASSWORD_AUTH');
                                }}>{lang.t('login.loginPage')}</a>
                            </span>
                        )}
                    </div>
                </div>
            </form>
        );
    }
}
export default Form_Forgot;
