import BaseActions from '../../../actions/base';
import services from '../config';

const { root } = services.payments;

const settings = new BaseActions({
    path: `${root}/settings`,
    type: 'PAYMENTS_SETTINGS',
});

export default settings;
