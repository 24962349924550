import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import { Link } from 'react-router-dom';
import main from '../../../../hoc/main';
import { getId } from '../../../../utils/url';

// Core Design
import SpinnerBody from '../../../../components/loaders/SpinnerBody';

import JourneyMap from '../../components/Telematics/JourneyMap';
import JourneyChart from '../../components/Telematics/JourneyChart';

import { extendedDateFormat } from '../../../../utils/format';

import { get, isEmpty, max, round} from 'lodash';
import ReactJson from 'react-json-view';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import moment from 'moment';

class TelematicsJourneyDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicle_index: 0,
        }
    }

    async componentWillMount() {
        const { history } = this.props;
        const id = getId(history);
        await this.props.telematics_journeys_get({ id });
        this.setState({ id })
    }

    render() {
        const {
            selected = {},
            data,
            isFetching,
        } = this.props.telematics_journeys;

        const { vehicle_index, id } = this.state;

        const { geojson = {} } = selected;

        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    <div className="row">
                        {/* Loader */}
                        {isFetching && <SpinnerBody />}

                        {/* Table */}
                        {!isFetching && !isEmpty(geojson) && (
                            <div className="col-sm-12 col-lg-7 mb-3">
                                <table 
                                    className="table table-vcenter table-mobile-md card-table mb-3" 
                                    style={{ background: 'white', border: '1px solid #868c973d' }}
                                >
                                    <thead>
                                        <tr>
                                            <th>{lang.t('tableHeaders.journeyRoute')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding:0 }}>
                                                <JourneyMap 
                                                    coords={geojson.geometry.coordinates.map(c => [c[1], c[0]])}
                                                    angles={geojson.properties.angles}
                                                    height={374}
                                                    vehicle_index={vehicle_index}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding:10 }}>
                                                <Slider 
                                                    min={0}
                                                    max={geojson.geometry.coordinates.length}
                                                    trackStyle={{
                                                        background: '#89a7cc'
                                                    }}
                                                    handleStyle={{
                                                        background: '#206bc4',
                                                        borderColor: 'white'
                                                    }}
                                                    step={1}
                                                    onChange={(val) => {
                                                        this.setState({
                                                            vehicle_index: (val === 0 ? 0 : (val - 1))
                                                        });
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <JourneyChart 
                                    series={[
                                        {
                                            name: 'Speed',
                                            data: geojson.properties.speeds,
                                        },
                                    ]}
                                    label={'Speed'}
                                    unit={'kmh'}
                                    value={geojson.properties.speeds[vehicle_index]}
                                />

                                <JourneyChart 
                                    series={[
                                        {
                                            name: 'X Axis',
                                            data: geojson.properties.accelerometers.map(a => a ? a[0] : 0),
                                        },
                                        {
                                            name: 'Y Axis',
                                            data: geojson.properties.accelerometers.map(a => a ? a[1] : 0),
                                        },
                                        {
                                            name: 'Z Axis',
                                            data: geojson.properties.accelerometers.map(a => a ? a[2] : 0),
                                        },
                                    ]}
                                    label={'Accelerometer'}
                                    unit={''}
                                    value={JSON.stringify(geojson.properties.accelerometers[vehicle_index])}
                                />

                                <JourneyChart 
                                    series={[
                                        {
                                            name: 'Altitude',
                                            data: geojson.properties.altitudes,
                                        },
                                    ]}
                                    label={'Altitude'}
                                    unit={'m'}
                                    value={geojson.properties.altitudes[vehicle_index]}
                                />

                            </div>
                        )}
                        {!isFetching && !isEmpty(geojson) && (
                            <div className="col-sm-12 col-lg-5">
                                <table 
                                    className="table table-vcenter table-mobile-md card-table mb-3" 
                                    style={{ background: 'white', border: '1px solid #868c973d' }}
                                >
                                    <thead>
                                        <tr>
                                            <th>{lang.t('tableHeaders.journeyInformation')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding:0 }}>
                                                <div className="card-body">
                                                    <dl className="row">
                                                        <dt className="col-5">{lang.t('policy.telematics.reference')}:</dt>
                                                        <dd className="col-7">{id}</dd>


                                                        <dt className="col-5">{lang.t('policy.telematics.startTime')}:</dt>
                                                        <dd className="col-7">{extendedDateFormat(geojson.properties.start)}</dd>

                                                        <dt className="col-5">{lang.t('policy.telematics.endTime')}:</dt>
                                                        <dd className="col-7">{extendedDateFormat(geojson.properties.end)}</dd>

                                                        <dt className="col-5">{lang.t('policy.telematics.tagConfidence')}:</dt>
                                                        <dd className="col-7">{round(geojson.properties.tag_confidence || 0, 3)}</dd>
                                                        
                                                        <dt className="col-5">{lang.t('policy.telematics.duration')}:</dt>
                                                        <dd className="col-7">{geojson.properties.duration_in_seconds} secs</dd>

                                                        <dt className="col-5">{lang.t('policy.telematics.distance')}:</dt>
                                                        <dd className="col-7">{geojson.properties.distance_in_metres} m</dd>

                                                        <dt className="col-5">{lang.t('policy.telematics.maxSpeed')}:</dt>
                                                        <dd className="col-7">{max(geojson.properties.speeds)} kmh</dd>

                                                        <dt className="col-5">{lang.t('policy.telematics.imei')}:</dt>
                                                        <dd className="col-7"><Link 
                                                            to={`/telematics/devices/${geojson.properties.device_reference}`}
                                                            className="btn btn-sm btn-primary"
                                                        >
                                                                {geojson.properties.device_reference}
                                                            </Link>
                                                        </dd>

                                                        <dt className="col-5">{lang.t('policy.telematics.dataPackets')}:</dt>
                                                        <dd className="col-7">{geojson.properties.speeds.length}</dd>
                                                    </dl>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <JourneyChart 
                                    series={[
                                        {
                                            name: 'MilliVolts',
                                            data: geojson.properties.battery,
                                        },
                                    ]}
                                    label={'Battery'}
                                    unit={'mV'}
                                    value={geojson.properties.battery[vehicle_index]}
                                />

                                <table 
                                    className="table table-vcenter table-mobile-md card-table mb-3" 
                                    style={{ background: 'white', border: '1px solid #868c973d' }}
                                >
                                    <thead>
                                        <tr>
                                            <th>{lang.t('tableHeaders.journeyEncodedPolyline')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding: 10 }}>
                                                <textarea style={{width: '100%', height: 140, overflowY: 'scroll', fontSize: 11, border: 'none'}}>
                                                    {geojson.properties.polyline}
                                                </textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default main(TelematicsJourneyDetail);