import React from 'react';

export default function AlertMessage(props) {

    const { icon, message } = props;

    return (
        <div className='alert alert-danger mb-2 p-1' role='alert'>
            <i style={{ marginRight: 5 }} className={`fe fe-${icon}`}></i>
            {message}
        </div>
    );
}
