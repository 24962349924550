import { get, sortBy, reject, max } from 'lodash';

export function indexCollection(col = []) {
    return col.map((c, i) => {
        return {
            ...c,
            id: (1 + i),
        }
    });
}

export function updateCollection(col = [], obj = {}) {
    const rejected = reject(col, c => {
        return get(c, 'id') === get(obj, 'id');
    });
    return sortBy([obj , ...rejected], r => get(r, 'id'));
}

export function deleteCollection(col = [], id) {
    const rejected = reject(col, c => {
        return get(c, 'id') === id;
    });
    return rejected;
}

export function addCollection(col = [], obj = {}) {
    const next_id = max(col.map(c => c.id)) + 1;
    return sortBy([...col, {
        id: next_id,
        ...obj
    }], r => get(r, 'id'));
}