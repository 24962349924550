import React from 'react'

export default function Input(props) {
    return (
        <div className='input-group mb-2'>
            <div className='input-group-prepend'>
                <span className='input-group-text' style={{width: '140px'}}>{props.label}</span>
            </div>
            <input
                type='text'
                className='form-control'
                value={props.value}
                disabled
            />
        </div>
    )
}
