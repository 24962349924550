import React, { Component } from 'react';
import moment from 'moment';
import { round, get, meanBy } from 'lodash';

import main from '../../../../hoc/main';

import PageHeader from '../../../../components/layout/PageHeader';

const jobs = require('./jobs.json');

function formatStatus(status) {
    if (status === 'completed') {
        return (<span className="badge bg-green">Completed</span>);
    }
    if (status === 'queued') {
        return (<span className="badge bg-gray">Queued</span>);
    }
    if (status === 'started') {
        return (<span className="badge bg-yellow">Running</span>);
    }
    if (status === 'error') {
        return (<span className="badge bg-red">error</span>);
    }
    return (<span class="badge bg-gray">{status}</span>);
}

function aveJobTime(jobs, key) {
    let job = jobs.filter(j => j.process === key);
    if (job.length === 0) return '-';
    return round(meanBy(job, 'time_taken')/1000, 1)
}

class Jobs extends Component {
    constructor(props) {
        super(props);
        this.runJob = this.runJob.bind(this);
    }

    componentDidMount() {
        this.props.claims_jobs_get_all({});
    }

    runJob(process) {
        this.props.claims_jobs_post({
            data: {
                process,
            }
        })
    }

    render() {
        return (
            <div class="my-3 my-md-5">
                <div class="container">
                    <PageHeader
                        section={'Claims'}
                        page={'Report Jobs'}
                    />
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Build Jobs</h4>
                                </div>
                                <table class="table card-table table-vcenter">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th colspan="4">Progress</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jobs.slice(0, 50).map(j => {
                                            return (
                                                <tr>
                                                    <td>#{j.id}</td>
                                                    <td>{j.process}</td>
                                                    <td>{formatStatus(j.status)}</td>
                                                    <td>{moment(j.started_at).format('HH:mm')} ({moment(j.started_at).fromNow()})</td>
                                                    <td>{j.current_count || '-'} / {j.total_count || '-'}</td>
                                                    <td>{round(j.time_taken/1000, 1) || '-'} seconds</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-lg-6">

                            <div className="card">
                                <div class="card-body d-flex align-items-center">
                                    <div class="mr-1 lh-sm">
                                        <div class="strong">
                                            Loss Ratio reports
                                        </div>
                                        <div class="mt-2">
                                            Average job time is {aveJobTime(jobs, 'reports')} seconds
                                        </div>
                                    </div>
                                    <div class="ml-auto">
                                        <div onClick={() => this.runJob('reports')} class={`btn text-white btn-blue`}>Queue Job</div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div class="card-body d-flex align-items-center">
                                    <div class="mr-1 lh-sm">
                                        <div class="strong">
                                            Demographic reports
                                        </div>
                                        <div class="mt-2">
                                            Average job time is {aveJobTime(jobs, 'demographics')} seconds
                                        </div>
                                    </div>
                                    <div class="ml-auto">
                                        <div onClick={() => this.runJob('demographics')} class={`btn text-white btn-blue`}>Queue Job</div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div class="card-body d-flex align-items-center">
                                    <div class="mr-1 lh-sm">
                                        <div class="strong">
                                            Generate Analysis 
                                        </div>
                                        <div class="mt-2">
                                            Average job time is {aveJobTime(jobs, 'analysis')} seconds
                                        </div>
                                    </div>
                                    <div class="ml-auto">
                                        <div onClick={() => this.runJob('analysis')} class={`btn text-white btn-blue`}>Queue Job</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default main(Jobs);