import React, { Component } from 'react';
import { getLangSource } from '../../../../lang/index';

import main from '../../../../hoc/main';

// Core Design
import SpinnerBody from '../../../../components/loaders/SpinnerBody';
import Search from '../../../../components/search/Simple';
import Empty from '../../../../components/empty/Results';
import PageHeader from '../../../../components/layout/PageHeader';

// Service Design
import Table from '../../components/Policies/PolicysTable';
import ProductsFilterDropDown from '../../components/Products/FilterDropdown';

class Policies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            filterTerm: '',
            page: 1,
        }
        this.search = this.search.bind(this);
        this.filter = this.filter.bind(this);
        this.term = this.term.bind(this);
        this.update = this.update.bind(this);
    }

    async componentWillMount() {
        await this.props.policys_products_get_all({});
        await this.props.policys_get_all({
            limit: 51,
            offset: 0,
        });
    }

    filter({ product_reference }) {
        
        this.setState({
            filterTerm: product_reference,
        }, () => {
            this.search();
        })
    }

    term(term) {
        
        if (
            (term.length > 0 && term.length < 3) || 
            this.props.policys.isFetching
        ) {
            return;
        }

        this.setState({
            searchTerm: term,
        }, () => {
            this.search();
        })
    }

    async search() {
        const { searchTerm = '', filterTerm = '' } = this.state;
        let where = { 
            term: searchTerm,
            limit: 51,
            offset: 0,
        };

        this.setState({
            page: 1,
        });

        if (filterTerm !== '') where = { ...where, product_reference: filterTerm };
        await this.props.policys_get_all(where);
    }

    update(data) {
        this.setState(data);
    }

    render() {
        const { policys, lang: language } = this.props;
        const { isFetching, data = [] } = policys;
        const { data: products } = this.props.policys_products;
        const { page, searchTerm, filterTerm } = this.state;

        const lang = getLangSource(language);

        return (
            <div className="my-3 my-md-5">
                <div className="container">
                    <PageHeader
                        section={lang.t('policy.title')}
                        page={lang.t('policy.policies.search')}
                    />

                    {/* Search */}
                    <div className="row">
                        <div className="col-sm-12 col-lg-8">
                            <ProductsFilterDropDown
                                products={this.props.policys_products}
                                update={this.filter}
                                lang={lang}
                            />
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                                <label className="form-label">{lang.t('policy.policies.searchByEtc')}</label>
                                <Search
                                    searchTerm={searchTerm}
                                    searchFunction={this.term}
                                    isFetching={isFetching}
                                    label="policies"
                                    lang={lang}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                    {/* Loader */}
                        {isFetching && <SpinnerBody />}

                    {/* Empty State */}
                    {!isFetching && data.length === 0
                            && <Empty label="policies" lang={lang} />}

                    {/* Table */}
                        {!isFetching && data.length > 0
                            && (
                            <div className="col-sm-12 col-lg-12">
                                <Table
                                    data={data}
                                    products={products}
                                    page={page}
                                    update={this.update}
                                    term={searchTerm}
                                    product_reference={filterTerm}
                                    policys_get_all={this.props.policys_get_all}
                                    lang={lang}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default main(Policies);