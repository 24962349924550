import React, { Component } from 'react';
import FormUpload from './FormUpload';
import AddSuccess from '../../../../components/modal/AddSuccess';
import CloseButton from '../../../../components/buttons/CloseButton';

export default class ModalAddFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stage: 'addFileForm',
            showError: false,
            errorIcon: '',
            errorText: '',
        };
        this.closeModal = this.closeModal.bind(this);
        this.updateStage = this.updateStage.bind(this);
        this.setErrorState = this.setErrorState.bind(this);
    }

    closeModal() {
        this.props.toggleModal();
        this.updateStage('addFileForm');
        this.setState({
            showError: false,
            errorIcon: '',
            errorText: ''
        })
    }

    updateStage(stage) {
        this.setState({
            stage
        });
    }

    setErrorState({ showError, errorIcon, errorText }) {
        this.setState({
            showError,
            errorIcon,
            errorText
        })
    }

    render() {
        const { 
            open, 
            claim_reference, 
            policys_claims_documents_post, 
            latestClaimVersion,
            policy_reference,
            policys_claims_put,
            refreshClaimData,
            policys_claims_documents_put,
            lang,
        } = this.props;

        const { stage, showError, errorIcon, errorText } = this.state;

        return (
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} tabIndex="-1" role="dialog" aria-modal="true">
                <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header theme-dark'>
                            <h5 className='modal-title'>{lang.t('policy.claims.addFile')}</h5>
                            <CloseButton onClick={this.closeModal} />
                        </div>
                        {stage === 'addFileForm' && 
                            <FormUpload
                                updateStage={this.updateStage}
                                policys_claims_documents_post={policys_claims_documents_post}
                                claim_reference={claim_reference}
                                latestClaimVersion={latestClaimVersion}
                                policys_claims_put={policys_claims_put}
                                policy_reference={policy_reference}
                                refreshClaimData={refreshClaimData}
                                policys_claims_documents_put={policys_claims_documents_put}
                                setErrorState={this.setErrorState}
                                showError={showError}
                                errorIcon={errorIcon}
                                errorText={errorText}
                                lang={lang}
                            />
                        }
                        {stage === 'addFileSuccess' && 
                            <AddSuccess 
                                close={this.closeModal} 
                                name={lang.t('policy.claims.file')}
                                lang={lang}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}
