import React, { Component } from 'react';

import main from '../../../hoc/main';

import SpinnerBody from '../../../components/loaders/SpinnerBody';
import Policys from '../components/Reports/Policys/Index';
import { get } from 'lodash';

class ReportPolicys extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.pricing_quotes_get_all({
            limit: 2000,
        });
    }

    render() {
        const quotes = get(this.props, 'pricing_quotes.data', []);
        const isFetching = get(this.props, 'pricing_quotes.isFetching');
        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    {isFetching && 
                        <div className="row">
                            <SpinnerBody 
                                message={'Retrieving models. This should be quick.'}
                                selectedProduct={this.props.selectedProduct}
                            />
                        </div>
                    }

                    {!isFetching && quotes.length > 0 &&
                        <Policys 
                            quotes={quotes}
                        />
                    }
                </div>
            </div>
        );
    }
}

export default main(ReportPolicys);