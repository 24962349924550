import React, { Component } from 'react';

class BasicDropDown extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            items,
            name,
            label,
            select,
        } = this.props;
        return (
            <div className="form-group">
                <label className="form-label">{label}</label>
                <select
                    onChange={(e) => select({
                        [name]: e.target.value,
                    })} name={name} id={`select-${name}`} className="form-control custom-select"
                >
                    {items.map(i => (<option value={i.value}>{i.label}</option>))}
                </select>
            </div>
        );
    }
}

export default BasicDropDown;
