import React from 'react';

function QuotesModal(props) {
        const { open, modalToggle, deleteQuote, quote_reference, lang } = props;

        return(
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-exclusion" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{lang.t('rating.components.deleteQuoteModal.delete')}</h5>
                            <button type="button" onClick={modalToggle} className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div style={{ textAlign: 'center', fontSize: '50px' }}>
                                <i style={{color: '#354052'}} className="fe fe-trash-2" />
                                <h4 style={{ color: '#354052' }}>{lang.t('rating.components.deleteQuoteModal.areUSure')}</h4>
                            </div>
                            
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" onClick={e => {
                                e.preventDefault();
                                deleteQuote(quote_reference);
                            }}
                                >{lang.t('rating.components.deleteQuoteModal.ok')}</button>
                            <button className="btn btn-light" onClick={modalToggle}>{lang.t('rating.components.deleteQuoteModal.cancel')}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

export default QuotesModal;