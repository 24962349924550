import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

class DropZone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
        };
        this.processFile= this.processFile.bind(this);
    }

    processFile([file]) {
        
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
            this.props.update({ 
                file, 
                data: reader.result,
                error: null,
            });
        }
    }
    
    render() {
        const { uploading } = this.state;
        const { upload_success, is_uploading } = this.props;
        return (
            <Dropzone onDrop={acceptedFiles => this.processFile(acceptedFiles)} disabled={upload_success || is_uploading}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <div className="card">
                            <div className="card-body" style={{ border: '2px dotted #868c97', margin: 5 }}>
                                <h4 className="card-title">{this.props.title}</h4>
                                <div style={{ width: '100%', textAlign: 'center', marginTop: 25, marginBottom: 25 }}>
                                    {!uploading && 
                                        <i style={{ fontSize: 40, opacity: 0.6 }} className="fe fe-share"></i>
                                    }
                                    {uploading &&
                                        <div style={{ width: 40, height: 40 }} className="spinner-border text-green spinner-border-sm" role="status"></div>
                                    }
                                    <input {...getInputProps()} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Dropzone>          
        );
    }
}

export default DropZone;