import React, { Component } from 'react';
import moment from 'moment';
import { round } from 'lodash';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/dark.css";

class Publish extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: moment().startOf('hour').toISOString(),
        };
        this.publish = this.publish.bind(this);
    }

    publish({
        status,
        published_at,
    }) {
        this.props.publishVersion({
            id: this.props.version_reference,
            status,
            published_at,
        });
    }

    render() {
        const { date } = this.state;
        const { status, lang } = this.props;
        return (
            <div className="row mt-6">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header" style={{ position: 'relative' }}>
                            <h3 className="card-title">
                                {lang.t('rating.components.publish.schedulePublish')}
                            </h3>
                            <button 
                                className="btn btn-orange text-white btn-sm"
                                style={{
                                    top: 12,
                                    right: 16,
                                    position: 'absolute',
                                }}
                                onClick={() => {
                                    this.publish({
                                        status: 'published',
                                        published_at: round(moment(date.toString()).format('X')),
                                    });
                                }}
                            >
                                {lang.t('rating.components.publish.schedulePublish')}
                            </button>
                        </div>
                        <div className="card-body">
                            <div className="row">                              
                                <div>
                                    <Flatpickr
                                        data-enable-time
                                        value={date}
                                        className={'form-select'}
                                        options={{
                                            minDate: moment().startOf('hour').toISOString(),
                                        }}
                                        onChange={data => {
                                            this.setState({
                                                date: data,
                                            });
                                        }}
                                    />
                                </div>                          
                            </div>
                            <div className="row mt-4 ml-1 mr-1">
                                <div className="alert alert-info" role="alert">
                                    <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                    {lang.t('rating.components.publish.scheduleInfo')}<b>{lang.t('rating.components.publish.public')}</b> {lang.t('rating.components.publish.prices')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Staging option */}
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {lang.t('rating.components.publish.stagingPublish')}
                            </h3>
                            {status !== 'staging' &&
                                <button 
                                    className="btn btn-indigo text-white btn-sm"
                                    style={{
                                        top: 12,
                                        right: 16,
                                        position: 'absolute',
                                    }}
                                    onClick={() => {
                                        this.publish({
                                            status: 'staging',
                                        });
                                    }}
                                >
                                    {lang.t('rating.components.publish.stagingPublish')}
                                </button>
                            }
                        </div>
                        <div className="card-body">
                            <div className="row ml-1 mr-1">                              
                                <div className="alert alert-info" role="alert">
                                    <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                    {lang.t('rating.components.publish.immediateInfo')} <b>{lang.t('rating.components.publish.private')}</b>{lang.t('rating.components.publish.stagingInfo')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {lang.t('rating.components.publish.publishNow')}
                            </h3>
                            <button 
                                className="btn btn-green text-white btn-sm"
                                style={{
                                    top: 12,
                                    right: 16,
                                    position: 'absolute',
                                }}
                                onClick={() => {
                                    this.publish({
                                        status: 'published',
                                        date,
                                    });
                                }}
                            >
                                {lang.t('rating.components.publish.publishNow')}
                            </button>
                        </div>
                        <div className="card-body">
                            <div className="row ml-1 mr-1">                              
                                <div className="alert alert-info" role="alert">
                                    <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                    {lang.t('rating.components.publish.immediateInfo')}<b>{lang.t('rating.components.publish.public')}</b>{lang.t('rating.components.publish.price')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Publish;