import React, { Component } from 'react';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { times, random, round } from 'lodash';
import moment from 'moment';

class SalesTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { segments = [], total, lang } = this.props;
        let policy_active = (total.nb - total.cn);
        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>  
                            <tr>
                                <th className="w-4">{lang.t('tableHeaders.date')}</th>
                                <th className="w-2">{lang.t('tableHeaders.active')}</th>
                                <th className="w-6">{lang.t('tableHeaders.chart')}</th>
                                <th className="w-2">{lang.t('tableHeaders.sold')}</th>
                                <th className="w-2">{lang.t('tableHeaders.cancelled')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {segments.slice(0, 10).map((d, i) => {
                                const {
                                    nb_count = 0,
                                    cn_count = 0 ,
                                    timestamp = 0,
                                } = d;
                                // Running update of active total
                                if (i !== 0 ) policy_active = (policy_active - nb_count - cn_count);
                                return (
                                    <tr key={`factors-${i}`}>
                                        <td data-label="Date">
                                            <div className="text-muted text-h5">{moment(timestamp, 'X').format('ll')}</div>
                                        </td>
                                        <td data-label="total">  
                                            <div className="text-h5"><div className='badge bg-blue'>{policy_active}</div></div>
                                        </td>
                                        <td data-label="Chart">
                                            <div style={{ width: 100}}>
                                            <Sparklines width={100} height={20} data={[1,1,1,1,1,1,1,1,1,1,1,1,11,1,1,1].map(r => random(0, 20))}>
                                                <SparklinesLine color="#206bc4" />
                                            </Sparklines>
                                            </div>
                                        </td>
                                        <td data-label="Sold">  
                                            <div className="text-h5"><div className='badge bg-green'>{nb_count}</div></div>
                                        </td>
                                        <td data-label="Cancelled">
                                            <div className="text-muted text-h5"><div className='badge bg-red'>{cn_count}</div></div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default SalesTable;
