import React from 'react'
import Input from '../../Inputs/Input'

export default function FormPricing(props) {

    const { 
        upfront_rate, 
        usage_rate, 
        subscription_rate, 
        refer_count, 
        exclusion_count, 
        total_excess,
        subscription_installments, 
        commission_rate, 
        ipt_rate, 
        base_premium, 
        upfront_premium, 
        subscription_premium, 
        usage_premium, 
        estimated_distance, 
        yearly_premium_estimate 
    } = props.data;

    const lang = props.lang;

    return (
        <div className='card ml-2'>
            <div className='card-header'>
                <h3 className='card-title'>{lang.t('rating.components.formPricing.detail')}</h3>
            </div>
            <div className='card-body'>
                <div className='mb-1'>
                    <Input 
                        label={lang.t('rating.components.formPricing.upfrontRate')}
                        value={upfront_rate} 
                    />
                    <Input 
                        label={lang.t('rating.components.formPricing.upfrontPremium')}
                        value={upfront_premium} 
                    />
                    <Input 
                        label={lang.t('rating.components.formPricing.usageRate')}
                        value={usage_rate} 
                    />    
                    <Input 
                        label={lang.t('rating.components.formPricing.usagePremium')}
                        value={usage_premium} 
                    />
                    <Input 
                        label={lang.t('rating.components.formPricing.estimatedDistance')}
                        value={estimated_distance} 
                    />
                    <Input 
                        label={lang.t('rating.components.formPricing.yearlyPremEst')}
                        value={yearly_premium_estimate} 
                    />
                    <Input 
                        label={lang.t('rating.components.formPricing.subRate')}
                        value={subscription_rate} 
                    />
                    <Input 
                        label={lang.t('rating.components.formPricing.subPremium')}
                        value={subscription_premium} 
                    />
                    <Input 
                        label={lang.t('rating.components.formPricing.referCount')}
                        value={refer_count} 
                    />
                    <Input 
                        label={lang.t('rating.components.formPricing.exclusionCount')}
                        value={exclusion_count} 
                    />
                    <Input 
                        label={lang.t('rating.components.formPricing.totalExcess')}
                        value={total_excess} 
                    />    
                    <Input 
                        label={lang.t('rating.components.formPricing.subInstall')}
                        value={subscription_installments} 
                    />
                    <Input 
                        label={lang.t('rating.components.formPricing.commissionRate')}
                        value={commission_rate} 
                    />
                    <Input 
                        label={lang.t('rating.components.formPricing.iptRate')}
                        value={ipt_rate} 
                    />    
                    <Input 
                        label={lang.t('rating.components.formPricing.basePremium')}
                        value={base_premium} 
                    />
                </div>
            </div>
        </div>
    )
}