import React, { Component } from 'react';
import { saveAs } from 'file-saver';
import DropZone from '../../DropZoneCSV'; 
import FactorsTable from './FactorsTable';
import { Parser } from 'json2csv';
import { each, findIndex, times } from 'lodash';

import { get, uniq, round, omit } from 'lodash';

class Factors extends Component {
    constructor(props) {
        super(props);
        this.updateFactorsCSV = this.updateFactorsCSV.bind(this);
        this.editFactor = this.editFactor.bind(this);
        this.deleteFactor = this.deleteFactor.bind(this);
        this.exportFactorsCSV = this.exportFactorsCSV.bind(this);
    }

    editFactor(factor) {
        this.props.openModal({
            factors: {
                open: true,
                name: factor,
            }
        });
    }

    deleteFactor(factor) {
        this.props.deleteFactor(omit(this.props.factors, factor));
    }

    downloadExample(factor_name) {

    }

    exportFactorsCSV(name) {
        const factor = this.props.factors[name];

        let mapped_factor = [];
        const [factor_1, factor_2, factor_3] = Object.keys(factor.dimensions);
        let index = 1;

        each(factor.hashes, (hash, hkey) => {
            const [value_1, value_2, value_3] = hkey.split('::');
            mapped_factor.push({
                name, 
                ...hash,
                factor_1,
                value_1,
                factor_2,
                value_2,
                factor_3,
                value_3,
            });
        });

        mapped_factor.forEach((mf, i) => {
            if (mf.factor_1) {
                const index = findIndex(factor.dimensions[mf.factor_1].value, f => f === mf.value_1);
                mapped_factor[i].value_to_1 =  factor.dimensions[mf.factor_1].value_to[index];
            }
            if (mf.factor_2) {
                const index = findIndex(factor.dimensions[mf.factor_2].value, f => f === mf.value_2);
                mapped_factor[i].value_to_2 =  factor.dimensions[mf.factor_2].value_to[index];
            }
            if (mf.factor_3) {
                const index = findIndex(factor.dimensions[mf.factor_3].value, f => f === mf.value_3);
                mapped_factor[i].value_to_3 =  factor.dimensions[mf.factor_3].value_to[index];
            }
        });

        const parser = new Parser({fields:[
            'name',
            'exclude',
            'refer',
            'excess',
            'weight',
            'endorsement',
            'factor_1',
            'value_1',
            'value_to_1',
            'factor_2',
            'value_2',
            'value_to_2',
            'factor_3',
            'value_3',
            'value_to_3',
        ]})
        const mapped_csv = parser.parse(mapped_factor)
        const file_name = `${name}.csv`;
        
        // Create a blob of the data
        const content = new Blob([mapped_csv], {
            type: 'text/csv',
            name: name
        });
        
        // Save the file
        saveAs(content, file_name);
    }

    updateFactorsCSV(items = []) {
        const name = get(items, '[0].name');
        if (!name) return console.log('Not a valid factors file');

        //TODO Check that schema and values exist - validation
        //TODO Force every table to have -1 default
        const factor = {
            description: '',
            dimensions: {},
            hashes: {}
        };

        function buildHashes(item) {
            return {
                weight: round(item.weight, 15),
                exclude: (item.exclude || '').toUpperCase() === 'TRUE',
                refer: (item.refer || '').toUpperCase() === 'TRUE',
                excess: round(item.excess, 2) || 0,
                endorsement: item.endorsement || null,
            }
        }

        function buildDimensions(key) {
            const value = uniq(items.map(i => i[`value_${key}`]));
            const value_to = uniq(items.map(i => i[`value_to_${key}`]));
            return {
                value: value,
                value_to: value_to || [],
            };
        }

        const factor_1 = get(items, '[0].factor_1', null);
        const factor_2 = get(items, '[0].factor_2', null);
        const factor_3 = get(items, '[0].factor_3', null);
        
        if (factor_3) {
            factor.dimensions[`${factor_1}`] = buildDimensions(1);
            factor.dimensions[`${factor_2}`] = buildDimensions(2);
            factor.dimensions[`${factor_3}`] = buildDimensions(3);
            items.forEach(item => {
                factor.hashes[`${item.value_1}::${item.value_2}::${item.value_3}`] = buildHashes(item);
            });
            return this.props.updateFactors({
                [name]: factor,
            });
        }
        
        if (factor_2) {
            factor.dimensions[`${factor_1}`] = buildDimensions(1);
            factor.dimensions[`${factor_2}`] = buildDimensions(2);
            items.forEach(item => {
                factor.hashes[`${item.value_1}::${item.value_2}`] = buildHashes(item);
            });
            return this.props.updateFactors({
                [name]: factor,
            });
        }
       
        if (factor_1) {
            factor.dimensions[`${factor_1}`] = buildDimensions(1);
            items.forEach(item => {
                factor.hashes[`${item.value_1}`] = buildHashes(item);
            });
            return this.props.updateFactors({
                [name]: factor,
            });
        }

        
    }

    render() {
        const { factors, lang } = this.props;
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">{lang.t('rating.components.factors.title')}</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        {lang.t('rating.components.factors.factorInfo')}
                                    </div>
                                    <FactorsTable 
                                        data={Object.keys(factors).map(f => {
                                            return {
                                                name: f,
                                                description: factors[f].description,
                                                dimensions: Object.keys(factors[f].dimensions).length + 'D',
                                            }
                                        })}
                                        edit={this.editFactor}
                                        delete={this.deleteFactor}
                                        download={this.exportFactorsCSV}
                                        pages={10}
                                        lang={lang}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        {lang.t('rating.components.factors.upload')}
                                    </div>
                                    <DropZone 
                                        title={lang.t('rating.components.factors.drop')}
                                        process={(data) => this.updateFactorsCSV(data)}
                                        height={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Factors;