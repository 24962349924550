import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DropDown from '../../../../components/forms/Dropdown';

class ModelsCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            template: 'empty',
        }
        this.save = this.save.bind(this);
    }

    save() {
        const {
            name,
            description,
            template,
        } = this.state;
        if (!name || name.length < 3) return;
        this.props.pricing_template_post({
            data: {
                name,
                description,
                template,
            }
        }).then(() => {
            this.props.pricing_models_get_all({});
        })
    }

    render() {
        const { data = [], lang } = this.props;
        const { name, description, template } = this.state;

        return (
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                        {lang.t('rating.models.createNew')}
                    </h3>
                    <button 
                        className="btn btn-primary btn-sm"
                        style={{
                            top: 12,
                            right: 16,
                            position: 'absolute',
                        }}
                        onClick={this.save}
                    >
                        {lang.t('rating.models.generate')}
                    </button>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-5">
                            <div class="form-label">{lang.t('rating.models.modelName')}</div>
                            <input type="text" class="form-control" onBlur={(e) => {
                                this.setState({
                                    name: e.target.value,
                                })
                            }} placeholder={lang.t('rating.models.placeholderName')} defaultValue={name} />
                        </div>
                        <div className="col-5">
                            <div class="form-label">{lang.t('rating.models.modelDescription')}</div>
                            <textarea onBlur={(e) => {
                                    this.setState({
                                        description: e.target.value,
                                    })
                                }} 
                                type="text" 
                                placeholder={lang.t('rating.models.placeholderDesc')}
                                style={{ height:100 }} 
                                class="form-control" 
                                defaultValue={description} 
                            />
                        </div>
                        <div className="col-2">
                            <div class="form-label">{lang.t('rating.models.modelTemplate')}</div>
                            <DropDown
                                ref='template'
                                label='template'
                                value={template}
                                update={(data) => {
                                    this.setState(data)
                                }}
                                labelMap={{
                                    'empty': lang.t('rating.models.emptyModel'), 
                                    'simple_motor': lang.t('rating.models.simplyMotor'), 
                                    'simple_home': lang.t('rating.models.simpleHome'),
                                }}
                                items={['empty', 'simple_motor', 'simple_home']}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModelsCreate;
