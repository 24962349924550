import React from 'react';
import { round } from 'lodash';

function ProgressBar(props) {
    const { message, selectedProduct = 'policy', progress } = props;
    const is_alt = selectedProduct ==='rating' ? true : false;
    const progress_perc = round(progress * 100);
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <div className="card" style={{ textAlign: 'center' }}>

                        {message && <h3 style={{
                            position: 'absolute',
                            width: '100%',
                            top: '50px',
                            color: is_alt ? 'white' : 'black',
                        }}>{message}</h3>}

                        <div className="progress" style={{ 
                                marginTop: 100,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: 100,
                                width: '32%',
                                textAlign: 'right'}}>
                            <div className="progress-bar progress-bar-striped progress-bar-animated" 
                                role="progressbar" 
                                style={{ width: `${progress_perc}%` }} 
                                aria-valuenow={progress_perc.toString()} 
                                aria-valuemin="0" 
                                aria-valuemax="100">
                            </div>
                        </div>
                        <h5 style={{ 
                            position: 'absolute', 
                            bottom: '60px', 
                            textAlign: 'center', 
                            width: '100%' 
                            }}>
                                {`${progress_perc}%`}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProgressBar;
