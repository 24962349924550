import React, { Component } from 'react';
import AvatarBox from './AvatarBox';
import Language from './Language';

import policy_icon_black_svg from './icons/policy_engine_black.svg';
import rating_icon_white_svg from './icons/rating_engine_white.svg';

class TopBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            lang,
            selectedLang,
            selectLang,
            selectProduct,
            selectedProduct,
            selectEnv,
            selectedEnv,
            auth,
            signOut,
            isLogin,
        } = this.props;
        return (
            <nav
                className="navbar navbar-secondary navbar-expand"
                id="navbar-secondary"
            >
                <div className="container">
                    <a href="#" className="navbar-brand navbar-brand-autodark d-none-navbar-vertical">
                        <div className="bb_logo"></div>
                    </a>

                    <ul className="navbar-nav ml-auto">

                        {/*selectedProduct === 'rating' &&
                        <li className="nav-item dark-custom-badge" style={{ marginRight: 24 }}>
                            {auth.company_name}
                        </li>
                        */}

                        {/*selectedProduct !== 'rating' &&
                        <li className="nav-item light-custom-badge" style={{ marginRight: 24 }}>
                            {auth.company_name}
                        </li>
                        */}

                        {/*selectedProduct === 'rating' &&
                            <li className="nav-item dark-custom-badge" style={{ marginRight: 24 }}>
                                <img src={rating_icon_white_svg} className="icon" /> {lang.tc(`products.rating`)} {lang.tc(`products.engine`)}
                            </li>
                        */}

                        {/*selectedProduct === 'policy' &&
                            <li className="nav-item light-custom-badge" style={{ marginRight: 24 }}>
                                <img src={policy_icon_black_svg} className="icon" /> {lang.tc(`products.policy`)} {lang.tc(`products.engine`)}
                            </li>
                        */}

                        {/*selectedEnv === 'production' &&
                            <li className="nav-item" style={{ marginRight: 24 }}>
                                <span className="badge badge-pill bg-green ml-1">{lang.tc(`products.production`)}</span>
                            </li>
                        */}

                        {/*selectedEnv === 'sandbox' &&
                            <li className="nav-item" style={{ marginRight: 24 }}>
                                <span className="badge badge-pill bg-yellow ml-1">{lang.tc(`products.sandbox`)}</span>
                            </li>
                        */}

                        
                        <Language
                            lang={lang}
                            selectLang={selectLang}
                            selectedLang={selectedLang}
                        />

                        {!isLogin && (
                            <AvatarBox
                                last_name={auth.l_names}
                                first_name={auth.f_names}
                                role={auth.role}
                                products={auth.products}
                                lang={lang}
                                auth={auth}
                                signOut={signOut}
                                selectProduct={selectProduct}
                                selectEnv={selectEnv}
                                selectedProduct={selectedProduct}
                                selectedEnv={selectedEnv}
                            />
                        )}
                </ul>
            </div>
        </nav>
        );
    }
}

export default TopBar;
