import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import main from '../../../../hoc/main';
import { getId } from '../../../../utils/url';
import validateUuid from '../../../../utils/validateUuid';
import { Link } from 'react-router-dom';

// Core Design
import SpinnerBody from '../../../../components/loaders/SpinnerBody';
import Empty from '../../../../components/empty/Results';

import EmailTemplatesTable from '../../components/Products/EmailTemplatesTable';
import DocTemplatesTable from '../../components/Products/DocTemplatesTable';
import EmailTemplateDetail from '../../components/Products/Emails/Index';
import DocTemplateDetail from '../../components/Products/Docs/Index';
import Payments from '../../components/Products/Payments';
import Webhooks from '../../components/Products/Webhooks';
import Settings from '../../components/Products/Settings';

import { get, isEmpty, first } from 'lodash';
import moment from 'moment-timezone'

class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: {},
            section: 'details',
            formError: false,
            timezones: [],
        }
        this.update = this.update.bind(this);
        this.updateSettings = this.updateSettings.bind(this);
        this.save = this.save.bind(this);
        this.section = this.section.bind(this);
        this.testPaymentKeys = this.testPaymentKeys.bind(this);
    }

    async componentWillMount() {
        const { history } = this.props;
        const id = getId(history);

        const timezones_array = [
            ...moment.tz.zonesForCountry('AU'),
            moment.tz.zonesForCountry('GB')[0],
            moment.tz.zonesForCountry('IT')[0]
        ]
        
        this.setState({timezones: timezones_array})

        await this.props.policys_products_get({
            id,
        });
        this.setState({
            selected: this.props.policys_products.selected,
        })
    }

    update(item) {
        this.setState({
            selected: {
                ...this.state.selected,
                ...item,
            },
        })
    }

    updateSettings(item, e) {
        if (e.target.value === '') {
            const property = get(e, 'target.attributes.label.value');
            
            if (property === 'renewal_rating_model') {
                this.setState({
                    formError: false,
                });
            }
            
            return e.target.value = this.state.selected[property];
        }

        if (item.renewal_rating_model) {
            const is_valid = validateUuid(item.renewal_rating_model);

            if (!is_valid) {
                return this.setState({
                    formError: true,
                });
            } else {
                this.setState({
                    formError: false,
                });
            }
        }

        this.setState({
            selected: {
                ...this.state.selected,
                ...item,
            },
        })
    }

    async save(product) {
        const product_data = product.data;

        const { 
            distance_capped,
            distance_capped_statement,
            payment_provider_key,
            payment_secret_key,
            webhooks_secret,
            webhooks_url,
            renewal_rating_model,
        } = product_data;

        // Does not submit product keys if they are unchanged.
        if (!payment_provider_key || payment_provider_key.includes('*****')) delete product_data.payment_provider_key;
        if (!payment_secret_key || payment_secret_key.includes('*****')) delete product_data.payment_secret_key;
        if (!webhooks_url) delete product_data.webhooks_url;
        if (!webhooks_secret) delete product_data.webhooks_secret;
        if (!renewal_rating_model) delete product_data.renewal_rating_model;

        // Remove app_url since We do not update it in product detail page
        delete product_data.app_url;

        // Deals with Input passing distance_capped and distance_capped_statement as strings.
        if (distance_capped) {
            product_data.distance_capped = parseInt(distance_capped);
        }

        if (distance_capped_statement) {
            product_data.distance_capped_statement = parseInt(distance_capped_statement);
        }

        const updated_product = {
            ...product,
            data: {
                ...product_data,
            }
        }

        const result = await this.props.policys_products_put(updated_product);

        const { payload } = result
        if (payload.status === 200) this.setState({ formError: false })

        const { history } = this.props;
        const id = getId(history);
        await this.props.policys_products_get({ 
            id,
        });
        this.setState({
            selected: this.props.policys_products.selected,
            section: 'details',
        })
    }

    section({ section, reference = null }) {
        this.setState({
            section,
            reference,
        })
    }

    async testPaymentKeys() {
        const { product_reference } = this.state.selected;
        const { payload } = await this.props.policys_payments_post({
            data: {
                type: 'TEST_KEY',
                product_reference,
            }
        });

        return payload;
    }

    render() {
        const { 
            isFetching = true,
            data = [],
        } = this.props.policys_products;

        const { selected, section, reference, formError, timezones } = this.state;

        const excludedEmailTemplates = [
            'email_policy_cancel_completed',
            'email_policy_cancel_submitted',
            'email_policy_int_submitted',
        ];

        const excludedDocumentTemplates = [
            'doc_policy_schedule_no_header',
        ];

        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">
                

                    <div className="row">
                        {/* Loader */}
                        {isFetching && <SpinnerBody />}

                        {/* Empty State */}
                        {!isFetching && isEmpty(selected) && <Empty label="Products" lang={lang} />}

                        {/* Settings */}
                        {!isFetching && !isEmpty(selected) && section === 'details' && (
                            <div className="col-sm-12 col-lg-12">
                                <div className="row ml-0 mb-3"> 
                                    <div className="col-lg-6">
                                        <Link class="btn btn-secondary" to='/settings/products'>&lt; {lang.t('settings.products.backToProducts')}</Link>
                                    </div>
                                </div>
                                <div className="row"> 
                                    <div className="col-lg-6">
                                        <Settings 
                                            save={this.save}
                                            update={this.updateSettings}
                                            formError={formError}
                                            selected={selected}
                                            timezones={timezones}
                                            lang={lang}
                                        />
                                        <Payments 
                                            save={this.save}
                                            update={this.update}
                                            selected={selected}
                                            testPaymentKeys={this.testPaymentKeys}
                                            lang={lang}
                                        />
                                        <Webhooks
                                            save={this.save}
                                            update={this.update}
                                            policys_health_get_all={this.props.policys_health_get_all}
                                            selected={selected}
                                            lang={lang}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <EmailTemplatesTable
                                                data={Object.keys(selected.templates.emails)
                                                    .filter(email => first(email.split('_')) !== 'custom')
                                                    .filter(email => !excludedEmailTemplates.includes(email))
                                                    .map(k => {
                                                    return {
                                                        name: k,
                                                        reference: k,
                                                        enabled: get(selected, `templates.emails[${k}]enabled`, true)
                                                    }
                                                }).filter(k => k.name !== 'styles')} 
                                                section={this.section}
                                                lang={lang}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <DocTemplatesTable 
                                                data={Object.keys(selected.templates.docs)
                                                    .filter(doc => !excludedDocumentTemplates.includes(doc))
                                                    .map(k => {
                                                        return {
                                                            name: k,
                                                            reference: k,
                                                        }
                                                }).filter(k => k.name !== 'styles')} 
                                                section={this.section}
                                                lang={lang}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Email Templates */}
                        {!isFetching && !isEmpty(selected) && section === 'emails' && (
                            <EmailTemplateDetail 
                                product={selected} 
                                section={this.section}
                                reference={reference}
                                save={this.save}
                                policys_emails_post={this.props.policys_emails_post} 
                                policys_emails={this.props.policys_emails}                
                                lang={lang}
                            />
                        )}

                        {/* Document Templates */}
                        {!isFetching && !isEmpty(selected) && section === 'docs' && (
                            <DocTemplateDetail 
                                product={selected} 
                                section={this.section}
                                reference={reference}
                                save={this.save}
                                auth={this.props.auth.data}
                                lang={lang}
                            />
                        )}

                    </div>
                </div>
            </div>
        );
    }
}

export default main(ProductDetail);