import React, { Component } from 'react';
import { getLangSource } from '../../../lang';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { Parser } from 'json2csv';
import { round, get, isUndefined, uniqBy, uniq, each, find } from 'lodash'
import { getId } from '../../../utils/url';
import { getS3SignedURL } from '../../../utils/S3SignedURL';


import main from '../../../hoc/main';
import SpinnerBody from '../../../components/loaders/SpinnerBody';
import DropDown from '../../../components/forms/Dropdown';

import FactorsTable from '../components/Factors/FactorsTable';
import FactorsChart from '../components/Factors/FactorsChart';

import {
    toUpper,
    modelLabelMap,
} from '../utils/format'; 

function pick_factors(factors = []) {
    const res = [];
    each(factors, (v, k) => {
        Object.keys(v.dimensions).forEach(q => res.push(q))
    });
    return uniq(res);
}

class Analytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching_detail: false,
            factors: [],
            selected_factor: '',
            selected_model: {},
            selected_range: 'last hour',
            selected_is_valid_only: 'false'
        };
        this.getFactors = this.getFactors.bind(this);
        this.download = this.download.bind(this);
        this.getModelDetails = this.getModelDetails.bind(this);
    }

    componentWillMount() {
        const { history } = this.props;
        let id = getId(history);
        this.props.pricing_models_get_all({});
    }

    download() {
        const selected_factors = this.props.pricing_factors.selected;
        const { selected_factor } = this.state;

        const data = selected_factors.analytics[selected_factor];

        const parser = new Parser({fields:[
            'factor',
            'value',
            'volume',
            'is_valid',
            'quotability',
            'median_yearly_premium',
            'mean_yearly_premium',
            'min_yearly_premium',
            'min_yearly_premium'
        ]})
        const mapped_csv = parser.parse(data.map(d => ({
            ...d,
            factor: selected_factor,
            volume: d.count,
        })));
        const file_name = `${selected_factor}.csv`;
        
        // Create a blob of the data
        const content = new Blob([mapped_csv], {
            type: 'text/csv',
            name: selected_factor
        });
        
        // Save the file
        saveAs(content, file_name);
    }

    getFactors() {
        const {
            selected_model,
            selected_factor,
            selected_range,
            selected_is_valid_only,
        } = this.state;

        let start_at = round(moment().subtract(1, 'hour').format('X'), 0);
        let end_at = round(moment().format('X'), 0);

        if (selected_range === 'last 2 hours') {
            start_at = round(moment().subtract(2, 'hour').format('X'), 0)
        }

        if (selected_range === 'last 3 hours') {
            start_at = round(moment().subtract(3, 'hour').format('X'), 0)
        }

        this.props.pricing_factors_post({
            data: {
                model_reference: selected_model.model_reference,
                selected_factors: [selected_factor],
                is_valid_only: (selected_is_valid_only === 'false' ? false : true),
                start_at,
                end_at,
            }
        });
    }

    getModelDetails({ version_reference, model_reference }) {
        this.setState({ fetching_detail: true });
        return this.props.pricing_upload_post({
            data: {
                version_reference,
                method: 'get',
            },
        }).then((data) => {
            const signed_url = get(data, 'payload.data.signed_url');
            return getS3SignedURL({
                signed_url,
            }).then((model) =>{
                this.setState({
                    fetching_detail: false,
                    selected_model_reference: model.model_reference,
                    selected_version_reference: model.version_reference, 
                    factors: pick_factors(model.factors),
                });
            });
        });
    }

    render() {
        const {
            selected: selected_factors = {},
            isFetching: fetching_factors = false,
        } = this.props.pricing_factors;

        const {
            isFetching: fetching_models = true,
            data:data_models = []
        } = this.props.pricing_models;

        const {
            fetching_detail = false,
            factors = [],
            selected_factor,
            selected_model,
            selected_range,
            selected_is_valid_only,
        } = this.state;

        const models = uniqBy(data_models, 'model_reference');
        const analytics_models = modelLabelMap(models);
        const isFetching = fetching_models || fetching_factors || fetching_detail;

        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    {isFetching && 
                        <div className="row">
                            <SpinnerBody 
                                message={lang.t('rating.home.isBuilding')}
                                selectedProduct={this.props.selectedProduct}
                            />
                        </div>
                    }

                    {!isFetching &&
                    <div>

                        {/*
                        <div class="row mb-3">
                            <div class="col-sm-12 col-lg-6">
                                <div className="col-auto">
                                    <div className="strong">Model Name: {toUpper(selected_model.name)}</div>
                                    <div className="text-muted text-h5">Model Reference: {selected_model.model_reference}</div>
                                    <div className="text-muted text-h5 mt-2"><div className="badge bg-green">Version: {selected_model.version}</div></div>
                                </div>
                            </div>
                        </div>
                        */}

                        <div class="row mb-3">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        {lang.t('rating.factors.select')}
                                    </h3>
                                    {!isUndefined(selected_factors.analytics) && selected_factor &&
                                        <button 
                                            className="btn btn-white btn-sm"
                                            style={{
                                                top: 12,
                                                right: 126,
                                                position: 'absolute',
                                            }}
                                            onClick={this.download}
                                        >{lang.t('rating.factors.download')}</button>
                                    }
                                    {factors.length > 0 && selected_factor &&
                                        <button 
                                            className="btn btn-primary btn-sm"
                                            style={{
                                                top: 12,
                                                right: 16,
                                                position: 'absolute',
                                            }}
                                            onClick={this.getFactors}
                                        >{lang.t('rating.factors.generate')}</button>
                                    }
                                </div>
                                <div className="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-lg-3">
                                            <div class="form-label">{lang.t('rating.factors.selectModel')}</div>
                                            <DropDown
                                                ref='model_reference' 
                                                label='model_reference'
                                                value={selected_model.model_reference}
                                                update={(data) => {
                                                    this.setState({
                                                        selected_model: find(models, m => m.model_reference === data.model_reference),
                                                        factors: [],
                                                        selected_factor: '',
                                                    }, () => {
                                                        this.getModelDetails({
                                                            version_reference: this.state.selected_model.version_reference,
                                                        })
                                                    })
                                                }}
                                                labelMap={analytics_models}
                                                items={['', ...models.map(m => m.model_reference)]}
                                            />
                                        </div>
                                        {factors.length > 0 &&
                                            <div class="col-sm-12 col-lg-3">
                                                <div class="form-label">{lang.t('rating.factors.selectFactor')}</div>
                                                <DropDown
                                                    ref='selected_factor'
                                                    label='selected_factor'
                                                    value={selected_factor}
                                                    update={(data) => {
                                                        this.setState(data)
                                                    }}
                                                    items={['', ...factors]}
                                                />
                                            </div>
                                        }
                                        {factors.length > 0 &&
                                            <div class="col-sm-12 col-lg-3">
                                                <div class="form-label">{lang.t('rating.factors.selectRange')}</div>
                                                <DropDown
                                                    ref='selected_range'
                                                    label='selected_range'
                                                    value={selected_range}
                                                    update={(data) => {
                                                        this.setState(data)
                                                    }}
                                                    items={['last hour', 'last 2 hours', 'last 3 hours']}
                                                    labelMap={{
                                                        'last hour': lang.t('rating.factors.lastHour'), 
                                                        'last 2 hours': lang.t('rating.factors.lastTwoHours'), 
                                                        'last 3 hours': lang.t('rating.factors.lastThreeHours'),
                                                    }}
                                                />
                                            </div>
                                        }
                                        {factors.length > 0 &&
                                            <div class="col-sm-12 col-lg-3">
                                                <div class="form-label">{lang.t('rating.factors.isValid')}</div>
                                                <DropDown
                                                    ref='selected_is_valid_only'
                                                    label='selected_is_valid_only'
                                                    value={selected_is_valid_only}
                                                    update={(data) => {
                                                        this.setState(data)
                                                    }}
                                                    items={['true', 'false']}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!isFetching && !isUndefined(selected_factors.analytics) && selected_factor &&
                            <div class="row mb-3">
                                <div class="col-sm-12 col-lg-12">
                                    <FactorsTable 
                                        data={selected_factors.analytics[selected_factor]}
                                        selected_factor={selected_factor}
                                        results_count={selected_factors.count}
                                        lang={lang}
                                    />
                                </div>
                                <div class="col-sm-12 col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">{`${lang.t('rating.factors.quotability')} (%)`}</h3>
                                        </div>
                                        <div className="mb-3 mr-3 mt-3 ml-3">
                                            <FactorsChart
                                                data={selected_factors.analytics[selected_factor]}
                                                series={'quotability'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">{lang.t('rating.factors.yearlyPremium')}</h3>
                                        </div>
                                        <div className="mb-3 mr-3 mt-3 ml-3">
                                            <FactorsChart
                                                data={selected_factors.analytics[selected_factor]}
                                                series={'premium'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default main(Analytics);