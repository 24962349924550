import React, { Component } from 'react';
import c3 from 'c3';
import S from 'string';
import colours from '../../../../components/colours';
import 'c3/c3.css';

function reverse(data = []) {
    return data.slice(0, 12).reverse();
}

class AreaStep extends Component {
    constructor(props) {
        super(props);
        this.key = `${S(props.title).slugify().s}-chart`;
        this.chart = {};
        this.draw = this.draw.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const obj = this.draw(nextProps); 
        setTimeout(() => {
            this.chart.load({
                columns: obj.columns,
                colors: obj.colors,
            });
        }, 200)
    }

    componentDidMount () {
        const { gridlines, suffix = '' } = this.props;
        const obj = this.draw(); 
        this.chart = c3.generate({
            bindto: `#${this.key}`,
            data: {
                columns: obj.columns,
                type: 'area-step',
                colors: obj.colors,
                names: obj.names,
            },
            axis: {
                y: {
                    padding: {
                        bottom: 0,
                    },
                    show: false,
                    tick: {
                        outer: false
                    }
                },
                x: {
                    padding: {
                        left: 0,
                        right: 0
                    },
                    type: 'category',
                    categories: obj.categories,
                    show: false
                }
            },
            format: {
                title: function(x) {
                    return x + suffix;
                }
            },
            bar: {
                width: 10
            },
            legend: {
                    show: false, //hide legend
            },
            padding: {
                bottom: -8,
                left: -1,
                right: -1
            },
            grid: {
                y: {
                    lines: gridlines.map(g => {
                        return {value: g, text: `${g}${suffix}`}
                    }),
                }
            }
        });
    }

    draw(nextProps) {
        const { data = [], categories, colour } = nextProps || this.props;    
        const obj = {
            columns: [],
            colors: {},
            names: {},
            categories: reverse(categories),
        };
        const map = {
            color1: colours[`${colour}`],
            color2: colours[`${colour}light`],
            color3: colours[`${colour}lighter`],
            color4: colours[`${colour}lightest`],
            color5: colours[`${colour}darkest`],
            color6: colours[`${colour}darker`],
            color7: colours[`${colour}dark`],
        };
        data.forEach((d, i) => {
            const values = reverse(d.values);
            obj.columns.push([`data${i+1}`, ...values]);
            obj.colors[`data${i+1}`] = map[`color${i+1}`];
            obj.names[`data${i+1}`] = d.label;
        });

        return obj;
    }

    render() {
        const { title, height = 200 } = this.props;
        return (
            <div>
                { title && 
                    <div className="card-header">
                        <h3 className="card-title">{title}</h3>
                    </div>
                }
                <div id={this.key} style={{ height }}> </div>
            </div>
        );
    }
}

export default AreaStep;