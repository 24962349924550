import React, { Component } from 'react';
import AlertMessage from '../../../../components/alerts/AlertMessage';
import { uploadFile } from './S3Helpers';
import { isEmpty, round, omit } from 'lodash';
import moment from 'moment';

export default class FormUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDocumentType: 'photo',
            selectedFile: undefined,
            uploadProgress: 0,
            isUploading: false,
            file_name: '',
            photo_type: 'location',
        }
        this.addFile = this.addFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.verifyFile = this.verifyFile.bind(this);
        this.updateUploadProgress = this.updateUploadProgress.bind(this);
        this.toggleUploadStatus = this.toggleUploadStatus.bind(this);
        this.addClaimVersion = this.addClaimVersion.bind(this);
        this.setFileName = this.setFileName.bind(this);
        this.handleTagChange = this.handleTagChange.bind(this);
    }

    handleChange(e) {
        this.setState({
            selectedDocumentType: e.target.value
        })
    }

    handleTagChange(e) {
        this.setState({
            photo_type: e.target.value
        })
    }

    updateUploadProgress(e) {
        const uploadProgress = e.loaded / e.total;
        this.setState({
            uploadProgress,
        });
    }

    toggleUploadStatus() {
        this.setState({
            isUploading: !this.state.isUploading
        })
    }

    setFileName(file_name) {
        this.setState({
            file_name
        })
    }

    verifyFile(lang) {
        const { setErrorState } = this.props;
        const NO_FILE_ERROR_MSG = lang.t('policy.claims.noFileError');
        const NOT_ACCEPTABLE_ERROR_MSG = lang.t('policy.claims.notAcceptableError');
        const NO_EXTENSION_ERROR_MSG = lang.t('policy.claims.noExtensionError');
        const { selectedFile } = this.state;
        if (!selectedFile) {
            setErrorState({
                showError: true,
                errorIcon: 'folder',
                errorText: NO_FILE_ERROR_MSG
            })
            return false
        }
        const ACCEPTABLE_FILES = ['png', 'jpg', 'jpeg', 'gif', 'raw', 'tiff', 'bmp', 'mp4', 'avi', 'pdf'];
        const fileExtension = selectedFile.type.split('/').pop();
        if (!fileExtension) {
            setErrorState({
                showError: true,
                errorIcon: 'file',
                errorText: NO_EXTENSION_ERROR_MSG
            })
            return false
        } else if (!ACCEPTABLE_FILES.includes(fileExtension)) {
            setErrorState({
                showError: true,
                errorIcon: 'file',
                errorText: NOT_ACCEPTABLE_ERROR_MSG
            }) 
            return false
        } 
        return true
    }

    async addClaimVersion() {
        const { latestClaimVersion, policy_reference, claim_reference, policys_claims_put } = this.props;
        const { file_name } = this.state;
        let data;
        if(isEmpty(latestClaimVersion)){
            data = {
                claim_reference,
                policy_reference,
                update: `${file_name} was added`
            }
        } else if(latestClaimVersion.settled_date) {
            data = {
                ...(omit(latestClaimVersion, 'settled_date')),
                update: `${file_name} was added`,
                settled_date:  parseInt(moment(latestClaimVersion.settled_date).format('X'))
            }
        } else {
            data = {
                ...(omit(latestClaimVersion, 'settled_date')),
                update: `${file_name} was added`
            }
        }
        await policys_claims_put({
            id: claim_reference,
            data
        })
    }

    async addFile(lang) {
        if (!this.verifyFile(lang)) { return }
        const { 
            selectedFile, 
            selectedDocumentType,
            photo_type 
        } = this.state;

        const { 
            policys_claims_documents_post,
            policys_claims_documents_put, 
            claim_reference, 
            updateStage, 
            refreshClaimData,
        } = this.props;

        const content_type = selectedFile.type;

        try {
            this.toggleUploadStatus()
            await uploadFile({
                policys_claims_documents_post, 
                claim_reference, 
                selectedDocumentType, 
                selectedFile,
                content_type,
                setFileName: this.setFileName,
                photo_type,
                policys_claims_documents_put, 
                },
                e => this.updateUploadProgress(e));
            await this.addClaimVersion();
            await refreshClaimData(claim_reference);
        } catch(err) {
            console.log(err);
        } 
        updateStage('addFileSuccess');
    }

    render() {
        const { uploadProgress, isUploading, selectedDocumentType } = this.state;
        const { showError, errorIcon, errorText, lang } = this.props;
        const progressPercent = round(uploadProgress * 100);
        return (
            <>
                <div className='modal-body'>
                    <div className='input-group mb-2'>
                        <div className='input-group-prepend'>
                            <label className='input-group-text' style={{width: '120px'}} htmlFor='category'>
                                {lang.t('policy.claims.documentType')}
                            </label>
                        </div>
                        <select className='form-control' id='category' onChange={(e) => this.handleChange(e)}>
                            <option value='photo'>{lang.t('policy.claims.photo')}</option>
                            <option value='video'>{lang.t('policy.claims.video')}</option>
                            <option value='statement'>{lang.t('policy.claims.statement')}</option>
                            <option value='other'>{lang.t('policy.claims.other')}</option>
                        </select>
                    </div>
                    {
                        selectedDocumentType === 'photo' && 
                        <div className='input-group mb-2'>
                            <div className='input-group-prepend'>
                                <label className='input-group-text' style={{width: '120px'}} htmlFor='photoType'>
                                    {lang.t('policy.claims.photoType')}
                                </label>
                            </div>
                            <select className='form-control' id='photoType' onChange={(e) => this.handleTagChange(e)}>
                                <option value='location'>{lang.t('policy.claims.location')}</option>
                                <option value='driver'>{lang.t('policy.claims.driver.title')}</option>
                                <option value='thirdParty'>{lang.t('policy.claims.thirdParty.title')}</option>
                            </select>
                        </div>
                    }
                    <div className='input-group mb-2'>
                        <div className='input-group-prepend'>
                            <label className='input-group-text' style={{width: '120px'}} htmlFor='file'>
                                {lang.t('policy.claims.file')} *
                            </label>
                        </div>
                        <input 
                            className="form-control form-control-sm p-1" 
                            type="file" 
                            id="file"
                            onChange={(e) => this.setState({
                                selectedFile: e.target.files[0]
                            })}
                        />
                    </div>
                    {
                        showError && <AlertMessage icon={errorIcon} message={errorText} />
                    }
                    <h5 style={{ marginTop: '10px' }}>* {lang.t('policy.claims.required')}</h5>
                    <h5 style={{ marginTop: '10px' }}>{lang.t('policy.claims.acceptableFileTypes')}: png, jpg, jpeg, gif, raw, tiff, bmp, mp4, avi, pdf</h5>
                    {isUploading && <div className='progress' style={{ marginTop: '10px' }}>
                            <div class="progress-bar" role="progressbar" style={{width: `${progressPercent}%`}} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>}
                </div>
                <div className='modal-footer'>
                    <button
                        type='button'
                        className='btn btn-primary'
                        data-dismiss='modal'
                        onClick={() => this.addFile(lang)}
                    >
                        {lang.t('buttons.add')}
                    </button>
                </div>
            </>
        )
    }
}
