import React from 'react';

export default function Textarea(props) {
    const {label, value, validation, disabled=true, update, title} = props;

    const is_valid = validation ? validation(value) : '';
    let classes = 'form-control';
    if(value && validation) {
        classes = is_valid ? 'form-control is-valid' : 'form-control is-invalid'
    }

    return (
        <div className='input-group mb-2'>
            <div className='input-group-prepend'>
                <span className='input-group-text' style={{width: '140px'}}>{title}</span>
            </div>
            <textarea 
                className={classes}
                value={value}
                disabled={disabled}
                rows='3'
                onChange={(e) => update({
                    [label]: e.target.value,
                })}
            />
        </div>
    )
}