import React, { Component } from 'react';

// Service Design
import CommsTable from '../../Comms/Table';

class CommsSection extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { 
            comms = [],
            currency,
            timezone,
            lang,
        } = this.props;

        return (
            <>
            {comms.length ? (
                <div className="col-sm-12 col-lg-12">
                    <CommsTable 
                        data={comms}
                        currency={currency}
                        timezone={timezone}
                        lang={lang}
                    />
                </div>
            ) : <div>{lang.t('policy.policies.noCommunications')}</div>}
            </>

        );
    }
}

export default CommsSection;