import React from 'react'
import ReactJson from 'react-json-view'

function RawDataModal(props) {
    const { openRawData, rawDataToggle, data, lang } = props;

    return (
        <div className={`modal modal-blur fade ${openRawData ? 'show' : ''}`} style={{ display: openRawData ? 'block' : '' }} id="modal-scrollable" tabIndex="-1" role="dialog" aria-modal="true">
            <div className='modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered' role='document'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title'>{lang.t('rating.components.rawDataModal.title')}</h5>
                        <button type='button' onClick={rawDataToggle} className='close' data-dismiss='modal' aria-label='Close'>
                            <i className="fe fe-x"></i>
                        </button>
                    </div>
                    <div className='modal-body' style={{height: "70vh"}}>
                        <div className="row mb-3">
                            <div className="col-sm-12 col-lg-6">
                                <h4 style={{color: "black"}}>{lang.t('rating.components.rawDataModal.quoteDetail')}</h4>
                                <ReactJson
                                    src={data.quote}
                                    collapsed={1}
                                    theme={'rjv-default'}
                                    enableClipboard={false}
                                    displayDataTypes={false}
                                    name={false}
                                    style={{
                                        background: 'transparent',
                                        fontFamily: 'monaco',
                                        fontSize: '12px',
                                    }}/>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <h4 style={{color: "black"}}>{lang.t('rating.components.rawDataModal.pricingDetail')}</h4>
                                <ReactJson
                                    src={data.pricing}
                                    collapsed={1}
                                    theme={'rjv-default'}
                                    enableClipboard={false}
                                    displayDataTypes={false}
                                    name={false}
                                    style={{
                                        background: 'transparent',
                                        fontFamily: 'monaco',
                                        fontSize: '12px',
                                    }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RawDataModal
