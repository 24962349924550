import React, { Component } from 'react';
import moment from 'moment';
import { round } from 'lodash';
import { getNumberFormat, getDistanceFormat } from '../../../../utils/numberFormat';

class StatementsTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { segments = [], currency, distance_units, lang } = this.props;
        const distance_modifier = distance_units === 'miles' ? 1606 : 1000;

        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>  
                            <tr>
                                <th>{lang.t('tableHeaders.date')}</th>
                                <th>{lang.t('tableHeaders.statements')}</th>
                                <th>{lang.t('tableHeaders.journeys')}</th>
                                <th>{lang.t('tableHeaders.usagePremium')}</th>
                                <th>{lang.t('tableHeaders.subPremium')}</th>
                                <th>{lang.t('tableHeaders.totalPremium')}</th>
                                <th>{lang.t('tableHeaders.distance')}</th>
                                <th>{lang.t('tableHeaders.units')}</th>
                                <th>{lang.t('tableHeaders.durationMins')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {segments.slice(0, 10).map((d, i) => {
                                const {
                                    timestamp = '',
                                    count = 0,
                                    journey_count = 0,
                                    usage_premium = 0,
                                    subscription_premium = 0,
                                    total_premium = 0,
                                    distance_in_metres = 0,
                                    duration_in_mins = 0,
                                } = d;
                                const distance = round(distance_in_metres / distance_modifier, 2);

                                return (
                                <tr key={`revenue-${i}`} style={{ height: 41}}>
                                    <td data-label="Date">
                                        <div className="text-muted text-h5">{moment(timestamp, 'X').format('ll')}</div>
                                    </td>
                                    <td data-label="Count">
                                        <div className="text-h5"><b>{count}</b></div>
                                    </td>
                                    <td data-label="Journeys">
                                        <div className="text-h5"><b>{journey_count}</b></div>
                                    </td>
                                    <td data-label="UPremium">
                                        <div className="text-h5"><b>{getNumberFormat(currency, usage_premium, '$0,0.00')}</b></div>
                                    </td>
                                    <td data-label="SPremium">
                                        <div className="text-h5"><b>{getNumberFormat(currency, subscription_premium, '$0,0.00')}</b></div>
                                    </td>
                                    <td data-label="TPremium">
                                        <div className="text-h5"><b>{getNumberFormat(currency, total_premium, '$0,0.00')}</b></div>
                                    </td>
                                    <td data-label="Distance">
                                        <div className="text-muted text-h5">{getNumberFormat(currency, distance, getDistanceFormat(distance))}</div>
                                    </td>
                                    <td data-label="Distance">
                                        <div className="text-muted text-h5">{distance_units}</div>
                                    </td>
                                    <td data-label="Duration">
                                        <div className="text-muted text-h5">{getNumberFormat(currency, duration_in_mins, '0,0')}</div>
                                    </td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default StatementsTable;
