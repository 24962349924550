import React, { Component } from 'react';
import { to_upper } from '../../../../components/format/string';
import moment from 'moment-timezone';
import axios from 'axios';
import config from '../../../../config';
import { saveAs } from 'file-saver';
import { getVersionsPaidAmount } from '../../../../utils/getVersionsPaidAmount';
import { getNumberFormat, getUsageFormat } from '../../../../utils/numberFormat';

class PolicyVersionsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getDocuments = this.getDocuments.bind(this);
    }

    badge(type) {
        if (type === 'cn' || type === 'ls') return (<div className='badge bg-red'>{to_upper(type)}</div>);
        if (type === 'mta' || type === 'st' || type === 'int') return (<div className='badge bg-yellow'>{to_upper(type)}</div>);
        return (
            <div className='badge bg-green'>{to_upper(type)}</div>
        )
    }

    async getDocuments(policy_reference) {
        const { data } = this.props.auth;
        const result = await axios({
            url: `${config.services.apis.root}/documents/${policy_reference}?template=doc_policy_schedule&format=pdf`,
            method: 'get',
            headers: {
                environment: 'sandbox',
                client_id: data.client_id,
                client_secret: data.client_secret_sandbox,
            },
            responseType: 'arraybuffer',
        }).then(({ data}) => data);
        
        const pdf = new Blob([result], {type: "data:application/pdf;base64"});
        saveAs(pdf, `${policy_reference}.pdf`);
    }

    async sendEmails(data) {        
        const { policys_emails_post, policys_statements_get } = this.props;
        const {
            policy_reference,
            product_reference,
            policy_version_reference,
            version_type,
            email,
        } = data;

        const templates = {
            nb: 'email_policy_created',
            st: 'email_policy_statement_generated',
            mta: 'email_policy_mta_submitted',
            rn: 'email_policy_renew_submitted',
            cn: 'email_policy_cancel_created',
        }
        
        let postBody = {
            policy_reference,
            product_reference,
            template: templates[version_type],
            recipient_email: email,
        }
        
        if(version_type === 'mta') {
            postBody.latest_version = 'true';
        }

        if(version_type === 'st') {
            const statement = await policys_statements_get({
                id: policy_version_reference,
            });

            postBody.additional_policy_data = statement.payload.data;
        }

        try {
            await policys_emails_post({
                data: postBody,
            });
    
            this.setState({
                [data.policy_version_reference]: 'sent',
            });
        } catch {
            this.setState({
                [data.policy_version_reference]: 'failed',
            });
            
            setTimeout(() => {
                this.setState({
                    [data.policy_version_reference]: null,
                });
            }, 1500);
        }
    }

    render() {
        const {
            data = [],
            currentVersion,
            action,
            cognito,
            auth,
            show_button = false,
            payments = [],
            currency,
            timezone,
            lang,
        } = this.props;
        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.lifecycle')}</th>
                                <th>{lang.t('tableHeaders.reference')}</th>
                                <th>{lang.t('tableHeaders.reg')}</th>
                                <th>{lang.t('tableHeaders.postcode')}</th>
                                <th>{lang.t('tableHeaders.upfront')}</th>
                                <th>{lang.t('tableHeaders.paid')}</th>
                                <th>{lang.t('tableHeaders.sub')}</th>
                                <th>{lang.t('tableHeaders.usage')}</th>
                                <th>{lang.t('tableHeaders.effectiveDate')}</th>
                                {show_button &&
                                    <th className="w-2"/>                                
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((d, i) => {
                                const is_current = currentVersion &&  d.policy_version_reference === currentVersion.policy_version_reference;
                                return (
                                <tr key={`identitys-${i}`} className={`${is_current ? "bg-blue-lt" : "bg-transparent"}`}>
                                    <td>
                                        {this.badge(d.version_type)}
                                        {is_current && <div style={{marginLeft: 6 }} className="badge bg-primary">{lang.t('policy.table.current').toUpperCase()}</div>}
                                    </td>
                                    <td>
                                        <div className="text-muted text-h5">{d.policy_reference}</div>
                                    </td>
                                    <td data-label="Reg">
                                        <div className="text-muted text-h5">{d.vehicle_reg}</div>
                                    </td>
                                    <td data-label="Postcode">
                                        <div className="text-muted text-h5">{d.address_postcode}</div>
                                    </td>
                                    <td data-label="Price">
                                        <div className="text-muted text-h5">{getNumberFormat(currency, d.upfront_rate, '$0,0.00')}</div>
                                    </td>
                                    <td data-label="Paid">
                                        <div className="text-muted text-h5">{getNumberFormat(currency, getVersionsPaidAmount(payments, d.policy_version_reference), '$0,0.00')}</div>
                                    </td>
                                    <td data-label="Price">
                                        <div className="text-muted text-h5">{getNumberFormat(currency, d.subscription_rate, '$0,0.00')}</div>
                                    </td>
                                    <td data-label="Price">
                                        <div className="text-muted text-h5">{getNumberFormat(currency, d.usage_rate, getUsageFormat(d.usage_rate))}</div>
                                    </td>
                                    <td data-label="EffectiveDate">
                                        <div className="text-muted text-h5">{moment.tz(d.effective_date, timezone).format('LLL')}</div>
                                    </td>
                                    {show_button &&
                                        <td>
                                            <div className="btn-list flex-nowrap">
                                                {!this.state[d.policy_version_reference] && (
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.sendEmails(d);
                                                        }}
                                                        className="btn btn-sm btn-primary text-white"
                                                    >{lang.t('policy.table.resend')}</button>
                                                )}
                                                {this.state[d.policy_version_reference] === 'sent' && (
                                                    <button                                                        
                                                        className="btn btn-sm btn-green text-white"
                                                        style={{ opacity: '1' }}
                                                        disabled
                                                    >{lang.t('policy.table.sent')}</button>
                                                )}
                                                {this.state[d.policy_version_reference] === 'failed' && (
                                                    <button
                                                        className="btn btn-sm btn-red text-white"
                                                        style={{ opacity: '1' }}
                                                        disabled
                                                    >{lang.t('policy.table.failed')}</button>
                                                )}
                                            </div>
                                        </td>
                                    }
                                </tr>
                            )})}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default PolicyVersionsTable;