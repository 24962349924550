import jsen from 'jsen';
import { isString } from 'lodash';
import React, { Component } from 'react';
import ReactJson from 'react-json-view';
import default_schema from './data/vehicle.schema';

class Schema extends Component {
    constructor(props) {
        super(props);
        this.state = {
            json: {},
            example: {},
            type: '',
            errors: [],
            is_edit: false,
            is_open: false,
            is_custom: false
        };
        this.editSchema = this.editSchema.bind(this);
        this.updateSchema = this.updateSchema.bind(this);
        this.validate = this.validate.bind(this);
        this.clear = this.clear.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.revertToDefault = this.revertToDefault.bind(this);
    }

    componentDidMount() {
        const { json, example } = default_schema;
        const { type } = this.props || '';

        if (type === "custom") {
            return this.setState({
                json: this.props.json,
                example: this.props.example,
                type: 'custom',
                is_custom: true,
            });
        }

        this.setState({
            json,
            example,
        }, () => this.props.updateSchema({
            json: this.state.json,
            example: this.state.example,
        }));
    }

    validate({
        json,
        example,
    }) {
        let validate;
        let errors = [];
        try {
            validate = jsen(json);
            validate(example);
            errors = validate.errors || [];
        } catch(e) {
            errors.push(e.message);
        }
        this.setState({
            errors, 
        });
    }

    editSchema({
        json,
        example,
        lang,
    }) {
        try {
            if (isString(json)) json = JSON.parse(json);
        } catch(e) {
            return this.setState({
                errors: lang.t('rating.components.schema.errorJsonFormat'), 
            });
        }

        try {
            if (isString(example)) example = JSON.parse(example);
        } catch(e) {
            return this.setState({
                errors: lang.t('rating.components.schema.errorQuoteFormat'), 
            });
        }

        this.setState({
            json,
            example,
        }, () => {
            this.validate({
                json,
                example,
            });
        });
    }

    updateSchema() {
        const { errors, json, example } = this.state;
        if (errors.length === 0) {
            this.props.updateSchema({ 
                json,
                example,
                type: "custom",
            });
        }
    }

    clear() {
        if(this.state.is_edit) {
            const is_custom = this.props.type === "custom" ? true : false;
            this.setState({
                is_edit: false,
                json: this.props.json,
                example: this.props.example,
                errors: [],
                type: this.props.type,
                is_custom,
            });
        } else {
            this.setState({
                is_open: false,
            })
        }
    }

    handleClick(e) {
        e.preventDefault();
        const { is_edit, is_open, errors  } = this.state;
        // Initial click to view schema
        if (!is_edit) {
            this.setState({
                is_open: true
            })
        }
        // Upon opening schema view, user can now edit the schema
        if(is_open){
            this.setState({
                is_edit: true,
                is_custom: true,
            });
        }
        // In edit mode user can save if valid schema and quote example
        if (is_edit && errors.length === 0) {
            this.updateSchema();
            this.setState({
                is_edit: false,
            });
        }
    }

    revertToDefault(e) {
        e.preventDefault();
        this.setState({
                json: default_schema.json,
                example: default_schema.example,
                type: '',
                is_edit: false,
                is_custom: false,
            },
            // Once state has updated, update the model with the default schema
            () => this.props.updateSchema({
                json: this.state.json,
                example: this.state.example,
            }));
    }

    render() {

        const { json = {}, example = {}, is_edit = false, errors = [], is_open, is_custom } = this.state;
        const lang = this.props.lang;
        return (
            <div className="row mb-3">
                <div className="col-sm-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">{lang.t('rating.components.schema.title')}</h3>
                            <button type="button" 
                                    className={`btn ${!is_custom ? 'btn-light disabled' : 'btn-success text-light'} btn-sm`}
                                    style={{position: 'absolute', top: '14px', left: '150px'}}
                            >
                                {is_custom ? lang.t('buttons.custom') : lang.t('buttons.default')}
                            </button>
                            { is_custom && is_edit &&
                                <button type="button"
                                        className="btn btn-light btn-sm"
                                        style={{position: 'absolute', top: '14px', left: '220px'}}
                                        onClick={(e) => this.revertToDefault(e)}
                                >
                                {lang.t('rating.components.schema.revertToDefault')}
                            </button>
                            }
                            <a 
                                className={`btn ${errors.length === 0 ? 'btn-primary' : 'btn-red'} btn-sm`}
                                style={{ position: 'absolute', top: '14px', right: `${(is_edit || is_open) ? '60px' : '20px'}`}}
                                onClick={(e) => this.handleClick(e)}
                            >
                                { !is_open && !is_edit ? lang.t('rating.components.schema.viewSchema') : (is_edit ? lang.t('buttons.save') : lang.t('rating.components.schema.editSchema')) }
                            </a>
                            { (is_edit || is_open) &&
                                <button type="button" onClick={this.clear} 
                                        style={{ position: 'absolute', top: '14px', right: '20px'}}
                                        className="close"
                                >
                                    <i className="fe fe-x"></i>
                                </button>
                            }
                            
                        </div>
                        <div className="row mr-3 ml-3 mt-3">
                            <div className="alert alert-info" role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                {lang.t('rating.components.schema.schemaInfo')}
                            </div>
                        </div>
                        {is_edit &&
                            <div className="row ml-3 mr-3">
                                <div className="col-sm-12 col-lg-6">
                                    <div className="card-header">
                                        <h5 className="card-title" style={{ fontSize: 14 }}>{lang.t('rating.components.schema.jsonSchema')}</h5>
                                    </div>
                                    <div className="card-body">
                                        <textarea
                                            key={'json-schema'}
                                            className="form-control"
                                            data-toggle="autosize"
                                            placeholder={lang.t('rating.components.schema.jsonPlaceholder')}
                                            spellCheck="false"
                                            style={{
                                                background: '#354052',
                                                borderRadius: '3px',
                                                height: 400,
                                                color: '#fff',
                                            }}
                                            onBlur={(e) => {
                                                const { value } = e.target;
                                                this.editSchema({
                                                    example,
                                                    json: value,
                                                    lang,
                                                });
                                            }}
                                            defaultValue={JSON.stringify(json, null, 4)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6">
                                    <div className="card-header">
                                        <h5 className="card-title" style={{ fontSize: 14 }}>{lang.t('rating.components.schema.quoteExample')}</h5>
                                    </div>
                                    <div className="card-body">
                                        <textarea
                                            key={'json-example'}
                                            className="form-control"
                                            data-toggle="autosize"
                                            placeholder={lang.t('rating.components.schema.quotePlaceholder')}
                                            spellCheck="false"
                                            style={{
                                                background: '#354052',
                                                borderRadius: '3px',
                                                height: 400,
                                                color: '#fff',
                                            }}
                                            onBlur={(e) => {
                                                const { value } = e.target;
                                                this.editSchema({
                                                    example: value,
                                                    json,
                                                    lang,
                                                });
                                            }}
                                            defaultValue={JSON.stringify(example, null, 4)}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {is_open && !is_edit &&
                        <div className="row mb-3 ml-3 mr-3">
                            <div className="col-sm-12 col-lg-6">
                                <div className="card-header">
                                    <h5 className="card-title" style={{ fontSize: 14 }}>{lang.t('rating.components.schema.jsonSchema')}</h5>
                                </div>
                                <div className="card-body">
                                    <ReactJson
                                            src={json}
                                            theme={'monokai'}
                                            enableClipboard={false}
                                            displayDataTypes={false}
                                            name={false}
                                            style={{
                                                background: 'transparent',
                                                fontFamily: 'monaco',
                                                fontSize: '12px',
                                                height: 300,
                                                overflowY: 'scroll',
                                            }}
                                        />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <div className="card-header">
                                    <h5 className="card-title" style={{ fontSize: 14 }}>{lang.t('rating.components.schema.quoteExample')}</h5>
                                </div>
                                <div className="card-body">
                                    <ReactJson
                                        src={example}
                                        theme={'monokai'}
                                        enableClipboard={false}
                                        displayDataTypes={false}
                                        name={false}
                                        style={{
                                            background: 'transparent',
                                            fontFamily: 'monaco',
                                            fontSize: '12px',
                                            height: 300,
                                            overflowY: 'scroll',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        }
                        { is_edit &&
                            <div className="row mb-3 ml-3 mr-3">
                                <div className="col">
                                    <table className="table card-table table-vcenter">
                                        <thead>
                                            <tr>
                                                <th>{lang.t('rating.components.schema.test')}</th>
                                                <th>{lang.t('rating.components.schema.result')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-truncate">
                                                    {errors.length === 0 &&
                                                    <div>
                                                        {lang.t('rating.components.schema.validate')}
                                                    </div>
                                                    }
                                                    {errors.length > 0 &&
                                                        <div style={{color: 'pink'}}>
                                                            {JSON.stringify(errors, null, 4)}
                                                        </div>
                                                    }
                                                </td>
                                                <td className="w-1 text-center">
                                                    {errors.length === 0 &&
                                                        <span className="avatar bg-green">
                                                            <i style={{color: 'green'}} className="fe fe-check"></i>
                                                        </span>
                                                    }
                                                    {errors.length > 0 &&
                                                        <span className="avatar bg-red">
                                                            <i style={{color: 'red'}} className="fe fe-x"></i>
                                                        </span>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div> 
                </div>
            </div>
        );
    }
}

export default Schema;