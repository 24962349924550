import React, { Component } from 'react';
import { reject } from 'lodash';
import ModelExclusionsTable from './ExclusionsTable';

class Exclusions extends Component {
    constructor(props) {
        super(props);
        this.editExclusion = this.editExclusion.bind(this);
        this.deleteExclusion = this.deleteExclusion.bind(this);
    }

    editExclusion(exclusion) {
        this.props.openModal({
            exclusions: {
                open: true,
                name: exclusion,
            }
        });
    }

    deleteExclusion(exclusion) {
        this.props.deleteExclusion(reject(this.props.exclusions, e => e.name === exclusion));
    }

    render() {
        const { exclusions, lang } = this.props;
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">{lang.t('rating.components.exclusions.title')}
                                <a href="#"  style={{
                                    position: 'absolute',
                                    top: 14,
                                    right: 20,
                                }} onClick={(e) => { 
                                    e.preventDefault();
                                    this.editExclusion(''); 
                                }}  data-toggle="modal" data-target="#modal-exclusion" className="btn btn-primary btn-sm">{lang.t('rating.components.exclusions.create')}</a>
                            </h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        {lang.t('rating.components.exclusions.exclusionInfo')}
                                    </div>
                                    <ModelExclusionsTable 
                                        data={exclusions}
                                        edit={this.editExclusion}
                                        delete={this.deleteExclusion}
                                        pages={10}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Exclusions;