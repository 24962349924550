import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import config from '../../../../config';

class EmailTemplatesTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], section, lang } = this.props;
        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('settings.products.emailTemplate')}</th>
                                <th>{lang.t('settings.products.status')}</th>
                                <th className="w-1"/>
                            </tr>
                        </thead>
                        <tbody>
                            {/*
                            <tr key={`email-template`}>
                                <td data-label="Total">
                                    <div className="text-h5">Layout (Header, Footer)</div>
                                </td>
                                <td data-label="Total">
                                    &nbsp;
                                </td>
                                <td>
                                    <div className="btn-list flex-nowrap">
                                        <a className="btn btn-sm btn-primary text-white">Edit</a>
                                    </div>
                                </td>
                            </tr>
                            */}
                            {data.map((d, i) => (
                                <tr key={`email-template-${i}`}>
                                    <td data-label="Total">
                                        <div className="text-h5">{d.name}</div>
                                    </td>
                                    <td data-label="Total">
                                        {d.enabled && <div className="badge bg-green">{lang.t('buttons.enabled')}</div>}
                                        {!d.enabled && <div className="badge bg-red">{lang.t('buttons.disabled')}</div>}
                                    </td>
                                    <td>
                                        <div className="btn-list flex-nowrap">
                                            <a 
                                                className="btn btn-sm btn-primary text-white"
                                                onClick={() => {
                                                    section({
                                                        section: 'emails',
                                                        reference: d.reference,
                                                    })
                                                }}
                                            >{lang.t('buttons.edit')}</a>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default EmailTemplatesTable;
