import React, { Component } from 'react';
import { to_upper } from '../../../../components/format/string';
import moment from 'moment-timezone';

class CommsTable extends Component {
    constructor(props) {
        super(props);
    }

    badge(event) {
        return (
            <div className='badge bg-green'>{to_upper(event)}</div>
        )
    }

    render() {
        const {
            data = [],
            currency,
            timezone,
            lang,
        } = this.props;
        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.event')}</th>
                                <th>{lang.t('tableHeaders.email')}</th>
                                <th>{lang.t('tableHeaders.subject')}</th>
                                <th>{lang.t('tableHeaders.sentDate')}</th>
                                {/*<th className="w-2"/>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((d, i) => {
                                return (
                                <tr key={`comms-${i}`}>
                                    <td>
                                        {this.badge(d.event)}
                                    </td>
                                    <td data-label="email">
                                        <div className="text-muted text-h5">{d.email}</div>
                                    </td>
                                    <td data-label="subject">
                                        <div className="text-muted text-h5">{d.subject}</div>
                                    </td>
                                    <td data-label="date">
                                        <div className="text-muted text-h5">{moment.tz(d.created_at, timezone).format('LLL')}</div>
                                    </td>
                                    {/*
                                    <td>
                                        <div className="btn-list flex-nowrap">
                                            <a
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                }}
                                                className="btn btn-sm btn-primary text-white"
                                            >View</a>
                                        </div>
                                    </td>
                                    */}
                                </tr>
                            )})}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default CommsTable;