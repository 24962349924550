import React, { Component } from 'react';
import Avatar from '../../../../components/avatar';
import Input from '../../../../components/forms/Input';
import DropDown from '../../../../components/forms/Dropdown';

class UsersForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                f_names: '',
                l_names: '',
                username: '',
                email: '',
                role: '',
                policy: '',
                active: 'true',
            }
        }
        this.updateDetails = this.updateDetails.bind(this);
        this.submit = this.submit.bind(this);
    }

    updateDetails(data) {
        this.setState({
            form: {
                ...this.state.form,
                ...data
            }
        });
    }

    validationOnSubmit() {

    }

    submit(data) {
        this.props.users_post({
            data: this.state.form,
        }).then(() => this.props.close)
    }

    render() {
        const { lang } = this.props;
        const roles = Object.keys(lang.lang.roles);
        const policies = [
            'apple-grape-orange',
        ];
        const {
            f_names,
            l_names,
            username,
            email,
            role,
            active,
            policy,
        } = this.state.form;
        return (
            <div className="card">
                <div className="row mt-3">
                    <div className="col-sm-12 col-lg-4">
                        <fieldset className="form-fieldset">
                            <div className="mb-3">
                                <label className="form-label required">{lang.t('settings.users.firstNames')}</label>
                                <Input
                                    ref={'f_names'}
                                    value={f_names}
                                    label={'f_names'}
                                    update={this.updateDetails}
                                    validation={(value) => {
                                        return value.length > 3;
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label required">{lang.t('settings.users.lastNames')}</label>
                                <Input
                                    ref={'l_names'}
                                    value={l_names}
                                    label={'l_names'}
                                    update={this.updateDetails}
                                    validation={(value) => {
                                        return value.length > 3;
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label required">{lang.t('settings.users.username')}</label>
                                <Input
                                    ref={'username'}
                                    label={'username'}
                                    value={username}
                                    update={this.updateDetails}
                                    validation={(value) => {
                                        return value.length > 3;
                                    }}
                                />
                            </div>
                        </fieldset>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                        <fieldset className="form-fieldset">
                            <div className="mb-3">
                                <label className="form-label required">{lang.t('settings.users.email')}</label>
                                <Input
                                    ref={'email'}
                                    label={'email'}
                                    value={email}
                                    update={this.updateDetails}
                                    validation={(value) => {
                                        return value.length > 3;
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label required">{lang.t('settings.users.role')}</label>
                                <DropDown
                                    ref={'role'}
                                    label={'role'}
                                    value={role}
                                    update={this.updateDetails}
                                    items={['', ...roles]}
                                    validation={(value) => {
                                        return value.length > 3;
                                    }}
                                />
                                
                            </div>
                            {/*<div className="mb-3">
                                <label className="form-label required">Enabled</label>
                                <DropDown
                                    ref={'active'}
                                    label={'active'}
                                    value={active}
                                    update={this.updateDetails}
                                    items={['true', 'false']}
                                    validation={(value) => {
                                        return value.length > 3;
                                    }}
                                />
                            </div>*/}
                            {role === 'policyholder' &&
                            <div className="mb-3">
                                <label className="form-label required">{lang.t('settings.users.assignToPolicy')}</label>
                                <DropDown
                                    ref={'policy'}
                                    label={'policy'}
                                    value={policy}
                                    update={this.updateDetails}
                                    items={['', ...policies]}
                                    validation={(value) => {
                                        return value.length > 3;
                                    }}
                                />
                                </div>
                            }
                        </fieldset>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="row row-sm align-items-center">
                                    <div className="col-auto">
                                        <Avatar 
                                            f_names={f_names}
                                            l_names={l_names}
                                        />
                                    </div>
                                    <div className="col">
                                        <h3 className="mb-0"><a href="#">{f_names} {l_names}</a></h3>
                                        <div className="text-muted text-h5">{email}</div>
                                        <div className="text-muted text-h5">{username}</div>
                                    </div>
                                    <div className="col-auto lh-1 align-self-start">
                                        <span className="badge bg-cyan-lt">
                                            {role}
                                        </span>
                                    </div>
                                </div>
                                <div className="row align-items-center mt-4">
                                    <div className="col">
                                    </div>
                                    <div className="col-auto">
                                        <div className="btn-list">
                                            {active === 'true' &&
                                                <a href="#" className="btn btn-green text-white btn-sm">
                                                    {lang.t('buttons.enabled')}
                                                </a>
                                            }
                                            {active === 'false' &&
                                                <a href="#" className="btn btn-red text-white btn-sm">
                                                    {lang.t('buttons.disabled')}
                                                </a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <a className="btn btn-primary text-white" onClick={(e) => {
                                e.preventDefault();
                                this.submit();
                            }}>{lang.t('settings.users.createUser')}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UsersForm;
