import React, { Component } from 'react';
import Input from '../../components/forms/Input';

class Form_Password extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            showPassword: false,

        };
        this.update = this.update.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleKeyPress(e) {
        if (e.keyCode !== 13) return;

        const { username, password } = this.state;

        this.props.submitAutocrat({
            username,
            password,
            type: 'USER_PASSWORD_AUTH'
        });
    }

    update(data) {
        this.setState(data);
    };

    render() {
        const { username, password, showPassword } = this.state;
        const { lang, isFetching } = this.props;
        return (
            <form className="card card-md" style={{ 
                boxShadow: '0px 0px 26px 12px rgba(0,0,0,0.15)'
            }}>
                <div className="card-body" onKeyDown={this.handleKeyPress}>
                    
                    <div className="mb-3">
                        <label className="form-label">{lang.t('login.username')}</label>
                        <Input
                            label={'username'}
                            value={username}
                            update={this.update}
                            validation={(value) => {
                                return value.length > 100;
                            }}
                        />
                    </div>

                    <div className="mb-2">
                        
                        <label className="form-label">
                            {lang.t('login.password')}
                            {/*
                            <span className="form-label-description">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.props.setAutocratType('FORGOT_PASSWORD');
                                }}
                                >
                                    {lang.t('login.forgotPassword')}
                                </a>
                            </span>
                            */}
                        </label>

                        <div className="input-group input-group-flat">
                            <Input
                                label={'password'}
                                value={password}
                                type={showPassword === true ? 'text' : 'password'}
                                update={this.update}
                                validation={(value) => {
                                    return value.length > 100;
                                }}
                            />
                            <span className="input-group-text">
                                <a href="#" 
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.update({
                                            showPassword: !showPassword,
                                        });
                                    }} 
                                    className="link-secondary" 
                                    title="Show password"
                                    data-toggle="tooltip"
                                >
                                    <i className="fe fe-eye" />
                                </a>
                            </span>
                        </div>
                    </div>
                    <div className="form-footer" style={{textAlign:'right'}}>
                        {!isFetching &&
                            <button type="submit" style={{ marginRight: 10 }}  onClick={(e) => {
                                e.preventDefault();
                                this.props.setAutocratType('FORGOT_PASSWORD');
                            }} className="skew-button black_secondary small">
                                <span><span>{lang.t('login.forgotPassword')}</span></span>
                            </button>
                        }

                        {!isFetching &&
                            <button onClick={(e) => {
                                e.preventDefault();
                                this.props.submitAutocrat({
                                    username,
                                    password,
                                    type: 'USER_PASSWORD_AUTH'
                                });
                            }} className="skew-button black_primary small">
                                <span><span>{lang.t('login.signIn')}</span></span>
                            </button>
                        }
                        
                        {isFetching &&
                            <div 
                                className="spinner-border text-black" 
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                    marginLeft: 'calc(50% - 10px)'
                            }} role="status" />
                        }
                    </div>
                </div>
            </form>
        );
    }
}
export default Form_Password;
