import React, { Component } from 'react';
import { first } from 'lodash';

import getCurrencySymbol from '../../../../utils/currencySymbol';
import { toUpper } from '../../../rating/utils/format';

class RefundsTable extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { refundsData, products, lang } = this.props;

        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.policyReference')}</th>
                                <th>{lang.t('tableHeaders.product')}</th>
                                <th>{lang.t('tableHeaders.versionType')}</th>
                                <th>{lang.t('tableHeaders.date')}</th>
                                <th>{lang.t('tableHeaders.refundAmount')}</th>
                                <th>{lang.t('tableHeaders.paymentMethod')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                refundsData.map((r, i) => {
                                    const { 
                                        policy_reference,
                                        product_reference,
                                        version_type,
                                        effective_date,
                                        refund_amount,
                                        payment_method,
                                        proposer_first_names,
                                        proposer_last_names,
                                    } = r;

                                    const { currency } = first(products.filter(p => p.product_reference === product_reference))

                                    return(
                                        <tr key={`refunds-${i}`}>
                                            <td data-label="policy reference">
                                                <div className="d-flex lh-sm py-1 align-items-center">
                                                    <div className="flex-fill">
                                                        <div className="strong">{toUpper(proposer_first_names)} {toUpper(proposer_last_names)}</div>
                                                        <div className="text-muted text-h5">{toUpper(policy_reference)}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td data-label="product reference">
                                                <div className="text-muted text-h5">{product_reference}</div>
                                            </td>
                                            <td data-label="version type">
                                                <span className="badge bg-blue btn-block">{version_type}</span>
                                            </td>
                                            <td data-label="date">
                                                <div className="text-muted text-h5">{effective_date}</div>
                                            </td>
                                            <td data-label="refund amount">
                                                <div className="text-muted text-h5">{`${getCurrencySymbol(currency)} ${refund_amount}`}</div>
                                            </td>
                                            <td data-label="payment_method">
                                                <div className="text-muted text-h5">{payment_method}</div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        );
    };
};

export default RefundsTable;