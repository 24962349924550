import React from 'react';

export default function InputCustom({label, value}) {
    return (
        <>
            <label className='ml-1'>
                {label}
            </label>
            <input
                    type='text'
                    className='form-control mb-3'
                    value={value}
                    disabled
            >
            </input>
        </>
    );
}
