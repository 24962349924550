import React, { Component } from 'react';
import { get, find, max } from 'lodash';
import { updateCollection } from '../../../../../utils/manipulate';

class ModalTests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            test: {
                name: '',
                quote_reference: '',
            },
            valid_ref: false,
        }
        this.update = this.update.bind(this);
        this.clear = this.clear.bind(this);
        this.save = this.save.bind(this);
        this.validate_ref = this.validate_ref.bind(this);
        this.onblur_quote_ref = this.onblur_quote_ref.bind(this);
    }

    componentWillMount() {
        const { name, tests } = this.props;
        let test = find(tests, f => f.name === name) || {};
        this.setState({
            test,
        });
    }

    componentWillReceiveProps(nextProps) {
        //This only gets triggered if it is the first time clicking on the edit test button
        //where the edit test case info is passed into props but the test modal state is blank
        if (nextProps.name && !this.props.name) {
            const { name, tests } = nextProps;
            let test = find(tests, f => f.name === name) || {};
            this.setState({
                test,
                valid_ref: true,
            });
        }
    }

    clear() {
        this.setState({
            test: {},
            valid_ref: false,
        });
        //Without openModal set to false, there are two modals open when editing
        this.props.openModal({
            tests: {
                open: false,
                name: '',
            }
        })
    }

    update(e) {
        const { name, value } = e.target;
        this.setState({
            test: {
                ...this.state.test,
                [name]: value
            }
        });
    }

    save() {
        const newTest = {
            ...this.state.test,
            id: this.state.test.id || max(this.props.tests.map(e => e.id)) + 1 || 1, //starts at 1 if not populated
        };
        const newTests = updateCollection(this.props.tests, newTest);
        this.props.update(newTests);
        this.clear();
    }

    async validate_ref(quote_ref) {
        if (quote_ref) {
            try {
                const validate_quote = await this.props.pricing_quotes_get({ id: quote_ref });
                const quote_ref_chk = get(validate_quote, 'payload.data.quote.quote_reference', '');
    
                if (quote_ref_chk == quote_ref) {
                    this.setState({
                        valid_ref: true
                    });
                } else {
                    this.setState({
                        valid_ref: false
                    });
                }
            } catch(e) {
                console.log(e.message);
                this.setState({
                    valid_ref: false
                });
            }
        }
    }

    async onblur_quote_ref(e) {
        this.update(e);
        await this.validate_ref(e.target.value);
    }

    render() {
        const { open, lang } = this.props;
        const { test: { name, quote_reference }, valid_ref } = this.state;
        return (
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-tests" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{lang.t('rating.components.tests.modalTitle')}</h5>
                            <button type="button" onClick={this.clear} className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        {lang.t('rating.components.tests.modalInfo')}
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" style={{ width: 140 }}>
                                                                {lang.t('rating.components.tests.name')}
                                                            </span>
                                                        </div>
                                                        <input type='text' onBlur={
                                                            (e) => this.update(e)
                                                            } key={name} name="name" className="form-control" defaultValue={name} placeholder={lang.t('rating.components.tests.namePlaceholder')}
                                                        />
                                                    </div>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" style={{ width: 140 }}>
                                                                {lang.t('rating.components.tests.quoteRef')}
                                                            </span>
                                                        </div>
                                                        <input type='text' onBlur={
                                                            (e) => this.onblur_quote_ref(e)
                                                            } key={quote_reference} className="form-control" name="quote_reference" defaultValue={quote_reference}  placeholder={lang.t('rating.components.tests.quoteRefPlaceholder')}
                                                        />
                                                        <div className="input-group-append" style={{ marginLeft: 5, marginTop: 3 }}>
                                                            { valid_ref ?
                                                                <span className="avatar bg-green">
                                                                    <i style={{color: 'white'}} className="fe fe-check"></i>
                                                                </span>
                                                                :
                                                                <span className="avatar bg-red">
                                                                    <i style={{color: 'red' }} className="fe fe-x"></i>
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {
                                valid_ref && <button type="button" onClick={this.save} className="btn btn-primary" data-dismiss="modal">{lang.t('buttons.saveChanges')}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalTests;