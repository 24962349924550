import React, { Component } from 'react';
import InputCustom from '../../Inputs/InputCustom';

export default class FormCustom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFilter: true
        }
        this.toggleFilter = this.toggleFilter.bind(this);
        this.CUSTOM_FIELDS_TO_SHOW = 5;
    }

    toggleFilter() {
        this.setState({ 
            isFilter: !this.state.isFilter 
        });
    }

    filterData(data) {
        return data.filter((item, index) => {
            return this.state.isFilter ? index < this.CUSTOM_FIELDS_TO_SHOW : item
        })
    }

    render() {
        const { data, lang }  = this.props;
        const { isFilter } = this.state;
        const customKeys = Object.keys(data || []) ;
        const lengthOfCustom = customKeys.length;

        return ( 
            <div className='card ml-2'>
                <div className='card-header'>
                    <h3 className='card-title'>
                        {lang.t('rating.components.formCustom.title')}
                    </h3>
                    {lengthOfCustom > this.CUSTOM_FIELDS_TO_SHOW &&
                        <button
                            className="btn btn-secondary btn-sm"
                            style={{ position: 'absolute', right:'16px' }}
                            onClick={this.toggleFilter}
                        >
                            {isFilter ? lang.t('rating.components.formCustom.showAll') : lang.t('rating.components.formCustom.collapse')}
                        </button>
                    }
                </div>
                <div className='card-body'>
                    <div className='mb-1'>
                        {this.filterData(customKeys).map(item => {
                            return (
                                <div className='row'>
                                    <div className='col'>
                                        <InputCustom label={lang.t(`rating.components.formCustom.${item.replaceAll('_', '')}`)} value={data[item]} />
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div> 
            </div>
        );
    }
}