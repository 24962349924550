import React from 'react';
import Input from '../../Inputs/Input';
import { parseDate } from '../../utils/formatDate';
import TableClaims from '../Tables/TableClaims';
import TableConvictions from '../Tables/TableConvictions';

export default function FormDriver(props) {
    const {
        title,
        first_names,
        last_names,
        dob,
        email,
        mobile_number,
        children,
        licence_type,
        licence_issued_date,
        ncd,
        employment,
        business,
        occupation,
        has_criminal_conviction,
        has_medical_conditions,
        has_informed_dvla_medical_conditions, 
        claims,
        convictions
    } = props.data;
    const { timezone } = props.timezone;

    const formattedLicenceDate = parseDate(licence_issued_date, 'DD/MM/YYYY', timezone);
    const formattedDOB = parseDate(dob, 'DD/MM/YYYY', timezone);
    const lang = props.lang;

    return (
        <div className='card-body'>
            <div className='mb-1'>
                <Input
                    label={lang.t('rating.components.formDriver.title')}
                    value={title}
                />
                <Input
                    label={lang.t('rating.components.formDriver.firstName')}
                    value={first_names}
                />
                <Input
                    label={lang.t('rating.components.formDriver.lastName')}
                    value={last_names}
                />
                <Input
                    label={lang.t('rating.components.formDriver.dob')}
                    value={formattedDOB}
                />
                <Input
                    label={lang.t('rating.components.formDriver.email')}
                    value={email}
                />
                <Input
                    label={lang.t('rating.components.formDriver.phone')}
                    value={mobile_number}
                />
                <Input
                    label={lang.t('rating.components.formDriver.children')}
                    value={children}
                />
                <Input
                    label={lang.t('rating.components.formDriver.licenseType')}
                    value={licence_type}
                />
                <Input
                    label={lang.t('rating.components.formDriver.licenseIssueDate')}
                    value={formattedLicenceDate}
                />
                <Input
                    label={lang.t('rating.components.formDriver.noClaimsDiscount')}
                    value={ncd}
                />
                <Input
                    label={lang.t('rating.components.formDriver.employment')}
                    value={employment}
                />
                <Input
                    label={lang.t('rating.components.formDriver.business')}
                    value={business}
                />
                <Input
                    label={lang.t('rating.components.formDriver.occupation')}
                    value={occupation}
                />
                <Input
                    label={lang.t('rating.components.formDriver.conv')}
                    value={has_criminal_conviction}
                />
                <Input
                    label={lang.t('rating.components.formDriver.med')}
                    value={has_medical_conditions}
                />
                <Input
                    label={lang.t('rating.components.formDriver.dvla')}
                    value={has_informed_dvla_medical_conditions}
                />
                <hr />
                <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                    <b>{lang.t('rating.components.formDriver.claims')}</b>
                </div>
                <TableClaims
                    data={claims}
                    lang={lang}
                />
                <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                    <b>{lang.t('rating.components.formDriver.convictions')}</b>
                </div>
                <TableConvictions
                    data={convictions}
                    lang={lang}
                />
            </div>
        </div>
    )
}
