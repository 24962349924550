import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import config from '../../../../config';

class DocTemplatesTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], section, lang } = this.props;
        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('settings.products.documentTemplate')}</th>
                                <th className="w-1"/>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((d, i) => (
                                <tr key={`doc-template-${i}`}>
                                    <td data-label="Total">
                                        <div className="text-h5">{d.name}</div>
                                    </td>
                                    <td>
                                        <div className="btn-list flex-nowrap">
                                            <a 
                                                className="btn btn-sm btn-primary text-white"
                                                onClick={() => {
                                                    section({
                                                        section: 'docs',
                                                        reference: d.reference,
                                                    })
                                                }}
                                            >{lang.t('buttons.edit')}</a>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default DocTemplatesTable;
