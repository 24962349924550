import { get } from 'lodash';
import React, { Component } from 'react';
import { extendedDateFormat } from '../../utils/format';
import { Link } from 'react-router-dom';

class PolicyModal extends Component {
    constructor(props) {
        super(props);
        this.handleHover = this.handleHover.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
        this.get_policy = this.get_policy.bind(this);
        this.state = {
            show: false,
        }
    }

    async get_policy() {
        if (!this.props.policys_get_all) return console.log('No Policy Get defined');
        const policy = await this.props.policys_get_all({
            policy_reference: this.props.policy_reference,
        }).then((data) => {
            const policy = get(data, 'payload.data[0]');
            return policy;
        });
        this.setState({
            show: true,
            policy,
        });
    }


    handleHover () {
        this.timeout = setTimeout(async() => {
            await this.get_policy();
        }, 350)
    }
    
    handleLeave () {
        this.setState({
            show: false,
        }, () => {
            clearTimeout(this.timeout);
        })
    }

    render() {
        const { policy_reference, lang } = this.props;
        const { show, policy = {} } = this.state
        return(
            <div className="policy_modal" style={{ position: 'relative' }}>
                <div
                    onMouseOver={this.handleHover} 
                    onMouseLeave={this.handleLeave}
                >
                    <Link
                        to={`/policys/detail/${policy_reference}`}
                        className="btn btn-sm btn-primary"
                    >{policy_reference}</Link>
                </div>
                <div className="policy_modal_content" style={{ 
                    display: show ? 'block' : 'none',
                }}>
                    <ul>
                        <li class="header">{lang.t('components.modal.policy.name')} <b>{policy.proposer_first_names} {policy.proposer_last_names}</b></li>
                        <li>{lang.t('components.modal.policy.reg')} <b>{policy.vehicle_reg}</b></li>
                        <li>{lang.t('components.modal.policy.postcode')} <b>{policy.address_postcode}</b></li>
                        <li>{lang.t('components.modal.policy.start')} <b>{extendedDateFormat(policy.start_date)}</b></li>
                    </ul>
                </div>
            </div>
        );
    }
};

export default PolicyModal;