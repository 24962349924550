import React, { Component } from 'react';
import L from '1liner';

class Filter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            count: 0,
        }
        this.query = this.query.bind(this);
    }

    query(e) {
        const query = e.target.value;
        if (query.length < 3) return;
        const { data } = this.props;
        const results = data.filter(d => d.policy_ref.includes(query));
        try {
            this.props.setFiltered(results);
            this.setState({ error: null, count: results.length });
        } catch(e) {
            this.setState({
                error: e.message,
                count: 0,
            });
        }
    }

    render() {
        const { error, count } = this.state
        return (
            <div>
                <div className="input-icon">
                    <input type="text" 
                        onKeyUp={this.query} 
                        className="form-control form-control-rounded"
                        placeholder="Search policies..."
                    />
                    <span className="input-icon-addon" onClick={() => this.help()}>
                        {!error && count=== 0 && <i className="fe fe-search"></i>}
                        {error && <span style={{marginRight: 20}} class="badge bg-red">Error</span>}
                        {!error && count > 0 && <span style={{marginRight: 20}} class="badge bg-green">Found: {count}</span> }
                    </span>
                </div>

                {error && 
                    <div style={{marginTop: 10}} className="alert alert-danger" role="alert">
                        {error}
                    </div>
                }

            </div>
        );
    }
}

export default Filter;