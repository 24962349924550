import jsen from 'jsen';

const schema = {
    type: 'object',
    properties: {
        meta: {
            type: 'object',
            properties: {
                name: {
                    type: 'string',
                },
                version: {
                    type: 'number',
                    minimum: 1,
                }
            },
            required: ['name', 'version'],
        },
        base: {
            type: 'object',
            properties: {
                commission: {
                    type: 'number',
                    minimum: 0,
                },
                ipt: {
                    type: 'number',
                    minimum: 0,
                },
                compulsory_excess: {
                    type: 'number',
                    minimum: 0,
                },
                base_premium: {
                    type: 'number',
                    minimum: 0,
                },
                base_premium_min: {
                    type: 'number',
                    minimum: 0,
                },
                base_premium_max: {
                    type: 'number',
                    minimum: 0,
                },
                upfront_split: {
                    type: 'number',
                    minimum: 0,
                },
                upfront_min: {
                    type: 'number',
                    minimum: 0,
                },
                upfront_max: {
                    type: 'number',
                    minimum: 0,
                },
                usage_split: {
                    type: 'number',
                    minimum: 0,
                },
                usage_distance: {
                    type: 'number',
                    minimum: 0,
                },
                usage_min: {
                    type: 'number',
                    minimum: 0,
                },
                usage_max: {
                    type: 'number',
                    minimum: 0,
                },
                subscription_split: {
                    type: 'number',
                    minimum: 0,
                },
                subscription_installments: {
                    type: 'number',
                    minimum: 0,
                },
                subscription_min: {
                    type: 'number',
                    minimum: 0,
                },
                subscription_max: {
                    type: 'number',
                    minimum: 0,
                },
            },
            required: [
                'commission',
                'ipt',
                'compulsory_excess',
                'base_premium',
                'base_premium_min',
                'base_premium_max',
                'upfront_split',
                'upfront_min',
                'upfront_max',
                'usage_split',
                'usage_distance',
                'usage_min',
                'usage_max',
                'subscription_split',
                'subscription_installments',
                'subscription_min',
                'subscription_max',
            ],
        },
        factors: {
            type: 'object',
            propertyNames: {
                pattern: '^[A-Za-z_][A-Za-z0-9_]*$',
            },
            patternProperties: {
                "": {
                    type: 'object',
                    properties: {
                        description: {
                            type: 'string',
                        },
                        dimensions: {
                            type: 'object',
                            propertyNames: {
                                pattern: '^[A-Za-z_][A-Za-z0-9_]*$',
                            },
                            patternProperties: {
                                "": {
                                    type: 'object',
                                    properties: {
                                        value: {
                                            type: 'array',
                                            items: {
                                                type: 'string',
                                            }
                                        },
                                        value_to: {
                                            type: 'array',
                                            items: {
                                                type: 'string',
                                            }
                                        },
                                    },
                                    required: ['value', 'value_to']
                                }
                            }
                        },
                        hashes: {
                            type: 'object',
                            propertyNames: {
                                pattern: '^[A-Za-z_][A-Za-z0-9_]*$',
                            },
                            patternProperties: {
                                "": {
                                    type: 'object',
                                    properties: {
                                        weight: {
                                            type: 'number',
                                        },
                                        excess: {
                                            type: 'number',
                                        },
                                        refer: {
                                            type: 'boolean',
                                        },
                                        exclude: {
                                            type: 'boolean',
                                        }
                                    },
                                    required: ['weight', 'excess', 'refer', 'exclude'],
                                }
                            }
                        }
                    },
                    required: ['description', 'dimensions', 'hashes']
                }
            }
        },
        schema: {
            type: 'object',
            description: 'Quote JSON schema for validation. Totally flexible.',
            example: {},
            properties: {
                /* Example within a schema example*/
                example: {
                    type: 'object',
                    description: 'Example of quote object.', 
                    example: {
                        ncd: 4, 
                        age: 30
                    },
                },
                /* Schema within a schema */
                json: {
                    type: 'object',
                    description: 'Quote JSON schema for validation',
                    example: {
                        type: 'object',
                        properties: {
                            name: {
                                type: 'integer',
                                description: 'The name of the proposer.', 
                            },
                            age: {
                                type: 'string',
                                description: 'The age of the proposer.',
                            },
                        },
                        required: [ 'name', 'age' ]
                    }
                },
            },
            required: [ 'example', 'json', ]
        },
        exclusions: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    name: {
                        type: "string",
                        //pattern: '^[A-Za-z_][A-Za-z0-9_]*$',
                    },
                    expression: {
                        type: "string",
                    },
                    depends_on_exclusion: {
                        type: "string",
                    },
                    depends_on_exclusion_bool: {
                        type: "boolean",
                    },
                },
                required: [ 'name', 'expression' ]
            }
        },
        formulas: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    name: {
                        type: "string",
                    },
                    filter_expression: {
                        type: "string",
                    },
                    priority: {
                        type: 'number',
                        minimum: 1,
                    },
                    formula_expression: {
                        type: "string",
                    },
                },
                required: [ 'name', 'formula_expression'],
            }
        },
        tests: {
            type: 'array',
            description: 'Test cases that will be used as part of the testing process by the insurers',
            items: {
                type: 'object',
                properties: {
                    name: {
                        type: "string",
                        description: 'The name of the test case',
                        example: 'Test case 1 - Driver with zero NCD'
                    },
                    quote_reference: {
                        type: "string",
                        description: 'This is the quote reference from a previously obtained quote',
                        example: '340925c4-8b52-4fd7-bcb7-9b7f2d890e9e'
                    }
                },
                required: [ 'name', 'quote_reference'],
            }
        },
        ml_models: {
            type: 'array',
            description: 'The machine learning models that have been uploaded by the insurer',
            items: {
                type: 'object',
                properties: {
                    name: {
                        type: "string",
                        description: 'The name of the machine learning model',
                        example: 'mkt_premium'
                    },
                    model_reference: {
                        type: "string",
                        description: 'Unique ID generated using UUID v4. This is the By Bits Model reference',
                        example: '8796ed9f-1767-4391-84c3-81c7f614141b'
                    },
                    ml_model_reference: {
                        type: 'string',
                        description: 'Model file name - excl extension',
                        example: 'mkt_premium_model',
                    },
                    description: {
                        type: 'string',
                        description: 'Description of the model',
                        example: 'TensorFlow model to predict market premium'
                    },
                    library: {
                        type: 'string',
                        description: 'Library used to build the machine learning model',
                        example: 'tensorflow, xgboost, catboost etc.'
                    },
                    path: {
                        type: 'string',
                        description: 'File name with file extension',
                        example: 'mkt_premium_model.pkl'
                    },
                    file_type: {
                        type: 'string',
                        description: 'File extension of the model file',
                        example: '.bin'
                    },
                    one_hot_keys: {
                        type: 'array',
                        description: 'Columns in the ML model that require one-hot encoding',
                        example: [
                            'column_a',
                            'column_b'
                        ]
                    },
                    one_hot_columns: {
                        type: 'array',
                        description: 'Columns to be used in ML model for prediction - should include the columns created as part of one-hot encoding',
                        example: [
                            'column_1',
                            'column_2'
                        ]
                    },
                    upload_date: {
                        type: 'date-time',
                        description: 'Date of when ML model was uploaded',
                        example: '2021-11-22T13:28:26.742Z'
                    }
                },
                required: [
                    'name', 
                    'model_reference', 
                    'description', 
                    'library', 
                    'path', 
                    'file_type', 
                    'ml_model_reference',
                    'one_hot_keys',
                    'one_hot_columns',
                    'upload_date'
                ]
            }
        },
    },
    required: ['meta', 'base' , 'factors', 'exclusions', 'formulas', 'schema'],
};

const validateBase = jsen(schema);

export function validate(model) {
    const properties = Object.keys(model);
    const result = {
        is_valid : true,
        errors: [],
    };
    
    const checkBase = validateBase(model);   

    if (checkBase === false) {
        return {
            is_valid: checkBase,
            errors: validateBase.errors
        }
    }

    return result;
}