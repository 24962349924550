import BaseActions from '../../../actions/base';
import services from '../config';

const { root } = services.payments;

const dashboard = new BaseActions({
    path: `${root}/dashboard`,
    type: 'PAYMENTS_DASHBOARD',
});

export default dashboard;
