import React, { Component } from 'react';
import SuperTable from '../../../../../components/tables/Super';
import { get_codelist, format_values } from '../Utils';

class TableModifications extends Component {
    constructor(props) {
        super(props);

        this.delete = this.delete.bind(this);
    }

    delete(index) {
        const { update, data } = this.props;
        
        const new_mods = [...data];

        new_mods.splice(index, 1)

        update({ modifications: new_mods });
    }

    render() {
        const { data = [], disabled, lang } = this.props;
        const map_list = get_codelist('vehicle.modifications');

        let headers = [
            {
                label: lang.t('tableHeaders.code'),
                name: 'code',
            },
        ];

        // Add delete button header if not disabled
        if (!disabled) {
            headers.push({
                label: '',
                name: 'delete',
            },)
        }

        const Cells = (index, d) => {
            return (
                <tr key={`mod-${index}`}>
                    <td data-label="Code">
                        <div className="text-h5">
                            {format_values(d, 'mapped', map_list)}
                        </div>
                    </td>
                    {!disabled && 
                        <td className="w-1">
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                this.delete(index);
                            }} className="btn btn-sm text-danger" style={{ boxShadow: 'none' }} data-toggle="modal" data-target="#modal-factor">
                                <i className="fe fe-trash-2 pb-1" style={{ fontSize: '15px' }}></i>
                            </a>
                        </td>
                    }
                </tr>
            )
        }

        return(
            <SuperTable 
                data={data}
                headers={headers}
                Cells={Cells}
                auto_sort={true}
                lang={lang}
            />
        )
    }
}

export default TableModifications;