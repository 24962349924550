import React from 'react';
import styled from 'styled-components';

import { to_capitalise } from '../components/format/string';

const BadgeStyle = styled.div`
    font-size: 0.6875rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    display: inline-flex;
    justify-content: center;
    padding: 0 0.5em;
    line-height: 1.75em;
    overflow: hidden;
    vertical-align: text-bottom;
    text-align: center;
    white-space: nowrap;
    border-radius: 3px;
`;

const Badge = (props) => {
    const { backgroundColor, text, marginRight, blackText } = props;

    let hexColor;
    if (backgroundColor === 'green') hexColor = '#5eba00'; //active policy
    if (backgroundColor === 'yellow') hexColor = '#fab005'; //cancelling policy
    if (backgroundColor === 'red') hexColor = '#fa4654'; //cancelled policy
    if (backgroundColor === 'grey') hexColor = '#cbcfd6'; //lapsed policy
    if (backgroundColor === 'orange') hexColor = '#ff922b'; //expiring policy
    if (backgroundColor === 'white') hexColor = '#fff'; //other

    const dynamicStyles = {
        backgroundColor: hexColor,
        marginRight: marginRight ? '1rem' : '',
        color: blackText ? 'black' : 'white',
    };

    return <BadgeStyle style={dynamicStyles}>{to_capitalise(text)}</BadgeStyle>;
};

export default Badge;
