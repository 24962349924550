import moment from 'moment';
import React from 'react';

export function releaseBadge ({
    status,
    published_at,
}) {
    if (status === 'draft') return (<div className='badge bg-yellow'>DRAFT</div>);
    if (status === 'staging') return (<div className='badge bg-indigo'>STAGING</div>);
    if (status === 'unpublished') return (<div className='badge bg-red'>UNPUBLISHED</div>);
    if (moment(published_at) > moment() && status === 'published') {
        return ( <div className='badge bg-orange'>SCHEDULED</div>);
    }
    return (
        <div className='badge bg-green'>PUBLISHED</div>
    )
}

export function versionBadge ({
    version,
    published_at,
    status,
    is_small = false
}) {
    const style = {
        textAlign: 'centre',
        width: is_small ? 20 : 30,
        fontSize: is_small ? 12 : 14,
        lineHeight: is_small ? '24px' : '34px',
    };
    if (status === 'draft') return (<div className='badge bg-yellow' style={style}>{version}</div>);
    if (status === 'staging') return (<div className='badge bg-indigo' style={style}>{version}</div>);
    if (status === 'unpublished') return (<div className='badge bg-red' style={style}>{version}</div>);
    if (moment(published_at) > moment() && status === 'published') return (<div className='badge bg-orange' style={style}>{version}</div>);
    return (
        <div className='badge bg-green' style={style}>{version}</div>
    )
}