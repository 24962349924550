import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import main from '../../../../hoc/main';
import { getId } from '../../../../utils/url';
import { isEmpty, first } from 'lodash';
import { parse_date } from '../../components/Detail/Utils';
import { getNumberFormat } from '../../../../utils/numberFormat';

// Core Design
import SpinnerBody from '../../../../components/loaders/SpinnerBody';
import Empty from '../../../../components/empty/Results';

// Service Design
import JourneysTable from '../../components/Journeys/Table';

class PolicyStatementDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        }
        this.updatePage = this.updatePage.bind(this);
    }

    componentDidMount() {
        const { history } = this.props;
        const id = getId(history);
        this.props.policys_statements_get({
            id,
        });

        this.props.policys_products_get_all({});
    }

    updatePage(page) {
        this.setState({
            page,
        });
    }

    render() {
        const {
            isFetching = true,
            selected = {},
            data = [],
        } = this.props.policys_statements;

        const { data: products = [] } = this.props.policys_products;
        // Checking only the currency of the first product. assume all products of a company uses same currency
        const currency = products.length !== 0 ? first(products).currency : 'GBP';
        const distance_units = first(products)?.distance_units === 'miles' ? 'miles' : 'km';
        const timezone = products.length !== 0 ? first(products).timezone : 'Europe/London';
        const { page } = this.state;
        const pages = 50;
        const no_of_pages = selected.journeys? Math.ceil(selected.journeys.length / pages) : 0;

        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    <div className="row">
                        {/* Loader */}
                        {isFetching && <SpinnerBody />}

                        {/* Table */}
                        {!isFetching && !isEmpty(selected) && (
                            <div className="col-sm-12 col-lg-12">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row"> 
                                            <div className="col-lg-4 text-right mb-2">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">
                                                            <span className="bg-gray text-white avatar">
                                                                <i className="fe fe-arrow-right-circle"></i>
                                                            </span>
                                                        </div>
                                                        <div className="col">
                                                            <div className="font-weight-bold">
                                                                {`${lang.t('policy.statements.total')} ${distance_units === 'miles' ?
                                                                    getNumberFormat(currency, selected.total_miles, '0,0[.]0') :
                                                                    getNumberFormat(currency, selected.total_kms, '0,0[.]0') } ${distance_units}`
                                                                }
                                                            </div>
                                                            <div className="text-muted">
                                                                {selected.journey_count} {lang.t('policy.statements.journeys')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 text-right mb-2">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">
                                                            <span className="bg-gray text-white avatar">
                                                                <i className="fe fe-hash"></i>
                                                            </span>
                                                        </div>
                                                        <div className="col">
                                                            <div className="font-weight-bold">
                                                                {`${lang.t('policy.statements.total')} ${getNumberFormat(currency, selected.total_premium || 0, '$0,0.00')} ${lang.t('policy.journeys.premium')}`}
                                                            </div>
                                                            <div className="text-muted">
                                                                {`${getNumberFormat(currency, selected.subscription_premium || 0, '$0,0.00')} ${lang.t('policy.statements.sub')} / ${getNumberFormat(currency, selected.usage_premium || 0, '$0,0.00')} ${lang.t('policy.statements.usage').toLowerCase()}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 text-right mb-2">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">
                                                            <span className="bg-gray text-white avatar">
                                                                <i className="fe fe-check"></i>
                                                            </span>
                                                        </div>
                                                        <div className="col">
                                                            <div>{lang.t('policy.statements.policyReference')} : <a onClick={() => {
                                                                    this.props.history.push(`/policys/detail/${selected.policy_reference}`)
                                                                }} className="btn btn-sm btn-primary text-white ml-auto">{selected.policy_reference}</a>
                                                            </div>
                                                            <div className="text-muted">
                                                                {parse_date(selected.statement_start_date, 'lll', timezone)} - {parse_date(selected.statement_end_date, 'lll', timezone)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        { selected.journeys.length === 0 ? <Empty label={lang.t('policy.statements.journeys').toLowerCase()} lang={lang} /> : <div className="row"> 
                                            <div className="col-lg-12">
                                                <JourneysTable 
                                                    journeys={selected.journeys}
                                                    currency={currency}
                                                    timezone={timezone}
                                                    page={page}
                                                    pages={pages}
                                                    no_of_pages={no_of_pages}
                                                    update={this.updatePage}
                                                    lang={lang}
                                                />
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        );
    }
}

export default main(PolicyStatementDetail);