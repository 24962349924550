import React, { Component } from 'react';
import { Parser } from 'json2csv';
import { saveAs } from 'file-saver';
import ScenariosTable from './ScenariosTable';
import ScenariosStats from './ScenariosStats';
import { get, reject, isEmpty } from 'lodash';
import { indexCollection } from '../../../../../utils/manipulate';

class Tests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            quotes: [],
            pricing: [],
            tests_processed: false,
            is_fetching: [],
            disable_btns: false,
        }
        this.runTests = this.runTests.bind(this);
        this.deleteTest = this.deleteTest.bind(this);
        this.editTest = this.editTest.bind(this);
        this.addTest = this.addTest.bind(this);
        this.testDetails = this.testDetails.bind(this);
        this.exportTestsCSV = this.exportTestsCSV.bind(this);
    }

    componentWillMount() {
        const enrichScenarios = indexCollection(this.props.tests).map((s, index) => {
            if (isEmpty(this.state.is_fetching)) {
                return {
                    ...s,
                }
            }
            return {
                ...s,
                ...this.state.results[index] || {},
                }});

        this.setState({
            results: enrichScenarios,
        })
    }

    componentWillReceiveProps(nextProps) {
        const enrichScenarios = indexCollection(nextProps.tests).map((s, index) => {
            if (isEmpty(this.state.is_fetching)) {
                return {
                    ...s,
                }
            }
            return {
                ...s,
                ...this.state.results[index] || {},
                }});

        this.setState({
            results: enrichScenarios,
        });
        
    }

    async runTests() {
        try {
            //Disable add and run tests buttons
            this.setState({
                disable_btns: true,
            });

            //1. Grab the quote details using the quote references
            let quote_refs = this.props.tests.map(test => test.quote_reference,);

            let is_fetching = this.props.tests.map(test => {
                return {
                    id: test.id,
                    is_loading: true,
                    processed: false,
                }
            });

            this.setState({
                is_fetching
            });

            // 2. Functions to hit endpoints to grab quote details and re-quote

            const get_individual_quote = async (quote_ref) => {
                const data = await this.props.pricing_quotes_get({ id: quote_ref });
                return get(data, 'payload.data.quote', {});
            }

            const get_individual_price = async(quote) => {
                const data = await this.props.pricing_quotes_post_some({
                    id: `${this.props.model_reference}?debug=true`, //TODO use the query builder
                    data: quote,
                });
                return get(data, 'payload.data', {})
            }

            //3. Test results
            let quotes = [];
            let pricing = [];

            for (const [index, quote_ref] of quote_refs.entries()) {
                const quote_detail = await get_individual_quote(quote_ref);
                const result = await get_individual_price(quote_detail);

                quotes.push(quote_detail);
                pricing.push(result);

                //4. Add test results to test array to populate UI

                let populateResults = [...this.state.results];

                populateResults[index] = {
                    ...populateResults[index],
                    ...pricing[index] || {},
                }

                //5. Set state for individual cases

                let update_is_fetching = [...this.state.is_fetching];

                update_is_fetching[index] = {
                    is_loading: false,
                    processed: true,
                }

                this.setState({
                    results: populateResults,
                    is_fetching: update_is_fetching,
                    quotes,
                });
            }

            //6. all tests have finished - set state

            this.setState({
                tests_processed: true,
            });
        } catch(e) {
            console.log(e);
        }
    }
    
    deleteTest(test) {
        this.props.deleteTests(reject(this.props.tests, e => e.name === test));
    }

    editTest(test) {
        this.props.openModal({
            tests: {
                open: true,
                name: test,
            }
        });
    }

    addTest() {
            this.props.openModal({
                tests: {
                    open: true,
                    name: '',
                }
            });
    }

    testDetails(quote, pricing) {
        this.props.openModal({
            test_detail: {
                open: true,
                quote,
                pricing,
            }
        });
    }

    exportTestsCSV(e, data) {
        e.preventDefault();
        const mapped_fields = data.map(test => {
            return {
                id: test.id,
                quote_reference: test.quote_reference,
                test_name: test.name,
                premium: test.base_premium,
                excess: test.total_excess,
                exclude: !!test.exclusion_count,
                refer: !!test.refer_count,
            };
        });

        const parser = new Parser({fields:[
            'id',
            'quote_reference',
            'test_name',
            'premium',
            'excess',
            'exclude',
            'refer',
        ]});
        const mapped_csv = parser.parse(mapped_fields);
        const date = Date.now();
        const file_name = `Test Scenarios Output ${date}.csv`;

        // Create a blob of the data
        const content = new Blob([mapped_csv], {
            type: 'text/csv'
        });
        
        // Save the file
        saveAs(content, file_name);
    }

    render() {
        const { results, tests_processed, is_fetching, disable_btns, quotes } = this.state;
        const number_of_tests = this.props.tests.length;
        const lang = this.props.lang;
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">{lang.t('rating.components.tests.title')}
                                { !disable_btns &&
                                    <button href="#"  
                                            style={{
                                                position: 'absolute',
                                                top: 14,
                                                right: `${number_of_tests > 0 ? '106px' : '20px'}`,
                                                }} 
                                            className={`btn ${number_of_tests > 0 ? 'btn-white text-black' : 'btn-primary'} btn-sm`}
                                            onClick={this.addTest} 
                                            data-toggle="modal" 
                                            data-target="#modal-tests">
                                        {`${lang.t('rating.components.tests.add')} ${number_of_tests === 0 ? '+' : ''}`}
                                    </button>
                                }
                                { !disable_btns && number_of_tests > 0 &&
                                <button href="#"  style={{
                                    position: 'absolute',
                                    top: 14,
                                    right: 20,
                                }} onClick={this.runTests} className="btn btn-primary btn-sm">{lang.t('rating.components.tests.run')}</button>
                                }
                                { tests_processed &&
                                <button href="#"  style={{
                                    position: 'absolute',
                                    top: 14,
                                    right: 20,
                                }} onClick={(e) => this.exportTestsCSV(e, results)} className="btn btn-white text-black btn-sm">{lang.t('rating.components.tests.exportCSV')}</button>
                                }
                            </h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        {lang.t('rating.components.tests.testsInfo')}
                                    </div>
                                    { tests_processed && 
                                        <ScenariosStats data={results} lang={lang} /> 
                                    }
                                    <ScenariosTable 
                                        delete={this.deleteTest}
                                        edit={this.editTest}
                                        tests_processed={tests_processed}
                                        is_fetching={is_fetching}
                                        details={this.testDetails}
                                        quotes={quotes}
                                        pricing={results}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Tests;