import React, { Component } from 'react';
import Select from './Select';
import DatePicker from './DatePicker';
import { get_codelist } from '../Utils';

class FormClaim extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const { data, disabled, update, lang } = this.props;
        return(
            <div className="card-body">
                    <Select 
                        label={`${lang.t('policy.policies.claimCode')} *`}
                        property={'code'}
                        options={get_codelist('proposer.claims.map(code)')}
                        value={data.code}
                        update={update}
                        disabled={disabled}
                    />
                    <DatePicker 
                        label={`${lang.t('policy.policies.date')} *`}
                        property={'date'}
                        value={data.date}
                        update={update}
                        disabled={disabled}
                    />
                    <Select 
                        label={`${lang.t('policy.policies.atFault')} *`}
                        property={'at_fault'}
                        options={[{ label: 'true' }, { label: 'false' }]}
                        value={data.at_fault}
                        update={update}
                        type = 'bool'
                        disabled={disabled}
                    />
                    <Select 
                        label={`${lang.t('policy.policies.ncdLost')} *`}
                        property={'ncd_lost'}
                        options={[{ label: 'true' }, { label: 'false' }]}
                        value={data.ncd_lost}
                        update={update}
                        type = 'bool'
                        disabled={disabled}
                    />
                </div>
        )
    }
}

export default FormClaim;