import React from 'react';
import Input from '../../Inputs/Input';

export default function FormExclusions(props) {
    const exclusions = props.data;
    const lang = props.lang;

    return (
        <div className='card ml-2'>
            <div className='card-header'>
                <h3 className='card-title'>
                    {lang.t('rating.pricingSection.exclusions')}
                </h3>
            </div>
            <div className='card-body'>
                <div className='mb-1'>
                    {exclusions.map((element, i) => {
                        return(
                            <Input 
                                label={i+1} 
                                value={element} 
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
