import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import numeral from 'numeral';
import main from '../../../../hoc/main';
import { first } from 'lodash';

// Core Design
import PageHeader from '../../../../components/layout/PageHeader';
import OverlayLoader from '../../../../components/loaders/OverlayLoader';

import UnpaidTable from '../../components/Versions/UnpaidTable';
import Search from '../../../../components/search/Simple';
import SpinnerBody from '../../../../components/loaders/SpinnerBody';
import Empty from '../../../../components/empty/Results';
import ProductsFilterDropDown from '../../components/Products/FilterDropdown';

class UnpaidVersions extends Component {
    constructor (props) {
        super(props);

        this.state = {
            unpaid_versions: [],
            showBanner: false,
            bannerPolicy: '',
            bannerAmount: '',
            paymentSuccess: false,
            searchTerm: '',
            disabledBtns: [],
            returnSearch: false,
        }

        this.showBanner = this.showBanner.bind(this);
        this.search = this.search.bind(this);
        this.filter = this.filter.bind(this);
        this.term = this.term.bind(this);
    }

    async componentDidMount() {
        await this.props.policys_products_get_all({});
        await this.props.policys_unpaid_versions_get_all({});
    }

    filter({ product_reference }) {
        this.setState({
            filterTerm: product_reference,
        }, () => {
            this.search();
        })
    }

    term(term) {
        if (
            (term.length > 0 && term.length < 3) || 
            this.props.policys_unpaid_versions.isFetching
        ) {
            return;
        }
        this.setState({
            searchTerm: term,
        }, () => {
            this.search();
        })
    }

    async search() {
        const { searchTerm = '', filterTerm = '' } = this.state;
        let where = { policy_reference: searchTerm };
        if (filterTerm !== '') where = { ...where, product_reference: filterTerm };
        await this.props.policys_unpaid_versions_get_all(where);
    }

    showBanner(policyRef, paymentAmount, paymentSuccess, errorMessage) {
        this.setState({ 
            showBanner: true,
            bannerPolicy: policyRef,
            bannerAmount: paymentAmount,
            paymentSuccess,
            errorMessage,
        });
    }

    render() {
        const { isFetching = true, data = [] } = this.props.policys_unpaid_versions;
        const { searchTerm, showBanner, paymentSuccess, bannerAmount, bannerPolicy, errorMessage } = this.state;
        const { data: products = [] } = this.props.policys_products;
        // Checking only the currency of the first product. assume all products of a company uses same currency
        const currency = products.length !== 0 ? first(products).currency : 'GBP';
        const timezone = products.length !== 0 ? first(products).timezone : 'Europe/London';

        const lang = getLangSource(this.props.lang);
        
        return (
            <div className= "my-3 my-md-5">
                    <div class="container">

                        {showBanner && paymentSuccess &&
                            <div className="alert alert-success" role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-check-circle"></i>
                                {`Successful payment of ${currency}${bannerAmount} for ${bannerPolicy}`}
                            </div>
                        }

                        {showBanner && !paymentSuccess &&
                            <div className="alert alert-danger" role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-alert-triangle"></i>
                                {`Failed payment of ${currency}${bannerAmount} for ${bannerPolicy} - ${errorMessage}`}
                            </div>
                        }

                        <PageHeader
                            section={lang.t('policy.reports.claims')}
                            page={lang.t('policy.reports.unpaidVersions')}
                        />

                        {/* Search */}
                        <div className="row">
                            <div className="col-sm-12 col-lg-8">
                                <ProductsFilterDropDown
                                    products={this.props.policys_products}
                                    update={this.filter}
                                    lang={lang}
                                />
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                <div className="form-group">
                                    <label className="form-label">{lang.t('policy.policies.searchByPolicy')}</label>
                                    <Search
                                        searchTerm={this.state.searchTerm}
                                        searchFunction={this.term}
                                        isFetching={isFetching}
                                        label={lang.t('policy.reports.policies').toLowerCase()}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {isFetching && <SpinnerBody />}

                            {!isFetching && data.length === 0 
                                && <Empty label="unpaid versions" lang={lang} />}

                            {!isFetching && data.length > 0 
                                && (
                                    <div className="col-sm-12 col-lg-12">
                                        <UnpaidTable 
                                            unpaid_versions={data}
                                            policys_payments_post={this.props.policys_payments_post}
                                            showBanner={this.showBanner}
                                            disabledBtns={this.state.disabledBtns}
                                            claimsFetching={isFetching}
                                            currency={currency}
                                            timezone={timezone}
                                            lang={lang}
                                        />
                                    </div>
                                )}
                        </div>
                    </div>
            </div>
        )
    }
}

export default main(UnpaidVersions);