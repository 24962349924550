import { isEmpty, round } from 'lodash';
import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import { getNumberFormat } from '../../../../utils/numberFormat';

function chartBuilder(series = [], name) {
    const { data } = series[0];
    const last_item = data[data.length - 1] || null;
    const second_last_item = data[data.length - 2] || null;

    function delta() {
        if (!(last_item && second_last_item)) return 0;
        return round(((last_item - second_last_item) /second_last_item) * 100, 1);
    }

    return {
        value: last_item || 0,
        delta: delta(),
    }
}

class StatChart extends Component {
    constructor(props) {
        super(props);
        this.updateChart = this.updateChart.bind(this);
    }

    componentWillMount() {
        this.updateChart(this.props);
    }

    updateChart() {
        const {
            series,
            format,
            labels,
            name,
        } = this.props;

        let chart = {
            series,
            options: {
                chart: {
                    sparkline: {
                        enabled: true
                    },
                    animations: {
                        enabled: false
                    },
                },
                fill: {
                    opacity: 1,
                },
                stroke: {
                    width: [2, 1],
                    dashArray: [0, 3],
                    lineCap: "round",
                    curve: "smooth",
                },

                grid: {
                    strokeDashArray: 4,
                },
                xaxis: {
                    labels: {
                        padding: 0
                    },
                    type: 'datetime',
                },
                yaxis: {
                    labels: {
                        padding: 4
                    },
                },
                labels,
                colors: ["#206bc4"],
                legend: {
                    show: false,
                },
                tooltip: {
                    x: {
                        format,
                    }
                }
            }
        };

        const {
            value,
            delta,
        } = chartBuilder(series, name);

        this.setState({ chart, delta, name, value });
    }

    deltaDirection(delta = 0) {
        const { currency = 'GBP' } = this.props;
        const formattedDelta = getNumberFormat(currency, delta, '0,0[.]0');

        if (delta < -1) {
            return (
                <span class="text-red d-inline-flex align-items-center lh-1">
                    {formattedDelta}% <i className="fe fe-arrow-down-right"></i> 
                </span>
            )
        } else if (delta > 1) {
            return (
                <span class="text-green d-inline-flex align-items-center lh-1">
                    {formattedDelta}% <i className="fe fe-arrow-up-right"></i> 
                </span>
            )
        } else{
            return (
                <span class="text-yellow d-inline-flex align-items-center lh-1">
                    {formattedDelta}%
                </span>
            )
        }
    }

    render() {
        const { chart = {}, value = 0, delta = 0, name } = this.state;
        const { currency = 'GBP' } = this.props;

        return(
            <div class="card">
                {!isEmpty(chart) &&
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="subheader">{name}</div>
                            <div class="ml-auto lh-1">
                            </div>
                        </div>
                        <div class="d-flex align-items-baseline">
                            <div class="h1 mb-3 mr-2">{getNumberFormat(currency, value, '0,0.00')}</div>
                            <div class="mr-auto">
                                {this.deltaDirection(delta)}
                            </div>
                        </div>
                        <div class="chart-sm" >
                            <Chart
                                options={chart.options}
                                series={chart.series}
                                type="line"
                                height="40"
                            />
                        </div>
                    </div>
                }
            </div>
            
        );
    }
}

export default StatChart;


