import React, { Component } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { sortBy, each, isNumber } from 'lodash';

import VirtualisedTable from '../../../../components/tables/Virtualised';

function versionise(v) {
    return v.type + moment(v.e_from).format('MMYY');
}

function riskDiff (ra, rb) {
    if(!ra) return [];
    const riska = ra.risks;
    const riskb = rb.risks;
    const diff = [];
    each(riska, (v, k) => {
        if(riskb[k] !== v) {
            diff.push({
                name: k,
                original: riska[k],
                change: riskb[k],
                movement: isNumber(riskb[k]) ? (riskb[k] - riska[k]) : '-'
            });
        }
    });
    return diff;
}

class PolicySelected extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            policySelected,
        } = this.props;

        const { versions = [], current = {} } = policySelected || {};

        return (
            <div className="card">
            {!policySelected &&    
                <div className="empty">
                    <div className="empty-icon">
                        <i className="fe fe-info"></i>
                    </div>
                    <p className="empty-title h3">No policy selected</p>
                    <p className="empty-subtitle text-muted">
                        Select a policy on the left to see details about the policy versions
                    </p>
                </div>
            }  
            {policySelected &&
                <div class="mt-3 mr-3 ml-3 mb-3" style={{ border: '1px solid #eaeaea'}}>
                    <VirtualisedTable 
                        data={sortBy(versions, 'e_from').map(v => {
                            return {
                                'Version': versionise(v),
                                'Type': v.type,
                                'Exposure': numeral(v.exposure).format('0,0'),
                                'Miles': numeral(v.miles).format('0,0'),
                                'Upfront': `£${v.gross_sub}`,
                                'Earned': `£${v.earned_total_premium}`,
                                'Date': moment(v.e_from).format('DD-MM-YYYY'),
                            };
                        })}
                    />
                </div>
            }
            {policySelected &&  
                <div class="card-tabs mt-3 mr-3 ml-3 mb-3">
                    <ul class="nav nav-tabs">
                        {sortBy(versions, 'e_from').map((v, i) => {
                            return (<li class="nav-item">
                                <a 
                                    href={`#${ versionise(v)}`} 
                                    class={`nav-link ${i === 0 ? 'active' : ''}`} 
                                    data-toggle="tab">
                                    { versionise(v)}
                                </a>
                            </li>)
                        })}
                    </ul>
                    <div class="tab-content">
                        {sortBy(versions, 'e_from').map((v, i) => {
                            return (  
                                <div id={versionise(v)} class={`card tab-pane ${i === 0 ? 'active show' : ''}`}>
                                    <div class="card-body" style={{ }}>
                                        <div class="hr-text hr-text-center mt-3 mr-3 ml-3 mb-3">Changes</div>
                                        <div class="table-responsive" style={{ border: '1px solid #eaeaea'}}>
                                            <table class="table table-vcenter card-table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Risk</th>
                                                        <th>Original</th>
                                                        <th>Change</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {riskDiff(versions[i - 1], v).map(r => {
                                                        return (
                                                            <tr>
                                                                <td>{r.name}</td>
                                                                <td class="text-muted">
                                                                    {r.original}
                                                                </td>
                                                                <td class="text-muted">
                                                                    {r.change}
                                                                </td>
                                                                <td class="text-muted">
                                                                    {r.movement}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="hr-text hr-text-center mt-3 mr-3 ml-3 mb-3">Detail</div>  
                                        <pre style={{ height: 300, background: '#354052', color: 'white' }}>
                                            {JSON.stringify(v, null, 2)}
                                        </pre>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            </div>
        );
    }
}

export default PolicySelected;