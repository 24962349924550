import React, { Component } from 'react';
import { reject } from 'lodash';
import ModelFormulasTable from './FormulasTable';

class Formulas extends Component {
    constructor(props) {
        super(props);
        this.editFormulas = this.editFormulas.bind(this);
        this.deleteFormulas = this.deleteFormulas.bind(this);
        this.updatePriority = this.updatePriority.bind(this);
    }

    editFormulas(formula) {
        this.props.openModal({
            formulas: {
                open: true,
                name: formula,
            }
        });
    }

    updatePriority(data) {
        const rejected = reject(this.props.formulas, e => e.name === data.name)
        this.props.updateFormulas([data, ...rejected]);
    }

    deleteFormulas(formula) {
        this.props.deleteFormulas(reject(this.props.formulas, e => e.name === formula));
    }

    render() {
        const { formulas, lang } = this.props;
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">{lang.t('rating.components.formulas.title')}
                                <a href="#"  style={{
                                    position: 'absolute',
                                    top: 14,
                                    right: 20,
                                }} className="btn btn-primary btn-sm" data-toggle="modal" data-target="#modal-formula">{lang.t('rating.components.formulas.create')}</a>
                            </h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        {lang.t('rating.components.formulas.formulasInfo')}
                                    </div>
                                    <ModelFormulasTable 
                                        data={formulas}
                                        updatePriority={this.updatePriority}
                                        edit={this.editFormulas}
                                        delete={this.deleteFormulas}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Formulas;