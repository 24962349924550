import React, { Component } from 'react';
import Select from './Select';
import { get_codelist } from '../Utils';

class FormModifications extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { 
            data,
            update,
        } = this.props;

        return (
            <div className="card-body">
                <Select 
                    label={'Mod code'}
                    property={'code'}
                    options={get_codelist('vehicle.modifications')}
                    value={data.code}
                    update={update}
                />
            </div>
        );
    }
}

export default FormModifications;