import React from 'react';

function AddSuccess(props) {
    const { close, name, lang } = props;
    return (
        <>
            <div className="modal-body">
                <div className={`alert alert-success mb-2 p-1`} role="alert">
                    <i style={{ marginRight: 5 }} className="fe fe-check-circle"></i>
                    {name} {lang.t('components.modal.beenAdded')}
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={close}>{lang.t('components.modal.ok').toUpperCase()}</button>
            </div>
        </>
    );
}

export default AddSuccess;
