import moment from 'moment';
import config from '../config/index';

export function getPolicyStatus(version) {
    
    const { start_date, end_date } = version;
    const { Policy_Expiry_Threshold_Days } = config;
    const currentMomentUnix = moment().unix();
    const end_date_unix = moment(end_date).unix();
    const expectedEndDate = moment(start_date).add(1, 'years').unix(); // Assumes policies have a duration of 12 months
    const secondsInDay = 60 * 60 * 24; // 60 secs in 1 min, 60 mins in 1 hour, 24 hours in a day
    const expiryInMonth = moment().add(Policy_Expiry_Threshold_Days, 'days').unix();

    // Check if end_date (ie actual) is within a day of expectedEndDate . If it is then policy is still live, otherwise it has cancelled
    // Expecting normal start and end dates to be: start = 2022-01-20T00:00:00 --> end = 2023-01-20T00:00:00
    // Need to account for the following edge cases for 12 month policies:
    //  1. start = 2022-01-20T00:00:00 --> end = 2023-01-19T23:59:59 (ie pol ends a second before)
    //  2. start = 2022-01-20T13:38:28 --> end = 2023-01-19T23:59:59 (ie pol start time is during the day)
    //  3. start = 2022-01-20T23:59:59 --> end = 2023-01-19T23:59:59 (ie more extreme case of pol start time being close to midnight)

    const hasCancelled = !(expectedEndDate - end_date_unix <= secondsInDay);

    if (!hasCancelled && end_date_unix < currentMomentUnix) {
        return 'lapsed';
    }

    if (!hasCancelled && end_date_unix <= expiryInMonth) {
        return {text:'expiring', policyDaysRemaining: Math.floor((end_date_unix - currentMomentUnix)/secondsInDay)}
    }

    if (!hasCancelled && end_date_unix >= currentMomentUnix) {
        return 'active';
    }

    if (hasCancelled && end_date_unix <= currentMomentUnix) {
        return 'cancelled';
    }

    if (hasCancelled && end_date_unix > currentMomentUnix) {
        return 'cancelling';
    }
}