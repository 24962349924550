import React, { Component } from 'react';
import Avatar from '../avatar';

import policy_icon_black_svg from './icons/policy_engine_black.svg';
import policy_icon_white_svg from './icons/policy_engine_white.svg';
import rating_icon_black_svg from './icons/rating_engine_black.svg';
import rating_icon_white_svg from './icons/rating_engine_white.svg';

class AvatarBox extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            last_name,
            first_name,
            role,
            lang,
            auth,
            products,
            selectProduct,
            selectedProduct,
            selectEnv,
            selectedEnv,
        } = this.props;

        return (
            <>
                <div class={`badge bg-blue mr-3`} style={{ color: 'white' }}>{auth.company_name}</div>
                <li className="nav-item dropdown pl-3">
                    <a href="#" className="nav-link d-flex lh-1 text-reset p-0 text-left" data-toggle="dropdown">
                        <Avatar f_names={first_name} l_names={last_name}/>
                        <div className="d-none d-lg-block pl-2">
                            <div>
                                {first_name} {last_name}
                            </div>
                            <div className="mt-1 small text-muted" style={{ padding: 0 }}>{lang.tc(`roles.${role}`)}</div>
                        </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">

                        {products.length > 1 && products.filter(p => p === 'rating' || p === 'policy').map(p => {
                            let icon;
                            if (selectedProduct === 'rating') {
                                icon = (p === 'rating') ? rating_icon_white_svg : policy_icon_white_svg;
                            } else {
                                icon = (p === 'rating') ? rating_icon_black_svg : policy_icon_black_svg;
                            }
                            return (
                                <a className={`dropdown-item`} onClick={(e) => {
                                    selectProduct(p);
                                }} href="#">
                                    <span>
                                        <img className="icon" src={icon} /> {lang.tc(`products.${p}`)} {lang.tc(`products.engine`)}
                                    </span>
                                </a>
                            )
                        })}     

                        {/*selectedEnv === 'sandbox' &&
                            <a className="dropdown-item" onClick={(e) => {
                                selectEnv('production');
                            }} href="#">
                                Switch to <span className="badge badge-pill bg-green ml-1">{lang.tc(`products.production`)}</span>
                            </a>
                        }

                        {/*selectedEnv === 'production' &&
                            <a className="dropdown-item" onClick={(e) => {
                                selectEnv('sandbox');
                            }} href="#">
                                Switch to <span className="badge badge-pill bg-yellow ml-1">{lang.tc(`products.sandbox`)}</span>
                            </a>
                        */}

                        <div className="dropdown-divider" />

                        <a className="dropdown-item" href="/settings/company">
                            {lang.tc('menu.company')}
                        </a>

                        <a className="dropdown-item" href="/settings/products">
                            {lang.tc('menu.products')}
                        </a>

                        <a className="dropdown-item" href="/settings/billing">
                            {lang.tc('menu.billing')}
                        </a>

                        <a className="dropdown-item" href="/settings/users">
                            {lang.tc('menu.users')}
                        </a>

                        <div className="dropdown-divider" />
                        
                        <a className="dropdown-item" href="#" onClick={(e) => {
                            e.preventDefault();
                            this.props.signOut();     
                        }}>
                            {lang.tc('menu.signout')}
                        </a>
                    </div>
                </li>
            </>
        );
    }
}

export default AvatarBox;
