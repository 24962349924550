import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import main from '../../../../hoc/main';
import { getId } from '../../../../utils/url';
import { isEmpty, get, first } from 'lodash';
import moment from 'moment';
import getCurrencySymbol from '../../../../utils/currencySymbol';
import { getOverrideDistance } from '../../../../utils/getOverrideDistance';
import { parse_date } from '../../components/Detail/Utils';
import { getNumberFormat, getUsageFormat } from '../../../../utils/numberFormat';

// Core Design
import SpinnerBody from '../../../../components/loaders/SpinnerBody';

// Service Design
import JourneyMap from '../../components/Journeys/Map';


class PolicyJourneyDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSubmitting: false,
            error: null,
        }
        
        this.voidJourney = this.voidJourney.bind(this);
    }

    componentDidMount() {
        const { history } = this.props;
        const id = getId(history);
        this.props.policys_journeys_get({
            id,
        });

        this.props.policys_products_get_all({});
    }

    async voidJourney(e, journey_reference) {
        e.preventDefault();
        this.setState({ 
            isSubmitting: true,
            error: null, 
        });

        try {
            await this.props.policys_journeys_put({
                id: journey_reference,
                data: {
                    is_void: true,
                }
            });

        } catch (err) {
            console.log(err);
            const errorMessage = get(err, 'error.response.data.message');
            const errorCode = get(err, 'error.response.data.status_code');

            this.setState({
                error: `${errorCode} - ${errorMessage}`
            });

        } finally {
            this.setState({ isSubmitting: false });
        }
    }

    render() {
        const {
            isFetching = true,
            selected = {},
        } = this.props.policys_journeys;

        const {
            is_void,
            journey_reference,
            policy_version_reference,
            override_distance_in_metres,
            distance_units,
        } = selected;

        const { isSubmitting, error } = this.state;
        const { data: products = [] } = this.props.policys_products;

        // Checking only the currency / timezone of the first product. assume all products of a company uses same currency / timezone
        // NOTE: Will need to amend API if a company has multi currency / timezone products
        const currency = products.length !== 0 ? first(products).currency : 'GBP';
        const timezone = products.length !== 0 ? first(products).timezone : 'Europe/London';
        const override_distance = override_distance_in_metres ? getOverrideDistance(distance_units, override_distance_in_metres) : null;
        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">
                    {error && (
                        <div className="alert alert-danger mb-4" role="alert">
                            <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                            {error}
                        </div>
                    )}
                    <div className="row">
                        {/* Loader */}
                        {isFetching && <SpinnerBody />}

                        {/* Table */}
                        {!isFetching && !isEmpty(selected) && (
                            <div className="col-sm-12 col-lg-7">
                                <div className="row"> 
                                    <div className="col-lg-12">
                                        <JourneyMap journey={selected} />
                                    </div>
                                </div>
                            </div>
                        )}

                        {!isFetching && !isEmpty(selected) && (
                            <div className="col-sm-12 col-lg-5">
                                <div className="row"> 
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div class="card-header">
                                                <h3 className="card-title">
                                                    {lang.t('policy.journeys.where')}
                                                </h3>
                                                <div className="card-actions d-flex align-items-center">
                                                    {override_distance_in_metres && 
                                                        <div className="badge bg-yellow mr-2">{lang.t('policy.journeys.distanceOverridden')}</div>
                                                    }
                                                    {is_void && <div className="badge">{lang.t('policy.journeys.journeyVoided')}</div>}
                                                    {policy_version_reference && <div className="badge bg-green">{lang.t('policy.journeys.billed')}</div>}
                                                    {!is_void && !policy_version_reference &&
                                                        <button
                                                            className="btn btn-sm btn-red"
                                                            style={{ boxShadow: '1px 1px 1px gray' }}
                                                            disabled={isSubmitting} 
                                                            onClick={e => this.voidJourney(e, journey_reference)}
                                                        >
                                                            {isSubmitting ? <div className="spinner-border"/> : lang.t('policy.journeys.voidJourney')}
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <b>{lang.t('policy.journeys.start')}</b>
                                                    </div>
                                                    <div className="col-8">
                                                        <b>{selected.start_name}</b> ({selected.start_postcode})
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <b></b>
                                                    </div>
                                                    <div className="col-8 text-muted">
                                                        {parse_date(selected.start_at, 'llll', timezone)}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <b>{lang.t('policy.journeys.end')}</b>
                                                    </div>
                                                    <div className="col-8">
                                                        <b>{selected.end_name}</b> ({selected.end_postcode})
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-4">
                                                        <b></b>
                                                    </div>
                                                    <div className="col-8 text-muted">
                                                        {parse_date(selected.end_at, 'llll', timezone)}
                                                    </div>
                                                </div>
                                                {override_distance_in_metres && 
                                                    <div className="row mb-2">
                                                        <div className="col-4">
                                                            <b>{lang.t('policy.journeys.premium')} ({lang.t('policy.journeys.override')})</b>
                                                        </div>
                                                        <div className="col-8">
                                                            {getNumberFormat(currency, selected.override_usage_premium, '$0,0.00')}
                                                        </div>
                                                    </div>                                                
                                                }
                                                <div className="row mb-2">
                                                    <div className={`col-4 ${override_distance_in_metres? 'text-muted' : ''}`}>
                                                        <b>{lang.t('policy.journeys.premium')}</b>
                                                    </div>
                                                    <div className={`col-8 ${override_distance_in_metres? 'text-muted text-decoration-line-through' : ''}`}>
                                                        {getNumberFormat(currency, selected.usage_premium, '$0,0.00')}
                                                    </div>
                                                </div>
                                                {override_distance_in_metres && 
                                                    <div className="row mb-2">
                                                        <div className="col-4">
                                                            <b>{lang.t('policy.journeys.distance')} ({lang.t('policy.journeys.override')})</b>
                                                        </div>
                                                        <div className="col-8">
                                                            {distance_units === 'miles' ?
                                                                `${getNumberFormat(currency, override_distance, '0,0[.]0')} miles` : 
                                                                `${getNumberFormat(currency, override_distance, '0,0[.]0')} km` 
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row mb-2">
                                                    <div className={`col-4 ${override_distance_in_metres? 'text-muted' : ''}`}>
                                                        <b>{lang.t('policy.journeys.distance')}</b>
                                                    </div>
                                                    <div className={`col-8 ${override_distance_in_metres? 'text-muted text-decoration-line-through' : ''}`}>
                                                        {distance_units === 'miles' ?
                                                            `${getNumberFormat(currency, selected.total_miles, '0,0[.]0')} miles` : 
                                                            `${getNumberFormat(currency, selected.total_kms, '0,0[.]0')} km` 
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-4">
                                                        <b>{lang.t('policy.journeys.usageRate')}</b>
                                                    </div>
                                                    <div className="col-8">
                                                        {getNumberFormat(currency, selected.usage_rate, getUsageFormat(selected.usage_rate))}
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-4">
                                                        <b>{lang.t('policy.journeys.journey')}</b>
                                                    </div>
                                                    <div className="col-8">
                                                        {selected.journey_reference}
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-4">
                                                        <b>{lang.t('policy.journeys.policy')}</b>
                                                    </div>
                                                    <div className="col-8">
                                                        <a onClick={() => {
                                                            this.props.history.push(`/policys/detail/${selected.policy_reference}`)
                                                        }} className="btn btn-sm btn-primary text-white">{selected.policy_reference}</a>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-4">
                                                        <b>{lang.t('policy.statements.statement')}</b>
                                                    </div>
                                                    <div className="col-8">
                                                        {selected.policy_version_reference && <a onClick={() => {
                                                            this.props.history.push(`/statements/detail/${selected.policy_version_reference}`)
                                                        }} className="btn btn-sm btn-primary text-white">{selected.policy_version_reference}</a>}
                                                        {!selected.policy_version_reference &&  '-'}
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-4">
                                                        <b>{lang.t('policy.journeys.provider')}</b>
                                                    </div>
                                                    <div className="col-8">
                                                        {selected.provider_reference && <a onClick={() => {
                                                            this.props.history.push(`/telematics/journey/${selected.provider_reference}`)
                                                        }} className="btn btn-sm btn-primary text-white">{selected.provider_reference}</a>}
                                                        {!selected.provider_reference && '-'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default main(PolicyJourneyDetail);