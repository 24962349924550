import { toUpper } from "../../utils/format";

export function to_upper(str = '') {
    return str.toUpperCase();
}

export function to_lower(str = '') {
    return str.toLowerCase();
}

export function to_capitalise(str = '') {
    str = str.trim().split(" ");
    for (let i = 0, x = str.length; i < x; i++) {
        str[i] = (str[i][0] || '').toUpperCase() + str[i].substr(1);
    }
    return str.join(" ");
}
