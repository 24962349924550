import React from 'react'
import { startCase } from 'lodash';
import Input from '../../Inputs/Input'

export default function FormAddress(props) {
    const { line_1, line_2, city, postcode, country } = props.data;
    const lang = props.lang;

    return (
        <div className='card ml-2'>
            <div className='card-header'>
                <h3 className='card-title'>{lang.t('components.formAddress.address')}</h3>
            </div>
            <div className='card-body'>
                <div className='mb-1'>
                    <Input
                        label={startCase(lang.t('components.formAddress.lineOne'))}
                        value={line_1}
                    />
                    <Input
                        label={startCase(lang.t('components.formAddress.lineTwo'))}
                        value={line_2}
                    />
                    <Input
                        label={lang.t('components.formAddress.city')}
                        value={city}
                    />
                    <Input
                        label={lang.t('components.formAddress.postcode')}
                        value={postcode}
                    />
                    <Input
                        label={lang.t('components.formAddress.country')}
                        value={country}
                    />
                </div>
            </div>
        </div>
    )
}
