import React, { Component } from 'react';
import Input from './Input';
import { get } from 'lodash';

class FormContacts extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { email, phone_number, update, lang } = this.props;

        return (
            <div className="card ml-2">
                <div className="card-header">
                    <h3 className="card-title">{lang.t('policy.nav.policyContactDetails')}</h3>
                </div>
                <div className="card-body">
                    <div className="mb-1">
                        <div className='alert alert-info mb-4' role="alert">
                            <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                            {lang.t('policy.policies.contactDetailsDescription')}
                        </div>
                        <Input 
                            property={'email'}
                            label={lang.t('policy.policies.email')}
                            value={email}
                            update={update}
                            type = 'text'
                        />
                        <Input 
                            property={'phone_number'}
                            label={lang.t('policy.policies.phoneNumber')}
                            value={phone_number}
                            update={update}
                            type = 'text'
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default FormContacts;