import moment from 'moment';
import { first } from 'lodash';

export function getCurrentVersion(versions) {
    const currentMoment = moment().format();

    const first_version = first(versions);

    if(first_version.effective_date > currentMoment) {
        return first_version;
    };

    const versionsToMoment = versions.filter(version => version.effective_date <= currentMoment);

    const currentVersion = versionsToMoment.reduce((acc, currVal) => (acc.effective_date > currVal.effective_date ? acc : currVal));

    return currentVersion;
}; 