import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import main from '../../../../hoc/main';
import { times, random, round, first } from 'lodash';
import moment from 'moment';
import { chartDateFormat } from '../../../../utils/format';

// Core Design
import SpinnerBody from '../../../../components/loaders/SpinnerBody';
import DropDown from '../../../../components/forms/Dropdown';

// Service Design
import JourneysTable from '../../components/Analytics/JourneysTable';
import SalesTable from '../../components/Analytics/SalesTable';
import TrafficChart from '../../components/Analytics/TrafficChart';
import StatChart from '../../components/Analytics/StatChart';

class JourneyDash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            range: 'month',
        }
        this.selectRange = this.selectRange.bind(this);
        this.getData = this.getData.bind(this);
    }

    componentWillMount() {
        this.getData();
    }

    getData() {
        this.props.policys_analytics_get({
            id: 'journeys',
            period: this.state.range,
        })

        if(!this.props.policys_products.data) {
            this.props.policys_products_get_all({});
        }
    }

    selectRange ({ range = '' }) {
        this.setState({
            range: range.toLowerCase(),
        }, () => {
            this.getData();
        })
    }

    render() {
        const {
            isFetching = true,
            selected = {},
        } = this.props.policys_analytics;
        
        const { range = '' } = this.state;
        const { segments = [], total = {} } = selected
        
        const chart_format = chartDateFormat(range);
        
        const { data: products = [] } = this.props.policys_products;
        // Checking only the currency of the first product. assume all products of a company uses same currency
        const currency = products.length !== 0 ? first(products).currency : 'GBP';
        const distance_units = products.length !== 0 ? first(products).distance_units : 'miles';
        const distance_modifier = distance_units === 'miles' ? 1606 : 1000;
        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-sm-12 col-lg-6">
                            <div className="page-pretitle">{lang.t('nav.journeys')}</div>
                            <h2 className="page-title">{lang.t('nav.dashboard')}</h2>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            <div className="form-label">{lang.t('policy.policies.range')}</div>
                            <DropDown
                                ref='range'
                                label='range'
                                value={range}
                                update={(range) => {
                                    this.selectRange(range);
                                }}
                                items={['day', 'week', 'month']}
                                labelMap={{
                                    'day': lang.t('time.day'), 
                                    'week': lang.t('time.week'), 
                                    'month': lang.t('time.month'),
                                }}
                            />
                        </div>
                    </div>
                    
                    {/* Loader */}
                    {isFetching && <SpinnerBody />}

                    {!isFetching && segments.length > 0 && 
                        <div className="row">
                            <div className="col-sm-9 col-lg-9">
                                <div className="card">
                                    <div className="ml-3 mr-3 mt-3">
                                        <TrafficChart 
                                            series={[
                                                {
                                                    name: lang.t('policy.statements.journeys'),
                                                    data: segments.map(d => d.count),
                                                },
                                            ]}
                                            annotations={[]}
                                            labels={segments.map(t => {
                                                return moment(t.timestamp, 'X').toISOString();
                                            })}
                                            format={chart_format}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-lg-3">
                                <StatChart 
                                    series={[
                                        {
                                            name: `${lang.t('policy.journeys.distance')} (${distance_units})`,
                                            data: segments.map(d => round(d.distance_in_metres / distance_modifier, 2)),
                                        },
                                    ]}
                                    name={`${lang.t('policy.journeys.distance')} (${distance_units})`}
                                    labels={segments.map(t => {
                                        return moment(t.timestamp, 'X').toISOString();
                                    })}
                                    format={chart_format}
                                    currency={currency}
                                />
                                <StatChart 
                                    series={[
                                        {
                                            name: lang.t('policy.journeys.premium'),
                                            data: segments.map(d => d.usage_premium),
                                        },
                                    ]}
                                    name={lang.t('policy.journeys.premium')}
                                    labels={segments.map(t => {
                                        return moment(t.timestamp, 'X').toISOString();
                                    })}
                                    format={chart_format}
                                    currency={currency}
                                />
                            </div>
                        </div>
                    }

                    {!isFetching &&
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <JourneysTable 
                                    segments={segments}
                                    currency={currency}
                                    distance_units={distance_units}
                                    lang={lang}
                                />
                            </div>
                        </div>

                        
                    }
                 
              </div>
          </div>
        );
    }
}

export default main(JourneyDash);
