import React, { Component } from 'react';

class DropDownFormat extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { selectFormat } = this.props;
        return (
            <select class="form-select" onChange={(e) => {
                e.preventDefault();
                selectFormat(e.target.value);
            }}>
                <option value="accrued">Accrued</option>
                <option value="policy_start_date">Start Date</option>
            </select>
        );
    }
}

export default DropDownFormat;