import React, { Component } from 'react';
import { first } from 'lodash';

const langs = [
    {
        key: 'en',
        flag: 'gb',
    },
    {
        key: 'fr',
        flag: 'fr',
    },
    {
        key: 'it',
        flag: 'it',
    },
    {
        key: 'es',
        flag: 'es',
    },
];

class Language extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { selectedLang, lang, selectLang } = this.props;
        const selected = first(langs.filter(l => l.key === selectedLang));
        return (
            <li className="nav-item dropdown">
                <a href="#" className="nav-link" data-toggle="dropdown">
                    <span className={`flag flag-country-${selected.flag} mr-1`} />
                    <span className="d-none d-lg-inline">{lang.tc(`languages.${selected.key}`)}</span>
                </a>
                <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
                    {langs.map(l => (
                        <a
                            key={l.key} href="#" onClick={(e) => {
                                e.preventDefault();
                                selectLang(l.key);
                            }} className="dropdown-item"
                        >
                            <span className={`flag flag-country-${l.flag} mr-1`} />
                            {lang.tc(`languages.${l.key}`)}
                        </a>
                    ))}
                </div>
          </li>
        );
    }
}

export default Language;
