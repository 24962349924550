import React, { Component } from 'react';
import { isEmpty, uniqBy, first } from 'lodash';
import moment from 'moment';
import { getLangSource } from '../../../lang';

import main from '../../../hoc/main';

// Core Design
import SpinnerBody from '../../../components/loaders/SpinnerBody';
import Empty from '../../../components/empty/Results';
import PageHeader from '../../../components/layout/PageHeader';

// Service Design
import RatingBillingTable from '../components/tables/RatingBilling';

function uniq_models(models = []) {
    const obj = {};
    const enriched = uniqBy(models.sort(m => moment(m.published_at)).map(model => {
        return {
            ...model,
            month: moment(model.published_at).format('MM-YYYY'),
        }
    }), 'model_reference').forEach(m => {
        obj[m.model_reference] = m;
    });

    return obj;
}

class Users extends Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.props.pricing_models_get_all({});
        await this.props.pricing_billing_get_all({
            start_at: '2021-01-01T00:00:00Z'
        });
        await this.props.policys_products_get_all({});
    }

    render() {
        const { isFetching, selected, data: billing_data } = this.props.pricing_billing;
        const pricing_models = uniq_models(this.props.pricing_models.data);
        const { data: products = [] } = this.props.policys_products;
        const lang = getLangSource(this.props.lang);
        
        const currency = products.length !== 0 ? first(products).currency : 'GBP';

        return (
            <div className="my-3 my-md-5">
                <div className="container">
                {isFetching && 
                    <div className="row">
                        <SpinnerBody
                            message={lang.t('settings.billing.gettingBillingDetails')}
                            selectedProduct={this.props.selectedProduct}
                        />
                    </div>
                }

                {!isFetching &&
                    <PageHeader
                        section={lang.t('settings.title')}
                        page={lang.t('settings.billing.title')}
                    />
                }

                    <div className="row">
                        
                        {!isFetching && !isEmpty(billing_data) &&
                            <div className="col-sm-12 col-lg-12">
                                {billing_data.segments.length === 0 && <Empty label={lang.t('settings.billing.ratingBilling')} lang={lang} />}
                                {billing_data.segments.length > 0 && <RatingBillingTable
                                    data={billing_data}
                                    pricing_models={pricing_models}
                                    pages={10}
                                    currency={currency}
                                    lang={lang}
                                />}
                            </div>
                        }

                    </div>
                </div>
            </div>
        );
    }
}

export default main(Users);