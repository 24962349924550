import { first } from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Badge from '../../../../atoms/Badge';
import { to_capitalise, to_upper } from '../../../../components/format/string';
import { getPolicyStatus } from '../../../../utils/getPolicyStatus';
import { parse_date } from './Utils';

const PolicyHeaderStyle = styled.div`
    background: #1f2936;
    color: #f5f7fb;
    display: flex;
    align-items: center;
    min-height: 2.25rem;
    margin: 0 0 1.25rem;
    .row-align {
        display: flex;
        flex-wrap: wrap;
        align-items: center !important;
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
        margin-left: 1rem !important;
        justify-content: space-between !important;
        width: 100%;
    }
    .column-one {
        flex: 0 0 auto;
        width: 50%;
    }
    .column-two {
        margin-left: auto !important;
        margin-right: 1.25rem !important;
        flex: 0 0 auto;
        width: auto;
    }
    .bold-text {
        font-weight: 600;
    }
    .text-muted {
        font-size: 0.8125rem !important;
        color: #868c97 !important;
    }
    .muted-right {
        text-align: right !important;
    }
`;

const PolicyHeader = (props) => {
    const { proposer = {}, policy = {}, vehicle = {}, policy_reference, versions } = props.policy_detail;

    const { timezone } = props;

    const version = first(versions);
    const { end_date } = version;
    const status = getPolicyStatus(version);

    // This variable will show the correct date for the respective timezone
    const end_date_cleaned = parse_date(end_date, 'll', timezone);

    return (
        <PolicyHeaderStyle>
            <div className="row-align">
                <div className="column-one">
                    <div className="bold-text">
                        {to_capitalise(proposer.first_names)} {to_capitalise(proposer.last_names)} /{' '}
                        {to_upper(vehicle.reg)} / {to_upper(policy_reference)}
                    </div>
                    <div className="text-muted">
                        {policy.address.line_1}, {policy.address.city}, {policy.address.postcode}
                    </div>
                </div>
                <div className="column-two">
                    <Link to={`/settings/products/${policy.product_reference}`}>
                        <Badge
                            backgroundColor="white"
                            text={policy.product_reference}
                            marginRight={true}
                            blackText={true}
                        />
                    </Link>
                    {status === 'active' && <Badge backgroundColor="green" text={status} />}
                    {status.text === 'expiring' && (
                        <>
                            <Badge backgroundColor="orange" text={status.text} />
                            <div className="text-muted-right">{`on ${end_date_cleaned}`}</div>
                        </>
                    )}

                    {status === 'lapsed' && <Badge backgroundColor="grey" text={status} />}
                    {status === 'cancelled' && (
                        <>
                            <Badge backgroundColor="red" text={status} />
                            <div className="text-muted-right">{`on ${end_date_cleaned}`}</div>
                        </>
                    )}
                    {status === 'cancelling' && (
                        <>
                            <Badge backgroundColor="yellow" text={status} />
                            <div className="text-muted muted-right">{`on ${end_date_cleaned}`}</div>
                        </>
                    )}
                </div>
            </div>
        </PolicyHeaderStyle>
    );
};

export default PolicyHeader;
