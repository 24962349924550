//TODO config select from url. Change to be in built distro via NODE_ENV
// import { get } from 'lodash';
// const env = get(process, 'env.NODE_ENV', 'development');

const env = (() => {
    const url = window.location.href;
    console.log("url output:");
    console.log(url);
    if (url.includes('admin.test.bymiles') === true) return 'test';
    if (url.includes('admin.bymiles') === true) return 'production';
    return 'development'
})();

let base = {
    root: 'http://localhost:3001',
    map_tiler_key: 'PbUY2xk6No6rPxN54Q4r',
    // aws: {
    //     cognito: {
    //         domain: 'bdx-services.auth.eu-west-2.amazoncognito.com',
    //         userPoolId: 'eu-west-2_J59yheq4K',
    //         userPoolWebClientId: '12juo20i69ens47lf0k3425i9h',
    //         identityPoolId: 'eu-west-2:23eb239e-d7a6-4d1c-983d-1db66eeadc19',
    //         region: 'eu-west-2',
    //         scope: [
    //             'phone',
    //             'email',
    //             'profile',
    //             'openid'
    //         ],
    //         responseType: 'token'
    //     }
    // },
    services: {
        autocrat: {
            root: 'not-used'
        },
        payments: {
            root: 'not-used',
        },
        apis: {
            root: 'http://localhost:22222/api',
        }
    },
    Policy_Expiry_Threshold_Days: 30,
}

if (env === 'test') {
    base = {
        ...base,
        root: 'https://admin.test.bymiles.co.uk',
        // aws: {
        //     cognito: {
        //         domain: 'http://bymiles-test-rating-platform-web-ui.s3-website.eu-west-2.amazonaws.com',
        //         region: 'eu-west-2',
        //         userPoolId: 'eu-west-1_DmP6GT3Ut',
        //         userPoolWebClientId: '4t3a61erh4a27qa0so6rnuetue',
        //         identityPoolId: 'eu-west-1:b4841b57-5f75-4e87-9d5d-462db7112744',
        //         scope: [
        //             'phone',
        //             'email',
        //             'profile',
        //             'openid'
        //         ],
        //         responseType: 'token'
        //     }
        // },
        services: {
            autocrat: {
                root: 'not-used'
            },
            payments: {
                root: 'not-used',
            },
            apis: {
                root: 'https://admin.test.bymiles.co.uk/api',
            }
        }
    }
}

if (env === 'production') {
    base = {
        ...base,
        root: 'https://admin.bymiles.co.uk',
        // aws: {
        //     cognito: {
        //         domain: 'prod-autocrat.auth.eu-west-2.amazoncognito.com',
        //         userPoolId: 'eu-west-2_n0XTENA2X',
        //         userPoolWebClientId: '6lhnlo3sf62q7ae00opqeflt0t',
        //         region: 'eu-west-2',
        //         identityPoolId: 'eu-west-2:91eab584-8f34-49b8-9d3c-5b895ca53362',
        //         scope: [
        //             'phone',
        //             'email',
        //             'profile',
        //             'openid'
        //         ],
        //         responseType: 'token'
        //     }
        // },
        services: {
            autocrat: {
                root: 'not-used'
            },
            payments: {
                root: 'not-used',
            },
            apis: {
                root: 'https://admin.bymiles.co.uk/api',
            }
        }
    }
}

export default base;