import React, { Component } from 'react';
import { getLangSource } from '../../../lang';

import getData from '../../../utils/getData';

import main from '../../../hoc/main';

// Core Design
import SpinnerBody from '../../../components/loaders/SpinnerBody';

// Service Design
import { isEmpty, times } from 'lodash';

class Users extends Component {
    constructor(props) {
        super(props);
        this.switchAccount = this.switchAccount.bind(this);
    }

    async componentDidMount() {
        const { data = {} } = getData(this.props.auth);
        await this.props.settings_companys_get({
            id: data.company_reference,
        });
        await this.props.settings_companys_get_all({});
    }

    async switchAccount(account_id) {
        await this.props.settings_superaccess_post({
            data: { account_id }
        });
        window.location.reload();
    }

    render() {
        const { isFetching, selected, data } = getData(this.props.settings_companys);
        const { data: auth_user } = getData(this.props.auth);
        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">
                {isFetching && 
                    <div className="row">
                        <SpinnerBody
                            message={lang.t('settings.company.gettingCompany')}
                            selectedProduct={this.props.selectedProduct}
                        />
                    </div>
                }

                {!isFetching &&
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="page-pretitle">
                                    {lang.t('settings.company.title')}
                                </div>
                                <h2 className="page-title">
                                    {lang.t('settings.title')}
                                </h2>
                            </div>
                            <div className="col-auto ml-auto d-print-none">
                                {auth_user.god === true &&
                                    <select onChange={(e) => {
                                        return this.switchAccount(e.target.value)
                                    }} className={`form-select custom-select`}>
                                        {data.map(item => {
                                            return(<option selected={item.company_reference === auth_user.company_reference} value={item.account_id}>{item.name}</option>);
                                        })}
                                    </select>
                                }
                            </div>
                        </div>
                    </div>
                }

                {!isFetching &&
                    <div className="row">
                        
                        {!isEmpty(selected) &&
                            <div className="col-sm-12 col-lg-6">
                                <table 
                                    className="table table-vcenter table-mobile-md card-table mb-3" 
                                    style={{ background: 'white', border: '1px solid #868c973d' }}
                                >
                                    <thead>
                                        <tr>
                                            <th>{lang.t('settings.company.companyInfo')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding:0 }}>
                                                <div className="card-body">
                                                    <dl className="row">
                                                        <dt className="col-5">{lang.t('settings.company.name')}</dt>
                                                        <dd className="col-7">{selected.name}</dd>

                                                        <dt className="col-5">{lang.t('settings.company.email')}</dt>
                                                        <dd className="col-7">{selected.email}</dd>

                                                        <dt className="col-5">{lang.t('settings.company.phone')}</dt>
                                                        <dd className="col-7">{selected.landline}</dd>

                                                        <dt className="col-5">{lang.t('settings.company.address')}</dt>
                                                        <dd className="col-7">
                                                            {selected.address_1}<br/>
                                                            {selected.address_2}<br/>
                                                            {selected.address_postcode}<br/>
                                                            {selected.address_city}
                                                        </dd>

                                                        <dt className="col-5">{lang.t('settings.company.taxNumber')}</dt>
                                                        <dd className="col-7">{selected.tax_number_1 || '-'}</dd>

                                                    </dl>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }

                        {!isEmpty(selected) && 
                            <div className="col-sm-12 col-lg-6">
                                {selected.products.map(p => {
                                    return (
                                        <table 
                                            className="table table-vcenter table-mobile-md card-table mb-3" 
                                            style={{ background: 'white', border: '1px solid #868c973d' }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th><div className="bg-green badge">{p.label}</div></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ padding:0 }}>
                                                        <div className="card-body">
                                                            <dl className="row">
                                                                <dt className="col-5">{lang.t('settings.company.name')}</dt>
                                                                <dd className="col-7">{p.label}</dd>

                                                                <dt className="col-5">{lang.t('settings.company.description')}</dt>
                                                                <dd className="col-7">{p.description}</dd>

                                                                <dt className="col-5">{lang.t('settings.company.monthlyMinSub')}</dt>
                                                                <dd className="col-7">£{p.subscription_rate}</dd>

                                                            </dl>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )
                                })}
                            </div>
                        }

                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default main(Users);