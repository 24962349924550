import L from '1liner';

import enrichQuote from './enrichQuote';
import { isNumber, max, min, isArray } from 'lodash';

// Safer than eval
function evalExpression(expression){
    const funcExpression = `function(min, max){ return ${expression}}` 
    return Function('"use strict";return (' + funcExpression + ')')()(min, max);
}

function makeArray(value = []) {
    if (isNumber(value[0])) {
        return '[' + value.join(',') + ']';
    }
    return '["' + value.join('","') + '"]';
}

function getExclusionFromStr(str = '', quote) {
    let expression = str;
    let queries = [];
    if (!(str.includes('{') && str.includes('}'))) throw new Error('No {} in expression');
    const elements = str.split('{');
    elements.forEach(el => {
        const elements = el.split('}');
        if (elements.length > 1) {
            const value = quote.query(elements[0]);
            if (isNumber(value)) {   
                expression = expression.replace('{' + elements[0] + '}', value);
            } else if (isArray(value)) {
                expression = expression.replace('{' + elements[0] + '}', makeArray(value));
            } else {
                expression = expression.replace('{' + elements[0] + '}', '"' + value + '"');
            }
            queries.push({
                query: elements[0],
                value,
            });
        };
    });
    return {
        queries,
        expression,
    };
}

export default function(ex, quote = {}) {
    const enrichedQuote = new L(enrichQuote(quote));
    try {
        const { expression, queries } = getExclusionFromStr(ex, enrichedQuote);
        const exclude = evalExpression(expression);
        return {
            is_valid: true,
            queries,
            expression,
            exclude,
        };
    } catch(e) {
        return { is_valid: false, message: e.message }
    }
};