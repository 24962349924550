import React, { Component } from 'react';
import Avatar from '../../../../components/avatar';
import { get } from 'lodash';
import base from '../../../../config';
import axios from 'axios';

class PolicyUsersTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email_success: null,
        }

        this.resendPassword = this.resendPassword.bind(this);
    }

    async resendPassword() {
        const { data } = this.props;

        try {

            const username = get(data[0], 'username');
            const cognito_status = get(data[0], 'cognito_status');

             // If user status is "FORCE_CHANGE_PASSWORD" then payload to have "RESEND_PASSWORD" type. Otherwise it is "FORGOT_PASSWORD"

            const requestBody = {
                username,
                type: cognito_status === "FORCE_CHANGE_PASSWORD" ? "RESEND_PASSWORD" : "FORGOT_PASSWORD",
            }

            // Generate new temp password

            await axios({
                url: base.services.apis.root + '/auth/token',
                method: 'POST',
                data: JSON.stringify(requestBody),
                headers: {
                    'Content-Type': 'application/json',
                    'environment': 'production',
                }
            });

            this.setState({ email_success: true });

        } catch (error) {
            this.setState({ email_success: false });

            if (error.response) {
                const { message, status } = error.response.data;
                throw {
                    message: message || error.response.data,
                    status: status || error.response.status,
                };
            } else if (error.request) {
                throw {
                    message: 'Server Error',
                    status: 500,
                }
            } else {
                throw {
                    message: error.data,
                }
            }
        } finally {
            setTimeout(() => this.setState({ email_success: null }), 5000) // Reset button status after 5 seconds
        }
    }

    render() {
        const { data, lang } = this.props;
        const { email_success } = this.state;
        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.email')}</th>
                                <th>{lang.t('tableHeaders.username')}</th>
                                <th>{lang.t('tableHeaders.authenticationStatus')}</th>
                                <th colSpan={1} />
                            </tr>
                        </thead>
                        <tbody>
                        {data.map((d, i) => (
                            <tr key={`users-${i}`}>
                                <td data-label="Name">
                                    <div className="d-flex lh-sm py-1 align-items-center">
                                        <div className="mr-2">
                                            <Avatar 
                                                f_names={d.email}
                                                l_names={d.email}
                                            />
                                        </div>
                                        <div className="flex-fill">
                                            <div className="strong">{d.email}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>{d.username}</td>
                                <td>{d.cognito_status}</td>
                                <td className="w-1">
                                    <div className="btn-list flex-nowrap">
                                        { email_success === true && (
                                            <a
                                                className="btn btn-green btn-sm text-white"
                                                onClick={this.resendPassword}
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                {lang.t('policy.policies.emailSent')}
                                            </a>
                                        )} 
                                        { email_success === false && (
                                            <a
                                                className="btn btn-red btn-sm text-white"
                                                onClick={this.resendPassword}
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                {lang.t('policy.policies.emailError')}
                                            </a>
                                        )}
                                        { email_success === null && (
                                            <a
                                                className="btn btn-primary btn-sm text-white"
                                                onClick={this.resendPassword}
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                {lang.t('policy.policies.resetPassword')}
                                            </a>
                                        )} 
                                        
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
          </div>
        );
    }
}

export default PolicyUsersTable;