import React, { Component } from 'react';

import PaymentsTable from '../../Payments/PaymentsTable';
import UnpaidTable from '../../Payments/UnpaidTable';

class PaymentSection extends Component {

    render () {
        const { versions, payments, unpaid, policys_payments_post, currency, timezone, credits, lang } = this.props;
        return (
            <div className="row"> 
                <div className="col-lg-12">
                    <div className="card-tabs" style={{ position: 'relative'}}>    
                        <ul className="nav nav-tabs">
                            <li className="nav-item"><a href="#tab-top-1" className="nav-link active" data-toggle="tab">{lang.t('policy.policies.unsuccessfulPayments')}</a></li>
                            <li className="nav-item"><a href="#tab-top-2" className="nav-link" data-toggle="tab">{lang.t('policy.policies.successfulPayments')}</a></li>
                            {payments.length >= 1 && 
                                <div className="badge bg-primary payments-section-customer-ref">{`CUSTOMER REF: ${payments[0].payment_provider_customer_ref}`}</div>
                            }
                        </ul>
                        <div className="tab-content">
                            <div id="tab-top-1" className="card tab-pane active show">

                                <UnpaidTable 
                                    unpaid={unpaid}
                                    policys_payments_post={policys_payments_post}
                                    currency={currency}
                                    timezone={timezone}
                                    lang={lang}
                                />
                            </div>
                            <div id="tab-top-2" className="card tab-pane">
                                <PaymentsTable 
                                    versions={versions}
                                    payments={payments}
                                    currency={currency}
                                    timezone={timezone}
                                    credits={credits}
                                    lang={lang}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PaymentSection;
