import React, { Component } from 'react';
import AlertMessage from '../../../../components/alerts/AlertMessage';
import { uploadFile } from './S3Helpers';
import { round } from 'lodash';

export default class FormUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: 'odometer',
            selectedFile: undefined,
            showError: false,
            errorIcon: '',
            errorText: '',
            uploadProgress: 0,
            isUploading: false
        }
        this.addFile = this.addFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.verifyFile = this.verifyFile.bind(this);
        this.updateUploadProgress = this.updateUploadProgress.bind(this);
        this.toggleUploadStatus = this.toggleUploadStatus.bind(this);
    }

    handleChange(e) {
        this.setState({
            selectedCategory: e.target.value
        })
    }

    updateUploadProgress(e) {
        const uploadProgress = e.loaded / e.total;
        this.setState({
            uploadProgress,
        });
    }

    toggleUploadStatus() {
        this.setState({
            isUploading: !this.state.isUploading
        })
    }

    verifyFile() {
        const { lang } = this.props;
        const NO_FILE_ERROR_MSG = lang.t('policy.policies.fileError');
        const NO_IMAGE_ERROR_MSG = lang.t('policy.policies.imageError');
        const NO_EXTENSION_ERROR_MSG = lang.t('policy.policies.extensionError');
        const { selectedFile } = this.state;
        if (!selectedFile) {
            this.setState({
                showError: true,
                errorIcon: 'folder',
                errorText: NO_FILE_ERROR_MSG
            })
            return false
        }
        const ACCEPTABLE_FILES = ['png', 'jpg', 'jpeg', 'gif', 'raw', 'tiff', 'bmp', 'mp4', 'avi'];
        const fileExtension = selectedFile.type.split('/').pop();
        if (!fileExtension) {
            this.setState({
                showError: true,
                errorIcon: 'file',
                errorText: NO_EXTENSION_ERROR_MSG
            })
            return false
        } else if (!ACCEPTABLE_FILES.includes(fileExtension)) {
            this.setState({
                showError: true,
                errorIcon: 'file',
                errorText: NO_IMAGE_ERROR_MSG
            }) 
            return false
        } 
        return true
    }

    async addFile() {
        if (!this.verifyFile()) { return }
        const { selectedFile, selectedCategory } = this.state;
        const fileExtension = selectedFile.type.split('/').pop();
        const { policys_kyc_post, policy_reference, updateStage, policys_kyc_get } = this.props;
        try {
            this.toggleUploadStatus()
            await uploadFile({
                policys_kyc_post, 
                policy_reference, 
                selectedCategory, 
                fileExtension,
                selectedFile
                },
                e => this.updateUploadProgress(e));
            await policys_kyc_get({ id: policy_reference });
        } catch(err) {
            console.log(err);
        } 
        updateStage('addFileSuccess');
    }

    render() {
        const { showError, errorIcon, errorText, uploadProgress, isUploading } = this.state;
        const { lang } = this.props;
        const progressPercent = round(uploadProgress * 100);
        return (
            <>
                <div className='modal-body'>
                    <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                            <label className='input-group-text' style={{width: '120px'}} htmlFor='category'>
                                {lang.t('policy.policies.documentType')}
                            </label>
                        </div>
                        <select className='form-control' id='category' onChange={(e) => this.handleChange(e)}>
                            <option value='odometer'>Odometer</option>
                            <option value='ncd'>No claims discount</option>
                            <option value='address'>Address</option>
                            <option value='licence'>Licence</option>
                            <option value='id'>Identification</option>
                            <option value='medical'>Medical</option>
                        </select>
                    </div>
                    {showError && 
                        <AlertMessage 
                            icon={errorIcon} 
                            message={errorText} 
                        />}
                    <div className='input-group mb-1'>
                        <div className='input-group-prepend'>
                            <label className='input-group-text' style={{width: '120px'}} htmlFor='file'>
                                {lang.t('policy.policies.file')}
                            </label>
                        </div>
                        <input 
                            className="form-control form-control-sm p-1" 
                            type="file" 
                            id="file"
                            onChange={(e) => this.setState({
                                selectedFile: e.target.files[0]
                            })}
                        />
                    </div>
                    <h5 style={{ marginTop: '10px' }}>{lang.t('policy.policies.acceptableFileTypes')}: png, jpg, jpeg, gif, raw, tiff, bmp, mp4, avi</h5>
                    {isUploading && <div className='progress' style={{ marginTop: '10px' }}>
                            <div class="progress-bar" role="progressbar" style={{width: `${progressPercent}%`}} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>}
                </div>
                <div className='modal-footer'>
                    <button
                        type='button'
                        className='btn btn-primary'
                        data-dismiss='modal'
                        onClick={this.addFile}
                    >
                        {lang.t('buttons.add')}
                    </button>
                </div>
            </>
        )
    }
}
