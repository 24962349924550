import React from 'react';
import FormBase from '../Forms/FormBase';
import FormVehicle from '../Forms/FormVehicle';
import FormDriver from '../Forms/FormDriver';
import FormAddress from '../Forms/FormAddress';
import FormCustom from '../Forms/FormCustom';

export default function QuoteSection(props) {
    const { policy, vehicle, proposer, additional_drivers = [], custom = {} } = props.data;
    const timezone = props.timezone;
    const lang = props.lang;

    return (
        <>
            <div className='row'>
                <div className='col-lg-6'>
                    <FormBase 
                        data={policy} 
                        timezone={timezone}
                        lang={lang}
                    />
                    <FormAddress data={policy.address} lang={lang} />
                    <FormVehicle 
                        data={vehicle}
                        timezone={timezone}
                        lang={lang}
                    />
                </div>
                <div className='col-lg-6'>
                    <div className="card-tabs" style={{ position: 'relative'}}>
                        <ul className={`nav nav-tabs ${additional_drivers.length === 4 && 'nav-fill'}`}>
                            <li className='nav-item'>
                                <a href="#tab-top-1" className="nav-link dark active" data-toggle="tab">{lang.t('rating.quoteSection.proposer')}</a>
                            </li>
                            {additional_drivers.map((person, i) => {
                                return (
                                    <li className="nav-item">
                                        <a href={`#tab-top-${i + 2}`} className="nav-link dark" data-toggle="tab">{lang.t('rating.quoteSection.driver')} {i + 1}</a>
                                    </li>
                                );
                            })}
                        </ul>
                        <div className='tab-content'>
                            <div className='card tab-pane active show' id='tab-top-1'>
                                <FormDriver 
                                    data={proposer}
                                    timezone={timezone}
                                    lang={lang}
                                />
                            </div>
                            {additional_drivers.map((person, i) => {
                                return (
                                    <div className='card tab-pane' id={`tab-top-${i + 2}`}>
                                        <FormDriver 
                                            data={person}
                                            timezone={timezone}
                                            lang={lang}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12'>
                    <FormCustom data={custom} lang={lang} />
                </div>
            </div>
        </>
    )
}
