import React, { Component } from 'react';
import { parse_date } from '../Detail/Utils';
import { getNumberFormat } from '../../../../utils/numberFormat';

class PaymentsTable extends Component {
    render () {
        const { payments, versions, currency, timezone, credits, lang } = this.props;

        return (
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.versionType')}</th>
                                <th>{lang.t('tableHeaders.creditApplied')}</th>
                                <th>{lang.t('tableHeaders.versionDate')}</th>
                                <th>{lang.t('tableHeaders.createdAtDate')}</th>
                                <th>{lang.t('tableHeaders.amount')}</th>
                                <th>{lang.t('tableHeaders.transactionRef')}</th>
                                <th>{lang.t('tableHeaders.paymentId')}</th>

                            </tr>
                        </thead>
                        <tbody>
                            {payments.map((p, i) => {
                                const version = versions.find(v => v.policy_version_reference === p.policy_version_reference);
                                const { version_type, effective_date, created_at } = version;
                                const credit = credits.filter(c => c.credit_amount < 0 && (p.policy_version_reference === c.policy_version_reference));

                                return (
                                    <tr key={`payments-${i}`}>
                                        <td data-label="version type">
                                            <div className="d-flex lh-sm py-1 align-items-center">
                                                <div classNmae="flex-fill">
                                                    <div className="strong">{version_type}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-label='credit'>
                                            {credit.length > 0 ? <div className='badge bg-green'>{lang.t('policy.policies.yes')}</div> : <div className='badge bg-grey'>{lang.t('policy.policies.no')}</div>}
                                        </td>
                                        <td data-label="version date">
                                            <div className="text-h5">{parse_date(effective_date, 'DD/MM/YYYY HH:mm:ss', timezone)}</div>
                                        </td>
                                        <td data-label="created date">
                                            <div className="text-h5">{parse_date(created_at, 'DD/MM/YYYY HH:mm:ss', timezone)}</div>
                                        </td>
                                        <td data-label="payment-amount">
                                            <div className="text-h5">{getNumberFormat(currency, p.payment_amount, '$0,0.00')}</div>
                                        </td>
                                        <td data-label="transaction-reference">
                                            <div className="text-h5">{p.payment_provider_transaction_ref}</div>
                                        </td>
                                        <td data-label="payment-method-id">
                                            <div className="text-h5">{p.payment_method_id}</div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default PaymentsTable;