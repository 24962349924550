import React from 'react';
import Input from '../../Inputs/Input';
import { parseDate } from '../../utils/formatDate';

export default function FormBase(props) {
    const { voluntary_excess, requested_usage, inception_date, timezone } = props.data;
    const formattedStartDate = parseDate(inception_date, 'DD/MM/YYYY HH:mm', timezone)
    const lang = props.lang;

    return (
        <div className='card ml-2'>
            <div className='card-header'>
                <h3 className='card-title'>{lang.t('rating.components.formBase.base')}</h3>
            </div>
            <div className='card-body'>
                <div className='mb-1'>
                    <Input 
                        label={lang.t('rating.components.formBase.startDate')}
                        value={formattedStartDate} 
                    />
                    <Input 
                        label={lang.t('rating.components.formBase.voluntaryExcess')}
                        value={voluntary_excess} 
                    />
                    <Input 
                        label={lang.t('rating.components.formBase.usage')}
                        value={requested_usage} 
                    />
                </div>
            </div>
        </div>
    )
}
