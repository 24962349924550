import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getLangSource } from '../../../../lang';
import { extendedDateFormat } from '../../../../utils/format';
import { sortBy, round } from 'lodash';
import SparkRoute from '../Journeys/SparkRoute';
import PolicyModal from '../../../../components/modal/Policy';

function tag_badge(tag_confidence) {
    if (tag_confidence > 0) return (<div className='badge bg-green'>{round(tag_confidence * 100, 1)}</div>)
    return (
        <div className='badge bg-gray'>-</div>
    )
}

class TelematicsJourneysTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], policy_detail = false, lang } = this.props;

        //TODO - Temporary
        const sorted = sortBy(data, d => -new Date(d.end_at)).slice(0, policy_detail ? 100 : 6);

        return (
            <div className="card">

                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.route')}</th>
                                {policy_detail && <th>{lang.t('tableHeaders.policy')}</th>}
                                {policy_detail && <th>{lang.t('tableHeaders.device')}</th>}
                                <th>{lang.t('tableHeaders.start')}</th>
                                <th>{lang.t('tableHeaders.end')}</th>
                                <th>{lang.t('tableHeaders.age')}</th>
                                <th>{lang.t('tableHeaders.tag')}</th>
                                <th>{lang.t('tableHeaders.distanceM')}</th>
                                <th>{lang.t('tableHeaders.duration')}</th>
                                <th className="w-2"/>
                            </tr>
                        </thead>
                        <tbody>
                            {sorted.map((d, i) => (
                                <tr key={`devices-${i}`}>
                                    <td data-label="Spark">
                                        <SparkRoute 
                                            route={d.polyline || ''}
                                            journey_reference={d.journey_reference}
                                        />
                                    </td>
                                    {policy_detail &&
                                        <td data-label="Policy">
                                            <div className="btn-list flex-nowrap">
                                                <PolicyModal 
                                                    policy_reference={d.policy_reference}
                                                    policys_get_all={this.props.policys_get_all}
                                                    lang={lang}
                                                />
                                            </div>
                                        </td>
                                    }
                                    {policy_detail &&
                                        <td data-label="Device">
                                            <div className="btn-list flex-nowrap">
                                                <Link
                                                    to={`/telematics/devices?device_reference=${d.device_reference}`}
                                                    className="btn btn-sm btn-primary"
                                                >{d.device_reference}</Link>
                                            </div>
                                        </td>
                                    }
                                    <td data-label="Start">
                                        <div className="text-h5">{extendedDateFormat(d.start_at)}</div>
                                    </td>
                                    <td data-label="End">
                                        <div className="text-h5">{extendedDateFormat(d.end_at)}</div>
                                    </td>
                                    <td data-label="Age">
                                        <div className="text-h5">{moment(d.end_at).fromNow(true)}</div>
                                    </td>
                                    <td data-label="Tag">
                                        <div className="text-h5">{tag_badge(round(d.tag_confidence, 3))}</div>
                                    </td>
                                    <td data-label="Heartbeat">
                                        <div className="text-muted text-h5">{d.distance_in_metres}</div>
                                    </td>
                                    <td data-label="Distance">
                                        <div className="text-muted text-h5">{d.duration_in_seconds}</div>
                                    </td>
                                    <td>
                                        <div className="btn-list flex-nowrap">
                                            <Link
                                                to={`/telematics/journey/${d.journey_reference}`}
                                                className="btn btn-sm btn-primary"
                                            >{lang.t('policy.table.view')}</Link>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default TelematicsJourneysTable;
