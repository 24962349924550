import numeral from 'numeral';
import 'numeral/locales/en-gb';
import 'numeral/locales/en-au';
import 'numeral/locales/it';

export function getNumberFormat(currency, value, format) {
    const locales = {
        GBP: 'en-gb',
        AUD: 'en-au',
        EUR: 'it',
    }

    numeral.locale(locales[currency]);

    return numeral(value).format(format);
}

export function getUsageFormat(usage_rate) {
    let usageFormat = '$0,0[.]00';
    const usageArr = usage_rate.toString().split('.');
    
    if(usageArr.length !== 1 && usageArr[1].length >= 3) {
        usageFormat = '$0,0[.]000';
    }

    return usageFormat;
}

export function getDistanceFormat(distance) {
    let distanceFormat = '0,0[.]0';
    const distanceArr = distance.toString().split('.');
    
    if(distanceArr.length !== 1 && distanceArr[1].length >= 2) {
        distanceFormat = '0,0[.]00';
    }

    return distanceFormat;
}
