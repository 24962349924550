import React, { Component } from 'react';
const moment = require('moment');
const { round } = require('lodash');

class Policy extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            policy,
        } = this.props;
        const active = moment() < moment(policy.policy_end_date)
        const days = moment().diff(moment(policy.policy_start_date), 'days');
        const width = days > 365 ? 100 : (days / 365) * 100;

        let types = policy.types.split(',');
        if(active === false) types.push('CN');
        return (
            <div className="card cursor-pointer" onClick={() => this.props.getDetail(policy.policy_ref)}>
                <div class="progress card-progress">
                    <div class={`progress-bar bg-${active ? 'blue' : 'red'}`} style={{width: `${round(width, 0)}%`}}></div>
                </div>
                <div class="card-body d-flex align-items-center">
                    <div class="mr-1 lh-sm">
                        <div class="strong">
                            {policy.policy_ref}
                        </div>
                        <div class="mt-2">
                            {types.map(t => {
                                let colour = 'bg-blue-lt';
                                if (t === 'CN') colour = 'bg-red-lt';
                                return (<span style={{ marginRight: 6 }} class={`badge ${colour}`}>{t}</span>)
                            })}
                        </div>
                    </div>
                    <div class="ml-auto">
                        <div class={`btn text-white btn-${active ? 'blue' : 'red'}`}>£{policy.earned_total_premium}</div>
                    </div>
                    {policy.claims.length > 0 &&
                        <div class="ribbon ribbon-top ribbon-right bg-red">
                            <b>{policy.claims.length}</b>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default Policy;