import React, { Component } from 'react';
import Empty from '../../../../components/empty/Results';
import Table from './Table';
import ModalAddUsage from '../Detail/Modal/ModalAddUsage';
import { sum } from 'lodash';

class UsagesContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_open: false,
        }
        this.setState = this.setState.bind(this);
        this.open = this.open.bind(this);
    }

    open() {
        this.setState({
            modal_open: true,
        })
    }

    render() {
        const { 
            usages, 
            policys_usages_put, 
            policy_detail, 
            policys_usages_post, 
            policys_usages_get_all,
            currency, 
            timezone,
            lang,
        } = this.props;

        const filteredUsages = usages.filter( usage => usage.type !== 'journey');

        return (
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-end">
                                <button 
                                    className="btn btn-primary btn-sm"
                                    onClick={this.open}
                                    style={{ marginBottom: '10px' }}
                                >
                                    + {lang.t('policy.policies.addUsage')}
                                </button>
                            </div>
                            {filteredUsages.length === 0 && <Empty label={lang.t('policy.policies.usages').toLowerCase()} lang={lang} />}
                            {filteredUsages.length > 0 &&
                                <div className="row"> 
                                    <div className="col-lg-12">
                                        <Table 
                                            usages={filteredUsages}
                                            policys_usages_put={policys_usages_put}
                                            policys_usages_get_all={policys_usages_get_all}
                                            currency={currency}
                                            timezone={timezone}
                                            lang={lang}
                                        />
                                    </div>
                                </div>
                            }
                            <ModalAddUsage
                                open={this.state.modal_open}
                                set_modal_open={this.setState}
                                detail={policy_detail}
                                policys_usages_post={policys_usages_post}
                                timezone={timezone}
                                lang={lang}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UsagesContainer;
