import React from 'react';
import DropZone from '../../../DropZone';
import { round, isEmpty } from 'lodash';

function UploadSection({
    file, 
    is_uploading,
    update,
    upload_success,
    progress_perc,
    models,
    lang,
}) {
    return (
        <>
            <div className="alert alert-info" role="alert">
                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                {lang.t('rating.components.machineLearning.selectOrDropFile')}
            </div>
            <DropZone 
                title={lang.t('rating.components.machineLearning.dropModelFile')}
                file={file}
                update={update}
                upload_success={upload_success}
                is_uploading={is_uploading}
                models={models}
            />
            { !isEmpty(file) &&
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">File name</th>
                            <th scope="col" style={{ textAlign: 'center' }}>Size</th>
                            {is_uploading && <th scope="col">{`${lang.t('rating.components.machineLearning.progress')} (${progress_perc}%)`}</th>}
                            {<th scope="col" style={{textAlign: 'right'}}>{typeof(upload_success) === "boolean" ? lang.t('rating.components.machineLearning.uploaded') : ''}</th>}
                        </tr>
                    </thead>
                    <tbody style={{ color: '#868c97' }}>
                        <tr>
                            <td scope="row">{file.name}</td>
                            <td style={{ textAlign: 'center' }}>{`${round(file.size / 1024)} KB`}</td>
                            {is_uploading &&
                                <td>
                                    <div className="progress" style={{ marginTop: '5px' }}>
                                        <div className="progress-bar progress-bar-striped progress-bar-animated" 
                                            role="progressbar" 
                                            aria-valuenow="75" 
                                            aria-valuemin="0" 
                                            aria-valuemax="100" 
                                            style={{width: `${progress_perc}%`}}
                                        ></div>
                                    </div>
                                </td>
                            }
                            {typeof(upload_success) === "boolean" &&
                                <td style={{ textAlign: 'right' }}>
                                    {upload_success ? 
                                        <i style={{color: 'green', marginRight: '25px' }} className="fe fe-check"></i>
                                        :
                                        <i style={{color: 'red', marginRight: '25px' }} className="fe fe-x"></i>
                                    }
                                </td>
                            }
                            {typeof(upload_success) !== "boolean" &&
                                <td>
                                </td>
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
            }
        </>
    )
}

export default UploadSection;