import React, { Component } from 'react';
import FormModifications from '../Forms/FormModifications';

class ModalAddMod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mod: {},
        }

        this.close = this.close.bind(this);
        this.update = this.update.bind(this);
        this.add = this.add.bind(this);
    }

    close() {
        this.props.modal_open({
            add_mod: {
                open: false,
            }
        });
        this.setState({
            mod: {}
        });

    }

    update(mod) {
        this.setState({ mod })
    }

    add() {
        const { detail, update } = this.props;

        const result = {
            ...detail,
            vehicle: {
                ...detail.vehicle,
                modifications: [...detail.vehicle.modifications || [], this.state.mod.code]
            }
        }

        update(result);

        this.close();
    }

    render() {
        const { open, lang } = this.props;
        const { mod } = this.state;
        
        return(
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-mod" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header theme-dark" >
                            <h5 className="modal-title">{lang.t('policy.policies.addModification')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <FormModifications 
                                data={mod}
                                update={this.update} 
                            />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={this.add}
                                disabled={!mod.code}
                            >
                                {lang.t('policy.policies.addModification')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalAddMod;