export function calculateRetryPayment(policy_version) {
    // TODO: update this after fixing api
    // mta_fee, cancel_fee not included in policy_version object.
    const { 
        version_type, 
        upfront_premium, 
        subscription_premium,
        usage_premium,
        mta_fee = 1,
        cancel_fee = 1,
    } = policy_version;
    let payment_amount;
    if (version_type === 'mta') {
        const prices = [upfront_premium, subscription_premium, mta_fee]
        const policy_fees = prices.map((p) => {
            return parseFloat(p)
        });
        payment_amount = policy_fees.reduce((a, b) => a + b, 0);
    }

    if (version_type === 'st') {
        const prices = [usage_premium, upfront_premium, subscription_premium]
        const policy_fees = prices.map((p) => {
            return parseFloat(p)
        });
        payment_amount = policy_fees.reduce((a, b) => a + b, 0);
    }

    if (version_type === 'cn') {
        const prices = [upfront_premium, cancel_fee]
        const policy_fees = prices.map((p) => {
            return parseFloat(p)
        });
        payment_amount = policy_fees.reduce((a, b) => a + b, 0);
    }
    return payment_amount;
}