import React from 'react';
import Input from '../../Inputs/Input';
import { parseDate } from '../../utils/formatDate';
import TableModifications from '../Tables/TableModifications';

export default function FormVehicle(props) {
    const {
        estimated_yearly_mileage,
        make,
        model,
        reg,
        fuel,
        engine,
        owner,
        keeper,
        type,
        abi_code,
        is_rhd,
        is_import,
        current_value,
        transmission,
        manufacture_date, 
        purchase_date,
        modifications
    } = props.data;
    const { timezone } = props.timezone;

    const formattedManufactureDate = parseDate(manufacture_date, 'DD/MM/YYYY', timezone);
    const formattedPurchaseDate = parseDate(purchase_date, 'DD/MM/YYYY', timezone);
    const lang = props.lang;

    return (
        <div className='card ml-2'>
            <div className='card-header'>
                <h3 className='card-title'>{lang.t('rating.components.formVehicle.vehicle')}</h3>
            </div>
            <div className='card-body'>
                <div className='mb-1'>
                    <Input 
                        label={lang.t('rating.components.formVehicle.annualMileage')}
                        value={estimated_yearly_mileage} 
                    />
                    <Input 
                        label={lang.t('rating.components.formVehicle.make')}
                        value={make} 
                    />
                    <Input 
                        label={lang.t('rating.components.formVehicle.model')}
                        value={model} 
                    />
                    <Input 
                        label={lang.t('rating.components.formVehicle.reg')}
                        value={reg} 
                    />
                    <Input 
                        label={lang.t('rating.components.formVehicle.fuelType')}
                        value={fuel} 
                    />
                    <Input 
                        label={lang.t('rating.components.formVehicle.engine')}
                        value={engine} 
                    />
                    <Input 
                        label={lang.t('rating.components.formVehicle.owner')}
                        value={owner} 
                    />
                    <Input 
                        label={lang.t('rating.components.formVehicle.keeper')}
                        value={keeper} />
                    <Input 
                        label={lang.t('rating.components.formVehicle.manufactured')}
                        value={formattedManufactureDate} 
                    />
                    <Input 
                        label={lang.t('rating.components.formVehicle.purchased')}
                        value={formattedPurchaseDate} 
                    />
                    <Input 
                        label={lang.t('rating.components.formVehicle.type')}
                        value={type} 
                    />
                    <Input 
                        label={lang.t('rating.components.formVehicle.abi')}
                        value={abi_code} 
                    />
                    <Input 
                        label={lang.t('rating.components.formVehicle.rightHandDrive')}
                        value={is_rhd} 
                    />
                    <Input 
                        label={lang.t('rating.components.formVehicle.imported')}
                        value={is_import} 
                    />
                    <Input 
                        label={lang.t('rating.components.formVehicle.currentValue')}
                        value={current_value} 
                    />
                    <Input 
                        label={lang.t('rating.components.formVehicle.transmission')}
                        value={transmission} 
                    />
                    <hr />
                    <div>
                        <div style={{ marginBottom: '25px' }}>
                            <b>{lang.t('rating.components.formVehicle.modifications')}</b>
                        </div>
                        <TableModifications
                            data={modifications}
                            lang={lang}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
