import React, { Component } from 'react';
import main from '../../../../hoc/main';
import { getLangSource } from '../../../../lang';
import { getQuery } from '../../../../utils/url';

// Core Design
import SpinnerBody from '../../../../components/loaders/SpinnerBody';

import DevicesTable from '../../components/Telematics/DevicesTable';

class TelematicsDevices extends Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
    }

    async componentWillMount() {
        const { history } = this.props;
        const device_reference = getQuery(history, 'device_reference');
        await this.props.telematics_devices_get_all(device_reference ? { device_reference } : {}) 
    }

    async search(search) {
        await this.props.telematics_devices_get_all({
            device_reference: search
        }) 
    }

    render() {
        const {
            isFetching = true,
            selected = {},
            data = [],
        } = this.props.telematics_devices;

        const obd_count = data.filter(d => d.type === 'obd').length;
        const cc_count = data.filter(d => d.type === 'cc').length;
        const mobile_count = data.filter(d => d.type === 'mobile').length;

        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-sm-12 col-lg-6">
                            <div className="page-pretitle">{lang.t('nav.telematics')}</div>
                            <h2 className="page-title">{lang.t('nav.dashboard')}</h2>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                        </div>
                        <div className="col-sm-12 col-lg-3">
                        </div>
                    </div>
                    
                    {/* Loader */}
                    {isFetching && <SpinnerBody />}

                    {!isFetching &&
                        <div className="row"> 
                            <div className="col-lg-4 text-right mb-2">
                                <div className="card card-sm" style={{ opacity: obd_count > 0 ? 1 : 0.5 }}>
                                    <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <span className={`${obd_count ? 'bg-blue' : 'bg-gray'} text-white avatar`}>
                                                <i className="fe fe-arrow-right-circle"></i>
                                            </span>
                                        </div>
                                        <div className="col">
                                            <div className="font-weight-bold">
                                                <b>{obd_count}</b> {lang.t('policy.telematics.obdDevices')}
                                            </div>
                                            <div className="text-muted">
                                                {obd_count} {lang.t('policy.telematics.active')}
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 text-right mb-2">
                                <div className="card card-sm" style={{ opacity: cc_count > 0 ? 1 : 0.5 }}>
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <span className={`${cc_count ? 'bg-blue' : 'bg-gray'} text-white avatar`}>
                                                    <i className="fe fe-arrow-right-circle"></i>
                                                </span>
                                            </div>
                                            <div className="col">
                                                <div className="font-weight-bold">
                                                    <b>{cc_count}</b> {lang.t('policy.telematics.connectedCar')}
                                                </div>
                                                <div className="text-muted">
                                                    {cc_count} {lang.t('policy.telematics.active')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 text-right mb-2">
                                <div className="card card-sm" style={{ opacity: mobile_count > 0 ? 1 : 0.5 }}>
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <span className={`${mobile_count ? 'bg-blue' : 'bg-gray'} text-white avatar`}>
                                                    <i className="fe fe-arrow-right-circle"></i>
                                                </span>
                                            </div>
                                            <div className="col">
                                                <div className="font-weight-bold">
                                                    <b>{mobile_count}</b> {lang.t('policy.telematics.mobileTag')}
                                                </div>
                                                <div className="text-muted">
                                                    {mobile_count} {lang.t('policy.telematics.active')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {!isFetching &&
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <DevicesTable 
                                    data={data}
                                    search={this.search}
                                    policys_get_all={this.props.policys_get_all}
                                    lang={lang}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default main(TelematicsDevices);