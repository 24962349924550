import React, { Component } from "react";

class RatingsNav extends Component {
    constructor(props) {
        super(props);
        this.renderItem= this.renderItem.bind(this);
    }

    renderItem(item, active) {
        const { name, section, count} = item;
        return (
            <a onClick={() => {
                this.props.page(section);
            }}className={`list-group-item cursor-pointer list-group-item-action d-flex align-items-center cursor ${section === active ? 'active' : ''}`}>
                {name} {count && <small className="text-muted ml-auto">{count}</small>}
            </a> 
        )
    }

    render() {
        const { active, lang } = this.props;
        const items = [{
            name: lang.t('rating.nav.quoteDetail'),
            section: 'quote',
        },{
            name: lang.t('rating.nav.pricingDetail'),
            section: 'pricing',
        }]

        return (
            <div className="ml-3">
                <div className="list-group list-group-transparent mb-4">
                    {items.map(item => this.renderItem(item, active))}
                </div>
            </div>
        )
    }
}

export default RatingsNav;