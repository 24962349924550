import React from 'react';
import StatMini from '../Analytics/StatMini';
import QuoteExampleTable from './QuoteExampleTable';

export default function QuotePricingBox(props) {

    const { 
        upfront_rate, 
        usage_rate, 
        subscription_rate, 
        refer_count, 
        exclusion_count, 
        total_excess,
        subscription_installments, 
        commission_rate, 
        ipt_rate, 
        base_premium, 
        upfront_premium, 
        subscription_premium, 
        usage_premium, 
        estimated_distance, 
        yearly_premium_estimate 
    } = props.data;

    const lang = props.lang;

    return [
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <StatMini 
                        mainCount={upfront_rate}
                        mainLabel={lang.t('rating.components.quotePricingBox.upfrontRate')}
                        subCount={''}
                        subLabel={lang.t('rating.components.quotePricingBox.upfrontSub')}
                        icon='fe-credit-card'
                        colour='blue'
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <StatMini 
                        mainCount={usage_rate}
                        mainLabel={lang.t('rating.components.quotePricingBox.usageRate')}
                        subCount={''}
                        subLabel={`${lang.t('rating.components.quotePricingBox.upfrontRate')} - (${estimated_distance})`}
                        icon='fe-chrome'
                        colour='blue'
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <StatMini 
                        mainCount={yearly_premium_estimate}
                        mainLabel={lang.t('rating.components.quotePricingBox.estimateYearly')}
                        subCount={''}
                        subLabel={lang.t('rating.components.quotePricingBox.estimateSub')}
                        icon='fe-check-square'
                        colour='blue'
                    />
                </div>
            </div>
        </div>
    ,
        <QuoteExampleTable 
            header={lang.t('rating.components.quotePricingBox.exampleDistancePricing')}
            data={{
                upfront_rate, 
                usage_rate,
                estimated_distance,
            }}
            lang={lang}
        />
    ]
}
