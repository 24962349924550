import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';

// Core Design
import Empty from '../../../../components/empty/Results';

// Service Design
import Table from './Table';

class UserContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const data = this.props.users;
        const lang = getLangSource(this.props.lang);
        return (
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <div className="row">
                        <div className="col-lg-12">
                            {data.length === 0 && <Empty label="users" lang={lang} />}
                            {data.length > 0 &&
                                <div className="row"> 
                                    <div className="col-lg-12">
                                        <Table 
                                            data={data}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserContainer;