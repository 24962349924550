import React, { Component } from 'react';
import { sortBy } from 'lodash';

class FormulasTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], lang } = this.props;
        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>  
                            <tr>
                                <th>{lang.t('tableHeaders.name')}</th>
                                <th>{lang.t('tableHeaders.filterExpression')}</th>
                                {/*<th className="w-1">Priority</th>*/}
                                <th className="w-1"></th>
                                <th className="w-1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortBy(data, d => d.priority).map((d, i) => (
                                <tr key={`factors-${i}`}>
                                    <td data-label={lang.t('tableHeaders.name')}>  
                                        <div className="text-h5">{d.name}</div>
                                    </td>
                                    <td className="td-truncate" data-label={lang.t('tableHeaders.expression')}>
                                        <div className="text-truncate text-muted text-h5">
                                            {d.formula_expression.substr(0, 70)}
                                        </div>
                                    </td>
                                    {/*<td data-label="Priority">
                                        <select key={`factors-select-${i}`} value={d.priority} style={{width: 60}} onChange={(e) => this.props.updatePriority({
                                            ...d,
                                            proirity: e.target.value,
                                        })} className={`form-control custom-select`}>
                                            {[1,2,3,4,5,6,7,8,9,10].map((d, i) => (<option key={`factors-option-${i}-${d}`} value={d}>{d}</option>))}
                                        </select>
                                    </td>*/}
                                    <td>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            this.props.edit(d.name)
                                        }} className="btn btn-primary btn-sm" data-toggle="modal" data-target="#modal-formula">
                                            {lang.t('buttons.edit')}
                                        </a>
                                    </td>
                                    <td>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            this.props.delete(d.name)
                                        }} className="btn btn-red btn-sm">
                                            {lang.t('buttons.delete')}
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default FormulasTable;
