import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import PageHeader from '../../../../components/layout/PageHeader';
import SpinnerBody from '../../../../components/loaders/SpinnerBody';
import { saveAs } from 'file-saver';
import moment from 'moment';
import main from '../../../../hoc/main';
import RefundsTable from '../../components/Reporting/RefundsTable';
import policys_products from '../../actions/policys_products';
import ProductsFilterDropDown from '../../components/Products/FilterDropdown';
import Search from '../../../../components/search/Simple';
import DownloadModal from '../../components/Reporting/DownloadModal';
import Empty from '../../../../components/empty/Results';

class Refunds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterTerm: '',
            searchTerm: '',
            isDownloadModalOpen: false,
            isDownloading: false,
            format: 'csv',
            displayData: [],
            isFetching: true,
        }

        this.filter = this.filter.bind(this);
        this.search =  this.search.bind(this);
        this.term = this.term.bind(this);
        this.toggleDownloadModal = this.toggleDownloadModal.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
    };

    async componentDidMount() {
        const { 
            policys_reporting_get_all, 
            policys_products_get_all 
        } = this.props;

        const { payload } = await policys_reporting_get_all({
            version_type: 'tim_refund',
        });
        await policys_products_get_all({});

        

        this.setState({ 
            isFetching: false,
            displayData: payload.data
        });
    };

    filter({ product_reference }) {
        this.setState({ filterTerm: product_reference }, () => {
            this.search();
        });
    };

    async search() {
        const { searchTerm = '', filterTerm = '', format = 'csv', limit = null, offset = null } = this.state;
        let where = { policy_reference: searchTerm.toLowerCase() };
        if (filterTerm !== '') where = { ...where, product_reference: filterTerm }
        await this.props.policys_reporting_get_all({
            ...where,
            version_type: 'tim_refund',
            format,
            limit,
            offset,
        });
    };

    term(term) {
        const { isFetching } = this.props.policys_reporting
        if ((term.length > 0 && term.length < 3) || isFetching) {
            return;
        } else {
            this.setState({ searchTerm: term }, () => {
                this.search();
            });
        };
    };

    toggleDownloadModal() {
        const { isDownloadModalOpen } = this.state;
        this.setState({ isDownloadModalOpen: isDownloadModalOpen ? false : true });
    };

    downloadFile(downloadInfo) {
        this.setState({
            isDownloadModalOpen: false,
            format: downloadInfo.format,
            limit: downloadInfo.limit || null,
            offset: downloadInfo.offset || null,
        }, async () => {
            await this.search();
            await this.export();
        });
    };

    export() {
        const { data } = this.props.policys_reporting;
        const { format } = this.state;

        this.setState({
            isDownloading: true
        });

        let content;
        let file = 'tim_refund ' + moment().format('YYYY-MM-DD');

        if (format === 'json') {
            file = `${file}.json`;
            content = new Blob([JSON.stringify(data, null, 4)], {
                type: 'application/json',
                name: file,
            });
        }

        if (format === 'csv') {
            file = `${file}.csv`;
            content = new Blob([data], {
                type: 'text/csv',
                name: file
            });
        }

        if (format === 'edi') {
            file = `${file}.edi`;
            content = new Blob([data], {
                type: 'text',
                name: file
            });
        }

        saveAs(content, file);

        this.setState({
            isDownloading: false,
        })
    }

    render ()  {
        const { isDownloadModalOpen, isDownloading, isFetching, displayData } = this.state;
        const { policys_reporting, policys_products, lang: language } = this.props;
        const { data = [] } = policys_reporting;
        const { data: products} = policys_products;
        const lang = getLangSource(language);

        return (
            <div className="my-3 my-md-5">
                <div class="container">
                    <PageHeader
                        section={lang.t('nav.reports')}
                        page={lang.t('nav.refunds')}
                    />

                    <div className="row">
                        <div className="col-auto ml-auto d-print-none mb-3" style={{ marginTop: "-48px" }}>
                            {/* TEMP FOR DEMO */}
                            {/* <button onClick={(e) => {
                                e.preventDefault();
                                this.toggleDownloadModal();
                            }} type="button" className={'btn btn-blue btn-sm mr-3'}>Download</button> */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-lg-8">
                            <ProductsFilterDropDown
                                products={policys_products}
                                update={this.filter}
                                lang={lang}
                                />
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                            <button onClick={(e) => {
                                e.preventDefault();
                                this.toggleDownloadModal();
                            }} type="button" className={'btn btn-blue btn-sm'} style={{ marginLeft: "285px", marginTop: "42px" }}>{lang.t('buttons.download')}</button>
                                {/* TEMP FOR DEMO */}
                                {/* <label className="form-label">Search By Policy Reference</label> */}
                                {/* <Search
                                    searchTerm={this.state.searchTerm}
                                    searchFunction={this.term}
                                    isFetching={isFetching}
                                    label='policies'
                                    lang={lang}
                                /> */}
                            </div>
                        </div>
                    </div>
                    {!isFetching && displayData.length > 0 &&
                        <RefundsTable
                            refundsData={displayData}
                            products={products}
                            lang={lang}
                        />
                    }

                    {/* Loader */}
                    {(isFetching || isDownloading) && <SpinnerBody />}

                    {/* Download Modal */}
                    {isDownloadModalOpen && 
                        <DownloadModal
                            toggleDownloadModal={this.toggleDownloadModal}
                            downloadFile={this.downloadFile}
                            lang={lang}
                        />
                    }

                </div>
            </div>
        )
    }
}

export default main(Refunds);