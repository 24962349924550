import React, { Component } from 'react';
import { isUndefined } from 'lodash';
import axios from 'axios';

import Input from '../Detail/Forms/Input';

class Webhooks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            spinner: null,
            spinner_direct: null,
            testEvent: null,
            testStatus: '',
            testMessage: ''
        }
        this.save = this.save.bind(this);
        this.send_test = this.send_test.bind(this);
    }

    save({ webhooks_enabled }) {
        const { selected } = this.props;
        this.setState({ error: null });
        this.props.save({
            id: selected.product_reference,
            data: {
                webhooks_url: selected.webhooks_url,
                webhooks_secret: selected.webhooks_secret,
                webhooks_enabled: isUndefined(webhooks_enabled) ? selected.webhooks_enabled : webhooks_enabled,
            }
        });
    }

    async send_test() {
        this.setState({ spinner: true })
        const { selected } = this.props;
        await this.props.policys_health_get_all({
            product_reference: selected.product_reference
        }).then(data => {
            (data.payload.status === 200) ? this.setState({ testStatus: 'success', testMessage: 'Webhook sucessfully' }) : this.setState({ testStatus: 'danger', testMessage: 'Failed to' });
            this.setState({ testEvent: true })
            this.setState({ spinner: false })
        })
    };

    async send_direct_test() {
        this.setState({ spinner_direct: true });
        const { selected } = this.props;

        let result;

        try {
            result = await axios({
                url: selected.webhooks_url,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'authorization': `Bearer ${selected.webhooks_secret}`},
                data: JSON.stringify({ webhook_test_status: 'SUCCESS' }),
                timeout: 2500,
            })
        } catch(e) {
            this.setState({ 
                testStatus: 'danger', 
                testMessage: 'Failed to', 
                spinner_direct: false,
                testEvent: true
            });
        };

        if (result && result.status === 200) {
            this.setState({ 
                testStatus: 'success', 
                testMessage: 'Webhook successfully', 
                spinner_direct: false, 
                testEvent: true });
        };
    };

    render() {
        const { selected, update, save, lang } = this.props;
        const { testMessage, testStatus, testEvent, spinner, spinner_direct, error } = this.state;

        return (
            <div className="card ml-2">
                <div className="card-header">
                    <h3 className="card-title">{lang.t('settings.products.webhooks')}</h3>
                    <div className="ml-auto btn-list flex-nowrap">
                        <a className="btn btn-sm btn-primary text-white" onClick={(e)=>{
                            e.preventDefault();
                            this.save({
                                webhooks_enabled: selected.webhooks_enabled,
                            });
                        }}>{lang.t('buttons.save')}</a>
                    </div>
                </div>
                <div className="card-body">
                    {error &&
                        <div style={{marginTop: 10}} className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    }
                    {testEvent &&
                        <div style={{marginTop: 10}} className={`alert alert-${testStatus}`} role="alert">
                            <span class="form-check-label">{`${testMessage} send to `}<b>{selected.webhooks_url}</b></span>
                        </div>
                    }
                    <div className="mb-1">
                        <Input 
                            label={lang.t('settings.products.webhooksUrl')}
                            property={'webhooks_url'}
                            value={selected.webhooks_url}
                            update={update}
                            disabled={false}
                        />
                        <Input 
                            label={lang.t('settings.products.webhooksSecret')}
                            property={'webhooks_secret'}
                            value={selected.webhooks_secret}
                            update={update}
                            disabled={false}
                        />                        
                        {!selected.webhooks_enabled &&
                            <div style={{marginTop: 10}} className={`alert alert-danger`} role="alert">
                                <label class="form-check form-switch">
                                    <input 
                                        class="form-check-input" 
                                        style={{ border: '1px solid #b5ddfa'}} type="checkbox" 
                                        onChange={() => {
                                            this.save({ webhooks_enabled: true })
                                        }} 
                                        disabled={!(selected.webhooks_secret && selected.webhooks_url)}
                                    />
                                    <span class="form-check-label">{lang.t('settings.products.webhooksAre')} <b>{lang.t('settings.products.notEnabled')}</b> {lang.t('settings.products.toBeSent')}</span>
                                </label>
                            </div>
                        }

                        {selected.webhooks_enabled &&
                            <div style={{marginTop: 10}} className={`alert alert-info`} role="alert">
                                <label class="form-check form-switch">
                                    <input class="form-check-input" style={{ border: '1px solid #b5ddfa'}} type="checkbox" checked="true" onChange={() => {
                                        this.save({ webhooks_enabled: false });
                                    }}/>
                                    <span class="form-check-label">{lang.t('settings.products.webhooksAre')} <b>{lang.t('settings.products.enabled')}</b> {lang.t('settings.products.toBeSent')}</span>
                                </label>
                            </div>
                        }

                        {selected.webhooks_enabled && selected.webhooks_url &&
                            <>
                                <div className={`alert alert-info`} role="alert">
                                    <p style={{ marginBottom: 10 }} className="form-check-label">{lang.t('settings.products.testWebhook')} <b>POST {selected.webhooks_url}</b> {lang.t('settings.products.receiveWithinApp')}.</p> 
                                    <p className="form-check-label">{lang.t('settings.products.webhookSuccesfullySent')}.</p>
                                    <div className="webhooks-send-container">
                                        <button style={{ marginTop: 5 }} className="btn btn-sm btn-primary" onClick={() => {
                                            this.send_test();
                                        }}>{lang.t('settings.products.sendTestEvent')}</button>
                                        {spinner &&
                                            <div className="spinner-border text-black" role="status" />
                                        }
                                    </div>
                                </div> 
                                <div className={'alert alert-info'} role="alert">
                                    <p style={{ marginBottom: 10 }} className="form-check-label">{lang.t('settings.products.wishToSendWebhook')}: <b>{selected.webhooks_url}.</b></p>
                                    <p className="form-check-label">{lang.t('settings.products.corsWarning')}.</p>
                                    <div className="webhooks-send-container" >
                                        <button style={{ marginTop: 5 }} className="btn btn-sm btn-primary" onClick={() => {
                                            this.send_direct_test()
                                        }}>{lang.t('settings.products.sendDirectTest')}</button>
                                        {spinner_direct &&
                                            <div className="spinner-border text-black" role="status" />
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Webhooks;