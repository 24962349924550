import React, { Component } from 'react';
import { getLangSource } from '../../../lang';
import { saveAs } from 'file-saver';
import { first, get } from 'lodash';
import { indexCollection, updateCollection } from '../../../utils/manipulate';

import main from '../../../hoc/main';
import scenarios from '../utils/__mocks__/scenarios.json';

import ScenariosTable from '../components/TestsDetail/ScenariosTable';

class TestDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: {
                import: {
                    open: false,
                }
            },
            
        };
        this.export = this.export.bind(this);
        this.openModal = this.openModal.bind(this);
        this.updateSelected = this.updateSelected.bind(this);
        this.updateScenario = this.updateScenario.bind(this);
    }

    componentWillMount() {
        //TODO Fetch Test Detail
        const lang = getLangSource(this.props.lang);
        const testsuite = {
            name: lang.t('rating.tests.basicScenarios'),
            scenarios: indexCollection(scenarios),
        }
        this.setState({
            testsuite,
            selected: first(testsuite.scenarios),
        })
    }

    updateSelected(selected) {
        this.setState({
            selected,
        });
    }

    updateScenario(scenario) {
        let result;

        // Check JSON validity
        try {
            result = JSON.parse(scenario)
        } catch(e) {
            return this.setState({
                selected: {
                    ...this.state.selected,
                    valid_json: false,
                }
            });
        }

        const newScenarios = updateCollection(this.state.testsuite.scenarios, result);
        return this.setState({
            testsuite: {
                ...this.state.testsuite,
                scenarios: newScenarios,
            },
            selected: result,
            valid_json: true,
        })
    }

    export() {
        const { name } = this.state.testsuite;
        const file = `${name}.json`;
        
        // Create a blob of the data
        const content = new Blob([JSON.stringify(this.state.testsuite)], {
            type: 'application/json',
            name: file
        });
        
        // Save the file
        saveAs(content, file);
    }

    openModal(modal) {
        this.setState({
            modal: {
                ...this.state.modal,
                ...modal,
            },
        });
    }

    render() {
        const { selected, testsuite } = this.state;
        const { name, scenarios } = testsuite;
        const valid_json = get(selected, 'valid_json', true);

        const lang = getLangSource(this.props.lang);

        return [
            <div className="my-3 my-md-5">
                <div className="container">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="page-pretitle">
                                    {lang.t('rating.tests.titleSingle')}
                                </div>
                                <h2 className="page-title">
                                    {name}
                                </h2>
                            </div>
                            <div className="col-auto ml-auto d-print-none">
                                <button onClick={() => {
                                    this.openModal('import');
                                    }} type="button" data-toggle="modal" data-target="#modal-test-import" className="btn btn-white mr-3">
                                    {lang.t('buttons.import')}
                                </button>
                                <button onClick={() => {
                                    this.export('import');
                                    }} type="button" className="btn btn-white mr-3">
                                    {lang.t('buttons.export')}
                                </button>
                                <button onClick={() => {
                                    this.save();
                                    }} type="button" className="btn btn-primary">
                                    {lang.t('buttons.save')}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-6">
                            <ScenariosTable 
                                scenarios={scenarios}
                                selected={selected}
                                updateSelected={this.updateSelected}
                                lang={lang}
                            />
                        </div>
                        <div className="col-lg-6" style={{ position: 'relative' }}>
                            <div style={{ top : 20, right: 30, position: 'absolute' }}>
                                {valid_json === false &&
                                    <span key={`selected-${selected.id}`} className="badge bg-red">
                                        {lang.t('rating.tests.scenarioID')} {selected.id} {lang.t('rating.tests.jsonIsInvalid')}
                                    </span>
                                }
                                {valid_json === true &&
                                    <span key={`selected-${selected.id}`} className="badge bg-green">
                                        {lang.t('rating.tests.scenarioID')} {selected.id} {lang.t('rating.tests.jsonIsValid')}
                                    </span>
                                }
                            </div>
                            <textarea 
                                className="form-control"
                                data-toggle="autosize"
                                key={get(selected, 'id')}
                                style={{
                                    overflow: 'auto',
                                    background: valid_json ? '#354052' : '#f1f1f1',
                                    borderRadius: '3px',
                                    fontSize: '14px',
                                    color: valid_json ? '#eaeaea' : '#000',
                                    height: '600px',
                                    width: '100%',
                                }} 
                                onBlur={(e) => {
                                    this.updateScenario(e.target.value);
                                }}
                                defaultValue={JSON.stringify(selected, null, 4)}
                            />
                        </div>
                    </div>

                </div>
            </div>
            ];
    }
}

export default main(TestDetail);