import React, { Component } from 'react';
import ModalAddFile from './ModalAddFile';
import Empty from '../../../../components/empty/Results';
import Table from './Table'

class DocumentsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalAddVisible: false,
            modalDeleteVisible: false
        }
        this.toggleAddModal = this.toggleAddModal.bind(this);
    }
    toggleAddModal() {
        this.setState({
            modalAddVisible: !this.state.modalAddVisible
        })
    }

    render() {
        const { 
            files, 
            policys_kyc_delete, 
            policy_reference, 
            policys_kyc_post, 
            policys_kyc_get,
            policys_kyc_get_all,
            timezone,
            lang,
        } = this.props;

        return (
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='d-flex justify-content-end'>
                        <button
                            className='btn btn-primary btn-sm'
                            style={{marginBottom: '10px'}}
                            onClick={this.toggleAddModal}
                        >
                            + {lang.t('policy.policies.addFile')}
                        </button>
                    </div>
                    {files.length === 0 && <Empty label={lang.t('policy.policies.files').toLowerCase()} lang={lang} />}
                    {files.length > 0 && 
                        <Table
                            files={files}
                            timezone={timezone}
                            policys_kyc_delete={policys_kyc_delete}
                            policy_reference={policy_reference}
                            policys_kyc_get={policys_kyc_get}
                            policys_kyc_get_all={policys_kyc_get_all}
                            lang={lang}
                        />
                    }
                    <ModalAddFile
                        open={this.state.modalAddVisible}
                        toggleModal={this.toggleAddModal}
                        policys_kyc_post={policys_kyc_post}
                        policy_reference={policy_reference}
                        policys_kyc_get={policys_kyc_get}
                        lang={lang}
                    />
                </div>
            </div>
        )
    }
}

export default DocumentsContainer;