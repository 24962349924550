import React, { Component } from 'react';
import { sortBy, round } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { getNumberFormat } from '../../../../utils/numberFormat';

class RatingBillingTable extends Component {
    constructor(props) {
        super(props);
    }

    badge(month, lang) {
        const current_month = moment().format('MM-YYYY');
        if (current_month === month) {
            return (
                <div className='badge bg-yellow'>{lang.t('settings.billing.current')}</div>
            )
        } else {
            return (
                <div className='badge bg-green'>{lang.t('settings.billing.invoiced')}</div>
            )
        };
    }

    render() {
        let { data = {}, pages, currency, lang } = this.props;
        let { segments = [], totals = [] } = data;
        segments = sortBy(segments, d => -moment(d.month, 'MM-YYYY'));
        totals = sortBy(totals, d => -moment(d.month, 'MM-YYYY'));
        return (
            <div className="card">
                <div className="card-header">
                    <div className="text-h5">{lang.t('settings.billing.ratingTotals')}</div>
                </div>
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.status')}</th>
                                <th>{lang.t('tableHeaders.month')}</th>
                                <th style={{ width: 500 }}>{lang.t('tableHeaders.activeModels')}</th>
                                <th>{`${lang.t('tableHeaders.aveResponse')} (${lang.t('time.ms')})`}</th>
                                <th>{lang.t('tableHeaders.quotes')}</th>
                                <th>{lang.t('tableHeaders.cost')}</th>
                                <th className="w-1"></th>
                            </tr>
                        </thead>
                        <tbody>
                        {totals.map((d, i) => {
                            return (
                                <tr key={`rating-billing-${i}`}>
                                    <td data-label={lang.t('tableHeaders.status')}>
                                        <div className="text-h5">{this.badge(d.month, lang)}</div>
                                    </td>
                                    <td data-label={lang.t('tableHeaders.month')}>
                                        <div className="text-h5">{d.month}</div>
                                    </td>
                                    <td data-label={lang.t('tableHeaders.model')}>
                                        <div className="text-h5"><b>{d.models || '-'}</b></div>
                                    </td>
                                    <td data-label={lang.t('tableHeaders.response')}>
                                        <div className="text-h5">{round(d.execution_time, 2).toFixed(2)}</div>
                                    </td>
                                    <td data-label={lang.t('tableHeaders.quotes')}>
                                        <div className="text-h5">{numeral(d.count).format('0,0')}</div>
                                    </td>
                                    <td data-label={lang.t('tableHeaders.cost')}>
                                        <div className="text-h5">{getNumberFormat(currency, round(d.cost, 2), '$0,0.00')}</div>
                                    </td>
                                    <td>
                                        <button 
                                        className="btn btn-primary btn-sm text-white" 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            alert(lang.t('settings.billing.alert'))
                                        }}>
                                            {lang.t('buttons.download')}
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <div className="card-header" style={{ borderTop: '1px solid #868c973d'}}><div className="text-h5">{lang.t('settings.billing.ratingLineItems')}</div></div>
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.status')}</th>
                                <th>{lang.t('tableHeaders.month')}</th>
                                <th style={{ width: 500 }}>{lang.t('tableHeaders.model')}</th>
                                <th>{`${lang.t('tableHeaders.aveResponse')} (${lang.t('time.ms')})`}</th>
                                <th>{lang.t('tableHeaders.quotes')}</th>
                                <th>{lang.t('tableHeaders.cost')}</th>
                                <th className="w-1"></th>
                            </tr>
                        </thead>
                        <tbody>
                        {segments.map((d, i) => {
                            const model = this.props.pricing_models[d.model_reference] || {};
                            return (
                                <tr key={`rating-billing-${i}`}>
                                    <td data-label={lang.t('tableHeaders.status')}>
                                        <div className="text-h5">{this.badge(d.month, lang)}</div>
                                    </td>
                                    <td data-label={lang.t('tableHeaders.month')}>
                                        <div className="text-h5">{d.month}</div>
                                    </td>
                                    <td data-label={lang.t('tableHeaders.model')}>
                                        <div className="text-h5"><b>{model.name || '-'}</b></div>
                                        <div className="text-h5 text-muted">{d.model_reference}</div>
                                    </td>
                                    <td data-label={lang.t('tableHeaders.response')}>
                                        <div className="text-h5">{round(d.execution_time, 2).toFixed(2)}</div>
                                    </td>
                                    <td data-label={lang.t('tableHeaders.quotes')}>
                                        <div className="text-h5">{numeral(d.count).format('0,0')}</div>
                                    </td>
                                    
                                    <td data-label={lang.t('tableHeaders.cost')}>
                                        <div className="text-h5">{getNumberFormat(currency, round(d.cost, 2), '$0,0.00')}</div>
                                    </td>
                                    <td>
                                        <button 
                                        className="btn btn-white btn-sm text-white" 
                                        style={{ visibility: 'hidden'}}
                                        >{lang.t('buttons.download')}</button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default RatingBillingTable;