const strings = {
    languages: {
        en: 'english',
        fr: 'french',
        it: 'italian',
        es: 'spanish'
    },
    menu: {
        profile: 'Profile',
        settings: 'Settings',
        company: 'Company',
        products: 'Products',
        billing: 'Billing',
        users: 'Users',
        tokens: 'Tokens',
        tasks: 'Tasks',
        inbox: 'Inbox',
        message: 'Message',
        signout: 'Sign out',
    },
    footer: {
        documentation: 'Documentation',
        getHelp: 'Get help',
    },
    products: {
        engine: 'Engine',
        sandbox: 'Sandbox',
        production: 'Production',
        rating: 'Rating',
        policy: 'Policy',
        openbanking: 'Open Banking',  
    },
    login: {
        loginAccount: 'Login to your account',
        username: 'Username',
        emailAddress: 'Email address',
        password: 'Password',
        newPassword: 'New password', 
        passwordReset: 'Password successfully reset',
        forgotPassword: 'I forgot password',
        setNewPassword: 'You need to replace your temporary password',
        rememberMe: 'Remember me on this device',
        signIn: 'Sign in',
        forgotPassword: 'Forgot password',
        newPassword: 'Create a new password',
        resetPassword: 'Enter your username and a password reset code will be set to you',
        sendPassword: 'Reset my password',
        takeBack: 'Take me back to the login page',
        loginPage: 'Login Page',
        setTOTP: 'Use Google Authenticator to scan the QR Code and then add the 6 digit code in to the input below',
        getTOTP: 'Use Google Authenticator and enter the 6 digit code in to the input below',
        enterCode: 'Enter Code',
        nextStep: 'Next Step',
        verificationCode: 'Please enter the verification code sent to your email and enter your new password',
        newTempPasswordSent: 'A new temporary password has been sent to your email',
    },
    main: {
        engagingEngines: 'Hold on. Engaging the engines...',
    },
    settings: {
        title: 'Settings',
        company: {
            gettingCompany: 'Getting company. This should be quick.',
            title: 'Company',
            companyInfo: 'Company Information',
            name: 'Name:',
            email: 'Email:',
            phone: 'Phone:',
            address: 'Address:',
            taxNumber: 'Tax Number:',
            name: 'Name:',
            description: 'Description:',
            monthlyMinSub: 'Monthly Min Subscription:',
        },
        products: {
            title: 'Products',
            create: 'Create Product',
            createInfo: 'New product creation is disabled within the user interface. A new product line can be created for but you will need to contact the ',
            createInfoBold: 'By Bits support team.',
            motor: 'Motor',
            backToProducts: 'Back to Products',
            product: 'Product',
            productReference: 'Product reference',
            name: 'Name',
            description: 'Description',
            currency: 'Currency',
            usageUnits: 'Usage units',
            usageDailyCap: 'Usage daily cap',
            timezone: 'Timezone',
            statementCap: 'Statement cap',
            language: 'Language',
            renewalRatingModelRef: 'Renewal rating model reference',
            isInvalid: 'is invalid',
            senderEmail: 'Sender Email',
            emailValidationWarning: 'The email domain needs to be validated. Please add the desired email address and then contact the',
            payment: 'Payment',
            providerName: 'Provider Name',
            providerKey: 'Provider Key',
            providerSecret: 'Provider Secret',
            paymentsAre: 'Payments are',
            notEnabled: 'not enabled',
            enabled: 'enabled',
            toBeTaken: 'to be taken via the By Bits platform',
            webhooks: 'Webhooks',
            webhooksUrl: 'Webhooks URL',
            webhooksSecret: 'Webhooks Secret',
            webhooksAre: 'Webhooks are',
            toBeSent: 'to be sent via the By Bits platform',
            testWebhook: 'Test a webhook via our GET/health endpoint to',
            receiveWithinApp: 'which you can receive within your application',
            webhookSuccesfullySent: 'Please note that a succesful request to the GET/health endpoint indicates that we have succesfully sent a request with the details given. It does not mean that the webhook has been recieved at your url',
            wishToSendWebhook: 'If you wish to send a test webhook directly to your url',
            corsWarning: 'Please note that you must have CORS enabled',
            sendTestEvent: 'Send Test Event',
            sendDirectTest: 'Send Direct Test',
            emailTemplate: 'Email Template',
            status: 'Status',
            documentTemplate: 'Document Template',
            saveTemplate: 'Save Template',
            recipientEmail: 'Recipient Email',
            selectVersionsEmail: 'Select versions for email',
            subject: 'Subject',
            preview: 'Preview',
        },
        billing: {
            title: 'Billing',
            ratingTotals: 'Rating Totals',
            ratingLineItems: 'Rating Line Items',
            current: 'Current',
            invoiced: 'Invoiced',
            ratingBilling: 'Rating Billing',
            alert: 'Upcoming Feature',
            gettingBillingDetails: 'Getting billing details. This should be quick.',
        },
        users: {
            title: 'Users',
            addUser: 'Add User',
            users: 'users',
            editRoles: 'Edit Roles',
            new: 'New',
            keys: 'Keys',
            newKeys: 'New Keys',
            gettingUsers: 'Getting users. This should be quick.',
            firstNames: 'First names',
            lastNames: 'Last names',
            username: 'Username',
            email: 'Email',
            role: 'Role',
            assignToPolicy: 'Assign to Policy',
            createUser: 'Create User',
        },
    },
    roles: {
        admin: 'Admin',
        /*developer: 'Developer',
        operative: 'Operative',
        finance: 'Finance',
        marketing: 'Marketing',*/
        policyholder: 'Policyholder',
    },
    claims: {
        reports: {
            select_report: 'select report',
            select_pivot: 'select pivot',
            select_format: 'select format',
            select_risks: 'select risks',
            generate_report: 'generate report',
            loss_ratio: 'loss ratio',
            claims_frequency: 'claims frequency',
            claim_types: 'claim types',
            claim_journeys: 'claim_journeys',
            number_of_policies: 'number of policies',
            number_of_rows: 'number of rows',
            fault_claims: 'fault clams',
            non_fault_claims: 'non-fault claims',
            exposure_years: 'exposure years',
            exposure_miles: 'exposure miles',
            active_policy_count: 'active policy count',
            lapsed_policy_count: 'lapsed policy count',
            claims_inc: 'claims inc.',
            claims_est: 'claims ext.',
            fault_frequency: 'fault frequency',
            non_fault_frequency: 'non-fault frequency',
            loss_ratio_inc: 'loss ratio inc.',
            loss_ratio_est: 'loss ratio est.',
        },        
    },
    buttons: {
        help: 'Help',
        view: 'View',
        set: 'Set',
        disabled: 'Disabled',
        download: 'Download',
        disable: 'Disable',
        enable: 'Enable',
        newKeys: 'New Keys',
        ok: 'OK',
        edit: 'Edit',
        delete: 'Delete',
        import: 'Import',
        export: 'Export',
        save: 'Save',
        autosaving: 'Autosaving...',
        publish: 'Publish',
        unpublish: 'Unpublish',
        custom: 'Custom',
        default: 'Default',
        details: 'Details',
        spinner: 'Spinner',
        success: 'Success',
        test: 'Test',
        result: 'Result',
        saveChanges: 'Save changes',
        hide: 'Hide',
        show: 'Show',
        saveModel: 'Save Model',
        uploadModel: 'Upload Model',
        finished: 'Finished',
        retry: 'Retry',
        enabled: 'Enabled',
        back: 'Back',
        sendTest: 'Send Test',
        add: 'Add',
        void: 'Void',
        paid: 'Paid',
        update: 'Update',
        cancel: 'Cancel',
        next: 'Next',
        confirm: 'Confirm',
        goBack: 'Go Back',
        proceed: 'Proceed',
        getDetail: 'Get Detail',
        rawData: 'Raw data',
    },
    nav: {
        policies: 'Policies',
        dashboard: 'Dashboard',
        search: 'Search',
        expiring: 'Expiring',
        users: 'Users',
        journeys: 'Journeys',
        search: 'Search',
        statements: 'Statements',
        activity: 'Activity',
        quotes: 'Quotes',
        conversions: 'Conversions',
        promotions: 'Promotions',
        blocklists: 'Blocklists',
        GDPR: 'GDPR',
        telematics: 'Telematics',
        devices: 'Devices',
        packets: 'Packets',
        reports: 'Reports',
        bordereaux: 'Borderaux',
        unpaidVersions: 'Unpaid Versions',
        refunds: 'Refunds',
        LRClaimsFrequency: 'LR & Claims Frequency',
        createReports: 'CreateReports',
        riskFactors: 'Risk Factors',
        reportJobs: 'Report Jobs',
        usage: 'Usage',
        devices: 'Devices',
        connectedCar: 'Connected Car',
        odometer: 'Odometer',
        incidents: 'Incidents',
        alerts: 'Alerts',
        packets: 'Packets',
        settings: 'Settings',
        products: 'Products',
        productDetails: 'Product Details',
        underwriters: 'Underwriters',
        models: 'Models',
        modelsDetail: 'ModelsDetail',
        tests: 'Tests',
        testsDetail: 'TestsDetail',
        logs: 'Logs',
        analytics: 'Analytics',
        healthAnalytics: 'Health Analytics',
        factorsAnalytics: 'Factors Analytics',
        compareVersions: 'Compare Versions',
        quotes: 'Quotes',
        errors: 'Errors',
        quotesDetail: 'Quotes Detail',
        KYC: 'KYC',
        openBankingIDScoring: 'Open Banking ID Scoring',
        IDScoringIndividual: 'ID Scoring Individual',
        vehicles: 'Vehicles',
        sanctions: 'Sanctions',
        quoteFraud: 'Quote Fraud',
        reports: 'Reports',
        managePolicies: 'Manage Policies',
    },
    rating: {
        nav: {
            quoteDetail: 'Quote Detail',
            pricingDetail: 'Pricing Detail',
        },
        title: 'Rating',
        logs: {
            quoteLogs: 'Quote logs',
            retrieving: 'Retrieving quotes log. This may take a moment.',
            valid: 'Valid',
            invalid: 'Invalid',
            view: 'View',
            error: 'Error Logs',
            retrievingErrors: 'Retrieving quotes log. This may take a moment.',
            retrievingError: 'Retrieving quote. This should be quick.',
            modelReference: 'Model Reference:',
            version: 'Version:',
            quoteDetail: 'Quote Detail',
            errorDetail: 'Error Detail',
        },
        pricingQuoteDetail: {
            retrieving: 'Retrieving quote. This should be quick.',
            modelReference: 'Model Reference:', // no translation needed?
            version: 'Version:',
        },
        quoteSection: {
            proposer: 'Proposer',
            driver: 'Driver',
        },
        pricingSection: {
            exclusions: 'Exclusions',
        },
        analytics: {
            modelName: 'Model Name:',
            modelRef: 'Model Reference:',
            version: 'Version:',
            edit: 'Edit Model',
            source: 'Source:',
            model: 'Model:',
            all: 'all',
            range: 'Range:',
            numberQuotes: 'Number of Quotes',
            numberValid: 'Number of Valid',
            quotability: 'Quotability',
            yearlyPremium: 'Yearly Premium',
            upfrontPremium: 'Upfront Premium',
            aveDistance: 'Ave. Distance',
            totalQuotes: 'Total Quotes',
            premium: 'Premium',
            estimatedYearlyDistance: 'Estimated Yearly Distance',
            responseTime: 'Response Time',
            exclusions: 'Exclusions',
            single: 'Single',
            multi: 'Multi',
            refers: 'Refers',
        },
        models: {
            model: 'Model',
            create: 'Create Model',
            createNew: 'Create a new model',
            generate: 'Generate now',
            modelName: 'Model name:',
            placeholderName: 'Add name for the model',
            placeholderDesc: 'Add a description of the model',
            modelDescription: 'Model Description:',
            modelTemplate: 'Model template:',
            emptyModel: 'Empty Model',
            simplyMotor: 'Simple Motor Model',
            simpleHome: 'Simple Home Model',
            analytics: 'Analytics',
            edit: 'Edit Model',
            retrievingModels: 'Retrieving models. This should be quick.',
        },
        modelDetail: {
            versionName: 'Version Name',
            viewVersionDesc: 'View Version Description',
            newVersion: 'New Version',
            cloneVersion: 'Copy Version',
            modelRef: 'Model Reference :',
            versionRef: 'Version Reference :',
            retrievingModel: 'Retrieving model. This could take a few moments.',
            modal: {
                isValid: 'IS VALID',
                valid: 'VALID',
                exclude: 'EXCLUDE',
                include: 'INCLUDE',
                refer: 'REFER',
                notRefer: 'NOT REFER',
                importModel: 'Import Model',
                dropJSONModel: 'Drop JSON Model here',
                validJSON: 'Valid JSON', 
                validModel: 'Valid Model',
                modelDetails: 'Model Details',
                modelName: 'Model Name',
                modelVersion: 'Model Version',
                factors: 'Factors',
                exclusions: 'Exclusions',
                formulas: 'Formulas',
                saveModel: 'Save Model',
            },
        },
        factors: {
            select: 'Select Factor for Model Analysis',
            download: 'Download CSV',
            generate: 'Generate now',
            selectModel: 'Select Model:',
            selectFactor: 'Select Factor:',
            selectRange: 'Select Range:',
            isValid: 'Is Valid Only:',
            quotability: 'Quotability',
            yearlyPremium: 'Yearly Premium',
            lastHour: 'last hour',
            lastTwoHours: 'last 2 hours',
            lastThreeHours: 'last 3 hours',
        },
        compare: {
            title: 'Compare Model Versions',
            select: 'Select Model:',
            published: 'Published between:',
            to: 'to',
            current: 'current',
            compare: 'Compare',
        },
        tests: {
            title: 'Test Suites',
            create: 'Create Test Suite',
            titleSingle: 'Test Suite',
            basicScenarios: 'Basic Scenarios',
            scenarioID: 'Scenario ID:',
            jsonIsInvalid: 'JSON is invalid',
            jsonIsValid: 'JSON is valid',
        },
        home: {
            isBuilding: 'Building analytics. This may take a few moments.',
            model: 'Model:',
            isValid: 'Is Valid Only:',
        },
        components: {
            formBase: {
                base: 'Base',
                startDate: 'Start date',
                voluntaryExcess: 'Voluntary excess',
                usage: 'Usage',
            },
            formVehicle: {
                vehicle: 'Vehicle',
                annualMileage: 'Annual mileage',
                make: 'Make',
                model: 'Model',
                reg: 'Registration',
                fuelType: 'Fuel type',
                engine: 'Engine',
                owner: 'Owner',
                keeper: 'Keeper',
                manufactured: 'Manufactured',
                purchased: 'Purchased',
                type: 'Type',
                abi: 'Abi code',
                rightHandDrive: 'Right hand drive',
                imported: 'Imported',
                currentValue: 'Current value',
                transmission: 'Transmission',
                modifications: 'Modifications',
            },
            formDriver: {
                title: 'Title',
                firstName: 'First name',
                lastName: 'Last name',
                dob: 'Date of birth',
                email: 'Email',
                phone: 'Phone number',
                children: 'Children',
                licenseType: 'License type',
                licenseIssueDate: 'Licence issue date',
                noClaimsDiscount: 'No claims discount',
                employment: 'Employment',
                business: 'Business',
                occupation: 'Occupation',
                conv: 'Criminal conv',
                med: 'Med conditions',
                dvla: 'DVLA informed',
                claims: 'Claims',
                convictions: 'Convictions',
            },
            tableClaims: {
                date: 'Date',
                code: 'Code',
                atFault: 'At Fault',
                ncd: 'NCD Lost',
            },
            tableConvictions: {
                date: 'Date',
                code: 'Code',
                points: 'Points',
                ban: 'Ban',
            },
            formCustom: {
                title: 'Custom',
                showAll: 'Show all',
                collapse: 'Collapse',
                claimspoints: 'Claim points', // done like this on purpose
                occupationcategory: 'Occupation Category', // build from item key
                postcodearearating: 'Postcode area rating',
                vehiclevanityreg: 'Vehicle vanity registation',
                vehiclerating: 'Vehicle rating',
            },
            deleteQuoteModal: {
                delete: 'Delete',
                areUSure: 'Are you sure you want to delete this quote?',
                cancel: 'Cancel',
                ok: 'OK',
            },
            rawDataModal: {
                title: 'Raw Date',
                quoteDetail: 'Quote Detail',
                pricingDetail: 'Pricing Detail',
            },
            quotePricingBox: {
                upfrontRate: 'Upfront Rate',
                upfrontSub: 'The premium taken at start',
                usageRate: 'Usage Rate',
                usageSub: 'The premium per mile/km (distance',
                estimateYearly: 'Estimated yearly',
                estimateSub: 'The premium estimated per year',
                exampleDistancePricing: 'Example distance pricing',
            },
            formPricing: {
                detail: 'Detail',
                upfrontRate: 'Upfront rate',
                upfrontPremium: 'Upfront premium',
                usageRate: 'Usage rate',
                usagePremium: 'Usage premium',
                estimatedDistance: 'Estimated distance',
                yearlyPremEst: 'Yearly prem est',
                subRate: 'Subscription rate',
                subPremium: 'Subscription prem',
                referCount: 'Refer count',
                exclusionCount: 'Exclusion count',
                totalExcess: 'Total excess',
                subInstall: 'Subscription install',
                commissionRate: 'Commission rate',
                iptRate: 'IPT rate',
                basePremium: 'Base premium',
            },
            errorsTable: {
                ref: 'Ref',
                code: 'Code',
                message: 'Message',
                environment: 'Environment',
                created: 'Created',
                view: 'View',
            },
            quotesStats: {
                selectFactor: 'Select Factor',
            },
            tableChart: {
                name: 'Name',
                count: 'Count',
                total: 'Total',
                collapse: 'Collapse',
                expand: 'Expand',
            },
            quoteStatsChart: {
                quoteCount: 'Quote Count',
                quotability: 'Quotability (%)',
                yearlyPremium: 'Yearly Premium',
                responseTime: 'Response Time',
                distance: 'Distance',
            },
            publish: {
                schedulePublish: 'Schedule Publish',
                scheduleInfo: 'The model will be scheduled and then serve ',
                public: 'public',
                prices: 'prices.',
                stagingPublish: 'Staging Publish',
                immediateInfo: 'The model will be immediately published and serve ',
                stagingInfo: ' prices. Staging is used to test a model.',
                private: 'private',
                publishNow: 'Publish Now',
                price: ' price.'
            },
            base: {
                baseModelVariables: 'Base Model Variables',
                necessary: 'Necessary for all rating models',
                upfrontSplit: 'Upfront split (%)',
                upfrontSplitInfo: 'The percentage of premium collected upfront',
                version: 'Version',
                versionInfo: 'The version of this model',
                basePremium: 'Base Premium',
                basePremiumInfo: 'The base premium of your model',
                minPremium: 'Min Premium',
                minPremiumInfo: 'The minimum base premium of your model',
                maxPremium: 'Max Premium',
                maxPremiumInfo: 'The maximum base premium of your model',
                ipt: 'IPT (%)',
                iptInfo: 'The rate of insurance premium tax',
                commission: 'Commission (%)',
                commissionInfo: 'The commission rate. Set to 0 for net rated',
                compulsoryExcess: 'Compulsory Excess',
                compulsoryExcessInfo: 'The compulsory excess to apply',
                usageVariables: 'Usage Variables',
                onlyIf: 'Only if usage rating models',
                usageSplit: 'Usage split (%)',
                averageMilage: 'Average Mileage',
                minUsage: 'Min Usage',
                maxUsage: 'Max Usage',
                minUpfront: 'Min Upfront',
                maxUpfront: 'Max Upfront',
                usageSplitInfo: 'The percentage of premium collected through the variable usage',
                averageMilageInfo: 'The average yearly mileage of market',
                minUsageInfo: 'The minimum usage rate of your model',
                maxUsageInfo: 'The maximum usage rate of your model',
                minUpfrontInfo: 'The minimum upfront rate of your model',
                maxUpfrontInfo: 'The maximum upfront rate of your model',
                subscriptionVariables: 'Subscription Variables',
                onlyIfSubscription: 'Only if subscription rating models',
                subscriptionSplit: 'Subscription Split',
                installments: 'Installments',
                minSubscription: 'Min Subscription',
                maxSubscription: 'Max Subscription',
                subscriptionSplitInfo: 'The percentage of premium collected through all subscription installments',
                installmentsInfo: 'The number of subscription installments',
                minSubscriptionInfo: 'The minimum subscription premium',
                maxSubscriptionInfo: 'The maximum subscription premium',
            },
            schema: {
                errorJsonFormat: 'JSON schema is not formatted correctly',
                errorQuoteFormat: 'Quote example is not formatted correctly',
                title: 'Quote Schema',
                revertToDefault: 'Revert to default',
                viewSchema: 'View Schema',
                editSchema: 'Edit Schema',
                schemaInfo: 'Defining the quote parameters via JSON schema. You can also define the canonical quote example.',
                jsonSchema: 'JSON Schema',
                jsonPlaceholder: 'Enter the JSON schema here',
                quoteExample: 'Quote Example',
                quotePlaceholder: 'Enter the quote example here',
                test: 'Test',
                result: 'Result',
                validate: 'Valid Schema and Example',
            },
            factors: {
                title: 'Factors',
                factorInfo: 'A factor (e.g. minimum driver age) weights are used in your formula for premium calculations.',
                upload: 'Upload a factor .csv to create.',
                drop: 'Drop .csv here',
                csv: 'CSV',
                factor: 'Factor :',
                saveChanges: 'Save changes',
            },
            linked: {
                notValid: 'Not a valid linked file',
                title: 'Linked',
                linkedInfo: 'A linked table makes associations between quote factors. e.g. vehicle abicode linking to vehicle security rating.',
                upload: 'Upload a linked table .csv to create.',
                drop: 'Drop .csv here',
                csv: 'CSV',
            },
            exclusions: {
                title: 'Exclusions',
                create: 'Create Exclusion +',
                exclusionInfo: 'A passed exclusion rule (e.g. claim count) will exclude a quote from being given a premium price.',
                modalInfo: 'The exclusions are defined in By Bits Query language.',
                name: 'Name',
                namePlaceholder: 'Name of your exclusion',
                expressionPlaceholder: 'Enter the exclusion expression',
                validExpression: 'Valid Expression',
                editInfo: 'You can edit the quote object below to test exclusions.',
                quoteIncluded: 'Quote is included',
                quoteExcluded: 'Quote is excluded',
                jsonInvalid: 'Quote JSON is invalid',
                enrichedQuote: 'Enriched Quote',
            },
            endorsements: {
                title: 'Endorsements',
                create: 'Create Endorsement +',
                endorsementsInfo: 'A passed endorsement rule (e.g. Must park in garage) will add the endorsement to the pricing result.',
                modalTitle: 'Endorsement',
                modalInfo: 'The endorsements are defined in By Bits Query language. Find out more ',
                here: 'here',
                name: 'Name',
                namePlaceholder: 'Name of your endorsement',
                descriptionPlaceholder: 'Enter the endorsement description',
                value: 'Value',
                valuePlaceholder: 'Value of your endorsement',
                expressionPlaceholder: 'Enter the endorsement expression',
                validExpression: 'Valid Expression',
                editInfo: 'You can edit the quote object below to test endorsements.',
                quoteIncluded: 'Quote is included',
                quoteExcluded: 'Quote is excluded',
                jsonInvalid: 'Quote JSON is invalid',
                enrichedQuote: 'Enriched Quote',
            },
            machineLearning: {
                title: 'Machine Learning',
                import: 'Import Model +',
                machineLearningInfo: 'Machine learning models can be uploaded and the output used to create a new factor or within the formula.',
                modalTitleConfigure: 'Configure ML Model',
                modalTitleUpload: 'Upload ML Model',
                error: 'ERROR',
                modalInfo: 'The model name should not include any white space.',
                name: 'Name',
                namePlaceholder: 'Model name',
                description: 'Description',
                descriptionPlaceholder: 'Description of model',
                modelLibrary: 'Model Library',
                selectModelLibrary: 'Select model library',
                xgBoost: 'XGBoost',
                mlModelRef: 'ML Model Reference',
                fileType: 'File Type',
                uploadDate: 'Upload Date',
                defineColumns: 'Define the columns requiring one-hot encoding and for predictions',
                selectOrDropFile: 'Select or drop model file for upload.',
                dropModelFile: 'Drop model file here',
                progress: 'Progress',
                uploaded: 'Uploaded?',
            },
            formulas: {
                title: 'Formulas',
                create: 'Create Formula +',
                formulasInfo: 'The formulas are the sum of factor weights. You can define complex calculations to create premiums.',
                modalTitle: 'Formula',
                modalInfo: 'The formula is made up from factors. Undefined factors are highlighted red when validating.',
                name: 'Name',
                namePlaceholder: 'Name of your formula',
                expressionPlaceholder: 'Enter the formula expression',
                validFormulaExpression: 'Valid Formula Expression'
            },
            tests: {
                title: 'Test Scenarios',
                add: 'Add Test',
                run: 'Run Tests',
                exportCSV: 'Export CSV',
                testsInfo: 'Test scenarios will be run against the latest published model. Requires historic quotes to process.',
                validQuotes: 'Valid Quotes',
                avePremium: 'Ave Premium',
                invalidQuotes: 'Invalid Quotes',
                quotability: 'Quotability',
                referredQuotes: 'referred quotes',
                with: 'with',
                modalTitle: 'Tests',
                modalInfo: 'A new test can only be added if the quote reference is valid.',
                name: 'Name',
                namePlaceholder: 'Name of test',
                quoteRef: 'Quote Reference',
                quoteRefPlaceholder: 'Quote reference of test case',
                modelReference: 'Model Reference:',
                quoteDetails: 'Quote Details',
                pricingDetails: 'Pricing Details',
            },
            versions: {
                title: 'Other Versions',
                versionsInfo: 'These are all the previous versions of the model. The latest publish versioned is what is served.',
            },
        },
    },
    policy: {
        title: 'Policies',
        nav: {
            policyDetail: 'Policy Detail',
            policyVersions: 'Policy Versions',
            policyStatements: 'Policy Statements',
            policyJourneys: 'Policy Journeys',
            policyUsers: 'Policy Users',
            policyActivity: 'Policy Activity',
            policyCancellation: 'Policy Cancellation',
            policyContactDetails: 'Policy Contact Details',
            policyAmendment: 'Policy Amendment',
            policyRenewals: 'Policy Renewals',
            policyPayments: 'Policy Payments',
            policyCredits: 'Policy Credits',
            policyUsages: 'Policy Usages',
            policyDocuments: 'Policy Documents',
            policyComms: 'Policy Comms',
            policyDevices: 'Policy Devices',
            policyClaims: 'Policy Claims',
        },
        policies: {
            search: 'Search',
            searchByEtc: 'Search By Name/Postcode/Reg/Reference',
            searchByPolicy: 'Search By Policy Reference',
            filter: 'Filter by product',
            all: 'All',
            expiring: 'Expiring',
            filterDays: 'Filter by days left until expiry',
            expiresIn: 'Expires in:',
            dashboard: 'Dashboard',
            range: 'Range:',
            sold: 'Sold',
            cancelled: 'Cancelled',
            premium: 'Premium',
            refunds: 'Refunds',
            fees: 'Fees',
            emailSent: 'Email Sent',
            emailError: 'Email Error',
            resetPassword: 'Reset Password',
            total: 'Total',
            addCredit: 'Add Credit',
            code: 'Code',
            description: 'Description',
            amount: 'Amount',
            required: 'Required',
            credit: 'Credit',
            credits: 'Credits',
            addUsage: 'Add Usage',
            usage: 'Usage',
            usages: 'Usages',
            type: 'Type',
            date: 'Date',
            distanceInMetres: 'Distance in metres',
            contactDetailsDescription: 'To amend the contact details, enter new details below and then click on update',
            contactDetailsUpdated: 'Contact Details Updated',
            contactDetailSuccess: 'Contact details for this policy has been updated successfully',
            noCommunications: 'Sorry, no policy communications found!',
            file: 'File',
            files: 'Files',
            fileName: 'File name',
            addFile: 'Add File',
            documentType: 'Document type',
            acceptableFileTypes: 'Acceptable file types',
            uploadDate: 'Upload date',
            deleteFile: 'Delete file',
            deleteWarning: 'Are you sure you want to delete this file?',
            fileError: 'Please attach a file and try again',
            imageError: 'Please attach a valid image or video file and try again',
            extensionError: 'Please ensure the file has a valid file extension and try again',
            base: 'Base',
            autoRenew: 'Auto Renew',
            address: 'Address',
            vehicle: 'Vehicle',
            modifications: 'Modifications',
            addModification: 'Add Modification',
            custom: 'Custom',
            addCustomField: 'Add Custom Field',
            pricing: 'Pricing',
            proposer: 'Proposer',
            driver: 'Driver',
            drivers: 'Drivers',
            addDriver: 'Add Driver',
            addAdditionalDriver: 'Add Additional Driver',
            deleteDriver: 'Delete Driver',
            driverDetails: 'Driver Details',
            driverClaims: 'Driver Claims',
            driverConvictions: 'Driver Convictions',
            selectDriverDelete: 'Select Driver to Delete',
            deleteDriverWarning: 'You are about to delete a driver from the policy',
            selectDriver: 'Select Driver',
            claims: 'Claims',
            addClaim: 'Add Claim',
            convictions: 'Convictions',
            addConviction: 'Add Conviction',
            requiredFields: 'Required fields',
            emailPhoneAmend: 'Email and Phone number can be amended in the',
            section: 'section',
            keySpaceWarning: 'Please ensure that the key does not contain any spaces',
            requiredNotPopulated: 'Required fields not populated',
            requiredFieldsWarning: 'There are required fields that have not been populated in the following section(s)',
            selectEffectiveDate: 'Select Effective Date of MTA',
            noChangesWarning: 'NOTE: It looks like no changes have been made to the policy',
            detailsCorrectWarning: 'Please ensure all customer details are correct before proceeding',
            sameDayWarning: 'WARNING: There are already policy versions effective on',
            selectMethodPrice: 'Select method for updating price',
            mtaPriceText: 'The Request MTA Price option will call the rating engine to generate a price. Manual Price Entry option allows the price to be manually entered.',
            manualPriceEntry: 'Manual Price Entry',
            requestMtaPrice: 'Request MTA Price',
            ratingCallSuccessful: 'The rating engine has been called successfully and the Upfront, Usage and Subscription Rates have been updated.',
            furtherAdjustments: 'Further manual adjustments can be made to the items below.',
            chosenManualPrice: 'You have chosen to manually enter the price and not call the rating engine.',
            updateFieldsBelow: 'Please update the fields below as appropriate.',
            anyFurtherAdjustments: 'Any further adjustments to price?',
            seeMtaPremium: 'Click next to see the calculated MTA premium that should be chargeable/refunded to the customer.',
            mtaSummary: 'MTA Summary',
            effectiveDate: 'Effective date',
            before: 'Before',
            after: 'After',
            change: 'Change',
            value: 'Value',
            unableToProceed: 'Unable to proceed due to error',
            summaryOfChange: 'Summary of change and amount due to the customer',
            mtaProcessed: 'MTA Processed',
            mtaSuccessful: 'MTA has been submitted successfully',
            mtaEffectiveFrom: 'MTA is effective from',
            unsavedChangesWarning: 'You have unsaved changes... Are you sure you want to navigate away?',
            unsuccessfulPayments: 'Unsuccessful Payments',
            successfulPayments: 'Successful Payments',
            yes: 'Yes',
            no: 'No',
            emailPhoneUpdated: 'The Email and Phone number have been updated for this policy',
            the: 'The',
            hasBeenUpdated: 'has been updated for this policy',
            email: 'Email',
            phoneNumber: 'Phone Number',
            claimCode: 'Claim code',
            atFault: 'At fault',
            ncdLost: 'NCD lost',
            convictionCode: 'Conviction code',
            points: 'Points',
            ban: 'Ban',
            claim: 'Claim',
            newDriver: 'New Driver',
            conviction: 'Conviction',
            noteProduct: 'Note: The Product is in',
            timezone: 'timezone',
            add: 'Add',
        },
        table: {
            view: 'View',
            detail: 'Detail',
            paid: 'Paid',
            failed: 'Failed',
            retry: 'Retry',
            resend: 'Resend',
            sent: 'Sent',
            current: 'Current',
        },
        journeys: {
            journey: 'Journey',
            void: 'Void',
            where: 'Where',
            policy: 'Policy',
            distanceOverridden: 'Distance overridden',
            journeyVoided: 'Journey Voided',
            voidJourney: 'Void Journey',
            billed: 'Billed',
            start: 'Start',
            end: 'End',
            override: 'override',
            usageRate: 'Usage Rate',
            provider: 'Provider',
            premium: 'Premium',
            distance: 'Distance',
            originalDistance: 'The original distance is overridden',
            originalPremium: 'The original premium is overridden',
        },
        statements: {
            statement: 'Statement',
            statements: 'Statements',
            sub: 'sub',
            total: 'Total',
            policyReference: 'Policy Reference',
            journeys: 'Journeys',
            usage: 'Usage',
        },
        activity: {
            activity: 'Activity',
            users: 'Users',
            requests: 'Requests'
        },
        claims: {
            addClaim: 'Add claim',
            claimType: 'Claim type',
            claimLat: 'Claim latitude',
            claimLong: 'Claim longitude',
            claimDesc: 'Claim Description',
            reportedDate: 'Reported date',
            lossDate: 'Loss date',
            claimDetails: 'Claim details',
            moreDetails: 'More details',
            details: 'Details',
            claimRef: 'Claim ref.',
            thirdParties: 'Third parties',
            witnesses: 'Witnesses',
            police: 'Police',
            claimVersions: 'Claim versions',
            addClaimVersion: 'Add claim version',
            claimVersion: 'Claim Version',
            update: 'Update',
            updateRequired: 'Update is required',
            policyHolderFault: 'Policyholder fault',
            status: 'Status',
            reported: 'Reported',
            active: 'Active',
            settled: 'Settled',
            estimatedAmount: 'Estimated amount',
            incurredAmount: 'Incurred amount',
            settledDate: 'Settled date',
            settledAmount: 'Settled amount',
            required: 'Required',
            claimDocuments: 'Claim documents',
            addFile: 'Add file',
            deleteFile: 'Delete file',
            areYouSure: 'Are you sure you want to delete this file?',
            file: 'File',
            documentType: 'Document type',
            photo: 'Photo',
            video: 'Video',
            statement: 'Statement',
            other: 'Other',
            photoType: 'Photo type',
            location: 'Location',
            acceptableFileTypes: 'Acceptable file types',
            noFileError: 'Please attach a file and try again',
            notAcceptableError: 'Please attach a valid file and try again.',
            noExtensionError: 'Please ensure the file has a valid file extension and try again',
            accident: 'Accident',
            fire: 'Fire',
            theft: 'Theft',
            driver: {
                title: 'Driver',
                name: 'Driver name',
                phone: 'Driver phone no.',
                house: 'Driver house no.',
                postcode: 'Driver post code',
                injuries: 'Driver injuries',
                reg: 'Driver reg',
                passengers: 'Driver passengers',
                damage: 'Driver damage',
            },
            thirdParty: {
                title: 'Third Party',
                name: 'TP name',
                phone: 'TP phone no.',
                house: 'TP house no.',
                postcode: 'TP post code',
                injuries: 'TP injuries',
                reg: 'TP reg',
                passengers: 'TP passengers',
                damage: 'TP damage',
            },
            witness: {
                name: 'Witness name',
                phone: 'Witness phone no.',
                house: 'Witness house no.',
                postcode: 'Witness post code',
            },
        },
        telematics: {
            obdDevices: 'OBD Devices',
            active: 'Active',
            connectedCar: 'Connected Car',
            mobileTag : 'Mobile Tag',
            packet: 'Packet',
            deviceLocation: 'Device location',
            reference: 'Reference',
            startTime: 'Start Time',
            endTime: 'End Time',
            tagConfidence: 'Tag Condifence',
            duration: 'Duration',
            distance: 'Distance',
            maxSpeed: 'Max Speed',
            imei: 'IMEI',
            dataPackets: 'Data packets',
            findingDevice: 'Finding device details. This may take a moment.',
            packetsRoute: 'Packets Route',
            devices: 'Devices',
            latitude: 'latitude',
            longitude: 'longitude',
            speed: 'speed',
            time: 'time',
            index: 'index',
        },
        reports: {
            records: 'Records',
            claims: 'Claims',
            unpaidVersions: 'Unpaid Versions',
            policies: 'Policies',
            download: 'Download',
            fileType: 'File type',
            maxRecords: 'Max number of records e.g. 100 (optional)',
            startRecords: 'Start at record number e.g. 100(optional)',
        },
        cancellation: {
            policy: 'Policy',
            policyRef: 'Policy reference',
            status: 'Status',
            startDate: 'Start date',
            endDate: 'End date',
            underwriter: 'Underwriter',
            policyCancellation: 'Policy Cancellation',
            whenPolicyCancel: 'When would you like this Policy to be cancelled?',
            noteTimezone: '(Note: The Product is in',
            selectCancellationDate: 'Select Cancellation Date',
            cancelPolicy: 'Cancel Policy',
            errorEffectiveDate: 'Effective date of Cancellation cannot be before effective date of a pending policy version:',
            thePolicy: 'The policy',
            alreadyCancelled: 'has already been cancelled. Cancellation date: ',
            dueToBeCancelled: 'is due to be cancelled on',
            lapsedNoCancel: 'is lapsed and cannot be cancelled.',
            selectDatePlaceholder: 'Select a date',
            refund: 'Refund',
            refuntAmount: 'The refund amount for the cancellation is',
            consistingOf: 'which consists of the following:',
            upfrontPremium: 'Upfront premium:',
            adminFee: 'Admin fee: ',
            cancellationFee: 'Cancellation fee: ',
            dueAfter: 'It is due after',
            areYouSure: 'Are you sure you wish to cancel the policy',
            on: 'On',
        },
    },
    currency: '£',
    distanceUnit: {
        miles: 'mi',
    },
    time: {
        ms: 'ms',
        hour: 'hour',
        day: 'day',
        week: 'week',
        month: 'month',
    },
    tableHeaders: {
        name: 'Name',
        product: 'Product',
        status: 'Status',
        reg: 'Reg',
        postcode: 'Postcode',
        sub: 'Sub.',
        pm: 'PM.',
        startDate: 'Start Date',
        endDate: 'End Date',
        date: 'Date',
        active: 'Active',
        chart: 'Chart',
        sold: 'Sold',
        cancelled: 'Cancelled',
        totalPremium: 'Total Premium',
        fee: 'Fee',
        refund: 'Refund',
        live: 'Live',
        sche: 'Sche.',
        draft: 'Draft',
        ghost: 'Ghost',
        publishedAt: 'Published At',
        updatedAt: 'Updated At',
        version: 'Version',
        quotes: 'Quotes',
        quotability: 'Quotability',
        upfront: 'Upfront',
        subs: 'Subs.',
        yearly: 'Yearly',
        mileage: 'Mileage',
        ref: 'Ref',
        valid: 'Valid',
        source: 'Source',
        distance: 'Dist.',
        premium: 'Premium',
        created: 'Created',
        reference: 'Reference',
        distance: 'Distance',
        upfront: 'Upfront',
        total: 'Total',
        type: 'Type',
        email: 'Email',
        units: 'Units',
        currency: 'Currency',
        stripeKeys: 'Stripe Keys',
        webhook: 'Webhook',
        month: 'Month',
        activeModels: 'Active Models',
        aveResponse: 'Ave. Response',
        cost: 'Cost',
        month: 'Month',
        model: 'Model',
        username: 'Username',
        production: 'Production',
        sandbox: 'Sandbox',
        route: 'Route',
        policyReference: 'Policy Reference',
        rate: 'Rate',
        distance: 'Distance',
        count: 'Count',
        usagePremium: 'Usage Premium',
        subPremium: 'Sub. Premium',
        units: 'Units',
        durationMins: 'Duration (mins)',
        total: 'Total',
        subscription: 'Subscription',
        reference: 'Reference',
        url: 'Url',
        created: 'Created',
        username: 'Username',
        journey: 'Journey',
        journeys: 'Journeys',
        statements: 'Statements',
        usage: 'Usage',
        claimType: 'Claim type',
        reportedDate: 'Reported date',
        createdAt: 'Created at',
        update: 'Update',
        policyholderFault: 'Policyholder fault',
        estimatedAmount: 'Estimated amount',
        incurredAmount: 'Incurred amount',
        settledDate: 'Settled date',
        settledAmount: 'Settled amount',
        fileName: 'File name',
        documentType: 'Document type',
        tag: 'Tag',
        uploadDate: 'Upload date',
        scenarios: 'Scenarios',
        value: 'Value',
        id: 'ID',
        dimensions: 'Dimensions',
        response: 'Response',
        key: 'Key',
        rows: 'Rows',
        expression: 'Expression',
        value: 'Value',
        description: 'Description',
        library: 'Library',
        filterExpression: 'Filter Expression',
        id: 'ID',
        excess: 'Excess',
        exclude: 'Exclude',
        refer: 'Refer',
        notRefer: 'Not refer',
        include: 'Include',
        published: 'Published',
        lastUpdated: 'Last updated',
        updated: 'Updated',
        values: 'Values',
        endorsement: 'Endorsement',
        weight: 'Weight',
        authenticationStatus: 'Authentication status',
        code: 'Code',
        description: 'Description',
        amount: 'Amount',
        event: 'Event',
        subject: 'Subject',
        sentDate: 'Sent Date',
        atFault: 'At Fault',
        ncdLost: 'NCD Lost',
        points: 'Points',
        ban: 'Ban',
        versionType: 'Version Type',
        creditApplied: 'Credit applied',
        versionDate: 'Version Date',
        createdAtDate: 'Created At Date',
        transactionRef: 'Transaction Ref',
        paymentId: 'Payment ID',
        amountUnpaid: 'Amount Unpaid',
        vehicleReg: 'Vehicle Reg',
        deviceReference: 'Device Reference',
        policy: 'Policy',
        tag: 'Tag',
        location: 'Location',
        heartbeat: 'Heartbeat',
        age: 'Age',
        status: 'Status',
        timestamp: 'Timestamp',
        size: 'Size',
        moving: 'Moving',
        speed: 'Speed (kmh)',
        activity: 'Activity',
        device: 'Device',
        packetRawDetail: 'Packet Raw Detail',
        start: 'Start',
        end: 'End',
        distanceM: 'Distance (M)',
        duration: 'Duration',
        lifecycle: 'Lifecycle',
        paid: 'Paid',
        effectiveDate: 'Effective Date',
        versionType: 'Version Type',
        versionDate: 'Version Date',
        createdAtDate: 'Created At Date',
        amountUnpaid: 'Amount unpaid',
        refundAmount: 'Refund Amount',
        paymentMethod: 'Payment Method',
        journeyRoute: 'Journey Route',
        journeyInformation: 'Journey Information',
        journeyEncodedPolyline: 'Journey Encoded Polyline',
    },
    components: {
        dropdown: {
            pleaseSelect: 'Please select',
        },
        empty: {
            no: 'No',
            found: 'found',
        },
        errors: {
            code: '404',
            oops: 'Oops.. You just found an error page..',
            sorry: 'We are sorry but our service is currently not available',
            back: 'Go back',
        },
        modal: {
            beenAdded: 'has been added successfully',
            ok: 'Ok',
            discard: 'Discard',
            policy: {
                name: 'Name:',
                reg: 'Reg:',
                postcode: 'Postcode:',
                start: 'Start:',
            },
        },
        search: {
            search: 'Search',
        },
        pagination: {
            showing: 'Showing',
            to: 'to',
            of: 'of',
            prev50: 'Prev 50',
            next50: 'Next 50',
        },
        superTable: {
            results: 'Results :',
        },
        formAddress: {
            address: 'Address',
            lineOne: 'Address line 1',
            lineTwo: 'Address line 2',
            lineThree: 'Address line 3',
            postcode: 'Address postcode',
            city: 'Address city',
            county: 'Address county',
            country: 'Address country',
        },
    },
}

export default strings;
