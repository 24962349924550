import React from 'react';

function buildLabel (label = '', property = '') {
    if (label !== '') return label;
    let str = property.split('_').join(' ');
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function Disabled (props) {
    const {
        label,
        property,
        value = '',
        type = 'text',
        width = 140,
    } = props;
    return (
        <div className="input-group mb-2">
            <div className="input-group-prepend">
                <span className="input-group-text" style={{ width }}>{buildLabel(label, property)}</span>
            </div>
            <input
               type={type}
               className={`form-control`}
               autoComplete="off"
               defaultValue={value}
               disabled={true}
            />
        </div>
    )
}

export default Disabled;
