import React, { Component } from 'react';
import { isEmpty, round, get, first, find } from 'lodash';
import moment from 'moment';
import { putS3SignedURL } from '../../../../../utils/S3SignedURL';
import UploadModelSection from './components/UploadModelSection';
import ConfigureModelSection from './components/ConfigureModelSection';
import MLModelDetailsSection from './components/MLModelDetailsSection';

class ModalMachineLearning extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: {},
            file: {},
            data: null,
            upload_success: null,
            is_uploading: null,
            progress: 0,
            valid_info: false,
            edit_model: false,
            error: null,
        }

        this.clear = this.clear.bind(this);
        this.validate = this.validate.bind(this);
        this.retry = this.retry.bind(this);
        this.uploadModel = this.uploadModel.bind(this);
        this.uploadToS3 = this.uploadToS3.bind(this);
        this.uploadButtonHandler = this.uploadButtonHandler.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
        this.update = this.update.bind(this);
    }

    componentDidUpdate(prevProps) {
        // For 'edit' model functionality so that we can re-use the modal for models that have been imported already
        if (this.props.model.ml_model_reference && !prevProps.model.ml_model_reference) {

            const { model, models } = this.props;

            const modelToState = find(models, m => m.ml_model_reference === model.ml_model_reference);

            this.setState({
                model: modelToState,
                edit_model: true,
                valid_info: true, // Setting this to be true as we do not re-validate the form fields. However the fields will have already been populated
            });
        }
    }

    update(stateItem) {
        this.setState({ 
            ...stateItem, 
        });
    }

    clear() {
        this.props.openModal({
            machine_learning: {
                open: false,
                model: {},
            }
        });

        this.setState({
            model: {},
            file: {},
            upload_success: null,
            is_uploading: null,
            progress: 0,
            valid_info: false,
            edit_model: false,
            error: null,
        });
    }

    validate(e) {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            model:{
                ...this.state.model,
                [name]: value,
            }
        }, () => {
            const { name, description, library } = this.state.model;

            if (name && description && library) {
                this.setState({
                    valid_info: true,
                });
            } else {
                this.setState({
                    valid_info: false,
                });
            }
        });
    }

    async uploadToS3() {

        try {
            const { model, file: { path }, data } = this.state;
            const { library } = model;
            const { pricing_ml_upload_post, version_reference, saveVersion, model_reference, models } = this.props;

            //Extract file extension from file object

            const file_split= path.split('.');
            const ml_model_reference = first(file_split).toLowerCase();
            const file_type = file_split.pop().toLowerCase();

            // Check to see if ml_model_reference already exists in ml_models array - if it does then return out of this function and set error in state

            const fileAlreadyExists = !!find(models, m => m.ml_model_reference === ml_model_reference); // if find fn can't find the object, it will return undefined

            if (fileAlreadyExists) {
                return this.setState({
                    error: `A file with the name '${ml_model_reference}' already exists, please choose a different file`,
                    file: {},
                    data: null,
                    is_uploading: null,
                });
            }

            //Get signed URL from endpoint

            const res = await pricing_ml_upload_post({
                data: {
                    id: `${model_reference}/${library}/${ml_model_reference}.${file_type}`,
                    method: 'put',
                }
            });

            const signed_url = get(res, 'payload.data.signed_url');

            //Use signed URL to upload to S3

            await putS3SignedURL({
                    signed_url,
                    data,
                    headers: {
                        'x-amz-acl': 'public-read',
                        'Content-Type': 'application/octet-stream'
                    }
            }, e => this.updateProgress(e));
            
            // After uploading 

            //Pass ML model info to local state
            const newModel = {
                ...model,
                path,
                ml_model_reference,
                file_type,
                model_reference,
            }

            const newModels = [...this.props.models, newModel];
            
            this.props.updateMLModels(newModels);

            //Update state
            this.setState({
                is_uploading: false,
                upload_success: true,
            });
            
            // Save model JSON to S3 after executing updateMLModels to make sure S3 and UI are in sync
            saveVersion({
                version_reference,
                reload: true,
            });

            } catch (err) {
                //Failed upload
                console.log(err.message);
                this.setState({
                    is_uploading: false,
                    upload_success: false,
                });
            }
    }

    updateProgress(e) {
        const progress = e.loaded / e.total;
        this.setState({
            progress,
        });
    }

    uploadModel() {
        this.setState({
            model: {
                ...this.state.model,
                one_hot_keys: [],
                one_hot_columns: [],
                upload_date: moment(),
            },
            is_uploading: true,

        }, async() => await this.uploadToS3());
    }

    retry() {
        this.setState({
            progress: 0,
            upload_success: null,
        }, () => this.uploadModel());
    }

    uploadButtonHandler() {
        const { upload_success } = this.state;

        if (upload_success === null) {
            this.uploadModel();

        } else if (upload_success === true) {
            this.clear();

        } else {
            this.retry();
        }
    }

    saveConfig() {
        const { version_reference, saveVersion, models, updateMLModels } = this.props; //models refer to the ml models array
        const { model } = this.state; //This is the model we are editing

        // Find the model we are editing in the 'models' array and update

        const all_models_updated = models.map(m => m.path === model.path ? model : m);

        // Update local state

        updateMLModels(all_models_updated);

        saveVersion({
            version_reference,
            reload: true,
        });

        this.clear();
    }

    render() {
        const { open, lang } = this.props;
        const { file, upload_success, is_uploading, progress, valid_info, model, edit_model, error } = this.state;
        const progress_perc = round(progress * 100);
        return (
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-ml" tabindex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{edit_model ? lang.t('rating.components.machineLearning.modalTitleConfigure') : lang.t('rating.components.machineLearning.modalTitleUpload')}</h5>
                            {(!is_uploading) &&
                                <button type="button" onClick={this.clear} className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="fe fe-x"></i>
                                </button>
                            }
                        </div>
                        <div className="modal-body">
                            {error && (
                                <div className="alert alert-danger" role="alert">
                                    <i style={{ marginRight: 5 }} className="fe fe-alert-triangle"></i>
                                    {`${lang.t('rating.components.machineLearning.error')} - ${error}`}
                                </div>
                            )}
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        {lang.t('rating.components.machineLearning.modalInfo')}
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <MLModelDetailsSection 
                                                        model={model}
                                                        validate={this.validate}
                                                        edit_model={edit_model}
                                                        is_uploading={is_uploading}
                                                        upload_success={upload_success}
                                                        lang={lang}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6" style={{ position: 'relative' }}>
                                    {edit_model 
                                        ?
                                        <ConfigureModelSection
                                            model={model}
                                            update={this.update}
                                            lang={lang}
                                        />
                                        :
                                        <UploadModelSection 
                                            file={file}
                                            is_uploading={is_uploading}
                                            upload_success={upload_success}
                                            progress_perc={progress_perc}
                                            update={this.update}
                                            models={this.props.models}
                                            lang={lang}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {edit_model && (
                                <button 
                                    type="button" 
                                    onClick={this.saveConfig} 
                                    className="btn btn-primary" 
                                    disabled={!valid_info || error}
                                >
                                    {lang.t('buttons.saveModel')}
                                </button>
                            )}
                            {!edit_model && (
                                <button 
                                    type="button" 
                                    onClick={this.uploadButtonHandler} 
                                    className="btn btn-primary" 
                                    disabled={is_uploading || isEmpty(file) || !valid_info}
                                >
                                    {`${upload_success === null ? lang.t('buttons.uploadModel') : (upload_success === true ? lang.t('buttons.finished') : lang.t('buttons.retry'))}`}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalMachineLearning;