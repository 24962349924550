import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import main from '../../../../hoc/main';
import { getId } from '../../../../utils/url';

// Core Design
import SpinnerBody from '../../../../components/loaders/SpinnerBody';
import Empty from '../../../../components/empty/Results';

import DevicesTable from '../../components/Telematics/DevicesTable';
import JourneysTable from '../../components/Telematics/JourneysTable';
import LocationMap from '../../components/Telematics/JourneyMap';
import PacketsTable from '../../components/Telematics/PacketsTable';
import PacketsMap from '../../components/Telematics/PacketsMap';

import Search from '../../../../components/search/Simple';

import { get, isEmpty } from 'lodash';

class TelematicsDeviceDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            section: 'journeys',
            packet_limit: 100,
            show_packets_route: false,
            vehicle_index: 0,
        }
        this.page = this.page.bind(this);
        this.fetch_packets = this.fetch_packets.bind(this);
        this.fetch_packet = this.fetch_packet.bind(this);
    }

    async componentWillMount() {
        const { history } = this.props;
        const { packet_limit } = this.state;
        const id = getId(history);
        const [ device_reference, policy_reference ] = id.split('::');
        await this.props.telematics_devices_get(policy_reference != 'null' ? { id: device_reference, policy_reference } : { id: device_reference });
        await this.props.telematics_journeys_get_all(policy_reference != 'null' ? { device_reference, policy_reference } : { device_reference });
        await this.props.telematics_packets_get_all({ device_reference, limit: packet_limit, verbose: true });
    }

    async page(section) {
        this.setState({ section });
    }

    fetch_packets(packet_limit) {
        const { history } = this.props;
        const id = getId(history);
        const [ device_reference, policy_reference ] = id.split('::');
        let payload = { device_reference, limit: packet_limit }
        // Verbose search
        if (packet_limit.includes('-v')) {
            payload = { 
                ...payload,
                limit: packet_limit.split('-v')[0],
                verbose: true
            };
        }
        this.setState({
            packet_limit,
        }, async () => {
            await this.props.telematics_packets_get_all(payload);
        });
    }

    fetch_packet({
        packet_reference,
        vehicle_index,
    }) {
        this.setState({
            vehicle_index,
        }, async () => {
            await this.props.telematics_packets_get({
                id: packet_reference,
            });
        });
    }

    render() {
        const isFetchingDevices = this.props.telematics_devices.isFetching;
        const isFetchingJourneys = this.props.telematics_journeys.isFetching;
        const isFetchingPackets = this.props.telematics_packets.isFetching;
        const device = this.props.telematics_devices.selected || {};
        const journeys = this.props.telematics_journeys.data || [];
        const packets = this.props.telematics_packets.data || [];

        const isFetching = (isFetchingDevices || isFetchingJourneys || isFetchingPackets);
        
        const { show_packets_route, packet_limit, vehicle_index } = this.state;

        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    <div className="row">
                        {/* Loader */}
                        {isFetching && <SpinnerBody message={lang.t('policy.telematics.findingDevice')} />}

                        {/* Table */}
                        {!isFetching && !isEmpty(device) && (
                            <div className="col-sm-12 col-lg-12">
                                <DevicesTable 
                                    data={[device]}
                                    show_button={false}
                                    lang={lang}
                                />
                                
                                {journeys.length > 0 &&
                                    <div className="row mb-3">
                                        <div className="col-lg-8">

                                            <JourneysTable 
                                                data={get(this.props.telematics_journeys, 'data', [])}
                                                lang={lang}
                                            />

                                        </div>
                                        <div className="col-lg-4">
                                            <table 
                                                className="table table-vcenter table-mobile-md card-table" 
                                                style={{ background: 'white', border: '1px solid #868c973d' }}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>{lang.t('policy.telematics.deviceLocation')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ padding:0 }}>
                                                            <LocationMap latitude={device.latitude} height={280} longitude={device.longitude} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
              
                                {packets.length > 0 &&
                                    <div className="row mb-3">
                                        <div className="col-lg-6">
                                            <button
                                                onClick={() => {
                                                    this.setState({
                                                        show_packets_route: !show_packets_route,
                                                    })
                                                }}
                                                className="btn btn-sm btn-primary"
                                            >
                                                {show_packets_route ? lang.t('buttons.hide') : lang.t('buttons.show')} {lang.t('policy.telematics.packetsRoute')} 
                                            </button>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="form-group">
                                                <Search
                                                    searchTerm={packet_limit}
                                                    searchFunction={this.fetch_packets}
                                                    isFetching={get(this.props.telematics_packets, 'isFetching', false)}
                                                    label="packets"
                                                    lang={lang}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4"></div>
                                    </div>
                                }
                                
                                {show_packets_route && packets.length > 0 &&
                                    <div className="row mb-3">
                                        <div className="col-lg-12">
                                            <PacketsMap 
                                                height={374}
                                                fetch_packet={this.fetch_packet}
                                                packets={packets}
                                                vehicle_index={vehicle_index}
                                                lang={lang}
                                            />
                                        </div>
                                    </div>
                                }
                                
                                {packets.length > 0 &&
                                    <div className="row mb-3">
                                        <div className="col-lg-12">
                                            <PacketsTable 
                                                data={packets}
                                                selected={get(this.props.telematics_packets, 'selected', {})}
                                                telematics_packets_get={this.props.telematics_packets_get}
                                                policys_get_all={this.props.policys_get_all}
                                                lang={lang}
                                            />
                                        </div>
                                    </div>
                                }

                            </div>
                        )}

                    </div>
                </div>
            </div>
        );
    }
}

export default main(TelematicsDeviceDetail);