import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

class DropZone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
        };
        this.processJSON = this.processJSON.bind(this);
    }

    processJSON([file]) {
        const reader = new FileReader();
        this.setState({ uploading: true });
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
            const result = reader.result ? reader.result.toString() : '';
            this.setState({ uploading: false });
            try {
                const parsed = JSON.parse(result);
                this.props.process({
                    result: parsed,
                    is_valid_json: true,
                });
            } catch(e) {
                this.props.process({
                    is_valid_json: false,
                });
            }
        }
        reader.readAsText(file);
    }

    render() {
        const { uploading } = this.state;
        return (
            <Dropzone onDrop={acceptedFiles => this.processJSON(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <div className="card" style={{ border: '2px dotted #1f2a36'}}>
                            <div className="card-body">
                                <h4 className="card-title">{this.props.title}</h4>
                                <div style={{ width: '100%', textAlign: 'center', marginTop: 25, marginBottom: 25 }}>
                                    {!uploading && 
                                        <i style={{ fontSize: 40, opacity: 0.6 }} className="fe fe-share"></i>
                                    }
                                    {uploading &&
                                        <div style={{ width: 40, height: 40 }} className="spinner-border text-green spinner-border-sm" role="status"></div>
                                    }
                                    <input {...getInputProps()} />
                                </div>
                            </div>
                        </div>

                    </div>
                )}
            </Dropzone>          
        );
    }
}

export default DropZone;