import React, { Component } from 'react';
import Input from '../../../components/Detail/Forms/Input';
import HelpButton from '../../../../../components/buttons/HelpButton'
import prettify from 'pretti';
import cssbeautify from 'cssbeautify';
import RunWeb from '@uiw/react-run-web';
import { get } from 'lodash';
import data from '../data.json'; // meta_data property is a response data from POST/statements

class EmailTemplateDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            styles: '',
            html: '',
            subject: '',
            recipient_email: '',
            enabled: true,
            data: {},
            email_sent: false,
            spinner: false,
            versions_to_send: []
        }
        this.send = this.send.bind(this);
        this.save = this.save.bind(this);
    }

    componentWillMount() {
        const { reference, product} = this.props;
        const template = get(product, `templates.emails.${reference}`, {});
        const styles = get(product, `templates.emails.styles.html`);
        const { subject, html, enabled = true, versions_to_send = [] } = template;

        this.setState({
            subject,
            html,
            styles,
            enabled,
            data,
            versions_to_send
        })
    }

    async send() {
        const { reference, product} = this.props;
        const { recipient_email } = this.state;
        this.setState({
            email_sent: true,
            spinner: true,
            email_status: '',
            email_message: '',
        });
        try {
            await this.props.policys_emails_post({
                data: {
                product_reference: product.product_reference,
                template: reference,
                recipient_email,
                data,
            }});
            this.setState({
                email_message: 'Sent Successfully',
                email_status: 'success'
            });
        } catch (e) {
            const message = get(e, 'error.response.data.message', 'Not Valid');
            this.setState({
                email_message: message,
                email_status: 'danger'
            });
        }
        this.setState({spinner: false})
    }

    save() {
        const { reference, product } = this.props;
        const { html, styles, subject, enabled, versions_to_send = [] } = this.state;
        const new_templates = {
            templates: {
                docs: product.templates.docs,
                emails: {
                    ...product.templates.emails,
                    [reference]: {
                        ...product.templates.emails[reference],
                        html,
                        subject,
                        enabled,
                        versions_to_send,
                        //TODO - we need to deal with text
                    },
                    styles: {
                        html: styles,
                    },
                }
            }
        }
        this.props.save({
            id: product.product_reference,
            data: {
                ...product,
                ...new_templates,
            }
        });
    }

    render() {
        const { section, reference, lang } = this.props;
        const { 
            html, 
            styles, 
            subject, 
            enabled, 
            email_sent, 
            email_message, 
            email_status, 
            spinner,
            versions_to_send,
        } = this.state;
        const versions = ['nb', 'st', 'mta', 'cn']

        return (
            <div className="col-sm-12 col-lg-12">
                <div className="row"> 
                    <div className="col-lg-6">
                        <a class="btn btn-secondary" onClick={() => {
                            section({ section: 'details' })
                        }}>&lt; {lang.t('buttons.back')}</a>
                    </div>
                    <div className="col-lg-6">
                        <a 
                            class="btn btn-primary text-white" 
                            style={{ float: 'right' }}
                            onClick={(e) => {
                                e.preventDefault();
                                this.save();
                            }}
                        >{lang.t('settings.products.saveTemplate')}</a>
                        <a 
                            class={`btn ${enabled ? 'btn-red' : 'btn-green'} text-white`} 
                            style={{ float: 'right', marginRight: 20 }}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    enabled: !enabled,
                                }, () => this.save());
                            }}
                        >{enabled ? lang.t('buttons.disable') : lang.t('buttons.enable')}</a>
                        <HelpButton
                            url="https://www.bybits.co.uk/articles/using-the-email-template-area/"
                            className="help-button"
                            lang={lang}
                        />
                    </div>
                </div>
                <div className="row mt-3 mb-1"> 
                    <div className="col-lg-4">
                        <div style={{
                            border: '1px solid #e3e5ea',
                            borderRadius: 3,
                            backgroundColor: 'white',
                            padding: 14,
                            paddingBottom: 11,
                        }}>
                            <div style={{ color: '#868c97', fontSize: 12 }}>{lang.t('settings.products.emailTemplate')}</div>
                            <div style={{ fontWeight: 'bold', fontSize: 16 }}>{reference}</div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="alert alert-info" role="alert">
                            <div className="row">
                                <div className="col-lg-10">
                                    <Input 
                                        label={lang.t('settings.products.recipientEmail')}
                                        property={'recipient_email'}
                                        update={(value) => {
                                            this.setState(value)
                                        }}
                                    />
                                </div>
                                <div className="col-lg-2">
                                {spinner ?
                                    
                                    <div 
                                        className="spinner-border text-black" 
                                        role="status"
                                        style={{margin: '5px 0 0 30px'}}
                                    />
                                    :
                                    <a 
                                        className="btn btn-sm btn-primary text-white"
                                        style={{ lineHeight: '30px', height: '35px', width: '80px'}}
                                        onClick={this.send}
                                    >
                                        {lang.t('buttons.sendTest')}
                                    </a>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="version-btn-box col-lg-2">
                        <div className="version-btn-header">{lang.t('settings.products.selectVersionsEmail')}:</div>
                        <div className="version-btn-container">
                            {versions && versions.map((v, i) => {
                                return (
                                    <a 
                                        key={i}
                                        className={`btn ${versions_to_send.includes(v) ? 'btn-green' : 'btn-red' } text-white version-btn`}
                                        onClick={(e) => {
                                            e.preventDefault();

                                            if (versions_to_send.includes(v)) {
                                                const version_position = versions_to_send.indexOf(v, 0);
                                                versions_to_send.splice(version_position, 1);
                                            } else {
                                                versions_to_send.push(v);
                                            }
                                            
                                            this.setState({ 
                                                versions_to_send
                                            });
                                        }}
                                    >
                                        {`${v.toUpperCase()}`}
                                    </a>
                            )})}
                        </div>
                    </div>
                </div>
                <div className="row"> 
                    <div className="col-lg-4">
                        <div className="card">
                            <div className="card-body" style={{ position: 'relative', background: '#354052' }}>
                                <div 
                                    className="badge bg-blue" 
                                    style={{ position: 'absolute', top: 30, right: 46, opacity: 1}}>
                                    HTML
                                </div>
                                <textarea style={{ 
                                    width: '100%', 
                                    height: '252px',
                                    fontSize: 10,
                                    border: 'none',
                                    fontFamily: 'monaco',
                                    background: '#354052',
                                    color: '#f9f8f5',
                                }} 
                                    className="pre"
                                    onBlur={(e) => {
                                        this.setState({
                                            html: e.target.value,
                                        });
                                    }}
                                >
                                    {prettify(html, {ocd: true})}
                                </textarea>
                            </div>
                            <div className="card-body" style={{ position: 'relative'}}>
                                <div 
                                    className="badge bg-blue" 
                                    style={{ position: 'absolute', top: 30, right: 46, opacity: 0.4}}>
                                    CSS
                                </div>
                                
                                <textarea style={{ 
                                    width: '100%', 
                                    height: '252px',
                                    fontSize: 10,
                                    border: 'none',
                                    fontFamily: 'monaco',
                                    background: '#ffffff',
                                    color: '#354052',
                                }} className="pre"
                                    onBlur={(e) => {
                                        this.setState({
                                            styles: e.target.value,
                                        });
                                    }}
                                >
                                    {cssbeautify(styles)}
                                </textarea>
                            </div>
                        </div> 
                    </div>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-12">
                                {email_sent && email_status &&
                                    <div className="row mt-1 mb-1"> 
                                        <div className="col-lg-12">
                                            <div className={`alert alert-${email_status}`} role="alert">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        {email_message}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <Input 
                                    label={lang.t('settings.products.subject')}
                                    property={'subject'}
                                    value={subject}
                                    update={(value) => {
                                        this.setState(value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body" style={{ height: 556, padding: 0 }}>
                                <div 
                                    className="badge bg-blue" 
                                    style={{ position: 'absolute', top: 30, right: 46, opacity: 0.4}}>
                                    {lang.t('settings.products.preview').toUpperCase()}
                                </div>
                                <RunWeb
                                    css={styles}
                                    html={html}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EmailTemplateDetail;