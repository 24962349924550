import { first, get, isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Prompt } from 'react-router-dom';

import Empty from '../../../../components/empty/Results';
// Core Design
import SpinnerBody from '../../../../components/loaders/SpinnerBody';
import clientConfig from '../../../../config/client_config';
import main from '../../../../hoc/main';
import { getLangSource } from '../../../../lang';
import { getId } from '../../../../utils/url';
import ActivitySection from '../../components/Activity/Container';
import ClaimsSection from '../../components/Claims/Container';
import CreditsSection from '../../components/Credits/Container';
import PolicyHeader from '../../components/Detail/PolicyHeader';
// Service Design
import PolicyNav from '../../components/Detail/PolicyNav';
import CancellationSection from '../../components/Detail/Sections/CancellationSection';
import CommsSection from '../../components/Detail/Sections/CommsSection';
import DetailSection from '../../components/Detail/Sections/DetailSection';
import DevicesSection from '../../components/Detail/Sections/DevicesSection';
import LogSection from '../../components/Detail/Sections/LogSection';
import LoginsSection from '../../components/Detail/Sections/LoginsSection';
import MTASection from '../../components/Detail/Sections/MTASection';
import PaymentSection from '../../components/Detail/Sections/PaymentSection';
import RenewalSection from '../../components/Detail/Sections/RenewalSection';
import UpdateContactSection from '../../components/Detail/Sections/UpdateContactSection';
import VersionsSection from '../../components/Detail/Sections/VersionsSection';
import { get_timezone_for_policy } from '../../components/Detail/Utils';
import DocumentsSection from '../../components/Documents/Container';
import JourneysSection from '../../components/Journeys/Container';
import StatementsSection from '../../components/Statements/Container';
import UsagesSection from '../../components/Usages/Container';

// class PolicyDetail extends Component {
const PolicyDetailTemp = (props) => {
    const [section, setSection] = useState('detail');
    const [selected, setSelected] = useState();
    const [isFetching, setIsFetching] = useState(true);
    const [isDataFetching, setIsDataFetching] = useState(false);
    const [edited, setEdited] = useState(false);
    const [latest, setLatest] = useState({});
    const [codelistEnabled, setCodelistEnabled] = useState(null);
    const [page, setPage] = useState(1);
    const [data, setData] = useState();

    const prevSection = useRef();
    const prevPolicy = useRef();

    useEffect(() => {
        const { history } = props;
        const { location } = history;

        prevSection.current = section;

        const get_policy_data = async () => {
            if (section !== 'detail') await get_page(location.state);

            const id = getId(history);

            const company_reference = get(props.auth, 'data.company_reference');
            const codelistEnabled = get(clientConfig, `${company_reference}.codelist.enabled`);

            await props.policys_get({
                id,
                verbose: codelistEnabled ? true : '', // API is not actually checking for 'true' or 'false' - just whether value is truthy
            });

            // This grabs the latest possible version for MTA
            const response = await props.policys_get({
                id,
                verbose: codelistEnabled ? true : '',
                latest_version: true,
            });

            const latest = get(response, 'payload.data', {});

            setIsFetching(false);
            setLatest(latest);
            setCodelistEnabled(codelistEnabled);

            const { isFetching: policysFetching, selected: propsSelected = {} } = props.policys;
            prevPolicy.current = propsSelected;
            if (!props.policys_products.data) await props.policys_products_get_all({});
        };

        get_policy_data();

        prevPolicy.current = section;
    }, []);

    useEffect(() => {
        if (isFetching === false) {
            const updateSelectedFromProps = async () => {
                setSelected(props.policys.selected);
            };

            updateSelectedFromProps();
        }
    }, [isFetching]);

    useEffect(() => {
        const { location } = props.history;
        const { pathname, state } = location;
        const lang = getLangSource(props.lang);

        if (section !== state) props.history.replace(pathname, section);

        const handle_mta = async () => {
            if (prevSection.current === 'mta' && section !== 'mta' && edited) {
                if (!window.confirm(lang.t('policy.policies.unsavedChangesWarning'))) {
                    setSection('mta');
                } else {
                    // If user has navigated away and has made changes, then need to get latest again as it would have been overwritten
                    const { history } = props;
                    const id = getId(history);

                    const latest_res = await props.policys_get({
                        id,
                        verbose: codelistEnabled || true,
                        latest_version: true,
                    });

                    setEdited(false);
                    setLatest(latest_res.payload.data);
                }
            }
        };

        handle_mta();

        const { history } = props;
        const id = getId(history);

        const handle_cancellation = async () => {
            if (
                section === 'cancellation' &&
                prevPolicy.selected?.policy_reference !== props.policys.selected?.policy_reference
            ) {
                setIsFetching(true);

                // Call policy GET for current version
                await props.policys_get({
                    id,
                    verbose: codelistEnabled ? true : '',
                });

                // Call policy GET for latest version
                const response = await props.policys_get({
                    id,
                    verbose: true,
                    latest_version: true,
                });

                const latest_payload = get(response, 'payload.data', {});

                const { isFetching: isPolicyFetching, selected: selectedPolicy } = props.policys;

                setIsFetching(isPolicyFetching);
                setLatest(latest_payload);
            }
        };
        handle_cancellation();
    }, [section]);

    const update = (result) => {
        setLatest(result);
        setEdited(true);
    };

    const pagination_update = (data) => {
        setData(data);
    };

    const get_page = async (selected_section) => {
        const { history } = props;
        const id = getId(history);
        setIsDataFetching(false);

        if (selected_section === 'statements') {
            setPage(1);
            await props.policys_statements_get_all({
                policy_reference: id,
                limit: 51,
                offset: 0,
            });
        }

        if (selected_section === 'journeys') {
            setPage(1);
            await props.policys_journeys_get_all({
                policy_reference: id,
                limit: 51,
                offset: 0,
            });
        }

        if (selected_section === 'logins') {
            setIsDataFetching(true);

            // Get email from auth/policyholders
            await props.policys_logins_get_all({
                policy_reference: id,
            });

            const policyHolderEmail = get(props, 'policys_logins.data[0].email');

            // Get user reference from auth/users?email=[email]
            await props.settings_users_get_all({
                email: encodeURIComponent(policyHolderEmail),
            });

            const userReference = get(props, 'settings_users.data[0].user_reference');

            // Get cognito status from auth/users/:user_reference
            await props.settings_users_get({
                id: userReference,
            });

            setIsDataFetching(false);
        }

        if (selected_section === 'activity') {
            await props.policys_user_logs_get_all({
                policy_reference: id,
            });
        }

        if (selected_section === 'payments') {
            await props.policys_unpaid_versions_get_all({
                policy_reference: id,
            });

            await props.policys_credits_get_all({
                policy_reference: id,
            });
        }

        if (selected_section === 'credits') {
            await props.policys_credits_get_all({
                policy_reference: id,
            });
        }

        if (selected_section === 'usages') {
            await props.policys_usages_get_all({
                policy_reference: id,
            });
        }

        if (selected_section === 'documents') {
            await props.policys_kyc_get({
                id,
            });
        }

        if (selected_section === 'comms') {
            await props.policys_comms_get_all({
                policy_reference: id,
            });
        }

        if (selected_section === 'devices') {
            await props.telematics_devices_get_all({
                policy_reference: id,
            });
        }

        if (selected_section === 'claims') {
            await props.policys_claims_get_all({
                policy_reference: id,
            });
        }

        setSection(selected_section);
    };

    const version_type = get(selected, 'versions[0].version_type', 'nb');
    const disable_mta = version_type === 'cn' ? true : false;
    const { data: products = [] } = props.policys_products;
    // Checking only the currency of the first product. assume all products of a company uses same currency
    const currency = products.length !== 0 ? first(products).currency : 'GBP';

    const product_reference = get(selected, 'policy.product_reference', '');

    const timezone =
        products.length !== 0 && product_reference.length > 0 ? get_timezone_for_policy(products, selected) : '';

    const lang = getLangSource(props.lang);

    return (
        <div className="my-3 my-md-5">
            <Prompt when={edited} message={(location) => lang.t('policy.policies.unsavedChangesWarning')} />
            <div className="container">
                <div className="row">
                    {/* Loader */}
                    {isFetching && <SpinnerBody />}

                    {/* Empty State */}
                    {!isFetching && isEmpty(selected) && <Empty label="policies" lang={lang} />}

                    {/* Table */}
                    {!isFetching && !isEmpty(selected) && (
                        <div className="col-sm-12 col-lg-12">
                            <PolicyHeader policy_detail={selected} timezone={timezone} />
                            <div className="row">
                                <div className="col-lg-2">
                                    <PolicyNav active={section} page={get_page} lang={lang} />
                                </div>
                                <div className="col-lg-10">
                                    {isDataFetching ? (
                                        <SpinnerBody />
                                    ) : (
                                        <>
                                            {section === 'detail' && (
                                                <DetailSection
                                                    policy_detail={selected}
                                                    update={update}
                                                    timezone={timezone}
                                                    lang={lang}
                                                />
                                            )}

                                            {section === 'versions' && (
                                                <VersionsSection
                                                    versions={selected.versions || []}
                                                    cognito={props.cognito}
                                                    auth={props.auth}
                                                    policys_emails_post={props.policys_emails_post}
                                                    policys_statements_get={props.policys_statements_get}
                                                    payments={selected.payments}
                                                    currency={currency}
                                                    timezone={timezone}
                                                    lang={lang}
                                                />
                                            )}

                                            {section === 'statements' && (
                                                <StatementsSection
                                                    statements={get(props.policys_statements, 'data', [])}
                                                    currency={currency}
                                                    timezone={timezone}
                                                    page={page}
                                                    policy_reference={selected.policy_reference}
                                                    update={pagination_update}
                                                    policys_statements_get_all={props.policys_statements_get_all}
                                                    lang={lang}
                                                />
                                            )}

                                            {section === 'journeys' && (
                                                <JourneysSection
                                                    journeys={get(props.policys_journeys, 'data', [])}
                                                    page={page}
                                                    policy_reference={selected.policy_reference}
                                                    update={pagination_update}
                                                    policys_journeys_get_all={props.policys_journeys_get_all}
                                                    currency={currency}
                                                    timezone={timezone}
                                                    lang={lang}
                                                />
                                            )}

                                            {section === 'logins' && (
                                                <LoginsSection
                                                    logins={[get(props.settings_users, 'selected', [])]} // Forced into an array
                                                    lang={lang}
                                                />
                                            )}

                                            {section === 'activity' && (
                                                <ActivitySection
                                                    activity={get(props.policys_user_logs, 'data', [])}
                                                    lang={lang}
                                                />
                                            )}

                                            {section === 'log' && (
                                                <LogSection
                                                    policy_detail={selected}
                                                    policy_reference={selected.policy_reference}
                                                    versions={selected.versions}
                                                    policys_reporting_get_all={props.policys_reporting_get_all}
                                                    policys_reporting={props.policys_reporting}
                                                    lang={lang}
                                                />
                                            )}

                                            {section === 'mta' && (
                                                <MTASection
                                                    policy_detail={latest}
                                                    update={update}
                                                    vehicles_get={props.vehicles_get}
                                                    policys_put={props.policys_put}
                                                    pol_detail_readonly={selected}
                                                    disabled={disable_mta}
                                                    edited={edited}
                                                    auth_details={props.auth}
                                                    policys_products_get={props.policys_products_get}
                                                    update_page={get_page}
                                                    timezone={timezone}
                                                    lang={lang}
                                                />
                                            )}
                                            {section === 'renewal' && (
                                                <RenewalSection
                                                    policy_detail={latest}
                                                    update={update}
                                                    vehicles_get={props.vehicles_get}
                                                    policys_put={props.policys_put}
                                                    pol_detail_readonly={selected}
                                                    disabled={disable_mta}
                                                    edited={edited}
                                                    auth_details={props.auth}
                                                    policys_products_get={props.policys_products_get}
                                                    update_page={page}
                                                    timezone={timezone}
                                                    lang={lang}
                                                />
                                            )}
                                            {section === 'contact' && (
                                                <UpdateContactSection
                                                    policy_detail={latest}
                                                    settings_users_get_all={props.settings_users_get_all}
                                                    settings_users_put={props.settings_users_put}
                                                    lang={lang}
                                                />
                                            )}
                                            {section === 'cancellation' && (
                                                <CancellationSection
                                                    policy_detail={latest}
                                                    policy_reference={latest.policy_reference}
                                                    versions={latest.versions}
                                                    policys_delete={props.policys_delete}
                                                    history={props.history}
                                                    currency={currency}
                                                    timezone={timezone}
                                                    lang={lang}
                                                />
                                            )}

                                            {section === 'payments' && (
                                                <PaymentSection
                                                    versions={selected.versions}
                                                    payments={selected.payments}
                                                    policys_payments_post={props.policys_payments_post}
                                                    unpaid={get(props.policys_unpaid_versions, 'data', [])}
                                                    currency={currency}
                                                    timezone={timezone}
                                                    credits={get(props.policys_credits, 'data', [])}
                                                    lang={lang}
                                                />
                                            )}

                                            {section === 'credits' && (
                                                <CreditsSection
                                                    policy_detail={selected}
                                                    credits={get(props.policys_credits, 'data', [])}
                                                    policys_credits_post={props.policys_credits_post}
                                                    currency={currency}
                                                    lang={lang}
                                                />
                                            )}

                                            {section === 'comms' && (
                                                <CommsSection
                                                    comms={get(props.policys_comms, 'data', [])}
                                                    timezone={timezone}
                                                    currency={currency}
                                                    lang={lang}
                                                />
                                            )}

                                            {section === 'usages' && (
                                                <UsagesSection
                                                    policy_detail={selected}
                                                    usages={get(props.policys_usages, 'data', [])}
                                                    policys_usages_post={props.policys_usages_post}
                                                    policys_usages_put={props.policys_usages_put}
                                                    policys_usages_get_all={props.policys_usages_get_all}
                                                    currency={currency}
                                                    timezone={timezone}
                                                    lang={lang}
                                                />
                                            )}

                                            {section === 'documents' && (
                                                <DocumentsSection
                                                    files={get(props.policys_kyc, 'selected', [])}
                                                    policy_reference={selected.policy_reference}
                                                    policys_kyc_post={props.policys_kyc_post}
                                                    policys_kyc_delete={props.policys_kyc_delete}
                                                    policys_kyc_get={props.policys_kyc_get}
                                                    policys_kyc_get_all={props.policys_kyc_get_all}
                                                    timezone={timezone}
                                                    lang={lang}
                                                />
                                            )}

                                            {section === 'devices' && (
                                                <DevicesSection
                                                    policy_detail={selected}
                                                    devices={get(props.telematics_devices, 'data', [])}
                                                    lang={lang}
                                                />
                                            )}
                                            {section === 'claims' && (
                                                <ClaimsSection
                                                    claims={get(props.policys_claims, 'data', [])}
                                                    timezone={timezone}
                                                    page={page}
                                                    policy_reference={selected.policy_reference}
                                                    update={pagination_update}
                                                    policys_claims_get_all={props.policys_claims_get_all}
                                                    policys_claims_post={props.policys_claims_post}
                                                    lang={lang}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default main(PolicyDetailTemp);
