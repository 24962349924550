import React from 'react';

function buildLabel (label = '', property = '') {
    if (label !== '') return label;
    let str = property.split('_').join(' ');
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function SelectOnly (props) {
    const {
        options = [],
        property,
        value = '',
        update,
        disabled = false,
        width = 140,
        label,
        placeholder = '',
    } = props;

    return (
        <div className="input-group mb-2">
            <div className="input-group-prepend">
                <label className="input-group-text" htmlFor={property} style={{ width }}>
                    {buildLabel(label, property)}
                </label>
            </div>
            <select
                id={property}
                className={`form-control ${value ? 'is-valid' : ''}`}
                disabled={disabled}
                style={{ 
                    WebkitAppearance: `${value? 'none' : 'auto'}`, 
                    MozAppearance: `${value? 'none' : 'auto'}`, 
                    color: `${value? '' : 'gray'}` 
                }}
                onChange={(e) => {
                    update({
                        [property]: e.target.value
                    })
                }}
            >
                <option selected={value ? false : true } value="">{placeholder}</option>
                {options.map(o => {
                    return (
                        <option value={o.value}>{o.label}</option>
                    ); 
                })}
            </select>
        </div>
    );
}

export default SelectOnly;
