import React, { Component } from 'react';
import { startCase } from 'lodash';
import ModalAddFile from './ModalAddFile';
import Empty from '../../../../components/empty/Results';
import DocumentsTable from './DocumentsTable';

export default class ClaimDocuments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalAddVisible: false,
            modalDeleteVisible: false
        }
        this.toggleAddModal = this.toggleAddModal.bind(this);
    }
    toggleAddModal() {
        this.setState({
            modalAddVisible: !this.state.modalAddVisible
        })
    }

    render() {
        const {
            files,
            policys_claims_documents_delete,
            policys_claims_documents_post,
            policys_claims_documents_get,
            policys_claims_documents_get_all,
            policys_claims_documents_put,
            policys_claims_put,
            claim_reference,
            policy_reference,
            latestClaimVersion,
            refreshClaimData,
            timezone,
            lang,
        } = this.props;

        return (
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title'>{startCase(lang.t('policy.claims.claimDocuments'))}</h3>
                    <a className="btn btn-sm btn-primary text-white ml-auto" style={{
                        position: 'absolute',
                        top: '12px',
                        right: '16px'
                    }}
                    onClick={this.toggleAddModal}
                    >+ {startCase(lang.t('policy.claims.addFile'))}</a>
                </div>
                <div className='card-body'>
                    {files.length === 0 && <Empty label='files' lang={lang} />}
                    {files.length > 0 && 
                        <DocumentsTable
                            files={files}
                            timezone={timezone}
                            policys_claims_documents_delete={policys_claims_documents_delete}
                            claim_reference={claim_reference}
                            policys_claims_documents_get={policys_claims_documents_get}
                            policys_claims_documents_get_all={policys_claims_documents_get_all}
                            lang={lang}
                        />
                    }
                </div>
                <ModalAddFile
                    open={this.state.modalAddVisible}
                    toggleModal={this.toggleAddModal}
                    policys_claims_documents_post={policys_claims_documents_post}
                    policys_claims_documents_put={policys_claims_documents_put}
                    claim_reference={claim_reference}
                    latestClaimVersion={latestClaimVersion}
                    policys_claims_put={policys_claims_put}
                    policy_reference={policy_reference}
                    refreshClaimData={refreshClaimData}
                    lang={lang}
                />
            </div>
        )
    }
}
