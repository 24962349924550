import set_lang from './lang';

import auth from './auth';
import set_cognito from './cognito';
import set_environment from './environment';

//autocrat
import settings_users from '../services/autocrat/actions/users';
import settings_companys from '../services/autocrat/actions/companys';
import settings_superaccess from '../services/autocrat/actions/superaccess';

//payments
import payments_dashboard from '../services/payments/actions/dashboard';
import payments_identitys from '../services/payments/actions/identitys';
import payments_settings from '../services/payments/actions/settings';

//claims
import claims_dashboard from '../services/claims/actions/factors';
import claims_identitys from '../services/claims/actions/jobs';
import claims_settings from '../services/claims/actions/policys';
import claims_reports from '../services/claims/actions/reports';
import claims_risks from '../services/claims/actions/risks';
import claims_versions from '../services/claims/actions/versions';

//policys
import policys from '../services/policys/actions/policys';
import policys_details from '../services/policys/actions/policys_details';
import policys_journeys from '../services/policys/actions/policys_journeys';
import policys_statements from '../services/policys/actions/policys_statements';
import policys_logins from '../services/policys/actions/policys_logins';
import policys_analytics from '../services/policys/actions/policys_analytics';
import policys_user_logs from '../services/policys/actions/policys_user_logs';
import policys_reporting from '../services/policys/actions/policys_reporting';
import policys_products from '../services/policys/actions/policys_products';
import policys_payments from '../services/policys/actions/policys_payments';
import policys_unpaid_versions from '../services/policys/actions/policys_unpaid_versions';
import policys_emails from '../services/policys/actions/policys_emails';
import policys_documents from '../services/policys/actions/policys_documents';
import policys_health from '../services/policys/actions/policys_health';
import policys_credits from '../services/policys/actions/policys_credits';
import policys_usages from '../services/policys/actions/policys_usages';
import policys_kyc from '../services/policys/actions/policys_kyc';
import policys_comms from '../services/policys/actions/policys_comms';
import policys_claims from '../services/policys/actions/policys_claims';
import policys_claims_documents from '../services/policys/actions/policys_claims_documents';

//pricing
import pricing_models from '../services/rating/actions/models';
import pricing_quotes from '../services/rating/actions/quotes';
import pricing_versions from '../services/rating/actions/versions';
import pricing_publish from '../services/rating/actions/publish';
import pricing_analytics from '../services/rating/actions/analytics';
import pricing_factors from '../services/rating/actions/factors';
import pricing_compare from '../services/rating/actions/compare';
import pricing_upload from '../services/rating/actions/upload';
import pricing_ml_upload from '../services/rating/actions/upload-ml';
import pricing_errors from '../services/rating/actions/errors';
import pricing_template from '../services/rating/actions/template';
import pricing_billing from '../services/rating/actions/billing';

//telematics
import telematics_devices from '../services/policys/actions/telematics_devices';
import telematics_packets from '../services/policys/actions/telematics_packets';
import telematics_journeys from '../services/policys/actions/telematics_journeys';

//vehicles
import vehicles from '../services/policys/actions/vehicles';
import policy_payments from '../services/policys/actions/policys_payments';

export default {
    set_lang,
    set_cognito,
    set_environment,
    ...auth.exportMethods(),
    auth_reset: () => ({
        type: 'RESET_AUTH',
    }),
    ...payments_dashboard.exportMethods(),
    ...payments_identitys.exportMethods(),
    ...payments_settings.exportMethods(),

    ...claims_dashboard.exportMethods(),
    ...claims_identitys.exportMethods(),
    ...claims_settings.exportMethods(),
    ...claims_reports.exportMethods(),
    ...claims_risks.exportMethods(),
    ...claims_versions.exportMethods(),
    
    ...policys.exportMethods(),
    ...policys_details.exportMethods(),
    ...policys_journeys.exportMethods(),
    ...policys_statements.exportMethods(),
    ...policys_logins.exportMethods(),
    ...policys_analytics.exportMethods(),
    ...policys_user_logs.exportMethods(),
    ...policys_reporting.exportMethods(),
    ...policys_products.exportMethods(),
    ...policys_payments.exportMethods(),
    ...policys_unpaid_versions.exportMethods(),
    ...policys_emails.exportMethods(),
    ...policys_documents.exportMethods(),
    ...policys_health.exportMethods(),
    ...policys_credits.exportMethods(),
    ...policys_usages.exportMethods(),
    ...policys_kyc.exportMethods(),
    ...policys_comms.exportMethods(),
    ...policys_claims.exportMethods(),
    ...policys_claims_documents.exportMethods(),

    ...pricing_models.exportMethods(),
    ...pricing_quotes.exportMethods(),
    ...pricing_versions.exportMethods(),
    ...pricing_publish.exportMethods(),
    ...pricing_analytics.exportMethods(),
    ...pricing_factors.exportMethods(),
    ...pricing_upload.exportMethods(),
    ...pricing_ml_upload.exportMethods(),
    ...pricing_errors.exportMethods(),
    ...pricing_template.exportMethods(),
    ...pricing_billing.exportMethods(),
    ...pricing_compare.exportMethods(),

    ...telematics_devices.exportMethods(),
    ...telematics_packets.exportMethods(),
    ...telematics_journeys.exportMethods(),

    ...settings_users.exportMethods(),
    ...settings_companys.exportMethods(),
    ...settings_superaccess.exportMethods(),

    ...vehicles.exportMethods(),
};
