import React, { Component } from 'react';

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            term: '',
        }
        this.searchOnClick = this.searchOnClick.bind(this);
        this.searchOnReturn = this.searchOnReturn.bind(this);
    }

    searchOnReturn(e) {
        const returnKeyId = 13
        if (e.which === returnKeyId) {
            this.props.searchFunction(e.target.value, 'returnSearch')
        }
    }

    searchOnClick() {
        const value = this.refs.search_term.value;
        this.props.searchFunction(value)
    }

    render() {
        const { searchTerm, isFetching = false, label, lang } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                <div className="input-icon">
                    <input
                        type="text"
                        onKeyPress={(e) => this.searchOnReturn(e)}
                        className="form-control form-control-rounded"
                        placeholder={`${lang.t('components.search.search')} ${label}...`}
                        defaultValue={searchTerm}
                        ref={'search_term'}
                    />
                    <span className="input-icon-addon">
                    </span>
                </div> 
                <i 
                    className="fe fe-search" 
                    onClick={this.searchOnClick} 
                    style={{
                        top: 10,
                        right: 10,
                        cursor: 'pointer',
                        position: 'absolute',
                        color: isFetching ? '#206bc4' : 'grey',
                    }}
                />
            </div>
        );
    }
}

export default Search;
