import React, { Component } from 'react';
import { getLangSource } from '../../../../../lang';
import { saveAs } from 'file-saver';
import DropZone from '../../DropZoneCSV'; 
import PolicysTable from './PolicysTable';
import { Parser } from 'json2csv';

import policy_data from './policy_data.json';

class Factors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: policy_data,
        }
        this.updatePolicyCSV = this.updatePolicyCSV.bind(this);
        this.downloadPolicys = this.downloadPolicys.bind(this);
    }

    downloadPolicys() {
        const { data } = this.state;

        const parser = new Parser({fields:[
            'policy_ref',
            'quote_ref',
            'bought_at',
            'click_through',
            'cancel_at',
            'claims_count',
            'claims_amount',
        ]});
        const mapped_csv = parser.parse(data)
        const file_name = `policys_quotes.csv`;
        
        // Create a blob of the data
        const content = new Blob([mapped_csv], {
            type: 'text/csv',
            name: file_name
        });
        
        // Save the file
        saveAs(content, file_name);
    }

    updatePolicyCSV(data = []) {
        // This is temp. The quote_ref should be on the CSV
        const quotes = this.props.quotes.map(q => q.quote_reference);
        const new_data = data.map((d, i) => {
            return {
                ...d,
                quote_ref: quotes[i],
            }
        });
        this.setState({
            data: new_data,
        })
    }

    render() {
        const { data = [], lang: language } = this.state;
        const lang = getLangSource(language);
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Policies</h4>
                            <button href="#" style={{
                                    position: 'absolute',
                                    top: 14,
                                    right: 20,
                                }}
                            onClick={this.downloadPolicys}
                            class="btn btn-primary btn-sm"
                            >Download Policies CSV</button>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        Make relations between your policies and quotes. This is necessary for building reports.
                                    </div>
                                    <PolicysTable 
                                        data={data}
                                        lang={lang}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        Upload a policys .csv to update.
                                    </div>
                                    <DropZone 
                                        title='Drop .csv here'
                                        process={(data) => this.updatePolicyCSV(data)}
                                        height={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Factors;