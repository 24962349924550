import React, { Component } from 'react';
import moment from 'moment';
import { omit } from 'lodash';
import Input from './Input';
import Disabled from './Disabled';
import Select from './Select';
import { get_codelist, parse_date } from '../Utils';

class FormBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updated_info: {}
        }
        this.update = this.update.bind(this);
        this.handleAutoRenew = this.handleAutoRenew.bind(this);
    }

    update(item) {
        const { detail } = this.props;
        const updated_info = {
            ...this.state.updated_info,
            ...item
        }
        const result = {
            ...detail,
            policy: {
                ...detail.policy,
                ...updated_info,
            }
        };
        this.setState({ updated_info });
        this.props.update(result);
    }

    handleAutoRenew() {
        const { detail } = this.props;

        if(!this.props.disabled) {
            this.props.update({
                ...detail,
                policy: {
                    ...detail.policy,
                    auto_renew: !detail.policy.auto_renew
                }
            })
        } 
    }

    render() {
        const { detail, disabled = false, timezone, lang } = this.props;
        const policy = omit(detail.policy, 'address');
        const start_date_cleaned = parse_date(policy.start_date, 'DD/MM/YYYY HH:mm', timezone);
        const end_date_cleaned = parse_date(policy.end_date, 'DD/MM/YYYY HH:mm', timezone);
        const effective_date_cleaned = parse_date(policy.effective_date, 'DD/MM/YYYY HH:mm', timezone);

        return [
            <div className="card ml-2">
                <div className="card-header">
                    <h3 className="card-title">{lang.t('policy.policies.base')}</h3>
                </div>
                <div className="card-body">
                    <div className="mb-1">
                        <Input
                            label='Billing day date *'
                            property={'billing_day_date'}
                            value={policy.billing_day_date}
                            update={this.update}
                            type = 'number'
                            disabled={disabled}
                            min={1}
                            max={28}
                        />
                        <Input
                            label='Voluntary excess *'
                            property={'voluntary_excess'}
                            value={policy.voluntary_excess}
                            update={this.update}
                            type='number'
                            disabled={disabled}
                        />
                        <Disabled 
                            property={'compulsory_excess'}
                            value={policy.compulsory_excess}
                        />
                        <Disabled 
                            property={'start_date'}
                            value={start_date_cleaned}
                        />
                        <Disabled 
                            property={'end_date'}
                            value={end_date_cleaned}
                        />
                        <Disabled 
                            property={'effective_date'}
                            value={effective_date_cleaned}
                        />
                        <Disabled 
                            property={'underwriter'}
                            value={policy.underwriter_reference}
                        />
                        <Disabled 
                            property={'underwriter_ref'}
                            value={policy.underwriter_policy_reference}
                        />
                        <Disabled 
                            property={'cover'}
                            value={policy.cover}
                        />
                        <Select
                            label='Usage *'
                            property={'usage'}
                            value={policy.usage}
                            options={get_codelist('policy.usage')}
                            update={this.update}
                            disabled={disabled}
                        />
                        <Disabled 
                            property={'product_name'}
                            value={policy.product_reference}
                        />
                        <div className="input-group mb-2">
                            <label className="form-check form-switch">
                                <span style={{ width: 140, fontSize: '15px' }} className="form-check-label"><b>{lang.t('policy.policies.autoRenew')}</b></span>
                                <input 
                                    className="form-check-input" 
                                    checked={policy.auto_renew} 
                                    type="checkbox" 
                                    onChange={this.handleAutoRenew} 
                                    disabled={disabled} />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        
        ];
    }
}

export default FormBase;