import React, { Component } from 'react';
import Input from './Input';
import Disabled from './Disabled';
import moment from 'moment';
import { round, find, indexOf } from 'lodash';
import FormDriver from './FormDriver';

const upsert = (arr, key, newval) => {
    var match = find(arr, key);
    if(match){
        var index = indexOf(arr, find(arr, key));
        arr.splice(index, 1, newval);
    } else {
        arr.push(newval);
    }
};

class FormProposer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: {}
        }
        this.update = this.update.bind(this);
    }

    update(item) {
        const { detail } = this.props;

        this.props.update({
            ...detail,
            proposer: {
                ...detail.proposer,
                ...item,
            }
        });
        
        this.setState({ item })
    }

    render() {
        const { detail, disabled = false, modal_open, set_driver_index, is_mta, update_page, lang } = this.props;
        const { proposer } = detail;
        return [
            <div>
                <div className="card-body">
                    <FormDriver 
                        driver={proposer}
                        disabled={disabled}
                        update={this.update}
                        modal_open={modal_open}
                        driver_index='proposer'
                        set_driver_index={set_driver_index}
                        update_page={update_page}
                        is_mta={is_mta}
                        lang={lang}
                    />
                </div>
            </div>
        
        ];
    }
}

export default FormProposer;