import React, { Component } from 'react';
import { find, max } from 'lodash';
import { updateCollection } from '../../../../../utils/manipulate';
import endorsementCheck from '../../../utils/exclusions';
import enrichQuote from '../../../utils/enrichQuote';

class ModalEndorsement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            endorsement: {},
            quote: {},
            valid_quote: true,
            showExploded: false,
        };
        this.update = this.update.bind(this);
        this.updateQuote = this.updateQuote.bind(this);
        this.save = this.save.bind(this);
        this.clear = this.clear.bind(this);
    }

    componentWillMount() {
        const { name, endorsements } = this.props;
        let endorsement = find(endorsements, e => e.name === name) || {};
        this.setState({
            endorsement,
            quote: this.props.example,
        });
    }

    componentWillReceiveProps(props) {
        const { name, endorsements } = props;
        let endorsement = find(endorsements, e => e.name === name) || {};
        this.setState({
            endorsement,
            quote: this.props.example,
        });
    }

    update(endorsement) {
        this.setState({
            endorsement,
        })
    }

    updateQuote(quote) {
        try {
            JSON.parse(quote)
        } catch(e) {
            return this.setState({
                valid_quote: false,
            });
        }
        return this.setState({
            valid_quote: true,
            quote: JSON.parse(quote),
        })
    }

    clear() {
        this.setState({
            endorsement: {},
        })
    }

    save() {
        const newEndorsement = this.state.endorsement;
        const newEndorsements = updateCollection(this.props.endorsements, newEndorsement);
        this.props.update(newEndorsements);

        this.props.saveVersion({
            version_reference: this.props.version_reference,
            reload: false,
        });
        this.clear();  
    }

    render() {
        const { open, lang } = this.props;
        let { quote, valid_quote, endorsement = {
            name: '',
            expression: '',
        }, showExploded } = this.state;

        const check = endorsementCheck(endorsement.expression, quote)
        return (
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-endorsement" tabindex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{lang.t('rating.components.endorsements.modalTitle')}</h5>
                            <button type="button" onClick={this.clear} className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        {lang.t('rating.components.endorsements.modalInfo')} <a href="">{lang.t('rating.components.endorsements.here')} </a>.
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" style={{ width: 140 }}>
                                                                {lang.t('rating.components.endorsements.name')} 
                                                            </span>
                                                        </div>
                                                        <input onBlur={(e) => {
                                                            this.update({
                                                                ...endorsement,
                                                                name: e.target.value,
                                                            })
                                                        }} key={endorsement.name} 
                                                        type='text' 
                                                        className="form-control" 
                                                        placeholder={lang.t('rating.components.endorsements.namePlaceholder')} 
                                                        defaultValue={endorsement.name} />
                                                    </div>
                                                    <div className="input-group mb-2">
                                                        <textarea
                                                            key={endorsement.description}
                                                            className="form-control"
                                                            data-toggle="autosize"
                                                            placeholder={lang.t('rating.components.endorsements.descriptionPlaceholder')} 
                                                            style={{
                                                                background: '#354052',
                                                                borderRadius: '3px',
                                                                color: '#fff',
                                                            }}
                                                            onBlur={(e) => {
                                                                this.update({
                                                                    ...endorsement,
                                                                    description: e.target.value,
                                                                })
                                                            }}
                                                            defaultValue={endorsement.description}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" style={{ width: 140 }}>
                                                        {lang.t('rating.components.endorsements.value')} 
                                                    </span>
                                                </div>
                                                <input onBlur={(e) => {
                                                    this.update({
                                                        ...endorsement,
                                                        value: e.target.value,
                                                    })
                                                }} key={endorsement.value} 
                                                type='text' 
                                                className="form-control" 
                                                placeholder={lang.t('rating.components.endorsements.valuePlaceholder')} 
                                                defaultValue={endorsement.value} />
                                            </div>
                                            <div className="input-group mb-2">
                                                <textarea
                                                    key={endorsement.expression}
                                                    className="form-control"
                                                    data-toggle="autosize"
                                                    placeholder={lang.t('rating.components.endorsements.expressionPlaceholder')}
                                                    style={{
                                                        background: '#354052',
                                                        borderRadius: '3px',
                                                        color: '#fff',
                                                    }}
                                                    onBlur={(e) => {
                                                        this.update({
                                                            ...endorsement,
                                                            expression: e.target.value,
                                                        })
                                                    }}
                                                    defaultValue={endorsement.expression}
                                                />
                                            </div>
                                        </div>
                                        <table className="table card-table table-vcenter">
                                            <thead>
                                                <tr>
                                                    <th>{lang.t('buttons.test')}</th>
                                                    <th>{lang.t('buttons.result')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="td-truncate">
                                                        {check.is_valid &&
                                                        <div>
                                                            {lang.t('rating.components.endorsements.validExpression')} <i style={{ fontSize: 11 }}>({check.expression})</i>
                                                        </div>
                                                        }
                                                        {!check.is_valid &&
                                                        <div style={{color: 'pink'}}>
                                                            {check.message}
                                                        </div>
                                                        }
                                                    </td>
                                                    <td className="w-1 text-center">
                                                        {check.is_valid &&
                                                            <span className="avatar bg-green">
                                                                <i style={{color: 'white'}} className="fe fe-check"></i>
                                                            </span>
                                                        }
                                                        {!check.is_valid &&
                                                            <span className="avatar bg-red">
                                                                <i style={{color: 'white'}} className="fe fe-x"></i>
                                                            </span>
                                                        }
                                                    </td>
                                                </tr>
                                                {check.is_valid && check.queries.map(q => {
                                                    return (
                                                        <tr>
                                                            <td className="td-truncate">
                                                                <div>
                                                                    {q.query}
                                                                </div>
                                                            </td>
                                                            <td className="w-1 text-center">
                                                                <span className="badge bg-green">
                                                                    {q.value.toString()}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-6" style={{ position: 'relative' }}>
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        {lang.t('rating.components.endorsements.editInfo')}
                                    </div>
                                    <div style={{ top : 80, right: 50, position: 'absolute' }}>
                                        {check.is_valid && !check.exclude && valid_quote &&
                                            <span className="badge bg-green">
                                                {lang.t('rating.components.endorsements.quoteIncluded')}
                                            </span>
                                        }

                                        {check.is_valid && check.exclude && valid_quote &&
                                            <span className="badge bg-red">
                                                {lang.t('rating.components.endorsements.quoteExcluded')}
                                            </span>
                                        }

                                        {!valid_quote &&
                                            <span className="badge bg-gray">
                                                {lang.t('rating.components.endorsements.jsonInvalid')}
                                            </span>
                                        }
                                    </div>
                                    {!showExploded &&
                                        <textarea 
                                            className="form-control"
                                            data-toggle="autosize"
                                            style={{
                                                overflow: 'auto',
                                                background: valid_quote ? '#354052' : '#f1f1f1',
                                                borderRadius: '3px',
                                                fontSize: '14px',
                                                color: valid_quote ? '#eaeaea' : '#000',
                                                height: '400px',
                                                width: '100%',
                                            }} 
                                            onBlur={(e) => {
                                                this.updateQuote(e.target.value);
                                            }}
                                            defaultValue={JSON.stringify(quote, null, 4)}
                                        />
                                    }
                                    {showExploded &&
                                        <textarea 
                                            className="form-control"
                                            data-toggle="autosize"
                                            style={{
                                                overflow: 'auto',
                                                background: '#f1f1f1',
                                                borderRadius: '3px',
                                                fontSize: '14px',
                                                color: '#000',
                                                height: '400px',
                                                width: '100%',
                                            }} 
                                            disabled={true}
                                            defaultValue={JSON.stringify(enrichQuote(quote), null, 4)}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-white mr-3" onClick={() => {
                                this.setState({
                                    showExploded: !showExploded,
                                })
                            }}>{`${showExploded ? lang.t('buttons.hide') : lang.t('buttons.show')} ${lang.t('rating.components.endorsements.enrichedQuote')}`}</button>
                            {check.is_valid &&
                                <button type="button" className="btn btn-primary" onClick={this.save} data-dismiss="modal">{lang.t('buttons.saveChanges')}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalEndorsement;