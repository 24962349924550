import React, { Component } from 'react';
import CloseButton from '../../../../components/buttons/CloseButton';

class DownloadModal extends Component{
    constructor (props) {
        super(props);
        this.state = {
            format: 'csv',
            limit: null,
            offset: null,
        }
    }

    updateState(event) {
        this.setState({ [event.target.id]: event.target.value })
    };

    render() {
        const { 
            toggleDownloadModal,
            downloadFile,
            disabled,
            lang,
        } = this.props;
        return (
            <div className={`modal modal-blur fade show`} style={{ display: 'block' }} tabIndex="-1" role="dialog" aria-modal="true">
                <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header theme-dark'>
                            <h5 className='modal-title'>{lang.t('policy.reports.download')}</h5>
                            <CloseButton onClick={toggleDownloadModal}/>  
                        </div>
                        <div className='modal-body'>
                            <div className='input-group-prepend'>
                                <span className='imput-group-text' style={{ width: '140px' }}>{lang.t('policy.reports.fileType')}</span>
                            </div>

                            <select name="fileType" id="format" onChange={(e) => this.updateState(e)} style={{marginBottom: '10px'}}>
                                <option value="csv">CSV</option>
                                <option value="json">JSON</option>
                            </select>

                            <div className='input-group-prepend'>
                                <span className='imput-group-text' style={{ width: '140px' }}>{lang.t('policy.reports.maxRecords')}:</span>
                            </div>
                            <input
                                type='number'
                                className='form-control'
                                id='limit'
                                onChange={(e) => this.updateState(e)}
                                style={{width: '150px'}}
                                placeholder='0'
                            />
                            <div className='input-group-prepend'>
                                <span className='imput-group-text' style={{ width: '140px' }}>{lang.t('policy.reports.startRecords')}:</span>
                            </div>
                            <input
                                type='number'
                                className='form-control'
                                id='offset'
                                onChange={(e) => this.updateState(e)}
                                style={{ width: '150px' }}
                                placeholder='0'
                            />
                        </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => {downloadFile(this.state)}}>{lang.t('buttons.download')}</button>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DownloadModal;