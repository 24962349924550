import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { get_codelist, validate_policy_object } from '../Utils';
import Input from '../Forms/Input';
import Select from '../Forms/Select';
import DatePicker from '../Forms/DatePicker';
import TableClaims from '../Tables/TableClaims';
import TableConvictions from '../Tables/TableConvictions';

class ModalAddDriver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            driver: {},
            can_submit: false,
        }

        this.update = this.update.bind(this);
        this.add = this.add.bind(this);
        this.close = this.close.bind(this);
        this.open_claims = this.open_claims.bind(this);
        this.open_convictions = this.open_convictions.bind(this);
    }

    componentDidUpdate(prevProps) {

        const { claims = [], convictions = [] } = this.state.driver;

        if (!isEmpty(this.props.claim) && prevProps.claim !== this.props.claim) {
            this.setState({
                driver: {
                    ...this.state.driver,
                    claims: [...claims, this.props.claim]
                }
            })
        }

        if (!isEmpty(this.props.conviction) && prevProps.conviction !== this.props.conviction) {
            this.setState({
                driver: {
                    ...this.state.driver,
                    convictions: [...convictions, this.props.conviction]
                }
            });
        }
    }

    update(result) {
        this.setState({
            driver: {
                ...this.state.driver,
                ...result,
            }
        }, () => {
            const { driver } = this.state;

            const isValid = validate_policy_object({
                data: driver,
                type: 'add_driver',
            });
            
            if (isValid) {
                    this.setState({ can_submit: true }) 
            } else {
                this.setState({ can_submit: false })
            }
        });
    }

    add() {
        const result = {
            ...this.props.detail,
            additional_drivers: [...this.props.detail.additional_drivers, this.state.driver]
        }
        this.props.update(result);
        this.setState({
            driver: {},
            can_submit: false,
        });
        this.props.modal_open({
            add_driver: {
                open: false,
            }
        })
    }

    close() {
        this.setState({
            driver: {},
            can_submit: false,
        });

        this.props.modal_open({
            add_driver: {
                open: false,
            }
        });
    }

    open_claims() {
        this.props.modal_open({
            add_claim: {
                open: true,
                
            }
        })
    }

    open_convictions() {
        this.props.modal_open({
            add_conviction: {
                open: true,
            }
        })
    }

    render() {
        const { open, lang } = this.props;
        const { driver, can_submit } = this.state;
        const { claims = [], convictions = [] } = driver;
        return (
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '', overflowY: 'auto' }} id="modal-add-driver" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header theme-dark" >
                            <h5 className="modal-title">{lang.t('policy.policies.addAdditionalDriver')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5 className='text-red text-right'>* {lang.t('policy.policies.requiredFields')}</h5>
                            <div className="row">
                                <div className="col-lg-5">
                                    <h4>{lang.t('policy.policies.driverDetails')}</h4>
                                    <Select
                                        label='Title *'
                                        property={'title'}
                                        options={get_codelist('proposer.title')}
                                        value={driver.title}
                                        update={this.update}
                                    />
                                    <Input
                                        label='First names *'
                                        property={'first_names'}
                                        value={driver.first_names}
                                        update={this.update}
                                        type = 'text'
                                    />
                                    <Input
                                        label='Last names *'
                                        property={'last_names'}
                                        value={driver.last_names}
                                        update={this.update}
                                        type = 'text'
                                    />
                                    <Input 
                                        property={'email'}
                                        value={driver.email}
                                        update={this.update}
                                        type = 'text'
                                    />
                                    <Input 
                                        property={'phone_number'}
                                        value={driver.phone_number}
                                        update={this.update}
                                        type = 'text'
                                    />
                                    <Select 
                                        label='Relationship *'
                                        property={'relationship'}
                                        options={get_codelist('additional_drivers.map(relationship)')}
                                        value={driver.relationship}
                                        update={this.update}
                                    />
                                    <Input
                                        label='Children *'
                                        property={'children'}
                                        value={driver.children}
                                        update={this.update}
                                        type = 'number'
                                        min={0}
                                    />
                                    <Input
                                        label='NCD *'
                                        property={'ncd'}
                                        value={driver.ncd}
                                        update={this.update}
                                        type = 'number'
                                        min={0}
                                    />
                                    <DatePicker 
                                        label={'Date of birth *'}
                                        property={'dob'}
                                        value={driver.dob}
                                        update={this.update}
                                    />
                                    <Select
                                        label='Licence type *'
                                        property={'licence_type'}
                                        options={get_codelist('proposer.licence_type')}
                                        value={driver.licence_type}
                                        update={this.update}
                                    />
                                    <Select
                                        label='Employment *'
                                        property={'employment'}
                                        options={get_codelist('proposer.employment')}
                                        value={driver.employment}
                                        update={this.update}
                                    />
                                    <Select 
                                        label={'Occupation'}
                                        property={'occupation'}
                                        options={get_codelist('proposer.occupation')}
                                        value={driver.occupation}
                                        update={this.update}
                                    />
                                    <Select 
                                        label='Business'
                                        property={'business'}
                                        options={get_codelist('proposer.business')}
                                        value={driver.business}
                                        update={this.update}
                                    />
                                    <DatePicker 
                                        label={'Licence issue *'}
                                        property={'licence_issued_date'}
                                        value={driver.licence_issued_date}
                                        update={this.update}
                                    />
                                    <DatePicker 
                                        label={'Resident since'}
                                        property={'residency_date'}
                                        value={driver.residency_date}
                                        update={this.update}
                                    />
                                    <Select 
                                        label='Criminal convs *'
                                        property='has_criminal_conviction'
                                        options={[{ label: 'true' }, { label: 'false' }]}
                                        value={driver.has_criminal_conviction}
                                        update={this.update}
                                    />
                                    <Select 
                                        label='Med. conditions *'
                                        property='has_medical_conditions'
                                        options={[{ label: 'true' }, { label: 'false' }]}
                                        value={driver.has_medical_conditions}
                                        update={this.update}
                                    />
                                    <Select 
                                        label='DVLA informed *'
                                        property='has_informed_dvla_medical_conditions'
                                        options={[{ label: 'true' }, { label: 'false' }]}
                                        value={driver.has_informed_dvla_medical_conditions}
                                        update={this.update}
                                    />
                                </div>
                                <div className="col-lg-7">
                                    <div style={{ marginBottom: '15px' }}>
                                        <b>{lang.t('policy.policies.driverClaims')}</b>
                                        { !isEmpty(driver) && 
                                            <a className="btn btn-primary btn-sm float-right text-white"
                                            onClick={this.open_claims}
                                            >+ {lang.t('policy.policies.addClaim')}</a> 
                                            }
                                    </div>
                                    <TableClaims 
                                        data={claims}
                                        update={this.update}
                                        lang={lang}
                                    />
                                    <div style={{ marginBottom: '15px' }}>
                                        <b>{lang.t('policy.policies.driverConvictions')}</b>
                                        { !isEmpty(driver) && 
                                            <a className="btn btn-primary btn-sm float-right text-white"
                                                onClick={this.open_convictions}
                                            >+ {lang.t('policy.policies.addConviction')}</a>
                                            }
                                    </div>
                                    <TableConvictions 
                                        data={convictions}
                                        update={this.update}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={this.add}
                                disabled={!can_submit}
                            >
                                {lang.t('policy.policies.addDriver')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalAddDriver;