import React from 'react';

function Dropdown (props) {
    const {
        label,
        property,
        value = '',
        update,
        items,
        validation,
        width = 140,
    } = props;
    const is_valid = validation ? validation(value) : '';
    
    return (
        <div className="input-group mb-2">
            <div className="input-group-prepend">
                <span className="input-group-text" style={{ width }}>{label}</span>
            </div>
            <select 
                onChange={(e) => update({
                    [property]: e.target.value
                }, e)} 
                className={`form-select custom-select ${is_valid ? 'is-valid' : ''}`}>
                {items.map(item => {
                    return (<option selected={value === item.value} value={item.value}>{item.label}</option>)
                })}
            </select>
        </div>
    )
}

export default Dropdown;
