import React, { Component } from 'react';
import { random, min, max, round, chunk, flatten } from 'lodash';

import 'ol/ol.css';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { LineString, Point, Polygon } from 'ol/geom';
import { toContext } from 'ol/render';
import { decode } from '@mapbox/polyline';

class SparkRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            random_id: 'ref-' + random(0, 9999),
        }
    }

    componentDidMount() {
        const { route } = this.props;
        const { random_id } = this.state;
        const canvas = this.refs[random_id];
        const vectorContext = toContext(canvas.getContext('2d'), { size: [28, 28] })
        const latlong = decode(route);
        const stroke = new Stroke({color: '#206bc4', width: 2});
        const style = new Style({
            stroke: stroke,
        });

        const chunked = chunk(latlong, 20);
        let sliced;

        if (chunked.length <= 2) {
            sliced = flatten(chunked);
        } else if (chunked.length <= 5) {
            sliced = flatten(chunked.map(chunk => chunk.filter((c, i) => i % 3 === 0)));
        } else if (chunked.length <= 10) {
            sliced = flatten(chunked.map(chunk => chunk.filter((c, i) => i % 5 === 0)));
        } else if (chunked.length <= 20) {
            sliced = flatten(chunked.map(chunk => chunk.filter((c, i) => i % 10 === 0)));
        } else {
            sliced = flatten(chunked.map(chunk => chunk.filter((c, i) => i % 20 === 0)));
        }

        const maxLat = max(sliced.map(l => l[0]));
        const maxLong = max(sliced.map(l => l[1]));
        const minLat = min(sliced.map(l => l[0]));
        const minLong = min(sliced.map(l => l[1]));

        const normalised = sliced.map(l => {
            const scaleLat = round(1/(maxLat - minLat),0) * 20;
            const scaleLong = round(1/(maxLong - minLong),0) * 20;
            let newLat = l[0] - minLat;
            let newLong = l[1] - minLong;
            return [
                round(newLat * scaleLat, 0) + 2,
                round(newLong * scaleLong, 0) + 2,
            ];
        });

        vectorContext.setStyle(style);
        vectorContext.drawGeometry(
            new LineString(normalised)
        );

        this.setState({
            vectorContext,
        });
    }

    render() {
        const { random_id } = this.state;
        return (
            <div style={{ border: '1px solid #eaeaea', width: 30, height: 30, padding: 2, transform: 'scaleY(-1)'  }}>
                <canvas ref={random_id} id={random_id}></canvas>
            </div>
        );
    }
}

export default SparkRoute;