import React, { Component } from 'react';
import DropZoneJSON from '../DropZoneJSON';
import { validate } from '../../utils/model';
import { isEmpty } from 'lodash';

class ModalImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {},
            is_valid_json: null,
            is_valid_model: null,
            model_errors: [],
        };
        this.process = this.process.bind(this);
        this.clear = this.clear.bind(this);
    }

    process({ result, is_valid_json }) {

        this.clear();

        if (!is_valid_json) {
            return this.setState({
                is_valid_json: false,
            });
        }
        const { is_valid, errors } = validate(result);

        if (!is_valid) {
            return this.setState({
                is_valid_model: false,
                is_valid_json,
                model_errors: errors,
            });
        }

        return this.setState({
            is_valid_model: true,
            is_valid_json,
            model: result,
        });
    }

    clear() {
        this.setState({
            model: {},
            is_valid_json: null,
            is_valid_model: null,
            model_errors: [],
        });
    }

    render() {
        const { open, updateModel, lang } = this.props;
        const { model, is_valid_json, is_valid_model, model_errors } = this.state;

        return (
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-import" tabindex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{lang.t('rating.modelDetail.modal.importModel')}</h5>
                            <button type="button" onClick={this.clear} className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <DropZoneJSON
                                                        process={this.process}
                                                        title={lang.t('rating.modelDetail.modal.dropJSONModel')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6">

                                {is_valid_model !== null &&
                                    <div className="card">
                                        <table className="table card-table table-vcenter mb-3">
                                            <thead>
                                                <tr>
                                                    <th>{lang.t('buttons.test')}</th>
                                                    <th>{lang.t('buttons.result')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="text-truncate">
                                                        {lang.t('rating.modelDetail.modal.validJSON')}
                                                        </div>
                                                    </td>
                                                    <td className="w-1 text-center">
                                                        {is_valid_json &&
                                                            <span className="avatar bg-green">
                                                                <i style={{ color: 'white' }} className="fe fe-check"></i>
                                                            </span>
                                                        }
                                                        {!is_valid_json &&
                                                            <span className="avatar bg-red">
                                                                <i style={{ color: 'white' }} className="fe fe-x"></i>
                                                            </span>
                                                        }
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="text-truncate">
                                                            {lang.t('rating.modelDetail.modal.validModel')}
                                                        </div>
                                                    </td>
                                                    {is_valid_model &&
                                                        <td className="w-1 text-center">
                                                            <span className="avatar bg-green">
                                                                <i style={{ color: 'white' }} className="fe fe-check"></i>
                                                            </span>
                                                        </td>
                                                    }
                                                    {!is_valid_model &&
                                                        <td className="w-1 text-center">
                                                            <span className="avatar bg-red">
                                                                <i style={{ color: 'white' }} className="fe fe-x"></i>
                                                            </span>
                                                        </td>
                                                    }
                                                </tr>

                                                {model_errors.map(me => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <div className="text-truncate">
                                                                    {JSON.stringify(me)}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span className="avatar bg-red">
                                                                    <i style={{ color: 'white' }} className="fe fe-x"></i>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {is_valid_model && 
                                
                                    <div className="card">
                                        <table className="table card-table table-vcenter mb-3">
                                            <thead>
                                                <tr>
                                                    <th>{lang.t('rating.modelDetail.modal.modelDetails')}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="text-truncate">
                                                            {lang.t('rating.modelDetail.modal.modelName')}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="badge bg-green">
                                                            {model.meta.name}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="text-truncate">
                                                            {lang.t('rating.modelDetail.modal.modelVersion')}
                                                        </div>
                                                    </td>
                                                    <td className="w-1 text-center">
                                                        <div className="badge bg-green">
                                                            {model.meta.version}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>   

                                        <table className="table card-table table-vcenter mb-3">
                                            <thead>
                                                <tr>
                                                    <th>{lang.t('rating.modelDetail.modal.factors')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {Object.keys(model.factors).map(f => {
                                                            return (
                                                                <span className="badge bg-green mr-2">
                                                                    {f}
                                                                </span>
                                                            )
                                                        })}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table className="table card-table table-vcenter mb-3">
                                            <thead>
                                                <tr>
                                                    <th>{lang.t('rating.modelDetail.modal.exclusions')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {model.exclusions.map(e => {
                                                            return (
                                                                <span className="badge bg-green mr-2">
                                                                    {e.name}
                                                                </span>
                                                            )
                                                        })}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table className="table card-table table-vcenter mb-3">
                                            <thead>
                                                <tr>
                                                    <th>{lang.t('rating.modelDetail.modal.formulas')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>         
                                                <tr>
                                                    <td>
                                                        {model.formulas.map(f => {
                                                            return (
                                                                <span className="badge bg-green mr-2">
                                                                    {f.name}
                                                                </span>
                                                            )
                                                        })}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {is_valid_model &&
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    updateModel(model);
                                    this.clear();
                                }} data-dismiss="modal">{lang.t('rating.modelDetail.modal.saveModel')}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalImport;