import React, { Component } from 'react';
import { parse_date } from '../Detail/Utils';

export default class TableClaimVersion extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            claimVersions: data = [],
            timezone,
            lang,
        } = this.props;

        return (
            <div className='card'>
                <div className='table-responsive'>
                    <table className='table table-vcenter table-mobile-md card-table'>
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.reference')}</th>
                                <th>{lang.t('tableHeaders.createdAt')}</th>
                                <th>{lang.t('tableHeaders.status')}</th>
                                <th>{lang.t('tableHeaders.update')}</th>
                                <th>{lang.t('tableHeaders.policyholderFault')}</th>
                                <th>{lang.t('tableHeaders.estimatedAmount')}</th>
                                <th>{lang.t('tableHeaders.incurredAmount')}</th>
                                <th>{lang.t('tableHeaders.settledDate')}</th>
                                <th>{lang.t('tableHeaders.settledAmount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((version, i) => {
                                    return (
                                        <tr key={`versions-${i}`}>
                                            <td>
                                                <div className='text-muted text-h5'>
                                                    {version.claim_version_reference}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-muted text-h5'>
                                                    {parse_date(version.created_at, 'DD/MM/YYYY', timezone)}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-muted text-h5'>
                                                    {version.status}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-muted text-h5'>
                                                    {version.update}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-muted text-h5'>
                                                    {version.is_policyholder_at_fault.toString()}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-muted text-h5'>
                                                    {version.estimated_amount}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-muted text-h5'>
                                                    {version.incurred_amount}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-muted text-h5'>
                                                {parse_date(version.settled_date, 'DD/MM/YYYY', timezone) === 'Invalid date' ? "n/a" : parse_date(version.settled_date, 'DD/MM/YYYY', timezone)}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-muted text-h5'>
                                                    {version.settled_amount}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
