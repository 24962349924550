import { get } from 'lodash';
import axios from 'axios';

/**
 * A signed URL is used for temporary access to ta S3 bucket.
 * We use this to manage large JSON models.
 */

const default_headers = {
    'x-amz-acl': 'public-read',
    'Content-Type': 'application/json'
}

export async function getS3SignedURL({
    signed_url,
},
progress = () => null
) {
    // This allows fetch=ing of 10MB+ uploads
    return axios({
        url: signed_url,
        method: 'get',
        onDownloadProgress: e => progress(e),
    }).then(({data}) => data)
}

export async function putS3SignedURL({
    signed_url,
    data,
    headers,
    }, 
    progress = () => null //takes a function to track progress - default is no function
    ) {
    const headers_use = !headers ? default_headers : headers; //Use default headers if no headers provided
    const data_use = !headers ? JSON.stringify(data) : data; //convert data to JSON if no headers provided - ie this will be a model file
    // This allows 10MB+ uploads
    return axios({
        url: signed_url,
        method: 'put',
        data: data_use,
        headers: headers_use,
        onUploadProgress: e => progress(e), 
    }).then(({data}) => data)
}
