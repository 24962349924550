import React, { Component } from 'react';
import SuperTable from '../../../../../../components/tables/Super';
import { round } from 'lodash';

class ModalFactorTable extends Component {
    constructor(props) {
        super(props);
    }

    badge({ is_valid }) {
        if (is_valid) return (<div className='badge bg-red'>IS VALID</div>)
        return (
            <div className='badge bg-green'>VALID</div>
        )
    }

    render() {
        const { data = [], update, lang } = this.props;

        const headers = [
            {
                label: lang.t('tableHeaders.values'),
                name: 'values',
                search: true,
            },
            {
                label: lang.t('tableHeaders.exclude'),
                name: 'exclude',
            },
            {
                label: lang.t('tableHeaders.refer'),
                name: 'refer',
            },
            {
                label: lang.t('tableHeaders.excess'),
                name: 'excess',
            },
            {
                label: lang.t('tableHeaders.endorsement'),
                name: 'endorsement',
            },
            {
                label: lang.t('tableHeaders.weight'),
                name: 'weight',
            },
        ];

        const Cells = (index, d) => {
            return (
                <tr key={`factors-${index}`}>
                    <td data-label={lang.t('tableHeaders.values')}>
                        <div className="text-muted text-h5">{d.values}</div>
                    </td>
                    <td data-label={lang.t('tableHeaders.include')}>
                        <div key={`factors-exclude-${index}`} onClick={() => {
                            update({
                                ...d,
                                exclude: !d.exclude,
                            });
                        }} style={{ cursor: 'pointer'}} className={`badge ${d.exclude ? 'bg-red' : 'bg-green'}`}>
                            {d.exclude ? lang.t('rating.modelDetail.modal.exclude') : lang.t('rating.modelDetail.modal.include')}
                        </div>       
                    </td>
                    <td data-label={lang.t('tableHeaders.refer')}>
                        <div key={`factors-refer-${index}`} onClick={() => {
                            update({
                                ...d,
                                refer: !d.refer,
                            });
                        }} style={{ cursor: 'pointer'}} className={`badge ${d.refer ? 'bg-red' : 'bg-green'}`}>
                            {d.refer ? lang.t('rating.modelDetail.modal.refer') : lang.t('rating.modelDetail.modal.notRefer')}
                        </div>
                    </td>
                    <td data-label={lang.t('tableHeaders.excess')} className="editable">
                        <div key={`factors-excess-${index}`} className="text-muted text-h5" onBlur={(e) => {
                            update({
                                ...d,
                                excess: round(e.target.innerText, 2),
                            });
                        }} suppressContentEditableWarning={true} contentEditable="true">{d.excess}</div>
                    </td>
                    <td data-label={lang.t('tableHeaders.endorsement')} className="editable">
                        <div key={`factors-endorsement-${index}`} className="text-muted text-h5" onBlur={(e) => {
                            update({
                                ...d,
                                endorsement: e.target.innerText,
                            });
                        }} suppressContentEditableWarning={true} contentEditable="true">{d.endorsement}</div>
                    </td>
                    <td data-label={lang.t('tableHeaders.weight')}  className="editable">
                        <div key={`factors-weight-${index}`} className="text-muted text-h5" onBlur={(e) => {
                            update({
                                ...d,
                                weight: round(e.target.innerText, 15),
                            });
                        }} suppressContentEditableWarning={true} contentEditable="true">{d.weight}</div>
                    </td>
                </tr>
            );
        }

        return (
            <SuperTable 
                data={data}
                headers={headers}
                Cells={Cells}
                lang={lang}
            />
        );
    }
}

export default ModalFactorTable;
