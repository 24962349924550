import { 
    findIndex, 
    get, 
    find, 
    round, 
    isEqual,
    omit,
} from 'lodash';
import moment from 'moment-timezone';
import code_list  from './codelists.json';

export const upsert = (arr, index = null, newval) => {
    if (index !== -1 || !index) {
        arr.splice(index, 1, newval)
    } else {
        arr.push(newval);
    }
}

export const driver_type = {
    proposer: 'Proposer',
    0: 'Driver 1',
    1: 'Driver 2',
    2: 'Driver 3',
    3: 'Driver 4',
}

export const get_codelist = (key) => {
    const index = findIndex(code_list, o => {
        return o.keys.includes(key);
    });

    if (index === -1) return [];

    return code_list[index].items;
}

export const format_values = (value, type, map_list) => {

    if (type === 'number') return parseFloat(value);
    if (type === 'bool') {
        if (value == 'true') return true;
        else if (value == 'false') return false;
        return value;
    }
    if (type === 'mapped') return get(find(map_list, { value }), 'label', value); //Converting code value to verbose label value - returns original value if can't be mapped
    if (type === 'code') return get(find(map_list, { label: value }), 'value', value); //converting verbose label value to code value - returns original value if can't be mapped

    return value; //defaults to returning back existing value if can't find format type
}

export const format_policy = (policy, enableCodelist) => {
    const { 
        start_date, 
        end_date, 
        inception_date, 
        voluntary_excess, 
        cover, 
        usage,
        auto_renew,
        billing_day_date
    } = policy;

    return {
        ...policy,
        start_date: enableCodelist ? moment(start_date).unix() : start_date,
        end_date: enableCodelist ? moment(end_date).unix() : end_date,
        inception_date: enableCodelist ? moment(inception_date).unix() : inception_date,
        voluntary_excess: format_values(voluntary_excess, 'number'),
        auto_renew: format_values(auto_renew, 'bool'),
        billing_day_date: format_values(billing_day_date, 'number'),
    }
}

const format_claims = (claims = [], enableCodelist) => {
    const new_claims = claims.map(claim => {
        return {
            ...claim,
            code: enableCodelist ? format_values(claim.code, 'code', get_codelist('proposer.claims.map(code)')) : claim.code,
            at_fault: format_values(claim.at_fault, 'bool'),
            ncd_lost: format_values(claim.ncd_lost, 'bool')
        }
    });

    return new_claims;
}

const format_convictions = (convictions = [], enableCodelist) => {
    const new_convs = convictions.map(conv => {
        return {
            ...conv,
            code: enableCodelist ? format_values(conv.code, 'code', get_codelist('proposer.convictions.map(code)')) : conv.code,
            points: format_values(conv.points, 'number'),
            ban: format_values(conv.ban, 'number'),
        }
    });
    
    return new_convs;
}

const format_driver = (driver, enableCodelist) => {
    const {
        business,
        claims,
        convictions,
        employment,
        licence_type,
        occupation,
        title,
        ncd,
        children,
        has_criminal_conviction,
        has_informed_dvla_medical_conditions,
        has_medical_conditions } = driver;

    return {
        ...driver,
        business: enableCodelist ? format_values(business, 'code', get_codelist('proposer.business')) : business,
        claims: format_claims(claims, enableCodelist),
        convictions: format_convictions(convictions, enableCodelist),
        employment: enableCodelist ? format_values(employment, 'code', get_codelist('proposer.employment')) : employment,
        licence_type: enableCodelist ? format_values(licence_type, 'code', get_codelist('proposer.licence_type')) : licence_type,
        occupation: enableCodelist ? format_values(occupation, 'code', get_codelist('proposer.occupation')) : occupation,
        title: enableCodelist ? format_values(title, 'code', get_codelist('proposer.title')) : title,
        ncd: format_values(ncd, 'number'),
        children: format_values(children, 'number'),
        has_criminal_conviction: format_values(has_criminal_conviction, 'bool'),
        has_informed_dvla_medical_conditions: format_values(has_informed_dvla_medical_conditions, 'bool'),
        has_medical_conditions: format_values(has_medical_conditions, 'bool')
    }
}

export const format_proposer = (proposer, enableCodelist) => {
    const formatted = format_driver(proposer, enableCodelist);

    return {
        ...formatted,
        own_home: format_values(proposer.own_home, 'bool'),
    }
}

export const format_add_drivers = (add_drivers = [], enableCodelist) => {
    return add_drivers.map(drv => {
        const formatted = format_driver(drv, enableCodelist);

        return {
            ...formatted,
            relationship: enableCodelist ? format_values(drv.relationship, 'code', get_codelist('additional_drivers.map(relationship)')) : drv.relationship,
        }
    });
}

const format_mods = (mods = [], enableCodelist) => {
    if (!enableCodelist) return mods;

    return mods.map(mod => format_values(mod, 'code', get_codelist('vehicle.modifications')));
}

export const format_vehicle = (vehicle, enableCodelist) => {
    const {
        body_type,
        type,
        current_value,
        engine,
        estimated_yearly_mileage,
        fuel,
        transmission,
        keeper,
        modifications,
        owner,
        is_rhd,
        is_import,
        seats,
        doors,
        parked_location,
    } = vehicle;

    return {
        ...vehicle,
        body_type: enableCodelist ? format_values(body_type, 'code', get_codelist('vehicle.body_type')) : body_type,
        type: enableCodelist ? format_values(type, 'code', get_codelist('vehicle.body_type')) : type,
        current_value: format_values(current_value, 'number'),
        engine: format_values(engine, 'number'),
        estimated_yearly_mileage: format_values(estimated_yearly_mileage, 'number'),
        fuel: enableCodelist ? format_values(fuel, 'code', get_codelist('vehicle.fuel')) : fuel,
        keeper: enableCodelist ? format_values(keeper, 'code', get_codelist('vehicle.keeper')) : keeper,
        owner: enableCodelist ? format_values(owner, 'code', get_codelist('vehicle.owner')) : owner,
        modifications: format_mods(modifications, enableCodelist),
        is_rhd: format_values(is_rhd, 'bool'),
        is_import: format_values(is_import, 'bool'),
        seats: format_values(seats, 'number'),
        doors: format_values(doors, 'number'),
        parked_location: enableCodelist ? format_values(parked_location, 'code', get_codelist('vehicle.parked_location')) : parked_location,
    }
}

export const format_pricing = pricing => {
    const {
        upfront_rate,
        usage_rate,
        subscription_rate,
        subscription_installments,
        admin_fee,
        mta_fee,
        cancel_fee,
        upfront_premium,
    } = pricing;

    return {
        ...pricing,
        upfront_rate: round(format_values(upfront_rate, 'number'), 2),
        usage_rate: round(format_values(usage_rate, 'number'), 3),
        subscription_rate: round(format_values(subscription_rate, 'number'), 2),
        subscription_installments: format_values(subscription_installments, 'number'),
        admin_fee: round(format_values(admin_fee, 'number'), 2),
        mta_fee: round(format_values(mta_fee, 'number'), 2),
        cancel_fee: round(format_values(cancel_fee, 'number'), 2),
        upfront_premium: round(format_values(upfront_premium, 'number'), 2),
    }
}

export const calculate_price_change = (before, after) => {
    // The cleaned dates are all in ISO string
    const start_date_cleaned = parse_date(after.start_date, '');
    const end_date_cleaned = parse_date(after.end_date, '');
    const effective_date_cleaned = parse_date(after.effective_date, '');
    const start_date_cleaned_unix = moment(start_date_cleaned).unix();
    const end_date_cleaned_unix = moment(end_date_cleaned).unix();
    const effective_date_cleaned_unix = moment(effective_date_cleaned).unix();
    const seconds_in_a_day = 60 * 60 * 24;
    const days_left = round((end_date_cleaned_unix - effective_date_cleaned_unix) / seconds_in_a_day, 0); // start time is from 00:00:00 and also ends on 00:00:00
    const full_policy_days = round((end_date_cleaned_unix - start_date_cleaned_unix) / seconds_in_a_day, 0);
    const ratio = days_left / full_policy_days;
    const upfront_rate_chg = after.upfront_rate - before.upfront_rate;
    const calc_amount = round(upfront_rate_chg * ratio, 2);
    return {
        upfront_rate_before: before.upfront_rate,
        usage_rate_before: before.usage_rate,
        subscription_rate_before: before.subscription_rate,
        upfront_rate_after: after.upfront_rate,
        usage_rate_after: after.usage_rate,
        subscription_rate_after: after.subscription_rate,
        upfront_rate_chg: round(upfront_rate_chg, 2),
        usage_rate_chg: round(after.usage_rate - before.usage_rate, 3),
        subscription_rate_chg: round(after.subscription_rate - before.subscription_rate, 2),
        calc_amount,
        days_left,
        full_policy_days,
        mta_fee: round(after.mta_fee, 2),
    }
}

export const validate_policy_object = ({
    data,
    product_reference,
    type,
}) => {

    // List of fields, split out by section, that must be completed
    const driver_checklist = [
        'title', 'first_names', 'last_names', 'dob', 'licence_type', 'licence_issued_date', 'employment', 'has_criminal_conviction', 
        'has_medical_conditions','has_informed_dvla_medical_conditions', 'ncd', 'children'
    ];
    const add_driver_checklist = [...driver_checklist, 'relationship'];
    const claim_checklist = ['code', 'date', 'at_fault', 'ncd_lost'];
    const conviction_checklist = ['code', 'date', 'points', 'ban'];
    const base_checklist = ['billing_day_date', 'voluntary_excess', 'usage'];
    const address_checklist = ['line_1', 'postcode', 'city', 'country'];

     // For differences in the required fields for Tim/ ByBits/ Miles Schema

    const tim_vehicle_checklist = [
        'body_type', 'current_value', 'engine', 'estimated_yearly_mileage', 'fuel', 'manufacture_date',
        'purchase_date', 'reg', 'make', 'model', 'owner', 'keeper', 'seats', 'doors'
    ];
    const bybm_vehicle_checklist = [
        'abi_code', 'body_type', 'current_value', 'engine', 'estimated_yearly_mileage', 'fuel', 'manufacture_date',
        'purchase_date', 'reg', 'make', 'model', 'owner', 'keeper', 'seats', 'doors'
    ];
    const vehicle_checklist = product_reference === 'tim_usage' ? tim_vehicle_checklist : bybm_vehicle_checklist ;

    // Used for modal validation - keeping the logic here rather than in the component

    if (type === 'claim') {
        if (claim_checklist.every(item => data[item])) return true;

        return false;
    }

    if (type === 'conviction') {
        if (conviction_checklist.every(item => data[item])) return true;

        return false;
    }

    if (type === 'add_driver') {
        if (add_driver_checklist.every(item => data[item])) return true;

        return false;
    }

    /*  Otherwise full policy object */

    const { 
        proposer = {}, 
        policy = {}, 
        vehicle = {}, 
        additional_drivers = [] 
    } = data;

    // Goes through checklist to identify if everything has been populated - converted to string to avoid any falsy answers (eg 0, false) which are valid
    const base_check = base_checklist.every(item => get(policy, item, "").toString()) ? true : false;
    const address_check = address_checklist.every(item => get(policy, `address.${item}`, "").toString()) ? true : false;
    const proposer_check = driver_checklist.every(item => get(proposer, item, "").toString()) ? true : false;
    const vehicle_check = vehicle_checklist.every(item => get(vehicle, item, "").toString()) ? true : false;

    // Also need to check whether body type key is present. If not then type must be present and have a value (ie we will use the type value in body type)

    const validate_add_drivers = arr => {
        if (arr.length === 0) return [];

        // O(n^2) time complexity but only a max of 3 - 4 drivers 
        let checks = additional_drivers.map((driver, index) => {
            if (add_driver_checklist.every(item => get(driver, item, "").toString())) {
                return {
                    [`add_driver_${index + 1}`]: true,
                }
            }

            return {
                [`add_driver_${index + 1}`]: false,
            }
        });

        return checks;
    }

    const add_driver_check = validate_add_drivers(additional_drivers);

    // Create output object then add in additional driver items
    let output = {
        base_check,
        address_check,
        proposer_check,
        vehicle_check,
    }

    if (add_driver_check.length > 0) {
        add_driver_check.forEach(chk => output = { ...output, ...chk });
    }
    
    // Get keys from output object - will be used to cycle through to check each section is valid
    const keys = Object.keys(output);

    if (keys.every(item => output[item])) {
        // All sections are valid
        return {
            isValid: true,
            valErrors: null,
        }
    } else {
        // Filter out the invalid sections for reporting out
        const valErrors = keys.filter(item => !output[item])
        return {
            isValid: false,
            valErrors,

        }
    }

}

export const parse_date = (date, format, timezone) => {
    if (typeof(date) === 'number') {

        const moment_date = moment.unix(date);
        return moment.tz(moment_date, timezone).format(format);

    }
    return moment.tz(date, timezone).format(format);
}

export const has_email_phone_changed = ({
    original,
    changes,
}) => {
    const email_original = get(original, 'email').toLowerCase();
    const phone_original = get(original, 'phone_number');
    const email_changes = get(changes, 'email').toLowerCase();
    const phone_changes = get(changes, 'phone_number');

    // Both have changed
    if (email_original !== email_changes && phone_original !== phone_changes) {
        return {
            email: email_changes,
            phone_number: phone_changes,
        }
    }

    // Only email has changed
    if (email_original !== email_changes && phone_original === phone_changes) {
        return {
            email: email_changes,
        }
    }

    // Only phone number has changed
    if (email_original === email_changes && phone_original !== phone_changes) {
        return {
            phone_number: phone_changes,
        }
    }

    return {}
}

// The obj parameter going into this function is the returned object from the 'has_email_phone_changed' function above

export const email_phone_update_msg = (obj, lang) => {
    const keys = Object.keys(obj) || [];
    const mapping = {
        email: 'Email',
        phone_number: 'Phone number',
    }

    if (keys.length === 2) {
        return lang.t('policy.policies.emailPhoneUpdated');
    } else {
        return `${lang.t('policy.policies.the')} ${mapping[keys[0]]} ${lang.t('policy.policies.hasBeenUpdated')}`;
    }
}

export const get_timezone_for_policy = (products, policy_detail) => {
    
    const { policy : { product_reference } } = policy_detail;

    const product = products.find(p => p.product_reference === product_reference);

    const { timezone } = product;

    return timezone;
}