import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '../../../../components/tables/Pagination';
import { round } from 'lodash';
import { extendedDateFormat } from '../../../../utils/format';

function toUpper(str = '') {
    return str?.toUpperCase();
}

class QuotesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        }
        this.selectPage = this.selectPage.bind(this);
    }

    badge({ exclusion_count}) {
        if (exclusion_count > 0) return (<div className='badge bg-red'>INVALID</div>)
        return (
            <div className='badge bg-green'>VALID</div>
        )
    }

    selectPage(page) {
        this.setState({
            page,
        });
    }

    render() {
        const { data = [], pages, show_button = true, action, lang } = this.props;
        const { page } = this.state;
        const no_of_pages = (data.length === 0 ? 0 : Math.ceil(data.length / pages));
        let pageData = data;
        if (pages && pages !== 0) pageData = data.slice((page * pages) - pages, (page * pages));
        return (
            <div className="card">
                {pages &&
                    <Pagination
                        page={page}
                        pages={pages}
                        no_of_pages={no_of_pages}
                        data={data}
                        selectPage={this.selectPage}
                        lang={lang}
                    />
                }
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.reference')}</th>
                                <th>{lang.t('tableHeaders.reg')}</th>
                                <th>{lang.t('tableHeaders.postcode')}</th>
                                <th>{lang.t('tableHeaders.yearly')}</th>
                                <th>{lang.t('tableHeaders.upfront')}</th>
                                <th>{lang.t('tableHeaders.sub')}</th>
                                <th>{lang.t('tableHeaders.pm')}</th>
                                <th>{lang.t('tableHeaders.source')}</th>
                                <th />
                                <th>{lang.t('tableHeaders.date')}</th>
                                {show_button && <th />}
                            </tr>
                        </thead>
                        <tbody>
                            {pageData.map((d, i) => (
                                <tr key={`identitys-${i}`}>
                                    <td data-label="Name">
                                        <div className="d-flex lh-sm py-1 align-items-center">
                                            <div className="flex-fill">
                                                <div className="text-muted text-h5">{toUpper(d.quote_reference)}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-label="Reg">
                                        <div className="text-muted text-h5">{d.reg_anon}</div>
                                    </td>
                                    <td data-label="Postcode">
                                        <div className="text-muted text-h5">{d.postcode_anon}</div>
                                    </td>
                                    <td data-label="Price">
                                        <div className="text-muted text-h5">{d.yearly_premium_estimate ? '£' + d.yearly_premium_estimate : '-'}</div>
                                    </td>
                                    <td data-label="Price">
                                        <div className="text-muted text-h5">{d.upfront_premium ? '£' + d.upfront_premium : '-'}</div>
                                    </td>
                                    <td data-label="Price">
                                        <div className="text-muted text-h5">{d.subscription_premium ? '£' + d.subscription_premium : '-'}</div>
                                    </td>
                                    <td data-label="Price">
                                        <div className="text-muted text-h5">{d.usage_rate ? round(d.usage_rate * 100, 1) + 'p' : '-'}</div>
                                    </td>
                                    <td data-label="Source">
                                        <div className="text-muted text-h5">{d.source}</div>
                                    </td>
                                    <td>
                                        <div className="text-muted text-h5">{this.badge(d)}</div>
                                    </td>
                                    <td data-label="Date">
                                        <div className="text-muted text-h5">{extendedDateFormat(d.created_at)}</div>
                                    </td>
                                    {show_button &&
                                        <td>
                                            <div className="btn-list flex-nowrap">
                                                <Link
                                                    to={`/rating-logs/quotes/${d.quote_reference}`}
                                                    className="btn btn-sm btn-primary"
                                                >{lang.t('rating.logs.view')}</Link>
                                            </div>
                                        </td>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {pages &&
                    <Pagination
                        page={page}
                        pages={pages}
                        no_of_pages={no_of_pages}
                        data={data}
                        selectPage={this.selectPage}
                        lang={lang}
                    />
                }
            </div>
        );
    }
}

export default QuotesTable;
