import React, { Component } from 'react';

function capitilise(str = '') {
    return str.charAt(0).toUpperCase() + str.substr(1, str.length);
}

function buildItems(report = {}) {
    const cats = Object.keys(report) || [];
    const lists = [];
    cats.forEach(cat => {
        const obj = {
            name: cat,
            items: [],
        }
        if(cat !== 'all') {  
            const pivots = Object.keys(report[cat]);
            pivots.forEach(p => {
                obj.items.push({
                    name: capitilise(p),
                    path: `${cat}.${p}`,
                });
            });
            lists.push(obj);
        } else {
            lists.push({
                name: 'All',
                items: [{
                    name: 'All Policies',
                    path: cat
                }]
            })
        }
    });
    return lists;
}

class DropDownPivot extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { report, selectPivot } = this.props;
        const categorys = buildItems(report);
        return (
            <select class="form-select" onChange={(e) => {
                e.preventDefault();
                selectPivot(e.target.value);
            }}>
                {categorys.map(category => {
                    return (
                        <optgroup label={category.name}>
                            {category.items.map(i => {
                                return (<option value={i.path}>{i.name}</option>);
                            })}
                        </optgroup> 
                    )
                })}
            </select>
        );
    }
}

export default DropDownPivot;