import React, { Component } from 'react';
import lang from '../../../../actions/lang';
import BasicDropDown from '../../../../components/dropdown/Basic';

class ProductsFilterDropDown extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], isFetching } = this.props.products;
        const lang = this.props.lang
        
        return (
            <div style={{ width: 300, marginBottom: 20}}>
                <BasicDropDown
                    items={[
                        {
                            value: '',
                            label: lang.t('policy.policies.all')
                        },
                        ...data.map(d => {
                            return {
                                value: d.product_reference,
                                label: d.product_reference,
                            }
                        })
                    ]}
                    name={'product_reference'}
                    label={lang.t('policy.policies.filter')}
                    select={this.props.update}
                />
            </div>
        );
    }
}

export default ProductsFilterDropDown;
