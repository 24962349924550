import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import axiosMiddleware from 'redux-axios-middleware';
import axios from 'axios';
import rootReducer from './reducers';
import { get } from 'lodash';
import { getLocalAutocrat } from './auth/autocrat';
 
const client = axios.create({
    // responseType: 'json',
});

const authentication = (store) => (next) => async (action) => {
    const data = store.getState();

    //This will always get the latest access_token from refresh_token if needed.
    const { access_token: token } = await getLocalAutocrat();

    const { environment } = data;
    const { client_id, client_secret, client_secret_sandbox } = get(data, 'auth.data', {});

    function selectClientSecret() {
        if (environment === 'production') {
            return (client_secret ? client_secret : '');
        }
        return (client_secret_sandbox ? client_secret_sandbox : '');
    }

    // Add all authorization to requests
    if (action && action.payload) {
        return next({
            ...action,
            payload: {
                ...action.payload,
                request: {
                    ...action.payload.request,
                    headers: {
                        ...action.payload.request.headers,
                        environment,
                        client_id: client_id ? client_id : '',
                        client_secret: selectClientSecret(),
                        authorization: token ? `Bearer ${token}` : '',
                    }
                }
            }
        })
    };

    return next(action);
};

const middleware = applyMiddleware(
    authentication,
    axiosMiddleware(client, { returnRejectedPromiseOnError: true }),
    thunkMiddleware,
);

const initStore = (initialState = {}) => createStore(rootReducer, initialState, middleware);

export default initStore;