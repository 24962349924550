import React, { Component } from 'react'
import { parse_date } from '../Detail/Utils';
import ModalDeleteFile from './ModalDeleteFile';
import { downloadFile } from './S3Helpers';

export default class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalDeleteVisible: false,
            fileToDelete: {}
        };
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    }
    toggleDeleteModal(file) {
        this.setState({
            fileToDelete: file,
            modalDeleteVisible: !this.state.modalDeleteVisible
        });
    }

    render() {
        const { files, timezone, policy_reference, policys_kyc_get_all, lang } = this.props;
        const CATEGORIES = {
            'odometer': 'Odometer',
            'ncd': 'No claims discount',
            'address': 'Address',
            'licence': 'Licence',
            'id': 'Identification',
            'medical': 'Medical'
        };

        return (
            <div className='card'>
                <div className='table-responsive'>
                    <table className='table table-vcenter table-mobile-md card-table'>
                        <thead>
                            <tr>
                                <th>{lang.t('policy.policies.fileName')}</th>
                                <th>{lang.t('policy.policies.documentType')}</th>
                                <th>{lang.t('policy.policies.uploadDate')}</th>
                                <th></th>
                                <th colSpan={2} />
                            </tr>
                        </thead>
                        <tbody>
                            {files.map((file, i) => {
                                return (
                                    <tr key={`documents-${i}`}>
                                        <td data-label='File name'>
                                            <div className='text-h5'>
                                                {file.file_name}
                                            </div>
                                        </td>
                                        <td data-label='Category'>
                                            <div className='text-h5'>
                                                {CATEGORIES[file.category]}
                                            </div>
                                        </td>
                                        <td data-label='Upload date'>
                                            <div className='text-h5'>
                                                {parse_date(file.upload_date, 'DD/MM/YYYY', timezone)}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='mr-6'> </div>
                                        </td>
                                        <td className="w-1">
                                            <div className="btn-list flex-nowrap">
                                                <button className='btn btn-sm btn-primary text-white'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    downloadFile(file, policy_reference, policys_kyc_get_all);
                                                }}>
                                                    {lang.t('buttons.download')}
                                                </button>
                                            </div>
                                        </td>
                                        <td className="w-1">
                                            <div className="btn-list flex-nowrap">
                                                <button className='btn btn-sm btn-red text-white' 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.toggleDeleteModal(file);
                                                }}>
                                                    {lang.t('buttons.delete')}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            )}
                        </tbody>
                    </table>
                </div>
                <ModalDeleteFile
                    open={this.state.modalDeleteVisible}
                    toggleModal={this.toggleDeleteModal}
                    fileToDelete={this.state.fileToDelete}
                    policy_reference = {this.props.policy_reference}
                    policys_kyc_delete={this.props.policys_kyc_delete}
                    policys_kyc_get={this.props.policys_kyc_get}
                    lang={lang}
                />
            </div>
        )
    }
}
