import React, { Component } from 'react';
import { getLangSource } from '../../../lang';
import { saveAs } from 'file-saver';
import { getId } from '../../../utils/url';
import { isEmpty, get, first } from 'lodash';
import main from '../../../hoc/main';
import SpinnerBody from '../../../components/loaders/SpinnerBody';
import Table from '../components/Index/QuotesTable';
import DeleteQuoteModal from '../components/Quotes/DeleteQuoteModal';
import RawDataModal from '../components/Quotes/RawDataModal';
import RatingsNav from '../components/Quotes/RatingsNav'
import QuoteSection from '../components/Sections/QuoteSection'
import PricingSection from '../components/Sections/PricingSection';

class QuoteDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openRawData: false,
            section: 'quote',
        }
        this.export = this.export.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.rawDataToggle = this.rawDataToggle.bind(this);
        this.deleteQuote = this.deleteQuote.bind(this);
        this.page = this.page.bind(this);
    }

    async componentWillMount() {
        const { history } = this.props;
        const id = getId(history);
        await this.props.pricing_quotes_get({ 
            id,
            debug:true
         });
        await this.props.policys_products_get_all({});
    }

    export() {
        const { selected:quote = {} } = this.props.pricing_quotes;
        const { meta } = quote;
        const file = `${meta.quote_reference}.json`;
        const content = new Blob([JSON.stringify(quote, null, 4)], {
            type: 'application/json',
            name: file
        });
        saveAs(content, file);
    }

    async deleteQuote(quote_ref) {
        try {
            await this.props.pricing_quotes_delete({ id: quote_ref });
            this.setState({
                open: true,
            });
            this.props.history.push('/rating-logs');
        } catch(e) {
            console.log(e);
        }
    }
        
    modalToggle() {
        this.setState({
            open: !this.state.open,
        });
    }

    rawDataToggle() {
        this.setState({
            openRawData: !this.state.openRawData,
        });
    }

    page(section){
        this.setState({
            section,
        });
    }

    render() {
        const { 
            pricing_quotes: {
                selected = {},
                isFetching = true,
            },
            lang: language,
        } = this.props;
        const { data: products = [] } = this.props.policys_products;
        const quote_reference = get(selected, 'meta.quote_reference', '');
        const { open, openRawData, section } = this.state;
        // Only checks the timezone of the first product, assumes all products of a company use same timezone
        const timezone = products.length !== 0 ? first(products).timezone : 'Europe/London';

        const lang = getLangSource(language);

        return [
            <div className="my-3 my-md-5">
                <div className="container">
                    {isFetching && 
                        <div className="row">
                            <SpinnerBody 
                                message={lang.t('rating.pricingQuoteDetail.retrieving')}
                                selectedProduct={this.props.selectedProduct}
                            />
                        </div>
                    }
                    {!isFetching && !isEmpty(selected) &&
                        <div>
                            <div className="row mb-3">
                                <div className="col-sm-12 col-lg-6">
                                    <div className="col-auto">
                                        <div className="strong" style={{ textTransform: 'normal'}}>
                                            {lang.t('rating.pricingQuoteDetail.modelReference')} {get(selected, 'meta.model_reference', '')}
                                        </div>
                                        <div className="text-muted text-h5 mt-2">
                                            <div className="badge bg-green">
                                            {lang.t('rating.pricingQuoteDetail.version')} {get(selected, 'meta.version', '')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto ml-auto d-print-none">
                                    <button type="button" className='btn btn-white' 
                                        onClick={e => {
                                            e.preventDefault();
                                            this.rawDataToggle();
                                        }}>
                                        {lang.t('buttons.rawData')}
                                    </button>
                                    <button onClick={this.export} type="button" className="btn btn-blue" style={{ marginLeft: '20px' }}>
                                        {lang.t('buttons.download')}
                                    </button>
                                    <button type="button" className="btn btn-red" style={{ marginLeft: '20px' }} onClick={e => {
                                        e.preventDefault();
                                        this.modalToggle();
                                        }}>
                                        {lang.t('buttons.delete')}
                                    </button>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12 col-lg-12">
                                    <Table
                                        data={[get(selected, 'meta')]}
                                        show_button={false}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='row mb-3'>
                                    <div className='col-lg-2'>
                                        <RatingsNav
                                            active={section}
                                            page={this.page}
                                            lang={lang}
                                        />
                                    </div>
                                    <div className='col-lg-10'>
                                        {section === 'quote' ? 
                                        <QuoteSection 
                                            data={selected.quote} 
                                            timezone={timezone}
                                            lang={lang}
                                        /> : 
                                        <PricingSection data={selected.pricing} lang={lang} />}
                                    </div>
                                </div>

                            </div>
                            <DeleteQuoteModal 
                                open={open}
                                modalToggle={this.modalToggle}
                                deleteQuote={this.deleteQuote}
                                quote_reference={quote_reference}
                                lang={lang}
                            />
                            <RawDataModal
                                openRawData={openRawData}
                                rawDataToggle={this.rawDataToggle}
                                data={selected}
                                lang={lang}
                            />
                        </div>
                    }
                </div>
            </div>
            ];
    }
}

export default main(QuoteDetail);