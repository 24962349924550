import React, { Component } from 'react';
import Input from '../../components/Detail/Forms/Input';
import Dropdown from '../../components/Detail/Forms/Dropdown';
import Disabled from '../../components/Detail/Forms/Disabled';
import { omit } from 'lodash'
import moment from 'moment-timezone'

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
        }
    }

    render() {
        const { selected, update, save, formError, timezones, lang } = this.props;

        return (
            <div className="card ml-2">
                <div className="card-header">
                    <h3 className="card-title">{lang.t('settings.products.product')}</h3>
                    <div className="ml-auto btn-list flex-nowrap">
                        <a className="btn btn-sm btn-primary text-white" onClick={(e)=>{
                            e.preventDefault();
                            save({
                                id: selected.product_reference,
                                data: omit(selected, [
                                    'payment_provider',
                                    'payment_provider_key',
                                    'payment_secret_key',
                                    'product_reference',
                                ]),
                            });
                        }}>{lang.t('buttons.save')}</a>
                    </div>
                </div>
                <div className="card-body">
                    <div className="mb-1">
                        <Disabled 
                            property={'product_reference'}
                            label={lang.t('settings.products.productReference')}
                            value={selected.product_reference}
                        />
                        <Input 
                            property={'product_name'}
                            label={lang.t('settings.products.name')}
                            value={selected.product_name}
                            update={update}
                            disabled={false}
                        />
                        <Input 
                            property={'product_description'}
                            label={lang.t('settings.products.description')}
                            value={selected.product_description}
                            update={update}
                            disabled={false}
                        />
                        <Dropdown
                            value={selected.currency}
                            items={[{
                                label: 'GBP',
                                value: 'GBP',
                            },{
                                label: 'EUR',
                                value: 'EUR',
                            },{
                                label: 'USD',
                                value: 'USD',
                            },{
                                label: 'AUD',
                                value: 'AUD',
                            }]}
                            property={'currency'}
                            label={lang.t('settings.products.currency')}
                            update={update}
                        />
                        <Dropdown 
                            value={selected.distance_units}
                            items={[{
                                label: 'Miles',
                                value: 'miles',
                            },{
                                label: 'Kilometers',
                                value: 'kms',
                            }]}
                            label={lang.t('settings.products.usageUnits')}
                            property={'distance_units'}
                            update={update}
                        />
                        <Input 
                            property={'distance_capped'}
                            label={`${lang.t('settings.products.usageDailyCap')}*`}
                            value={selected.distance_capped}
                            update={update}
                            type = 'number'
                            disabled={false}
                        />
                        <Dropdown
                            value={selected.timezone}
                            items={timezones.map(timezone => {
                                return {
                                    label: timezone,
                                    value: timezone,
                                }})
                            }
                            property={'timezone'}
                            label={lang.t('settings.products.timezone')}
                            update={update}
                            />
                        <Input 
                            property={'distance_capped_statement'}
                            label={`${lang.t('settings.products.statementCap')}*`}
                            value={selected.distance_capped_statement}
                            update={update}
                            type = 'number'
                            disabled={false}
                        />
                        <Dropdown 
                            value={selected.iso_language}
                            items={[{
                                label: 'English',
                                value: 'en',
                            },{
                                label: 'Italian',
                                value: 'it',
                            }]}
                            label={lang.t('settings.products.language')}
                            property={'iso_language'}
                            update={update}
                        />
                        <Input 
                            property={'renewal_rating_model'}
                            label={lang.t('settings.products.renewalRatingModelRef')}
                            value={selected.renewal_rating_model}
                            update={update}
                            disabled={false}
                            whiteSpace="normal"
                            textAlign="start"
                        />
                        {formError && (
                            <div style={{marginTop: 10}} className={`alert alert-danger`} role="alert">
                                <span class="form-check-label"><b>{lang.t('settings.products.renewalRatingModelRef')}</b> {lang.t('settings.products.isInvalid')}.</span>
                            </div>
                        )}
                        <div>
                            <div style={{marginLeft: 10}}>{`* In ${selected.distance_units}`}</div>
                        </div>
                        <div style={{marginTop: 10}} className={`alert alert-info`} role="alert">
                            <Input 
                                label={lang.t('settings.products.senderEmail')}
                                property={'sender_email_address'}
                                value={selected.sender_email_address}
                                update={update}
                                disabled={false}
                            />
                            {lang.t('settings.products.emailValidationWarning')} <b>By Bits support team</b>.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Settings;