import React, { Component } from 'react';
import Input from '../../components/forms/Input';

class Form_Token_MFA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            showPassword: false,
        };
        this.update = this.update.bind(this);
    }

    update(data) {
        this.setState(data);
    }

    render() {
        const { code } = this.state;
        const { isFetching, autocrat, lang } = this.props;
        const { access_token, qrcode } = autocrat; 
        return (
            <form 
                className="card card-md" 
                style={{ boxShadow: '0px 0px 26px 12px rgba(0,0,0,0.15)'}}
            >
                <div className="card-body">
                    <div className="mb-3">
                        <i className="fe fe-info" /> {lang.t('login.setTOTP')}
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-lg-5">
                            <div className="mt-2">
                                <img src={qrcode} />
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-7">
                            <p><b>{lang.t('login.enterCode')}</b></p>
                            <Input
                                label={'code'}
                                value={code}
                                type={'text'}
                                update={this.update}
                                validation={(value) => {
                                    return value.length === 6;
                                }}
                            />
                        </div>
                        <div className="form-footer" style={{ textAlign: 'right' }}>
                            {!isFetching &&
                                <button type="submit" onClick={(e) => {
                                    e.preventDefault();
                                    this.props.submitAutocrat({
                                        access_token,
                                        code,
                                        type: 'VERIFY_SOFTWARE_TOKEN_MFA'
                                    });
                                }} className="skew-button black_primary small">
                                    <span><span>{lang.t('login.nextStep')}</span></span>
                                </button>
                            }
                            {isFetching &&
                                <div 
                                    className="spinner-border text-black" 
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                        marginLeft: 'calc(50% - 10px)'
                                }} role="status" />
                            }
                        </div>
                    </div>
                    
                </div>
            </form>
        );
    }
}
export default Form_Token_MFA;