import React from 'react';

function Bar(props) {
    return (
        <div className="container">
        <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <div className="card">
                        <div className="progress progress-sm">
                            <div className="progress-bar progress-bar-indeterminate" />
                    </div>
                </div>
        </div>
        </div>
    </div>
    );
}

export default Bar;
