import React, { Component } from 'react';


class ModalTestDetails extends Component {
    constructor(props) {
        super(props);
        this.clear = this.clear.bind(this);
    }
    

    clear() {
        this.props.openModal({
            test_detail: {
                open: false,
            }
        })
    }

    render() {
        const { open, quote, pricing, lang } = this.props;
        const { model_reference = '', version = '' } = pricing || {};
    
        return (
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-exclusion" tabindex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >{`${lang.t('rating.components.tests.modelReference')} ${model_reference}`}</h5>
                            <div className="badge bg-green">{`VERSION: ${version}`}</div>
                            <button type="button" onClick={this.clear} className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h5 style={{
                                        color: '#354052'
                                    }}>{lang.t('rating.components.tests.quoteDetails')}</h5>
                                    <textarea 
                                        className="form-control"
                                        data-toggle="autosize"
                                        spellCheck="false"
                                        style={{
                                            overflow: 'auto',
                                            background: '#354052',
                                            borderRadius: '3px',
                                            fontSize: '14px',
                                            color: '#eaeaea',
                                            height: '400px',
                                            width: '100%',
                                        }} 
                                        value={JSON.stringify(quote, null, 4)}
                                    />
                                </div>
                                <div className="col-lg-6" style={{ position: 'relative' }}>
                                    <h5 style={{
                                        color: '#354052'
                                    }}>{lang.t('rating.components.tests.pricingDetails')}</h5>
                                    <textarea 
                                        className="form-control"
                                        data-toggle="autosize"
                                        spellCheck="false"
                                        style={{
                                            overflow: 'auto',
                                            background: '#354052',
                                            borderRadius: '3px',
                                            fontSize: '14px',
                                            color: '#eaeaea',
                                            height: '400px',
                                            width: '100%',
                                        }} 
                                        value={JSON.stringify(pricing, null, 4)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalTestDetails;