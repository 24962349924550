/*
 * All routing
 */
import Login from '../auth/Login';
import SettingsBilling from '../services/autocrat/pages/Billing';
import SettingsCompany from '../services/autocrat/pages/Company';
import SettingsUsers from '../services/autocrat/pages/Users';
// Claims
import ClaimsGenerate from '../services/claims/pages/analysis/Settings';
import ClaimsDash from '../services/claims/pages/dashboard/Dash';
import ClaimsJobs from '../services/claims/pages/jobs/Index';
import ClaimsPolicysView from '../services/claims/pages/policys/View';
import ClaimsFactors from '../services/claims/pages/reports/Factors';
import ClaimsLossRatio from '../services/claims/pages/reports/LossRatio';
import IdentityDetail from '../services/payments/pages/IdentityDetail';
// Payments
import Identitys from '../services/payments/pages/Identitys';
import Widget from '../services/payments/pages/Widget';
import ActivityDash from '../services/policys/pages/Activity/Dash';
import PolicyActivity from '../services/policys/pages/Activity/Search';
import ClaimDetail from '../services/policys/pages/Claims/ClaimDetail';
import JourneysDash from '../services/policys/pages/Journeys/Dash';
import JourneyDetail from '../services/policys/pages/Journeys/JourneyDetail';
import PolicyJourneys from '../services/policys/pages/Journeys/Search';
import PoliciesDash from '../services/policys/pages/Policies/Dash';
import PoliciesExpiring from '../services/policys/pages/Policies/Expiring';
import PoliciesSearch from '../services/policys/pages/Policies/Search';
import PolicyDetail from '../services/policys/pages/Policy/PolicyDetail';
import PolicyDetailTemp from '../services/policys/pages/Policy/PolicyDetailTemp';
import Bordereaux from '../services/policys/pages/Reporting/Bordereaux';
import Refunds from '../services/policys/pages/Reporting/Refunds';
import PolicysUnpaid from '../services/policys/pages/Reporting/Unpaid.js';
import ProductDetail from '../services/policys/pages/Settings/ProductDetail';
import Products from '../services/policys/pages/Settings/Products';
import StatementsDash from '../services/policys/pages/Statements/Dash';
import PolicyStatements from '../services/policys/pages/Statements/Search';
import StatementDetail from '../services/policys/pages/Statements/StatementDetail';
import TelematicsDeviceDetail from '../services/policys/pages/Telematics/DeviceDetail';
//Telematics
import TelematicsDevices from '../services/policys/pages/Telematics/Devices';
import TelematicsJourneyDetail from '../services/policys/pages/Telematics/JourneyDetail';
import TelematicsJourneys from '../services/policys/pages/Telematics/Journeys';
import TelematicsPackets from '../services/policys/pages/Telematics/Packets';
import PolicyUsers from '../services/policys/pages/Users';
import PricingAnalytics from '../services/rating/pages/Analytics';
import PricingCompare from '../services/rating/pages/Compare';
import PricingErrorDetail from '../services/rating/pages/ErrorDetail';
import PricingErrors from '../services/rating/pages/Errors';
import PricingFactors from '../services/rating/pages/Factors';
//Pricing
import PricingIndex from '../services/rating/pages/Index';
import PricingModelDetail from '../services/rating/pages/ModelDetail';
import PricingModels from '../services/rating/pages/Models';
import PricingQuoteDetail from '../services/rating/pages/QuoteDetail';
import PricingQuotes from '../services/rating/pages/Quotes';
import PricingPolicyReports from '../services/rating/pages/ReportPolicys';
import PricingTestDetail from '../services/rating/pages/TestDetail';
import PricingTests from '../services/rating/pages/Tests';

/*
properties:
name - Link display name
icon - feather icon for display
path - route path. This automatically defined in Router 
product - when to show link with in Policy or Rating view
component - The React Page to render
sublinks - The dropdown children links
hide - if true, this sets up the route but doesn't display it. Use for details pages mainly
*/

const routes = [
    {
        name: 'Home',
        path: '/',
        icon: 'fe-square',
        product: 'rating',
        component: PricingIndex,
        hide: true,
    },
    {
        name: 'Users',
        path: '/users',
        component: SettingsUsers,
        // hide: true,
    },
    {
        name: 'Users',
        path: '/settings/users',
        component: SettingsUsers,
        // hide: true,
    },
    {
        name: 'Company',
        path: '/settings/company',
        component: SettingsCompany,
        hide: true,
    },
    {
        name: 'Company',
        path: '/settings/billing',
        component: SettingsBilling,
        hide: true,
    },
    {
        name: 'Products',
        path: '/settings/products',
        component: Products,
        hide: true,
    },
    {
        name: 'ProductDetails',
        path: '/settings/products/:product_reference',
        component: ProductDetail,
        hide: true,
    },
    {
        name: 'Login',
        path: '/login',
        colour: 'cyanlight',
        component: Login,
        hide: true,
    },
    /*
    {
        name: 'Dashboard',
        icon: 'fe-cpu',
        path: '/policy-dashboard',
        component: PolicyLatest,
        product: 'policy',
    },
    */
    {
        name: 'policies',
        icon: 'fe-box',
        path: '/policys',
        component: PoliciesSearch,
        product: 'policy',
        sublinks: [
            // General
            {
                name: 'dashboard',
                path: '/policys/dashboard',
                component: PoliciesDash,
            },
            {
                name: 'search',
                path: '/policys',
                component: PoliciesSearch,
            },
            {
                name: 'expiring',
                path: '/policys/expiring',
                component: PoliciesExpiring,
            },
            /*{
                name: 'users',
                path: '/policys/users',
                component: PolicyUsers,
            },*/

            //Policy Specific
            {
                name: 'detail',
                path: '/policys/detail/:policy_reference',
                component: PolicyDetail,
                hide: true,
            },
            {
                name: 'detail',
                path: '/policys/detail-temp/:policy_reference',
                component: PolicyDetailTemp,
                hide: true,
            },
        ],
    },
    {
        name: 'claims',
        icon: 'fe-box',
        path: '/claims',
        component: '',
        hide: true,
        product: 'policy',
        sublinks: [
            {
                name: 'detail',
                path: '/claims/detail/:claim_reference',
                component: ClaimDetail,
            },
        ],
    },
    {
        name: 'journeys',
        icon: 'fe-git-merge',
        path: '/journeys',
        component: PolicyJourneys,
        product: 'policy',
        sublinks: [
            {
                name: 'dashboard',
                path: '/journeys/dashboard',
                component: JourneysDash,
            },
            {
                name: 'search',
                path: '/journeys',
                component: PolicyJourneys,
            },
            {
                name: 'journeyDetail',
                path: '/journeys/detail/:id',
                component: JourneyDetail,
                hide: true,
            },
        ],
    },
    {
        name: 'statements',
        icon: 'fe-briefcase',
        path: '/statements',
        component: PolicyStatements,
        product: 'policy',
        sublinks: [
            {
                name: 'dashboard',
                path: '/statements/dashboard',
                component: StatementsDash,
            },
            {
                name: 'search',
                path: '/statements',
                component: PolicyStatements,
            },
            {
                name: 'statementDetail',
                path: '/statements/detail/:id',
                component: StatementDetail,
                hide: true,
            },
        ],
    },
    {
        name: 'activity',
        icon: 'fe-cpu',
        path: '/activity',
        component: PolicyActivity,
        product: 'policy',
        sublinks: [
            {
                name: 'dashboard',
                path: '/activity/dashboard',
                component: ActivityDash,
            },
            {
                name: 'search',
                path: '/activity',
                component: PolicyActivity,
            },
        ],
    },
    /*
    {
        name: 'quotes',
        icon: 'fe-aperture',
        path: '/quotes',
        component: PricingIndex,
        product: 'policy',
        sublinks: [
            {
                name: 'search',
                path: '/quotes/latest',
                component: PricingIndex,
            },
            {
                name: 'conversions',
                path: '/quotes/conversions',
            },
            {
                name: 'promotions',
                path: '/quotes/promotions',
            },
            {
                name: 'blocklists',
                path: '/quotes/blocklists',
            },
            {
                name: 'GDPR',
                path: '/quotes/gdpr',
            },
        ],
    },
    */
    {
        name: 'telematics',
        icon: 'fe-grid',
        path: '/telematics',
        product: 'policy',
        sublinks: [
            {
                name: 'devices',
                path: '/telematics/devices',
                component: TelematicsDevices,
            },
            {
                name: 'packets',
                path: '/telematics/packets',
                component: TelematicsPackets,
            },
            {
                name: 'journeys',
                path: '/telematics/journeys',
                component: TelematicsJourneys,
            },
            {
                name: 'devicesDetail',
                path: '/telematics/devices/:id',
                component: TelematicsDeviceDetail,
                hide: true,
            },
            {
                name: 'journeyDetail',
                path: '/telematics/journey/:id',
                component: TelematicsJourneyDetail,
                hide: true,
            },
        ],
    },
    {
        name: 'reports',
        icon: 'fe-grid',
        path: '/reports',
        product: 'policy',
        sublinks: [
            {
                name: 'bordereaux',
                path: '/reports/bordereaux',
                component: Bordereaux,
            },
            {
                name: 'unpaidVersions',
                path: '/reports/unpaid',
                component: PolicysUnpaid,
            },
            {
                name: 'refunds',
                path: '/reports/refunds',
                component: Refunds,
                hide: true,
                showLinkOnlyFor: ['By Bits Ltd', 'TIM'],
            },
            /*
            {
                name: 'LRClaimsFrequency',
                path: '/reports/loss-ratio',
                component: ClaimsLossRatio,
            },
            {
                name: 'createReports',
                path: '/reports/settings',
                component: ClaimsGenerate,
            },
            {
                name: 'riskFactors',
                path: '/reports/factors',
                component: ClaimsFactors,
            },
            {
                name: 'reportJobs',
                path: '/reports/jobs',
                component: ClaimsJobs,
            },
            */
        ],
    },
    /*
    {
        name: 'usage',
        icon: 'fe-rss',
        path: '/usage',
        product: 'policy',
        sublinks: [
            {
                name: 'devices',
                path: '/usage/devices',
            },
            {
                name: 'connectedCar',
                path: '/usage/connected-car',
            },
            {
                name: 'journeys',
                path: '/usage/journeys',
            },
            {
                name: 'odometer',
                path: '/usage/odometer',
            },
            {
                name: 'incidents',
                path: '/usage/incidents',
            },
            {
                name: 'alerts',
                path: '/usage/alerts',
            },
            {
                name: 'packets',
                path: '/usage/packets',
            },
        ],
    },
    {
        name: 'settings',
        icon: 'fe-settings',
        path: '/products',
        component: PolicyActivity,
        product: 'policy',
        sublinks: [
            {
                name: 'products',
                path: '/products',
                component: Products,
            },
            {
                name: 'productDetails',
                path: '/products/:id',
                component: ProductDetail,
                hide: true,
            },
            {
                name: 'underwriters',
                path: '/activity',
                component: PolicyActivity,
            },
        ],
    },
    */
    {
        name: 'dashboard',
        icon: 'fe-cpu',
        path: '/rating-dashboard',
        component: PricingIndex,
        product: 'rating',
    },
    {
        name: 'models',
        icon: 'fe-database',
        path: '/rating',
        component: PricingIndex,
        product: 'rating',
        sublinks: [
            {
                name: 'models',
                path: '/rating/models',
                component: PricingModels,
            },
            {
                name: 'modelsDetail',
                path: '/rating/models/:id',
                component: PricingModelDetail,
                hide: true,
            },
            {
                name: 'tests',
                path: '/rating/tests',
                component: PricingTests,
                hide: true,
            },
            {
                name: 'testsDetail',
                path: '/rating/tests/:id',
                component: PricingTestDetail,
                hide: true,
            },
            {
                name: 'analytics',
                path: '/rating/analytics/:id',
                component: PricingAnalytics,
                hide: true,
            },
            {
                name: 'healthAnalytics',
                path: '/rating/analytics',
                component: PricingAnalytics,
            },
            {
                name: 'factorsAnalytics',
                path: '/rating/factors',
                component: PricingFactors,
            },
            {
                name: 'compareVersions',
                path: '/rating/compare',
                component: PricingCompare,
            },
        ],
    },
    /*
    {
        name: 'KYC',
        icon: 'fe-book-open',
        path: '/kyc',
        component: IdentityDetail,
        product: 'rating',
        sublinks: [
        {
            name: 'openBankingIDScoring',
            icon: 'fe-tag',
            path: '/kyc/open-banking',
            component: Identitys,
        },
        {
            name: 'IDScoringIndividual',
            icon: 'fe-tag',
            path: '/kyc/open-banking/:id',
            component: IdentityDetail,
            hide: true,
        },
        {
            name: 'vehicles',
            icon: 'fe-tag',
            path: '/kyc/vehicles',
        },
        {
            name: 'sanctions',
            icon: 'fe-tag',
            path: '/kyc/sanctions',
        },
        {
            name: 'quoteFraud',
            icon: 'fe-tag',
            path: '/kyc/quote-fraud',
        },
        ],
    },
    {
        name: 'reports',
        icon: 'fe-airplay',
        path: '/rating-reports',
        product: 'rating',
        component: PricingPolicyReports,
        sublinks: [
            {
                name: 'managePolicies',
                path: '/rating-reports/policies',
                component: PricingPolicyReports,
            },
        ]
    },*/
    {
        name: 'logs',
        icon: 'fe-file-text',
        path: '/rating-logs',
        product: 'rating',
        component: PricingQuotes,
        sublinks: [
            {
                name: 'quotes',
                path: '/rating-logs',
                component: PricingQuotes,
            },
            {
                name: 'errors',
                path: '/rating-logs/errors',
                component: PricingErrors,
            },
            {
                name: 'quotesDetail',
                path: '/rating-logs/quotes/:id',
                component: PricingQuoteDetail,
                hide: true,
            },
            {
                name: 'quotesDetail',
                path: '/rating-logs/errors/:id',
                component: PricingErrorDetail,
                hide: true,
            },
        ],
    },
];

export default routes;
