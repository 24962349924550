import React, { Component } from 'react';
import Input from '../../components/forms/Input';

class Form_New_Password extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_password: '',
            showPassword: false,
        };
        this.update = this.update.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
    }

    update(data) {
        this.setState(data);
    }

    validatePassword(newPassword) {
        const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        const validValue = regex.test(newPassword);
        return validValue;
    }

    render() {
        const { new_password, showPassword } = this.state;
        const { lang, isFetching, autocrat, setAutocratError } = this.props;
        const { username, session } = autocrat;
        return (
            <form 
                className="card card-md" 
                style={{ boxShadow: '0px 0px 26px 12px rgba(0,0,0,0.15)'}}>
                <div className="card-body">
                    <p className="text-muted">
                        {lang.t('login.setNewPassword')}
                    </p>
                    <div className="input-group input-group-flat">
                        <Input
                            label={'new_password'}
                            value={new_password}
                            type={showPassword === true ? 'text' : 'password'}
                            update={this.update}
                            validation={(value) => {
                                return value.length > 100;
                            }}
                        />
                        <span className="input-group-text">
                            <a href="#" 
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.update({
                                        showPassword: !showPassword,
                                    });
                                }} 
                                className="link-secondary" 
                                title="Show password"
                                data-toggle="tooltip"
                            >
                                <i className="fe fe-eye" />
                            </a>
                        </span>
                    </div>
                    <div className="form-footer" style={{ textAlign: 'right' }}>
                        {!isFetching &&
                            <button type="submit" onClick={(e) => {
                                e.preventDefault();
                                if(!this.validatePassword(new_password)) {
                                    setAutocratError('Incorrect password format');
                                } else {
                                    this.props.submitAutocrat({
                                        username,
                                        session,
                                        new_password,
                                        type: 'NEW_PASSWORD_REQUIRED'
                                    });
                                }
                            }} className="skew-button black_primary small">
                                <span><span>{lang.t('login.sendPassword')}</span></span>
                            </button>
                        }
                        {isFetching &&
                            <div 
                                className="spinner-border text-black" 
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                    marginLeft: 'calc(50% - 10px)'
                            }} role="status" />
                        }
                    </div>
                </div>
            </form>
        );
    }
}
export default Form_New_Password;
