import React, { Component } from 'react';

import FormBase from '../Forms/FormBase';
import FormAddress from '../Forms/FormAddress';
import FormVehicle from '../Forms/FormVehicle';
import FormProposer from '../Forms/FormProposer';
import FormPricing from '../Forms/FormPrice';
import FormAdditionalDrivers from '../Forms/FormAdditionalDrivers';
import FormCustom from '../Forms/FormCustom';

class DetailSection extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            additional_drivers = [],
        } = this.props.policy_detail;

        const { timezone, lang } = this.props;

        return (<>
            <div className="row"> 
                <div className="col-lg-6">
                    <FormBase
                        update={this.props.update}
                        detail={this.props.policy_detail}
                        disabled={true}
                        timezone={timezone}
                        lang={lang}
                    />
                    <FormAddress
                        update={this.props.update}
                        detail={this.props.policy_detail}
                        disabled={true}
                        lang={lang}
                    />
                    <FormVehicle
                        update={this.props.update}
                        detail={this.props.policy_detail}
                        disabled={true}
                        lang={lang}
                    />
                </div>
                <div className="col-lg-6">
                    <div className="card-tabs" style={{ position: 'relative'}}>    
                        <ul className={`nav nav-tabs ${additional_drivers.length === 4 && 'nav-fill'}`}>
                            <li className="nav-item"><a href="#tab-top-1" className="nav-link active" data-toggle="tab">{lang.t('policy.policies.proposer')}</a></li>
                            {additional_drivers.map((ad, i) => {
                                return (
                                    <li className="nav-item">
                                        <a href={`#tab-top-${i + 2}`} className="nav-link" data-toggle="tab">{lang.t('policy.policies.driver')} {i + 1}</a>
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="tab-content">
                            <div id="tab-top-1" className="card tab-pane active show">
                                <FormProposer 
                                    update={this.props.update}
                                    detail={this.props.policy_detail}
                                    disabled={true}
                                    lang={lang}
                                />
                            </div>
                            {additional_drivers.map((ad, i) => {
                                return (
                                    <div id={`tab-top-${i + 2}`} className="card tab-pane">
                                        <FormAdditionalDrivers
                                            update={this.props.update}
                                            detail={this.props.policy_detail}
                                            driver={ad}
                                            disabled={true}
                                            lang={lang}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <FormPricing
                        update={this.props.update}
                        detail={this.props.policy_detail}
                        disabled={true}
                        lang={lang}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <FormCustom 
                        update={this.props.update}
                        detail={this.props.policy_detail}
                        disabled={true}
                        lang={lang}
                    />
                </div>
            </div>
        </>);
    }
}

export default DetailSection;
