import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { bindActionCreators } from 'redux';
import actions from '../../../actions';
import { getLangSource } from '../../../lang';

class Widget extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const lang = getLangSource(this.props.lang);

        const isFetching = get(this.props, 'payments_dashboard.isFetching', false);

        return (
            <div className="my-3 my-md-5">
                {!isFetching
                    && (
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-lg-3" />
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ...actions,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Widget);
