export const validateColumnsSchema = {
    "type": "object",
    "properties": {
        "one_hot_keys": {
            "type": "array",
            "description": "Columns that require one-hot encoding prior to model prediction",
            "example": ["column_1", "column_2"]
        },
        "columns_for_predictions": {
            "type": "array",
            "description": "Columns that are required for model prediction. This includes columns from one-hot encoding",
            "example": ["column_1_A", "column_1_B"]
        }
    },
    "required": [
        "one_hot_keys", 
        "columns_for_predictions"
    ]
}