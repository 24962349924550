import React, { Component } from 'react';
import moment from 'moment';

class DropDownReports extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { reports, selectReport } = this.props;
        return (
            <select class="form-select" onChange={(e) => {
                e.preventDefault();
                selectReport(e.target.value);
            }}>
                {reports.map(r => {
                    return (<option value={r.id}>{moment(r.created_at).fromNow()}</option>)
                })}
            </select>
        );
    }
}

export default DropDownReports;