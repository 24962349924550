import React, { Component } from 'react';
import Input from './Input';
import Select from './Select';
import DatePicker from './DatePicker';
import { get_codelist } from '../Utils';

class FormConviction extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data, disabled, update, lang } = this.props;
        return(
            <div className="card-body">
                <Select 
                    label={`${lang.t('policy.policies.convictionCode')} *`}
                    property={'code'}
                    options={get_codelist('proposer.convictions.map(code)')}
                    value={data.code}
                    update={update}
                    disabled={disabled}
                />
                <DatePicker 
                    label={`${lang.t('policy.policies.date')} *`}
                    property={'date'}
                    value={data.date}
                    update={update}
                    disabled={disabled}
                />
                <Input 
                    label={`${lang.t('policy.policies.points')} *`}
                    property={'points'}
                    value={data.points}
                    update={update}
                    type = 'number'
                    disabled={disabled}
                />
                <Input
                    label={`${lang.t('policy.policies.ban')} *`}
                    property={'ban'}
                    value={data.ban}
                    update={update}
                    type = 'number'
                    disabled={disabled}
                />
            </div>
        )
    }
}

export default FormConviction;