import React from 'react';
function colour(fn = '') {
    const size = fn.length;
    const map = {
        1: 'avatar bg-blue-lt',
        2: 'avatar bg-azure-lt',
        3: 'avatar bg-indigo-lt',
        4: 'avatar bg-purple-lt',
        5: 'avatar bg-pink-lt',
        6: 'avatar bg-red-lt',
        7: 'avatar bg-orange-lt',
        8: 'avatar bg-yellow-lt',
        9: 'avatar bg-lime-lt',
        10: 'avatar bg-green-lt',
        11: 'avatar bg-teal-lt',
        12: 'avatar bg-cyan-lt',
    }
    return map[size] || 'avatar-gray';
}

function initials(fn = '-', ln = '-') {
    return fn.charAt(0).toUpperCase() + ln.charAt(0).toUpperCase();
}

function Avatar(props) {
    const { f_names, l_names } = props;
    return (
        <span className={`avatar ${colour(f_names)}`}>{initials(f_names, l_names)}</span>
    );
}

export default Avatar;