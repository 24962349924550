import React, { Component } from 'react';
import Avatar from '../../../../components/avatar'
import { sortBy, round, transform } from 'lodash';

class PolicyUsersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        }
    }

    updateUser ({ id, data }) {
        this.props.users_put({
            id,
            data,
        }).then(() => {
            this.props.auth_get_all({});
        });
    }

    render() {
        const { data = [] } = this.props;
        const filtered = sortBy(data.filter(d => d.role === 'policyholder'), 'username');
        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th className="w-1"></th>
                            </tr>
                        </thead>
                        <tbody>
                        {filtered.map((d, i) => (
                            <tr key={`users-${i}`}>
                                <td data-label="Name">
                                    <div className="d-flex lh-sm py-1 align-items-center">
                                        <div className="mr-2">
                                            <Avatar 
                                                f_names={d.f_names}
                                                l_names={d.l_names}
                                            />
                                        </div>
                                        <div className="flex-fill">
                                            <div className="strong">{d.f_names} {d.l_names}</div>
                                        </div>
                                    </div>
                                </td>
                                <td data-label="Username">
                                    {d.username}
                                </td>
                                <td data-label="Production">
                                    {d.email}
                                </td>
                                <td data-label="Sandbox">
                                    {d.role}
                                </td>
                                <td className="w-1">
                                    <div className="btn-list flex-nowrap">
                                        {d.active === true &&
                                            <a className="btn btn-red btn-sm text-white" onClick={(e) => {
                                                e.preventDefault();
                                                this.updateUser({
                                                    id: d.id,
                                                    data: {
                                                        active: 'false',
                                                    }
                                                })
                                            }}>Disable</a>
                                        }
                                        {d.active === false &&
                                            <a className="btn btn-primary btn-sm text-white" onClick={(e) => {
                                                e.preventDefault();
                                                this.updateUser({
                                                    id: d.id,
                                                    data: {
                                                        active: 'true',
                                                    }
                                                })
                                            }}>Enable</a>
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
          </div>
        );
    }
}

export default PolicyUsersTable;
