import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

class ActivityTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], show_reference = true, lang } = this.props;
        return (
            <div className="card">

                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                {show_reference && <th>{lang.t('tableHeaders.policyReference')}</th>}
                                <th>{lang.t('tableHeaders.username')}</th>
                                <th>{lang.t('tableHeaders.url')}</th>
                                <th>{lang.t('tableHeaders.created')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((d, i) => (
                                <tr key={`activity-${i}`}>
                                    {show_reference &&
                                        <td data-label="Ref">
                                            <div className="btn-list flex-nowrap">
                                                <Link
                                                    to={`/policys/detail/${d.policy_reference}`}
                                                    className="btn btn-sm btn-primary"
                                                >{d.policy_reference}</Link>
                                            </div>
                                        </td>
                                    }
                                    <td data-label="Username">
                                        <div className="text-h5"><b>{d.username}</b></div>
                                    </td>
                                    <td data-label="Url">
                                        <div className="text-h5">{d.url}</div>
                                    </td>
                                    <td data-label="Price">
                                        <div className="text-h5">{moment(d.created_at).fromNow()}</div>
                                    </td>
                                  
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default ActivityTable;
