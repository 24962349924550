import React, { Component } from 'react';
import { inRange } from 'lodash';
import FormConviction from '../Forms/FormConviction';
import { upsert, driver_type, validate_policy_object } from '../Utils';

class ModalAddConviction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conviction: {},
            can_submit: false,
        }

        this.close = this.close.bind(this);
        this.update = this.update.bind(this);
        this.add = this.add.bind(this);
    }

    close() {
        this.props.modal_open({
            add_conviction: {
                open: false,
            }
        });
        this.setState({
            conviction: {},
            can_submit: false,
        });

        this.props.set_driver_index(null)
    }

    update(item) {
        this.setState({
            conviction: {
                ...this.state.conviction,
                ...item,
            }
        }, () => {
            const { conviction } = this.state;

            const isValid = validate_policy_object({
                data: conviction,
                type: 'conviction',
            });

            if(isValid) {
                this.setState({ can_submit: true });
            } else {
                this.setState({ can_submit: false })
            }
        });
    }

    add() {
        const { 
            detail, 
            driver_index, 
            update, 
            modal_open,
            set_driver_index,
        } = this.props;

        const { conviction } = this.state;
        let result = { ...detail };

        if (driver_index || driver_index === 0) {
            //Handle proposer
            if (driver_index === "proposer") {
                result = {
                    ...result,
                    proposer: {
                        ...detail.proposer,
                        convictions: [...detail.proposer.convictions, conviction]
                    }
                }
            }

            // Handle additional drivers
            if (inRange(driver_index, 0, 4)) {
                let { additional_drivers } = detail;
                const drv_info = additional_drivers[driver_index]

                const new_info = {
                    ...drv_info,
                    convictions: [...drv_info.convictions || [], conviction]
                }

                upsert(additional_drivers, driver_index, new_info);

                result = {
                    ...result,
                    additional_drivers,
                }
            }
            
            update(result);

            set_driver_index(null);

            modal_open({
                add_conviction: {
                    open: false,
                }
            });
        } else {
            //Handle new driver to be added
        
            this.props.modal_open({
                add_conviction: {
                    open: false,
                    conviction,
                }
            });
        }

        this.setState({
            conviction: {},
            can_submit: false,
        });
    }

    render() {
        const { open, driver_index, lang } = this.props;
        const { conviction, can_submit } = this.state;
        return (
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-add-conviction" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header theme-dark" >
                            <h5 className="modal-title">{`${lang.t('policy.policies.add')} ${driver_type[driver_index] ? driver_type[driver_index] : lang.t('policy.policies.newDriver')} ${lang.t('policy.policies.conviction')}`}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5 className="text-right text-red">* {lang.t('policy.policies.requiredFields')}</h5>
                            <FormConviction 
                                data={conviction}
                                update={this.update}
                                lang={lang}
                            />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={this.add}
                                disabled={!can_submit}
                            >
                                {lang.t('policy.policies.addConviction')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalAddConviction;