import React, { Component } from 'react';

import SuperTable from '../../../../../components/tables/Super';

class MLModelsTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], lang } = this.props;
        const headers = [
            {
                label: lang.t('tableHeaders.name'),
                name: 'name',
                search: true,
            },
            {
                label: lang.t('tableHeaders.description'),
                name: 'description',
            },
            {
                label: lang.t('tableHeaders.library'),
                name: 'library',
            },
            {
                label: '',
                name: '',
                class: 'w-1'
            },
            {
                label: '',
                name: '',
                class: 'w-1'
            }
        ];

        const Cells = (index, d) => {
            return (
                <tr key={`ml-${index}`}>
                    <td data-label={lang.t('tableHeaders.name')}>
                        <div className="text-h5"><b>{d.name}</b></div>
                    </td>
                    <td data-label={lang.t('tableHeaders.description')}>
                        <div className="text-h5"><b>{d.description}</b></div>
                    </td>
                    <td data-label={lang.t('tableHeaders.library')}>
                        <div className="text-h5"><b>{d.library.toUpperCase()}</b></div>
                    </td>
                    <td data-label={lang.t('buttons.edit')}>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.props.edit(d);
                        }} className="btn btn-primary btn-sm">
                            {lang.t('buttons.edit')}
                        </a>
                    </td>
                    <td data-label={lang.t('buttons.delete')}>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.props.delete(d); 
                        }} className="btn btn-red btn-sm">
                            {lang.t('buttons.delete')}
                        </a>
                    </td>
                </tr>
            );
        }

        return (
            <SuperTable 
                data={data}
                headers={headers}
                Cells={Cells}
                limit={1000}
                height={500}
                lang={lang}
            />
        );
    }
}

export default MLModelsTable;