import React, { Component } from 'react';
import SuperTable from '../../../../components/tables/Super';
import { getCodeList } from '../../utils/getCodeList';
import { formatValues } from '../../utils/formatValues';

class TableModifications extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], lang } = this.props;
        const map_list = getCodeList('vehicle.modifications');

        let headers = [
            {
                label: 'Code',
                name: 'code',
            },
        ];

        const Cells = (index, d) => {
            return (
                <tr key={`mod-${index}`}>
                    <td data-label="Code">
                        <div className="text-h5">
                            {formatValues(d, 'mapped', map_list)}
                        </div>
                    </td>
                </tr>
            )
        }

        return(
            <SuperTable 
                data={data}
                headers={headers}
                Cells={Cells}
                auto_sort={true}
                lang={lang}
            />
        )
    }
}

export default TableModifications;