import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'

import {
    Router,
    Route,
} from 'react-router-dom';

import routes from './routes';
import store from './store';

import createHistory from 'history/createBrowserHistory';
const history = createHistory();

export const DefaultRender = (props) => (
    <div className="my-3 my-md-5">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <div className="alert alert-info alert-dismissible" role="alert">
                        <h3>This is a default page element</h3>
                        <p>This probably means you haven't added a component in the routes</p>
                        <a href="#" className="close" data-dismiss="alert" aria-label="close">&times;</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

function flattenRoutes(routes = []) {
    const flatLinks = [];
    routes.forEach(link => {
        const { sublinks = [], path, component } = link
        if (sublinks.length === 0) {
            flatLinks.push({
                path,
                component,
            });
        } else {
            sublinks.forEach(s => {
                flatLinks.push({
                    path: s.path,
                    component: s.component,
                });
            })
        }
    });
    return flatLinks;
}

ReactDOM.render(
    <Provider store={store()}>
        <Router history={history}>
            {flattenRoutes(routes).map(r => {
                let { component } = r;
                if (!component) component = DefaultRender;
                return (
                    <Route exact path={r.path} component={component} />
                );
            })}
        </Router>
    </Provider>,
    document.getElementById('root'),
);