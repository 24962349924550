import React from 'react';
import DatePicker from '../../Forms/DatePicker';
import moment from 'moment';
import { get } from 'lodash';

function SelectEffectiveDate(props) {
    const { close, next, update, date, edited, minDate, detail_readonly, timezone, lang } = props;
    const latest_version_effective_date = moment(get(detail_readonly, 'versions[0].effective_date')).unix();

    const isSameDayMTA = (date.effective_date <= latest_version_effective_date && date.effective_date !== "") ? true : false;

    return (
        <div className="modal-content">
            <div className="modal-header theme-dark" >
                <h5 className="modal-title">{lang.t('policy.policies.selectEffectiveDate')}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
                    <i className="fe fe-x"></i>
                </button>
            </div>
            <div className="modal-body">
                {!edited && (
                    <div className='alert alert-warning mb-4' role="alert">
                        <i style={{ marginRight: 5 }} className="fe fe-alert-triangle"></i>
                        {lang.t('policy.policies.noChangesWarning')}
                    </div>
                )}
                {isSameDayMTA && (
                    <div className='alert alert-danger mb-4' role="alert">
                        <i style={{ marginRight: 5 }} className="fe fe-alert-triangle"></i>
                        {`${lang.t('policy.policies.sameDayWarning')} ${moment.unix(date.effective_date).format('D MMM YYYY')}!`}
                    </div>
                )}
                <div className='alert alert-info mb-4' role="alert">
                    <p>
                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                        {lang.t('policy.policies.detailsCorrectWarning')}
                    </p>
                    <small>({lang.t('policy.policies.noteProduct')} <strong>{timezone}</strong> {lang.t('policy.policies.timezone')})</small>
                </div>
                <DatePicker 
                    label={lang.t('policy.policies.effectiveDate')}
                    property='effective_date'
                    type='unix'
                    update={update}
                    value={date.effective_date}
                    minDate={minDate}
                    showTimePicker={true}
                    dateFormat={'dd/MM/yyyy HH:mm'}
                />
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={e => next(e)}
                    disabled={!date.effective_date}
                >
                    {lang.t('buttons.next')}
                </button>
            </div>
        </div>
    );
}

export default SelectEffectiveDate;