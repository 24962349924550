import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { each, groupBy, first, sortBy } from 'lodash';
import moment from 'moment';

import { extendedDateFormat } from '../../../../utils/format';
import { releaseBadge, versionBadge } from '../../components/Shared/Badges';

function toUpper(str = '') {
    return str?.toUpperCase();
}

function modelOrder(models) {
    let arr = [];
    const groupedModel = groupBy(models, 'model_reference');
    each(groupedModel, (g, k) => {

        const ghosts = sortBy(
            g.filter(g => g.status === 'ghost'),
            g => -moment(g.published_at)
        );

        const schedules = sortBy(
            g.filter(g => g.status === 'published' && moment(g.published_at) > moment()),
            g => -moment(g.published_at)
        );

        const lives = sortBy(
            g.filter(g => g.status === 'published' && moment(g.published_at) <= moment()),
            g => -moment(g.published_at)
        );

        const drafts = sortBy(
            g.filter(g => g.status === 'draft'),
            g => -moment(g.updated_at)
        );

        const current_live = first(lives);
        const current_schedule = first(schedules);
        const current_ghost = first(ghosts);
        const current_draft = first(drafts);

        arr.push({
            ...(current_live || current_draft || current_schedule || current_ghost),
            live: current_live ? {
                version: current_live.version,
                published_at: current_live.published_at,
            } : null,
            schedule: current_schedule ? {
                version: current_schedule.version,
                published_at: current_schedule.published_at,
            }: null,
            ghost: current_ghost ? {
                version: current_ghost.version,
                published_at: current_ghost.published_at,
            } : null,
            draft: current_draft ? {
                version: current_draft.version,
                published_at: current_draft.published_at,
            } : null,
        });
    });
    return arr;
}

class ModelsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        }
    }

    render() {
        const { data = [], lang } = this.props;
        const models = modelOrder(data);

        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>  
                            <tr>
                                {/*
                                <th className="w-1">Quotes</th>
                                <th className="w-4">Chart</th>
                                */}
                                <th>{lang.t('tableHeaders.name')}</th>
                                <th>{lang.t('tableHeaders.live')}</th>
                                <th>{lang.t('tableHeaders.sche')}</th>
                                <th>{lang.t('tableHeaders.draft')}</th>
                                <th>{lang.t('tableHeaders.ghost')}</th>
                                <th>{lang.t('tableHeaders.publishedAt')}</th>
                                <th>{lang.t('tableHeaders.updatedAt')}</th>
                                <th className="w-2"></th>
                                <th className="w-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {models.filter(m => !m.disabled).map((d, i) => (
                                <tr key={`models-${i}`}>
                                    <td data-label="Name">
                                        <div className="d-flex lh-sm py-1 align-items-center">
                                            <div className="flex-fill">
                                                <div className="strong">{toUpper(d.name)}</div>
                                                <div className="text-muted text-h5">{d.model_reference}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-label="LiveVersion">
                                        {d.live && versionBadge({
                                            version: d.live.version,
                                            published_at: d.live.published_at,
                                            status: 'published',
                                            is_small: true,
                                        })}
                                    </td>
                                    <td data-label="SchedVersion">
                                        {d.schedule && versionBadge({
                                            version: d.schedule.version,
                                            published_at: d.schedule.published_at,
                                            status: 'published',
                                            is_small: true,
                                        })}
                                    </td>
                                    <td data-label="DraftVersion">
                                        {d.draft && versionBadge({
                                            version: d.draft.version,
                                            published_at: d.draft.published_at,
                                            status: 'draft',
                                            is_small: true,
                                        })}
                                    </td>
                                    <td data-label="GhostVersion" className="align-center">
                                        {d.ghost && versionBadge({
                                            version: d.ghost.version,
                                            published_at: d.ghost.published_at,
                                            status: 'ghost',
                                            is_small: true,
                                        })}
                                    </td>
                                    {/*
                                    <td data-label="Status">
                                        <div className="text-muted text-h5">{releaseBadge({
                                            status: d.status,
                                            published_at: d.published_at,
                                        })}</div>
                                    </td>
                                    */}
                                    <td data-label="Label">
                                        <div className="text-muted text-h5">{d.published_at ? extendedDateFormat(d.published_at) : '-'}</div>
                                    </td>
                                    <td data-label="Label">
                                        <div className="text-muted text-h5">{extendedDateFormat(d.updated_at)}</div>
                                    </td>
                                    <td>
                                        <div className="btn-list flex-nowrap">
                                            <Link
                                                to={`/rating/analytics/${d.model_reference}`}
                                                className="btn btn-blue btn-sm"
                                                style={{ width: 100 }}
                                            >{lang.t('rating.models.analytics')}</Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="btn-list flex-nowrap">
                                            <Link
                                                to={`/rating/models/${d.version_reference}`}
                                                className="btn btn-primary btn-sm"
                                                style={{ width: 100 }}
                                            >{lang.t('rating.models.edit')}</Link>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default ModelsTable;
