import React from 'react';
import Input from '../../../../components/forms/Input';
import Textarea from '../../../../components/forms/Textarea';
import { parse_date } from '../Detail/Utils';
import { isEmpty } from 'lodash';

export default function FormClaim(props) {
    const { 
        timezone,
        claim,
        toggleDetailModal,
        lang,
    } = props;

    const {
        claim_reference,
        claim_type,
        claim_latitude,
        claim_longitude,
        reported_date,
        loss_date,
        claim_description,
        detail = {},
    } = claim;

    const InfoAvailable = property => {
        if(isEmpty(detail)) {
            return 'Not available'
        } else if (isEmpty(detail[property])) {
            return 'No'
        } else {
            return 'Yes'
        }
    }

    return (
        <div className='card'>
            <div className='card-header'>
                <h3 className='card-title'>{lang.t('policy.claims.claimDetails')}</h3>
                <a className="btn btn-sm btn-green text-white ml-auto" style={{
                        position: 'absolute',
                        top: '12px',
                        right: '16px'
                    }}
                onClick={toggleDetailModal}
                >{lang.t('policy.claims.moreDetails')}</a>
            </div>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='input-group mb-2'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.claimRef')}</span>
                            </div>
                            <Input
                                value={claim_reference}
                                disabled={true}
                            />
                        </div>
                        <div className='input-group mb-2'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.claimType')}</span>
                            </div>
                            <Input
                                value={claim_type}
                                disabled={true}
                            />
                        </div>
                        <div className='input-group mb-2'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.claimLat')}</span>
                            </div>
                            <Input
                                value={claim_latitude}
                                disabled={true}
                            />
                        </div>
                        <div className='input-group mb-2'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.claimLong')}</span>
                            </div>
                            <Input
                                value={claim_longitude}
                                disabled={true}
                            />
                        </div>
                        <div className='input-group mb-2'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.reportedDate')}</span>
                            </div>
                            <Input
                                value={parse_date(reported_date, 'DD/MM/YYYY', timezone)}
                                disabled={true}
                            />
                        </div>
                        <div className='input-group'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.lossDate')}</span>
                            </div>
                            <Input
                                value={parse_date(loss_date, 'DD/MM/YYYY', timezone)}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <Textarea 
                            title={lang.t('policy.claims.claimDesc')}
                            value={claim_description}
                        />
                        <div className='input-group mb-2'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.thirdParties')}</span>
                            </div>
                            <Input
                                value={InfoAvailable('third_parties')}
                                disabled={true}
                            />
                        </div>
                        <div className='input-group mb-2'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.witnesses')}</span>
                            </div>
                            <Input
                                value={InfoAvailable('witnesses')}
                                disabled={true}
                            />
                        </div>
                        <div className='input-group mb-2'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.police')}</span>
                            </div>
                            <Input
                                value={InfoAvailable('police')}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
