import React, { Component } from 'react';
import SuperTable from '../../../../../components/tables/Super';

class LinkedTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], lang } = this.props;

        const headers = [
            {
                label: lang.t('tableHeaders.key'),
                name: 'key',
                search: true,
            },
            {
                label: lang.t('tableHeaders.rows'),
                name: 'rows_count',
            },
            {
                label: '',
                name: 'csv',
            },
            {
                label: '',
                name: 'edit',
            },
            {
                label: '',
                name: 'delete',
            },
        ];

        const Cells = (index, d) => {
            return (
                <tr key={`linked-${index}`}>
                    <td data-label={lang.t('tableHeaders.key')}>  
                        <div className="text-h5">{d.key}</div>
                    </td>
                    <td data-label={lang.t('tableHeaders.count')}>
                        <div className="badge bg-green">
                            {d.rows_count}
                        </div>
                    </td>
                    <td className="w-1">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.props.download(d.key)
                        }} className="btn btn-white btn-sm">
                            {lang.t('rating.components.linked.csv')}
                        </a>
                    </td>
                    <td className="w-1">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.props.edit(d.key)
                        }} className="btn btn-primary btn-sm" data-toggle="modal" data-target="#modal-linked">
                            {lang.t('buttons.edit')}
                        </a>
                    </td>
                    <td className="w-1">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.props.delete(d.key)
                        }} className="btn btn-red btn-sm">
                            {lang.t('buttons.delete')}
                        </a>
                    </td>
                </tr>
            );
        }

        return (
            <SuperTable 
                data={data}
                headers={headers}
                Cells={Cells}
                auto_sort={true}
                lang={lang}
            />
        );
    }
}

export default LinkedTable;
