import {
    round,
    sortBy,
    groupBy,
    keys,
    min,
    max,
} from 'lodash';

const defaultRisks = {

    //Drivers
    drivers_age_min: {
        type: 'int',
        category: 'drivers',
        desc: 'Min age of all drivers',
        query: 'drivers.map(dobYY).min()'
    },
    drivers_age_max: {
        type: 'int',
        desc: 'Max age of all drivers',
        category: 'drivers',
        query: 'drivers.map(dobYY).max()',
    },
    drivers_age_range: {
        type: 'int',
        desc: 'Range of ages of all drivers',
        category: 'drivers',
        query: 'drivers.map(dobYY).range()',
    },
    drivers_licence_min: {
        type: 'int',
        desc: 'Min licence held of drivers (years)',
        category: 'drivers',
        query: 'drivers.map(licenceYY).min()',
    },
    drivers_licence_max: {
        type: 'int',
        desc: 'Max licence held of drivers (years)',
        category: 'drivers',
        query: 'drivers.map(licenceYY).max()',
    },
    drivers_residency_min: {
        type: 'int',
        desc: 'Min residency held of drivers (years)',
        category: 'drivers',
        query: 'drivers.map(residencyYY).min()',
    },
    drivers_residency_max: {
        type: 'int',
        desc: 'Max residency held of drivers (years)',
        category: 'drivers',
        query: 'drivers.map(residencyYY).max()',
    },
    drivers_insurance_rels: {
        type: 'str',
        desc: 'Driver relationship (i.e. IS)',
        category: 'drivers',
        query: 'policy.insurance_relationships',
    },
    drivers_employed: {
        type: 'int',
        desc: 'Count of employed drivers',
        category: 'drivers',
        query: 'drivers.filter(employment=E).count()',
    },
    drivers_id_max: {
        type: 'int',
        desc: 'Max ID score of drivers',
        category: 'drivers',
        query: 'drivers.map(id_score).max()',
    },
    drivers_id_min: {
        type: 'int',
        desc: 'Min ID score of drivers',
        category: 'drivers',
        query: 'drivers.map(id_score).max()',
    },
    drivers_credit_min: {
        type: 'int',
        desc: 'Min credit score of drivers',
        category: 'drivers',
        query: 'drivers.map(id_score).min()',
    },
    drivers_credit_max: {
        type: 'int',
        desc: 'Max credit score of drivers',
        category: 'drivers',
        query: 'drivers.map(id_score).max()',
    },
    drivers_fault_claims: {
        type: 'int',
        desc: 'Count all fault claims of drivers',
        category: 'drivers',
        query: 'drivers.map(claims).filter(at_fault=true).count()',
    },
    drivers_all_claims: {
        type: 'int',
        desc: 'Count all claims of drivers',
        category: 'drivers',
        query: 'drivers.map(claims).filter(at_fault=true).count()',
    },
    drivers_convictions: {
        type: 'int',
        desc: 'Count convictions of drivers',
        category: 'drivers',
        query: 'drivers.map(claims).filter(at_fault=true).count()',
    },

    //Vehicle
    vehicle_value: {
        type: 'int',
        desc: 'Vehicle value',
        category: 'vehicle',
        query: 'vehicle.current_value',
    },
    vehicle_manufacture_age: {
        type: 'int',
        desc: 'Vehicle age (years)',
        category: 'vehicle',
        query: 'vehicle.manufacture_dateYY',
    },
    vehicle_purchase_age: {
        type: 'int',
        desc: 'Vehicle owned since (years)',
        category: 'vehicle',
        query: 'vehicle.purchase_dateYY',
    },
    vehicle_make: {
        type: 'str',
        desc: 'Vehicle make',
        category: 'vehicle',
        query: 'vehicle.make',
    },
    vehicle_rating: {
        type: 'int',
        desc: 'Vehicle rating',
        category: 'vehicle',
        query: 'vehicle.rating',
    },
    vehicle_engine: {
        type: 'int',
        desc: 'Vehicle engine',
        category: 'vehicle',
        query: 'vehicle.engine',
    },
    vehicle_seats: {
        type: 'int',
        desc: 'Vehicle seats',
        category: 'vehicle',
        query: 'vehicle.seats',
    },
    vehicle_ncd: {
        type: 'int',
        desc: 'Vehicle NCD',
        category: 'vehicle',
        query: 'vehicle.ncd',
    },
    vehicle_ncd: {
        type: 'int',
        desc: 'Vehicle engine',
        category: 'vehicle',
        query: 'vehicle.ncd',
    },
    vehicle_mods: {
        type: 'int',
        desc: 'Vehicle modifications count',
        category: 'vehicle',
        query: 'vehicle.modifications.count()',
    },
    vehicle_type: {
        type: 'str',
        desc: 'Vehicle body type',
        category: 'vehicle',
        query: 'vehicle.type',
    },
    vehicle_owner: {
        type: 'str',
        desc: 'Vehicle owner',
        category: 'vehicle',
        query: 'vehicle.owner',
    },

    //Policy

    policy_postcode_area: {
        type: 'str',
        desc: 'Policy postcode area',
        category: 'policy',
        query: 'policy.address.postcode_area',
    },
    policy_days_to_incept: {
        type: 'int',
        desc: 'Policy days to inception',
        category: 'policy',
        query: 'policy.days_to_inception',
    },
    policy_actual_mileage: {
        type: 'int',
        desc: 'Policy actual mileage',
        category: 'policy',
        query: 'vehicle.mileage',
    },
    policy_estimated_mileage: {
        type: 'int',
        desc: 'Policy estimated mileage',
        category: 'policy',
        query: 'vehicle.estimated_mileage',
    },

}

const defaultCohorts = {
    confidence: 95.0,
    ave_freq: 5.8,
    min_freq: 4.0,
    min_lr: 50.0,
    min_exposure: 1000,
    max_freq: 8.0,
    max_lr: 100.0,
    max_exposure: 1000,
    step_factor: 10.0,
}

/** 
 * This creates the default exposure ranges and risk factor buckets
 * and ignores infrequent risk factors 
*/

function cohortsConfig(selected_risks = {}, data = [], selected_cohorts = {}) {
    const config = {};

    const riskItems = Object.keys(selected_risks)
        .filter(r => selected_risks[r].active === true)
        .map(r => ({
            ...selected_risks[r],
            key: r,
        }));

    riskItems.filter(r => r.type === 'int').forEach(risk => {
        const max_value = max(data.map(d => d[risk.key]));

        let dp = 0
        if(max_value > 5000) {
            dp = -3;
        } else if(max_value > 1000) {
            dp = -2;
        } else if(max_value > 100) {
            dp = -1;
        } else {
            dp = 0;
        }

        const chunk = data.map(d => {
            return round(d[risk.key], dp);
        });

        const grouped = groupBy(chunk);

        let values = keys(grouped).filter(v => {
            // Return frequent values if sample size big enough
            if(grouped[v].length >= 10 || data.length < 1000) return v;
        });

        values = sortBy(values.map(g => round(g, 0)));

        function minStep (range) {
            // 20% of range
            return max([round(range * ((selected_cohorts.step_factor || defaultCohorts.step_factor) / 100), dp), 1])
        }

        config[risk.key] = {
            type: 'int',
            max: max(values),
            min: min(values),
            values,
            min_step: minStep(max(values) - min(values)),
            query: `.filter(${risk.key}>=::${risk.key}_curr).filter(${risk.key}<=::${risk.key}_next)`
        }
    });
    riskItems.filter(r => r.type === 'str').forEach(risk => {
        const grouped = groupBy(data, risk.key);
        // Return frequent values if sample size big enough
        let values = keys(grouped).filter(v => {
            if(grouped[v].length >= 10 || data.length < 1000) return v;
        });

        config[risk.key] = {
            type: 'str',
            values,
            query: `.filter(${risk.key}=::${risk.key}_curr)`
        }
    });

    return config;
}

function riskCombos(selected_risks) {
    const arrIterables = [];
    const arr_selected_risks = Object.keys(selected_risks)
        .filter(sr => selected_risks[sr].active === true);

    // Creates 1D and 2D array of risk combinations
    arr_selected_risks.forEach((s, i) => {
        arrIterables.push([
            s, 
        ]);
        const rest = arr_selected_risks.slice(i + 1, arr_selected_risks.length);
        rest.forEach(r => {
            arrIterables.push([
                s, 
                r,
            ]);
        });
    });

    return arrIterables;
}

function cohortCombos(selected_risks, risks, selected_cohorts) {
    const rCombos = riskCombos(selected_risks);
    const config = cohortsConfig(selected_risks, risks, selected_cohorts);
    let total = 0; 
    rCombos.forEach(rArr => {
        let multiple = 1;
        rArr.forEach(risk => {
            if (config[risk].type === 'str') return multiple = multiple * config[risk].values.length;
            const value = round((config[risk].max - config[risk].min) / config[risk].min_step, 0);
            return multiple = value * multiple;
        });
        total = total + multiple;
    });
    return total;
}

export {
    riskCombos,
    cohortCombos,
    cohortsConfig,
    defaultRisks,
    defaultCohorts,
}