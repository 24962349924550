import React, { Component } from 'react';
import {
    get, isEmpty, sample, round, max,
} from 'lodash';
import moment from 'moment';

import { getLangSource } from '../../../lang/index';
import main from '../../../hoc/main';

import {
    idScoreColourScale,
    creditScoreColourScale,
    toUpper,
} from '../../../utils/format';

import getData from '../../../utils/getData';
import { getId } from '../../../utils/url';

// Core Design
import SpinnerBody from '../../../components/loaders/SpinnerBody';
import Empty from '../../../components/empty/Results';
import CodePre from '../../../components/code/Pre';

// Service Design
import Score from '../components/tables/Score';
import AccountsTab from '../components/tabs/Accounts';

class IdentityDetail extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { history } = this.props;
        const id = getId(history);
        this.props.payments_identitys_get({
            id,
        });
    }

    render() {
        const lang = getLangSource(this.props.lang);
        const { isFetching, selected } = getData(this.props.payments_identitys);
        const trans_balances = get(selected, 'raw.trans', []).map(t => round(get(t, 'Balance.Amount.Amount', '0.00'), 2));
        const max_balance = max(trans_balances);

        const creditReport = get(selected, 'creditReport', [])
        const totalBalance = creditReport.balances

        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    {/* Loader */}
                    {isFetching && <div className="row"><SpinnerBody /></div>}

                    {/* If 404 */}
                    {!isFetching && isEmpty(selected)
                        && (
                            <div className="row">
                                <Empty
                                    label="identity"
                                    lang={lang}
                                />
                            </div>
                        )}

                    {/* Header Details */}
                    {!isFetching && !isEmpty(selected)
                        && (
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="card">
                                        <div className="table-responsive">
                                            <table className="table table-vcenter table-mobile-md card-table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Provider</th>
                                                        <th>Accounts</th>
                                                        <th>ID Score</th>
                                                        <th>Credit Score</th>
                                                        <th>Created</th>
                                                        <th className="w-1" />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-label="Name">
                                                            <div className="d-flex lh-sm py-1 align-items-center">
                                                                <div className="flex-fill">
                                                                    <div className="strong">{selected.account_holder_name}</div>
                                                                    <div className="text-muted text-h5">{toUpper(selected.account_holder_postcode)}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td data-label="Provider">
                                                            <div>
                                                                {toUpper(selected.name)} {selected.environment}
                                                            </div>
                                                            <div className="text-muted text-h5">{selected.ref}</div>
                                                        </td>
                                                        <td data-label="Accounts">
                                                            <div>
                                                                {selected.no_of_accounts}  Accounts
                                                            </div>
                                                            <div className="text-muted text-h5">
                                                                {selected.no_of_transactions} Transactions
                                                            </div>
                                                        </td>
                                                        <td style={{ width: 80 }} data-label="ID">
                                                            {selected.id_score}
                                                        </td>
                                                        <td style={{ width: 100 }} data-label="CreditScore">
                                                            {creditReport.score}
                                                        </td>
                                                        <td className="w-120" data-label="Date">
                                                            <div className="text-muted text-h5">{moment(selected.created_at).format('DD/MM/YY - HH:mm')}</div>
                                                        </td>
                                                        {/*
                                                        <td className="w-120">
                                                            <div className="btn-list flex-nowrap">
                                                                <a href="#" className="btn btn-primary">Refresh</a>
                                                            </div>
                                                        </td>
                                                        */}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    {/* Score Boxes */}
                    {!isFetching && !isEmpty(selected)
                        && (
                            <div className="row">
                                <div className="col-sm-4">
                                    <Score
                                        score={selected.id_score}
                                        colour={idScoreColourScale(selected.id_score)}
                                        title="ID Score"
                                        date={selected.created_at}
                                        data={[{
                                            name: 'Transactions',
                                            score: trans_balances.length,
                                            maxscore: '-',
                                            check: trans_balances.length > 10,
                                        }, {
                                            name: 'Balance',
                                            score: `£${totalBalance ? totalBalance : 0}`,
                                            maxscore: '-',
                                            check: totalBalance > 1000,
                                        }]}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Score
                                        score={creditReport.score}
                                        colour={creditScoreColourScale(creditReport.score)}
                                        title="Credit Score"
                                        date={selected.created_at}
                                        data={[{
                                            name: 'Direct Debits',
                                            score: creditReport.directdebits,
                                            maxscore: '-',
                                            check: sample([true, false]),
                                        }, {
                                            name: 'Overdraft Limits',
                                            score: creditReport.creditCardLimits,
                                            maxscore: '-',
                                            check: sample([true, false])
                                        }]}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    {!isFetching && !isEmpty(selected)
                                        && (
                                            <div className="row">
                                                <div className="col-sm-12">
                                                <CodePre
                                                    title="Raw Response"
                                                    code={selected.standardized}
                                                    />
                                                </div>
                                            </div>
                                    )}
                                </div>
                            </div>
                        )}
                    {/* Accounts and Transactions */}
                    {!isFetching && !isEmpty(selected)
                        && (
                            <div className="row">
                                <div className="col-sm-12">
                                    <AccountsTab
                                        selected={selected}
                                        balance={max_balance}
                                    />
                                </div>
                            </div>
                        )}

                </div>
            </div>
        );
    }
}

export default main(IdentityDetail);
