import React from "react"

export default function BinaryRadioGroup({
    mainLabel,
    name,
    checkedInputOne,
    checkedInputTwo,
    onChange,
    optionOneLabel,
    optionTwoLabel,
    optionOneValue,
    optionTwoValue,
}) {
    return (
        <div className="input-group mb-2">
            <div className="input-group-prepend">
                <span className="input-group-text force-border" style={{width: "140px"}}>{mainLabel}</span>
            </div>
            <div className="radio-container">
                <div className="form-check mx-2">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name={name}
                        checked={checkedInputOne}
                        onChange={(e) => onChange(JSON.parse(e.target.value))}
                        value={optionOneValue}
                    />
                    <label className="form-check-label">{optionOneLabel}</label>
                </div>
                <div className="form-check mx-2">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name={name}
                        checked={checkedInputTwo}
                        onChange={(e) => onChange(JSON.parse(e.target.value))}
                        value={optionTwoValue}
                    />
                    <label className="form-check-label">{optionTwoLabel}</label>
                </div>
            </div>
        </div>
    )
}
