import { get, find } from 'lodash';

export const formatValues = (value, type, map_list) => {

    if (type === 'number') return parseFloat(value);
    if (type === 'bool') {
        if (value == 'true') return true;
        else if (value == 'false') return false;
        return value;
    }
    if (type === 'mapped') return get(find(map_list, { value }), 'label', value); //Converting code value to verbose label value - returns original value if can't be mapped
    if (type === 'code') return get(find(map_list, { label: value }), 'value', value); //converting verbose label value to code value - returns original value if can't be mapped

    return value; //defaults to returning back existing value if can't find format type
}