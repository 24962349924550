import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { omit, isObject } from 'lodash';

function getHeaders(data = [], headers) {
    if (headers) return headers;
    if (data.length === 0) return [];
    if (!isObject(data[0])) return [];
    return Object.keys(data[0]);
}

const customStyles = {
    header: {
        style: {
            display: 'none', 
        }
    },
    headCells: {
        style: {
            background: '#35405206',
            borderWidth: 1,
            fontSize: 12,
            textTransform: 'uppercase',
            color: '#868c97',
            verticalAlign: 'bottom',
            borderBottomColor: '#868c973d',
        },
    },  
    pagination: {
        style: {
            justifyContent: 'flex-start',
        }
    }
};

class VirtualisedTable extends Component {
    constructor(props) {
        super(props);
        this.table = {};
    }
    
    render() {
        const { title, data = [], pages, ExpanableComponent } = this.props;

        const normData = data.map(d => {
            return {
                ...omit(d, 'risk'),
                ...d.risk
            }  
        });
        
        const columns = getHeaders(normData).map((h, i) => {
            let base = {
                name: h,
                selector: h,
                sortable: true,
            };
            return base;
        });

        /* Example
        const ExpanableComponent = ({ data }) => (
            <div style={{ padding: 12, fontSize: 12 }}>
                <div>{data.query}</div>
            </div>
        );
        */

        return (
            <div>
                <DataTable
                    title={title}
                    columns={columns}
                    data={normData}
                    pagination={pages ? true : false}
                    paginationPerPage={pages}
                    fixedHeader={true}
                    striped={true}
                    dense={true}
                    customStyles={customStyles}
                    expandableRows={ExpanableComponent ? true : false}
                    expandableRowsComponent={ExpanableComponent}
                />
            </div>
        );
    }
}

export default VirtualisedTable;