import React from 'react';
import Input from '../Forms/Input';

function Select (props) {
    const { 
        options = [], 
        update,
        type,
        property,
        label, 
        value, 
        disabled 
    } = props;
    return (
        <div>
            <Input
                label={label}
                property={property}
                disabled={disabled} 
                list_id={label} 
                value={value}
                type={type}
                update={update}
                options={options}
            />
            <datalist id={label}>
                {options.map(o => {
                        return (
                            <option value={o.label}></option>
                        ); 
                    }
                )}
            </datalist>
        </div>
    );
}

export default Select;