import React, { Component } from 'react';
import moment from 'moment';
import { sortBy } from 'lodash';
import { Link } from 'react-router-dom';

import { releaseBadge } from '../../../components/Shared/Badges';
import lang from '../../../../../actions/lang';

function toUpper(str = '') {
    return str?.toUpperCase();
}

class VersionsTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { versions = [], version_reference, lang } = this.props;
        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>  
                            <tr>
                                <th className="w-1">{lang.t('tableHeaders.version')}</th>
                                <th>{lang.t('tableHeaders.version')}</th>
                                <th>{lang.t('tableHeaders.status')}</th>
                                <th>{lang.t('tableHeaders.lastUpdated')}</th>
                                <th>{lang.t('tableHeaders.published')}</th>
                                <th className="w-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortBy(versions, v => -v.version).map((d, i) => {
                                const is_selected = (d.version_reference === version_reference);
                                return (
                                    <tr key={`factors-${i}`} className={`${is_selected ? 'version-selected' : ''}`}>
                                        <td data-label={lang.t('tableHeaders.value')}>  
                                            <div className="text-h5"><div style={{color: (is_selected? '' : 'black')}} className={`badge ${is_selected ? 'bg-blue' : 'bg-white'}`}>{d.version}</div></div>
                                        </td>
                                        <td data-label={lang.t('tableHeaders.name')}>
                                            <div className="d-flex lh-sm py-1 align-items-center">
                                                <div className="flex-fill">
                                                    <div className="strong" style={{color: (is_selected? 'black' : '')}}>{toUpper(d.name)}</div>
                                                    <div className="text-muted text-h5">{d.version_reference}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-label={lang.t('tableHeaders.status')}>
                                            {releaseBadge({
                                                status: d.status,
                                                published_at: d.published_at,
                                            })}
                                        </td>
                                        <td data-label={lang.t('tableHeaders.updated')}>
                                            <div className="text-muted text-h5">{moment(d.updated_at).format('DD/MM/YY - HH:ss')}</div>
                                        </td>
                                        <td data-label={lang.t('tableHeaders.published')}>
                                            <div className="text-muted text-h5">{d.published_at ? moment(d.published_at).format('DD/MM/YY - HH:ss') : '-'}</div>
                                        </td>
                                        <td>
                                            <Link
                                                to={`/rating/models/${d.version_reference}`}
                                                onClick={() => {
                                                    window.location.href=`/rating/models/${d.version_reference}`;
                                                }}
                                                className="btn btn-primary btn-sm"
                                            >{lang.t('buttons.edit')}</Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default VersionsTable;