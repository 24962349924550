import React, { Component } from 'react';
import { round, sum, times } from 'lodash';

function map_table({
    usage_rate = 0,
    upfront_rate = 0,
    estimated_distance,
}) {
    let data = [];
    times(13, (i) => {
        const distance = i * 1000
        const usage = round(distance * round(usage_rate, 3), 2);
        const upfront = round(upfront_rate, 2);
        const selected = (i * 1000) >= round(estimated_distance, 0) && ((i - 1) * 1000) < round(estimated_distance, 0);
        data.push({
            selected,
            distance,
            usage,
            upfront,
            total: round(usage + upfront, 2)
        })
    });
    return data;
}

class QuoteExampleTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = {}, header, lang } = this.props;
        return (
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                        {header} 
                    </h3>
                </div>
                <table className="table card-table table-vcenter">
                    <thead>
                        <tr>
                            <th>{lang.t('tableHeaders.distance')}</th>
                            <th>{lang.t('tableHeaders.upfront')}</th>
                            <th>{lang.t('tableHeaders.usage')}</th>
                            <th>{lang.t('tableHeaders.total')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {map_table(data).map(d => {
                            return (
                                <tr style={{ backgroundColor: d.selected ? '#5eba00' : ''}}>
                                    <td>{d.distance}</td>
                                    <td>{d.upfront}</td>
                                    <td>{d.usage}</td>
                                    <td>{d.total}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

        );
    }
}

export default QuoteExampleTable;