import React, { Component } from 'react';
import FormUsage from '../Forms/FormUsage';
import AddSuccess from '../../../../../components/modal/AddSuccess';

class ModalAddUsage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usage: {
                type: null,
                description: null,
                distance_in_metres: null,
                activated_at: null,
            },
            can_submit: false,
            stage: 'add_usage_form',
        };

        this.close = this.close.bind(this);
        this.updateUsageInfo = this.updateUsageInfo.bind(this);
        this.updateStage = this.updateStage.bind(this);
    }

    close() {
        this.props.set_modal_open({
            modal_open: false,
        });
        this.setState({
            usage: {},
            can_submit: false,
            stage: 'add_usage_form',
        });
    }

    updateUsageInfo(item) {
        this.setState({
            usage: {
                ...this.state.usage,
                ...item,
            }
        }, () => {
            const { type, description, distance_in_metres } = this.state.usage;
            if (type && description && distance_in_metres) {
                this.setState({ can_submit: true });
            } else {
                this.setState({ can_submit: false })
            }
        });
    }

    updateStage(stage) {
        this.setState({
            stage,
        })
    }

    render() {
        const { open, detail, policys_usages_post, timezone, lang } = this.props;
        const { usage, can_submit, stage } = this.state;
        
        return(
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-add-custom" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header theme-dark" >
                            <h5 className="modal-title">{lang.t('policy.policies.addUsage')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        {stage === 'add_usage_form' &&
                            <FormUsage
                                detail={detail}
                                usage={usage}
                                can_submit={can_submit}
                                update={this.updateUsageInfo}
                                updateStage={this.updateStage}
                                policys_usages_post={policys_usages_post}
                                timezone={timezone}
                                lang={lang}
                            />
                        }
                        {stage === 'add_usage_success' &&
                            <AddSuccess
                                close={this.close}
                                name={lang.t('policy.policies.usage')}
                                lang={lang}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalAddUsage;
