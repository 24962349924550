import React from 'react';

function Modal(props) {
    const { 
        open,
        modalToggle, 
        action, 
        id, 
        modalTitle, 
        modalText,
        theme,
        lang,
    } = props;

        return(
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-exclusion" tabindex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`modal-content ${theme === "dark" ? "theme-dark" : ''}`}>
                        <div className="modal-header">
                            <h5 className="modal-title">{modalTitle}</h5>
                            <button type="button" onClick={modalToggle} className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div style={{ textAlign: 'center', fontSize: '50px' }}>
                                <i style={{color: `${theme === "dark" ? '#fff' : '#354052'}`}} className="fe fe-trash-2" />
                                <h4 style={{color: `${theme === "dark" ? '#fff' : '#354052'}`}}>{modalText}</h4>
                            </div>
                            
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" onClick={e => {
                                e.preventDefault();
                                action(id);
                            }}
                                >{lang.t('components.modal.ok').toUpperCase()}</button>
                            <button className="btn btn-light" onClick={modalToggle}>{lang.t('components.modal.discard')}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

export default Modal;