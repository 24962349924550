import {
    groupBy,
    each,
    round,
    uniqBy,
    get,
    sortBy,
} from 'lodash';

import moment from 'moment';

export function toUpper(str = '') {
    return str?.toUpperCase();
}

//Maps model uuid to name
export function modelLabelMap(models = []) {
    const obj = {};
    models.forEach(m => {
        obj[m.model_reference] = m.name; 
    });
    return obj;
}

// Makes the correct x-axis label for different ranges
export function chartDateFormat(range) {
    const map = {
        'hour': 'HH:mm',
        'day': 'HH:mm',
        'week': 'dd/MM',
        'month': 'dd/MM',
    }
    return map[range] || 'HH:mm';
}

// For making chart annotation for release
export function chartVersionAnnotations(versions = [], sorted = []) {
    const annotations = versions.map(v => {
        const exists = v.published_at !== null;
        const timestamp = moment(v.published_at).format('X');
        let nearest_timestamp = null;
        sorted.forEach((s, i) => {
            const next_timestamp = sorted[(i + 1)];
            if (exists && 
                timestamp >= round(s.timestamp, 0) && 
                next_timestamp && 
                timestamp < round(next_timestamp.timestamp, 0)
            ) {
                nearest_timestamp = s.timestamp;
            }
        });
        return {
            ...v,
            exists,
            timestamp,
            nearest_timestamp,
        }
    })
    .filter(v => {
        return v.exists && v.nearest_timestamp;
    });

    const grouped = groupBy(annotations, 'nearest_timestamp');
    const results = [];
    each(grouped, (g, k) => {
        results.push({
            label: `version ${g.map(g => g.version).join(',')}`,
            timestamp:  new Date(moment(k, 'X').toISOString()).getTime(),
        })
    });
    return results;
}

//model get(this.props, 'pricing_models.data', [])
//anayltics get(this.props, 'pricing_analytics.selected', {}); 
export function analyticsDataFormat({
    analytics, 
    models,
}) {
    const sorted = sortBy(get(analytics, 'analytics.segments', []), 'timestamp');
    const timestamps = sorted.map(d => (d.timestamp));
    const sources = get(analytics, 'analytics.sources', []);
    const annotations = chartVersionAnnotations(get(analytics, 'versions', []), sorted);
    const format = chartDateFormat(get(analytics, 'analytics.range'));
    return {
        analytics_sorted: sorted,
        analytics_sources: sources,
        chart_timestamps: timestamps,
        chart_annotations: annotations,
        chart_format: format,
        analytics_models: modelLabelMap(uniqBy(models, 'model_reference')),
        models: uniqBy(models, 'model_reference'),
    }
}