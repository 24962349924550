import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import main from '../../../../hoc/main';
import { times, random, round, first } from 'lodash';
import moment from 'moment';
import { chartDateFormat } from '../../../../utils/format';

// Core Design
import SpinnerBody from '../../../../components/loaders/SpinnerBody';
import DropDown from '../../../../components/forms/Dropdown';

// Service Design
import RevenueTable from '../../components/Analytics/RevenueTable';
import SalesTable from '../../components/Analytics/SalesTable';
import TrafficChart from '../../components/Analytics/TrafficChart';
import StatChart from '../../components/Analytics/StatChart';

class PolicyDash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            range: 'month',
            //pivot: 'all',
        }
        this.selectPivot = this.selectPivot.bind(this);
        this.selectRange = this.selectRange.bind(this);
        this.getData = this.getData.bind(this);
    }

    componentWillMount() {
        this.getData();
    }

    getData() {
        this.props.policys_analytics_get({
            id: 'policys',
            period: this.state.range,
        })

        if(!this.props.policys_products.data) {
            this.props.policys_products_get_all({});
        }
    }

    selectPivot (pivot) {
        this.setState({
            pivot,
        })
    }

    selectRange ({ range = '' }) {
        this.setState({
            range: range.toLowerCase(),
        }, () => {
            this.getData();
        })
    }

    render() {
        const {
            isFetching = true,
            selected = {},
        } = this.props.policys_analytics;
        
        const { range = '', pivot = '' } = this.state;
        const { segments = [], total = {} } = selected

        const chart_format = chartDateFormat(range);
        
        const { data: products = [] } = this.props.policys_products;
        // Checking only the currency of the first product. assume all products of a company uses same currency
        const currency = products.length !== 0 ? first(products).currency : 'GBP';
        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-sm-12 col-lg-6">
                            <div className="page-pretitle">{lang.t('policy.title')}</div>
                            <h2 className="page-title">{lang.t('policy.policies.dashboard')}</h2>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            {/*
                            <div className="form-label">Pivot:</div>
                            <select 
                                onChange={(e) => {
                                    this.selectPivot(e.target.value);
                                }} 
                                ref={'Pivot'} 
                                className={`form-select custom-select`}>
                                    <option value='All'>All</option>
                                    <optgroup label="Channel">
                                        <option value='pcw'>PCW</option>
                                        <option value='direct'>Direct</option>
                                    </optgroup>
                                    <optgroup label="Source">
                                        <option value='msm'>MoneySuperMarket</option>
                                        <option value='ctm'>Compare The Market</option>
                                        <option value='confused'>Confused</option>
                                        <option value='goco'>Go Compare</option>
                                        <option value='brochure'>Brochure</option>
                                    </optgroup>
                                    <optgroup label="Underwriter">
                                        <option value='zurich'>Zurich</option>
                                        <option value='axa'>AXA</option>
                                        <option value='muslanne'>Mulsanne</option>
                                        <option value='lpa'>LPA</option>
                                    </optgroup>
                                    <optgroup label="Product">
                                        <option value='pbm'>Pay-By-Mile</option>
                                        <option value='monthly'>Monthly</option>
                                        <option value='vanilla'>Vanilla</option>
                                    </optgroup>
                            </select>
                            */}
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            <div className="form-label">{lang.t('policy.policies.range')}</div>
                            <DropDown
                                ref='range'
                                label='range'
                                value={range}
                                update={(range) => {
                                    this.selectRange(range);
                                }}
                                items={['day', 'week', 'month']}
                                labelMap={{
                                    'day': lang.t('time.day'), 
                                    'week': lang.t('time.week'), 
                                    'month': lang.t('time.month'),
                                }}
                            />
                        </div>
                    </div>
                    
                    {/* Loader */}
                    {isFetching && <SpinnerBody />}

                    {!isFetching && segments.length > 0 && 
                        <div className="row">
                            <div className="col-sm-9 col-lg-9">
                                <div className="card">
                                    <div className="ml-3 mr-3 mt-3">
                                        <TrafficChart 
                                            series={[
                                                {
                                                    name: lang.t('policy.policies.sold'),
                                                    data: segments.map(d => d.nb_count || 0),
                                                },
                                                {
                                                    name: lang.t('policy.policies.cancelled'),
                                                    data: segments.map(d => d.cn_count || 0),
                                                },
                                            ]}
                                            annotations={[]}
                                            labels={segments.map(t => {
                                                return moment(t.timestamp, 'X').toISOString();
                                            })}
                                            format={chart_format}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-lg-3">
                                <StatChart 
                                    series={[
                                        {
                                            name: lang.t('policy.policies.premium'),
                                            data: segments.map(d => d.upfront_premium || 0),
                                        },
                                        {
                                            name: lang.t('policy.policies.refunds'),
                                            data: segments.map(d => d.refund_premium || 0),
                                        },
                                    ]}
                                    name={lang.t('policy.policies.premium')}
                                    labels={segments.map(t => {
                                        return moment(t.timestamp, 'X').toISOString();
                                    })}
                                    format={chart_format}
                                    currency={currency}
                                />
                                <StatChart 
                                    series={[
                                        {
                                            name: lang.t('policy.policies.fees'),
                                            data: segments.map(d => d.admin_fee || 0),
                                        },
                                    ]}
                                    name={lang.t('policy.policies.fees')}
                                    labels={segments.map(t => {
                                        return moment(t.timestamp, 'X').toISOString();
                                    })}
                                    format={chart_format}
                                    currency={currency}
                                />
                            </div>
                        </div>
                    }

                    {!isFetching &&
                        <div className="row">
                            <div className="col-sm-12 col-lg-6">
                                <SalesTable 
                                    segments={segments}
                                    total={total}
                                    lang={lang}
                                />
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <RevenueTable 
                                    segments={segments}
                                    currency={currency}
                                    lang={lang}
                                />
                            </div>
                        </div>

                        
                    }
                </div>
            </div>
        );
    }
}

export default main(PolicyDash);