import React, { Component } from 'react';
import { startCase } from 'lodash';
import { getLangSource } from '../../../lang';
import { uniqBy } from 'lodash';

import main from '../../../hoc/main';
import SpinnerBody from '../../../components/loaders/SpinnerBody';

import QuotesTable from '../components/Quotes/QuotesTable';

class Quotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this.search = this.search.bind(this);
    }

    componentDidMount() {
        this.props.pricing_quotes_get_all({ limit: 100 });
    }

    search(term) {
        this.setState({ error: '' });
        if (term === '') {
            this.props.pricing_quotes_get_all({ 
                limit: 100,
            });
        }
        const is_uuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(term);
        if (!is_uuid) {
            this.setState({
                error: `${term} is not correct quote reference format`
            })
        } else {
            this.props.pricing_quotes_get_all({ 
                search: term,
            });
        }
    }

    render() {
        const {
            pricing_quotes: {
                isFetching = true,
                selected = {},
                data = [],
            },
            lang: language,
        } = this.props;

        const { error, open } = this.state;

        const quotes = uniqBy(data, d => d.quote_reference);
        const lang = getLangSource(language);
        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    {isFetching && 
                        <div className="row">
                            <SpinnerBody 
                                message={lang.t('rating.logs.retrieving')}
                                selectedProduct={this.props.selectedProduct}
                            />
                        </div>
                    }

                    {!isFetching &&
                    <div>
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <div className="page-pretitle">{lang.t('rating.title')}</div>
                                    <h2 className="page-title">{startCase(lang.t('rating.logs.quoteLogs'))}</h2>
                                </div>
                                <div className="col-3">
                                </div>
                                <div className="col-auto ml-auto">
                                </div>
                            </div>
                        </div>

                        {(!isFetching && error) &&
                            <div className="alert alert-danger" role="alert">
                                <i className="fe fe-info" /> {error}
                            </div>
                        }

                        <div className="row">
                            <div className="col-lg-12">
                                <QuotesTable
                                    data={quotes}
                                    search={this.search}
                                    modalToggle={this.modalToggle}
                                    deleteQuote={this.deleteQuote}
                                    lang={lang}
                                />
                            </div>
                        </div>
                    </div>
                    }

                </div>
            </div>
        );
    }
}

export default main(Quotes);