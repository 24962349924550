import React, { Component } from 'react';
import InputCustom from './InputCustom';
import { omit, get } from 'lodash';

class FormCustom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFilter: true,
        }

        this.update = this.update.bind(this);
        this.open = this.open.bind(this);
        this.delete = this.delete.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
    }

    update(item) {
        const { detail } = this.props;

        const result = {
            ...detail,
            custom: {
                ...detail.custom,
                ...item,
            }
        };
        this.props.update(result);
    }

    open() {
        this.props.modal_open({
            add_custom: {
                open: true,
            }
        })
    }

    delete(item) {
        const { detail, update } = this.props;

        const new_custom = omit(detail.custom, item);

        update({
            ...detail,
            custom: new_custom,
        });
    }

    toggleFilter() {
        this.setState({ isFilter: !this.state.isFilter });
    }

    render() {
        const { detail, disabled, lang } = this.props;
        const { isFilter } = this.state;
        const customKeys = Object.keys(detail.custom || {}) ;
        const lengthOfCustom = customKeys.length;
        const customFieldsToShow = 5;
        return (
            <div className="card ml-2">
                    <div className="card-header">
                        <h3 className="card-title">{lang.t('policy.policies.custom')}</h3>
                        {lengthOfCustom > customFieldsToShow &&
                            <button 
                                className="btn btn-secondary btn-sm"
                                style={{ position: 'absolute', right: `${disabled ? '16px' : '160px'}` }}
                                onClick={this.toggleFilter}
                            >
                                {isFilter ? 'Show all' : 'Collapse'}
                            </button>
                        }
                        
                        {!disabled && 
                            <button 
                                className="btn btn-primary btn-sm" 
                                style={{ position: 'absolute', right: '16px' }}
                                onClick={this.open}
                            >
                                + {lang.t('policy.policies.addCustomField')}
                            </button>}
                    </div>
                    <div className="card-body">
                        <div className="mb-1">
                            {Object.keys(get(detail, 'custom', {}))
                                .filter((item, index) => {
                                    if (isFilter) {
                                        return index < customFieldsToShow // zero indexed
                                    } else {
                                        return item;
                                    }
                                })
                                .map(item => {
                                    return (
                                        <div className="row">
                                            <div className={`${disabled ? 'col' : 'col-11'} `}>
                                                <InputCustom
                                                    label={item}
                                                    property={item}
                                                    value={detail.custom[item]}
                                                    update={this.update}
                                                    type='text'
                                                    disabled={disabled}
                                                    key={`custom-${item}`}
                                                />
                                            </div>
                                            {!disabled &&
                                                <div className="col-1 mt-3"  style={{ display: 'flex' }}>
                                                    <a className="btn btn-sm p-0" onClick={() => this.delete(item)} style={{ boxShadow: 'none' }}>
                                                        <i className="fe fe-trash-2 text-danger" style={{ fontSize: '15px' }}></i>
                                                    </a>
                                                </div>}
                                            
                                            
                                        </div>
                                    
                                    );
                            })}
                        </div>
                    </div>
                </div>
        );
    }
}

export default FormCustom;