import React, { Component } from 'react';
import { last, startCase } from 'lodash';
import Empty from '../../../../components/empty/Results';
import TableClaimVersion from './TableClaimVersion';
import ModalAddClaimVersion from './ModalAddClaimVersion';

export default class ClaimVersions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalAddVisible: false,
            isError: false
        }
        this.toggleAddModal = this.toggleAddModal.bind(this);
        this.setIsError = this.setIsError.bind(this);
    }
    toggleAddModal() {
        this.setState({
            modalAddVisible: !this.state.modalAddVisible,
            isError: false
        })
    }
    setIsError(value) {
        this.setState({
            isError: value
        })
    }
    render() {
        const {
            claimVersions=[],
            timezone,
            policy_reference,
            claim_reference,
            policys_claims_put,
            refreshClaimData,
            lang,
        } = this.props;

        const { isError } = this.state;
    
        return (
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title'>{lang.t('policy.claims.claimVersions')}</h3>
                    <a className="btn btn-sm btn-primary text-white ml-auto" style={{
                        position: 'absolute',
                        top: '12px',
                        right: '16px'
                    }}
                    onClick={this.toggleAddModal}
                    >+ {startCase(lang.t('policy.claims.addClaimVersion'))}</a>
                </div>
                <div className='card-body'>
                    {
                        claimVersions.length === 0 && <Empty label='claim versions' lang={lang} />
                    }
                    {
                        claimVersions.length > 0 && <TableClaimVersion claimVersions={claimVersions} timezone={timezone} lang={lang} />
                    }
                </div>
                <ModalAddClaimVersion
                    open={this.state.modalAddVisible}
                    toggleModal={this.toggleAddModal}
                    policy_reference={policy_reference}
                    claim_reference={claim_reference}
                    policys_claims_put={policys_claims_put}
                    refreshClaimData={refreshClaimData}
                    latestClaimVersion={last(claimVersions)}
                    isError={isError}
                    setIsError={this.setIsError}
                    lang={lang}
                />
            </div>
        )
    }
}
