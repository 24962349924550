import { combineReducers } from 'redux';

import lang from './lang';
import auth from './auth';
import cognito from './cognito';
import environment from './environment';

//autocrat
import settings_users from '../services/autocrat/reducers/users';
import settings_companys from '../services/autocrat/reducers/companys';
import settings_superaccess from '../services/autocrat/reducers/superaccess';

//payments
import payments_dashboard from '../services/payments/reducers/dashboard';
import payments_identitys from '../services/payments/reducers/identitys';
import payments_settings from '../services/payments/reducers/settings';

//claims
import claims_dashboard from '../services/claims/reducers/factors';
import claims_identitys from '../services/claims/reducers/jobs';
import claims_settings from '../services/claims/reducers/policys';
import claims_reports from '../services/claims/reducers/reports';
import claims_risks from '../services/claims/reducers/risks';
import claims_versions from '../services/claims/reducers/versions';

//policys
import policys from '../services/policys/reducers/policys';
import policys_details from '../services/policys/reducers/policys_details';
import policys_journeys from '../services/policys/reducers/policys_journeys';
import policys_statements from '../services/policys/reducers/policys_statements';
import policys_logins from '../services/policys/reducers/policys_logins';
import policys_analytics from '../services/policys/reducers/policys_analytics';
import policys_user_logs from '../services/policys/reducers/policys_user_logs';
import policys_reporting from '../services/policys/reducers/policys_reporting';
import policys_products from '../services/policys/reducers/policys_products';
import policys_payments from '../services/policys/reducers/policys_payments';
import policys_unpaid_versions from '../services/policys/reducers/policys_unpaid_versions';
import policys_emails from '../services/policys/reducers/policys_emails';
import policys_documents from '../services/policys/reducers/policys_documents';
import policys_health from '../services/policys/reducers/policys_health';
import policys_credits from '../services/policys/reducers/policys_credits';
import policys_usages from '../services/policys/reducers/policys_usages';
import policys_kyc from '../services/policys/reducers/policys_kyc';
import policys_comms from '../services/policys/reducers/policys_comms';
import policys_claims from '../services/policys/reducers/policys_claims';
import policys_claims_documents from '../services/policys/reducers/policys_claims_documents';

//rating
import pricing_models from '../services/rating/reducers/models';
import pricing_quotes from '../services/rating/reducers/quotes';
import pricing_versions from '../services/rating/reducers/versions';
import pricing_publish from '../services/rating/reducers/publish';
import pricing_analytics from '../services/rating/reducers/analytics';
import pricing_factors from '../services/rating/reducers/factors';
import pricing_compare from '../services/rating/reducers/compare';
import pricing_upload from '../services/rating/reducers/upload';
import pricing_ml_upload from '../services/rating/reducers/upload-ml';
import pricing_errors from '../services/rating/reducers/errors';
import pricing_template from '../services/rating/reducers/template';
import pricing_billing from '../services/rating/reducers/billing';

//telematics
import telematics_devices from '../services/policys/reducers/telematics_devices';
import telematics_packets from '../services/policys/reducers/telematics_packets';
import telematics_journeys from '../services/policys/reducers/telematics_journeys';

//vehicles
import vehicles from '../services/policys/reducers/vehicles';

export default combineReducers({
    lang,
    cognito,
    environment,
    auth,

    payments_dashboard,
    payments_identitys,
    payments_settings,

    claims_dashboard,
    claims_identitys,
    claims_settings,
    claims_reports,
    claims_risks,
    claims_versions,
    
    policys,
    policys_details,
    policys_journeys,
    policys_statements,
    policys_logins,
    policys_analytics,
    policys_user_logs,
    policys_reporting,
    policys_products,
    policys_payments,
    policys_unpaid_versions,
    policys_emails,
    policys_documents,
    policys_health,
    policys_credits,
    policys_usages,
    policys_kyc,
    policys_comms,
    policys_claims,
    policys_claims_documents,

    pricing_models,
    pricing_quotes,
    pricing_versions,
    pricing_publish,
    pricing_analytics,
    pricing_factors,
    pricing_upload,
    pricing_ml_upload,
    pricing_errors,
    pricing_template,
    pricing_billing,
    pricing_compare,

    telematics_devices,
    telematics_packets,
    telematics_journeys,

    settings_companys,
    settings_users,
    settings_superaccess,

    vehicles,
});
