import React, { Component } from 'react';
import SuperTable from '../../../../../components/tables/Super';

class PolicysTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], lang } = this.props;

        const headers = [
            {
                label: 'Policy Ref',
                name: 'policy_ref',
                search: true,
            },
            {
                label: 'Quote Ref',
                name: 'quote_ref',
                search: true,
            },
            {
                label: 'Bought At',
                name: 'bought_at',
            },
            {
                label: 'Cancel At',
                name: 'cancel_at',
            },
            {
                label: 'Claims',
                name: 'claims_count',
            },
            {
                label: 'Claims Amount',
                name: 'claims_amount',
            },
        ];

        const Cells = (index, d) => {
            return (
                <tr key={`reports-${index}`}>
                    <td data-label="PolicyRef">  
                        <div className="text-h5">{d.policy_ref}</div>
                    </td>
                    <td data-label="QuoteRef">  
                        <div className="text-h5">{d.quote_ref}</div>
                    </td>
                    <td data-label="SoldAt">  
                        <div className="text-h5">{d.bought_at}</div>
                    </td>
                    <td data-label="CancelAt">  
                        <div className="text-h5">{d.cancel_at}</div>
                    </td>
                    <td data-label="Claims">  
                        <div className="text-h5">{d.claims_count}</div>
                    </td>
                    <td data-label="ClaimsAmount">  
                        <div className="text-h5">{d.claims_amount}</div>
                    </td>
                </tr>
            );
        }

        return (
            <SuperTable 
                data={data}
                headers={headers}
                Cells={Cells}
                lang={lang}
            />
        );
    }
}

export default PolicysTable;
