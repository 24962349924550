const L = require('1liner');
const { 
    uniqBy,
} = require('lodash');
const {
    accidentStats,
    chartData,
    monthlyStats,
} = require('./utils/aggregate');

export function controller ({
    risks, 
    cohorts, 
    selected_cohorts,
    selected_risks,
}) {
    const {
        min_freq,
        min_lr,
        min_exposure,
        max_freq,
        max_lr,
        max_exposure,
    } = selected_cohorts;

    const bestQueryArr = [];
    const worstQueryArr = [];
    let bestPolicyArr = [];
    let worstPolicyArr = [];

    const l = new L({
        data: risks,
    });

    cohorts.forEach(c => {
        if(c.claimsFaultFreq <= min_freq && c.LREst <= min_lr && c.exposureSumYears >= min_exposure) {
            bestQueryArr.push(c.query);
        }
        if(c.claimsFaultFreq >= max_freq && c.LREst >= max_lr && c.exposureSumYears >= max_exposure) {
            worstQueryArr.push(c.query);
        }
    });


    bestQueryArr.forEach(b => {
        const results = l.query(b);
        bestPolicyArr = uniqBy([
            ...bestPolicyArr,
            ...results,
        ], 'version');
    });
    
    worstQueryArr.forEach(b => {
        const results = l.query(b);
        worstPolicyArr = uniqBy([
            ...worstPolicyArr,
            ...results,
        ], 'version');
    });

    bestPolicyArr = bestPolicyArr
        .filter(b => !worstPolicyArr.map(w => w.version).includes(b.version));

    return {
        best: {
            risks: bestPolicyArr,
            accidents: accidentStats(bestPolicyArr),
            monthly: monthlyStats(bestPolicyArr),
            
        },
        worst: {
            risks: worstPolicyArr,
            accidents: accidentStats(worstPolicyArr),
            monthly: monthlyStats(worstPolicyArr),
        },
        all: {
            risks,
            accidents: accidentStats(risks),
            monthly: monthlyStats(risks),
        },
        charts: chartData({
            data: risks,
            best: bestPolicyArr,
            worst: worstPolicyArr,
            selected_risks,
            selected_cohorts,
        }),
    }
};

export function build ({
    risks,
    cohorts,
    selected_cohorts,
    selected_risks,
}) {
    try {
        
        const result = controller({
            selected_risks,
            risks,
            cohorts,
            selected_cohorts,
        })

        postMessage(result);

    }catch(e) {
        console.log(e);
    }
}

export default build;