import React, { Component } from 'react';
import Empty from '../../../../../components/empty/Results';

// Service Design
import DevicesTable from '../../Telematics/DevicesTable';

class DevicesSection extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { devices = [], lang } = this.props;

        return (
            <>
            {devices.length ? (
                <div className="col-sm-12 col-lg-12">
                    <DevicesTable 
                        data={devices}
                        lang={lang}
                    />
                </div>
            ) : <Empty label={lang.t('policy.telematics.devices').toLowerCase()} lang={lang} />}
            </>

        );
    }
}

export default DevicesSection;