
const moment = require('moment');
const { first } = require('lodash');

const postcode_area = (postcode = '') => {
    const letters = postcode.toUpperCase().split('');
    if (!isNaN(parseInt(letters[1]))) return letters[0];
    return letters[0] + letters[1];
};

const postcode_sector = (postcode = '') => {
    return postcode
        .toUpperCase()
        .substring(0, postcode.length - 2);
};

const insurance_relationships = (drivers) => {
    const relationships = drivers
        .filter(d => d.driver === 'ad')
        .map(m => m.relationship) || [];
    if (relationships.length === 0) return 'I';
    if (relationships.length === 1) {
        if (relationships[0] === 'S') return 'IS';
        if (relationships[0] === 'J') return 'IS';
        if (relationships[0] === 'W') return 'ICL';
        return 'I1';
    }
    if (relationships.length === 2) return 'I2';
    if (relationships.length === 3) return 'I3';
    return '-1';
};

function enrichDetail (data = {}) {
    const {
        additional_drivers = [],
        proposer = {},
        vehicle = {},
        policy = {}
    } = data;

    function addAges(label, date) {
        function __normalise_age(period = 'years') {
            return moment(policy.inception_date).diff(moment(date), period, false);
        }
        return {
            [`${label}YY`]: __normalise_age('years'),
            [`${label}MM`]: __normalise_age('months'),
            [`${label}DD`]: __normalise_age('days'),
        }
    }

    function agesDriver(driver) {
        return {
            ...driver,
            ...addAges('dob', driver.dob),
            ...addAges('licence', driver.licence_issued_date || `${driver.licence_year}-${driver.licence_month}-01`),
            ...addAges('residency', driver.residency_date ||`${driver.residency_year}-${driver.residency_month}-01`),
            claims: (driver.claims || []).map(c => {
                return {
                    ...c,
                    ...addAges('date', c.date),
                }
            }),
            convictions: (driver.convictions || []).map(c => {
                return {
                    ...c,
                    ...addAges('date', c.date),
                }
            })
        }
    }

    const drivers = [{
        ...agesDriver(proposer),
        driver: 'ph',
    }];
    (additional_drivers || []).forEach(a => {
        drivers.push({
            ...agesDriver(a),
            driver: 'ad',
        });
    });

    const spouse = first(drivers.filter(d => d.relationship === 'S' || d.relationship === 'J'));

    // Helper information added to data 
    const result = {
        drivers,
        proposer: {
            ...proposer,
            ...agesDriver(proposer),
            relationship: 'P',
        },
        spouse,
        additional_drivers,
        vehicle: {
            ...vehicle,
            ...addAges('manufacture_date', vehicle.manufacture_date),
            ...addAges('purchase_date', vehicle.purchase_date),
            postcode_area: postcode_area(vehicle.park_postcode),
            postcode_sector: postcode_sector(vehicle.park_postcode),
            ncd: proposer.ncd,
            is_kitcar: (vehicle.reg).charAt(0) === 'Q',
        },
        policy:{
            ...policy,
            days_to_inception: (addAges('', policy.created_at).DD),
            insurance_relationships: insurance_relationships(drivers),
            address: {
                ...policy.address,
                postcode_area: postcode_area(policy.address.postcode),
                postcode_sector: postcode_sector(policy.address.postcode),
            }
        },
    };

    return result;
}

export default function (detail) {
    const enriched = enrichDetail(detail);
    return enriched;
}