import React, { Component } from 'react';
import { find, reject, keys, max } from 'lodash';
import exclusionCheck from '../../../utils/exclusions';
import formulaCheck from '../../../utils/formulas';
import { updateCollection } from '../../../../../utils/manipulate';

class ModalFormula extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formula: {},
            quote: {},
            valid_quote: true,
        };
        this.update = this.update.bind(this);
        this.save = this.save.bind(this);
        this.clear = this.clear.bind(this);
    }

    componentWillMount() {
        const { name, formulas } = this.props;
        let formula = find(formulas, f => f.name === name) || {};
        this.setState({
            formula,
        });
    }

    componentWillReceiveProps(props) {
        const { name, formulas } = props;
        let formula = find(formulas, f => f.name === name) || {};
        this.setState({
            formula,
        });
    }

    update(formula) {
        this.setState({
            formula: {
                ...formula,
                proirity: 2, //defaults to 2 as it is not master formula
                filter_expression: '',
            },
        })
    }

    clear() {
        this.setState({
            formula: {},
        })
    }

    save() {
        const newFormula = {
            ...this.state.formula,
            id: this.state.formula.id || max(this.props.formulas.map(e => e.id)) + 1 || 1, //starts at 1 if not populated
        }
        const newFormulas = updateCollection(this.props.formulas, newFormula);
        this.props.update(newFormulas);
        this.clear();  
    }

    render() {
        const { open, factors, lang, status } = this.props;
        let { formula } = this.state;
        const checkFilter = exclusionCheck(formula.filter_expression, this.props.example);
        const checkFormula = formulaCheck(formula.formula_expression, keys(factors));

        return (
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-formula" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{lang.t('rating.components.formulas.modalTitle')}</h5>
                            <button type="button" onClick={this.clear} className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                            {lang.t('rating.components.formulas.modalInfo')}
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" style={{ width: 140 }}>
                                                                {lang.t('rating.components.formulas.name')}
                                                            </span>
                                                        </div>
                                                        <input onBlur={(e) => {
                                                            this.update({
                                                                ...formula,
                                                                name: e.target.value,
                                                            })
                                                        }} key={formula.name} type='text' className="form-control" placeholder={lang.t('rating.components.formulas.namePlaceholder')} defaultValue={formula.name} />
                                                    </div>
                                                    <div className="input-group mb-2">
                                                        <textarea
                                                            key={formula.formula_expression}
                                                            className="form-control"
                                                            data-toggle="autosize"
                                                            placeholder={lang.t('rating.components.formulas.expressionPlaceholder')}
                                                            style={{
                                                                background: '#354052',
                                                                borderRadius: '3px',
                                                                color: '#fff',
                                                                height: 200,
                                                            }}
                                                            onBlur={(e) => {
                                                                this.update({
                                                                    ...formula,
                                                                    formula_expression: e.target.value,
                                                                })
                                                            }}
                                                            defaultValue={formula.formula_expression}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>  
                                    <div className="card">
                                        <table className="table card-table table-vcenter mb-3">
                                            <thead>
                                                <tr>
                                                    <th>{lang.t('buttons.test')}</th>
                                                    <th>{lang.t('buttons.result')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div>
                                                            {lang.t('rating.components.formulas.validFormulaExpression')}
                                                        </div>
                                                    </td>
                                                    <td className="w-1 text-center">
                                                        {checkFormula.is_valid &&
                                                            <span className="avatar bg-green">
                                                                <i style={{color: 'white'}} className="fe fe-check"></i>
                                                            </span>
                                                        }
                                                        {!checkFormula.is_valid &&
                                                            <span className="avatar bg-red">
                                                                <i style={{color: 'white'}} className="fe fe-x"></i>
                                                            </span>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {checkFormula.queries.map(q => {
                                                            if (q.has_factor) {
                                                                return (
                                                                    <span className="badge bg-green mr-2">
                                                                        {q.query}
                                                                    </span>
                                                                )
                                                            }
                                                            return (
                                                                <span className="badge bg-red mr-2">
                                                                        {q.query}
                                                                </span>
                                                            )
                                                        })}
                                                    </td>
                                                    <td className="w-1 text-center"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/*<div className="col-lg-6">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        The formula filters will allows different formulas for different quote cohorts.
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="input-group mb-2">
                                                        <textarea
                                                            key={formula.filter_expression}
                                                            className="form-control"
                                                            data-toggle="autosize"
                                                            placeholder="Enter the formula expression"
                                                            style={{
                                                                background: '#354052',
                                                                borderRadius: '3px',
                                                                color: '#fff',
                                                            }}
                                                            onBlur={(e) => {
                                                                this.update({
                                                                    ...formula,
                                                                    filter_expression: e.target.value,
                                                                })
                                                            }}
                                                            defaultValue={formula.filter_expression}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <table className="table card-table table-vcenter">
                                            <thead>
                                                <tr>
                                                    <th>Test</th>
                                                    <th>Result</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className="td-truncate">
                                                    <div>
                                                        Valid Filter Expression
                                                    </div>
                                                </td>
                                                <td className="w-1 text-center">
                                                    {checkFilter.is_valid &&
                                                        <span className="avatar bg-green">
                                                            <i style={{color: 'white'}} className="fe fe-check"></i>
                                                        </span>
                                                    }
                                                    {!checkFilter.is_valid &&
                                                        <span className="avatar bg-red">
                                                            <i style={{color: 'white'}} className="fe fe-x"></i>
                                                        </span>
                                                    }
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                        <div className="modal-footer">
                            {checkFormula.is_valid && status == 'draft' &&
                                <button type="button" className="btn btn-primary" onClick={this.save} data-dismiss="modal">{lang.t('buttons.saveChanges')}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalFormula;