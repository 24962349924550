import React from 'react';
import numeral from 'numeral';
import { get, round } from 'lodash';
import moment from 'moment-timezone';

function RenewalSummary(props) {
    const { close, back, price_change, submit, isSubmitting, error, detail, timezone } = props;
    const {
        upfront_rate_before,
        usage_rate_before,
        subscription_rate_before,
        upfront_rate_after,
        usage_rate_after,
        subscription_rate_after,
    } = price_change;


    const effective_date = get(detail, 'policy.effective_date');

    const dateWithTimezone = moment.tz(moment.unix(effective_date).format(), timezone);

    

    return (
        <div className="modal-content">
            <div className="modal-header theme-dark">
                <h5 className="modal-title">Renewal Summary</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close} disabled={isSubmitting}> 
                    <i className="fe fe-x"></i>
                </button>
            </div>
            <div className="modal-body">
                <div className={`alert alert-${error? 'danger' : 'info'} mb-4`} role="alert">
                    <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                    {`${error ? error : 'Summary of change and amount due to the customer'}`}
                    <br />
                    <span>Effective date: <strong>{dateWithTimezone.format('DD-MM-YYYY HH:mm')} {timezone}</strong></span>
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Before</th>
                            <th scope="col"></th>
                            <th scope="col">After</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Upfront Rate</th>
                            <td>{numeral(upfront_rate_before).format('0.00')}</td>
                            <td><i className={`fe fe-chevrons-right text-dark`}></i></td>
                            <td>{numeral(upfront_rate_after).format('0.00')}</td>
                        </tr>
                        <tr>
                            <th scope="row">Usage Rate</th>
                            <td>{numeral(usage_rate_before).format('0.000')}</td>
                            <td><i className={`fe fe-chevrons-right text-dark`}></i></td>
                            <td>{numeral(usage_rate_after).format('0.000')}</td>
                        </tr>
                        <tr>
                            <th scope="row">Subscription Rate</th>
                            <td>{numeral(subscription_rate_before).format('0.00')}</td>
                            <td><i className={`fe fe-chevrons-right text-dark`}></i></td>
                            <td>{numeral(subscription_rate_after).format('0.00')}</td>
                        </tr>
                    </tbody>
                </table>
                {error && <h5 className="text-danger text-right"><i className="fe fe-alert-triangle mr-2" ></i><b>Unable to proceed due to error</b></h5>}
            </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={back} disabled={isSubmitting}>Back</button>
            {isSubmitting &&
                <button type="button" className="btn btn-primary" disabled={isSubmitting}><div className="spinner-border"/></button>
            }
            {!isSubmitting && !error &&
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={submit} disabled={isSubmitting}>Proceed</button>
            }
        </div>
    </div>
    );
}

export default RenewalSummary;