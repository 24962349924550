import { round } from 'lodash';
import L from '1liner';
import csv from 'csvtojson';
import moment from 'moment';
import enrichPolicy from './utils/enrichPolicy';
import { defaultRisks } from './utils/config';

function removeIPT(value) {
    return round(value - (value * 0.12), 2);
}

function buildRisk(selected_risks, quote) {
    const l = new L(quote);
    const risk = {};
    Object.keys(selected_risks).map(k => {
        if(selected_risks[k].active === true) {
            const item = defaultRisks[k] || {};
            if (item.type === 'int') {
                risk[k] = round(l.query(item.query), 0)
            }
            if (item.type === 'str') {
                risk[k] = (l.query(item.query) || '').toUpperCase()
            }
        }
    });
    return risk;
}

export function controller(selected_risks, json) {
    const details = JSON.parse(json.details);
    const quote = enrichPolicy({
        ...details,
        // TODO - This should be in policy details object 
        policy: {
            ...details.policy,
            inception_date: json.policy_start_date,
            created_at: json.created_at,
            reference: json.reference,
            underwriter: json.underwriter_id,
        }
    });

    let result = {
        policy_id: json.policy_id,
        policy_created_at: json.created_at,
        underwriter_id:json.underwriter_id,
        reference: json.reference,
        version:`${json.reference}-${moment(json.e_from).format('MMDD')}`,
        month: round(moment(json.policy_start_date).format('YYYYMM'), 0),
        policy_start_date: moment(json.policy_start_date).format('YYYY-MM-DD'),
        policy_end_date: moment(json.policy_end_date).format('YYYY-MM-DD'),
        effective_from: moment(json.e_from).format('YYYY-MM-DD'),
        effective_to:json.e_to !== '' ?  moment(json.e_to).format('YYYY-MM-DD') : '',
        days_exposure: round(json.days_exposure, 0),
        miles_exposure: round(json.miles, 0),
        gross_base: removeIPT(round(json.gross_base, 2)),
        gross_sub: removeIPT(round(json.gross_sub, 2)),
        earnd_sub_prem: removeIPT(round(json.earned_sub_premium, 2)),
        earnd_mile_prem: round(json.earned_mileage_premium, 2),
        earnd_total_prem: removeIPT(round(json.earned_total_premium, 2)),
        claim_f_count: round(json.claim_fault_count, 0),
        claim_f_type: json.claim_fault_type,
        claim_f_jour_type: json.claim_fault_journey_type,
        claim_f_est_amt: json.claim_fault_estimated_amount === '' ? '' : round(json.claim_fault_estimated_amount, 2),
        claim_f_inc_amt: json.claim_fault_incurred_amount === '' ? '' : round(json.claim_fault_incurred_amount, 2),
        claim_nf_count: round(json.claim_non_fault_count, 0),
        claim_nf_type: json.claim_non_fault_type,
        claim_nf_jour_type: json.claim_non_fault_journey_type,
        claim_nf_est_amt: json.claim_non_fault_estimated_amount === '' ? '' : round(json.claim_non_fault_estimated_amount, 2),
        claim_nf_inc_amt: json.claim_non_fault_incurred_amount === '' ? '' : round(json.claim_non_fault_incurred_amount, 2),
    };

    const risk = buildRisk(selected_risks, {
        ...quote,
        json,
    });

    return {
        ...result,
        ...risk,
    }
}

export function build (selected_risks, data) {
    csv()
    .fromString(data)
    .subscribe((json)=>{ 
        try {
            const result = controller(selected_risks, json);
            postMessage(result);
        }catch(e) {
            console.log(e);
        }
    },
    (e) => { console.log(e) }, 
    () => {
        postMessage({ isDone: true });
    });
}

export default build;