import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import { get, first } from 'lodash';

import main from '../../../../hoc/main';

// Core Design
import SpinnerBody from '../../../../components/loaders/SpinnerBody';
import PageHeader from '../../../../components/layout/PageHeader';
import Search from '../../../../components/search/Simple';

// Service Design
import JourneyContainer from '../../components/Journeys/Container';
import ProductsFilterDropDown from '../../components/Products/FilterDropdown';

class Journeys extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            filterTerm: '',
            page: 1,
        }
        this.filter = this.filter.bind(this);
        this.term = this.term.bind(this);
        this.update = this.update.bind(this);
    }

    async componentWillMount() {
        await this.props.policys_products_get_all({});
        await this.props.policys_journeys_get_all({
            limit: 51,
            offset: 0,
        });
    }

    filter({ product_reference }) {
        
        this.setState({
            filterTerm: product_reference,
        }, () => {
            this.search();
        })
    }

    // This doesn't work from an API at the moment
    term(term) {
        if (
            (term.length > 0 && term.length < 3) || 
            this.props.policys_journeys.isFetching
        ) {
            return;
        }

        this.setState({
            searchTerm: term,
        }, () => {
            this.search();
        })
    }

    async search() {
        const { searchTerm = '', filterTerm = '' } = this.state;
        let where = { 
            policy_reference: searchTerm,
            limit: 51,
            offset: 0,
        };

        this.setState({
            page: 1,
        });

        if (filterTerm !== '') where = { ...where, product_reference: filterTerm };
        await this.props.policys_journeys_get_all(where);
    }

    update(data) {
        this.setState(data);
    }

    render() {
        const isFetching = this.props.policys_journeys.isFetching;
        const results = this.props.policys_journeys.data || [];
        const { page, searchTerm } = this.state;
        const { data: products = [] } = this.props.policys_products;

        // Checking only the currency / timezone of the first product. assume all products of a company uses same currency / timezone
        // NOTE: will need to amend API if a company has multi currency/timezone products
        const currency = products.length !== 0 ? first(products).currency : 'GBP';
        const timezone = products.length !== 0 ? first(products).timezone : 'Europe/London';
        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    <PageHeader
                        section={lang.t('nav.policies')}
                        page={lang.t('nav.journeys')}
                    />

                    {/* Search */}
                    <div className="row">
                        <div className="col-sm-12 col-lg-8">
                            {/*
                            <ProductsFilterDropDown
                                products={this.props.policys_products}
                                update={this.filter}
                                lang={lang}
                            />
                            */}
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group" style={{marginBottom: 30 }}>
                                <label className="form-label">{lang.t('policy.policies.searchByPolicy')}</label>
                                <Search
                                    searchTerm={searchTerm}
                                    searchFunction={this.term}
                                    isFetching={isFetching}
                                    label={lang.t('policy.statements.journeys').toLowerCase()}
                                    lang={lang}
                                />
                            </div>
                        </div>

                    </div>

                    {/* Loader */}
                    {isFetching && <SpinnerBody />}

                    {!isFetching && <JourneyContainer 
                        journeys={results}
                        page={page}
                        update={this.update}
                        policy_reference={searchTerm}
                        policys_journeys_get_all={this.props.policys_journeys_get_all}
                        currency={currency}
                        timezone={timezone}
                        lang={lang}
                    />}
                </div>
            </div>
        );
    }
}

export default main(Journeys);