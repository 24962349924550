import React from 'react';
import FormPricing from '../Forms/FormPricing';
import FormExclusions from '../Forms/FormExclusions';
import QuotePricingBox from '../../components/Quotes/QuotePricingBox';

export default function PricingSection(props) {
    const { exclusions } = props.data
    
    return (
        <div className='row'>
            <div className='col-lg-6'>
                <QuotePricingBox 
                    data={props.data}
                    lang={props.lang}
                />
            </div>
            <div className='col-lg-6'>
                <FormExclusions data={exclusions} lang={props.lang} />
                <FormPricing data={props.data} lang={props.lang} />
            </div>
        </div>
    )
}
