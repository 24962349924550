import React from 'react';
import numeral from 'numeral';
import { get, round } from 'lodash';
import moment from 'moment-timezone';

function MTASummary(props) {
    const { close, back, price_change, submit, isSubmitting, error, detail, timezone, lang } = props;
    const {
        upfront_rate_before,
        usage_rate_before,
        subscription_rate_before,
        upfront_rate_after,
        usage_rate_after,
        subscription_rate_after,
        upfront_rate_chg,
        usage_rate_chg,
        subscription_rate_chg,
        calc_amount,
        days_left,
        full_policy_days,
        mta_fee
    } = price_change;


    const effective_date = get(detail, 'policy.effective_date');

    const dateWithTimezone = moment.tz(moment.unix(effective_date).format(), timezone);

    

    return (
        <div className="modal-content">
            <div className="modal-header theme-dark" >
                <h5 className="modal-title">{lang.t('policy.policies.mtaSummary')}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close} disabled={isSubmitting}> 
                    <i className="fe fe-x"></i>
                </button>
            </div>
            <div className="modal-body">
                <div className={`alert alert-${error? 'danger' : 'info'} mb-4`} role="alert">
                    <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                    {`${error ? error : lang.t('policy.policies.summaryOfChange')}`}
                    <br />
                    <span>{lang.t('policy.policies.effectiveDate')}: <strong>{dateWithTimezone.format('YYYY-MM-DD HH:mm')} {timezone}</strong></span>
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">{lang.t('policy.policies.before')}</th>
                            <th scope="col"></th>
                            <th scope="col">{lang.t('policy.policies.after')}</th>
                            <th scope="col"></th>
                            <th scope="col">{lang.t('policy.policies.change')}</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Upfront Rate</th>
                            <td>{numeral(upfront_rate_before).format('0.00')}</td>
                            <td><i className={`fe fe-chevrons-right text-dark`}></i></td>
                            <td>{numeral(upfront_rate_after).format('0.00')}</td>
                            <td><i className={`fe fe-chevrons-right text-dark`}></i></td>
                            <td>{numeral(upfront_rate_chg).format('0.00')}</td>
                            <td>
                                <i 
                                className={`fe ${upfront_rate_chg < 0 ? 
                                            'fe-chevrons-down text-success' : 
                                            (upfront_rate_chg > 0 ? 'fe-chevrons-up text-danger' : '')
                                            }`
                                            }>
                                </i>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Usage Rate</th>
                            <td>{numeral(usage_rate_before).format('0.000')}</td>
                            <td><i className={`fe fe-chevrons-right text-dark`}></i></td>
                            <td>{numeral(usage_rate_after).format('0.000')}</td>
                            <td><i className={`fe fe-chevrons-right text-dark`}></i></td>
                            <td>{numeral(usage_rate_chg).format('0.000')}</td>
                            <td>
                                <i 
                                className={`fe ${usage_rate_chg < 0 ? 
                                            'fe-chevrons-down text-success' : 
                                            (usage_rate_chg > 0 ? 'fe-chevrons-up text-danger' : '')
                                            }`
                                            }>
                                </i>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Subscription Rate</th>
                            <td>{numeral(subscription_rate_before).format('0.00')}</td>
                            <td><i className={`fe fe-chevrons-right text-dark`}></i></td>
                            <td>{numeral(subscription_rate_after).format('0.00')}</td>
                            <td><i className={`fe fe-chevrons-right text-dark`}></i></td>
                            <td>{numeral(subscription_rate_chg).format('0.00')}</td>
                            <td>
                                <i 
                                className={`fe ${subscription_rate_chg < 0 ? 
                                            'fe-chevrons-down text-success' : 
                                            (subscription_rate_chg > 0 ? 'fe-chevrons-up text-danger' : '')
                                            }`
                                            }>
                                </i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Days remaining on policy</th>
                            <td>{days_left}</td>
                        </tr>
                        <tr>
                            <th scope="row">Full policy days</th>
                            <td>{full_policy_days}</td>
                        </tr>
                        <tr>
                            <th scope="row">{`Pro-rated MTA premium (excl. fees)`}</th>
                            <td>{numeral(calc_amount).format('0.00')}</td>
                        </tr>
                        <tr>
                            <th scope="row">MTA fee</th>
                            <td>{numeral(mta_fee).format('0.00')}</td>
                        </tr>
                        <tr>
                            <th scope="row">{`Difference to ${round(calc_amount + mta_fee) < 0 ? 'refund' : 'pay'} (incl. fees)`}</th>
                            <td><b>{numeral(calc_amount + mta_fee).format('0.00')}</b></td>
                        </tr>
                    </tbody>
                </table>
                {error && <h5 className="text-danger text-right"><i className="fe fe-alert-triangle mr-2" ></i><b>{lang.t('policy.policies.unableToProceed')}</b></h5>}
            </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={back} disabled={isSubmitting}>{lang.t('buttons.back')}</button>
            {isSubmitting &&
                <button type="button" className="btn btn-primary" disabled={isSubmitting}><div className="spinner-border"/></button>
            }
            {!isSubmitting && !error &&
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={submit} disabled={isSubmitting}>{lang.t('buttons.proceed')}</button>
            }
        </div>
    </div>
    );
}

export default MTASummary;