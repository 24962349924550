import React, { Component } from 'react';

import numeral from 'numeral';

import StatMini from '../cards/StatMini';

class Banner extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { totalRiskStats = {}, colour = 'blue', lang } = this.props;
        return (
            <div>
                <div class="row">
                    <div class="col-sm-6 col-lg-3">
                        <StatMini
                            mainCount={numeral(totalRiskStats.policyCount).format('0,0')}
                            mainLabel={lang.tc('claims.reports.number_of_policies')}
                            subCount={numeral(totalRiskStats.itemCount).format('0,0')}
                            subLabel={lang.tc('claims.reports.number_of_rows')}
                            icon='fe-bar-chart-2'
                            colour={colour}
                        />
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <StatMini
                            mainCount={numeral(totalRiskStats.faultClaimsCount).format('0,0')}
                            mainLabel={lang.tc('claims.reports.fault_claims')}
                            subCount={numeral(totalRiskStats.nonFaultClaimsCount).format('0,0')}
                            subLabel={lang.tc('claims.reports.non_fault_claims')}
                            icon='fe-bar-chart-2'
                            colour={colour}
                        />
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <StatMini
                            mainCount={numeral(totalRiskStats.exposureSumYears).format('0,0')}
                            mainLabel={lang.tc('claims.reports.exposure_years')}
                            subCount={numeral(totalRiskStats.exposureSumMiles).format('0,0')}
                            subLabel={lang.tc('claims.reports.exposure_miles')}
                            icon='fe-bar-chart-2'
                            colour={colour}
                        />
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <StatMini
                            mainCount={numeral(totalRiskStats.activePolicyCount).format('0,0')}
                            mainLabel={lang.tc('claims.reports.active_policy_count')}
                            subCount={numeral(totalRiskStats.lapsedPolicyCount).format('0,0')}
                            subLabel={lang.tc('claims.reports.lapsed_policy_count')}
                            icon='fe-bar-chart-2'
                            colour={colour}
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-lg-3">
                        <StatMini
                            mainCount={`£${numeral(totalRiskStats.earnedPremium).format('0,0')}`}
                            mainLabel={lang.tc('general.premium')}
                            subCount={`£${numeral(totalRiskStats.earnedMilePremium).format('0,0')}`}
                            subLabel={`${lang.tc('general.miles')} ${lang.t('general.premium')}`}
                            icon='fe-bar-chart'
                            colour={colour}
                        />
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <StatMini
                            mainCount={`£${numeral(totalRiskStats.claimInc).format('0,0')}`}
                            mainLabel={lang.tc('claims.reports.claims_inc')}
                            subCount={`£${numeral(totalRiskStats.claimEst).format('0,0')}`}
                            subLabel={lang.tc('claims.reports.claims_est')}
                            icon='fe-bar-chart'
                            colour={colour}
                        />
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <StatMini
                            mainCount={`${totalRiskStats.claimsFaultFreq}%`}
                            mainLabel={lang.tc('claims.reports.fault_frequency')}
                            subCount={`${totalRiskStats.claimsNonFaultFreq}%`}
                            subLabel={lang.tc('claims.reports.non_fault_frequency')}
                            icon='fe-bar-chart'
                            colour={colour}
                        />
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <StatMini
                            mainCount={`${totalRiskStats.LRInc}%`}
                            mainLabel={lang.tc('claims.reports.loss_ratio_inc')}
                            subCount={`${totalRiskStats.LREst}%`}
                            subLabel={lang.tc('claims.reports.loss_ratio_est')}
                            icon='fe-bar-chart'
                            colour={colour}
                        />
                    </div>
                </div>
            </div>   
        );
    }
}

export default Banner