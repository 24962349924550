import React, { Component } from 'react';
import { startCase } from 'lodash'
import Empty from '../../../../components/empty/Results';
import Table from './Table';
import ModalAddClaim from './ModalAddClaim';

class ClaimsContainer extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            modalAddVisible: false
        }
        this.toggleAddModal = this.toggleAddModal.bind(this);
    }

    toggleAddModal() {
        this.setState({
            modalAddVisible: !this.state.modalAddVisible
        })
    }

    render() {
        const { 
            claims,
            page,
            update,
            policy_reference,
            policys_claims_get_all,
            timezone,
            policys_claims_post,
            lang,
        } = this.props;

        return (
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='d-flex justify-content-end'>
                        <button
                            className='btn btn-primary btn-sm'
                            style={{marginBottom: '10px'}}
                            onClick={this.toggleAddModal}
                        >
                            + {startCase(lang.t('policy.claims.addClaim'))}
                        </button>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            {claims.length === 0 && <Empty label="claims" lang={lang} />}
                            {claims.length > 0 &&
                                <Table
                                    claims={claims}
                                    page={page}
                                    update={update}
                                    policy_reference={policy_reference}
                                    policys_claims_get_all={policys_claims_get_all}
                                    timezone={timezone}
                                    lang={lang}
                                />
                            }
                        </div>
                    </div>
                    <ModalAddClaim 
                        open={this.state.modalAddVisible}
                        toggleModal={this.toggleAddModal}
                        policys_claims_post={policys_claims_post}
                        policys_claims_get_all={policys_claims_get_all}
                        policy_reference={policy_reference}
                        lang={lang}
                    />
                </div>
            </div>
        )
    }
}

export default ClaimsContainer;


