import React, { Component } from 'react';

import main from '../../../hoc/main';

// Core Design
import PageHeader from '../../../components/layout/PageHeader';

// Service Design
import UserContainer from '../components/Users/Container';

class PolicyUsers extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.users_get_all({});
    }

    render() {
        const {
            isFetching = true,
            selected = {},
            data = [],
        } = this.props.users;
        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    <PageHeader
                        section={'Policies'}
                        page={'Users'}
                    />

                    <UserContainer 
                        users={data}
                    />

                </div>
            </div>
        );
    }
}

export default main(PolicyUsers);