import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Pagination from '../../../../components/tables/Pagination';
import { round, sortBy } from 'lodash';
import { idBadgeColour, creditBadgeColour } from '../../../../utils/format'

function toUpper(str = '') {
    return str?.toUpperCase();
}

class IdentitysTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        }
        this.selectPage = this.selectPage.bind(this);
    }

    badgeColour(value, type) {
        let colour = null
        if (type === "id") colour = idBadgeColour(value);
        if (type === "credit") colour = creditBadgeColour(value);
        return (
            <div className={colour}>{round(value, 0)}</div>
        )
    }

    selectPage(page) {
        this.setState({
            page,
        });
    }

    render() {
        const { data = [], pages, action, lang } = this.props;
        const { page } = this.state;
        const no_of_pages = (data.length === 0 ? 0 : Math.ceil(data.length / pages));
        let pageData = sortBy(data, d => -moment(d.created_at));
        if (pages !== 0) pageData = pageData.slice((page * pages) - pages, (page * pages));

        return (
            <div className="card">
                <Pagination
                    page={page}
                    pages={pages}
                    no_of_pages={no_of_pages}
                    data={data}
                    selectPage={this.selectPage}
                    lang={lang}
                />
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Provider</th>
                                <th>Accounts</th>
                                <th>ID Score</th>
                                <th>Credit Score</th>
                                <th>Created</th>
                                <th className="w-1" />
                            </tr>
                        </thead>
                        <tbody>
                            {pageData.map((d, i) => (
                                <tr key={`identitys-${i}`}>
                                    <td data-label="Name">
                                        <div className="d-flex lh-sm py-1 align-items-center">
                                            <div className="flex-fill">
                                                <div className="strong">{d.account_holder_name}</div>
                                                <div className="text-muted text-h5">{toUpper(d.account_holder_postcode)}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-label="Provider">
                                        <div>{toUpper(d.name)} {d.environment}</div>
                                        <div className="text-muted text-h5">{d.ref}</div>
                                    </td>
                                    <td data-label="Accounts">
                                        <div>{d.no_of_accounts} Accounts</div>
                                        <div className="text-muted text-h5">{d.no_of_transactions} Transactions</div>
                                    </td>
                                    <td style={{ width: 80 }} data-label="ID">
                                        {this.badgeColour(d.id_score, 'id')}
                                    </td>
                                    <td style={{ width: 100 }} data-label="Credit">
                                        {this.badgeColour(d.credit_score, 'credit')}
                                    </td>
                                    <td style={{ width: 120 }} data-label="Created">
                                        <div className="text-muted text-h5">{moment(d.created_at).format('DD/MM/YY - HH:mm')}</div>
                                    </td>
                                    <td>
                                        <div className="btn-list flex-nowrap">
                                            <Link
                                                to={`/kyc/open-banking/${d.id}`}
                                                className="btn btn-primary"
                                            >View</Link>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                
            </div>
        );
    }
}

export default IdentitysTable;
