import { get, isEmpty, last, omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactNotification from 'react-notifications-component';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from '../actions';
import Login from '../auth/Login';
import { getLocalAutocrat, signOutAutocrat } from '../auth/autocrat';
import Footer from '../components/layout/Footer';
import Nav from '../components/layout/Nav';
import TopBar from '../components/layout/TopBar';
import OverlayLoader from '../components/loaders/OverlayLoader';
import { getLangSource } from '../lang';
import routes from '../routes/index';

const productPicker = (url) => {
    const route = routes.filter((r) => {
        return url.includes(r.path);
    });
    if (route.length === 0) return 'rating';
    const { product } = last(route);
    return product;
};

export default (Page, opts = {}) => {
    const Main = (props) => {
        const { location } = props;
        const [isFetchingAutocrat, setIsFetchingAutocrat] = useState(true);
        const [selectedProduct, setSelectedProduct] = useState(productPicker(location.pathname));
        const [selectedLang, setSelectedLang] = useState(localStorage.getItem('lang') || 'en');
        const [section, setSection] = useState('payments');

        useEffect(() => {
            const setAuthAndLang = async () => {
                try {
                    await setAuthToken();
                    props.set_lang(selectedLang);
                } catch (e) {
                    console.log({ e });
                }
            };
            setAuthAndLang();
        }, []);

        const setAuthToken = async () => {
            try {
                const autocratData = await getLocalAutocrat();
                setIsFetchingAutocrat(false);
                if (!isEmpty(autocratData) && get(autocratData, 'type') === 'DONE') {
                    props.set_cognito({
                        ...autocratData,
                        token: autocratData.access_token,
                    });
                    await props.auth_get_all({});
                }
            } catch (e) {
                console.log({ e });
            }
        };

        const signOut = () => {
            signOutAutocrat();
            props.auth_reset({});
        };

        const selectLang = (selectedLang) => {
            localStorage.setItem('lang', selectedLang);
            window.location.reload();
        };

        const selectProduct = (selectedProduct) => {
            setSelectedProduct({ selectedProduct });
            if (selectedProduct === 'rating') return props.history.push('/rating-dashboard');
            return props.history.push('/policys');
        };

        const selectEnv = (selectedEnv) => {
            props.set_environment(selectedEnv);
        };

        const lang = getLangSource(selectedLang);
        const { cognito = {}, auth = {}, environment } = props;

        if (isFetchingAutocrat || get(auth, 'isFetching'))
            return <OverlayLoader selectedProduct={selectedProduct} message={lang.t('main.engagingEngines')} />;

        if (!get(cognito, 'access_token') || !get(auth, 'data.client_id')) return <Login />;

        return (
            <div className={`antialiased page ${selectedProduct === 'rating' ? 'theme-dark' : ''}`}>
                <Nav
                    links={omit(routes, ['main'])}
                    lang={lang}
                    selectedProduct={selectedProduct}
                    company_name={get(auth, 'data.company_name', '')}
                />
                <TopBar
                    lang={lang}
                    selectLang={selectLang}
                    selectedLang={selectedLang}
                    selectedProduct={selectedProduct}
                    selectedEnv={environment}
                    section={section}
                    auth={auth.data}
                    signOut={signOut}
                    selectProduct={selectProduct}
                    selectEnv={selectEnv}
                />
                <Page {...{ ...props, selectedProduct }} />
                <div style={{ height: 20, width: '100%' }} />
                <Footer lang={lang} />
                <ReactNotification />
            </div>
        );
    };

    const mapStateToProps = (state) => state;

    const mapDispatchToProps = (dispatch) =>
        bindActionCreators(
            {
                ...actions,
            },
            dispatch,
        );

    return connect(mapStateToProps, mapDispatchToProps)(Main);
};
