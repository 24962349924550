import BaseActions from '../../../actions/base';
import services from '../config';

const { root } = services.apis;

const superaccess = new BaseActions({
    path: `${root}/auth/companys/superaccess`,
    type: 'SETTINGS_SUPERACCESS',
});

export default superaccess;