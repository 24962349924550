import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import { get, first } from 'lodash';

import main from '../../../../hoc/main';

// Core Design
import PageHeader from '../../../../components/layout/PageHeader';
import Search from '../../../../components/search/Simple';

// Service Design
import StatementContainer from '../../components/Statements/Container';
import ProductsFilterDropDown from '../../components/Products/FilterDropdown';

class Statements extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchTerm: '',
            filterTerm: '',
            results: [],
            page: 1,
        }
        this.search = this.search.bind(this);
        this.filter = this.filter.bind(this);
        this.term = this.term.bind(this);
        this.update = this.update.bind(this);
    }

    async componentWillMount() {
        await this.props.policys_products_get_all({});
        await this.props.policys_statements_get_all({
            limit: 51,
            offset: 0,
        });
    }

    filter({ product_reference }) {
        this.setState({
            filterTerm: product_reference,
        }, () => {
            this.search();
        })
    }

    term(term) {
        if (
            (term.length > 0 && term.length < 3) || 
            this.props.policys_statements_get_all.isFetching
        ) {
            return;
        }

        this.setState({
            searchTerm: term,
        }, () => {
            this.search();
        })
    }

    async search() {
        const { searchTerm = '', filterTerm = '' } = this.state;
        let where = { 
            policy_reference: searchTerm,
            limit: 51,
            offset: 0,
        };

        this.setState({
            page: 1,
        });

        if (filterTerm !== '') where = { ...where, product_reference: filterTerm };
        await this.props.policys_statements_get_all(where);
    }

    update(data) {
        this.setState(data);
    }

    render() {
        const isFetching = this.props.policys_statements;
        const results = this.props.policys_statements.data || [];
        const { data: products = [] } = this.props.policys_products;
        // Checking only the currency / timezone / distance units of the first product. assume all products of a company uses same currency / timezone / distance units
        const currency = products.length !== 0 ? first(products).currency : 'GBP';
        const distance_units = products.length !== 0 ? first(products).distance_units : 'miles';
        const timezone = products.length !== 0 ? first(products).timezone : 'Europe/London';
        const { page, searchTerm, filterTerm } = this.state;
        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    <PageHeader
                        section={lang.t('nav.policies')}
                        page={lang.t('nav.statements')}
                    />

                    {/* Search */}
                    <div className="row">
                        <div className="col-sm-12 col-lg-8">
                            <ProductsFilterDropDown
                                products={this.props.policys_products}
                                update={this.filter}
                                lang={lang}
                            />
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                                <label className="form-label">{lang.t('policy.policies.searchByPolicy')}</label>
                                <Search
                                    searchTerm={searchTerm}
                                    searchFunction={this.term}
                                    isFetching={isFetching}
                                    label={lang.t('policy.statements.statements').toLowerCase()}
                                    lang={lang}
                                />
                            </div>
                        </div>

                    </div>

                    <StatementContainer 
                        statements={results}
                        currency={currency}
                        distance_units={distance_units}
                        timezone={timezone}
                        page={page}
                        update={this.update}
                        policy_reference={searchTerm}
                        product_reference={filterTerm}
                        policys_statements_get_all={this.props.policys_statements_get_all}
                        lang={lang}
                    />

                </div>
            </div>
        );
    }
}

export default main(Statements);