import React, { Component } from 'react';
import { getLangSource } from '../../../lang';
import { uniqBy } from 'lodash';

import main from '../../../hoc/main';
import SpinnerBody from '../../../components/loaders/SpinnerBody';

import ErrorsTable from '../components/Errors/ErrorsTable';

class Errors extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.search = this.search.bind(this);
    }

    componentDidMount() {
        this.props.pricing_errors_get_all({ limit: 100 });
    }

    search(term) {
        this.setState({ error: '' });
        if (term === '') {
            this.props.pricing_errors_get_all({ 
                limit: 100,
            });
        }
        const is_uuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(term);
        if (!is_uuid) {
            this.setState({
                error: `${term} is not correct quote reference format`
            })
        } else {
            this.props.pricing_quotes_get_all({ 
                search: term,
            });
        }
    }

    render() {
        const {
            pricing_errors: {
                isFetching = true,
                selected = {},
                data = [],
            },
            lang: language,
        } = this.props;

        const { error } = this.state;

        const error_logs = uniqBy(data, d => d.quote_reference);
        const lang = getLangSource(language);

        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    {isFetching && 
                        <div className="row">
                            <SpinnerBody 
                                message={lang.t('rating.logs.retrievingErrors')}
                                selectedProduct={this.props.selectedProduct}
                            />
                        </div>
                    }

                    {!isFetching &&
                    <div>
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <div className="page-pretitle">{lang.t('rating.title')}</div>
                                    <h2 className="page-title">{lang.t('rating.logs.error')}</h2>
                                </div>
                                <div className="col-3">
                                </div>
                                <div className="col-auto ml-auto">
                                </div>
                            </div>
                        </div>

                        {(!isFetching && error) &&
                            <div className="alert alert-danger" role="alert">
                                <i className="fe fe-info" /> {error}
                            </div>
                        }

                        <div className="row">
                            <div className="col-lg-12">
                                <ErrorsTable
                                    data={error_logs}
                                    search={this.search}
                                    lang={lang}
                                />
                            </div>
                        </div>

                    </div>
                    }

                </div>
            </div>
        );
    }
}

export default main(Errors);