import React, { Component } from 'react';

import { getLangSource } from '../../../lang/index';
import getData from '../../../utils/getData';
import main from '../../../hoc/main';

// Core Design
import SpinnerBody from '../../../components/loaders/SpinnerBody';
import Search from '../../../components/search/Simple';
import Empty from '../../../components/empty/Results';

// Service Design
import IdentitysTable from '../components/tables/IdentitysTable';

class Identitys extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
        }
        this.searchIdentitys = this.searchIdentitys.bind(this);
    }

    componentDidMount() {
        const { data } = getData(this.props.payments_identitys);
        if (data.length === 0) {
            this.props.payments_identitys_get_all({});
        }
    }

    searchIdentitys(str) {
        this.setState({
            searchTerm: str,
        }, () => {
            // Debounce searches
            if (str.length > 2) {
                this.props.payments_identitys_get_all({
                    search: str,
                    limit: 100,
                });
            }
            if (str === '') {
                this.props.payments_identitys_get_all({});
            }
        });
    }

    render() {
        const lang = getLangSource(this.props.lang);
        const { isFetching, selected, data } = getData(this.props.payments_identitys);

        return (
            <div className="my-3 my-md-5">
                <div className="container">
                {/* Search */}
                <div className="row">
                        <div className="col-sm-12 col-lg-8" />
                        <div className="col-sm-12 col-lg-4">
                            <div className="row">
                                <div className="mb-3">
                                    <Search
                                        searchTerm={this.state.searchTerm}
                                        searchFunction={this.searchIdentitys}
                                        label="indentities"
                                        lang={lang}
                                    />
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                    {/* Loader */}
                        {isFetching && <SpinnerBody />}

                    {/* Empty State */}
                    {!isFetching && data.length === 0
                            && <Empty label="identities" lang={lang} />}

                    {/* Table */}
                        {!isFetching && data.length > 0
                            && (
                            <div className="col-sm-12 col-lg-12">

                                    <IdentitysTable
                                        data={data}
                                        pages={10}
                                        lang={lang}
                                    />
                                </div>
                            )}
                    </div>
                </div>
            </div>
        );
    }
}

export default main(Identitys);
