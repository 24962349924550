import React, { Component } from 'react';
import moment from 'moment';
import { getNumberFormat } from '../../../../utils/numberFormat';

class PolicyCreditsTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], currency, lang } = this.props;
        
        return (
            <div className="card">

                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.date')}</th>
                                <th>{lang.t('tableHeaders.code')}</th>
                                <th>{lang.t('tableHeaders.description')}</th>
                                <th>{lang.t('tableHeaders.amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((d, i) => (
                                <tr key={`credits-${i}`}>
                                    <td data-label="Date">
                                        <div className="text-h5">{moment(d.created_at).format('D MMMM YYYY')}</div>
                                    </td>
                                    <td data-label="Code">
                                        <div className="text-h5">{d.credit_code}</div>
                                    </td>
                                    <td data-label="Description">
                                        <div className="text-h5">{d.credit_description}</div>
                                    </td>
                                    <td data-label="Amount" style={{ display: 'flex' }}>
                                        {d.credit_amount > 0 && <div style={{marginRight: 8 }} className="badge bg-primary">+</div>}
                                        {d.credit_amount < 0 && <div style={{marginRight: 8 }} className="badge bg-red">-</div>}
                                        <div className="text-h5"><b>{getNumberFormat(currency, d.credit_amount, '$0,0.00')}</b></div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default PolicyCreditsTable;
