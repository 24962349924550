import React, { Component } from 'react';

function buildLabel (label = '', property = '') {
    if (label !== '') return label;
    let str = property.split('_').join(' ');
    return str.charAt(0).toUpperCase() + str.slice(1);
}

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edited: false,
        }
    }

    componentDidUpdate(prevProps) {
        // preventing editing bug so using loose equality as changing string back to number in save function in ProductDetail.js
        if (prevProps.value != this.props.value) {
            this.setState({
                edited: true,
            });
        }
    }

    render() {
        const {
            property,
            value = '',
            update,
            validation,
            type = 'text',
            disabled = false,
            width = 140,
            whiteSpace,
            textAlign,
            label,
            min,
            max,
            placeholder = '',
            search = false,
            search_fn = null,
            isFetching = false,
            list_id = null,
        } = this.props;

        const { edited } = this.state;

        let is_valid = null;

        // Only show validation if field has been edited
        if (validation) {
            is_valid = edited && validation ? validation(value) : '';
        } else {
            is_valid = edited && value ? true : null;
        }

        return (
            <div className="input-group mb-2">
                <div className="input-group-prepend">
                    <span className="input-group-text" style={{ width, whiteSpace, textAlign }}>{buildLabel(label, property)}</span>
                </div>
                <input
                    type={type}
                    className={`form-control ${is_valid ? 'is-valid' : ''}`}
                    autoComplete="off"
                    defaultValue={value}
                    label={property}
                    disabled={disabled}
                    min={min}
                    max={max}
                    placeholder={placeholder}
                    key={value}
                    list={list_id}
                    onBlur={(e) => {
                        update({
                            [property]: e.target.value
                        }, e)
                    }}
                />
                {search && 
                    <button className="btn btn-outline-light input-group-append border-left-0 border" onClick={search_fn}>
                        {isFetching ?
                            <div className="spinner-border text-secondary" style={{width: '20px', height: '20px'}} />
                            :
                            <i className="fe fe-search" style={{ color: '#354052', fontSize: '20px' }}/>
                        }
                    </button>
                    }
            </div>
        )
    }

}

export default Input;
