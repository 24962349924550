import React, { Component } from 'react';

import BaseFormInput from './BaseFormInput'; 

class Base extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { meta, base, updateBase, lang } = this.props;
        return (
            <div className="row">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {lang.t('rating.components.base.baseModelVariables')}
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="alert alert-info" role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                {lang.t('rating.components.base.necessary')}
                            </div>
                            {/*
                            <div className="row">
                                <BaseFormInput
                                    value={meta.version}
                                    property='version'
                                    label={lang.t('rating.components.base.version')}
                                    update={() => {}}
                                    description={lang.t('rating.components.base.versionInfo')}
                                    placeholder='1'
                                    type='text'
                                    disabled={true}
                                />
                            </div>
                            */}
                            <div className="row">
                                <BaseFormInput
                                    value={base.upfront_split}
                                    property='upfront_split'
                                    label={lang.t('rating.components.base.upfrontSplit')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.upfrontSplitInfo')}
                                    placeholder='33.00'
                                />
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.base_premium}
                                    property='base_premium'
                                    label={lang.t('rating.components.base.basePremium')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.basePremiumInfo')}
                                    placeholder='1500.00'
                                />
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.base_premium_min}
                                    property='base_premium_min'
                                    label={lang.t('rating.components.base.minPremium')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.minPremiumInfo')}
                                    placeholder='300.00'
                                />
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.base_premium_max}
                                    property='base_premium_max'
                                    label={lang.t('rating.components.base.maxPremium')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.maxPremiumInfo')}
                                    placeholder='4000.00'
                                />
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.ipt}
                                    property='ipt'
                                    label={lang.t('rating.components.base.ipt')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.iptInfo')}
                                    placeholder='12.00'
                                />
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.commission}
                                    property='commission'
                                    label={lang.t('rating.components.base.commission')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.commissionInfo')}
                                    placeholder='15.00'
                                />
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.compulsory_excess}
                                    property='compulsory_excess'
                                    label={lang.t('rating.components.base.compulsoryExcess')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.compulsoryExcessInfo')}
                                    placeholder='200.00'
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {lang.t('rating.components.base.usageVariables')}
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="alert alert-info" role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                {lang.t('rating.components.base.onlyIf')}
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.usage_split}
                                    property='usage_split'
                                    label={lang.t('rating.components.base.usageSplit')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.usageSplitInfo')}
                                    placeholder='33.00'
                                />
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.usage_distance}
                                    property='usage_distance'
                                    label={lang.t('rating.components.base.averageMilage')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.averageMilageInfo')}
                                    placeholder='7000'
                                />
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.usage_min}
                                    property='usage_min'
                                    label={lang.t('rating.components.base.minUsage')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.minUsageInfo')}
                                    placeholder='0.01'
                                    step={0.01}
                                />
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.usage_max}
                                    property='usage_max'
                                    label={lang.t('rating.components.base.maxUsage')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.maxUsageInfo')}
                                    placeholder='1.00'
                                    step={0.01}
                                />
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.upfront_min}
                                    property='upfront_min'
                                    label={lang.t('rating.components.base.minUpfront')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.minUpfrontInfo')}
                                    placeholder='0.01'
                                    step={0.01}
                                />
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.upfront_max}
                                    property='upfront_max'
                                    label={lang.t('rating.components.base.maxUpfront')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.maxUpfrontInfo')}
                                    placeholder='1.00'
                                    step={0.01}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {lang.t('rating.components.base.subscriptionVariables')}
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="alert alert-info" role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                {lang.t('rating.components.base.onlyIfSubscription')}
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.subscription_split}
                                    property='subscription_split'
                                    label={lang.t('rating.components.base.subscriptionSplit')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.subscriptionSplitInfo')}
                                    placeholder='60.00'
                                />
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.subscription_installments}
                                    property='subscription_installments'
                                    label={lang.t('rating.components.base.installments')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.installmentsInfo')}
                                    placeholder='10'
                                />
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.subscription_min}
                                    property='subscription_min'
                                    label={lang.t('rating.components.base.minSubscription')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.minSubscriptionInfo')}
                                    placeholder='500.00'
                                />
                            </div>
                            <div className="row">
                                <BaseFormInput
                                    value={base.subscription_max}
                                    property='subscription_max'
                                    label={lang.t('rating.components.base.maxSubscription')}
                                    update={updateBase}
                                    description={lang.t('rating.components.base.maxSubscriptionInfo')}
                                    placeholder='1.00'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Base;