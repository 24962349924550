const strings = {
    languages: {
        en: 'Anglais',
        fr: 'Français',
        it: 'italian',
        es: 'spanish'
    },
    menu: {
        profile: 'Profil',
        settings: 'Réglages',
        inbox: 'Inbox',
        company: 'Compagnie',
        users: 'Utilisatrices',
        tokens: 'Jetons',
        tasks: 'Tâches',
        message: 'Message',
        signout: 'Se déconnecter',
    },
    products: {
        engine: 'Engine',
        sandbox: 'Sandbox',
        production: 'Production',
        rating: 'Rating',
        policy: 'Policy',
        openbanking: 'Open Banking',  
    },
    login: {
        loginAccount: 'Connectez-vous à votre compte',
        username: 'Username',
        emailAddress: 'Adresse électronique',
        password: 'Mot de passe',
        newPassword: 'Nouveau mot de passe', 
        passwordReset: 'Mot de passe réinitialisé avec succès',
        forgotPassword: "J'ai oublié le mot de passe",
        setNewPassword: 'Veuillez créer un nouveau mot de passe',
        rememberMe: 'Se souvenir de moi sur cet appareil',
        signIn: 'se connecter',
        forgotPassword: 'Mot de passe oublié',
        newPassword: 'Nouveau mot de passe',
        resetPassword: 'Entrez votre adresse e-mail et un code de réinitialisation de mot de passe vous sera défini',
        sendPassword: 'Envoyez-moi un nouveau mot de passe',
        takeBack: 'Me ramener à la page de connexion',
        loginPage: 'Page de connexion',
        setTOTP: "Utilisez Google Authenticator pour scanner le code QR, puis ajoutez le code à 6 chiffres dans l'entrée ci-dessous",
        getTOTP: "Utilisez Google Authenticator et entrez le code à 6 chiffres dans l'entrée ci-dessous",
        enterCode: 'Entrez le code',
        nextStep: "L'étape suivante",
    },
    roles: {
        admin: 'Admin',
        /*developer: 'Développeur',
        operative: 'Opérations',
        finance: 'Finance',
        marketing: 'Commercialisation',*/
        policyholder: 'Assuré',
    },
    claims: {
        reports: {
            select_report: 'select report(F)',
            select_pivot: 'select pivot(F)',
            select_format: 'select format(F)',
            select_risks: 'select risks(F)',
            generate_report: 'generate report(F)',
            loss_ratio: 'loss ratio(F)',
            claims_frequency: 'claims frequency(F)',
            claim_types: 'claim types(F)',
            claim_journeys: 'claim_journeys(F)',
            number_of_policies: 'number of policies(F)',
            number_of_rows: 'number of rows(F)',
            fault_claims: 'fault clams(F)',
            non_fault_claims: 'non-fault claims(F)',
            exposure_years: 'exposure years(F)',
            exposure_miles: 'exposure miles(F)',
            active_policy_count: 'active policy count(F)',
            lapsed_policy_count: 'lapsed policy count(F)',
            claims_inc: 'claims inc.(F)',
            claims_est: 'claims ext.(F)',
            fault_frequency: 'fault frequency(F)',
            non_fault_frequency: 'non-fault frequency(F)',
            loss_ratio_inc: 'loss ratio inc.(F)',
            loss_ratio_est: 'loss ratio est.(F)',

        },        
    },
    policies: {
        search: 'Searchss',
    },
}

export default strings;
