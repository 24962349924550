function getFactorFromStr(str = '', factors) {
    let expression = str;
    let queries = [];
    const elements = str.split('{');
    elements.forEach(el => {
        const elements = el.split('}');
        if (elements.length > 1) {
            queries.push({
                query: elements[0],
                has_factor: factors.includes(elements[0]),
            });
        };
    });
    return {
        queries,
        expression,
    };
}

export default function(ex, factors) {
    try {
        const { expression, queries } = getFactorFromStr(ex, factors);
        return {
            is_valid: true,
            queries,
            expression,
        };
    } catch(e) {
        return { is_valid: false }
    }
};