import React, { Component } from 'react';
import SuperTable from '../../../../components/tables/Super';

class ScenariosTable extends Component {
    constructor(props) {
        super(props);
        this.delete = this.delete.bind(this);
    }

    delete() {
        
    }

    render() {
        const { scenarios = [], selected, lang } = this.props;

        const headers = [
            {
                label: lang.t('tableHeaders.id'),
                name: 'id',
            },
            {
                label: lang.t('tableHeaders.name'),
                name: 'name',
                search: true,
            },
            {
                label: '',
                name: 'edit',
            },
            {
                label: '',
                name: 'delete',
            },
        ];

        const Cells = (index, d) => {
            return (
                <tr key={`tests-${index}`}>
                    <td data-label={lang.t('tableHeaders.id')}>
                        <div className="text-muted text-h5">{d.id}</div>
                    </td>
                    <td data-label={lang.t('tableHeaders.name')}>
                        <div className="text-h5"><b>{d.name}</b></div>
                    </td>
                    <td className="w-1">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.props.updateSelected(d);
                        }} className="btn btn-primary btn-sm">
                            {lang.t('buttons.edit')}
                        </a>
                    </td>
                    <td className="w-1">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.delete(d)
                        }} className="btn btn-red btn-sm">
                            {lang.t('buttons.delete')}
                        </a>
                    </td>
                </tr>
            );
        }

        return (
            <SuperTable 
                // Filters out undefined
                data={scenarios}
                headers={headers}
                Cells={Cells}
                limit={1000}
                height={500}
                lang={lang}
            />
        );
    }
}

export default ScenariosTable;
