import BaseActions from '../../../actions/base';
import services from '../config';

const { root } = services.apis;

const companys = new BaseActions({
    path: `${root}/auth/companys`,
    type: 'SETTINGS_COMPANYS',
});

export default companys;