import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PageHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { section, page, buttonText, buttonLink } = this.props;
        return (
            <div className="page-header">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <div className="page-pretitle">
                            {section}
                        </div>
                        <h2 className="page-title">
                            {page}
                        </h2>
                    </div>
                    <div className="col-auto ml-auto d-print-none">
                        {buttonText &&
                            <Link
                                to={buttonLink}
                                className="btn btn-primary"
                            >{buttonText}</Link>
                        
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default PageHeader;