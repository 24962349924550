import React from 'react';

function ValidationCheck(props) {
    const { close, valErrors = [], lang } = props;

    const mapping = {
        base_check: 'Base',
        address_check: 'Address',
        vehicle_check: 'Vehicle',
        proposer_check: 'Proposer',
        add_driver_1: 'Additional Driver 1',
        add_driver_2: 'Additional Driver 2',
        add_driver_3: 'Additional Driver 3',
        add_driver_4: 'Additional Driver 4',
    }
    return (
        <div className="modal-content">
            <div className="modal-header theme-dark" >
                <h5 className="modal-title">{lang.t('policy.policies.requiredNotPopulated')}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
                    <i className="fe fe-x"></i>
                </button>
            </div>
            <div className="modal-body">
                <h5 className="mb-3">{lang.t('policy.policies.requiredFieldsWarning')}:</h5>
                <ul>
                    {valErrors.map(err => (
                        <li className='text-red'><h5>{mapping[err]}</h5></li>
                    ))}
                </ul>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={close}
                >
                    {lang.t('buttons.goBack')}
                </button>
            </div>
        </div>
    );
}

export default ValidationCheck;