import { get } from 'lodash';

export default function getData(obj) {
    const data = get(obj, 'data', []);
    const isFetching = get(obj, 'isFetching', false);
    const selected = get(obj, 'selected', {});
    return {
        data,
        isFetching,
        selected,
    }
}
