import React, { Component } from 'react';
import FormContacts from '../Forms/FormContacts';
import ChangeSuccess from '../Modal/components/ChangeSuccess';
import { get } from 'lodash';
import { has_email_phone_changed, email_phone_update_msg } from '../Utils'

class UpdateContactSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_edited: false,
            is_loading: false,
            error: null,
            open_modal: false,
            success_msg: '',
        }

        this.update = this.update.bind(this);
        this.submit = this.submit.bind(this);
        this.close_modal = this.close_modal.bind(this);
    }

    componentWillMount() {
        const email = get(this.props.policy_detail, 'proposer.email', '');
        const phone_number = get(this.props.policy_detail, 'proposer.phone_number', '');

        this.setState({
            original_email: email,
            original_phone: phone_number,
            email,
            phone_number
        });
    }

    update(item) {
        this.setState(item, () => {
            const { original_email, original_phone, email, phone_number } = this.state;

            if (original_email.toLowerCase() !== email.toLowerCase() || original_phone !== phone_number) {
                this.setState({ is_edited: true });
            } else {
                this.setState({ is_edited: false });
            }
        });
    }

    async submit() {
        this.setState({ 
            is_loading: true,
            error: null,
        });

        const { original_email, original_phone, email, phone_number } = this.state;
        const { lang } = this.props;

        // Check what has changed - will return a object of the changes eg { email: xyz@email.com }
        const body = has_email_phone_changed({
            original: {
                email: original_email,
                phone_number: original_phone,
            },
            changes: {
                email,
                phone_number,
            }
        });

        // Set the success message to state
        this.setState({ 
            success_msg: email_phone_update_msg(body, lang) 
        });

        try {
            const userResponse = await this.props.settings_users_get_all({
                email: encodeURIComponent(original_email),
            });

            const userReference = get(userResponse, 'payload.data[0].user_reference');

            await this.props.settings_users_put({
                id: userReference,
                data: body,
            });

            this.setState({ open_modal: true });

        } catch (err) {
            console.log(err);
            this.setState({ error: get(err, 'error.response.data.message', 'There is an error') });

        } finally {
            this.setState({ is_loading: false });
        }
    }

    close_modal() {
        this.setState({ open_modal: false });

        window.location.reload();
    }

    render() {
        const {
            is_edited,
            email,
            phone_number,
            is_loading,
            error,
            open_modal,
            success_msg,
        } = this.state;
        const { lang } = this.props;
        return [
            <div style={{ width: '65%', margin: '30px auto 0px auto' }}>
                {error &&
                    <div className={`alert alert-danger mb-4`} role="alert">
                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                        {error}
                    </div>
                }
                <FormContacts
                    email={email}
                    phone_number={phone_number}
                    update={this.update}
                    lang={lang}
                />
                {is_loading &&
                    <button
                        type="button"
                        className="btn btn-primary float-right"
                        disabled={is_loading}
                    >
                        <div className="spinner-border"/>
                    </button>
                }
                {!is_loading &&
                    <button 
                        type="button"
                        className="btn btn-primary float-right"
                        onClick={this.submit}
                        disabled={!is_edited}
                    >
                        {lang.t('buttons.update')}
                    </button>
                }
            </div>,
            <div className={`modal modal-blur fade ${open_modal ? 'show' : ''}`} style={{ display: open_modal ? 'block' : '' }} id="modal-mod" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <ChangeSuccess
                        close={this.close_modal}
                        title_text={lang.t('policy.policies.contactDetailsUpdated')}
                        alert_text={lang.t('policy.policies.contactDetailSuccess')}
                        body_text={success_msg}
                    />
                </div>
            </div>
        ];
    }
}

export default UpdateContactSection;