import React, { Component } from 'react';
import { get } from 'lodash';
import { parse_date } from '../Detail/Utils';

import { toUpper } from '../../../../utils/format';
import { getPolicyStatus } from '../../../../utils/getPolicyStatus';
import { calculateRetryPayment } from '../../../../utils/calculateRetryPayment';
import { getNumberFormat } from '../../../../utils/numberFormat';

class UnpaidTable extends Component {
    constructor (props) {
        super(props) 
        this.state = {
            successBtns: [],
            failedBtns: [],
        }
        this.retryPayment = this.retryPayment.bind(this);
    }

    btnType(rowId) {
        const { successBtns, failedBtns } = this.state;
        const { lang } = this.props;

        if (successBtns.includes(rowId)) {
            return { 
                disabled: true,
                style: "btn btn-sm btn-block badge-retry retry-success",
                text: `${lang.t('policy.table.paid')}`,
            }
        } else if (failedBtns.includes(rowId)) {
            return {
                disabled: false,
                style: "btn btn-sm btn-block badge-retry retry-failed",
                text: `${lang.t('policy.table.failed')}`,
            }
        } else {
            return {
                disabled: false,
                style: "btn btn-sm btn-primary badge-retry",
                text: `${lang.t('policy.table.retry')}`,
            }
        }
    }

    updateRetryBtn(isSuccess, rowId) {
        if (isSuccess) {
            const successBtns = [...this.state.successBtns, rowId]
            this.setState({ successBtns })
        }
        if (!isSuccess) {
            const failedBtns = [...this.state.failedBtns, rowId]
            this.setState({ failedBtns })
        }
    }

    retryPayment({
        product_reference,
        policy_reference, 
        policy_version_reference, 
        payment_amount, 
        row_id
    }) {
        this.props.policys_payments_post({
            data: {
                type: 'RETRY_PAYMENT',
                product_reference,
                policy_reference,
                policy_version_reference,
            }
        }).then((payments_response) => {
            // TODO: improve this check
            const { error_message } = get(payments_response, 'payload.data', ''); 
            if (error_message) {
                this.props.showBanner(policy_reference, payment_amount, false, error_message)
                this.updateRetryBtn(false, row_id)
            }
            if (!error_message) {
                this.props.showBanner(policy_reference, payment_amount, true) 
                this.updateRetryBtn(true, row_id)
            }
        }).catch(e => {
            const error = get(e, 'error.response.data.message', null);
            if (error) {
                this.props.showBanner(policy_reference, payment_amount, false, error)
                this.updateRetryBtn(false, row_id)
            }
        });
    }

    render () {
        const { unpaid_versions, currency, timezone, lang } = this.props;
        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.policyReference')}</th>
                                <th>{lang.t('tableHeaders.status')}</th>
                                <th>{lang.t('tableHeaders.versionType')}</th>
                                <th>{lang.t('tableHeaders.reg')}</th>
                                <th>{lang.t('tableHeaders.versionDate')}</th>
                                <th>{lang.t('tableHeaders.createdAtDate')}</th>
                                <th>{lang.t('tableHeaders.amountUnpaid')}</th>
                                <th className="w-1" />
                            </tr>
                        </thead>
                        <tbody>
                                {unpaid_versions.map((d, i) => {
                                    const status = getPolicyStatus(d);
                                    const paymentAmount = calculateRetryPayment(d);
                                    const { disabled, style, text } = this.btnType(i);
                                    return (
                                        <tr key={`Unpaid-${i}`}>
                                            <td data-label="Name">
                                                <div className="d-flex lh-sm py-1 align-items-center">
                                                    <div className="flex-fill">
                                                        <div className="strong">{toUpper(d.proposer_first_names)} {toUpper(d.proposer_last_names)}</div>
                                                        <div className="text-muted text-h5">{toUpper(d.policy_reference)}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {status === "active" && <span style={{ marginRight: 6 }} className={`badge bg-green btn-block`}>Active</span>}
                                                {status.text === "expiring" && <span style={{ marginRight: 6 }} className={`badge bg-orange btn-block`} data-toggle="tooltip" data-placement="top" title={`Expiring in ${status.policyDaysRemaining} ${status.policyDaysRemaining > 1 ? 'days' : 'day'}`}>Expiring</span>}
                                                {status === "cancelled" && <span style={{ marginRight: 6 }} className={`badge bg-red btn-block`}>Cancelled</span>}
                                                {status === "lapsed" && <span style={{ marginRight: 6 }} className={`badge bg-grey btn-block`}>Lapsed</span>}
                                                {status === "cancelling" && <span style={{ marginRight: 6 }} className={`badge bg-yellow btn-block`}>Cancelling</span>}
                                            </td>
                                            <td>
                                                <div className="Text-muted text-h5">{d.version_type}</div>
                                            </td>
                                            <td data-label="Reg">
                                                <div className="text-muted text-h5">{d.vehicle_reg}</div>
                                            </td>
                                            <td data-label="Version Date">
                                                <div className="text-muted text-h5" >{parse_date(d.effective_date, 'DD/MM/YYYY HH:mm:ss', timezone)}</div>
                                            </td>
                                            <td data-label="Created At Date">
                                                <div className="text-muted text-h5">{parse_date(d.created_at, 'DD/MM/YYYY HH:mm:ss', timezone)}</div>
                                            </td>
                                            <td data-label="Payment amount"> 
                                                {getNumberFormat(currency, paymentAmount, '$0,0.00')}
                                            </td>
                                            <td>
                                                <div className="btn-list flex-nowrap" >
                                                    <button 
                                                        disabled={disabled}
                                                        className={style}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.retryPayment({
                                                                product_reference: d.product_reference,
                                                                policy_reference: d.policy_reference, 
                                                                policy_version_reference: d.policy_version_reference, 
                                                                payment_amount: paymentAmount, 
                                                                row_id: i
                                                            });
                                                        }}>
                                                            {text}
                                                        </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                </table>
            </div>

            </div>
        )
    }
}

export default UnpaidTable;