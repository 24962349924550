import React, { Component } from 'react';

class Empty extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            label,
            lang,
        } = this.props;
        return (
            <div className="card">
                <div className="empty">
                    <div className="empty-icon">
                        <i className="fe fe-info" />
                    </div>
                    <p className="empty-title h3">
                        {lang.t('components.empty.no')} {label} {lang.t('components.empty.found')}.
                    </p>
              </div>
          </div>
        );
    }
}

export default Empty;
