import React, { Component } from 'react';
import Input from './Input';
import Disabled from './Disabled';

class FormAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updated_info: {}
        }
        this.update = this.update.bind(this);
    }

    update(item) {
        const { detail } = this.props;
        const updated_info = {
            ...this.state.items,
            ...item,
        }
        const result = {
            ...detail,
            policy: {
                ...detail.policy,
                address: {
                    ...detail.policy.address,
                    ...updated_info,
                }
            }
        };
        this.setState({ updated_info });
        this.props.update(result);
    }

    render() {
        const { detail, disabled = false, lang } = this.props;
        const address = detail.policy.address;
        return [
            <div className="card ml-2">
                <div className="card-header"><h3 className="card-title">{lang.t('components.formAddress.address')}</h3></div>
                <div className="card-body">
                    <div className="mb-1">
                        <Input 
                            label={`${lang.t('components.formAddress.lineOne')} *`}
                            property={'line_1'}
                            value={address.line_1}
                            update={this.update}
                            type = 'text'
                            disabled={disabled}
                        />

                        <Input 
                            label={lang.t('components.formAddress.lineTwo')}
                            property={'line_2'}
                            value={address.line_2}
                            update={this.update}
                            type = 'text'
                            disabled={disabled}
                        />
                        <Input 
                            label={lang.t('components.formAddress.lineThree')}
                            property={'line_3'}
                            value={address.line_3}
                            update={this.update}
                            type = 'text'
                            disabled={disabled}
                        />
                        <Input 
                            label={`${lang.t('components.formAddress.postcode')} *`}
                            property={'postcode'}
                            value={address.postcode}
                            update={this.update}
                            type = 'text'
                            disabled={disabled}
                        />
                        <Input 
                            label={`${lang.t('components.formAddress.city')} *`}
                            property={'city'}
                            value={address.city}
                            update={this.update}
                            type = 'text'
                            disabled={disabled}
                        />
                        <Input 
                            label={lang.t('components.formAddress.county')}
                            property={'county'}
                            value={address.county}
                            update={this.update}
                            type = 'text'
                            disabled={disabled}
                        />
                        <Input 
                            label={`${lang.t('components.formAddress.country')} *`}
                            property={'country'}
                            value={address.country}
                            update={this.update}
                            type='text'
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
        
        ];
    }
}

export default FormAddress;