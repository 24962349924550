import low from 'lowdb';
import LocalStorage from 'lowdb/adapters/Memory';
const adapter = new LocalStorage('db');
const db = low(adapter);

db
    .defaults({
        //Base Data
        policys: [],
        claims: [],
        risks: [],
        cohorts: [],
        analysis: {},

        //Application state
        application : {
            confidence: 0.95,
            ave_freq: 5.8,
            min_freq: 4.0,
            min_lr: 50.0,
            min_exposure: 1000,
            max_freq: 8.2,
            max_lr: 110.0,
            max_exposure: 1000,
        },

    })
    .write()


export default db;