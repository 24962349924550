import React, { Component } from "react";
import moment from "moment";
import BinaryRadioGroup from "../../../../components/forms/BinaryRadioGroup";
import AlertMessage from "../../../../components/alerts/AlertMessage";
import Input from '../../../../components/forms/Input';

export default class FormClaimVersionUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            update: "",
            isFault: false,
            status: "Reported",
            settledDate: undefined,
            settledAmount: 0,
            estimatedAmount: 0,
            incurredAmount: 0
        }
        this.populateClaimVersionData = this.populateClaimVersionData.bind(this);
        this.addClaimVersion = this.addClaimVersion.bind(this);
        this.handleRadioOption = this.handleRadioOption.bind(this);
        this.setState = this.setState.bind(this);
    }

    componentDidMount() {
        this.populateClaimVersionData();
    }

    componentDidUpdate(prevProps) {
        if(this.props.latestClaimVersion !== prevProps.latestClaimVersion) {
            this.populateClaimVersionData();
        }
    }

    populateClaimVersionData() {
        const { latestClaimVersion } = this.props;
        if(latestClaimVersion) {
            const {
                update,
                is_policyholder_at_fault,
                status,
                settled_amount,
                estimated_amount,
                incurred_amount,
                settled_date
            } = latestClaimVersion;

            this.setState({
                update,
                isFault: is_policyholder_at_fault,
                status,
                estimatedAmount: estimated_amount,
                incurredAmount: incurred_amount,
                settledAmount: settled_amount,
                settledDate: settled_date ? moment(settled_date).format("YYYY-MM-DD") : null
            })
        }
    }

    async addClaimVersion() {
        const { 
            policy_reference,
            claim_reference,
            policys_claims_put, 
            updateStage, 
            refreshClaimData, 
            setIsError 
        } = this.props;

        const {
            update,
            isFault,
            status,
            settledAmount,
            estimatedAmount,
            incurredAmount,
            settledDate,
        } = this.state;

        if(!update) { 
            setIsError(true);
        } else {
            setIsError(false);
            const data = {
                policy_reference,
                update,
                is_policyholder_at_fault: isFault,
                status, 
                estimated_amount: parseFloat(estimatedAmount),
                incurred_amount: parseFloat(incurredAmount),
                settled_amount: parseFloat(settledAmount),
                settled_date: settledDate ? parseInt(moment(settledDate, "YYYY-MM-DD").format("X")) : undefined
            };

            await policys_claims_put({
                id: claim_reference,
                data
            });

            updateStage("addClaimVersionSuccess");
            refreshClaimData(claim_reference);
        }
    };

    handleRadioOption(selection) {
        this.setState({
            isFault: selection
        });
    };

    isText = value => /^[A-Za-z\s]*$/.test(value);
    isNum = value => /^[0-9.\s]*$/.test(value);
    isTextAndNum = value => /^[A-Za-z0-9.\s]*$/.test(value);

    render() {
        const {
            update,
            isFault,
            status,
            settledAmount,
            estimatedAmount,
            incurredAmount,
            settledDate,
        } = this.state;

        const {
            isError,
            lang,
        } = this.props;

        return (
            <>
            <div className="modal-body">
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <span className="input-group-text" style={{width: "140px"}}>{lang.t('policy.claims.update')} *</span>
                    </div>
                    <Input 
                        value={update}
                        update={this.setState}
                        label={'update'}
                        validation={this.isText}
                    />
                </div>
                {
                    isError && <AlertMessage icon={'info'} message={lang.t('policy.claims.updateRequired')} />
                }
                <BinaryRadioGroup 
                    mainLabel={lang.t('policy.claims.policyHolderFault')}
                    name={"policyholder_fault"}
                    checkedInputOne={!isFault ? true : false}
                    checkedInputTwo={isFault ? true : false}
                    onChange={this.handleRadioOption}
                    optionOneLabel={"False"}
                    optionTwoLabel={"True"}
                    optionOneValue={false}
                    optionTwoValue={true}
                />
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <span className="input-group-text" style={{width: "140px"}}>{lang.t('policy.claims.status')}</span>
                    </div>
                    <select className="form-control" value={status} id="category" onChange={(e) => this.setState({status: e.target.value})}>
                        <option value="Reported">{lang.t('policy.claims.reported')}</option>
                        <option value="Active">{lang.t('policy.claims.active')}</option>
                        <option value="Settled">{lang.t('policy.claims.settled')}</option>
                    </select>
                </div>
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <span className="input-group-text" style={{width: "140px"}}>{lang.t('policy.claims.estimatedAmount')}</span>
                    </div>
                    <input
                        type="number"
                        className="form-control"
                        value={estimatedAmount}
                        onChange={(e) => this.setState({estimatedAmount: e.target.value})}
                    />
                </div>
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <span className="input-group-text" style={{width: "140px"}}>{lang.t('policy.claims.incurredAmount')}</span>
                    </div>
                    <input
                        type="number"
                        className="form-control"
                        value={incurredAmount}
                        onChange={(e) => this.setState({incurredAmount: e.target.value})}
                    />
                </div>
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <span className="input-group-text" style={{width: "140px"}}>{lang.t('policy.claims.settledDate')}</span>
                    </div>
                    <Input 
                        type='date'
                        value={settledDate}
                        label={'settledDate'}
                        update={this.setState}
                    />
                </div>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" style={{width: "140px"}}>{lang.t('policy.claims.settledAmount')}</span>
                    </div>
                    <input
                        type="number"
                        className="form-control"
                        value={settledAmount}
                        onChange={(e) => this.setState({settledAmount: e.target.value})}
                    />
                </div>
                <h5 style={{ marginTop: '10px' }}>* {lang.t('policy.claims.required')}</h5>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addClaimVersion}>{lang.t('buttons.add')}</button>
            </div>
            </>
        )
    }
}
