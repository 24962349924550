import { first, get, isEmpty } from 'lodash';
import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';

import Empty from '../../../../components/empty/Results';
// Core Design
import SpinnerBody from '../../../../components/loaders/SpinnerBody';
import clientConfig from '../../../../config/client_config';
import main from '../../../../hoc/main';
import { getLangSource } from '../../../../lang';
import { getId } from '../../../../utils/url';
import ActivitySection from '../../components/Activity/Container';
import ClaimsSection from '../../components/Claims/Container';
import CreditsSection from '../../components/Credits/Container';
import PolicyHeader from '../../components/Detail/PolicyHeader';
// Service Design
import PolicyNav from '../../components/Detail/PolicyNav';
import CancellationSection from '../../components/Detail/Sections/CancellationSection';
import CommsSection from '../../components/Detail/Sections/CommsSection';
import DetailSection from '../../components/Detail/Sections/DetailSection';
import DevicesSection from '../../components/Detail/Sections/DevicesSection';
import LogSection from '../../components/Detail/Sections/LogSection';
import LoginsSection from '../../components/Detail/Sections/LoginsSection';
import MTASection from '../../components/Detail/Sections/MTASection';
import PaymentSection from '../../components/Detail/Sections/PaymentSection';
import RenewalSection from '../../components/Detail/Sections/RenewalSection';
import UpdateContactSection from '../../components/Detail/Sections/UpdateContactSection';
import VersionsSection from '../../components/Detail/Sections/VersionsSection';
import { get_timezone_for_policy } from '../../components/Detail/Utils';
import DocumentsSection from '../../components/Documents/Container';
import JourneysSection from '../../components/Journeys/Container';
import StatementsSection from '../../components/Statements/Container';
import UsagesSection from '../../components/Usages/Container';

class PolicyDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            section: 'detail',
            selected: {}, //latest current version of pol object (ie not future dated)
            isFetching: true,
            isDataFetching: false,
            edited: false,
            latest: {}, //latest possible version of pol object (can be future dated),
            codelistEnabled: null,
            page: 1,
        };
        this.update = this.update.bind(this);
        this.page = this.page.bind(this);
        this.pagenationUpdate = this.pagenationUpdate.bind(this);
    }

    async componentWillMount() {
        const { history } = this.props;
        const { location } = history;

        await this.setState({ section: location.state ? location.state : 'detail' });

        if (this.state.section !== 'detail') {
            await this.page(location.state);
        }

        const id = getId(history);

        const company_reference = get(this.props.auth, 'data.company_reference');
        const codelistEnabled = get(clientConfig, `${company_reference}.codelist.enabled`);

        // This grabs the latest current version
        await this.props.policys_get({
            id,
            verbose: codelistEnabled ? true : '', // API is not actually checking for 'true' or 'false' - just whether value is truthy
        });

        const { isFetching, selected } = this.props.policys;

        //This grabs the latest possible version for MTA
        const latest_res = await this.props.policys_get({
            id,
            verbose: codelistEnabled ? true : '',
            latest_version: true,
        });

        if (!this.props.policys_products.data) {
            await this.props.policys_products_get_all({});
        }

        this.setState({
            isFetching,
            selected,
            latest: latest_res.payload.data,
            codelistEnabled,
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        const { location } = this.props.history;
        const { pathname, state } = location;
        const { section } = this.state;
        const lang = getLangSource(this.props.lang);

        if (section !== state) this.props.history.replace(pathname, section);

        if (prevState.section === 'mta' && this.state.section !== 'mta' && this.state.edited) {
            if (!window.confirm(lang.t('policy.policies.unsavedChangesWarning'))) {
                this.setState({
                    section: 'mta',
                });
            } else {
                // If user has navigated away and has made changes, then need to get latest again as it would have been overwritten
                const { history } = this.props;
                const id = getId(history);

                const latest_res = await this.props.policys_get({
                    id,
                    verbose: this.state.codelistEnabled,
                    latest_version: true,
                });

                this.setState({
                    edited: false,
                    latest: latest_res.payload.data,
                });
            }
        }

        const { history } = this.props;
        const { codelistEnabled } = this.state;
        const id = getId(history);

        if (
            this.state.section === 'cancellation' &&
            prevProps.policys.selected?.policy_reference !== this.props.policys.selected?.policy_reference
        ) {
            this.setState({ isFetching: true });

            // Call policy GET for current version
            await this.props.policys_get({
                id,
                verbose: codelistEnabled ? true : '',
            });

            // Call policy GET for latest version
            const response = await this.props.policys_get({
                id,
                verbose: true,
                latest_version: true,
            });

            const latest = get(response, 'payload.data', {});

            const { isFetching, selected } = this.props.policys;

            this.setState({
                latest,
                isFetching,
                selected,
            });
        }
    }

    update(result) {
        this.setState({
            latest: result,
            edited: true,
        });
    }

    pagenationUpdate(data) {
        this.setState(data);
    }

    async page(section) {
        const { history } = this.props;
        const id = getId(history);
        this.setState({ isDataFetching: false });

        if (section === 'statements') {
            this.setState({
                page: 1,
            });
            await this.props.policys_statements_get_all({
                policy_reference: id,
                limit: 51,
                offset: 0,
            });
        }

        if (section === 'journeys') {
            this.setState({
                page: 1,
            });
            await this.props.policys_journeys_get_all({
                policy_reference: id,
                limit: 51,
                offset: 0,
            });
        }

        if (section === 'logins') {
            // TODO: modify auth/policyholders to return cognito sub (ie user_reference) to improve performance here
            this.setState({ isDataFetching: true });

            // Get email from auth/policyholders
            await this.props.policys_logins_get_all({
                policy_reference: id,
            });

            const policyHolderEmail = get(this.props, 'policys_logins.data[0].email');

            // Get user reference from auth/users?email=[email]
            await this.props.settings_users_get_all({
                email: encodeURIComponent(policyHolderEmail),
            });

            const userReference = get(this.props, 'settings_users.data[0].user_reference');

            // Get cognito status from auth/users/:user_reference
            await this.props.settings_users_get({
                id: userReference,
            });

            this.setState({ isDataFetching: false });
        }

        if (section === 'activity') {
            await this.props.policys_user_logs_get_all({
                policy_reference: id,
            });
        }

        if (section === 'payments') {
            await this.props.policys_unpaid_versions_get_all({
                policy_reference: id,
            });

            await this.props.policys_credits_get_all({
                policy_reference: id,
            });
        }

        if (section === 'credits') {
            await this.props.policys_credits_get_all({
                policy_reference: id,
            });
        }

        if (section === 'usages') {
            await this.props.policys_usages_get_all({
                policy_reference: id,
            });
        }

        if (section === 'documents') {
            await this.props.policys_kyc_get({
                id,
            });
        }

        if (section === 'comms') {
            await this.props.policys_comms_get_all({
                policy_reference: id,
            });
        }

        if (section === 'devices') {
            await this.props.telematics_devices_get_all({
                policy_reference: id,
            });
        }

        if (section === 'claims') {
            await this.props.policys_claims_get_all({
                policy_reference: id,
            });
        }

        this.setState({
            section,
        });
    }

    render() {
        const { section, isFetching, isDataFetching, selected, edited, latest, page } = this.state;
        const version_type = get(selected, 'versions[0].version_type', 'nb');
        const disable_mta = version_type === 'cn' ? true : false;
        const { data: products = [] } = this.props.policys_products;
        // Checking only the currency of the first product. assume all products of a company uses same currency
        const currency = products.length !== 0 ? first(products).currency : 'GBP';

        const product_reference = get(selected, 'policy.product_reference', '');

        const timezone =
            products.length !== 0 && product_reference.length > 0 ? get_timezone_for_policy(products, selected) : '';

        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <Prompt when={edited} message={(location) => lang.t('policy.policies.unsavedChangesWarning')} />
                <div className="container">
                    <div className="row">
                        {/* Loader */}
                        {isFetching && <SpinnerBody />}

                        {/* Empty State */}
                        {!isFetching && isEmpty(selected) && <Empty label="policies" lang={lang} />}

                        {/* Table */}
                        {!isFetching && !isEmpty(selected) && (
                            <div className="col-sm-12 col-lg-12">
                                <PolicyHeader policy_detail={selected} timezone={timezone} />
                                <div className="row">
                                    <div className="col-lg-2">
                                        <PolicyNav active={section} page={this.page} lang={lang} />
                                    </div>
                                    <div className="col-lg-10">
                                        {isDataFetching ? (
                                            <SpinnerBody />
                                        ) : (
                                            <>
                                                {section === 'detail' && (
                                                    <DetailSection
                                                        policy_detail={selected}
                                                        update={this.update}
                                                        timezone={timezone}
                                                        lang={lang}
                                                    />
                                                )}

                                                {section === 'versions' && (
                                                    <VersionsSection
                                                        versions={selected.versions || []}
                                                        cognito={this.props.cognito}
                                                        auth={this.props.auth}
                                                        policys_emails_post={this.props.policys_emails_post}
                                                        policys_statements_get={this.props.policys_statements_get}
                                                        payments={selected.payments}
                                                        currency={currency}
                                                        timezone={timezone}
                                                        lang={lang}
                                                    />
                                                )}

                                                {section === 'statements' && (
                                                    <StatementsSection
                                                        statements={get(this.props.policys_statements, 'data', [])}
                                                        currency={currency}
                                                        timezone={timezone}
                                                        page={page}
                                                        policy_reference={selected.policy_reference}
                                                        update={this.pagenationUpdate}
                                                        policys_statements_get_all={
                                                            this.props.policys_statements_get_all
                                                        }
                                                        lang={lang}
                                                    />
                                                )}

                                                {section === 'journeys' && (
                                                    <JourneysSection
                                                        journeys={get(this.props.policys_journeys, 'data', [])}
                                                        page={page}
                                                        policy_reference={selected.policy_reference}
                                                        update={this.pagenationUpdate}
                                                        policys_journeys_get_all={this.props.policys_journeys_get_all}
                                                        currency={currency}
                                                        timezone={timezone}
                                                        lang={lang}
                                                    />
                                                )}

                                                {section === 'logins' && (
                                                    <LoginsSection
                                                        logins={[get(this.props.settings_users, 'selected', [])]} // Forced into an array
                                                        lang={lang}
                                                    />
                                                )}

                                                {section === 'activity' && (
                                                    <ActivitySection
                                                        activity={get(this.props.policys_user_logs, 'data', [])}
                                                        lang={lang}
                                                    />
                                                )}

                                                {section === 'log' && (
                                                    <LogSection
                                                        policy_detail={selected}
                                                        policy_reference={selected.policy_reference}
                                                        versions={selected.versions}
                                                        policys_reporting_get_all={this.props.policys_reporting_get_all}
                                                        policys_reporting={this.props.policys_reporting}
                                                        lang={lang}
                                                    />
                                                )}

                                                {section === 'mta' && (
                                                    <MTASection
                                                        policy_detail={latest}
                                                        update={this.update}
                                                        vehicles_get={this.props.vehicles_get}
                                                        policys_put={this.props.policys_put}
                                                        pol_detail_readonly={selected}
                                                        disabled={disable_mta}
                                                        edited={edited}
                                                        auth_details={this.props.auth}
                                                        policys_products_get={this.props.policys_products_get}
                                                        update_page={this.page}
                                                        timezone={timezone}
                                                        lang={lang}
                                                    />
                                                )}
                                                {section === 'renewal' && (
                                                    <RenewalSection
                                                        policy_detail={latest}
                                                        update={this.update}
                                                        vehicles_get={this.props.vehicles_get}
                                                        policys_put={this.props.policys_put}
                                                        pol_detail_readonly={selected}
                                                        disabled={disable_mta}
                                                        edited={edited}
                                                        auth_details={this.props.auth}
                                                        policys_products_get={this.props.policys_products_get}
                                                        update_page={this.page}
                                                        timezone={timezone}
                                                        lang={lang}
                                                    />
                                                )}
                                                {section === 'contact' && (
                                                    <UpdateContactSection
                                                        policy_detail={latest}
                                                        settings_users_get_all={this.props.settings_users_get_all}
                                                        settings_users_put={this.props.settings_users_put}
                                                        lang={lang}
                                                    />
                                                )}
                                                {section === 'cancellation' && (
                                                    <CancellationSection
                                                        policy_detail={latest}
                                                        policy_reference={latest.policy_reference}
                                                        versions={latest.versions}
                                                        policys_delete={this.props.policys_delete}
                                                        history={this.props.history}
                                                        currency={currency}
                                                        timezone={timezone}
                                                        lang={lang}
                                                    />
                                                )}

                                                {section === 'payments' && (
                                                    <PaymentSection
                                                        versions={selected.versions}
                                                        payments={selected.payments}
                                                        policys_payments_post={this.props.policys_payments_post}
                                                        unpaid={get(this.props.policys_unpaid_versions, 'data', [])}
                                                        currency={currency}
                                                        timezone={timezone}
                                                        credits={get(this.props.policys_credits, 'data', [])}
                                                        lang={lang}
                                                    />
                                                )}

                                                {section === 'credits' && (
                                                    <CreditsSection
                                                        policy_detail={selected}
                                                        credits={get(this.props.policys_credits, 'data', [])}
                                                        policys_credits_post={this.props.policys_credits_post}
                                                        currency={currency}
                                                        lang={lang}
                                                    />
                                                )}

                                                {section === 'comms' && (
                                                    <CommsSection
                                                        comms={get(this.props.policys_comms, 'data', [])}
                                                        timezone={timezone}
                                                        currency={currency}
                                                        lang={lang}
                                                    />
                                                )}

                                                {section === 'usages' && (
                                                    <UsagesSection
                                                        policy_detail={selected}
                                                        usages={get(this.props.policys_usages, 'data', [])}
                                                        policys_usages_post={this.props.policys_usages_post}
                                                        policys_usages_put={this.props.policys_usages_put}
                                                        policys_usages_get_all={this.props.policys_usages_get_all}
                                                        currency={currency}
                                                        timezone={timezone}
                                                        lang={lang}
                                                    />
                                                )}

                                                {section === 'documents' && (
                                                    <DocumentsSection
                                                        files={get(this.props.policys_kyc, 'selected', [])}
                                                        policy_reference={selected.policy_reference}
                                                        policys_kyc_post={this.props.policys_kyc_post}
                                                        policys_kyc_delete={this.props.policys_kyc_delete}
                                                        policys_kyc_get={this.props.policys_kyc_get}
                                                        policys_kyc_get_all={this.props.policys_kyc_get_all}
                                                        timezone={timezone}
                                                        lang={lang}
                                                    />
                                                )}

                                                {section === 'devices' && (
                                                    <DevicesSection
                                                        policy_detail={selected}
                                                        devices={get(this.props.telematics_devices, 'data', [])}
                                                        lang={lang}
                                                    />
                                                )}
                                                {section === 'claims' && (
                                                    <ClaimsSection
                                                        claims={get(this.props.policys_claims, 'data', [])}
                                                        timezone={timezone}
                                                        page={page}
                                                        policy_reference={selected.policy_reference}
                                                        update={this.pagenationUpdate}
                                                        policys_claims_get_all={this.props.policys_claims_get_all}
                                                        policys_claims_post={this.props.policys_claims_post}
                                                        lang={lang}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default main(PolicyDetail);
