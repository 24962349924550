import React from 'react';
import CloseButton from '../../../../components/buttons/CloseButton';
import { deleteFile } from './S3Helpers'

export default function ModalDeleteFile(props) {
    const { open, toggleModal, fileToDelete, policy_reference, policys_kyc_delete, policys_kyc_get, lang } = props;
    
    return (
        <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} tabIndex="-1" role="dialog" aria-modal="true">
            <div className='modal-dialog modal-dialog-centered' role='document'>
                <div className='modal-content'>
                    <div className='modal-header theme-dark'>
                        <h5 className='modal-title'>{lang.t('policy.policies.deleteFile')}</h5>
                        <CloseButton onClick={toggleModal} />
                    </div>
                    <div className='modal-body'>
                        <div style={{ textAlign: 'center', fontSize: '50px' }}>
                            <i style={{ color: '#354052' }} className="fe fe-trash-2" />
                            <h4 style={{ color: '#354052' }}>{lang.t('policy.policies.deleteWarning')}</h4>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary" 
                            onClick={e => {
                                e.preventDefault();
                                deleteFile(fileToDelete, policy_reference, policys_kyc_delete, policys_kyc_get);
                            }}>OK
                        </button>
                        <button className="btn btn-light" onClick={toggleModal}>{lang.t('buttons.cancel')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}