import React from 'react';
import styled from 'styled-components';

import PolicyNavItem from '../../../../atoms/PolicyNavItem';

const PolicyNavStyle = styled.div`
    margin-left: 1rem !important;
    .nav-items {
        margin: 0 -0.75rem;
        margin-bottom: 1.25rem !important;
        display: flex;
        flex-direction: column;
        padding-left: 0;
    }
`;

const PolicyNav = (props) => {
    const { active, lang, page } = props;

    const items = [
        {
            name: lang.t('policy.nav.policyDetail'),
            section: 'detail',
        },
        {
            name: lang.t('policy.nav.policyVersions'),
            section: 'versions',
        },
        {
            name: lang.t('policy.nav.policyStatements'),
            section: 'statements',
        },
        {
            name: lang.t('policy.nav.policyJourneys'),
            section: 'journeys',
        },
        {
            name: lang.t('policy.nav.policyUsers'),
            section: 'logins',
        },
        {
            name: lang.t('policy.nav.policyActivity'),
            section: 'activity',
        },
        {
            name: lang.t('policy.nav.policyCancellation'),
            section: 'cancellation',
        },
        {
            name: lang.t('policy.nav.policyContactDetails'),
            section: 'contact',
        },
        {
            name: lang.t('policy.nav.policyAmendment'),
            section: 'mta',
        },
        {
            name: lang.t('policy.nav.policyRenewals'),
            section: 'renewal',
        },
        {
            name: lang.t('policy.nav.policyPayments'),
            section: 'payments',
        },
        {
            name: lang.t('policy.nav.policyCredits'),
            section: 'credits',
        },
        {
            name: lang.t('policy.nav.policyUsages'),
            section: 'usages',
        },
        {
            name: lang.t('policy.nav.policyDocuments'),
            section: 'documents',
        },
        {
            name: lang.t('policy.nav.policyComms'),
            section: 'comms',
        },
        {
            name: lang.t('policy.nav.policyDevices'),
            section: 'devices',
        },
        {
            name: lang.t('policy.nav.policyClaims'),
            section: 'claims',
        },
        /*,{
        name: 'Policy Reporting',
        section: 'log',
    }
    /*,{
        name: 'Policy Payments',
    },{
        name: 'Policy Claims',
    },{
        name: 'Policy Convictions',
    }*/
    ];

    return (
        <PolicyNavStyle>
            <div className="nav-items">
                {items.map((item) => {
                    return <PolicyNavItem active={active} item={item} page={page} />;
                })}
            </div>
            {/*
            current unused sections
            <div className="list-group list-group-transparent">
                <a class="btn btn-blue btn-sm mb-2" href="">Add Claim</a>
                <a class="btn btn-blue btn-sm mb-2" href="">Add Conviction</a>
                <a class="btn btn-red btn-sm mb-2" href="">Blocklist</a>
                <a class="btn btn-red btn-sm mb-2" href="">Cancel</a>
            </div>*/}
        </PolicyNavStyle>
    );
};

export default PolicyNav;
