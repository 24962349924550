import { saveAs } from 'file-saver';
import axios from 'axios';
import { first, last } from 'lodash';

export async function deleteFile(
        file, 
        claim_reference, 
        policys_claims_documents_delete, 
        policys_claims_documents_get_all
    ) {
    const { file_name } = file;
    const id = claim_reference;
    const query = {
        file_name
    };
    await policys_claims_documents_delete({ 
        id, 
        query
    });
    await policys_claims_documents_get_all({ claim_reference });
}

async function getFileInfo (
    file, 
    claim_reference, 
    policys_claims_documents_get
) {
    const { file_name, content_type } = file;
    const res = await policys_claims_documents_get({
        id: claim_reference,
        file_name,
        content_type
    });
    return res.payload.data;
}

export async function downloadFile (
    file, 
    claim_reference, 
    policys_claims_documents_get
) {
    const { file_name } = file;
    const responseData = await getFileInfo(file, claim_reference, policys_claims_documents_get);
    const { signed_url } = responseData;
    saveAs(signed_url, first(file_name.split('.')));
}

export async function getTagSet (
    file, 
    claim_reference, 
    policys_claims_documents_get
) {
    const responseData = await getFileInfo(file, claim_reference, policys_claims_documents_get);
    const { TagSet } = responseData;
    return TagSet;
}

export async function uploadFile ({
        policys_claims_documents_post, 
        claim_reference, 
        selectedDocumentType, 
        selectedFile,
        content_type,
        setFileName,
        photo_type,
        policys_claims_documents_put, 
        },
        progress = () => null
    ) {
    const res = await policys_claims_documents_post({
        data: {
            document_type: selectedDocumentType,
            claim_reference,
            content_type
        }
    });
    const { signed_url, version_reference } = res.payload.data;
    const file_name = last(version_reference.split('/'));
    setFileName(file_name);
    const headers = {
        'x-amz-acl': 'public-read',
        'Content-Type': content_type,
        "Content-Disposition": "attachment;" 
    };
    await axios({
        method: 'put',
        url: signed_url,
        headers: headers,
        data: selectedFile,
        onUploadProgress: e => progress(e)
    });
    await policys_claims_documents_put({ 
        id: claim_reference, 
        data: {
            file_name,
            photo_type
        }
    });
}