import React, { Component } from 'react';
import LoginTable from '../../Users/LoginTable';

class LoginsSection extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { logins: data, lang } = this.props;
        return (
            <div className="col-sm-12 col-lg-12">
                { data.length !== 0  && 
                    <LoginTable 
                        data={data}
                        lang={lang}
                    />
                }
            </div>
        )
    }
}

export default LoginsSection;