import React, { Component } from 'react'
import { parse_date } from '../Detail/Utils';
import { first, isEmpty } from 'lodash';
import ModalDeleteFile from './ModalDeleteFile';
import { downloadFile, getTagSet } from './S3Helpers';
import SpinnerBody from '../../../../components/loaders/SpinnerBody';

export default class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalDeleteVisible: false,
            fileToDelete: {},
            filesWithTags: {}, 
        };
        this.PHOTO_TYPE = {
            'location': 'Location',
            'driver': 'Driver',
            "thirdParty": "Third Party"
        };
        this.DOCUMENT_TYPE = {
            'photo': 'Photo',
            'video': 'Video',
            'statement': 'Statement',
            'other': 'Other'
        };
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.updateTags = this.updateTags.bind(this);
    }

    toggleDeleteModal(file) {
        this.setState({
            fileToDelete: file,
            modalDeleteVisible: !this.state.modalDeleteVisible
        })
    }

    async updateTags() {
        const { files, claim_reference, policys_claims_documents_get } = this.props;
        let filesWithTags = {}
        if(!isEmpty(files)){
            for(let i = 0; i < files.length; i++) {
                const { file_name } = files[i];
                const tagSet = await getTagSet(files[i], claim_reference, policys_claims_documents_get);
                const tagValue = first(tagSet).Value;
                filesWithTags[file_name] = this.PHOTO_TYPE[tagValue];
            }
            this.setState({ filesWithTags: {...filesWithTags} })
        }
    }

    componentDidMount() {
        this.updateTags()
    }

    componentDidUpdate(prevProps) {
        if (this.props.files !== prevProps.files) { this.updateTags() }
    }

    render() {
        const { 
            files, 
            timezone, 
            claim_reference, 
            policys_claims_documents_get,
            policys_claims_documents_get_all, 
            lang,
        } = this.props;

        const { filesWithTags } = this.state;

        return (
            <>
            {(Object.keys(filesWithTags).length === files.length) ? (
                <div className='card'>
                    <div className='table-responsive'>
                        <table className='table table-vcenter table-mobile-md card-table'>
                            <thead>
                                <tr>
                                    <th>{lang.t('tableHeaders.fileName')}</th>
                                    <th>{lang.t('tableHeaders.documentType')}</th>
                                    <th>{lang.t('tableHeaders.tag')}</th>
                                    <th>{lang.t('tableHeaders.uploadDate')}</th>
                                    <th colSpan={2} />
                                </tr>
                            </thead>
                            <tbody>
                                {files.map((file, i) => {
                                    return (
                                        <tr key={`documents-${i}`}>
                                            <td data-label='File name'>
                                                <div className='text-h5'>
                                                    {file.file_name}
                                                </div>
                                            </td>
                                            <td data-label='Document type'>
                                                <div className='text-h5'>
                                                    {this.DOCUMENT_TYPE[file.document_type]}
                                                </div>
                                            </td>
                                            <td data-label='Tag'>
                                                <div className='text-h5'>
                                                    {filesWithTags[file.file_name]}
                                                </div>
                                            </td>
                                            <td data-label='Upload date'>
                                                <div className='text-h5'>
                                                    {parse_date(file.upload_date, 'DD/MM/YYYY', timezone)}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='mr-6'> </div>
                                            </td>
                                            <td className="w-1">
                                                <div className="btn-list flex-nowrap">
                                                    <button className='btn btn-sm btn-primary text-white'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        downloadFile(file, claim_reference, policys_claims_documents_get);
                                                    }}>
                                                        {lang.t('buttons.download')}
                                                    </button>
                                                </div>
                                            </td>
                                            <td className="w-1">
                                                <div className="btn-list flex-nowrap">
                                                    <button className='btn btn-sm btn-red text-white' 
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.toggleDeleteModal(file);
                                                    }}>
                                                        {lang.t('buttons.delete')}
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table>
                    </div>
                    <ModalDeleteFile
                        open={this.state.modalDeleteVisible}
                        toggleModal={this.toggleDeleteModal}
                        fileToDelete={this.state.fileToDelete}
                        claim_reference = {this.props.claim_reference}
                        policys_claims_documents_delete={this.props.policys_claims_documents_delete}
                        policys_claims_documents_get_all={policys_claims_documents_get_all}
                        lang={lang}
                    />
                </div>
            ) : (
                <SpinnerBody />
            )}
            </>
        )
    }
}
