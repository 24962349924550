import React, { Component } from 'react';

class Pre extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            title,
            code,
        } = this.props;
        return (
        <div className="card">
            <div className="card-header">{title}</div>
                {/* prev height 254 */}
                <pre style={{ height: 174, overflowY: 'auto', background: '#354052', color: 'white', marginBottom: 0 }}>
                    {JSON.stringify(code, null, 2)}
            </pre>
        </div>
        );
    }
}

export default Pre;
