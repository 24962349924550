import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import { saveAs } from 'file-saver';
import moment from 'moment';
import main from '../../../../hoc/main';
import getCurrencySymbol from '../../../../utils/currencySymbol';
import { first } from 'lodash';

// Service Design
import PolicyVersionsTable from '../../components/Versions/Table';
import Search from '../../../../components/search/Simple';
import SpinnerBody from '../../../../components/loaders/SpinnerBody';
import ProductsFilterDropDown from '../../components/Products/FilterDropdown';
import DownloadModal from '../../components/Reporting/DownloadModal';


class Bordereaux extends Component {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            searchTerm: '',
            filterTerm: '',
            format: 'csv',
            is_downloading: false,
            is_searching: false,
            isDownloadModalOpen: false,
            isFetchingReports: true,
        }
        this.export = this.export.bind(this);
        this.countReports = this.countReports.bind(this);

        this.search = this.search.bind(this);
        this.filter = this.filter.bind(this);
        this.term = this.term.bind(this);
        this.toggleDownloadModal = this.toggleDownloadModal.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
    }

    async componentDidMount() {
        await this.props.policys_products_get_all({});
        await this.search();
    }

    countReports() {
        const { data } = this.props.policys_reporting;
        const { format } = this.state;
        if (!data) return 0;
        if (format === 'csv') return (data.split(/\r?\n/)).length - 1;
        return data.length; 
    }
    

    filter({ product_reference }) {
        
        this.setState({
            filterTerm: product_reference,
        }, () => {
            this.search();
        })
    }

    term(term) {
        if (
            (term.length > 0 && term.length < 3) || 
            this.props.policys_reporting.isFetching
        ) {
            return;
        }

        this.setState({
            searchTerm: term,
        }, () => {
            this.search();
        })
    }

    async search() {
        const { searchTerm = '', filterTerm = '', format = 'csv', limit = null, offset = null } = this.state;
        let where = { term: searchTerm };
        if (filterTerm !== '') where = { ...where, product_reference: filterTerm, all_versions: true };
        await this.props.policys_get_all(where);
        where = { policy_reference: searchTerm, format }
        if (filterTerm !== '') where = { ...where, product_reference: filterTerm };
        await this.props.policys_reporting_get_all({ ...where, format, limit, offset });
        this.setState({ isFetchingReports: false })
    }

    export() {
        const { data } = this.props.policys_reporting;
        const { format } = this.state;

        this.setState({
            is_downloading: true,
        });

        let content;
        let file = 'bordereaux-' + moment().format('YYYY-MM-DD');

        if (format === 'json') {
            file = `${file}.json`;
            content = new Blob([JSON.stringify(data, null, 4)], {
                type: 'application/json',
                name: file
            });
        }

        if (format === 'csv') {
            file = `${file}.csv`;
            content = new Blob([data], {
                type: 'text/csv',
                name: file
            });
        }

        if (format === 'edi') {
            file = `${file}.edi`;
            content = new Blob([data], {
                type: 'text',
                name: file
            });
        }

        // Save the file
        saveAs(content, file);

        this.setState({
            is_downloading: false,
        });
    }

    downloadFile(downloadInfo) {
        this.setState({ 
            isDownloadModalOpen: false,
            format: downloadInfo.format, 
            limit: downloadInfo.limit || null,
            offset: downloadInfo.offset || null,
        }, async () => {
            await this.search();
            await this.export();
        })
    }

    toggleDownloadModal() {
        const { isDownloadModalOpen } = this.state;
        this.setState({ isDownloadModalOpen: isDownloadModalOpen ? false : true });
    }

    render() {
        const { is_downloading, searchTerm, is_searching, format, isDownloadModalOpen, isFetchingReports } = this.state;
        const { isFetching = true, data = [] } = this.props.policys;
        const { data: products = [] } = this.props.policys_products;
        // Checking only the currency of the first product. assume all products of a company uses same currency
        const currency = products.length !== 0 ? getCurrencySymbol(first(products).currency) : '£';
        const timezone = products.length !== 0 ? first(products).timezone : 'Europe/London';
        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    <div className="row">
                        <div className="col-sm-12 col-lg-6 mb-3">
                            {/* Fix error where navigating to page when this.props.policys_reporting has been done thus isFetching will be false even if defaulted to true */}
                            {!isFetchingReports && 
                                <span class="badge bg-green">{lang.t('policy.reports.records')}: {this.countReports()}</span>
                            }
                            {isFetchingReports &&
                                <span class="badge bg-green">{lang.t('policy.reports.records')}: 1000</span>
                            }
                        </div>
                        <div class="col-auto ml-auto d-print-none mb-3">
                            <button onClick={(e) => {
                                e.preventDefault();
                                this.toggleDownloadModal()
                            }} type="button" className={`btn btn-blue btn-sm mr-3`}>{lang.t('buttons.download')}</button>
                        </div>
                    </div>

                    {/* Search */}
                    <div className="row">
                        <div className="col-sm-12 col-lg-8">
                            <ProductsFilterDropDown
                                products={this.props.policys_products}
                                update={this.filter}
                                lang={lang}
                            />
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                                <label className="form-label">{lang.t('policy.policies.searchByPolicy')}</label>
                                <Search
                                    searchTerm={searchTerm}
                                    searchFunction={this.term}
                                    isFetching={isFetching}
                                    label={lang.t('policy.reports.policies')}
                                    lang={lang}
                                />
                            </div>
                        </div>
                    </div>

                    {!isFetching && !is_searching &&
                        <div class="row mb-3">
                            <div className="col-sm-12 col-lg-12">
                                <PolicyVersionsTable 
                                    data={data.slice(0, 50)}
                                    currency={currency}
                                    timezone={timezone}
                                    lang={lang}
                                />
                            </div>
                        </div>
                    }

                    {/* Loader */}
                    {(isFetching || is_searching || is_downloading)   && <SpinnerBody />}


                    {/* Download Modal */}
                    {isDownloadModalOpen &&
                        <DownloadModal 
                            toggleDownloadModal={this.toggleDownloadModal}
                            downloadFile={this.downloadFile}
                            lang={lang}
                        /> 
                    }
                </div>
            </div>
        )
    }
}

export default main(Bordereaux);