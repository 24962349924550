import React, { Component } from 'react';
import { round, meanBy, max, min } from 'lodash';

function buildStats(data = []) {
    const valid = data.filter(d => !!d.exclusion_count === false);
    const invalid = data.filter(d => !!d.exclusion_count === true);
    const referred = data.filter(d => !!d.refer_count === true);
    return {
        valid_count: valid.length,
        invalid_count: invalid.length,
        valid_premium: round(meanBy(valid, v => v.base_premium), 2),
        invalid_premium: round(meanBy(invalid, v => v.base_premium), 2),
        quotability: round((valid.length / (data.length)) * 100, 1),
        referred_count: referred.length,
        premium: round(meanBy(data, v => v.base_premium), 2),
        max_premium: round(max(data.map(d => d.base_premium)), 2),
        min_premium: round(min(data.map(d => d.base_premium)), 2),
    }
}

class ScenariosStats extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const result = buildStats(this.props.data);
        const lang = this.props.lang;
        return (
            <div className="row">
                <div className="col-sm-6 col-xl-3">
                    <div className="card card-sm">
                        <div className="card-body d-flex align-items-center">
                            <span className="bg-green text-white stamp mr-3">
                                <i className="fe fe-check"></i>
                            </span>
                            <div className="mr-3 lh-sm">
                                <div className="strong">
                                {result.valid_count} {lang.t('rating.components.tests.validQuotes')}
                                </div>
                                <div className="text-muted">{`${lang.t('currency')}${result.valid_premium} ${lang.t('rating.components.tests.avePremium')}`}</div>
                            </div>`
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card card-sm">
                        <div className="card-body d-flex align-items-center">
                            <span className="bg-red text-white stamp mr-3">
                                <i className="fe fe-x"></i>
                            </span>
                            <div className="mr-3 lh-sm">
                                <div className="strong">
                                {result.invalid_count} {lang.t('rating.components.tests.invalidQuotes')}
                                </div>
                                <div className="text-muted">{`${lang.t('currency')}${result.invalid_premium} ${lang.t('rating.components.tests.avePremium')}`}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card card-sm">
                        <div className="card-body d-flex align-items-center">
                            <span className="bg-blue text-white stamp mr-3">
                                <i className="fe fe-percent"></i>
                            </span>
                            <div className="mr-3 lh-sm">
                                <div className="strong">
                                {result.quotability}% {lang.t('rating.components.tests.quotability')}
                                </div>
                                <div className="text-muted">{lang.t('rating.components.tests.with')} {result.referred_count} {lang.t('rating.components.tests.referredQuotes')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card card-sm">
                        <div className="card-body d-flex align-items-center">
                            <span className="bg-blue text-white stamp mr-3">
                                <i className="fe fe-star"></i>
                            </span>
                            <div className="mr-3 lh-sm">
                                <div className="strong">
                                {`${lang.t('currency')}${result.premium} ${lang.t('rating.components.tests.avePremium')}`}
                                </div>
                                <div className="text-muted">{`${lang.t('currency')}${result.min_premium} - ${lang.t('currency')}${result.max_premium}`}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ScenariosStats;