import React, { Component } from 'react';
import DatePickerModule from 'react-datepicker';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.styles.css";

// NOTE: Overriding some CSS in react-datepicker as it would not allow you to override what the calendar should show as 'today'
// For example in Australia, they could be T + 1 day ahead of the UK
// We want the date picker to show T + 1 as 'today' rather than T (think this happens due to react datepicker picking up browser time)
// Can get round this by passing in 'highlightDates' prop into date picker and modifying the styling

class DatePicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            edited: false,
        }

        this.handleManualEntry = this.handleManualEntry.bind(this);
        this.handlePickerSelect = this.handlePickerSelect.bind(this);
        this.handleDateTypes = this.handleDateTypes.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && this.props.value) {
            this.setState({
                edited: true,
            });
        }
    }

    //Have two handlers to reduce onscreen lag that comes with onChange prop with DatePicker

    formatDate(date, type) {
        if (!date) return '';

        let result = moment(date).format('YYYY-MM-DD');

        if (type === 'unix') {
            result = moment(date).unix();
        }

        return result;
    }


    handlePickerSelect(date) {
        const { property, update, type } = this.props;

        update({
            [property]: this.formatDate(date, type)
        });
    }

    handleManualEntry ({ target: { value } }) {
        const { property, update, type } = this.props;

        const date = value ? moment.utc(value, 'DD/MM/YYYY') : '';

        update({
            [property]: this.formatDate(date, type)
        });
    }

    handleDateTypes(date, type) {
        if (!date || date === 'Invalid Date')  return '';

        if (type==='unix') {
            return new Date(date * 1000);
        }

        return new Date(date);
    }

    handleOnChange(date){
        const { property, update, type } = this.props;

        update({
            [property]: this.formatDate(date, type)
        });
    }

    render() {
        const { 
            value,
            label,
            disabled,
            type,
            width = 140,
            dateFormat = 'dd/MM/yyyy',
            showMonthYearPicker = false,
            showFullMonthYearPicker = false,
            showFourColumnMonthYearPicker = false,
            showTimePicker = false,
            placeholderText = '',
            minDate = '',
            maxDate = '',
        } = this.props;

        const { edited } = this.state;
        const minDateString = moment.unix(minDate / 1000).format('YYYY-MM-DD');
        
        return (
            <div className="input-group mb-2">
                <div className="input-group-prepend">
                    <span className="input-group-text" style={{ width }}>{label}</span>
                </div>
                <DatePickerModule
                    className={`form-control ${edited && value ? 'is-valid': ''}`}
                    wrapperClassName='form-control date-picker'
                    disabled={disabled}
                    onBlur={this.handleManualEntry}
                    onSelect={this.handlePickerSelect}
                    selected={this.handleDateTypes(value, type)}
                    onChange={this.handleOnChange}
                    dateFormat={dateFormat}
                    showMonthYearPicker={showMonthYearPicker}
                    showFullMonthYearPicker={showFullMonthYearPicker}
                    showFourColumnMonthYearPicker={showFourColumnMonthYearPicker}
                    placeholderText={placeholderText}
                    minDate={minDate}
                    maxDate={maxDate}
                    showTimeSelect={showTimePicker}
                    timeFormat="HH:mm"
                    highlightDates={[new Date(minDateString)]}
                />
            </div>
        )
    }
}

export default DatePicker;