import React from 'react';
import FormPricing from '../../Forms/FormPrice';

// Component is used for setting the price manually

function MTAManualPriceFees(props) {
    const { close, back, detail, update, calculatePriceUpdate, hasCalledRating, lang } = props;

    return (
        <div className="modal-content">
            <div className="modal-header theme-dark" >
                <h5 className="modal-title">{hasCalledRating ? lang.t('policy.policies.anyFurtherAdjustments') : lang.t('policy.policies.manualPriceEntry')}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
                    <i className="fe fe-x"></i>
                </button>
            </div>
            <div className="modal-body">
                {hasCalledRating &&
                    (
                        <>
                            <div className='alert alert-info mb-4' role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                {lang.t('policy.policies.ratingCallSuccessful')}
                            </div>
                            <div className='alert alert-info mb-4' role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                {lang.t('policy.policies.furtherAdjustments')}
                            </div>
                        </>
                    )
                }
                {!hasCalledRating &&
                    (
                        <>
                            <div className='alert alert-info mb-4' role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                {lang.t('policy.policies.chosenManualPrice')}
                            </div>
                            <div className='alert alert-info mb-4' role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                {lang.t('policy.policies.updateFieldsBelow')}
                            </div>
                        </>
                    )
                }
                <FormPricing
                    detail={detail}
                    update={update}
                    hasCalledRating={hasCalledRating}
                    isMta={true}
                    lang={lang}
                />
                <h5>{lang.t('policy.policies.seeMtaPremium')}</h5>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={back}
                >
                    {lang.t('buttons.back')}
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={calculatePriceUpdate}
                >
                    {lang.t('buttons.next')}
                </button>
            </div>
        </div>
    );
}

export default MTAManualPriceFees;