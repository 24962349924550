import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { parse_date } from '../Detail/Utils';
import { getNumberFormat } from '../../../../utils/numberFormat';
import PaginationNoIndex from '../../../../components/tables/PaginationNoIndex';

function toUpper(str = '') {
    return str?.toUpperCase();
}

class PolicyStatementsTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { 
            statements: data = [],
            currency,
            distance_units,
            timezone,
            page,
            update,
            policy_reference,
            product_reference,
            policys_statements_get_all,
            lang,
        } = this.props;
        return (
            <div className="card">
                <PaginationNoIndex
                    data={data}
                    page={page}
                    update={update}
                    policy_reference={policy_reference}
                    product_reference={product_reference}
                    fetchData={policys_statements_get_all}
                    lang={lang}
                />
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.reference')}</th>
                                <th>{lang.t('tableHeaders.subscription')}</th>
                                <th>{lang.t('tableHeaders.usage')}</th>
                                <th>{lang.t('tableHeaders.total')}</th>
                                <th>{lang.t('tableHeaders.journeys')}</th>
                                <th>{lang.t('tableHeaders.distance')}</th>
                                <th>{lang.t('tableHeaders.startDate')}</th>
                                <th className="w-1"/>
                            </tr>
                        </thead>
                        <tbody>
                            {data.filter((d, i) => i !== 50).map((d, i) => (
                                <tr key={`statements-${i}`}>
                                    <td data-label="reference">
                                        <div className="d-flex lh-sm py-1 align-items-center">
                                            <div className="flex-fill">
                                                <div className="strong">{toUpper(d.policy_reference)}</div>
                                                <div className="text-muted text-h5">{d.policy_version_reference}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-label="Subscription">
                                        <div className="text-h5">{getNumberFormat(currency, d.subscription_premium || 0, '$0,0.00')}</div>
                                    </td>
                                    <td data-label="Total">
                                        <div className="text-h5">{getNumberFormat(currency, d.usage_premium || 0, '$0,0.00')}</div>
                                    </td>
                                    <td data-label="Billed">
                                        <div className="text-h5"><b>{getNumberFormat(currency, d.total_premium || 0, '$0,0.00')}</b></div>
                                    </td>
                                    <td data-label="Count">
                                        <div className="text-muted text-h5">{d.journey_count}</div>
                                    </td>
                                    <td data-label="Distance">
                                        <div className="text-muted text-h5">
                                            { distance_units === 'miles' ?
                                                `${getNumberFormat(currency, d.total_miles, '0,0[.]0')} miles` :
                                                `${getNumberFormat(currency, d.total_kms, '0,0[.]0')} km`
                                            }
                                        </div>
                                    </td>
                                    <td data-label="EndDate">
                                        <div className="text-muted text-h5">{parse_date(d.statement_end_date, 'DD/MM/YYYY', timezone)}</div>
                                    </td>
                                    <td>
                                        <div className="btn-list flex-nowrap">
                                            <Link
                                                to={`/statements/detail/${d.policy_version_reference}`}
                                                className="btn btn-sm btn-primary"
                                            >{lang.t('policy.table.view')}</Link>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <PaginationNoIndex
                    data={data}
                    page={page}
                    update={update}
                    policy_reference={policy_reference}
                    product_reference={product_reference}
                    fetchData={policys_statements_get_all}
                    lang={lang}
                />
            </div>
        );
    }
}

export default PolicyStatementsTable;
