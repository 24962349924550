import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import { get, omit, first, last } from 'lodash';
import main from '../../../../hoc/main';
import { getId } from '../../../../utils/url';
import FormClaim from '../../components/Claims/FormClaim';
import ClaimVersions from '../../components/Claims/ClaimVersions';
import ClaimsDocuments from '../../components/Claims/ClaimsDocuments'
import SpinnerBody from '../../../../components/loaders/SpinnerBody';
import ModalDetail from '../../components/Claims/ModalDetail';

class ClaimDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            claim: {},
            isFetching: null,
            openDetailModal: false
        }
        this.refreshClaimData = this.refreshClaimData.bind(this);
        this.toggleDetailModal = this.toggleDetailModal.bind(this);
    }

    toggleDetailModal() {
        this.setState({
            openDetailModal: !this.state.openDetailModal
        })
    }

    async refreshClaimData(claim_reference) {
        const claimResult = await this.props.policys_claims_get({
            id:claim_reference
        });
        await this.props.policys_claims_documents_get_all({ claim_reference });
        this.setState({
            claim: get(claimResult, 'payload.data'),
        })
    }

    async componentDidMount() {
        this.setState({
            isFetching: true
        })
        const { history } = this.props;
        const claim_reference = getId(history);
        await this.refreshClaimData(claim_reference);
        await this.props.policys_products_get_all({});
        this.setState({
            isFetching: false
        })
    }
    
    render() {
        const { claim, isFetching, openDetailModal } = this.state;
        const { data: products = [] } = this.props.policys_products;
        const timezone = products.length !== 0 ? first(products).timezone : 'Europe/London';
        const { policy_reference, claim_reference, detail } = claim;
        const lang = getLangSource(this.props.lang);

        return (
            <div className='container my-3'>
                <div className='row'>
                    <div className='col-lg-12'>
                        {isFetching && <SpinnerBody />}
                        {!isFetching && (
                        <>
                            <FormClaim 
                                claim={omit(claim, 'claim_versions')}
                                timezone={timezone}
                                toggleDetailModal={this.toggleDetailModal}
                                lang={lang}
                            />
                            <ClaimVersions
                                claimVersions={claim.claim_versions} 
                                timezone={timezone} 
                                policy_reference={policy_reference}
                                claim_reference={claim_reference}
                                policys_claims_put={this.props.policys_claims_put}
                                refreshClaimData={this.refreshClaimData}
                                lang={lang}
                            />
                            <ClaimsDocuments 
                                files={get(this.props.policys_claims_documents, 'data', [])}
                                timezone={timezone}
                                policys_claims_documents_get={this.props.policys_claims_documents_get}
                                policys_claims_documents_get_all={this.props.policys_claims_documents_get_all}
                                policys_claims_documents_post={this.props.policys_claims_documents_post}
                                policys_claims_documents_put={this.props.policys_claims_documents_put}
                                policys_claims_documents_delete={this.props.policys_claims_documents_delete}
                                claim_reference={claim_reference}
                                latestClaimVersion={last(claim.claim_versions)}
                                policys_claims_put={this.props.policys_claims_put}
                                policy_reference={policy_reference}
                                refreshClaimData={this.refreshClaimData}
                                lang={lang}
                            />
                        </>
                        )}
                    </div>
                </div>
                <ModalDetail
                    openDetailModal={openDetailModal}
                    toggleDetailModal={this.toggleDetailModal}
                    data={{"detail": detail}}
                    lang={lang}
                />
            </div>
        )
    }
}

export default main(ClaimDetail);