import React, { Component } from 'react';
import { round, sum } from 'lodash';

class TableChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clip: true,
        }
        this.clip = this.clip.bind(this);
    }

    clip() {
        this.setState({
            clip: !this.state.clip,
        })
    }

    render() {
        const { data = {}, header, lang } = this.props;
        const { clip } = this.state;
        const total = sum(Object.values(data).map(d => round(d, 0)))
        return (
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                        {header} 
                        {clip && <button style={{
                                    position: 'absolute',
                                    top: 14,
                                    right: 20,
                        }} onClick={this.clip} className="btn btn-sm btn-primary">{lang.t('rating.components.tableChart.expand')}</button>}
                        {!clip && <button style={{
                                    position: 'absolute',
                                    top: 14,
                                    right: 20,
                        }} onClick={this.clip} className="btn btn-sm btn-primary">{lang.t('rating.components.tableChart.collapse')}</button>}
                    </h3>
                </div>
                <table className="table card-table table-vcenter">
                    <thead>
                        <tr>
                            <th>{lang.t('rating.components.tableChart.name')}</th>
                            <th colspan="2">{`${lang.t('rating.components.tableChart.count')} (${lang.t('rating.components.tableChart.total')}${total})`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(data).slice(0, clip ? 10 : 100).map(k => {
                            const item = data[k];
                            const perc = round((data[k] / total) * 100, 1);
                            return (
                                <tr>
                                    <td>{k}</td>
                                    <td>{item} ({perc}%)</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

        );
    }
}

export default TableChart;