import BaseActions from '../../../actions/base';
import services from '../config';

const { root } = services.payments;

const identitys = new BaseActions({
    path: `${root}/identitys`,
    type: 'PAYMENTS_IDENTITYS',
});

export default identitys;
