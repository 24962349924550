import React, { Component } from 'react';
import clientConfig from '../../../../../config/client_config';
import ValidationCheck from './components/ValidationCheck';
import SelectPriceMethod from './components/SelectPriceMethod';
import RenewalManualPriceFees from './components/RenewalManualPriceFees';
import RenewalSummary from './components/RenewalSummary';
import ChangeSuccess from './components/ChangeSuccess';
import { omit, get, round } from 'lodash';
import axios from 'axios';
import moment from 'moment-timezone';
import { 
    format_policy, 
    format_proposer,
    format_vehicle,
    format_add_drivers,
    format_pricing,
    calculate_price_change,
} from '../Utils';

class ModalConfirmRenewal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isSubmitting: false,
            price_change: {},
            stage: 'price_method',
            stageArray: ['price_method'],
            hasCalledRating: false,
        }

        this.close = this.close.bind(this);
        this.submit = this.submit.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.calculatePriceUpdate = this.calculatePriceUpdate.bind(this);
        this.callRatingEngine = this.callRatingEngine.bind(this);
        this.manualPriceUpdate = this.manualPriceUpdate.bind(this);

        
    }

    close() {
        if(this.state.stage === 'renewal_success') {
            return window.location.reload();
        }

        this.props.modal_open({
            confirm_change: {
                open: false,
            }
        });
        this.setState({
            error: null,
            submitted: false,
            stage: 'price_method',
            stageArray: ['price_method'],
            price_change: {}
        });

    }

    updatePriceChange(price_change) {
        this.setState({ price_change })
    }

    updatePaymentPricing(price_change) {
        const { detail, update } = this.props;

        update({
            ...detail,
            payment: {
                payment_amount: round(price_change.calc_amount + price_change.mta_fee, 2),
                payment_provider: 'stripe',
            },
            pricing: {
                ...detail.pricing,
                upfront_premium: round(price_change.calc_amount, 2),
            }
        });
    }

    cleanPolicyObject(obj, enableCodelist) {

        //1. Get Renewal dates based on most recent policy version

        const version_latest_end_date = get(obj, 'versions[0].end_date');
        const renewal_start_date = moment(version_latest_end_date).add(1, 'seconds').unix();
        const renewal_end_date = moment(version_latest_end_date).add(1, 'year').unix();

        //2. Remove versions and version type from the object and Format verbose labels to code and ensure correct types

        let result = omit({
            ...obj,
            policy: format_policy(obj.policy, enableCodelist),
            proposer: format_proposer(obj.proposer, enableCodelist),
            additional_drivers: format_add_drivers(obj.additional_drivers, enableCodelist),
            vehicle: format_vehicle(obj.vehicle, enableCodelist),
            pricing: format_pricing(obj.pricing),
        }, 'versions')

        //3. Add renewed dates to policy and version type

        result = {
            ...result,
            policy: {
                ...result.policy,
                effective_date: renewal_start_date,
                start_date: renewal_start_date,
                end_date: renewal_end_date,
                is_renewal: true,
            }
        }

        return result;
    }

    manualPriceUpdate() {
        this.setState({ 
            hasCalledRating: false,
            error: null,
        });
        this.next('price_fees');
    }

    async callRatingEngine() {
        const { auth_details, detail } = this.props;
        const companyRef = get(auth_details, 'data.company_reference');
        const endpointDetails = get(clientConfig, `${companyRef}.mta_quote`);
        const enableCodelist = get(clientConfig, `${companyRef}.codelist.enabled`);

        const cleaned = this.cleanPolicyObject(detail, enableCodelist);

        // Call rating engine

        try {
            this.setState({ 
                isSubmitting: true,
                hasCalledRating: true,
            });

            // harcoded for now
            const model_reference = 'dc6df20a-6cb2-4890-9226-039790a89c63'

            const { data } = await axios({
                url: `https://api.bybits.co.uk/rating/quotes/${model_reference}`,
                data: cleaned,
                method: 'POST',
                headers: {
                    'client_id': '3941eb25-5df1-4aee-be76-fb0a6a432b73',
                    'client_secret': 'f434b6a0-f762-4276-a153-61fe9fde6288',
                    'environment': 'sandbox',
                },
            });

            // Update pricing block of the policy object - upfront premium to be updated just before policy PUT as it is calculated in next step

            this.props.update({
                ...detail,
                pricing: {
                    ...detail.pricing,
                    quote_model_reference: data.model_reference,
                    quote_reference: data.quote_reference,
                    quote_version_reference: data.version_reference,
                    upfront_rate: data.upfront_rate,
                    usage_rate: data.usage_rate,
                    subscription_rate: data.subscription_rate,
                }
            });

            // Move to next step;
            this.next('price_fees');
            
        } catch (err) {
            console.log(err);
            console.log(err.response);
            
            if (err.response) {
                this.setState({ 
                    error: `ERROR - ${get(err, endpointDetails.error_status_path)} - ${get(err, endpointDetails.error_message_path)}`,
                    hasCalledRating: false,
                });
            } else {
                this.setState({
                    error: err.message,
                    hasCalledRating: false,
                })
            }
            
        } finally {
            this.setState({ isSubmitting: false });
        }
    }

    calculatePriceUpdate() {
        const { detail, detail_readonly } = this.props;

        const after = {
            ...detail.pricing,
            ...detail.policy,
        }

        const price_change = calculate_price_change(detail_readonly.versions[0], after);

        this.updatePaymentPricing(price_change);
        this.updatePriceChange(price_change);
        this.next('renewal_summary');
    }

    next(stage) {

        if (this.state.stage === 'price_method'){

            const { update, detail } = this.props;

            // update start and effective date to 1 second after the end date of previous policy, update end date to a year after end date
            const version_latest_end_date = get(detail, 'versions[0].end_date');
            const renewal_start_date = moment(version_latest_end_date).add(1, 'seconds').unix();
            const renewal_end_date = moment(version_latest_end_date).add(1, 'year').unix();

            update({
                ...detail,
                policy: {
                    ...detail.policy,
                    effective_date: renewal_start_date,
                    start_date: renewal_start_date,
                    end_date: renewal_end_date,
                }
            });
        }


        this.setState({
            stage,
            stageArray: [...this.state.stageArray, stage],
        });
    }

    back() {
        const { stageArray } = this.state
        stageArray.pop();
        this.setState({
            stage: stageArray[stageArray.length - 1],
            price_change: {},
            error: null, //If user navigated back from MTA summary due to error, reset the error
        });
    }

    async submit() {
        const { detail, policys_put, auth_details } = this.props;
        const companyRef = get(auth_details, 'data.company_reference');
        const enableCodelist = get(clientConfig, `${companyRef}.codelist.enabled`);

        this.setState({ 
            error: null, 
            isSubmitting: true,
        });

        // 1. Clean policy object again
        const result = this.cleanPolicyObject(detail, enableCodelist);

        try {

            // 2. Submit to put policys endpoint 
            await policys_put({
                id: result.policy_reference,
                data: result,
            });

            // 3. Update state and move to next stage

            this.setState({
                isSubmitting: false,
            });

            this.next('renewal_success');

        } catch(e) {
            console.log(e)
            console.log(e.message)
            this.setState({ 
                error: get(e, 'error.response.data.message', e.error.data),
                isSubmitting: false, 
            });
        }
    }


    render() {
        const { open, edited, detail, update, pol_obj_validation, timezone, lang } = this.props;
        const { error, isSubmitting, price_change, stage, hasCalledRating } = this.state;
        const { isValid, valErrors } = pol_obj_validation;


        return(
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-mod" tabIndex="-1" role="dialog" aria-modal="true">
                {!isValid && (
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <ValidationCheck 
                            next={this.next}
                            close={this.close}
                            edited={edited}
                            valErrors={valErrors}
                            lang={lang}
                        />
                    </div>
                )}
                {isValid && (
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        {stage === 'price_method' && 
                            <SelectPriceMethod
                                close={this.close}
                                back={this.back}
                                callRatingEngine={this.callRatingEngine}
                                error={error}
                                isSubmitting={isSubmitting}
                                manualPriceUpdate={this.manualPriceUpdate}
                                title="Select method for updating price"
                                text="The Request Renewal Price option will call the rating engine to generate a price. Manual Price Entry option allows the price to be manually entered."
                                buttonOne="Manual Price Entry"
                                buttonTwo="Request Renewal Price"
                                isClose
                            />
                        }
                        {stage === 'price_fees' &&
                            <RenewalManualPriceFees 
                                close={this.close}
                                back={this.back}
                                next={this.next}
                                detail={detail}
                                update={update}
                                calculatePriceUpdate={this.calculatePriceUpdate}
                                hasCalledRating={hasCalledRating}
                                type="Renewal"
                                lang={lang}
                            />
                        }
                        {stage === 'renewal_summary' &&
                            <RenewalSummary
                                close={this.close}
                                back={this.back}
                                submit={this.submit}
                                price_change={price_change}
                                detail={detail}
                                error={error}
                                isSubmitting={isSubmitting}
                                timezone={timezone}
                            />
                        }
                        {stage === 'renewal_success' &&
                            <ChangeSuccess 
                                close={this.close}
                                title_text='Renewal Processed'
                                alert_text='Renewal has been submitted successfully'
                                body_text='Renewal is effective from the previous end date of your policy'
                                
                            />
                        }
                    </div>
                )}
            </div>
        )
    }
}

export default ModalConfirmRenewal;
