import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import main from '../../../../hoc/main';
import { get } from 'lodash';

// Core Design
import SpinnerBody from '../../../../components/loaders/SpinnerBody';

import PacketsTable from '../../components/Telematics/PacketsTable';

class PacketsDevices extends Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
    }

    async componentWillMount() {
        await this.props.telematics_packets_get_all({}) 
    }

    async search(search) {
        await this.props.telematics_packets_get_all({
            device_reference: search
        }) 
    }

    render() {
        const {
            isFetching = true,
            selected = {},
            data = [],
        } = this.props.telematics_packets;

        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-sm-12 col-lg-6">
                            <div className="page-pretitle">{lang.t('nav.telematics')}</div>
                            <h2 className="page-title">{lang.t('nav.packets')}</h2>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                        </div>
                        <div className="col-sm-12 col-lg-3">
                        </div>
                    </div>
                    
                    {/* Loader */}
                    {isFetching && <SpinnerBody />}

                    {!isFetching &&
                        <div className="row">
                            <div className="col-lg-12">
                                <PacketsTable 
                                    data={data}
                                    policy_detail={false}
                                    selected={get(this.props.telematics_packets, 'selected', {})}
                                    telematics_packets_get={this.props.telematics_packets_get}
                                    policys_get_all={this.props.policys_get_all}
                                    lang={lang}
                                />
                            </div>
                        </div>
                    }
                 
              </div>
          </div>
        );
    }
}

export default main(PacketsDevices);