import React from 'react';

function Input (props) {
    const {
        label,
        value = '',
        update,
        validation,
        type = 'text',
        disabled = false
    } = props;

    const is_valid = validation ? validation(value) : '';
    let classes = 'form-control';
    if(value && validation) {
        classes = is_valid ? 'form-control is-valid' : 'form-control is-invalid'
    }

    return (
        <input
            type={type}
            className={classes}
            autoComplete="off"
            defaultValue={value}
            label={label}
            disabled={disabled}
            onChange={(e) => update({
                [label]: e.target.value,
            })}
        />
    )
}

export default Input;
