import React from 'react';
import FormPricing from '../../Forms/FormPrice';

// Component is used for setting the price manually

function RenewalManualPriceFees(props) {
    const { close, back, detail, update, calculatePriceUpdate, hasCalledRating, lang } = props;

    return (
        <div className="modal-content">
            <div className="modal-header theme-dark" >
                <h5 className="modal-title">{hasCalledRating ? 'Any further adjustments to price?': 'Manual price entry'}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
                    <i className="fe fe-x"></i>
                </button>
            </div>
            <div className="modal-body">
                {hasCalledRating &&
                    (
                        <>
                            <div className='alert alert-info mb-4' role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                The rating engine has been called successfully and the Upfront, Usage and Subscription Rates have been updated.
                            </div>
                            <div className='alert alert-info mb-4' role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                Further manual adjustments can be made to the items below.
                            </div>
                        </>
                    )
                }
                {!hasCalledRating &&
                    (
                        <>
                            <div className='alert alert-info mb-4' role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                You have chosen to manually enter the price and not call the rating engine.
                            </div>
                            <div className='alert alert-info mb-4' role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                Please update the fields below as appropriate.
                            </div>
                        </>
                    )
                }
                <FormPricing
                    detail={detail}
                    update={update}
                    hasCalledRating={hasCalledRating}
                    isRenewal={true}
                    lang={lang}
                />
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={back}>Back</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={calculatePriceUpdate}>Next</button>
            </div>
        </div>
    );
}

export default RenewalManualPriceFees;