import {
    uniqBy,
    filter,
    round,
    sumBy,
    orderBy,
    each,
    groupBy,
    sum,
    keys,
    meanBy,
    first,
} from 'lodash';

import moment from 'moment';

const { cohortsConfig } = require('./config');

function riskStats (data = [], providedClaims) {

    let claims = [];
    if (providedClaims) {
        claims = providedClaims;
    } else {
        data.forEach(d => {
            claims = [...claims, ...(d.claims || [])];
        });
    }

    const at_fault_claims = filter(claims, d => d.type !== 'W' && d.at_fault === true);

    // Meta
    const itemCount = data.length;
    const policyCount = uniqBy(data, 'policy_ref').length;
    const nonFaultClaimsCount = filter(claims, d => d.at_fault === false).length;
    const faultClaimsCount = at_fault_claims.length;
    const exposureSumYears = round(sumBy(data, d => d.exposure) / 365, 0);
    const exposureSumMiles = round(sumBy(data, d => d.miles), 0);
    const activePolicyCount = uniqBy(filter(data, d => moment(d.policy_end_date) >= moment()), 'policy_ref').length;
    const lapsedPolicyCount = policyCount - activePolicyCount;

    // Number
    const earnedPremium = round(sumBy(data, d => d.earned_total_premium), 0);
    const earnedMilePremium = round(sumBy(data, d => d.earned_mileage_premium), 0);
    const claimInc= round(sumBy(at_fault_claims, d => d.incurred_amount || 0), 0);
    const claimEst = round(sumBy(at_fault_claims, d => d.estimated_amount || 0), 0);
    const claimsFaultFreq = faultClaimsCount === 0 ? 0 : round((faultClaimsCount /exposureSumYears) * 100, 1);
    const claimsNonFaultFreq = nonFaultClaimsCount === 0 ? 0 : round((nonFaultClaimsCount/exposureSumYears) * 100, 1);
    const LRInc = claimInc === 0 ? 0 : round((claimInc/earnedPremium) * 100, 1);
    const LREst = claimEst === 0 ? 0 : round((claimEst/earnedPremium) * 100, 1);

    return {
        itemCount,
        policyCount,
        faultClaimsCount,
        nonFaultClaimsCount,
        exposureSumYears,
        exposureSumMiles,
        activePolicyCount,
        lapsedPolicyCount,
        earnedPremium,
        earnedMilePremium,
        claimInc,
        claimEst,
        claimsFaultFreq,
        claimsNonFaultFreq,
        LRInc,
        LREst,
    }
}

function monthlyStatsStartDate(data = [],) {
    const grouped = groupBy(data, d => {
        return moment(d.policy_start_date).format('YYYY-MM');
    });
    const months = [];
    each(grouped, (v, k) => {
        const stats = riskStats(v);
        months.push({
            month: k,
            ...stats
        });
    });
    return orderBy(months, 'month', 'desc');
}

function monthlyStatsAccrued (data = []) {
    let allPremiumMonths = [];
    let allClaimMonths = [];

    data.forEach(d => {
        allPremiumMonths = [ ...allPremiumMonths, ...d.premiums];
        allClaimMonths = [ ...allClaimMonths, ...d.claims];
    });

    const groupedPremium = groupBy(allPremiumMonths, 'month');
    const groupedClaims = groupBy(allClaimMonths, 'month');
    let allMonths = [];

    Object.keys(groupedPremium).forEach(k => {
        const stats = riskStats(groupedPremium[k], groupedClaims[k]);
        allMonths = [...allMonths, {
            month: k,
            ...stats,
        }];
    })
    return orderBy(allMonths, 'month', 'desc');
}

function monthlyRolling(data = [], period) {
    const result = [];
    data.forEach((d, i) => {
        const window = data.slice(i, i + period);
        result.push({
            month: data[i].month,
            claimsFaultFreq: round(meanBy(window, w => w.claimsFaultFreq), 2), 
            LRInc: round(meanBy(window, w => w.LRInc), 2),
        })
    });
    return result;
}

function accidentStats(data = []) {
        // Type
        const accidentType = filter(data, d => d.claim_f_type === 'A').length;
        const windscreenType = filter(data, d => d.claim_f_type === 'W').length;
        const vandalismType = filter(data, d => d.claim_f_type === 'V').length;
        const fireType = filter(data, d => d.claim_f_type === 'F').length;
        const theftType = filter(data, d => d.claim_f_type === 'Q').length;
        const fuelType = filter(data, d => d.claim_f_type === 'C').length;
        const otherType = filter(data, d => {
            return !['A', 'W', 'V', 'Q', 'F', 'C'].includes(d.claim_f_type) &&
            d.claim_f_type !== '';
        }).length;

        // Journey
        const parkType = filter(data, d => d.claim_f_jour_type === 'P').length;
        const movingType = filter(data, d => d.claim_f_jour_type === 'M').length;
        const journeyType = filter(data, d => d.claim_f_jour_type === 'J').length;

        return {
            fireType,
            fuelType,
            accidentType,
            windscreenType,
            vandalismType,
            otherType,
            theftType,
            parkType,
            movingType,
            journeyType,
        }
}

function chartData({
    selected_risks,
    selected_cohorts,
    data,
    best,
    worst,
}) {
    const charts = {};
    const config = cohortsConfig(selected_risks, data, selected_cohorts);
    
    Object.keys(selected_risks)
        .filter(k => selected_risks[k].active === true)
        .forEach(k => {
            const base = config[k];
            const chunk = data.map(d => d[k]) || [];
            const bestChunk = best.map(d => d[k]) || [];
            const worstChunk = worst.map(d => d[k]) || [];

            charts[k] = {};
            let results = [];
            let worstResults = [];
            let bestResults = [];

            const worstScale = worst.length === 0 ? 0 : data.length / worst.length;
            const bestScale = best.length === 0 ? 0 :  data.length / best.length;

            if(base.type === 'int') {
                base.values.forEach((v, i) => {
                    const next = base.values[i + 1];
                    if(next) {
                        results.push(chunk.filter(c => (c >= v && c < next)).length);
                        bestResults.push(round(bestChunk.filter(c => (c >= v && c < next)).length * bestScale, 0));
                        worstResults.push(round(worstChunk.filter(c => (c >= v && c < next)).length * worstScale, 0));
                    } else {
                        results.push(chunk.filter(c => (c >= v)).length);
                        bestResults.push(round(bestChunk.filter(c => (c >= v)).length * bestScale, 0));
                        worstResults.push(round(worstChunk.filter(c => (c >= v)).length * worstScale, 0));
                    }
                });
            } else {
                base.values.forEach((v, i) => {
                    results.push(chunk.filter(c => c === v).length);
                    bestResults.push(round(bestChunk.filter(c => c === v).length * bestScale, 0));
                    worstResults.push(round(worstChunk.filter(c => c === v).length * worstScale, 0));
                });
            }

            charts[k].categories = base.values;

            charts[k].all = results.map(r => {
                const total = sum(results);
                return round((r / total) * 100, 1)
            });

            charts[k].best = bestResults.map((b, i) => {
                const w = worstResults[i];
                if (b === 0 && w === 0) return 0;
                return round((b / (b + w)) * 100, 1);
            });

            charts[k].worst = worstResults.map((w, i) => {
                const b = bestResults[i];
                if (b === 0 && w === 0) return 0;
                return round((w / (b + w)) * 100, 1);
            });

        });

   return charts;
}

function lastUpdate(data = [], key) {
    return moment((first(data) || {})[key]).fromNow();
}

export {
    chartData,
    riskStats,
    monthlyStatsStartDate,
    monthlyStatsAccrued,
    monthlyRolling,
    accidentStats,
    lastUpdate,
}