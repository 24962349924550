import React from 'react';
import ReactJson from 'react-json-view';

export default function ModalDetail(props) {
    const { openDetailModal, toggleDetailModal, data, lang } = props;
    
    return (
        <div className={`modal modal-blur fade ${openDetailModal ? 'show' : ''}`} style={{ display: openDetailModal ? 'block' : '' }} id="modal-scrollable" tabIndex="-1" role="dialog" aria-modal="true">
            <div className='modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered' role='document'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title'>{lang.t('policy.claims.details')}</h5>
                        <button type='button' onClick={toggleDetailModal} className='close' data-dismiss='modal' aria-label='Close'>
                            <i className="fe fe-x"></i>
                        </button>
                    </div>
                    <div className='modal-body' style={{height: "70vh"}}>
                        <ReactJson 
                            src={data}
                            collapsed={3}
                            theme={'rjv-default'}
                            enableClipboard={false}
                            displayDataTypes={false}
                            name={false}
                            style={{
                                background: 'transparent',
                                fontFamily: 'monaco',
                                fontSize: '12px',
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
