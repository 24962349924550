import React, { Component } from 'react';
import SuperTable from '../../../../components/tables/Super';
import { formatValues } from '../../utils/formatValues';
import { getCodeList } from '../../utils/getCodeList';

class TableClaims extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], lang } = this.props;

        let headers = [
            {
                label: lang.t('rating.components.tableClaims.date'),
                name: 'date',
                search: false,
            },
            {
                label: lang.t('rating.components.tableClaims.code'),
                name: 'code',
            },
            {
                label: lang.t('rating.components.tableClaims.atFault'),
                name: 'at_fault',
            },
            {
                label: lang.t('rating.components.tableClaims.ncd'),
                name: 'ncd_lost',
            },
        ];

        const Cells = (index, d) => {
            const at_fault = d.at_fault
            const codes = getCodeList('proposer.claims.map(code)'); 
            return (
                <tr key={`claims-${index}`}>
                    <td data-label="Date">  
                        <div className="text-h5">{d.date}</div>
                    </td>
                    <td data-label="Code">
                        <div className="text-h5">
                            {formatValues(d.code, 'code', codes)}
                        </div>
                    </td>
                    <td data-label="At Fault">
                        <div className="text-h5">
                            {d.at_fault.toString()}
                        </div>
                    </td>
                    <td data-label="NCD Lost">
                        <div className="text-h5">
                            {d.at_fault.toString()}
                        </div>
                    </td>
                </tr>
            )
        }

        return(
            <SuperTable 
                data={data}
                headers={headers}
                Cells={Cells}
                auto_sort={true}
                lang={lang}
            />
        )
    }
}

export default TableClaims;