import React, { Component } from 'react';
import Input from './Input';
import DatePicker from './DatePicker';
import Select from '../Forms/Select';
import TableModifications from '../Tables/TableModifications';
import { get_codelist } from '../Utils';
import { get } from 'lodash';

class FormVehicle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching_vehicles: false,
            error: null,
        }
        this.update = this.update.bind(this);
        this.open_mod_modal = this.open_mod_modal.bind(this);
        this.get_vehicle = this.get_vehicle.bind(this);
    }

    open_mod_modal() {
        this.props.modal_open({
            add_mod: {
                open: true,
            }
        })
    }

    update(item) {
        const { detail } = this.props;
        const result = {
            ...detail,
            vehicle: {
                ...detail.vehicle,
                ...item,
            }
        };
        this.props.update(result);
    }

    async get_vehicle() {
        const { detail, vehicles_get, lang } = this.props;
        const { vehicle: { abi_code } } = detail;

        try {
            this.setState({
                fetching_vehicles: true,
                error: null,
            });

            const res = await vehicles_get({ id: abi_code });

            const data = res.payload.data;
            
            const result = {
                ...detail,
                vehicle: {
                    reg: detail.vehicle.reg,
                    estimated_yearly_mileage: detail.vehicle.estimated_yearly_mileage,
                    abi_code: data.abi_code,
                    make: data.make,
                    model: data.model,
                    fuel: data.fuel,
                    transmission: data.transmission,
                    engine: data.engine,
                    doors: data.doors,
                    seats: data.seats,
                    body_type: data.body_style.toUpperCase(),
                    type: data.body_style.toUpperCase()
                }
            }

            this.props.update(result);

            this.setState({
                fetching_vehicles: false,
            });
        } catch(err) {
            console.log(err);
            //TODO: Need to understand how the error message will be displayed
            this.setState({
                fetching_vehicles: false,
                error: get(err, 'error.response.data', err.error.data)
            });
        }
    }

    render() {
        const { detail, product_reference, disabled = false, lang } = this.props;
        const vehicle = detail.vehicle;
        const { fetching_vehicles, error } = this.state;

        /*
        "reg": "WO123XX",
      "vin": "YV1UZ25UCK1337428",
      "type": "01",
      "make": "tesla",
      "model": "S",
      "colour": "black",
      "group_rating_50": 21,
      "group_rating_20": 10,
      "abi_code": "01001000",
      "engine": 1650,
      "fuel": "001",
      "owner": "1",
      "keeper": "1",
      "estimated_yearly_mileage": 10000,
      "purchase_date": "2016-01-01",
      "manufacture_date": "2016-01-01",
      "current_value": 1000,
      "is_rhd": true,
      "seats": 1,
      "is_parked_home": true,
      "is_import": true,
      "modifications": "1"
        */
        return [
            <div className="card ml-2">
                <div className="card-header"><h3 className="card-title">{lang.t('policy.policies.vehicle')}</h3></div>
                <div className="card-body">
                    <div className="mb-1">
                        <Input
                            label='Annual mileage *'
                            property={'estimated_yearly_mileage'}
                            value={vehicle.estimated_yearly_mileage}
                            update={this.update}
                            type = 'number'
                            min={0}
                            disabled={disabled}
                        />
                        <Input
                            label={'Reg *'}
                            property={'reg'}
                            value={vehicle.reg}
                            update={this.update}
                            type = 'text'
                            disabled={disabled}
                        />
                        <Input 
                            label={`ABI code ${product_reference === 'tim_usage' ? '' : '*'}`}
                            property={'abi_code'}
                            value={vehicle.abi_code}
                            update={this.update}
                            type = 'text'
                            disabled={disabled}
                            search={!disabled}
                            search_fn={this.get_vehicle} 
                            isFetching={fetching_vehicles}
                        />
                        {error && <h5 className="text-danger text-right"><i className="fe fe-alert-triangle mr-2" ></i><b>{error}</b></h5>}
                        <Input
                            label='Make *'
                            property={'make'}
                            value={get(vehicle, 'make', "").toUpperCase()}
                            update={this.update}
                            type = 'text'
                            disabled={disabled}
                        />
                        <Input
                            label='Model *'
                            property={'model'}
                            value={get(vehicle, 'model', "").toUpperCase()}
                            update={this.update}
                            type = 'text'
                            disabled={disabled}
                        />
                        <Select
                            label={'Type *'}
                            property={'body_type'}
                            value={get(vehicle, 'body_type', "").toUpperCase()}
                            options={get_codelist('vehicle.body_type')}
                            update={this.update}
                            disabled={disabled}
                        />
                        <Input
                            label='Engine *'
                            property={'engine'}
                            value={vehicle.engine}
                            update={this.update}
                            type = 'number'
                            disabled={disabled}
                        />
                        <Select
                            label='Fuel *'
                            property='fuel'
                            options={get_codelist('vehicle.fuel')}
                            value={vehicle.fuel}
                            update={this.update}
                            disabled={disabled}
                        />
                        <Input 
                            property={'transmission'}
                            value={vehicle.transmission}
                            update={this.update}
                            type = 'text'
                            disabled={disabled}
                        />
                        <Input
                            label='Overnight postcode'
                            property={'overnight_postcode'}
                            value={vehicle.overnight_postcode}
                            update={this.update}
                            type = 'text'
                            disabled={disabled}
                        />
                        <Select
                            label='Parked location'
                            property='parked_location'
                            options={get_codelist('vehicle.parked_location')}
                            value={vehicle.parked_location}
                            update={this.update}
                            disabled={disabled}
                        />
                        <DatePicker 
                            label='Manufactured *'
                            property={'manufacture_date'}
                            value={vehicle.manufacture_date}
                            update={this.update}
                            disabled={disabled}
                            // dateFormat='MMMM yyyy'
                            showMonthYearPicker={true}
                            showFullMonthYearPicker={true}
                            showFourColumnMonthYearPicker={true}
                        />
                        <DatePicker 
                            label='Purchased *'
                            property={'purchase_date'}
                            value={vehicle.purchase_date}
                            update={this.update}
                            disabled={disabled}
                            // dateFormat='MMMM yyyy'
                            showMonthYearPicker={true}
                            showFullMonthYearPicker={true}
                            showFourColumnMonthYearPicker={true}
                        />
                        <Select 
                            label='Right hand drive'
                            property='is_rhd'
                            options={[{ label: 'true' }, { label: 'false' }]}
                            value={vehicle.is_rhd}
                            update={this.update}
                            disabled={disabled}
                        />
                        <Select 
                            label='Imported'
                            property='is_import'
                            options={[{ label: 'true' }, { label: 'false' }]}
                            value={vehicle.is_import}
                            update={this.update}
                            disabled={disabled}
                        />
                        <Input
                            label='Current value *'
                            property={'current_value'}
                            value={vehicle.current_value}
                            update={this.update}
                            type = 'number'
                            min={0}
                            disabled={disabled}
                        />
                        <Select 
                            label='Owner *'
                            property='owner'
                            options={get_codelist('vehicle.owner')}
                            value={vehicle.owner}
                            update={this.update}
                            disabled={disabled}
                        />
                        <Select 
                            label='Keeper *'
                            property='keeper'
                            options={get_codelist('vehicle.keeper')}
                            value={vehicle.keeper}
                            update={this.update}
                            disabled={disabled}
                        />
                        <Input
                            label='Seats *'
                            property={'seats'}
                            value={vehicle.seats}
                            update={this.update}
                            type = 'number'
                            min={0}
                            disabled={disabled}
                        />
                        <Input
                            label='Doors *'
                            property={'doors'}
                            value={vehicle.doors}
                            update={this.update}
                            type = 'number'
                            min={0}
                            disabled={disabled}
                        />
                        <Input
                            label='VIN'
                            property={'vin'}
                            value={vehicle.vin}
                            update={this.update}
                            type = 'text'
                            disabled={disabled}
                        />
                        <Input 
                            property={'colour'}
                            value={vehicle.colour}
                            update={this.update}
                            type = 'text'
                            disabled={disabled}
                        />
                        <hr />
                        <div>
                            <div style={{ marginBottom: '25px' }}>
                                <b>{lang.t('policy.policies.modifications')}</b>
                                {!disabled && 
                                    <a
                                        className="btn btn-primary btn-sm float-right text-white"
                                        onClick={this.open_mod_modal}
                                    >
                                        + {lang.t('policy.policies.addModification')}
                                    </a>
                                }
                            </div>
                            <TableModifications 
                                data={vehicle.modifications}
                                disabled={disabled}
                                update={this.update}
                                lang={lang}
                            />
                        </div>
                    </div>
                </div>
            </div>
        
        ];
    }
}

export default FormVehicle;