import React, { Component } from 'react';
import { round } from 'lodash';

// Service Design
import PolicyVersionsTable from '../../Versions/Table';
import { getCurrentVersion } from '../../../../../utils/getCurrentVersion.js';

class VersionsSection extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            versions,
            type,
            cognito,
            auth,
            payments,
            currency,
            timezone,
            policys_emails_post,
            policys_statements_get,
            lang,
        } = this.props;

        return (
            <>
            {versions.length ? (
                <div className="col-sm-12 col-lg-12">
                    <PolicyVersionsTable 
                        data={versions}
                        currentVersion={getCurrentVersion(versions)}
                        cognito={cognito}
                        auth={auth}
                        payments={payments}
                        show_button={true}
                        currency={currency}
                        timezone={timezone}
                        policys_emails_post={policys_emails_post}
                        policys_statements_get={policys_statements_get}
                        lang={lang}
                    />
                </div>
            ) : <div>Sorry, no policy versions found!</div>}
            </>

        );
    }
}

export default VersionsSection;