import React, { Component } from 'react';
import Avatar from '../../../../components/avatar'
import Pagination from '../../../../components/tables/Pagination';
import { sortBy, round, transform } from 'lodash';

class UsersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        }
        this.selectPage = this.selectPage.bind(this);
        this.updateUser = this.updateUser.bind(this);
    }

    badge(value) {
        let colour = 'badge bg-green';
        if (value < 500) colour = 'badge bg-yellow';
        if (value < 200) colour = 'badge bg-red';
        return (
            <div className={colour}>{value}</div>
        )
    }

    selectPage(page) {
        this.setState({
            page,
        });
    }

    updateUser ({ id, data }) {
        this.props.users_put({
            id,
            data,
        }).then(() => {
            this.props.auth_get_all({});
        });
    }

    render() {
        const { data = [], pages, authorisedUser, lang } = this.props;
        const roles = Object.keys(lang.lang.roles);
        const { page } = this.state;
        const no_of_pages = (data.length === 0 ? 0 : Math.ceil(data.length / pages));
        let pageData = sortBy(data, d => d.user_name);
        if (pages !== 0) pageData = data.slice((page * pages) - pages, (page * pages));
        return (
            <div className="card">
                <Pagination
                    page={page}
                    pages={pages}
                    no_of_pages={no_of_pages}
                    data={pageData}
                    selectPage={this.selectPage}
                    lang={lang}
                />
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.name')}</th>
                                <th>{lang.t('tableHeaders.username')}</th>
                                <th>{lang.t('tableHeaders.production')}</th>
                                <th>{lang.t('tableHeaders.sandbox')}</th>
                                <th className="w-1"></th>
                                <th className="w-1"></th>
                                <th className="w-1"></th>
                            </tr>
                        </thead>
                        <tbody>
                        {pageData.map((d, i) => (
                            <tr key={`users-${i}`}>
                                <td data-label="Name">
                                    <div className="d-flex lh-sm py-1 align-items-center">
                                        <div className="mr-2">
                                            <Avatar 
                                                f_names={d.f_names}
                                                l_names={d.l_names}
                                            />
                                        </div>
                                        <div className="flex-fill">
                                            <div className="strong">{d.f_names} {d.l_names}</div>
                                            <div className="text-muted text-h5">{d.email}</div>
                                        </div>
                                    </div>
                                </td>
                                <td data-label="Username">
                                    <div>{d.username}</div>
                                    <div className="text-muted text-h5">{d.role}</div>
                                </td>
                                <td data-label="Production">
                                    <div className="text-muted text-h5">CID: {d.client_id}</div>
                                    <div className="text-muted text-h5">CST:{d.client_secret}</div>
                                </td>
                                <td data-label="Sandbox">
                                    <div className="text-muted text-h5">CID: {d.client_id}</div>
                                    <div className="text-muted text-h5">CST: {d.client_secret_sandbox}</div>
                                </td>
                                <td className="w-1" data-label="Roles">
                                    <div className="dropdown position-static">
                                        <button 
                                            className="btn btn-primary btn-sm dropdown-toggle align-text-top" 
                                            data-boundary="viewport" 
                                            data-toggle="dropdown" 
                                        >{lang.t('settings.users.editRoles')}</button>
                                        <div className="dropdown-menu btn-smdropdown-menu-right">
                                            {roles.map(r => {
                                                return (
                                                    <a className="dropdown-item" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.updateUser({
                                                            id: d.user_reference,
                                                            data: {
                                                                role: r,
                                                            }
                                                        })
                                                    }}>
                                                        {lang.t(`roles.${r}`)}
                                                    </a>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </td>
                                <td className="w-1">
                                    <div className="btn-list flex-nowrap">
                                        <a className="btn btn-primary btn-sm text-white" style={{ whiteSpace: 'nowrap' }} onClick={(e) => {
                                            e.preventDefault();
                                            this.updateUser({
                                                id: d.user_reference,
                                                data: {
                                                    new_keys: true,
                                                }
                                            })
                                        }}>{lang.t('buttons.newKeys')}</a>
                                    </div>
                                </td>
                                <td className="w-1">
                                    <div className="btn-list flex-nowrap">
                                        {d.active === true && d.user_reference !== authorisedUser.account_id &&
                                            <a className="btn btn-red btn-sm text-white" onClick={(e) => {
                                                e.preventDefault();
                                                this.updateUser({
                                                    id: d.user_reference,
                                                    data: {
                                                        active: 'false',
                                                    }
                                                })
                                            }}>{lang.t('buttons.disable')}</a>
                                        }
                                        {d.active === false && d.user_reference !== authorisedUser.account_id &&
                                            <a className="btn btn-primary btn-sm text-white" onClick={(e) => {
                                                e.preventDefault();
                                                this.updateUser({
                                                    id: d.user_reference,
                                                    data: {
                                                        active: 'true',
                                                    }
                                                })
                                            }}>{lang.t('buttons.enable')}</a>
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    page={page}
                    pages={pages}
                    no_of_pages={no_of_pages}
                    data={data}
                    selectPage={this.selectPage}
                    lang={lang}
                />
          </div>
        );
    }
}

export default UsersTable;
