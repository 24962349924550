import React, { Component } from 'react';
import { extendedDateFormat } from '../../../../utils/format';
import { sortBy, find, isEmpty } from 'lodash';
import moment from 'moment';

import {
    Link
} from 'react-router-dom';

import JourneyMap from './JourneyMap'

import ReactJson from 'react-json-view';

function badge(speed = 0) {
    if (speed > 0) return (<div className='badge bg-green'>MOVING</div>)
    return (
        <div className='badge bg-gray'>STILL</div>
    )
}

function tag_badge(tag_reference) {
    if (tag_reference) return (<div className='badge bg-green'>T</div>)
    return (
        <div className='badge bg-gray'>-</div>
    )
}

function activity_badge(activity) {
    if (activity === 'in_vehicle') return (<div className='badge bg-green'>in_vehicle</div>)
    if (activity === 'unknown') return (<div className='badge bg-gray'>-</div>)
    return (
        <div className='badge bg-gray'>{activity || '-'}</div>
    )
}

class TelematicsPacketsTable extends Component {
    constructor(props) {
        super(props);
        this.select = this.select.bind(this);
    }

    select(packet_reference) {
        this.props.telematics_packets_get({
            id: packet_reference,
        });
    }

    render() {
        const {
            data = [],
            selected,
            policy_detail = true,
            lang,
        } = this.props;

        //TODO - Temporary
        const sorted = sortBy(data, d => -new Date(d.timestamp)).slice(0, 100);

        return (
            <div className="row">
                <div className={isEmpty(selected) ? 'col-lg-8' : 'col-lg-8'}>
                    <div className="card">
                        <div className="table-responsive">
                            <table className="table table-vcenter table-mobile-md card-table">
                                <thead>
                                    {policy_detail &&
                                        <tr>
                                            <th>{lang.t('tableHeaders.timestamp')}</th>
                                            <th>{lang.t('tableHeaders.age')}</th>
                                            <th>{lang.t('tableHeaders.size')}</th>
                                            <th>{lang.t('tableHeaders.location')}</th>
                                            <th>{lang.t('tableHeaders.moving')}</th>
                                            <th>{lang.t('tableHeaders.speed')}</th>
                                            <th>{lang.t('tableHeaders.activity')}</th>
                                            <th>{lang.t('tableHeaders.tag')}</th>
                                            <th className="w-2"/>
                                        </tr>
                                    }
                                    {!policy_detail &&
                                        <tr>
                                            <th>{lang.t('tableHeaders.timestamp')}</th>
                                            <th>{lang.t('tableHeaders.age')}</th>
                                            <th>{lang.t('tableHeaders.device')}</th>
                                            <th>{lang.t('tableHeaders.location')}</th>
                                            <th>{lang.t('tableHeaders.activity')}</th>
                                            <th>{lang.t('tableHeaders.tag')}</th>
                                            <th className="w-2"/>
                                        </tr>
                                    }
                                </thead>
                                <tbody>
                                    {policy_detail &&
                                        sorted.map((d, i) => (
                                            <tr key={`devices-${i}`} style={{ background: (d.packet_reference === selected.packet_reference ? '#eeeeff' : '')}}>
                                                <td data-label="Timestamp">
                                                    <div className="text-h5">{extendedDateFormat(d.timestamp_at)}</div>
                                                </td>
                                                <td data-label="Age">
                                                    <div className="text-h5">{moment(d.timestamp_at).fromNow(true)}</div>
                                                </td>
                                                <td data-label="Size">
                                                    <div className="text-h5">{d.size} Kb</div>
                                                </td>
                                                <td data-label="Location">
                                                    <div className="text-h5">({d.latitude}, {d.longitude})</div>
                                                </td>
                                                <td data-label="Moving">
                                                    <div className="text-h5">{badge(d.speed)}</div>
                                                </td>
                                                <td data-label="Speed">
                                                    <div className="text-h5">{d.speed}</div>
                                                </td>
                                                <td data-label="Tag">
                                                    <div className="text-h5">{activity_badge(d.activity)}</div>
                                                </td>
                                                <td data-label="Tag">
                                                    <div className="text-h5">{tag_badge(d.tag_reference)}</div>
                                                </td>
                                                <td>
                                                    <div className="btn-list flex-nowrap">
                                                        <button
                                                            onClick={() => {
                                                                this.select(d.packet_reference)
                                                            }}
                                                            className="btn btn-sm btn-primary"
                                                        >{lang.t('policy.telematics.packet')}</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    {!policy_detail &&
                                        sorted.map((d, i) => (
                                            <tr key={`devices-${i}`} style={{ background: (d.packet_reference === selected.packet_reference ? '#eeeeff' : '')}}>
                                                <td data-label="Timestamp">
                                                    <div className="text-h5">{extendedDateFormat(d.timestamp_at)}</div>
                                                </td>
                                                <td data-label="Age">
                                                    <div className="text-h5">{moment(d.timestamp_at).fromNow(true)}</div>
                                                </td>
                                                <td data-label="Device">
                                                    <div className="btn-list flex-nowrap">
                                                        <Link
                                                            to={`/telematics/devices?device_reference=${d.device_reference}`}
                                                            className="btn btn-sm btn-primary"
                                                        >{(d.device_reference || '').slice(0, 10)}...</Link>
                                                    </div>
                                                </td>
                                                <td data-label="Location">
                                                    <div className="text-h5">({d.latitude}, {d.longitude})</div>
                                                </td>
                                                <td data-label="Tag">
                                                    <div className="text-h5">{activity_badge(d.activity)}</div>
                                                </td>
                                                <td data-label="Tag">
                                                    <div className="text-h5">{tag_badge(d.tag_reference)}</div>
                                                </td>
                                                <td>
                                                    <div className="btn-list flex-nowrap">
                                                        <button
                                                            onClick={() => {
                                                                this.select(d.packet_reference)
                                                            }}
                                                            className="btn btn-sm btn-primary"
                                                        >{lang.t('policy.telematics.packet')}</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {!isEmpty(selected) &&
                    <div className="col-lg-4">
                        <div style={{ marginBottom: 10}}>
                            <JourneyMap latitude={selected.latitude} height={280} longitude={selected.longitude} />
                        </div>
                        <table 
                            className="table table-vcenter table-mobile-md card-table" 
                            style={{ background: 'white', border: '1px solid #868c973d' }}
                        >
                            <thead>
                                <tr>
                                    <th>{lang.t('tableHeaders.packetRawDetail')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: 0}}>
                                        <div style={{ maxHeight: 420, overflow: 'scroll', overflowWrap: 'break-word'}}>
                                            <ReactJson
                                                src={selected.raw}
                                                theme={'grayscale:inverted'}
                                                enableClipboard={false}
                                                displayDataTypes={false}
                                                name={false}
                                                style={{
                                                    background: 'transparent',
                                                    fontFamily: 'monaco',
                                                    fontSize: '10px',
                                                    overflowWrap: 'break-word',
                                                    width: 360,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </div>

            
        );
    }
}

export default TelematicsPacketsTable;
