import React, { Component } from 'react';
import L, { divIcon } from 'leaflet';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { ArrowUpCircle, Droplet } from 'react-feather';
import config from '../../../../config';
import { last, first } from 'lodash';

class JourneyMap extends Component {

    getPlaceIcon () {
        const iconMarkup = renderToStaticMarkup(
            <div className='custom-marker-container'>
                <Droplet 
                    size={24}
                    style={{
                        color: `#206bc4`, 
                        'transform': 'rotate(180deg)',
                    }}
                />
            </div>
        )

        return divIcon({
            html: iconMarkup
        })
    }

    getVehicleIcon (angle = 0) {
        const iconMarkup = renderToStaticMarkup(
            <div className='custom-marker-container'>
                <ArrowUpCircle 
                    size={20}
                    style={{
                        color: `#000000`, 
                        'transform': `rotate(${angle}deg)`,
                        marginTop: 10,
                    }}
                />
            </div>
        )

        return divIcon({
            html: iconMarkup
        })
    }

    render () {
        const {
            latitude,
            longitude,
            coords,
            angles,
            height = 250,
            vehicle_index = null,
        } = this.props;

        let start_location;
        let end_location;
        if (coords) {
            start_location = first(coords);
            end_location = last(coords);
        } else {
            start_location = [latitude, longitude];
            end_location = [latitude, longitude];
        }

        let vehicle_location;
        let vehicle_angle;

        if (vehicle_index !== null) {
            vehicle_location = coords[vehicle_index];
            vehicle_angle = angles ? angles[vehicle_index] : 0;
        }

        const startEnd = L.polyline([start_location, end_location]);
        const mapBounds = startEnd.getBounds();
        const mapCenter = mapBounds.getCenter();

        return (
            <div className='map-placeholder' style={{ height }}>
                <MapContainer className='leaflet-map' center={mapCenter} bounds={mapBounds} attributionControl={false}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                        url={`https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=${config.map_tiler_key}`} 
                    />

                    {latitude && <Marker className='svg' position={[latitude, longitude]} icon={this.getPlaceIcon()}></Marker>}

                    {coords &&
                        <Polyline 
                            positions={coords} 
                            pathOptions={{ color: `#89a7cc` }} 
                        />
                    }
                    
                    {coords && vehicle_index !== null && <Marker position={vehicle_location} icon={this.getVehicleIcon(vehicle_angle)}></Marker>}
                </MapContainer>
            </div>
        )
    }
}

export default JourneyMap;