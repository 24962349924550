import React, { Component } from 'react';

import {
    Link,
    withRouter,
} from 'react-router-dom';

import routes from '../../routes';

function makeLinks(routes, pathname, selectedProduct, company_name, lang) {
    
    return routes.filter(r => r.hide !== true).filter(r => r.product === selectedProduct).map((r, k) => {
        const isActive = pathname.includes(r.path) && ! pathname.includes(r.path + '-');
        if (!r.sublinks) {
            return(
                <li key={r.icon} className={`nav-item ${isActive ? 'active' : ''}`}>
                    <Link key={`main-link-${k}`} to={`${r.path}`} className={`nav-link ${!r.component ? 'unbuilt' : ''}`}>
                        <i className={`fe ${r.icon}`} /> <span style={{ marginLeft: 5 }}>{lang.t(`nav.${r.name}`)}</span>
                    </Link>
                </li>
            );
        }

        return (
            <li key={r.icon} className={`nav-item ${isActive ? 'active' : ''}`}>
                <a className="nav-link dropdown-toggle" data-toggle="dropdown" role="button">
                    <i className={`fe ${r.icon}`} /> <span style={{ marginLeft: 5 }}>{lang.t(`nav.${r.name}`)}</span>
                </a>
                <ul className="dropdown-menu dropdown-menu-arrow">
                    {(r.sublinks || []).map((sublink, i) => {
                        const { name = '', path, hide, component, showLinkOnlyFor = [] } = sublink;
                        if (hide && !showLinkOnlyFor.includes(company_name)) return;
                        return (
                            <Link key={`sublink-${i}`} to={`${path}`} className={`dropdown-item ${!component ? 'unbuilt' : ''}`}>
                                <span>{lang.t(`nav.${name}`)}</span>
                            </Link>
                        )
                    })}
                </ul>
            </li>
        );
    });
}

class Nav extends Component {
    constructor(props) {
        super(props);
    }

    
    render() {
        const { lang, location, selectedProduct, company_name } = this.props;
        const { pathname } = location;

        return (
            <nav className="navbar navbar-expand-lg navbar-primary" id="navbar-primary">
                <div className="container">
                    <ul className="navbar-nav">
                        {makeLinks(routes, pathname, selectedProduct, company_name, lang)}
                    </ul>
                </div>
            </nav>
        );
    }
}

export default withRouter(Nav);
