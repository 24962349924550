import React, { Component } from 'react';
import { getLangSource } from '../../../lang';

import main from '../../../hoc/main';
import ModelsTable from '../components/Models/ModelsTable';
import ModelCreate from '../components/Models/ModelCreate';

import SpinnerBody from '../../../components/loaders/SpinnerBody';
import Empty from '../../../components/empty/Results';

class Models extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreate: false,
        };
    }

    componentDidMount() {
        this.props.pricing_models_get_all({});
    }

    render() {
        const { 
            pricing_models: {
                selected = {},
                error = {},
                data = [],
                isFetching = true,
            },
            lang: language
        } = this.props;

        const { showCreate } = this.state;
        const lang = getLangSource(language);
        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    {isFetching && 
                        <div className="row">
                            <SpinnerBody 
                                message={lang.t('rating.models.retrievingModels')}
                                selectedProduct={this.props.selectedProduct}
                            />
                        </div>
                    }
                    
                    {!isFetching &&
                        <div>
                            <div className="page-header">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div className="page-pretitle">
                                            {lang.t('rating.title')}
                                        </div>
                                        <h2 className="page-title">
                                            {lang.t('rating.models.model')}
                                        </h2>
                                    </div>
                                    <div className="col-auto ml-auto d-print-none">
                                        <button onClick={() => {
                                            this.setState({
                                                showCreate: !showCreate,
                                            });
                                        }} type="button" className="btn btn-blue mr-3">
                                            {lang.t('rating.models.create')}
                                            {!showCreate && <i style={{marginLeft: 4}} className="fe fe-chevron-up"></i>}
                                            {showCreate && <i style={{marginLeft: 4}} className="fe fe-chevron-down"></i>}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {showCreate && <ModelCreate 
                                pricing_template_post={this.props.pricing_template_post} 
                                pricing_models_get_all={this.props.pricing_models_get_all}
                                lang={lang}
                            />}

                        </div>
                    }

                    {!isFetching && data.length > 0 &&
                        <div className="row">
                            <div className="col-lg-12">
                                <ModelsTable 
                                    data={data}
                                    pages={10}
                                    lang={lang}
                                />
                            </div>
                        </div>
                    }

                    {!isFetching && data.length === 0 && <Empty label="models" lang={lang} />}                
                </div>
            </div>
        );
    }
}

export default main(Models);