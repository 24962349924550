import React, { Component } from 'react';
import { startCase } from 'lodash';

class FooterContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { lang } = this.props;
        return (
            <footer>
                <div className="container">
                    <div className="row align-items-center flex-row-reverse">
                        <div className="col-auto ml-lg-auto">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <ul className="list-inline list-inline-dots mb-0">
                                        <li className="list-inline-item"><a target="_blank" href="https://www.bybits.co.uk/docs/authentication" className="btn btn-outline-primary btn-sm">{lang.t('footer.documentation')}</a></li>
                                    </ul>
                                </div>
                                <div className="col-auto">
                                    <a target="_blank" href="https://www.bybits.co.uk/docs/authentication" className="btn btn-outline-primary btn-sm">{startCase(lang.t('footer.getHelp'))}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default FooterContainer;
