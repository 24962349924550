import moment from 'moment'

export function createTransactionHistory(transactions) {
    return transactions.map((transaction) => {
            return parseInt(transaction.amount)
        })
}

export function getXaxisCategories(transactions) {
    return transactions.reverse().map((transaction) => {
        return moment(transaction.date).format('MM/YY')
    })
}