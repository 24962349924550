import React, { Component } from 'react';
import { parse_date } from '../Detail/Utils';
import { Link } from 'react-router-dom';
import PaginationNoIndex from '../../../../components/tables/PaginationNoIndex';

function toUpper(str = '') {
  return str?.toUpperCase();
}

export default class ClaimsTable extends Component {
  constructor(props) {
    super(props);
}
  render() {
    const {
      claims: data = [],
      page,
      update,
      policy_reference,
      policys_claims_get_all,
      timezone,
      lang,
    } = this.props;
    
    return (
      <div className='card'>
        <div className='table-responsive'>
          <table className='table table-vcenter table-mobile-md card-table'>
            <thead>
              <tr>
                <th>{lang.t('tableHeaders.claimType')}</th>
                <th>{lang.t('tableHeaders.reference')}</th>
                <th>{lang.t('tableHeaders.reportedDate')}</th>
                <th className="w-1"/>
              </tr>
            </thead>
            <tbody>
              {
                data.filter((claim, i) => i !== 50).map((claim, i) => (
                  <tr key={`claims-${i}`}>
                    <td data-label='Reference'>
                      <div className='d-flex lh-sm py-1 align-items-center'>
                        <div className='flex-fill'>
                          <div className="strong">{toUpper(claim.claim_type)}</div>
                        </div>
                      </div>
                    </td>
                    <td data-label='Claim-Reference'>
                      <div className="text-muted text-h5">{claim.claim_reference}</div>
                    </td>
                    <td data-label='Reported-Date'>
                      <div className="text-h5">{parse_date(claim.reported_date, 'DD/MM/YYYY', timezone)}</div>
                    </td>
                    <td>
                      <div className='btn-list flex-nowrap'>
                        <Link
                          to={`/claims/detail/${claim.claim_reference}`}
                          className="btn btn-sm btn-primary"
                        >
                          {lang.t('buttons.view')}
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <PaginationNoIndex
          data={data}
          page={page}
          update={update}
          policy_reference={policy_reference}
          fetchData={policys_claims_get_all}
          lang={lang}
        />
      </div>
    )
  }
}
