import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getPolicyStatus } from '../../../../utils/getPolicyStatus';
import { parse_date } from '../Detail/Utils';
import { round, first } from 'lodash';
import { getNumberFormat, getUsageFormat } from '../../../../utils/numberFormat';
import PaginationNoIndex from '../../../../components/tables/PaginationNoIndex';

function toUpper(str = '') {
    return str?.toUpperCase();
}

class PolicysTable extends Component {
    constructor(props) {
        super(props);
    }

    badge({ is_valid}) {
        if (is_valid) return (<div className='badge bg-red'>IS VALID</div>)
        return (
            <div className='badge bg-green'>VALID</div>
        )
    }

    render() {
        const { 
            data = [],
            products = [],
            page,
            update,
            term,
            product_reference,
            policys_get_all,
            lang,
        } = this.props;

        return (
            <div className="card">
                <PaginationNoIndex
                    data={data}
                    page={page}
                    update={update}
                    term={term}
                    product_reference={product_reference}
                    fetchData={policys_get_all}
                    lang={lang}
                />
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.name')}</th>
                                <th>{lang.t('tableHeaders.product')}</th>
                                <th>{lang.t('tableHeaders.status')}</th>
                                <th>{lang.t('tableHeaders.reg')}</th>
                                <th>{lang.t('tableHeaders.postcode')}</th>
                                <th>{lang.t('tableHeaders.upfront')}</th>
                                <th>{lang.t('tableHeaders.sub')}</th>
                                <th>{lang.t('tableHeaders.pm')}</th>
                                <th>{lang.t('tableHeaders.startDate')}</th>
                                <th>{lang.t('tableHeaders.endDate')}</th>
                                <th className="w-1"/>
                            </tr>
                        </thead>
                        <tbody>
                            {data.filter((d, i) => i !== 50).map((d, i) => {
                                const product = products.filter(p => p.product_reference === d.product_reference);
                                const { timezone, currency } = first(product);
                                const status = getPolicyStatus(d);

                                return (
                                <tr key={`identitys-${i}`}>
                                    <td data-label="Name">
                                        <div className="d-flex lh-sm py-1 align-items-center">
                                            <div className="flex-fill">
                                                <div className="strong">{toUpper(d.proposer_first_names)} {toUpper(d.proposer_last_names)}</div>
                                                <div className="text-muted text-h5">{toUpper(d.policy_reference)}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-label="Product">
                                        <div className="text-muted text-h5">{d.product_reference}</div>
                                    </td>
                                    <td>
                                        {status === "active" && <span style={{ marginRight: 6 }} className={`badge bg-green btn-block`}>Active</span>}
                                        {status.text === "expiring" && <span style={{ marginRight: 6 }} className={`badge bg-orange btn-block`} data-toggle="tooltip" data-placement="top" title={`Expiring in ${status.policyDaysRemaining} ${status.policyDaysRemaining > 1 ? 'days' : 'day'}`}>Expiring</span>}
                                        {status === "cancelled" && <span style={{ marginRight: 6 }} className={`badge bg-red btn-block`}>Cancelled</span>}
                                        {status === "lapsed" && <span style={{ marginRight: 6 }} className={`badge bg-grey btn-block`}>Lapsed</span>}
                                        {status === "cancelling" && <span style={{ marginRight: 6 }} className={`badge bg-yellow btn-block`}>Cancelling</span>}
                                    </td> 
                                    <td data-label="Reg">
                                        <div className="text-muted text-h5">{d.vehicle_reg}</div>
                                    </td>
                                    <td data-label="Postcode">
                                        <div className="text-muted text-h5">{d.address_postcode}</div>
                                    </td>
                                    <td data-label="Price">
                                        <div className="text-muted text-h5">
                                            {getNumberFormat(currency, d.upfront_premium || 0, '$0,0.00')}
                                        </div>
                                    </td>
                                    <td data-label="Price">
                                        <div className="text-muted text-h5">
                                            {getNumberFormat(currency, d.subscription_premium || 0, '$0,0.00')}
                                        </div>
                                    </td>
                                    <td data-label="Price">
                                        <div className="text-muted text-h5">
                                            {getNumberFormat(currency, d.usage_rate || 0, getUsageFormat(d.usage_rate))}
                                        </div>
                                    </td>
                                    <td data-label="StartDate">
                                        <div className="text-muted text-h5">{parse_date(d.start_date, 'DD/MM/YYYY', timezone)}</div>
                                    </td>
                                    <td data-label="EndDate">
                                        <div className="text-muted text-h5">{parse_date(d.end_date, 'DD/MM/YYYY', timezone)}</div>
                                    </td>
                                    <td>
                                        <div className="btn-list flex-nowrap">
                                            <Link
                                                to={`/policys/detail/${d.policy_reference}`}
                                                className="btn btn-sm btn-primary"
                                            >{lang.t('policy.table.view')}</Link>
                                        </div>
                                    </td>
                                </tr>
                            )})}
                        </tbody>
                    </table>
                </div>
                <PaginationNoIndex
                    data={data}
                    page={page}
                    update={update}
                    term={term}
                    product_reference={product_reference}
                    fetchData={policys_get_all}
                    lang={lang}
                />
            </div>
        );
    }
}

export default PolicysTable;
