import React, { Component } from 'react';
import L, { divIcon } from 'leaflet';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { fromEncoded }  from 'polyline-encoded';
import { Droplet } from 'react-feather';
import config from '../../../../config';

class JourneyMap extends Component {

    getSVGIcon () {
        const iconMarkup = renderToStaticMarkup(
            <div className='custom-marker-container'>
                <Droplet 
                    size={24}
                    style={{
                        color: `#206bc4`, 
                        'transform': 'rotate(180deg)',
                    }}
                />
            </div>
        )

        return divIcon({
            html: iconMarkup
        })
    }

    getDecodedPolylone (encodedPolyline) {
        const decodedPolyline = L.Polyline.fromEncoded(encodedPolyline);

        return decodedPolyline._latlngs.map((data) => {
            return [data.lat, data.lng]
        });
    }


    render () {
        const {
            start_lat,
            start_long,
            end_lat,
            end_long,
            route,
        } = this.props.journey;
        const startLocation = [start_lat, start_long];
        const endLocation = [end_lat, end_long];

        const startEnd = L.polyline([startLocation, endLocation]);
        const mapBounds = startEnd.getBounds();
        const mapCenter = mapBounds.getCenter();

        // use getSVGIcon to use a customisable svg or you can set customIcon to equal a png
        const customIcon = this.getSVGIcon();

        return (
            <div className='map-placeholder'>
                <MapContainer className='leaflet-map' center={mapCenter} bounds={mapBounds} attributionControl={false}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                        url={`https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=${config.map_tiler_key}`} 
                    />
                    <Polyline 
                        positions={this.getDecodedPolylone(route)} 
                        pathOptions={{ color: `#89a7cc` }} 
                    />
                    <Marker position={startLocation} icon={customIcon}></Marker>
                    <Marker className='svg' position={endLocation} icon={customIcon}></Marker>
                </MapContainer>
            </div>
        )
    }
}

export default JourneyMap;