import React, { Component } from 'react';
import Input from './Input';
import Disabled from './Disabled';
import moment from 'moment';
import { round, find, indexOf, findIndex, isEmpty } from 'lodash';
import FormDriver from './FormDriver';
import { upsert } from '../Utils';


class FormAdditionalDrivers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updated_info: {},
            driver_index: null,
        }
        this.update = this.update.bind(this);
    }

    componentDidMount() {
        const { detail: { additional_drivers }, driver } = this.props;
        const driver_index = findIndex(additional_drivers, driver);
        this.setState({ driver_index })
    }

    update(driver) {
        const { detail } = this.props;
        const { driver_index } = this.state;
        let { additional_drivers = [] } = detail;


        const updated_info = {
            ...this.state.items,
            ...driver,
        }

        upsert(additional_drivers, driver_index, driver);

        this.props.update({
            ...detail,
            additional_drivers,
        });

        this.setState({ updated_info })
    }

    render() {
        const { driver, disabled = false, modal_open, set_driver_index, lang } = this.props;
        const { driver_index } = this.state;
        return [
            <div>
                <div className="card-body">
                    <FormDriver 
                        driver={driver}
                        update={this.update}
                        disabled={disabled}
                        modal_open={modal_open}
                        driver_index={driver_index}
                        set_driver_index={set_driver_index}
                        lang={lang}
                    />
                </div>
            </div>
        
        ];
    }
}

export default FormAdditionalDrivers;