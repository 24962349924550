import { get } from 'lodash';
import axios from 'axios';
import moment from 'moment';

import base from '../config';

// Example Autocrat Requests

/*
    {
        "refresh_token": "12345ABCDE",
        "type": "REFRESH_TOKEN_AUTH",
    }
*/

// Example Autocrat Response
/*
    {
        "type": "DONE",
        "username": "callum",
        "expiry": "1609506237",
        "access_token": "12345ABCDE",
        "refresh_token": "12345ABCDE",
        "qrcode": null,
        "code": null,
        "session": null,
    }
*/

export async function getRemoteAutocrat(data) {
    const result = await axios({
        url: base.services.apis.root + '/auth/token',
        method: 'post',
        data: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'environment': 'production',
        }, 
    }).then(({data}) => {
        return setLocalAutocrat(data);
    }). catch((error) => {
        // For any error then clean out the localStorage
        window.localStorage.autocrat = null;
        if (error.response) {
            const { message, status } = error.response.data;
            throw {
                message: message || error.response.data,
                status: status || error.response.status,
            };
        } else if (error.request) {
            throw {
                message: 'Server Error',
                status: 500,
            }
        } else {
            throw {
                message: error.data,
            }
        }
    })
    return result;
}

export function setLocalAutocrat (data) {
    const origData = JSON.parse(get(window, 'localStorage.autocrat', '{}'));
    const newData = { ...origData, ...data };
    window.localStorage.autocrat = JSON.stringify(newData);
    return newData;
} 

export function signOutAutocrat () {
    window.localStorage.autocrat = null;
    window.location.reload();
} 

export async function getLocalAutocrat () {
    const store = JSON.parse(get(window, 'localStorage.autocrat', '{}')) || {};
    // Refresh Access Token
    if (store.expiry) {
        const expired = moment() > moment(store.expiry, 'X');
        if (expired) {
            const new_store = getRemoteAutocrat({
                refresh_token: store.refresh_token,
                type: 'REFRESH_TOKEN_AUTH',
            });
            setLocalAutocrat(new_store);
            return new_store;
        }
    }
    return store;
} 