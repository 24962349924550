import React, { Component } from 'react';
import { first, has, orderBy } from 'lodash';
import Search from '../search/Simple';

function dataFilter({
    data = [],
    searchTerm = '',
    searchColumn = '',
}) {
    return data.filter(d => {
        if (searchTerm === ``) return true;
        if (!d[searchColumn]) return false;
        return d[searchColumn].toLowerCase().includes(searchTerm.toLowerCase())
    });
}


class SuperTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchColumn: '',
            searchTerm: '',
            sortAsc: null,
        }
        this.search = this.search.bind(this);
        this.sort = this.sort.bind(this);
    }

    componentWillMount() {
        const {data = {}, headers = [] } = this.props;
        this.setState({
            data,
            searchColumn: (first(headers.filter(h => h.search)) || {}).name || '',
        })
    }

    search(term) {
        if (this.props.search) return this.props.search(term);
        this.setState({
            searchTerm: term,
        });
    }

    sort(data) {
        const { searchColumn , sortAsc } = this.state;
        const { auto_sort = false } = this.props;
        if (!auto_sort) return data;
        if (!searchColumn) return data;
        return orderBy(data, [searchColumn], sortAsc ? 'asc' : 'desc');
    }

    render() {
        const { searchColumn, searchTerm = '' } = this.state;
        const { headers, Cells, limit = 1000, height = 300, lang } = this.props;

        // TODO make virtualised table
        const data = dataFilter({
            data: this.props.data,
            searchColumn,
            searchTerm,
        }).slice(0, limit);

        return [
            <div className="row">
                <div className="col-sm-12 col-lg-6">
                    <div className="badge bg-green mt-2">{lang.t('components.super.results')} {this.props.results_count || this.props.data.length}</div>
                </div>
                <div className="col-sm-12 col-lg-6 mb-3">
                    <Search 
                        searchTerm={searchTerm}
                        searchFunction={this.search} 
                        label={searchColumn}
                        lang={lang}
                    />
                </div>
            </div>,
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <div className="card supertable">
                        <div className="super-table table-responsive" style={{ maxHeight: height }}>
                            <table className="table table-vcenter table-mobile-md card-table">
                                <thead> 
                                    <tr>
                                    {headers.map((h, i) => {
                                        return (
                                            <th key={`header-${h.name}-${i}`} className={h.class || ''} onClick={() => {
                                                if (h.search) {
                                                    this.setState({
                                                        searchColumn: h.name,
                                                        sortAsc: !(this.state.sortAsc || false)
                                                    });
                                                }
                                            }}>
                                                {h.search && h.name === searchColumn && 
                                                    <div className="badge bg-blue">{h.label}</div>
                                                }
                                                {h.search && h.name !== searchColumn &&
                                                    <div style={{cursor: 'pointer' }} className="badge bg-gray">{h.label}</div>
                                                } 
                                                {!h.search && h.label}
                                            </th>
                                        )
                                    })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.sort(data).map((d, i) => {
                                        return(Cells(i, d))
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        ];
    }
}

export default SuperTable;
