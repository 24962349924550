import React, { Component } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import Modal from '../../../../../components/modal/Modal';
import { enGB } from 'date-fns/esm/locale';
import moment from 'moment-timezone';
import { omit, capitalize, isUndefined } from 'lodash'; 
import Disabled from './Disabled';
import 'react-datepicker/dist/react-datepicker.css';
import { to_upper } from '../../../../../components/format/string';
import { defaultDateFormat, extendedDateFormat } from '../../../../../utils/format';
import { getNumberFormat } from '../../../../../utils/numberFormat';
import { parse_date } from '../Utils';

registerLocale('en-GB', enGB);
setDefaultLocale('en-GB');

class FormCancelPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            effectiveDate: '',
            isSelectingDate: false,
            isModalOpen: false,
        }
        this.handleSelectDateButton = this.handleSelectDateButton.bind(this);
        this.handleDatePickerChange = this.handleDatePickerChange.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.cancelPolicy = this.cancelPolicy.bind(this);
    }

    handleDatePickerChange(date, event) {

        // there appears to be no event handler to detect a time is selected in react-datepicker
        // only when event is undefined, is when the time has been selected.
        // so when time has been selected, assume calendar picker is complete and hide it by setting isSelectingDate to false.

        this.setState({
            ...this.state,
            effectiveDate: date,
            isSelectingDate: isUndefined(event) ? false : true,
        })
    }

    handleSelectDateButton() {
        const { isSelectingDate } = this.state;

        this.setState({
            ...this.state,
            isSelectingDate: !isSelectingDate,
        })
    }

    modalToggle() {
        const { isModalOpen } = this.state;

        this.setState({
            isModalOpen: !isModalOpen,
        });
    }

    parseDateToSpecificTimezone(dateRequired){

        const dateWithNoTimeZone = moment(dateRequired).format('YYYY-MM-DD HH:mm');

        const dateWithTimezone = moment.tz(dateWithNoTimeZone, this.props.timezone);

        return dateWithTimezone;
    }

    async cancelPolicy(policy_reference) {

        const dateWithTimezone = this.parseDateToSpecificTimezone(this.state.effectiveDate);
    
        const converted_date_unix = moment(dateWithTimezone).unix();

        try {
            await this.props.policys_delete({
                id: policy_reference,
                query: {
                effective_date: converted_date_unix,
                },
            })

            this.setState({
                isModalOpen: false,
            });

            } catch (error) {
            console.log(error);
            throw error;
            }
    }
    
    render() {
        const { 
            detail, 
            policy_reference, 
            policyStatus,
            minCancellationDate, 
            currency,
            timezone,
            lang,
        } = this.props;

        const { effectiveDate, isSelectingDate, isModalOpen } = this.state;
        const policy = omit(detail.policy, 'address');

        const selectedEffectiveDateInTimezone = this.parseDateToSpecificTimezone(this.state.effectiveDate);
        const isDateBeforeMinDate = moment(selectedEffectiveDateInTimezone).unix() < moment(minCancellationDate).unix();

        const { upfront_premium, admin_fee, cancel_fee } = detail.pricing;
        const total_refund = upfront_premium + admin_fee + cancel_fee;
        const start_date_cleaned = parse_date(policy.start_date ,'LLL', timezone);
        const end_date_cleaned = parse_date(policy.end_date, 'LLL', timezone);
        
        
        const minCancellationDateInRespectiveTimezone = moment.tz(minCancellationDate, timezone);

        return (
            <div className="row">
                <div className="col-lg-6">
                    <div className="card ml-2">
                        <div className="card-header"><h3 className="card-title">{lang.t('policy.cancellation.policy')}</h3>
                        </div>
                        <div className="card-body">
                            <div className="mb-1">
                                <Disabled 
                                    label={lang.t('policy.cancellation.policyRef')}
                                    value={to_upper(policy_reference)}
                                />
                                <Disabled 
                                    label={lang.t('policy.cancellation.status')}
                                    value={capitalize(policyStatus)}
                                />
                                <Disabled 
                                    label={lang.t('policy.cancellation.startDate')}
                                    value={start_date_cleaned}
                                />
                                <Disabled 
                                    label={lang.t('policy.cancellation.endDate')}
                                    value={end_date_cleaned}
                                />
                                <Disabled 
                                    label={lang.t('policy.cancellation.underwriter')}
                                    value={policy.underwriter_reference}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card ml-2">
                        <div className="card-header"><h3 className="card-title">
                            {lang.t('policy.cancellation.policyCancellation')}</h3>
                        </div>
                        <div className="card-body">
                            <div className="mb-1">
                                {policyStatus === "active" && (
                                <div className="container pl-0 pr-0">
                                    <p className="text-center">{lang.t('policy.cancellation.whenPolicyCancel')}
                                    <br/>
                                    <small>{lang.t('policy.cancellation.noteTimezone')} <strong>{timezone}</strong> timezone)</small>
                                    </p>
                                   
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-auto">
                                            <button 
                                                className="btn btn-sm btn-blue text-white"
                                                type="button"
                                                onClick={this.handleSelectDateButton}>
                                                    {effectiveDate ? `${extendedDateFormat(effectiveDate)}` : lang.t('policy.cancellation.selectCancellationDate')}
                                            </button>
                                            
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" onClick={e => {
                                                e.preventDefault();
                                                this.modalToggle();
                                            }} 
                                            className={`btn btn-sm btn-red text-white ${(!effectiveDate || isDateBeforeMinDate) ? 'disabled' : ''}`}>
                                                {lang.t('policy.cancellation.cancelPolicy')}
                                            </button>
                                        </div>
                                        {isDateBeforeMinDate && (
                                            <h5 className="text-danger text-center mt-3"><i className="fe fe-alert-triangle mr-2" ></i>
                                                <b>{`${lang.t('policy.cancellation.errorEffectiveDate')} ${extendedDateFormat(minCancellationDateInRespectiveTimezone)}`}</b>
                                            </h5>
                                        )}

                                    </div>
                                </div>
                                )}
                                {policyStatus === "cancelled" && (
                                    <div className="text text-muted text-center">
                                        <p>{lang.t('policy.cancellation.thePolicy')} {to_upper(policy_reference)} {lang.t('policy.cancellation.alreadyCancelled')} <strong>{end_date_cleaned} ({timezone})</strong>
                                        </p>
                                    </div>
                                )} 
                                {policyStatus === "cancelling" && (
                                    <div className="text text-muted text-center">
                                        <p>{lang.t('policy.cancellation.thePolicy')} {to_upper(policy_reference)} {lang.t('policy.cancellation.dueToBeCancelled')}  <br/><strong> {end_date_cleaned} ({timezone}) </strong> 
                                        </p>
                                    </div>
                                )} 
                                {policyStatus === "lapsed" && (
                                    <div className="text text-muted text-center">
                                        <p>{lang.t('policy.cancellation.thePolicy')} {to_upper(policy_reference)} {lang.t('policy.cancellation.lapsedNoCancel')}
                                        </p>
                                    </div>
                                )} 
                                {isSelectingDate && (
                                    <div className="mt-2">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-auto">
                                                <DatePicker
                                                    selected={effectiveDate}
                                                    onChange={this.handleDatePickerChange}
                                                    minDate={new Date()}
                                                    dateFormat="dd/MM/yyyy HH:mm"
                                                    placeholderText={lang.t('policy.cancellation.selectDatePlaceholder')} 
                                                    inline
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {(policyStatus === "cancelled" || policyStatus === "cancelling") && (
                        <div className="card ml-2">
                        <div className="card-header">
                            <h3 className="card-title">
                                {lang.t('policy.cancellation.refund')}
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="mb-1">
                                
                                <div className="container pl-0 pr-0 text text-muted">
                                    <p>{lang.t('policy.cancellation.refuntAmount')}
                                        <span> </span>
                                        <span style={{ marginRight: 6 }} class={`badge bg-green`}>{getNumberFormat(currency, total_refund, '$0,0.00')}</span>
                                        <span>{lang.t('policy.cancellation.consistingOf')}</span>
                                    </p>
                                    <ul>
                                        <li>{lang.t('policy.cancellation.upfrontPremium')}  <span className="badge text-dark">{getNumberFormat(currency, upfront_premium, '$0,0.00')}</span></li>
                                        <li>{lang.t('policy.cancellation.adminFee')}  <span className="badge text-dark">{getNumberFormat(currency, admin_fee, '$0,0.00')}</span></li>
                                        <li>{lang.t('policy.cancellation.cancellationFee')}  <span className="badge text-dark">{getNumberFormat(currency, cancel_fee, '$0,0.00')}</span></li>
                                    </ul>
                                    <p>{lang.t('policy.cancellation.dueAfter')}  <strong>{end_date_cleaned} ({timezone})</strong></p>  
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                <Modal 
                    open={isModalOpen}
                    modalToggle={this.modalToggle}
                    action={this.cancelPolicy}
                    id={policy_reference}
                    modalTitle={lang.t('policy.cancellation.policyCancellation')}
                    modalText={`${lang.t('policy.cancellation.areYouSure')} ${to_upper(policy_reference)} ${lang.t('policy.cancellation.on')} ${extendedDateFormat(effectiveDate)}?`}
                    theme="dark"
                    lang={lang}
                />
            </div>
        );
    }
}

export default FormCancelPolicy;