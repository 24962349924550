import React, { Component } from 'react'
import FormClaimUpload from './FormClaimUpload';
import AddSuccess from '../../../../components/modal/AddSuccess';
import CloseButton from '../../../../components/buttons/CloseButton';

export default class ModalAddClaim extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stage: 'addClaimForm'
        };
        this.closeModal = this.closeModal.bind(this);
        this.updateStage = this.updateStage.bind(this);
    }
    closeModal() {
        this.props.toggleModal()
        this.updateStage('addClaimForm')
    }
    updateStage(stage) {
        this.setState({
            stage
        })
    }
    render() {
        const { open, policy_reference, policys_claims_post, policys_claims_get_all, lang } = this.props;
        const { stage } = this.state;

        return (
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} tabIndex="-1" role="dialog" id="modal-scrollable" aria-modal="true">
                <div className='modal-dialog modal-dialog-scrollable modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header theme-dark'>
                            <h5 className='modal-title'>{lang.t('policy.claims.addClaim')}</h5>
                            <CloseButton onClick={this.closeModal} />
                        </div>
                        {stage === 'addClaimForm' && 
                            <FormClaimUpload
                                updateStage={this.updateStage}
                                policys_claims_post={policys_claims_post}
                                policy_reference={policy_reference}
                                policys_claims_get_all={policys_claims_get_all}
                                lang={lang}
                            />
                        }
                        {stage === 'addClaimSuccess' && 
                            <AddSuccess 
                                close={this.closeModal} 
                                name='Claim'
                                lang={lang}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}
