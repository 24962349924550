import React, { Component } from 'react';
import { getLangSource } from '../../../lang';
import { v4 as uuidv4 } from 'uuid';

import main from '../../../hoc/main';
import PageHeader from '../../../components/layout/PageHeader';
import TestsTable from '../components/Tests/TestsTable';
import testsuites from '../utils/__mocks__/tests.json';

class Tests extends Component {
    constructor(props) {
        super(props);
        this.openModal = this.openModal.bind(this);
    }

    openModal(modal) {
        this.setState({
            modal: {
                ...this.state.modal,
                ...modal,
            },
        });
    }

    render() {
        const lang = getLangSource(this.props.lang);

        return [
            <div className="my-3 my-md-5">
                <div className="container">
                    <PageHeader
                        section={lang.t('rating.title')}
                        page={lang.t('rating.tests.title')}
                        buttonText={lang.t('rating.tests.create')}
                        buttonLink={'/rating/tests/create'}
                    />

                    <div className="row">
                        <div className="col-lg-12">
                            <TestsTable 
                                data={testsuites}
                                pages={10}
                                lang={lang}
                            />
                        </div>
                    </div>
                </div>
            </div>
        ];
    }
}

export default main(Tests);