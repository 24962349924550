import React, { Component } from 'react';
import Bar from '../../../../../components/loaders/Bar';
import prettify from 'pretti';
import cssbeautify from 'cssbeautify';
import RunWeb from '@uiw/react-run-web';
import { saveAs } from 'file-saver';
import { get } from 'lodash';
import data from '../data.json';
import axios from 'axios';
import config from '../../../../../config/index'

class DocTemplateDetail extends Component {
    constructor(props) {
        super(props);
        this.state ={
            styles:'',
            html:'',
        }
        this.save = this.save.bind(this);
        this.preview = this.preview.bind(this);
    }

    componentWillMount() {
        const { reference, product} = this.props;
        const template = get(product, `templates.docs.${reference}`, {});
        const styles = get(product, `templates.docs.styles.html`);
        const { html } = template;
        this.setState({
            html,
            styles,
        })
    }

    save() {
        const { reference, product } = this.props;
        const { html, styles } = this.state;
        const new_templates = {
            templates: {
                emails: product.templates.emails,
                docs: {
                    ...product.templates.docs,
                    [reference]: {
                        ...product.templates.docs[reference],
                        html,
                    },
                    styles: {
                        html: styles,
                    },
                }
            }
        }
        this.props.save({
            id: product.product_reference,
            data: new_templates
        });
    }

    async preview() {
        const { reference, product, auth } = this.props;
        const { client_id, client_secret } = auth;
        this.setState({ is_preview_load: true })
        await axios({
                url: `${config.services.apis.root}/documents?format=pdf`, 
                method: 'post',
                data: {
                    product_reference: product.product_reference,
                    template: reference,
                    data,
                },
                responseType: 'blob',
                headers: {
                    environment: 'production',
                    client_id,
                    client_secret,
                }
            })
            .then(response => {
                const content = new Blob([response.data], {
                    type: 'application/pdf'
                });
                saveAs(content,`${reference}.pdf`);
                this.setState({ is_preview_load: false })
            })
            .catch(error => {
                this.setState({ is_preview_load: false });
                console.log(error)
            });
    }

    render() {
        const { section, reference } = this.props;
        const { html, styles, is_preview_load } = this.state;
        
        return (
            <div className="col-sm-12 col-lg-12">
                <div className="row"> 
                    <div className="col-lg-6">
                        <a class="btn btn-secondary" onClick={() => {
                            section({ section: 'details' })
                        }}>&lt; Back</a>
                    </div>
                    <div className="col-lg-6">
                        <a 
                            class="btn btn-primary text-white" 
                            style={{ float: 'right' }}
                            onClick={(e) => {
                                e.preventDefault();
                                this.save();
                            }}
                        >Save Document</a>

                        <a 
                            class={`btn btn-secondary ${is_preview_load ? 'disabled' : ''}`} 
                            style={{ float: 'right', marginRight: 20 }}
                            onClick={(e) => {
                                e.preventDefault();
                                if(!is_preview_load) return this.preview();
                            }}
                        >Preview PDF</a>
                    </div>
                </div>
                {is_preview_load &&
                    <div className="row mt-3 mb-3"> 
                        <Bar />
                    </div>
                }
                <div className="row mt-3 mb-3"> 
                    <div className="col-lg-4">
                        <div style={{
                            border: '1px solid #e3e5ea',
                            borderRadius: 3,
                            backgroundColor: 'white',
                            padding: 14,
                            paddingBottom: 11,
                            marginBottom: 10,
                        }}>
                            <div style={{ color: '#868c97', fontSize: 12 }}>DOCUMENT TEMPLATE</div>
                            <div style={{ fontWeight: 'bold', fontSize: 16 }}>{reference}</div>
                        </div>
                        <div className="card">
                            <div className="card-body" style={{ position: 'relative', background: '#354052' }}>
                                <div 
                                    className="badge bg-blue" 
                                    style={{ position: 'absolute', top: 30, right: 46, opacity: 1}}>
                                    HTML
                                </div>
                                <textarea style={{ 
                                    width: '100%', 
                                    height: '252px',
                                    fontSize: 10,
                                    border: 'none',
                                    fontFamily: 'monaco',
                                    background: '#354052',
                                    color: '#f9f8f5',
                                }} 
                                    className="pre"
                                    onBlur={(e) => {
                                        this.setState({
                                            html: e.target.value,
                                        });
                                    }}
                                >
                                    {prettify(html, {ocd: true})}
                                </textarea>
                            </div>
                            <div className="card-body" style={{ position: 'relative'}}>
                                <div 
                                    className="badge bg-blue" 
                                    style={{ position: 'absolute', top: 30, right: 46, opacity: 0.4}}>
                                    CSS
                                </div>
                                
                                <textarea style={{ 
                                    width: '100%', 
                                    height: '252px',
                                    fontSize: 10,
                                    border: 'none',
                                    fontFamily: 'monaco',
                                    background: '#ffffff',
                                    color: '#354052',
                                }} className="pre"
                                    onBlur={(e) => {
                                        this.setState({
                                            styles: e.target.value,
                                        });
                                    }}
                                >
                                    {cssbeautify(styles)}
                                </textarea>
                            </div>
                        </div> 
                    </div>
                    <div className="col-lg-8">
                        <div className="card">
                            <div className="card-body" style={{ height: 680, padding: 0 }}>
                                <div 
                                    className="badge bg-blue" 
                                    style={{ position: 'absolute', top: 30, right: 46, opacity: 0.4}}>
                                    PREVIEW
                                </div>
                                <RunWeb
                                    css={styles}
                                    html={html}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DocTemplateDetail;