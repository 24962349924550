import React, { Component } from 'react';
import moment from 'moment';
import SuperTable from '../../../../components/tables/Super';
import {
    Link
} from 'react-router-dom';

class ErrorsTable extends Component {
    constructor(props) {
        super(props);
    }
    
    badge({ exclusion_count}) {
        if (exclusion_count > 0) return (<div className='badge bg-red'>INVALID</div>)
        return (
            <div className='badge bg-green'>VALID</div>
        )
    }

    render() {
        const { data = [], lang } = this.props;

        const headers = [
            {
                label: lang.t('rating.components.errorsTable.ref'),
                name: 'quote_reference',
                search: true
            },
            {
                label: lang.t('rating.components.errorsTable.code'),
                name: 'status_code',
            },
            {
                label: lang.t('rating.components.errorsTable.message'),
                name: 'error_message',
            },
            {
                label: lang.t('rating.components.errorsTable.environment'),
                name: 'environment',
            },
            {
                label: lang.t('rating.components.errorsTable.created'),
                name: 'created_at',
            },
            {
                label: '',
                name: '',
            },
        ];

        const Cells = (index, d) => {
            const is_valid = (d.exclusion_count === 0);
            return (
                <tr key={`factors-${index}`}>
                    <td data-label="Ref">
                        <div className="text-h5">{d.quote_reference}</div>
                    </td>
                    <td data-label="Status">
                        <div className="text-h5">{d.status_code}</div>
                    </td>
                    <td data-label="Message">
                        <div className="text-h5">{(d.error_message || '').substr(0, 80)}...</div>
                    </td>
                    <td data-label="environment">
                        <div className="text-h5">{d.environment}</div>
                    </td>
                    <td data-label="Created">
                        <div className="text-h5">{moment(d.created_at).format('DD/MM/YY - HH:mm:ss')}</div>
                    </td>
                    <td>
                        <div className="btn-list flex-nowrap">
                            <Link
                                to={`/rating-logs/errors/${d.quote_reference}`}
                                className="btn btn-sm btn-primary"
                            >{lang.t('rating.components.errorsTable.view')}</Link>
                        </div>
                    </td>
                </tr>
            );
        }

        return (
            <SuperTable 
                data={data}
                headers={headers}
                Cells={Cells}
                height={600}
                search={this.props.search}
                lang={lang}
            />
        );
    }
}

export default ErrorsTable;
