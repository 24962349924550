import React, { Component } from 'react';
import Input from './Input';
import numeral from 'numeral';
import moment from 'moment';

/*
{
    "upfront_rate": 100,
    "subscription_rate": 20,
    "pm_rate": 0.04,
    "subscription_installments": 12,
    "withhold_fee": 50,
    "deposit_fee": 50,
    "admin_fee": 60,
    "mta_fee": 20,
    "cancel_fee": 50
}
*/

class FormBase extends Component {
    constructor(props) {
        super(props);
        this.update = this.update.bind(this);
    }

    update(item) {
        const { detail } = this.props;
        const result = {
            ...detail,
            pricing: {
                ...detail.pricing,
                ...item,
            }
        };
        this.props.update(result);
    }

    render() {
        const { detail, disabled = false, isMta = false, isRenewal = false, lang } = this.props;
        const { pricing, policy } = detail;
        return [
            <div className="card">
                <div className="card-header"><h3 className="card-title">{lang.t('policy.policies.pricing')}</h3></div>
                <div className="card-body">
                    <div className="mb-1">
                        <Input
                            label={'Upfront rate'}
                            property={'upfront_rate'}
                            value={numeral(pricing.upfront_rate).format('0.00')}
                            update={this.update}
                            type = 'number'
                            min={0}
                            disabled={disabled}
                        />
                        <Input 
                            label={'Usage rate'}
                            property={'usage_rate'}
                            value={numeral(pricing.usage_rate).format('0.000')}
                            update={this.update}
                            type = 'number'
                            min={0}
                            disabled={disabled}
                        />
                        <Input
                            label={'Subscription rate'}
                            property={'subscription_rate'}
                            value={numeral(pricing.subscription_rate).format('0.00')}
                            update={this.update}
                            type = 'number'
                            min={0}
                            disabled={disabled}
                        />
                        {!isMta && !isRenewal && (
                            <>
                                <Input 
                                label={'Installments'}
                                property={'subscription_installments'}
                                value={pricing.subscription_installments}
                                update={this.update}
                                type = 'number'
                                disabled={disabled}
                                min={1}
                                max={12}
                                />
                                <Input 
                                    label={'Admin fee'}
                                    property={'admin_fee'}
                                    value={numeral(pricing.admin_fee).format('0.00')}
                                    update={this.update}
                                    type = 'number'
                                    min={0}
                                    disabled={disabled}
                                />
                            </>
                        )}
                        <Input 
                            label={'MTA fee'}
                            property={'mta_fee'}
                            value={numeral(pricing.mta_fee).format('0.00')}
                            update={this.update}
                            type = 'number'
                            min={0}
                            disabled={disabled}
                        />
                        <Input 
                            label={'Cancel fee'}
                            property={'cancel_fee'}
                            value={numeral(pricing.cancel_fee).format('0.00')}
                            update={this.update}
                            type = 'number'
                            min={0}
                            disabled={disabled}
                        />
                        {isRenewal && (
                            <>
                                <Input 
                                    label={'Effective date'}
                                    property={'effective_date'}
                                    value={moment.unix(policy.effective_date).format("DD/MM/YYYY")}
                                    update={this.update}
                                    min={0}
                                    disabled
                                />
                                <Input 
                                    label={'End date'}
                                    property={'end_date'}
                                    value={moment.unix(policy.end_date).format("DD/MM/YYYY")}
                                    update={this.update}
                                    min={0}
                                    disabled
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        
        ];
    }
}

export default FormBase;