import React, { Component } from 'react';
import Input from '../../components/Detail/Forms/Input';
import Dropdown from '../../components/Detail/Forms/Dropdown';
import { isUndefined, capitalize } from 'lodash';

class Payments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            keyDisabled: true,
            privateDisabled: true,
            testKeyResult: 'Test',
            badgeType: 'badge-retry',
        }
        this.save = this.save.bind(this);
    }

    save({ payment_enabled }) {
        const { selected } = this.props;
        this.setState({ error: null });
        this.props.save({
            id: selected.product_reference,
            data: {
                payment_provider: selected.payment_provider,
                payment_provider_key: selected.payment_provider_key,
                payment_secret_key: selected.payment_secret_key,
                payment_enabled: isUndefined(payment_enabled) ? selected.payment_enabled : payment_enabled,
            }
        });
    }

    updateProductBtn(type) {
        this.state[type] ? this.setState({ [`${type}`]: false }) : this.setState({ [`${type}`]: true })
    }

    async handleTestBtnClick() {
        try {
            await this.props.testPaymentKeys({});
            this.setState({ 
                testKeyResult: "Success",
                badgeType: `retry-success`,
            });
        } catch(e) {
            this.setState({ 
                testKeyResult: "Failed",
                badgeType: `retry-failed`,
            });
        };
    }

    render() {
        const { selected, update, save, lang } = this.props;
        const { keyDisabled, privateDisabled, testKeyResult, badgeType } = this.state;
        return (
            <div className="card ml-2">
                <div className="card-header">
                    <h3 className="card-title">{lang.t('settings.products.payment')}</h3>
                    <div className="ml-auto btn-list flex-nowrap">
                        <a className={`btn btn-sm btn-primary ${badgeType}`} style={{ marginRight: "15px" }} onClick={(e) => {
                            e.preventDefault();
                            this.handleTestBtnClick()
                        }}>{testKeyResult}</a>
                    </div>
                    <div className="btn-list flex-nowrap">
                        <a className="btn btn-sm btn-primary text-white" onClick={(e) => {
                            e.preventDefault();
                            const { payment_provider, payment_provider_key, payment_secret_key } = selected;
                            if ((payment_provider && payment_provider_key && payment_secret_key)) {
                                this.save({
                                    payment_enabled: selected.payment_enabled,
                                });
                            } else {
                                this.setState({
                                    error: 'You need to complete all payment fields'
                                });
                            }
                        }}>{lang.t('buttons.save')}</a>
                    </div>
                </div>
                <div className="card-body">
                    {this.state.error &&
                        <div style={{ marginTop: 10 }} className="alert alert-danger" role="alert">
                            {this.state.error}
                        </div>}
                    <div className="mb-1">
                        <Dropdown
                            value={selected.payment_provider}
                            property={'payment_provider'}
                            items={[{
                                label: 'Yolo',
                                value: 'yolo',
                            },{
                                label: 'Stripe',
                                value: 'stripe',
                            }]}
                            label={lang.t('settings.products.providerName')}
                            update={update} />
                        <Input
                            label={lang.t('settings.products.providerKey')}
                            property={'payment_provider_key'}
                            value={selected.payment_provider_key}
                            update={update}
                            disabled={keyDisabled} />
                        <i className="fe fe-edit product-key-edit" onClick={() => { this.updateProductBtn('keyDisabled'); }}></i>
                        <Input
                            label={lang.t('settings.products.providerSecret')}
                            property={'payment_secret_key'}
                            value={selected.payment_secret_key}
                            update={update}
                            disabled={privateDisabled} />
                        <i className="fe fe-edit product-key-edit" onClick={() => { this.updateProductBtn('privateDisabled'); } }></i>
                        {!selected.payment_enabled &&
                            <div style={{ marginTop: 10 }} className={`alert alert-danger`} role="alert">
                                <label class="form-check form-switch">
                                    <input class="form-check-input" style={{ border: '1px solid #b5ddfa' }} type="checkbox" onChange={() => {
                                        this.save({ payment_enabled: true });
                                    }} />
                                    <span class="form-check-label">{lang.t('settings.products.paymentsAre')} <b>{lang.t('settings.products.notEnabled')}</b> {lang.t('settings.products.toBeTaken')}</span>
                                </label>
                            </div>}
                        {selected.payment_enabled &&
                            <div style={{ marginTop: 10 }} className={`alert alert-info`} role="alert">
                                <label class="form-check form-switch">
                                    <input class="form-check-input" style={{ border: '1px solid #b5ddfa' }} type="checkbox" checked="true" onChange={() => {
                                        this.save({ payment_enabled: false });
                                    }} />
                                    <span class="form-check-label">{lang.t('settings.products.paymentsAre')} <b>{lang.t('settings.products.enabled')}</b> {lang.t('settings.products.toBeTaken')}</span>
                                </label>
                            </div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default Payments;