import React, { Component } from 'react';
import { extendedDateFormat } from '../../../../utils/format';

class Score extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            data = [], title, score, date, colour,
        } = this.props;
        return (
            <div className="card">
                <div className="progress card-progress">
                    <div className={`progress-bar bg-${colour}`} style={{ width: '100%' }} />
                </div>
                <div className="card">
                    <div className={`bg-${colour}-lt card-body p-2 text-center`}>
                        <div className="text-right">
                            <span className={`d-inline-flex lh-1 text-${colour} text-h6`}>
                                {extendedDateFormat(date)}
                            </span>
                        </div>
                        <div className={`h1 m-0 text-${colour}`}>{score}</div>
                        <div className={`mb-4 text-${colour}`}>{title}</div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table card-table table-vcenter">
                        <tbody>
                    {data.map(d => (
                                <tr key={d.name}>
                                    <td className="w-100">
                                    <a href="#" className="text-reset">{d.name}</a>
                                    </td>
                                    <td className="text-nowrap">
                                        <a href="#" className="text-muted">
                                            {d.maxscore === '-' ? `${d.score}` : `${d.score}/${d.maxscore}`}
                                        </a>
                                    </td>
                                    <td className="text-nowrap">
                                        {d.check === true
                                                && <i className="fe fe-check text-green" />}
                                        {d.check === false
                                                && <i className="fe fe-x-circle text-red" />}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Score;
