import React, { Component } from 'react';

import FormCancelPolicy from '../Forms/FormCancelPolicy';
import { getPolicyStatus } from '../../../../../utils/getPolicyStatus';

class CancellationSection extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            policy_reference,
            policy_detail, // This is the latest version
            versions,
            policys_delete,
            currency,
            timezone,
            lang,
        } = this.props;

        return (
            <FormCancelPolicy 
                detail={policy_detail}
                policy_reference={policy_reference}
                policyStatus={getPolicyStatus(versions[0])}
                minCancellationDate={versions[0].effective_date}
                policys_delete={policys_delete}
                history={this.props.history}
                currency={currency}
                timezone={timezone}
                lang={lang}
            />
        );
    }
}

export default CancellationSection;