import round from 'lodash.round';
import React, { Component } from 'react';
import SuperTable from '../../../../components/tables/Super';

class FactorsTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const data = (this.props.data || []).map(d => {
            return {
                ...d,
                factor: this.props.selected_factor,
            }
        });

        const headers = [
            {
                label: 'Factor',
                name: 'factor',
            },
            {
                label: 'Value',
                name: 'value',
            },
            {
                label: 'Volume',
                name: 'count',
            },
            {
                label: 'Quotability',
                name: 'quotability',
            },
            {
                label: 'Mean Yearly Premium',
                name: 'mean_yearly_premium',
            },
            {
                label: 'Median Yearly Premium',
                name: 'median_yearly_premium',
            },
        ];

        const Cells = (index, d) => {
            return (
                <tr key={`factors-${index}`}>
                    <td data-label="Factor">  
                        <div className="text-h5">{d.factor}</div>
                    </td>
                    <td data-label="Value">
                        <div className="text-h5">{d.value}</div>
                    </td>
                    <td data-label="Volume">  
                        <div className="text-h5">{d.count}</div>
                    </td>
                    <td data-label="Quotability">  
                        <div className="text-h5">{d.quotability}</div>
                    </td>
                    <td data-label="Premium">  
                        <div className="text-h5">{d.mean_yearly_premium}</div>
                    </td>
                    <td data-label="MedianPremium">  
                        <div className="text-h5">{d.median_yearly_premium}</div>
                    </td>
                </tr>
            );
        }

        return (
            <SuperTable 
                data={data}
                headers={headers}
                Cells={Cells}
                results_count={this.props.results_count}
                lang={this.props.lang}
            />
        );
    }
}

export default FactorsTable;