import React, { Component } from 'react';
import { getLangSource } from '../../../lang';
import moment from 'moment';
import { round, get, isEmpty, mean, sum, first } from 'lodash'
import numeral from 'numeral';
import { getId } from '../../../utils/url';

import main from '../../../hoc/main';
import SpinnerBody from '../../../components/loaders/SpinnerBody';
import DropDown from '../../../components/forms/Dropdown';

import BigChart from '../components/Analytics/BigChart';
import StatMini from '../components/Analytics/StatMini';
import TableChart from '../components/Analytics/TableChart';

import {
    toUpper,
    analyticsDataFormat,
} from '../utils/format'; 

function calculatedData(sorted = []) {
    const quotes = sorted.map(d => d.quotes);
    const valid = sorted.map(d => d.is_valid);
    const yearly_premium = sorted.map(d => d.yearly_premium_estimate);
    const upfront_rate = sorted.map(d => d.upfront_rate);
    const execution_time = sorted.map(d => d.execution_time);
    const estimated_distance = sorted.map(d => d.estimated_distance);
    const quotability = sorted.map(d => round((d.is_valid / d.quotes) * 100, 1));
    return {
        quotes: {
            chart: quotes,
            stat: sum(quotes),
        },
        valid: {
            chart: valid,
            stat: sum(valid),
        },
        yearly_premium: {
            chart: yearly_premium,
            stat: round(mean(yearly_premium), 2),
        },
        upfront_rate: {
            chart: upfront_rate,
            stat: round(mean(upfront_rate), 2),
        },
        execution_time: {
            chart: execution_time,
            stat: round(mean(execution_time), 2),
        },
        quotability: {
            chart: quotability,
            stat: round(mean(quotability), 2),
        },
        estimated_distance: {
            chart: estimated_distance,
            stat: round(mean(estimated_distance), 2),
        },
    }
}

class Analytics extends Component {
    constructor(props) {
        super(props);
        this.getAnalytics = this.getAnalytics.bind(this);
    }

    componentWillMount() {
        const { history } = this.props;
        let id = getId(history);
        this.props.pricing_models_get_all({})
            .then((data) => {
                if (id === 'analytics') {
                    const models = get(data, 'payload.data', []).map(m => m.model_reference);
                    id = first(models);
                }
                this.getAnalytics({
                    id, 
                    range: 'hour',
                });
            });
    }

    getAnalytics({
        id,
        range = null,
        source = null,
    }) {
        if (!id) return;
        if (source === 'all') source = null;

        let start_at =  moment().subtract(1, 'hour').format('X');

        if (range === 'week') {
            start_at = moment().subtract(1, 'week').format('X');
        }
        
        if (range === 'day') {
            start_at = moment().subtract(1, 'day').format('X');
        }

        console.log({
            model_reference: id, 
            start_at,
            source,
            with_exclusions: true,
        })

        this.props.pricing_analytics_post({
            data: {
            model_reference: id, 
            start_at,
            source,
            with_exclusions: true,
        }});
    }

    render() {
        const { 
            pricing_analytics: {
                selected = {},
                isFetching = true
            },
            lang: language
        } = this.props;

        const {
            analytics_sorted,
            chart_timestamps,
            analytics_sources,
            chart_annotations,
            chart_format,
            models,
            analytics_models,
        } = analyticsDataFormat({
            analytics: get(this.props, 'pricing_analytics.selected', {}), 
            models: get(this.props, 'pricing_models.data', [])
        });

        const {
            quotes,
            valid,
            yearly_premium,
            upfront_rate,
            execution_time,
            quotability,
            estimated_distance,
        } = calculatedData(analytics_sorted);

        const lang = getLangSource(language);

        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    {isFetching && 
                        <div className="row">
                            <SpinnerBody 
                                message={lang.t('rating.home.isBuilding')}
                                selectedProduct={this.props.selectedProduct}
                            />
                        </div>
                    }

                    {!isFetching && !isEmpty(selected) &&
                    <div>

                        <div class="row mb-3">
                            <div class="col-sm-12 col-lg-6">
                                <div className="col-auto">
                                    <div className="strong">{lang.t('rating.analytics.modelName')} {toUpper(selected.name)}</div>
                                    <div className="text-muted text-h5">{lang.t('rating.analytics.modelRef')} {selected.model_reference}</div>
                                    <div className="text-muted text-h5 mt-2"><div className="badge bg-green">{lang.t('rating.analytics.modelRef')} {selected.version}</div></div>
                                </div>
                            </div>
                            <div class="col-auto ml-auto d-print-none">
                                <button onClick={() => {
                                    this.props.history.push('/rating/models/' + selected.versions[0].version_reference)
                                }} type="button" className="btn btn-blue">
                                    {lang.t('rating.analytics.edit')}
                                </button>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-sm-12 col-lg-6">
                                <div class="form-label">{lang.t('rating.analytics.model')}</div>
                                <DropDown
                                    ref='model_reference'
                                    label='model_reference'
                                    value={selected.model_reference}
                                    update={(data) => {
                                        this.getAnalytics({
                                            id: data.model_reference,
                                            range: selected.range,
                                            source: selected.source,
                                        })
                                    }}
                                    labelMap={analytics_models}
                                    items={[...models.map(m => m.model_reference)]}
                                />
                            </div>
                            <div class="col-sm-12 col-lg-3">
                                <div class="form-label">{lang.t('rating.analytics.source')}</div>
                                <DropDown
                                    ref='source'
                                    label='source'
                                    value={selected.analytics.source}
                                    update={(data) => {
                                        this.getAnalytics({
                                            id: selected.model_reference,
                                            range: selected.range,
                                            source: data.source,
                                        })
                                    }}
                                    items={[lang.t('rating.analytics.all'), ...analytics_sources]}
                                />
                            </div>
                            <div class="col-sm-12 col-lg-3">
                                <div class="form-label">{lang.t('rating.analytics.range')}</div>
                                <DropDown
                                    ref='range'
                                    label='range'
                                    value={selected.analytics.range}
                                    update={(data) => {
                                        this.getAnalytics({
                                            id: selected.model_reference,
                                            range: data.range,
                                            source: selected.source,
                                        })
                                    }}
                                    items={['hour', 'day', 'week']}
                                    labelMap={{
                                        'hour': lang.t('time.hour'),
                                        'day': lang.t('time.day'),
                                        'week': lang.t('time.week'),
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-3">
                                <StatMini 
                                    mainCount={numeral(quotes.stat).format('0,0')}
                                    mainLabel={lang.t('rating.analytics.numberQuotes')}
                                    subCount={numeral(valid.stat).format('0,0')}
                                    subLabel={lang.t('rating.analytics.numberValid')}
                                    icon='fe-bar-chart-2'
                                    colour='blue'
                                />
                            </div>
                            <div className="col-lg-3">
                                <StatMini 
                                    mainCount={numeral(quotability.stat).format('0,0')+ '%'}
                                    mainLabel={lang.t('rating.analytics.quotability')}
                                    subCount={''}
                                    subLabel={''}
                                    icon='fe-bar-chart-2'
                                    colour='blue'
                                />
                            </div>
                            <div className="col-lg-3">
                            <StatMini 
                                    mainCount={'£' + numeral(yearly_premium.stat).format('0,0')}
                                    mainLabel={lang.t('rating.analytics.yearlyPremium')}
                                    subCount={'£' + numeral(upfront_rate.stat).format('0,0')}
                                    subLabel={lang.t('rating.analytics.upfrontPremium')}
                                    icon='fe-bar-chart-2'
                                    colour='blue'
                                />
                            </div>
                            <div className="col-lg-3">
                                <StatMini 
                                    mainCount={numeral(estimated_distance.stat).format('0,0') + ' (mi)'}
                                    mainLabel={lang.t('rating.analytics.aveDistance')}
                                    subCount={''}
                                    subLabel={''}
                                    icon='fe-bar-chart-2'
                                    colour='blue'
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">{lang.t('rating.analytics.totalQuotes')}</h3>
                                    </div>
                                    <div className="mb-3 mr-3 mt-3 ml-3">
                                        <BigChart 
                                            series={[
                                                {
                                                    name: 'Quotes',
                                                    data: quotes.chart,
                                                },
                                                {
                                                    name: 'Valid',
                                                    data: valid.chart,
                                                }
                                            ]}
                                            annotations={chart_annotations}
                                            labels={chart_timestamps.map(t => {
                                                return moment(t, 'X').toISOString();
                                            })}
                                            format={chart_format}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">{`${lang.t('rating.analytics.quotability')} (%)`}</h3>
                                    </div>
                                    <div className="mb-3 mr-3 mt-3 ml-3">
                                        <BigChart 
                                            series={[
                                                {
                                                    name: 'Quotability (%)',
                                                    data: quotability.chart,
                                                }
                                            ]}
                                            labels={chart_timestamps.map(t => {
                                                return moment(t, 'X').toISOString();
                                            })}
                                            annotations={chart_annotations}
                                            format={chart_format}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">{`${lang.t('rating.analytics.premium')} (${lang.t('currency')})`}</h3>
                                    </div>
                                    <div className="mb-3 mr-3 mt-3 ml-3">
                                        <BigChart 
                                            series={[
                                                {
                                                    name: 'Yearly Premium',
                                                    data: yearly_premium.chart,
                                                },
                                                {
                                                    name: 'Upfront Premium',
                                                    data: upfront_rate.chart
                                                }
                                            ]}
                                            labels={chart_timestamps.map(t => {
                                                return moment(t, 'X').toISOString();
                                            })}
                                            annotations={chart_annotations}
                                            format={chart_format}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">{`${lang.t('rating.analytics.estimatedYearlyDistance')} (${lang.t('distanceUnit.miles')})`}</h3>
                                    </div>
                                    <div className="mb-3 mr-3 mt-3 ml-3">
                                        <BigChart 
                                            series={[
                                                {
                                                    name: 'Estimated Yearly Distance',
                                                    data: estimated_distance.chart,
                                                },
                                            ]}
                                            labels={chart_timestamps.map(t => {
                                                return moment(t, 'X').toISOString();
                                            })}
                                            annotations={chart_annotations}
                                            format={chart_format}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">{`${lang.t('rating.analytics.responseTime')} (${lang.t('time.ms')})`}</h3>
                                    </div>
                                    <div className="mb-3 mr-3 mt-3 ml-3">
                                        <BigChart 
                                            series={[
                                                {
                                                    name: 'Time (ms)',
                                                    data: execution_time.chart,
                                                },
                                            ]}
                                            labels={chart_timestamps.map(t => {
                                                return moment(t, 'X').toISOString();
                                            })}
                                            annotations={chart_annotations}
                                            format={chart_format}
                                            height={200}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <TableChart
                                    header={`${lang.t('rating.analytics.exclusions')} - ${lang.t('rating.analytics.single')}`}
                                    data={get(selected, 'analytics.exclusions.single_exclusions', {})}
                                    lang={lang}
                                />
                            </div>
                            <div className="col-lg-6">
                                <TableChart
                                    header={`${lang.t('rating.analytics.exclusions')} - ${lang.t('rating.analytics.multi')}`}
                                    data={get(selected, 'analytics.exclusions.multi_exclusions', {})}
                                    lang={lang}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <TableChart
                                    header={`${lang.t('rating.analytics.refers')} - ${lang.t('rating.analytics.single')}`}
                                    data={get(selected, 'analytics.refers.single_exclusions', {})}
                                    lang={lang}
                                />
                            </div>
                            <div className="col-lg-6">
                                <TableChart
                                    header={`${lang.t('rating.analytics.refers')} - ${lang.t('rating.analytics.multi')}`}
                                    data={get(selected, 'analytics.refers.multi_exclusions', {})}
                                    lang={lang}
                                />
                            </div>
                        </div>

                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default main(Analytics);