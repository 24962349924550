const colours = {
    blue: '#467fcf',
    bluedarkest: '#0e1929',
    bluedarker: '#1c3353',
    bluedark: '#3866a6',
    bluelight: '#7ea5dd',
    bluelighter: '#c8d9f1',
    bluelightest: '#edf2fa',
    azure: '#45aaf2',
    azuredarkest: '#0e2230',
    azuredarker: '#1c4461',
    azuredark: '#3788c2',
    azurelight: '#7dc4f6',
    azurelighter: '#c7e6fb',
    azurelightest: '#ecf7fe',
    indigo: '#6574cd',
    indigodarkest: '#141729',
    indigodarker: '#282e52',
    indigodark: '#515da4',
    indigolight: '#939edc',
    indigolighter: '#d1d5f0',
    indigolightest: '#f0f1fa',
    purple: '#a55eea',
    purpledarkest: '#21132f',
    purpledarker: '#42265e',
    purpledark: '#844bbb',
    purplelight: '#c08ef0',
    purplelighter: '#e4cff9',
    purplelightest: '#f6effd',
    pink: '#f66d9b',
    pinkdarkest: '#31161f',
    pinkdarker: '#622c3e',
    pinkdark: '#c5577c',
    pinklight: '#f999b9',
    pinklighter: '#fcd3e1',
    pinklightest: '#fef0f5',
    red: '#e74c3c',
    reddarkest: '#2e0f0c',
    reddarker: '#5c1e18',
    reddark: '#b93d30',
    redlight: '#ee8277',
    redlighter: '#f8c9c5',
    redlightest: '#fdedec',
    orange: '#fd9644',
    orangedarkest: '#331e0e',
    orangedarker: '#653c1b',
    orangedark: '#ca7836',
    orangelight: '#feb67c',
    orangelighter: '#fee0c7',
    orangelightest: '#fff5ec',
    yellow: '#f1c40f',
    yellowdarkest: '#302703',
    yellowdarker: '#604e06',
    yellowdark: '#c19d0c',
    yellowlight: '#f5d657',
    yellowlighter: '#fbedb7',
    yellowlightest: '#fef9e7',
    lime: '#7bd235',
    limedarkest: '#192a0b',
    limedarker: '#315415',
    limedark: '#62a82a',
    limelight: '#a3e072',
    limelighter: '#d7f2c2',
    limelightest: '#f2fbeb',
    green: '#5eba00',
    greendarkest: '#132500',
    greendarker: '#264a00',
    greendark: '#4b9500',
    greenlight: '#8ecf4d',
    greenlighter: '#cfeab3',
    greenlightest: '#eff8e6',
    teal: '#2bcbba',
    tealdarkest: '#092925',
    tealdarker: '#11514a',
    tealdark: '#22a295',
    teallight: '#6bdbcf',
    teallighter: '#bfefea',
    teallightest: '#eafaf8',
    cyan: '#17a2b8',
    cyandarkest: '#052025',
    cyandarker: '#09414a',
    cyandark: '#128293',
    cyanlight: '#5dbecd',
    cyanlighter: '#b9e3ea',
    cyanlightest: '#e8f6f8',
    gray: '#868e96',
    graydarkest: '#1b1c1e',
    graydarker: '#36393c',
    graydark: '#343a40',
    graylight: '#aab0b6',
    graylighter: '#dbdde0',
    graylightest: '#f3f4f5',
    graydarkdarkest: '#0a0c0d',
    graydarkdarker: '#15171a',
    graydarkdark: '#2a2e33',
    graydarklight: '#717579',
    graydarklighter: '#c2c4c6',
    graydarklightest: '#ebebec',
};

module.exports = colours;
