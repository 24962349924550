import React, { Component } from 'react';
import ModalLinkedTable from './ModalLinkedTable';

import {
    each,
    omit,
} from 'lodash';

const buildTableData = (linked = {}) => {
    const array = [];
    each(linked, (link, key) => {
        array.push({
            key_value: key,
            ...link,
        });
    });
    return array;
}

class ModalLinked extends Component {
    constructor(props) {
        super(props);
        this.state = {
            linked: {},
        };
    }

    render() {
        const { name, open, linked, version_reference, lang } = this.props;
        const table = buildTableData(linked);

        return (
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{display: open ? 'block' : ''}} id="modal-linked" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{lang.t('rating.components.linked.title')} : {name}</h5>
                            <button type="button" onClick={this.clear} className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <ModalLinkedTable 
                                        data={table}
                                        pages={10}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalLinked;