import React, { Component } from 'react';
import Input from '../Forms/Input';

class ModalAddCustom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            custom: {},
            can_submit: false,
        };

        this.close = this.close.bind(this);
        this.update = this.update.bind(this);
        this.add = this.add.bind(this);
    }

    close() {
        this.props.modal_open({
            add_custom: {
                open: false,
            }
        });
        this.setState({
            custom: {},
            can_submit: false,
        });
    }

    update(item) {
        this.setState({
            custom: {
                ...this.state.custom,
                ...item,
            }
        }, () => {
            const { custom } = this.state;
            if (custom.value && custom.key) {
                this.setState({ can_submit: true });
            } else {
                this.setState({ can_submit: false })
            }
        });
    }

    add() {
        const { 
            detail, 
            update, 
        } = this.props;

        const { custom } = this.state;
        const result = { 
            ...detail,
            custom: {
                ...detail.custom,
                [custom.key]: custom.value,
            }
        };

        update(result);

        this.close();
    }

    render() {
        const { open, lang } = this.props;
        const { custom, can_submit } = this.state;
        
        return(
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-add-custom" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header theme-dark" >
                            <h5 className="modal-title">{lang.t('policy.policies.addCustomField')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className={`alert alert-info mb-4`} role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                {lang.t('policy.policies.keySpaceWarning')}
                            </div>
                            <h5 className="text-right text-red">* {lang.t('policy.policies.requiredFields')}</h5>
                            <Input 
                                label='Key *'
                                property='key'
                                value={custom.key}
                                update={this.update}
                                type='text'
                            />
                            <Input 
                                label='Value *'
                                property='value'
                                value={custom.value}
                                update={this.update}
                                type='text'
                            />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={this.add}
                                disabled={!can_submit}
                            >
                                {lang.t('buttons.add')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalAddCustom;