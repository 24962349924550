import React, { Component } from 'react';
import { min } from 'lodash';

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.selectPage = this.selectPage.bind(this);
        this.arrowScroll = this.arrowScroll.bind(this);
        this.generateIndex = this.generateIndex.bind(this);
    }

    selectPage(page) {
        if (page <= 0) return;
        if (page > this.props.no_of_pages) return;
        this.props.selectPage(page);
    }

    arrowScroll(direction) {
        const { page, indexRange = 10 } = this.props;
        const count = Math.floor((page - 1)/indexRange);
        let newCount;

        direction === 'down' ? newCount = count - 1 : newCount = count + 1;
        this.selectPage( newCount * indexRange + 1);
    }

    generateIndex() {
        const { page, no_of_pages, indexRange = 10 } = this.props;
        const count = Math.floor((page - 1)/indexRange);
        const firstIndexNum = count * indexRange;
        const indexArray = [];
        let lastPage = firstIndexNum + indexRange;

        if(firstIndexNum + indexRange > no_of_pages) {
            lastPage = no_of_pages;
        }

        for(let i = firstIndexNum; i < lastPage; i++) {
            indexArray.push(<li key={`key${i}`} className={`page-item btn-sm ${i + 1 === page ? 'active' : ''}`}>
                <a
                    className="page-link cursor-pointer" onClick={(e) => {
                        e.preventDefault();
                        this.selectPage(i + 1)
                    }}
                >{i + 1}
                </a>
            </li>);
        };

        return indexArray;
    }

    render() {
        const {
            data,
            page,
            pages,
            label,
            lang,
        } = this.props;
        // console.log(data, page, pages, label, no_of_pages)
        
        return (
            <div className="card-footer d-flex align-items-center" style={{ borderBottom: '1px solid #ccc' }}>
                <p className="m-0 text-muted">{lang.t('components.pagination.showing')} <span>{((page - 1) * pages) + 1}</span> {lang.t('components.pagination.to')} <span>{min([page * pages, data.length])}</span> {lang.t('components.pagination.of')} <span>{data.length}</span> {label}</p>
                <ul className="pagination m-0 ml-auto">
                    <li key='down-arrow' className='pagination-arrow'>
                        <a className="page-link cursor-pointer" onClick={(e) => {
                            e.preventDefault();
                            this.arrowScroll('down')
                        }}>
                            <span className='fa fa-angle-left'></span>
                        </a>
                    </li>
                    {this.generateIndex()}
                    <li key='up-arrow' className='pagination-arrow'>
                        <a className="page-link cursor-pointer" onClick={(e) => {
                            e.preventDefault();
                            this.arrowScroll('up')
                        }}>
                            <span className='fa fa-angle-right'></span>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Pagination;
