import React, { Component } from 'react';
import moment from 'moment';

class DropDownRisks extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { selectRisks } = this.props;
        return (
            <select class="form-select" onChange={(e) => {
                e.preventDefault();
                selectRisks(e.target.value);
            }}>
                <option value="reports">All Risks</option>
                <option value="best_reports">Best Risks</option>
                <option value="worst_reports">Worst Risks</option>
            </select>
        );
    }
}

export default DropDownRisks;