const strings = {
    // languages: {
    //     en: 'english',
    //     fr: 'french',
    //     it: 'italian',
    //     es: 'spanish'
    // },
    // menu: {
    //     profile: 'Profile',
    //     settings: 'Settings',
    //     company: 'Company',
    //     users: 'Users',
    //     tokens: 'Tokens',
    //     tasks: 'Tasks',
    //     inbox: 'Inbox',
    //     message: 'Message',
    //     signout: 'Sign out',
    // },
    // products: {
    //     engine: 'Engine',
    //     sandbox: 'Sandbox',
    //     production: 'Production',
    //     rating: 'Rating',
    //     policy: 'Policy',
    //     openbanking: 'Open Banking',  
    // },
    // login: {
    //     loginAccount: 'Login to your account',
    //     username: 'Username',
    //     emailAddress: 'Email address',
    //     password: 'Password',
    //     newPassword: 'New password', 
    //     passwordReset: 'Password successfully reset',
    //     forgotPassword: 'I forgot password',
    //     setNewPassword: 'You need to replace your temporary password',
    //     rememberMe: 'Remember me on this device',
    //     signIn: 'Sign in',
    //     forgotPassword: 'Forgot password',
    //     newPassword: 'Create a new password',
    //     resetPassword: 'Enter your username and a password reset code will be set to you',
    //     sendPassword: 'Reset my password',
    //     takeBack: 'Take me back to the login page',
    //     loginPage: 'Login Page',
    //     setTOTP: 'Use Google Authenticator to scan the QR Code and then add the 6 digit code in to the input below',
    //     getTOTP: 'Use Google Authenticator and enter the 6 digit code in to the input below',
    //     enterCode: 'Enter Code',
    //     nextStep: 'Next Step',
    //     verificationCode: 'Please enter the verification code sent to your email and enter your new password',
    //     newTempPasswordSent: 'A new temporary password has been sent to your email',
    // },
    roles: {
        admin: 'Admin',
        /*developer: 'Developer',
        operative: 'Operative',
        finance: 'Finance',
        marketing: 'Marketing',*/
        policyholder: 'Policyholder',
    },
    // claims: {
    //     reports: {
    //         select_report: 'select report',
    //         select_pivot: 'select pivot',
    //         select_format: 'select format',
    //         select_risks: 'select risks',
    //         generate_report: 'generate report',
    //         loss_ratio: 'loss ratio',
    //         claims_frequency: 'claims frequency',
    //         claim_types: 'claim types',
    //         claim_journeys: 'claim_journeys',
    //         number_of_policies: 'number of policies',
    //         number_of_rows: 'number of rows',
    //         fault_claims: 'fault clams',
    //         non_fault_claims: 'non-fault claims',
    //         exposure_years: 'exposure years',
    //         exposure_miles: 'exposure miles',
    //         active_policy_count: 'active policy count',
    //         lapsed_policy_count: 'lapsed policy count',
    //         claims_inc: 'claims inc.',
    //         claims_est: 'claims ext.',
    //         fault_frequency: 'fault frequency',
    //         non_fault_frequency: 'non-fault frequency',
    //         loss_ratio_inc: 'loss ratio inc.',
    //         loss_ratio_est: 'loss ratio est.',

    //     },        
    // },
}

export default strings;