import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import { round } from 'lodash';

class FactorsChart extends Component {
    constructor(props) {
        super(props);
        this.updateChart = this.updateChart.bind(this);
    }

    componentWillMount() {
        this.updateChart();
    }

    updateChart() {

        //const raw_data = data_mock;
        const raw_data = (this.props.data || []);

        const data = raw_data.map(d => {
            return {
                ...d,
                quotability: round((d.is_valid/d.count) * 100, 1),
            }
        });

        let series = []

        if (this.props.series === 'quotability') {
            series = [
                {
                    name: 'Quotability (%)',
                    data: data.map(d => d.quotability),
                },
                {
                    name: 'Volume',
                    data: data.map(d => d.count),
                }
            ];
        } else if (this.props.series === 'premium') {
            series = [
                {
                    name: 'Mean Premium',
                    data: data.map(d => d.mean_yearly_premium),
                },
                {
                    name: 'Volume',
                    data: data.map(d => d.count),
                },
            ];
        }

        const labels = data.map(d => d.value);

        let chart = {
            series,
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    opacity: 1,
                },
                grid: {
                    padding: {
                        top: -20,
                        right: 0,
                        left: -4,
                        bottom: 0
                    },
                    strokeDashArray: 4,
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                },
                xaxis: {
                    labels: {
                        padding: 0,
                    },
                    axisBorder: {
                        show: true,
                    },
                    tickAmount: 20,
                },
                yaxis: [{
                    labels: {
                        padding: 4
                    },
                },{
                    opposite: true,
                    show: false,
                    labels: {
                        padding: 4
                    },
                }],
                labels: labels,
                colors: ["#206bc4", "#79a6dc", "#ffffff"],
                legend: {
                    show: false,
                },
            }
        };

        this.setState(chart);
    }

    render() {
        return(
            <Chart
                options={this.state.options}
                series={this.state.series}
                type="line"
                height={this.props.height || 280}
            />
        );
    }
}

export default FactorsChart;
