import React, { Component } from 'react';
import { getLangSource } from '../../../lang';
import { saveAs } from 'file-saver';

import main from '../../../hoc/main';

import SpinnerBody from '../../../components/loaders/SpinnerBody';
import Table from '../components/Index/QuotesTable';

import { getId } from '../../../utils/url';
import ReactJson from 'react-json-view'

import { isEmpty, get } from 'lodash';

class ErrorDetail extends Component {
    constructor(props) {
        super(props);
        this.export = this.export.bind(this);
    }

    async componentWillMount() {
        const { history } = this.props;
        const id = getId(history);
        try {
            await this.props.pricing_errors_get({
                id,
            });
        } catch (e) {
            console.log(e)
        }
    }

    export() {
        const { selected = {} } = this.props.pricing_errors;
        const { meta } = selected;
        const file = `${meta.quote_reference}.json`;
        
        // Create a blob of the data
        const content = new Blob([JSON.stringify(selected, null, 4)], {
            type: 'application/json',
            name: file
        });
        
        // Save the file
        saveAs(content, file);
    }

    render() {

        const { 
            pricing_errors: {
                selected = {},
                isFetching = true,
            },
            lang: language,
        } = this.props;

        const lang = getLangSource(language);

        return [
            <div className="my-3 my-md-5">
                <div className="container">

                    {isFetching && 
                        <div className="row">
                            <SpinnerBody
                                message={lang.t('rating.logs.retrievingError')}
                                selectedProduct={this.props.selectedProduct}
                            />
                        </div>
                    }

                    {/* Header Details */}
                    {!isFetching && !isEmpty(selected) &&
                        <div>
                            <div class="row mb-3">
                                <div class="col-sm-12 col-lg-6">
                                    <div className="col-auto">
                                        <div className="strong" style={{ textTransform: 'normal'}}>{lang.t('rating.logs.modelReference')} {get(selected, 'meta.model_reference', '')}</div>
                                        <div className="text-muted text-h5 mt-2"><div className="badge bg-green">{lang.t('rating.logs.version')} {get(selected, 'meta.version', '')}</div></div>
                                    </div>
                                </div>
                                <div class="col-auto ml-auto d-print-none">
                                    <button onClick={this.export} type="button" className="btn btn-blue">
                                        {lang.t('buttons.download')}
                                    </button>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div className="col-sm-12 col-lg-12">
                                    <Table
                                        data={[get(selected, 'meta')]}
                                        show_button={false}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-12 col-lg-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">{lang.t('rating.logs.quoteDetail')}</h3>
                                        </div>
                                        <div className="card-body">
                                            <ReactJson
                                                src={get(selected, 'quote')}
                                                theme={'monokai'}
                                                enableClipboard={false}
                                                displayDataTypes={false}
                                                name={false}
                                                style={{
                                                    background: 'transparent',
                                                    fontFamily: 'monaco',
                                                    fontSize: '12px',
                                                }}
                                            />
                                        </div>
                                    </div>   
                                </div>
                                <div class="col-sm-12 col-lg-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">{lang.t('rating.logs.errorDetail')}</h3>
                                        </div>
                                        <div className="card-body">
                                            <ReactJson
                                                src={get(selected, 'error')}
                                                theme={'monokai'}
                                                name={false}
                                                enableClipboard={false}
                                                displayDataTypes={false}
                                                style={{
                                                    background: 'transparent',
                                                    fontFamily: 'monaco',
                                                    fontSize: '12px',
                                                }}
                                            />
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
            ];
    }
}

export default main(ErrorDetail);