import React, { Component } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { getNumberFormat } from '../../../../utils/numberFormat';

class RevenueTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { segments = [], currency, lang } = this.props;
        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>  
                            <tr>
                                <th>{lang.t('tableHeaders.date')}</th>
                                <th>{lang.t('tableHeaders.totalPremium')}</th>
                                <th>{lang.t('tableHeaders.upfront')}</th>
                                <th>{lang.t('tableHeaders.fee')}</th>
                                <th>{lang.t('tableHeaders.refund')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {segments.slice(0, 10).map((d, i) => {
                                const {
                                    admin_fee = 0,
                                    upfront_premium = 0,
                                    timestamp = '',
                                    refund_premium = 0,
                                } = d;
                                return (
                                <tr key={`revenue-${i}`} style={{ height: 41}}>
                                    <td data-label="Date">
                                        <div className="text-muted text-h5">{moment(timestamp, 'X').format('ll')}</div>
                                    </td>
                                    <td data-label="Total">
                                        <div className="text-h5"><b>{getNumberFormat(currency, upfront_premium + admin_fee + refund_premium, '$0,0')}</b></div>
                                    </td>
                                    <td data-label="Upfront">
                                        <div className="text-muted text-h5">{getNumberFormat(currency, upfront_premium, '$0,0')}</div>
                                    </td>
                                    <td data-label="Admin">
                                        <div className="text-muted text-h5">{getNumberFormat(currency, admin_fee, '$0,0')}</div>
                                    </td>
                                    <td data-label="Refund">
                                        <div className="text-muted text-red text-h5">{getNumberFormat(currency, refund_premium, '$0,0')}</div>
                                    </td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default RevenueTable;
