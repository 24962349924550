import React, { Component } from 'react';
import { get } from 'lodash';
import { calculateRetryPayment } from '../../../../utils/calculateRetryPayment';
import { getNumberFormat } from '../../../../utils/numberFormat';
import { parse_date } from '../Detail/Utils';

class UnpaidTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            successBtns: [],
            failedBtns: [],
        }
    }

    btnType(rowId) {
        const { successBtns, failedBtns } = this.state;
        const { lang } = this.props;
        if (successBtns.includes(rowId)) {
            return { 
                disabled: true,
                style: "btn btn-sm btn-block badge-retry retry-success",
                text: lang.t('policy.table.paid'),
            }
        } else if (failedBtns.includes(rowId)) {
            return {
                disabled: false,
                style: "btn btn-sm btn-block badge-retry retry-failed",
                text: lang.t('policy.table.failed'),
            }
        } else {
            return {
                disabled: false,
                style: "btn btn-sm btn-primary badge-retry",
                text: lang.t('policy.table.retry'),
            }
        }
    }

    updateRetryBtn(isSuccess, rowId) {
        if (isSuccess) {
            const successBtns = [...this.state.successBtns, rowId]
            this.setState({ successBtns })
        }
        if (!isSuccess) {
            const failedBtns = [...this.state.failedBtns, rowId]
            this.setState({ failedBtns })
        }
    }

    retryPayment({ 
        policy_reference, 
        policy_version_reference, 
        product_reference, 
        row_id 
    }) {
        this.props.policys_payments_post({
            data: {
                type: 'RETRY_PAYMENT',
                product_reference,
                policy_reference,
                policy_version_reference,
            }
        }).then((payments_response) => {
            const { error_message } = get(payments_response, 'payload.data', '');
            error_message ? this.updateRetryBtn(false, row_id) : this.updateRetryBtn(true, row_id);
        });
    }

    render () {
        const { unpaid, currency, timezone, lang } = this.props;
        return (
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.versionType')}</th>
                                <th>{lang.t('tableHeaders.versionDate')}</th>
                                <th>{lang.t('tableHeaders.createdAtDate')}</th>
                                <th>{lang.t('tableHeaders.amountUnpaid')}</th>
                                <th>{lang.t('tableHeaders.vehicleReg')}</th>
                                <th className="w-1" />
                            </tr>
                        </thead>
                        <tbody>
                            {unpaid.map((d, i) => {
                                const paymentAmount = calculateRetryPayment(d);
                                const { disabled, style, text } = this.btnType(i);
                                return (
                                    <tr key={`Unpaid-${i}`}>
                                        <td data-label="Version type">
                                            <div className="d-flex lh-sm py-1 align-items-center">
                                                <div className="flex-fill">
                                                    <div className="strong">{d.version_type}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-label="Version start date">
                                            <div className="text-muted text-h5">{parse_date(d.effective_date, 'DD/MM/YYYY HH:mm:ss', timezone)}</div>
                                        </td>
                                        <td data-label="Created at date">
                                            <div className="text-muted text-h5">{parse_date(d.created_at, 'DD/MM/YYYY HH:mm:ss')}</div>
                                        </td>
                                        <td data-label="Payment amount">
                                            <div className="text-muted text-h5">{getNumberFormat(currency, paymentAmount, '$0,0.00')}</div>
                                        </td>
                                        <td data-label="Address postcode">
                                            <div className="text-muted text-h5">{d.vehicle_reg}</div>
                                        </td>
                                        <td>
                                            <div className='btn-list flex-nowrap'>
                                                <button 
                                                    disabled={disabled}
                                                    className={style}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.retryPayment({
                                                            policy_reference: d.policy_reference,
                                                            policy_version_reference: d.policy_version_reference, 
                                                            product_reference: d.product_reference,
                                                            row_id: i
                                                        });
                                                    }}>{text}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default UnpaidTable;