import React, { Component } from 'react';
import moment from 'moment-timezone';
import { orderBy } from 'lodash';
import { getNumberFormat, getUsageFormat } from '../../../../utils/numberFormat';

class PolicyUsagesTable extends Component {
    constructor(props) {
        super(props);
        this.voidUsage = this.voidUsage.bind(this);
    }

    async voidUsage({ id, data, policy_reference }) {
        const { policys_usages_put, policys_usages_get_all } = this.props;

        await policys_usages_put({
            id, 
            data,
        });

        await policys_usages_get_all({
            policy_reference,
        })
    }

    render() {
        const { usages, currency, timezone, lang } = this.props;
        const data = orderBy(usages, 'activated_at', 'desc');


        return (
            <div className="card">

                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.date')}</th>
                                <th>{lang.t('tableHeaders.reference')}</th>
                                <th>{lang.t('tableHeaders.type')}</th>
                                <th>{lang.t('tableHeaders.premium')}</th>
                                <th>{lang.t('tableHeaders.rate')}</th>
                                <th>{lang.t('tableHeaders.distance')}</th>
                                <th className="w-1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((d, i) => {
                                const isInactive = d.is_void || d.policy_version_reference;
                                const total_distance = d.distance_units === 'miles' ? d.total_miles : d.total_kms;
                                const distance_unit = d.distance_units === 'miles' ? 'miles' : 'km';
                                return (
                                    <tr key={`usages-${i}`}>
                                        <td data-label="Date">
                                            <div className={`text-h5 ${isInactive ? 'text-muted': ''}`}>
                                                {moment.tz(d.activated_at, timezone).format('D MMMM YYYY')}
                                            </div>
                                        </td>
                                        <td data-label="Reference">
                                            <div className={`text-h5 ${isInactive ? 'text-muted': ''}`}>
                                                {d.usage_reference}
                                            </div>
                                        </td>
                                        <td data-label="Type">
                                            <div className={`text-h5 ${isInactive ? 'text-muted': ''}`}>
                                                {d.type}
                                            </div>
                                        </td>
                                        <td data-label="Premium">
                                            <div className={`text-h5 ${isInactive ? 'text-muted': ''}`}>
                                                {getNumberFormat(currency, d.usage_premium, '$0,0.00')}
                                            </div>
                                        </td>
                                        <td data-label="Rate">
                                            <div className={`text-h5 ${isInactive ? 'text-muted': ''}`}>
                                                {getNumberFormat(currency, d.usage_rate, getUsageFormat(d.usage_rate))}
                                            </div>
                                        </td>
                                        <td data-label="Distance">
                                            <div className={`text-h5 ${isInactive ? 'text-muted': ''}`}>
                                                {`${getNumberFormat(currency, total_distance, '0,0[.]0')} ${distance_unit}`}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="btn-list flex-nowrap">

                                                {isInactive && 
                                                    <button 
                                                        className={`btn btn-sm ${d.is_void ? 'btn-gray' : 'btn-green'} text-white`} 
                                                        disabled
                                                    >{ d.policy_version_reference ? lang.t('buttons.paid') : lang.t('buttons.void') }
                                                    </button>
                                                }

                                                {!isInactive && 
                                                    <button 
                                                        className="btn btn-sm btn-red text-white"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.voidUsage({
                                                                id: d.usage_reference,
                                                                data: {
                                                                    is_void: true,
                                                                },
                                                                policy_reference: d.policy_reference,
                                                            });
                                                        }}
                                                    >{lang.t('buttons.void')}
                                                    </button>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default PolicyUsagesTable;
