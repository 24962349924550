import React, { Component } from 'react';
import VersionsTable from './VersionsTable';

class Tests extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { versions = [], version_reference, selectVersion, lang } = this.props;
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card theme-dark">
                        <div className="card-header">
                            <h4 className="card-title">{lang.t('rating.components.versions.title')}</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="alert" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        {lang.t('rating.components.versions.versionsInfo')}
                                    </div>
                                    <VersionsTable 
                                        versions={versions}
                                        version_reference={version_reference}
                                        selectVersion={selectVersion}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Tests;