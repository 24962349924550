import React from 'react';
import jsen from 'jsen';
import { validateColumnsSchema } from './validateColumnsSchema';

function ConfigureModelSection({
    model,
    update,
    lang,
}) {

    const validate = ({
        input,
        schema,
    }) => {
        let validInput;
        let error;
        try {
            const validate = jsen(schema);
            validInput = validate(JSON.parse(input));

            if (!validInput) {
                error = JSON.stringify(validate.errors[0]);
            } else {
                error = null;
            }
        } catch(e) {
            error = e.message
        }
        
        update({
            error,
        });

        return validInput;
    }

    const updateModel = (item) => {
        const validInput = validate({
            input: item,
            schema: validateColumnsSchema,
        });

        if (validInput) {
            const parsed = JSON.parse(item);
            const { one_hot_keys, columns_for_predictions } = parsed;

            const updated_model = {
                ...model,
                one_hot_keys,
                one_hot_columns: columns_for_predictions,
            }

            update({
                model: updated_model,
            });
        }
    }
    
    const defaultDisplay = {
        one_hot_keys: model.one_hot_keys || [],
        columns_for_predictions: model.one_hot_columns || [],
    }
    return (
        <>  
            <div className="alert alert-info" role="alert">
                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                {lang.t('rating.components.machineLearning.defineColumns')}
            </div>
            <textarea 
                className="form-control"
                data-toggle="autosize"
                style={{
                    overflow: 'auto',
                    background: '#354052',
                    borderRadius: '3px',
                    fontSize: '14px',
                    color: '#eaeaea',
                    height: '305px',
                    width: '100%',
                }} 
                onBlur={(e) => {
                    updateModel(e.target.value);
                }}
                defaultValue={JSON.stringify(defaultDisplay, null, 4)}
            />
        </>
    );
    

} 

export default ConfigureModelSection;