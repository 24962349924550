import L from '1liner';

import confidence from './utils/confidence';
import { cohortsConfig, riskCombos } from './utils/config';
import { riskStats } from './utils/aggregate.js'
import { uniqBy } from 'lodash';

export function controller ({
    config,
    iterables, // [risk1, risk2]
    queryBase, //start of string
    selected_cohorts,
    l,
}) {
    let query = queryBase;
    const queryArr = [];
    let cohortArr = [];

    function write(query) {
        const result = l.query(query);
        const stats = riskStats(result);
        
        const conf = confidence({
            value: stats.claimsFaultFreq,
            exposure: stats.exposureSumYears,
            confidence: selected_cohorts.confidence,
            book_freq: selected_cohorts.ave_freq,
        });

        queryArr.push(query);

        cohortArr = uniqBy([...cohortArr, {
            query,
            ...stats,
            ...conf,
        }], 'query');
    }

    function setValues (set, query, iterable, step) {
        const { type, values } = set;
        let sourceQuery = '';
        values.forEach(v => {
            if (type === 'str') {
                sourceQuery = query.replace(`::${iterable}_curr`, v);
                write(sourceQuery);
            } else { 
                if ((v + step) > set.max) return false;
                sourceQuery = query.replace(`::${iterable}_curr`, v).replace(`::${iterable}_next`, (v + step));
                write(sourceQuery);
            }
        });
        if (type === 'int') {
            step = step + set.min_step;
            if (step <= 10) setValues(set, query, iterable, step);
        }
    }

    iterables.forEach((iterable, i) => {
        const set = config[iterable];
        if (queryArr.length === 0){
            query = query + set.query;
            setValues(set, query, iterable, set.min_step);
        } else {
            queryArr.forEach(qa => {
                query = qa + set.query;
                setValues(set, query, iterable, set.min_step);
            });
        }
    });

    return cohortArr;
} 

export function build (selected_cohorts, selected_risks, data) {
    try {
        
        const arrIterables = riskCombos(selected_risks);
        const l = new L({ data });
        let allCohorts = [];

        arrIterables.forEach((iterables, i) => {

            const config = cohortsConfig(selected_risks, data, selected_cohorts);

            const cohortArr = controller({
                config,
                iterables, // [risk1, risk2]
                queryBase: 'data', //start of string
                selected_cohorts,
                l,
            });
            const uniqCohortArr = uniqBy([...allCohorts, ...cohortArr], 'query');

            postMessage({
                cohortArr: uniqCohortArr,
                iterables: iterables.join(','),
                cohortCount: uniqCohortArr.length,
                currentCount: (i + 1),
                totalCount: arrIterables.length,
            });
        });

        postMessage({
            isDone: true,
        })

    }catch(e) {
        console.log(e);
    }
}

export default build;