import React, { Component } from 'react';
import SuperTable from '../../../../components/tables/Super';
import { defaultDateFormat } from '../../../../utils/format';

export class TransactionsSearchTable extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const headers = [
            {
                label: 'Description',
                name: 'description',
                search: true,
            },
            {
                label: 'Amount',
                name: 'amount',
                search: true
            },
            {
                label: 'Credit Or Debit',
                name: 'credit or debit',
                search: false,
            },
            {
                label: 'Currency',
                name: 'currency',
                search: false,
            },
            {
                label: 'Date',
                name: 'date',
                search: false,
            },
        ]
    
        const Cells = (index, d) => {
            return (
                <tr key={`factors-${index}`}>
                    <td data-label="description">  
                        <div className="text-h5">{d.description}</div> 
                    </td>
                    <td data-label="Amount">
                        <div className="text-h5">{d.amount}</div>
                    </td>
                    <td data-label="CreditorDebit">
                        <div className="text-h5">{d.creditOrDebit}</div>
                    </td>
                    <td data-label="Currency">
                        <div className="text-h5">{d.currency}</div>
                    </td>
                    <td data-label="Date">
                        <div className="text-h5">{defaultDateFormat(d.date)}</div>
                    </td>
                </tr>
            );
        }

        const {
            tableData,
            lang,
        } = this.props;

        return (
            <div>
                <SuperTable 
                    data={tableData}
                    headers={headers}
                    Cells={Cells}
                    lang={lang}
                />
            </div>
        )
    }
}

export default TransactionsSearchTable
