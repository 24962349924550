import React, { Component } from 'react';
import SuperTable from '../../../../components/tables/Super';

class TableConvictions extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], lang } = this.props;

        let headers = [
            {
                label: lang.t('rating.components.tableConvictions.date'),
                name: 'date',
                search: false,
            },
            {
                label: lang.t('rating.components.tableConvictions.code'),
                name: 'conviction_code',
            },
            {
                label: lang.t('rating.components.tableConvictions.points'),
                name: 'points',
            },
            {
                label: lang.t('rating.components.tableConvictions.ban'),
                name: 'ban',
            },
        ];

        const Cells = (index, d) => {
            return (
                <tr key={`convictions-${index}`}>
                    <td data-label="Date">  
                        <div className="text-h5">{d.date}</div>
                    </td>
                    <td data-label="Code">
                        <div className="text-h5">
                            {d.code.slice(0, 4)}
                        </div>
                    </td>
                    <td data-label="Points">
                        <div className="text-h5">
                            {d.points}
                        </div>
                    </td>
                    <td data-label="Ban">
                        <div className="text-h5">
                            {d.ban}
                        </div>
                    </td>
                </tr>
            )
        }

        return(
            <SuperTable 
                data={data}
                headers={headers}
                Cells={Cells}
                auto_sort={true}
                lang={lang}
            />
        )
    }
}

export default TableConvictions;