import React, { Component } from 'react';

class StatMini extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const {
            mainCount,
            mainLabel,
            subCount,
            subLabel,
            icon = 'fe-chart',
            colour = 'blue',
        } = this.props;
        return (
            <div class="card card-sm">
                <div class="card-body d-flex align-items-center">
                    <span class={`bg-${colour}  text-white stamp mr-3`}>
                        <i class={`fe ${icon}`}></i>
                    </span>
                    <div class="mr-3 lh-sm">
                        <div class="strong" style={{ fontSize: 18 }}>{mainCount} {mainLabel}</div>
                        <div class="text-muted">{subCount} {subLabel}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StatMini;