import React, { Component } from 'react';

import SuperTable from '../../../../../components/tables/Super';

//TODO: Could use functional component as no state here
class ScenariosTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { pricing = [], is_fetching, quotes, lang } = this.props;

        const headers = [
            {
                label: lang.t('tableHeaders.id'),
                name: 'id',
            },
            {
                label: lang.t('tableHeaders.name'),
                name: 'name',
                search: true,
            },
            {
                label: lang.t('tableHeaders.premium'),
                name: 'premium',
                class: 'w-1'
            },
            {
                label: lang.t('tableHeaders.excess'),
                name: 'excess',
                class: 'w-1'
            },
            {
                label: lang.t('tableHeaders.exclude'),
                name: 'exclude',
                class: 'w-1'
            },
            {
                label: lang.t('tableHeaders.refer'),
                name: 'refer',
                class: 'w-1'
            },
            {
                label: '',
                name: '',
                class: 'w-1'
            },
            {
                label: '',
                name: '',
                class: 'w-1'
            }
        ];

        const Cells = (index, d) => {
            const { processed = false, is_loading = false } = is_fetching[index] || [];
            const exclude = !!d.exclusion_count;
            const refer = !!d.refer_count;
            return (
                <tr key={`scenarios-${index}`}>
                    <td data-label={lang.t('tableHeaders.id')}>
                        <div className="text-muted text-h5">{d.id}</div>
                    </td>
                    <td data-label={lang.t('tableHeaders.name')}>
                        <div className="text-h5"><b>{d.name}</b></div>
                    </td>
                    <td data-label={lang.t('tableHeaders.premium')}>
                        <div className="text-h5"><b>{d.base_premium}</b></div>
                    </td>
                    <td data-label={lang.t('tableHeaders.excess')}>
                        <div className="text-muted text-h5">{d.total_excess}</div>
                    </td>
                    <td data-label={lang.t('tableHeaders.include')}>
                        { processed ? 
                            <div className={exclude == true ? 'badge bg-red' : 'badge bg-green'}>
                                {exclude == true ? (lang.t('tableHeaders.exclude').toUpperCase()) :  (lang.t('tableHeaders.include').toUpperCase())}
                            </div>  :
                            null
                        }
                            
                    </td>
                    <td data-label="Refer">
                    { processed ? 
                        <div className={refer == true ? 'badge bg-red' : 'badge bg-green'}>
                            {refer == true ? (lang.t('tableHeaders.refer').toUpperCase()) :  (lang.t('tableHeaders.notRefer').toUpperCase())}
                        </div> :
                        null
                    }
                    </td>
                    { !processed && !is_loading &&
                        <td data-label={lang.t('buttons.edit')}>
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                this.props.edit(d.name);
                            }} className="btn btn-primary btn-sm">
                                {lang.t('buttons.edit')}
                            </a>
                        </td>
                    }
                    { !processed && is_loading &&
                        <td data-label="Blank-Space" />
                    }
                    { processed &&
                        <td data-label={lang.t('buttons.details')}>
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                this.props.details(quotes[index], pricing[index]);
                            }} className="btn btn-primary btn-sm">
                                {lang.t('buttons.details')}
                            </a>
                        </td>
                    }
                    { !processed && !is_loading &&
                        <td data-label={lang.t('buttons.delete')}>
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                this.props.delete(d.name);
                            }} className="btn btn-red btn-sm">
                                {lang.t('buttons.delete')}
                            </a>
                        </td>
                    }
                    { is_loading && 
                        <td data-label={lang.t('buttons.spinner')}>
                            <div class="spinner-border text-light" role="status" />
                        </td> 
                    }
                    { processed && 
                        <td data-label={lang.t('buttons.success')}>
                            <span className="avatar bg-green">
                                <i style={{color: 'white'}} className="fe fe-check"></i>
                            </span>
                        </td>
                    }
                </tr>
            );
        }

        return (
            <SuperTable 
                // Filters out undefined
                data={pricing}
                headers={headers}
                Cells={Cells}
                limit={1000}
                height={500}
                lang={lang}
            />
        );
    }
}

export default ScenariosTable;