import React, { Component } from 'react';
import { get } from 'lodash';

import FormBase from '../Forms/FormBase';
import FormAddress from '../Forms/FormAddress';
import FormVehicle from '../Forms/FormVehicle';
import FormProposer from '../Forms/FormProposer';
import FormPricing from '../Forms/FormPrice';
import FormCustom from '../Forms/FormCustom';
import FormAdditionalDrivers from '../Forms/FormAdditionalDrivers';
import ModalAddDriver from '../Modal/ModalAddDriver';
import ModalAddClaim from '../Modal/ModalAddClaim';
import ModalAddConviction from '../Modal/ModalAddConviction';
import ModalAddMod from '../Modal/ModalAddMod';
import ModalConfirmMTA from '../Modal/ModalConfirmMTA';
import ModalDeleteDriver from '../Modal/ModalDeleteDriver';
import ModalAddCustom from '../Modal/ModalAddCustom';
import { validate_policy_object } from '../Utils';

class MTASection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: {
                add_driver: {
                    open: false,
                },
                add_claim: {
                    open: false,
                    claim: {},
                },
                add_conviction: {
                    open: false,
                    conviction: {}
                },
                add_mod: {
                    open: false,
                },
                confirm_change: {
                    open: false,
                },
                delete_driver: {
                    open: false,
                },
                add_custom: {
                    open: false,
                }
            },
            driver_index: null,
            pol_obj_validation: {}
        }
        this.modal_open = this.modal_open.bind(this);
        this.set_driver_index = this.set_driver_index.bind(this);
        this.open_confirm_modal = this.open_confirm_modal.bind(this);
    }

    modal_open(item) {
        this.setState({
            modal: {
                ...this.state.modal,
                ...item,
            }
        });
    }

    set_driver_index(index) {
        this.setState({
            driver_index: index,
        })
    }

    open_confirm_modal() {

        // Validate policy object

        const valResult = validate_policy_object({
            data: this.props.policy_detail,
            product_reference: get(this.props, 'policy_detail.policy.product_reference'),
        });

        // Open modal
        
        this.setState({
            modal: {
                ...this.state.modal,
                confirm_change: {
                    open: true,
                }
            },
            pol_obj_validation: valResult,
        })
    }

    render() {
        const {
            additional_drivers = [],
        } = this.props.policy_detail;

        const { modal, driver_index, pol_obj_validation } = this.state;
        const num_add_drvs = additional_drivers.length;
        const { disabled, timezone, policy_detail: { policy: { product_reference }}, lang } = this.props;

        return [
            <>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <h2 className="col-lg-6" style={{paddingLeft: '30px'}}>
                                {lang.t('policy.nav.policyAmendment')}
                            </h2>
                            <div className="col-lg-6">
                                <h5 className="text-right mb-4 text-red"><b>* {lang.t('policy.policies.requiredFields')}</b></h5> 
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <FormBase 
                            update={this.props.update}
                            detail={this.props.policy_detail}
                            disabled={disabled}
                            timezone={timezone}
                            lang={lang}
                        />
                        <FormAddress 
                            update={this.props.update}
                            detail={this.props.policy_detail}
                            disabled={disabled}
                            lang={lang}
                        />
                        <FormVehicle 
                            update={this.props.update}
                            detail={this.props.policy_detail}
                            vehicles_get={this.props.vehicles_get}
                            product_reference={product_reference}
                            modal_open={this.modal_open}
                            disabled={disabled}
                            lang={lang}
                        />
                    </div>
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{lang.t('policy.policies.drivers')}</h3>
                                {num_add_drvs < 4 && !disabled &&
                                    <a className="btn btn-sm btn-primary text-white ml-auto" style={{
                                        position: 'absolute',
                                        top: '12px',
                                        right: '16px'
                                    }}
                                    onClick={() => this.modal_open({
                                        add_driver: {
                                            open: true,
                                        }
                                    })}
                                    >+ {lang.t('policy.policies.addDriver')}</a>}
                                {num_add_drvs > 0 && !disabled &&
                                    <a className="btn btn-sm btn-red text-white ml-auto" style={{
                                        position: 'absolute',
                                        top: '12px',
                                        right: `${num_add_drvs === 4 ? '16px' : '116px'}`
                                    }}
                                    onClick={() => this.modal_open({
                                        delete_driver: {
                                            open: true,
                                        }
                                    })}
                                    >{lang.t('policy.policies.deleteDriver')}</a>}
                            </div>
                            
                            <div className="card-tabs" style={{ position: 'relative', marginBottom: '0px' }}>
                                <ul className={`nav nav-tabs ${additional_drivers.length === 4 && 'nav-fill'}`}>
                                    <li className="nav-item"><a href="#tab-top-1" className="nav-link active" data-toggle="tab">{lang.t('policy.policies.proposer')}</a></li>
                                    {additional_drivers.map((ad, i) => {
                                        return (
                                            <li className="nav-item" key={`driver-tab-${i}`}>
                                                <a href={`#tab-top-${i + 2}`} className="nav-link" data-toggle="tab">{lang.t('policy.policies.driver')} {i + 1}</a>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <div className="tab-content">
                                    <div id="tab-top-1" className="card tab-pane active show">
                                        <FormProposer 
                                            update={this.props.update}
                                            detail={this.props.policy_detail}
                                            modal_open={this.modal_open}
                                            set_driver_index={this.set_driver_index}
                                            disabled={disabled}
                                            update_page={this.props.update_page}
                                            is_mta
                                            lang={lang}
                                        />
                                    </div>
                                    {additional_drivers.map((ad, i) => {
                                        return (
                                            <div id={`tab-top-${i + 2}`} className="card tab-pane" key={`tab-pane-${i}`}>
                                                <FormAdditionalDrivers 
                                                    update={this.props.update}
                                                    detail={this.props.policy_detail}
                                                    driver={ad}
                                                    modal_open={this.modal_open}
                                                    set_driver_index={this.set_driver_index}
                                                    disabled={disabled}
                                                    lang={lang}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FormCustom 
                    update={this.props.update}
                    detail={this.props.policy_detail}
                    modal_open={this.modal_open}
                    disabled={disabled}
                    lang={lang}
                />
                <div style={{ textAlign: 'right', marginBottom: '30px', marginTop: '20px' }}>
                    <button 
                        className="btn btn-primary"
                        onClick={this.open_confirm_modal}
                        disabled={disabled}
                        >
                        {lang.t('buttons.next')}
                    </button>
                </div>
            </>,
            <ModalAddDriver 
                open={modal.add_driver.open}
                modal_open={this.modal_open}
                update={this.props.update}
                detail={this.props.policy_detail}
                claim={modal.add_claim.claim}
                conviction={modal.add_conviction.conviction}
                lang={lang}
            />,
            <ModalAddClaim
                open={modal.add_claim.open}
                modal_open={this.modal_open}
                detail={this.props.policy_detail}
                driver_index={driver_index}
                update={this.props.update}
                set_driver_index={this.set_driver_index}
                lang={lang}
            />,
            <ModalAddConviction 
                open={modal.add_conviction.open}
                modal_open={this.modal_open}
                detail={this.props.policy_detail}
                driver_index={driver_index}
                update={this.props.update}
                set_driver_index={this.set_driver_index}
                lang={lang}
            />,
            <ModalAddMod 
                open={modal.add_mod.open}
                detail={this.props.policy_detail}
                modal_open={this.modal_open}
                update={this.props.update}
                lang={lang}
            />,
            <ModalConfirmMTA 
                open={modal.confirm_change.open}
                detail={this.props.policy_detail}
                modal_open={this.modal_open}
                update={this.props.update}
                policys_put={this.props.policys_put}
                detail_readonly={this.props.pol_detail_readonly}
                auth_details={this.props.auth_details}
                disabled={disabled}
                edited={this.props.edited}
                pol_obj_validation={pol_obj_validation}
                policys_products_get={this.props.policys_products_get}
                settings_users_get_all={this.props.settings_users_get_all}
                settings_users_put={this.props.settings_users_put}
                timezone={this.props.timezone}
                lang={lang}
            />,
            <ModalDeleteDriver 
                open={modal.delete_driver.open}
                detail={this.props.policy_detail}
                modal_open={this.modal_open}
                update={this.props.update}
                lang={lang}
            />,
            <ModalAddCustom
                open={modal.add_custom.open}
                detail={this.props.policy_detail}
                modal_open={this.modal_open}
                update={this.props.update}
                lang={lang}
            />
        ];
    }
}

export default MTASection;