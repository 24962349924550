import React, { Component } from 'react';

class ModalDeleteDriver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: null,
        }

        this.update = this.update.bind(this);
        this.close = this.close.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    update(e) {
        this.setState({ index: e.target.value })
    }

    close() {
        this.setState({ index: null });

        this.props.modal_open({ 
            delete_driver: {
                open: false,
            }
        });
    }

    confirm() {
        const { detail, update } = this.props;
        const { index } = this.state;

        let additional_drivers = [...detail.additional_drivers];

        additional_drivers.splice(index, 1);

        update({
            ...detail,
            additional_drivers 
        });

        this.close();

    }

    render() {
        const { open, detail, lang } = this.props;
        const { index } = this.state;
        return(
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-mod" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header theme-dark" >
                            <h5 className="modal-title">{lang.t('policy.policies.selectDriverDelete')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className={`alert alert-warning mb-4`} role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                {lang.t('policy.policies.deleteDriverWarning')}
                            </div>
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" style={{ width: '140px' }}>{lang.t('policy.policies.selectDriver')}</span>
                                </div>
                                <select className="form-control" onChange={this.update}>
                                    <option selected></option>
                                    {detail.additional_drivers.map((drv, index) => {
                                        return (
                                            <option value={index} key={`delete-drv-${index}`}>
                                                {`Driver ${index + 1} - ${drv.first_names} ${drv.last_names}`}
                                            </option>
                                        )
                                            
                                    })}
                                    
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {index &&
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={this.confirm}
                                >
                                    {lang.t('buttons.confirm')}
                                </button>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalDeleteDriver;