import React, { Component } from 'react';
import clientConfig from '../../../../../config/client_config';
import ValidationCheck from './components/ValidationCheck';
import SelectEffectiveDate from './components/SelectEffectiveDate';
import SelectPriceMethod from './components/SelectPriceMethod';
import MTAManualPriceFees from './components/MTAManualPriceFees';
import MTASummary from './components/MTASummary';
import ChangeSuccess from './components/ChangeSuccess';
import { omit, get, round } from 'lodash';
import axios from 'axios';
import moment from 'moment-timezone';
import { 
    format_policy, 
    format_proposer,
    format_vehicle,
    format_add_drivers,
    format_pricing,
    calculate_price_change,
    parse_date
} from '../Utils';

class ModalConfirmMTA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: {},
            error: null,
            isSubmitting: false,
            price_change: {},
            stage: 'effective_date',
            stageArray: ['effective_date'],
            hasCalledRating: false,
        }

        this.close = this.close.bind(this);
        this.update = this.update.bind(this);
        this.submit = this.submit.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.calculatePriceUpdate = this.calculatePriceUpdate.bind(this);
        this.callRatingEngine = this.callRatingEngine.bind(this);
        this.manualPriceUpdate = this.manualPriceUpdate.bind(this);

        
    }



    close() {
        if(this.state.stage === 'mta_success') {
            return window.location.reload();
        }

        this.props.modal_open({
            confirm_change: {
                open: false,
            }
        });
        this.setState({
            date: {},
            error: null,
            submitted: false,
            stage: 'effective_date',
            stageArray: ['effective_date'],
            price_change: {}
        });

    }

    update(date) {

        const { detail } = this.props;
        this.setState({ date, error: null });
        this.props.update({
            ...detail,
            policy: {
                ...detail.policy,
                ...date,
            }
        });
    }

    updatePriceChange(price_change) {
        this.setState({ price_change })
    }

    updatePaymentPricing(price_change) {
        const { detail, update } = this.props;

        update({
            ...detail,
            payment: {
                payment_amount: round(price_change.calc_amount + price_change.mta_fee, 2),
                payment_provider: 'stripe',
            },
            pricing: {
                ...detail.pricing,
                upfront_premium: round(price_change.calc_amount, 2),
            }
        });
    }

    
    convertLocalDateToFixedTimezone(date) {
    
        const timezone = this.props.timezone;

        const dateWithNoTimeZone = moment.unix(date).format('YYYY-MM-DD HH:mm');

        const dateWithTimezone = moment.tz(dateWithNoTimeZone, timezone);

        return dateWithTimezone;
    }


    cleanPolicyObject(obj, enableCodelist) {

        //1. Clean effective date - if MTA effective date is the same as the effective date in the latest version, add a second to the MTA timestamp

        const version_latest_effective_date = moment(get(obj, 'versions[0].effective_date')).unix();
        let mta_effective_date = get(obj, 'policy.effective_date');

        // Note: UI does not allow user to select a date before effective date of latest version
        // The mta_effective_date comes from the date picker which defaults the time to 00:00:00 - the '<=' handles scenarios where there are already MTAs on the date selected
        
        if (mta_effective_date <= version_latest_effective_date) {
            mta_effective_date = version_latest_effective_date + 1;
        }

        //2. Remove versions and version type from the object and Format verbose labels to code and ensure correct types

        let result = omit({
            ...obj,
            policy: format_policy(obj.policy, enableCodelist),
            proposer: format_proposer(obj.proposer, enableCodelist),
            additional_drivers: format_add_drivers(obj.additional_drivers, enableCodelist),
            vehicle: format_vehicle(obj.vehicle, enableCodelist),
            pricing: format_pricing(obj.pricing),
        }, 'versions')

        //3. Add 'cleaned' mta effective date to policy and version type

        result = {
            ...result,
            policy: {
                ...result.policy,
                effective_date: mta_effective_date,
                is_renewal: false,
            }
        }
        
        return result;
    }

    manualPriceUpdate() {
        this.setState({ 
            hasCalledRating: false,
            error: null,
        });
        this.next('price_fees');
    }

    async callRatingEngine() {
        const { auth_details, detail } = this.props;
        const companyRef = get(auth_details, 'data.company_reference');
        const endpointDetails = get(clientConfig, `${companyRef}.mta_quote`);
        const enableCodelist = get(clientConfig, `${companyRef}.codelist.enabled`);

        const cleaned = this.cleanPolicyObject(detail, enableCodelist);

        // Call rating engine

        try {
            this.setState({ 
                isSubmitting: true,
                hasCalledRating: true,
            });

            // Get the webhooks secret key from policys_product_get which might be used by clients for rating engine key as well

            const { getAuth } = endpointDetails.authorisation; // get the client specific getAuth module
            const product_reference = get(detail, 'policy.product_reference');
            const mta_url = get(endpointDetails, `${product_reference}.url`);

            const policys_products = await this.props.policys_products_get({ id: product_reference });

            const authHeader = getAuth({
                policys_products,
                endpointDetails,
                companyRef,
            });

            // Make external API call

            const { data: quote_res } = await axios({
                url: mta_url,
                data: cleaned,
                method: endpointDetails.method,
                headers: {
                    ...endpointDetails.headers,
                    ...authHeader,
                },
            });

            // Update pricing block of the policy object - upfront premium to be updated just before policy PUT as it is calculated in next step

            this.props.update({
                ...detail,
                pricing: {
                    ...detail.pricing,
                    quote_model_reference: quote_res.model_reference,
                    quote_reference: quote_res.quote_reference,
                    quote_version_reference: quote_res.version_reference,
                    upfront_rate: quote_res.upfront_rate,
                    usage_rate: quote_res.usage_rate,
                    subscription_rate: quote_res.subscription_rate,
                }
            });

            // Move to next step;
            this.next('price_fees');
            
        } catch (err) {
            console.log(err);
            console.log(err.response);
            
            if (err.response) {
                this.setState({ 
                    error: `ERROR - ${get(err, endpointDetails.error_status_path)} - ${get(err, endpointDetails.error_message_path)}`,
                    hasCalledRating: false,
                });
            } else {
                this.setState({
                    error: err.message,
                    hasCalledRating: false,
                })
            }
            
        } finally {
            this.setState({ isSubmitting: false });
        }
    }

    calculatePriceUpdate() {
        const { detail, detail_readonly } = this.props;

        const after = {
            ...detail.pricing,
            ...detail.policy,
        }

        const price_change = calculate_price_change(detail_readonly.versions[0], after);

        this.updatePaymentPricing(price_change);
        this.updatePriceChange(price_change);
        this.next('mta_summary');
    }

    // next() {
    next(stage) {

        if (this.state.stage === 'effective_date'){

            // if stage is SelectEffectiveDate get the selected effective_date from props.
            const { update, detail } = this.props;

            // update the effective_date of policy props to fixed timezone that is defined in pm_products
            const dateWithTimezone = this.convertLocalDateToFixedTimezone(this.state.date.effective_date);

            update({
                ...detail,
                policy: {
                    ...detail.policy,
                    effective_date: dateWithTimezone.unix()
                }
            });
        }

        this.setState({
            stage,
            stageArray: [...this.state.stageArray, stage],
        });
    }

    back() {
        const { stageArray } = this.state
        stageArray.pop();
        this.setState({
            // stage: this.state.stage - 1,
            stage: stageArray[stageArray.length - 1],
            price_change: {},
            error: null, //If user navigated back from MTA summary due to error, reset the error
        });
    }

    async submit() {
        const { detail, policys_put, auth_details } = this.props;
        const companyRef = get(auth_details, 'data.company_reference');
        const enableCodelist = get(clientConfig, `${companyRef}.codelist.enabled`);

        this.setState({ 
            error: null, 
            isSubmitting: true,
        });

        // 1. Clean policy object again
        const result = this.cleanPolicyObject(detail, enableCodelist);

        try {

            // 2. Submit to put policys endpoint 
            await policys_put({
                id: result.policy_reference,
                data: result,
            });

            // 3. Update state and move to next stage

            this.setState({
                isSubmitting: false,
            });

            this.next('mta_success');

        } catch(e) {
            console.log(e)
            console.log(e.message)
            this.setState({ 
                error: get(e, 'error.response.data.message', e.error.data),
                isSubmitting: false, 
            });
        }
    }


    render() {
        const { open, detail_readonly, edited, detail, update, pol_obj_validation, timezone, lang } = this.props;
        const { date, error, isSubmitting, price_change, stage, hasCalledRating } = this.state;
        const latestEffectiveDate = parse_date(detail_readonly.versions[0].effective_date, 'YYYY-MM-DD', timezone);
        const dateTimeNowLocal = parse_date(new Date(), 'YYYY-MM-DD', timezone);
        const minDate = Math.max(new Date(dateTimeNowLocal), new Date(latestEffectiveDate)); 
        const { isValid, valErrors } = pol_obj_validation;


        return(
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} id="modal-mod" tabIndex="-1" role="dialog" aria-modal="true">
                {!isValid && (
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <ValidationCheck 
                            next={this.next}
                            close={this.close}
                            edited={edited}
                            valErrors={valErrors}
                            lang={lang}
                        />
                    </div>
                )}
                {isValid && (
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        {stage === 'effective_date' &&
                            <SelectEffectiveDate
                                close={this.close}
                                date={date}
                                update={this.update}
                                next={()=>this.next('price_method')}
                                minDate={minDate}
                                edited={edited}
                                detail_readonly={detail_readonly}
                                timezone={timezone}
                                lang={lang}
                            />
                        }
                        {stage === 'price_method' &&
                            <SelectPriceMethod
                                close={this.close}
                                back={this.back}
                                callRatingEngine={this.callRatingEngine}
                                error={error}
                                isSubmitting={isSubmitting}
                                manualPriceUpdate={this.manualPriceUpdate}
                                title={lang.t('policy.policies.selectMethodPrice')}
                                text={lang.t('policy.policies.mtaPriceText')}
                                buttonOne={lang.t('policy.policies.manualPriceEntry')}
                                buttonTwo={lang.t('policy.policies.requestMtaPrice')}
                                lang={lang}
                            />
                        }
                        {stage === 'price_fees' &&
                            <MTAManualPriceFees 
                                close={this.close}
                                back={this.back}
                                next={()=>this.next('mta_summary')}
                                detail={detail}
                                update={update}
                                calculatePriceUpdate={this.calculatePriceUpdate}
                                hasCalledRating={hasCalledRating}
                                type="MTA"
                                lang={lang}
                            />
                        }
                        {stage === 'mta_summary' &&
                            <MTASummary
                                close={this.close}
                                back={this.back}
                                submit={this.submit}
                                price_change={price_change}
                                detail={detail}
                                error={error}
                                isSubmitting={isSubmitting}
                                timezone={timezone}
                                lang={lang}
                            />
                        }
                        {stage === 'mta_success' &&
                            <ChangeSuccess 
                                close={this.close}
                                title_text={lang.t('policy.policies.mtaProcessed')}
                                alert_text={lang.t('policy.policies.mtaSuccessful')}
                                body_text={`${lang.t('policy.policies.mtaEffectiveFrom')} ${this.convertLocalDateToFixedTimezone(date.effective_date).format("dddd Do @ HH:mm MMMM YYYY!")}`}
                                lang={lang}
                            />
                        }
                    </div>
                )}
            </div>
        )
    }
}

export default ModalConfirmMTA;