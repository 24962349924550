import React, { Component } from 'react';
import Chart from 'react-apexcharts';

function chartBuilder({
    weights,
    label_name,
    labels,
}){
    const limit = 250;
    return {
        series: [{
            name: "Weight",
            data: weights.slice(0, limit),
        }],
        options: {
            chart: {
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            fill: {
                opacity: 1,
            },
            grid: {
                padding: {
                    top: -20,
                    right: 0,
                    left: -4,
                    bottom: 0
                },
                strokeDashArray: 4,
                xaxis: {
                    lines: {
                        show: true
                    }
                },
            },
            xaxis: {
                type: 'category',
                labels: {
                    padding: 4,
                    show: true,
                },
                tooltip: {
                    enabled: false
                },
                axisBorder: {
                    show: false,
                },
                tickAmount: 10,
                title: {
                    text: label_name
                }
            },
            yaxis: {
                labels: {
                    padding: 4
                },
            },
            labels: labels.slice(0, limit),
            colors: ["#206bc4", "#79a6dc", "#bfe399"],
            legend: {
                show: false,
            },
        }
    }
}

class ModalFactorChart extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { options, series } = chartBuilder(this.props.chart);
        return(
            <Chart
                options={options}
                series={series}
                type="line"
                height="190"
            />
        );
    }
}

export default ModalFactorChart;
