import React, { Component } from 'react';
import S from 'string';
import { round, sumBy, meanBy } from 'lodash';
import colours from '../../../../components/colours';
import numeral from 'numeral';

function buildGradient({
    value,
    avg,
    dir = 'asc',
    full = false,
}) {
    if (value === 0 ) return '';

    let colour;

    if (value < avg) {
        colour = dir === 'asc' ? colours['redlighter'] : colours['greenlighter'];
    } else {
        colour = dir === 'asc' ? colours['greenlighter'] : colours['redlighter'];
    }
    if(dir === 'gray') colour = colours['graylightest'];
    if(dir === 'green') colour = colours['greenlighter'];
    if(dir === 'red') colour = colours['redlighter'];
    
    if (full) return `linear-gradient(90deg, ${colour} 100%, white 0%)`

    let perc = round(value /(2* avg), 1) * 100;
    if (perc >= 95) perc = 95;
    
    return `linear-gradient(90deg, ${colour} ${perc}%, white 1%)`
}

function tidyQuery(query, title) {
    let clean = query
        .replace(title, '')
        .replace(title, '')
        .replace('.filter', ' ')
        .replace('.filter', ' ')
        .replace('data', '')
    return clean;
}

function periodCells({
    d,
    data,
    key,
    padding,
    width,
}) {
    const meanEarnedPremium =  meanBy(data.map(d => d[key]).filter(d => d.earnedPremium > 0), 'earnedPremium');
    const meanClaims =  meanBy(data.map(d => d[key]).filter(d => d.claimInc > 0), 'claimInc');
    return [
        <td style={{
            backgroundImage: buildGradient({
                value: d.earnedPremium,
                avg: meanEarnedPremium,
                dir: 'green',
            }),
            padding,
            width
        }}>
            £{numeral(d.earnedPremium).format('0,0')}
        </td>,
        <td style={{
            backgroundImage: buildGradient({
                value: d.claimInc,
                avg: meanClaims,
                dir: 'red',
            }),
            padding,
            width
        }}>
            £{numeral(d.claimInc).format('0,0')}
        </td>,
        <td style={{
            backgroundImage: buildGradient({
                value: d.claimsFaultFreq,
                avg: 5,
                dir: 'desc',
                full: false,
            }),
            padding,
            width,
        }}>
            {d.claimsFaultFreq}
        </td>,
        <td style={{
            backgroundImage: buildGradient({
                value: d.LRInc,
                avg: 70,
                dir: 'desc',
                full: false,
            }),
            padding,
            width,
        }}>
            {d.LRInc}
        </td>
    ]
}

class FactorsTable extends Component {
    constructor(props) {
        super(props);
        this.key = `${S(props.title).slugify().s}-chart`;
    }

    render() {
        const { title, colour, lang } = this.props;
        const { data = [] } = this.props;
        return (
            <div style={{ background: 'white', padding: 4}} className="mb-3" id={this.key}>
                <table style={{ fontSize: 11 }} className="table mb-1 mt-1 bg-white">
                    <thead>
                        <tr>
                            <th style={{ width: 100 }}>{title.replace('drivers_', '').replace('policy_', '')}</th>
                            <th>Exp.</th>
                            <th>CIPEY</th>
                            <th>PR 13W</th>
                            <th>CL 13W</th>
                            <th>FQ 13W</th>
                            <th>LR 13W</th>
                            <th>PR 26W</th>
                            <th>CL 26W</th>
                            <th>FQ 26W</th>
                            <th>LR 26W</th>
                            <th>PR 52W</th>
                            <th>CL 52W</th>
                            <th>FQ 52W</th>
                            <th>LR 52W</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(d => {
                            if (d.year.exposureSumYears === 0) return;

                            const width = 80;
                            const padding = 3;
                            const meanCIPEY = meanBy(data.map(d => d.year).filter(d => d.CIPEY > 0), 'CIPEY');
                            const meanItemCount = meanBy(data.map(d => d.year).filter(d => d.itemCount > 0), 'itemCount');
                            const meanExposureSumYears = meanBy(data.map(d => d.year).filter(d => d.exposureSumYears > 0), 'exposureSumYears');

                            return (
                                <tr>
                                    <td style={{ padding }}>{tidyQuery(d.query, title)}</td>

                                    <td style={{
                                        backgroundImage: buildGradient({
                                            value: d.year.exposureSumYears,
                                            avg: meanExposureSumYears,
                                            dir: 'gray',
                                        }),
                                        padding,
                                        width
                                    }}>
                                        {numeral(d.year.exposureSumYears).format('0,0')}
                                    </td>

                                    <td style={{
                                        backgroundImage: buildGradient({
                                            value: d.year.CIPEY,
                                            avg: 200,
                                            dir: 'desc',
                                            full: true
                                        }),
                                        padding,
                                        width
                                    }}>
                                        £{numeral(d.year.CIPEY).format('0,0')}
                                    </td>

                                    {periodCells({
                                        d: d.quarter,
                                        key: 'quarter',
                                        data,
                                        padding,
                                        width,
                                    })}
                                    {periodCells({
                                        d: d.halfyear,
                                        key: 'halfyear',
                                        data,
                                        padding,
                                        width,
                                    })}
                                    {periodCells({
                                        d: d.year,
                                        key: 'year',
                                        data,
                                        padding,
                                        width,
                                    })}

                                </tr>
                            )
                        })}
                        <tr>
                            <td>Total</td>
                            <td>{sumBy(data.map(d => d.year), 'exposureSumYears')}</td> 
                            <td></td> 
                            <td></td> 
                            <td></td> 
                            <td></td> 
                            <td></td> 
                            <td></td> 
                            <td></td> 
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        );
    }
}

export default FactorsTable;

