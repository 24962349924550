import React from 'react';
import moment from 'moment';

function MLModelDetailsSection({
    model,
    edit_model,
    validate,
    is_uploading,
    upload_success,
    lang,
}) {
    return (
        <>
            <div className="input-group mb-2">
                <div className="input-group-prepend">
                    <span className="input-group-text" style={{ width: 145 }}>
                        {lang.t('rating.components.machineLearning.name')}
                    </span>
                </div>
                <input 
                    key={model.name}
                    type='text' 
                    className="form-control" 
                    placeholder={lang.t('rating.components.machineLearning.namePlaceholder')}
                    name="name"
                    defaultValue={model.name}
                    onBlur={validate}
                    disabled={is_uploading || upload_success}
                />
            </div>
            <div className="input-group mb-2">
                <div className="input-group-prepend">
                    <span className="input-group-text" style={{ width: 145 }}>
                        {lang.t('rating.components.machineLearning.description')}
                    </span>
                </div>
                <input 
                    key={model.description}
                    type='text' 
                    className="form-control" 
                    placeholder={lang.t('rating.components.machineLearning.descriptionPlaceholder')}
                    name="description" 
                    defaultValue={model.description} 
                    onBlur={validate}
                    disabled={is_uploading || upload_success}
                />
            </div>
            <div className="input-group mb-2">
                <div className="input-group-prepend">
                    <span className="input-group-text" style={{ width: 145 }}>
                        {lang.t('rating.components.machineLearning.modelLibrary')}
                    </span>
                </div>
                <select className="form-select"
                    key={model.library}
                    name="library" 
                    aria-label="Model type select" 
                    disabled={is_uploading || upload_success} 
                    onChange={validate}
                    defaultValue={model.library}
                >
                    <option selected>{lang.t('rating.components.machineLearning.selectModelLibrary')}</option>
                    <option value="xgboost">{lang.t('rating.components.machineLearning.xgBoost')}</option>
                </select>
            </div>
            {edit_model && (
                <>
                    <div className="input-group mb-2">
                        <div className="input-group-prepend">
                            <span className="input-group-text" style={{ width: 145 }}>
                                {lang.t('rating.components.machineLearning.mlModelRef')}
                            </span>
                        </div>
                        <input 
                            key={model.ml_model_reference}
                            type='text' 
                            className="form-control text-muted" 
                            name="ml_model_reference" 
                            defaultValue={model.ml_model_reference} 
                            disabled={edit_model}
                        />
                    </div>
                    <div className="input-group mb-2">
                        <div className="input-group-prepend">
                            <span className="input-group-text" style={{ width: 145 }}>
                                {lang.t('rating.components.machineLearning.fileType')}
                            </span>
                        </div>
                        <input 
                            key={model.file_type}
                            type='text' 
                            className="form-control text-muted" 
                            name="ml_model_reference" 
                            defaultValue={model.file_type} 
                            disabled={edit_model}
                        />
                    </div>
                    <div className="input-group mb-2">
                        <div className="input-group-prepend">
                            <span className="input-group-text" style={{ width: 145 }}>
                                {lang.t('rating.components.machineLearning.uploadDate')}
                            </span>
                        </div>
                        <input 
                            key={model.upload_date}
                            type='text' 
                            className="form-control text-muted" 
                            name="upload_date" 
                            defaultValue={moment(model.upload_date).format('DD MMMM YYYY, HH:mm:ss')} 
                            disabled={edit_model}
                        />
                    </div>
                </>
            )}
        </>
    );
}

export default MLModelDetailsSection;