import { reject, get } from 'lodash';

export function fail(error) {
    if (get(error, 'data')) {
        return {
            isFetching: false,
            error: {
                status: 500,
                message: 'Network Error',
            },
        }
    }
    if (get(error ,'response.status') === 401) {
        return {
            isFetching: false,
            error: {
                status: error.response.status,
                message: 'Not Authorised',
            },
        };
    }
    return {
        isFetching: false,
        error: {
            status: get(error ,'response.status', 400),
            ...get(error ,'response.data', {}),
        },
    };
}

export function fetch() {
    return {
        error: false,
        isFetching: true,
    };
}

export function successGetAll(payload) {
    return {
        error: false,
        isFetching: false,
        data: payload.data,
    };
}

export function successGetSome(payload, state) {
    return {
        error: false,
        isFetching: false,
        data: [
            ...payload.data,
            ...state.data,
        ],
    };
}

export function successGet(payload) {
    return {
        error: false,
        isFetching: false,
        selected: payload.data,
    };
}

export function successPost(payload, state) {
    return {
        error: false,
        isFetching: false,
        selected: payload.data,
        data: [
            payload.data,
            ...state.data || [],
        ],
    };
}

export function successPut(payload, state) {
    return {
        error: false,
        isFetching: false,
        selected: payload.data,
        data: [
            payload.data,
            ...reject(state.data, s => s.id === payload.data.id),
        ],
    };
}

export function successDelete(payload, state) {
    return {
        error: false,
        isFetching: false,
        selected: {},
        data: reject(state.data, s => s.id === payload.data.id),
    };
}

export default function base(types) {
    return function reducer(state = {}, action) {
        const { type, error, payload } = action;
        switch (type) {
        case types.getAll:
        case types.getSome:
        case types.get:
        case types.post:
        case types.put:
        case types.delete:
            return {
                ...state,
                ...fetch(),
            };
        case `${types.getAll}_SUCCESS`:
            return {
                ...state,
                ...successGetAll(payload),
            };
        case `${types.getSome}_SUCCESS`:
            return {
                ...state,
                ...successGetSome(payload, state),
            };
        case `${types.reset}`:
            return {
                error: false,
                isFetching: false,
                data: [],
            };
        case `${types.get}_SUCCESS`:
            return {
                ...state,
                ...successGet(payload),
            };
        case `${types.post}_SUCCESS`:
            return {
                ...state,
                ...successPost(payload, state),
            };
        case `${types.put}_SUCCESS`:
            return {
                ...state,
                ...successPut(payload, state),
            };
        case `${types.delete}_SUCCESS`:
            return {
                ...state,
                ...successDelete(payload, state),
            };
        case `${types.getAll}_FAIL`:
        case `${types.getSome}_FAIL`:
        case `${types.get}_FAIL`:
        case `${types.post}_FAIL`:
        case `${types.put}_FAIL`:
        case `${types.delete}_FAIL`:
            return {
                ...state,
                ...fail(error),
            };
        default:
            return state;
        }
    };
}
