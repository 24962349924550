import React from 'react';
import grid_alt from './grid_alt.svg';
import grid from './grid.svg';

function TinySpinner(props) {
    const { is_alt } = props;
    return (
        <img style={{
            height: 20,
            width: 20,
        }} src={is_alt ? grid_alt : grid} />
    );
}

export default TinySpinner;
