import React, { Component } from 'react';
import { min } from 'lodash';

class PaginationNoIndex extends Component {
    constructor(props) {
        super(props);
        this.arrowScroll = this.arrowScroll.bind(this);
    }

    async arrowScroll (direction) {
        const { 
            policy_reference = '',
            product_reference = '',
            term = '',
            page: curPage,
            update,
            fetchData
        } = this.props;
        let page;

        direction === 'next' ? page = curPage + 1 : page = curPage -1;
        
        await fetchData({
            policy_reference,
            product_reference,
            term,
            limit: 51,
            offset: (page - 1) * 50,
        });
        
        update({
            page,
        })
    }

    render() {
        const { data, page, lang } = this.props;
        const prevDisabled = page === 1;
        const nextDisabled = data.length < 51;

        return (
            <div className="card-footer d-flex align-items-center" style={{ borderBottom: '1px solid #ccc', padding: '0.625rem 1.25rem' }}>
                <p className="m-0 text-muted">
                    {lang.t('components.pagination.showing')} {((page - 1) * 50) + 1} {lang.t('components.pagination.to')} {min([page * 50, (page - 1) * 50 + data.length])}
                </p>
                <ul className="pagination m-0 ml-auto">
                    <li key='down-arrow' className='pagination-arrow'>
                        <button 
                            className={`page-link btn-outline-light ${prevDisabled ? 'text-muted' : 'cursor-pointer text-dark'}`}
                            disabled={prevDisabled}
                            onClick={(e) => {
                                e.preventDefault();
                                this.arrowScroll('prev');
                            }
                        }>
                            <span className='fa fa-angle-left pr-2'></span>{lang.t('components.pagination.prev50')} 
                        </button>
                    </li>
                    <li className="pt-1 pl-3 pr-3">
                        <p className='mb-0 text-dark'>{page}</p>
                    </li>
                    <li key='up-arrow' className='pagination-arrow'>
                        <button 
                            className={`page-link btn-outline-light ${nextDisabled ? 'text-muted' : 'cursor-pointer text-dark'}`}
                            disabled={nextDisabled}
                            onClick={(e) => {
                                e.preventDefault();
                                this.arrowScroll('next');
                            }
                        }>
                            {lang.t('components.pagination.next50')}  <span className='fa fa-angle-right pl-2'></span>
                        </button>
                    </li>
                </ul>
            </div>
        );
    }
}

export default PaginationNoIndex;
