import React from 'react';
import LoaderRaw from '../../../../../../components/loaders/grid.svg';

function SelectPriceMethod(props) {
    const { 
        close,
        back,
        callRatingEngine,
        error,
        isSubmitting,
        manualPriceUpdate,
        title,
        text,
        buttonOne,
        buttonTwo,
        isClose,
        lang,
    } = props;
    return (
        <div className="modal-content">
            <div className="modal-header theme-dark" >
                <h5 className="modal-title">{title}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
                    <i className="fe fe-x"></i>
                </button>
            </div>
            {isSubmitting && 
                <img 
                    style={{
                        marginTop: 85,
                        marginBottom: 85,
                        marginLeft: 'calc(50% - 15px)',
                        width: 35,
                    }}
                    src={LoaderRaw} 
                    alt="loader" 
                />}
            {!isSubmitting && (
                <>
                    <div className="modal-body">
                        {error &&
                            <div className='alert alert-danger mb-4 text-break' role="alert">
                                <i style={{ marginRight: 5 }} className="fe fe-alert-triangle mr-2"></i>
                                {error}
                            </div>
                        }
                        <div className='alert alert-info mb-4' role="alert">
                            <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                            {text}
                        </div>
                        <button className="btn btn-primary float-left ml-5 mt-3" onClick={manualPriceUpdate} disabled={isSubmitting}>{buttonOne}</button>
                        <button className="btn btn-primary float-right mr-5 mt-3" onClick={callRatingEngine} disabled={isSubmitting}>{buttonTwo}</button>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={isClose ? close : back}
                        >
                            {lang.t('buttons.back')}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}

export default SelectPriceMethod;