import React, { Component } from 'react';
import ModalFactorTable from './ModalFactorTable';
import ModalFactorChart from './ModalFactorChart';

import {
    each,
    omit,
} from 'lodash';

const buildTableData = (factor = {}) => {
    const { hashes = {}, dimensions = {} } = factor;
    const array = [];
    each(hashes, (h, k) => {
        array.push({
            factors: Object.keys(dimensions).join('::'),
            values: k,
            exclude: h.exclude,
            refer: h.refer,
            excess: h.excess,
            endorsement: h.endorsement,
            weight: h.weight,
        });
    });
    return array;
}

const buildChartData = (factor = {}) => {
    const { hashes = {}, dimensions = {} } = factor;
    const label_name = Object.keys(dimensions).join('::');
    const weights = [];
    const labels = [];
    each(hashes, (h, k) => {
        labels.push(k);
        weights.push(h.weight);
    });
    return {
        label_name,
        weights,
        labels,
    };
};

class ModalFactor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            factor: {},
        };
        this.update = this.update.bind(this);
        this.clear = this.clear.bind(this);
    }

    update(obj) {
        this.props.update({
            [this.props.name]: {
                ...this.props.factor,
                hashes: {
                    ...this.props.factor.hashes,
                    [obj.values]: omit(obj, ['factors', 'values']),
                }
            }
        });
    }

    clear() {
        this.setState({
            factor: {},
        });

        this.props.openModal({
            factors: {
                open: false,
                name: '',
            }
        })
    }

    render() {
        const { name, open, factor, version_reference, lang, status } = this.props;
        const chart = buildChartData(factor);
        const table = buildTableData(factor);

        return (
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{display: open ? 'block' : ''}} id="modal-factor" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{lang.t('rating.components.factors.factor')} {name} ({chart.label_name})</h5>
                            <button type="button" onClick={this.clear} className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fe fe-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="mt-3 mr-3 ml-3 mb-3">
                                            <ModalFactorChart 
                                                chart={chart}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <ModalFactorTable 
                                        data={table}
                                        pages={10}
                                        update={this.update}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {(status === 'draft') &&
                            <button 
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => {
                                    this.props.saveVersion({
                                        version_reference,
                                        reload: false,
                                    });
                                }}
                            >{lang.t('rating.components.factors.saveChanges')}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalFactor;