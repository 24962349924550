import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import { get } from 'lodash';

import main from '../../../../hoc/main';

// Core Design
import PageHeader from '../../../../components/layout/PageHeader';
import Search from '../../../../components/search/Simple';
import Empty from '../../../../components/empty/Results';

// Service Design
import Table from '../../components/Products/ProductsTable';

class Products extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount(){
        this.props.policys_products_get_all({});
    }

    render() {
        const { 
            isFetching = true,
            data = [],
            selected = {} 
        } = this.props.policys_products;

        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="page-pretitle">
                                    {lang.t('settings.title')}
                                </div>
                                <h2 className="page-title">
                                    {lang.t('settings.products.title')}
                                </h2>
                            </div>
                            <div className="col-auto ml-auto d-print-none">
                                    <button
                                        className="btn btn-primary disabled"
                                    >{lang.t('settings.products.create')}</button>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-sm-12 col-lg-12">
                            <div style={{marginTop: 10}} className="alert alert-info" role="alert">
                                {lang.t('settings.products.createInfo')} <b>{lang.t('settings.products.createInfoBold')}</b>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <div className="row">
                                <div className="col-lg-12">
                                    {!isFetching && data.length === 0 && <Empty label="products" lang={lang} />}
                                    {!isFetching && data.length > 0 &&
                                        <div className="row"> 
                                            <div className="col-lg-12">
                                                <Table 
                                                    data={data}
                                                    lang={lang}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default main(Products);