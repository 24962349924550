import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import { get, round, max, min, sortBy } from 'lodash';
import TransactionChart from './TransactionChart';
import TransactionsSearchTable from '../tables/TransactionsSearchTable';
import moment from 'moment'

class AccountsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: 0,
            accountId: ''
        }
        this.selectAccount = this.selectAccount.bind(this);
    }

    componentWillMount() {
        const { selected } = this.props;
        const accounts = get(selected, 'standardized.accs', [])
        this.setState({ accountId: accounts[0].accountId })
    }

    selectAccount(index) {
        this.setState({
            account: index,
        })
    }

    render() {
        
        const { selected, lang: language } = this.props;
        const { account } = this.state;
        const transactions = (get(selected, 'standardized.trans', []))
        const balance = get(selected, 'standardized.bals',[])
        const accounts = get(selected, 'standardized.accs', [])
        const tableData = sortBy(transactions, t => -moment(t.bookingDateTime));
        
        const lang = getLangSource(language);
        
        return (
            <div className="card">
                <div className="card-header">Accounts</div>
                <ul className="nav nav-tabs" data-toggle="tabs">
                    {accounts.map((a, i) => (
                        <li key={a.accountId} className="nav-item">
                                <a onClick={(e) => {
                                        e.preventDefault();
                                        this.selectAccount(i);
                                        this.setState({ accountId: a.accountId })
                                    }} className={`nav-link ${i === account ? 'active' : ''}`}
                                >
                                    {i === account && <div className="badge bg-blue">{i+1}. {a.accountSubType}</div>}
                                    {i !== account && <div>{i+1}. {a.accountSubType}</div> }
                                </a>
                            </li>
                        ))}
                    </ul>
                    { transactions.length >= 10 
                        && (
                            <div className='card-body'>
                                <TransactionChart 
                                        data={transactions
                                            .filter(t => t.accountId === this.state.accountId)
                                            .map(t => {
                                                return ({
                                                    amount: t.amount.amount,
                                                    date: t.bookingDateTime,
                                            })
                                        })}
                                        balance={balance
                                            .filter(b => b.accountId === this.state.accountId)
                                            .map(b => {
                                                    return ({
                                                        balance: b.amount.amount
                                                    })
                                            })
                                        }
                                    />
                            </div>
                        )}
                <div className="card-body">
                    <div className="tab-content">
                        {
                            <TransactionsSearchTable 
                                tableData={tableData
                                    .filter(t => t.accountId === this.state.accountId)
                                    .map(t => {
                                        return ({
                                            description: t.transactionInformation,
                                            amount: t.amount.amount,
                                            creditOrDebit: t.creditDebitIndicator,
                                            currency: t.amount.currency,
                                            date: t.bookingDateTime
                                        })
                                    })
                                
                                }
                                lang={lang}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountsTab;
