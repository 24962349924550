import React, { Component } from 'react';
import Input from './Input';
import SelectOnly from './SelectOnly';

class FormCredit extends Component {
    constructor(props) {
        super(props);
        this.add = this.add.bind(this);
    }

    async add() {
        const { credit, detail, policys_credits_post } = this.props;
        const { credit_code, credit_description, credit_amount } = credit;

        await policys_credits_post({
            data: {
                credit_code,
                credit_description,
                credit_amount: Number(credit_amount),
                policy_reference: detail.policy_reference,
            }
        })
        this.props.updateStage('add_credit_success');
    }

    render() {
        const { credit, can_submit, update, lang } = this.props;
        const { credit_code, credit_description, credit_amount } = credit;
        
        return(
            <>
                <div className="modal-body">
                    <SelectOnly
                        label={`${lang.t('policy.policies.code')} *`}
                        property='credit_code'
                        options={[
                            { label: 'Promotion', value: 'promotion' },
                            { label: 'Referer', value: 'referer' },
                            { label: 'Referee', value: 'referee' },
                            { label: 'Consession', value: 'consession' },
                            { label: 'Complaint', value: 'complaint' },
                            { label: 'Statement', value: 'statement' },
                            { label: 'Other', value: 'other' },
                        ]}
                        value={credit_code}
                        update={update}
                        placeholder='Select a code'
                    />
                    <Input 
                        label={`${lang.t('policy.policies.description')} *`}
                        property='credit_description'
                        value={credit_description}
                        update={update}
                        type='text'
                    />
                    <Input 
                        label={`${lang.t('policy.policies.amount')} *`}
                        property='credit_amount'
                        value={credit_amount}
                        update={update}
                        type='number'
                    />
                    <h5 style={{ marginTop: '10px' }}>* {lang.t('policy.policies.required')}</h5>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        onClick={this.add}
                        disabled={!can_submit}
                    >
                        {lang.t('buttons.add')}
                    </button>
                </div>
            </>        
        )
    }
}

export default FormCredit;
