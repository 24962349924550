import React, { Component } from 'react';
import S from 'string';
import { round, sumBy, orderBy } from 'lodash';

class BarTable extends Component {
    constructor(props) {
        super(props);
        this.key = `${S(props.title).slugify().s}-chart`;
    }

    render() {
        const { title, colour } = this.props;
        const { data = [] } = this.props;
        return (
            <div>
                {title &&
                    <div className="card-header">
                        <h3 className="card-title">{title}</h3>
                    </div>
                }
                <div id={this.key}>
                    <table className="table card-table table-vcenter">
                        <thead>
                            <tr>
                                <th>Label</th>
                                <th colSpan="2">Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderBy(data, 'value', 'desc').map(d => {
                                const perc = d.value === 0 ? 0 : round(d.value/sumBy(data, 'value') * 100, 1);
                                return (
                                    <tr>
                                        <td>{d.label}</td>
                                        <td>{d.value} ({perc}%)</td>
                                        <td className="w-50">
                                            <div className="progress progress-xs">
                                                <div className={`progress-bar bg-${colour}`} style={{width: `${perc}%`}}></div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default BarTable;

