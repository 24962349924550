import React, { Component } from 'react';
import Empty from '../../../../components/empty/Results';
import Table from './Table';
import ModalAddCredit from '../Detail/Modal/ModalAddCredit';
import { getNumberFormat } from '../../../../utils/numberFormat';
import { sum } from 'lodash';

class CreditsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_open: false,
        }
        this.setState = this.setState.bind(this);
        this.open = this.open.bind(this);
    }

    open() {
        this.setState({
            modal_open: true,
        })
    }

    render() {
        const { credits: data, currency, lang } = this.props;
        const credits = data.map(obj => obj.credit_amount * 100);
        const totalCredit = sum(credits)/100;

        return (
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <div className="row">
                        <div className="col-lg-12">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ paddingLeft: '10px', paddingTop: '6px' }}>
                                    { data.length !== 0 && <p 
                                        style={{ fontSize: '15px', fontWeight: 'bold', marginBottom: '0px' }}
                                        >{`${lang.t('policy.policies.total')} : ${getNumberFormat(currency, totalCredit, '$0,0.00')}`}</p>
                                    }
                                </div>
                                <button 
                                    className="btn btn-primary btn-sm"
                                    onClick={this.open}
                                    style={{ marginBottom: '10px' }}
                                >
                                    + {lang.t('policy.policies.addCredit')}
                                </button>
                            </div>
                            {data.length === 0 && <Empty label={lang.t('policy.policies.credits').toLowerCase()} lang={lang} />}
                            {data.length > 0 &&
                                <div className="row"> 
                                    <div className="col-lg-12">
                                        <Table 
                                            data={data}
                                            currency={currency}
                                            lang={lang}
                                        />
                                    </div>
                                </div>
                            }
                            <ModalAddCredit
                                open={this.state.modal_open}
                                set_modal_open={this.setState}
                                detail={this.props.policy_detail}
                                policys_credits_post={this.props.policys_credits_post}
                                lang={lang}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreditsContainer;
