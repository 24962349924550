import React, { Component } from 'react';
import { get } from 'lodash';

import main from '../../../../hoc/main';

//Core Design
import LoaderBar from '../../../../components/loaders/Bar';

import PolicyCard from '../../components/cards/Policy';
import PolicySelectedCard from '../../components/cards/PolicySelected'
import ListFilter from '../../components/filter/ListFilter';

class View extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filtered: [],
        };
        this.setFiltered = this.setFiltered.bind(this);
        this.getDetail = this.getDetail.bind(this);
    }

    componentDidMount() {
        const data = get(this.props, 'claims_policys.data', []);
        if (data.length === 0) this.props.claims_policys_get_all({});
    }

    setFiltered(result) {
        this.setState({
            filtered: result
        });
    }

    getDetail(id) {
        this.props.claims_policys_get({
            id,
        });
    }

    render() {
        const policys = get(this.props, 'claims_policys.data', []);
        const policySelected = get(this.props, 'claims_policys.selected');
        const filteredPolicys = this.state.filtered;

        const data = filteredPolicys.length > 0 ? filteredPolicys : policys;
        
        return (
            <div class="my-3 my-md-5">

                {data.length === 0 && <LoaderBar />}

                {data.length > 0 &&
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 col-lg-8">
                                
                            </div>
                            <div class="col-sm-12 col-lg-4">
                                <div class="row">
                                    <div class="mb-3">
                                        <ListFilter 
                                            data={policys}
                                            setFiltered={this.setFiltered}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-8">
                            <PolicySelectedCard 
                                policySelected={policySelected}
                            />
                            </div>
                            <div class="col-lg-4">
                                {data.slice(0, 10).map(d => {
                                    return (<PolicyCard
                                        policy={d} 
                                        getDetail={this.getDetail}
                                    />)
                                })}
                            </div>
                        </div>  
                    </div>
                }
            </div>
        );
    }
}

export default main(View);