import React, { Component } from 'react';

// Core Design
import Empty from '../../../../components/empty/Results';

// Service Design
import Table from './Table';

class JourneyContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { journeys, lang } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <div className="row">
                        <div className="col-lg-12">
                            {journeys.length === 0 && <Empty label={lang.t('nav.journeys').toLowerCase()} lang={lang} />}
                            {journeys.length > 0 &&
                                <div className="row"> 
                                    <div className="col-lg-12">
                                        <Table 
                                            { ...this.props }
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default JourneyContainer;