import React, { Component } from 'react';
import { getLangSource } from '../../../lang/index';
import getData from '../../../utils/getData';
import main from '../../../hoc/main';

// Core Design
import SpinnerBody from '../../../components/loaders/SpinnerBody';
import Search from '../../../components/search/Simple';
import Empty from '../../../components/empty/Results';
import PageHeader from '../../../components/layout/PageHeader';

// Service Design
import UsersTable from '../components/tables/Users';
import UsersForm from '../components/forms/Users';

import { orderBy } from 'lodash';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            showAddUser: false,
            allUsers: [],
            users: [],
        }
        this.search = this.search.bind(this);
        this.showAddUser = this.showAddUser.bind(this);
    }

    async componentDidMount() {
        const { data } = getData(this.props.users);
        let allUsers;

        if (data.length) {
            allUsers = data;
        }

        if (!data.length) {
            const res = await this.props.settings_users_get_all({});
            allUsers = res.payload.data;
        }

        this.setState({
            ...this.state,
            allUsers,
            users: allUsers,
        })
    }

    search(str) {
        this.setState({
            searchTerm: str,
        }, async () => {
            // Debounce searches
            if (str.length > 2) {
                // TODO: impletented frontend solution as temp fix until a backend solutin is implemented

                // this.props.settings_users_get_all({
                //     search: str,
                //     limit: 1,
                // });
                const {allUsers} = this.state;
                const filteredUsers = allUsers.filter(user => (
                    user.username.includes(str) ||
                    user.f_names.includes(str) ||
                    user.l_names.includes(str) ||
                    user.email.includes(str) 
                ));

                this.setState({
                    ...this.state,
                    users: filteredUsers,
                })
            }
            if (str === '') {
                const res = await this.props.settings_users_get_all({});
                const allUsers = res?.payload.data;

                this.setState({
                    ...this.state,
                    users: allUsers,
                })
            }
        });
    }

    showAddUser() {
        this.setState({
            showAddUser: !this.state.showAddUser,
        })
    }
    
    render() {
        const lang = getLangSource(this.props.lang);
        const { isFetching, selected, data } = getData(this.props.settings_users);
        const { data:authorisedUser } = getData(this.props.auth);
        const { showAddUser, allUsers, users } = this.state;
        const adminUsers = users.filter( user => user.role === 'admin');
        const orderedUsers = orderBy(adminUsers, 'created_at', 'asc');

        return (
            <div className="my-3 my-md-5">
                <div className="container">
                {isFetching && 
                    <div className="row">
                        <SpinnerBody
                            message={lang.t('settings.users.gettingUsers')}
                            selectedProduct={this.props.selectedProduct}
                        />
                    </div>
                }

                {!isFetching &&
                    <PageHeader
                        section={lang.t('settings.title')}
                        page={lang.t('settings.users.title')}
                    />
                }

                {!isFetching &&
                <div className="row">
                    <div className="col-sm-12 col-lg-8" />
                        <div className="col-sm-12 col-lg-8">
                            <a className="btn btn-primary text-white" onClick={(e) => {
                                e.preventDefault();
                                this.showAddUser();
                            }}>{lang.t('settings.users.addUser')}</a>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="row">
                                <div className="mb-3">
                                    <Search
                                        searchTerm={this.state.searchTerm}
                                        searchFunction={this.search}
                                        label={lang.t('settings.users.users')}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {!isFetching &&
                    <div className="row">

                        {showAddUser === true && 
                            <UsersForm 
                                lang={lang}
                                users_post={this.props.settings_users_post}
                                close={this.showAddUser}
                            />
                        }
                        
                        {/* Empty State */}
                        {!isFetching && (allUsers.length === 0 || users.length === 0) && 
                            <div className="col-sm-12 col-lg-8">
                                <Empty label={lang.t('settings.users.users')} lang={lang} />
                            </div>
                        }

                        {/* Table */}
                        {!isFetching && allUsers.length > 0 && users.length > 0 && 
                            <div className="col-sm-12 col-lg-12">
                                <UsersTable
                                    data={orderedUsers}
                                    pages={10}
                                    users_put={this.props.settings_users_put}
                                    auth_get_all={this.props.auth_get_all}
                                    authorisedUser={authorisedUser}
                                    lang={lang}
                                />
                            </div>
                        }
                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default main(Users);