import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class TrafficChart extends Component {
    constructor(props) {
        super(props);
        this.updateChart = this.updateChart.bind(this);
    }

    componentWillMount() {
        this.updateChart();
    }

    updateChart() {
        const {
            series,
            labels,
            format,
            annotations = [],
        } = this.props;
        let chart = {
            series,
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    opacity: 1,
                },
                grid: {
                    padding: {
                        top: -20,
                        right: 0,
                        left: -4,
                        bottom: 0
                    },
                    strokeDashArray: 4,
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                },
                xaxis: {
                    labels: {
                        padding: 0,
                        format,
                    },
                    axisBorder: {
                        show: true,
                    },
                    type: 'datetime',
                },
                yaxis: {
                    labels: {
                        padding: 4
                    },
                },
                labels: labels,
                colors: ["#206bc4", "#79a6dc", "#bfe399"],
                legend: {
                    show: false,
                },
                tooltip: {
                    x: {
                        format,
                    }
                }
            }
        };

        if (annotations.length > 0) {
            chart.options.annotations = {
                xaxis: annotations.map((a = {}) => {
                    return {
                        x: a.timestamp,
                        strokeDashArray: 0,
                        borderColor: '#5eba00',
                        label: {
                            borderColor: '#5eba00',
                            style: {
                                color: '#fff',
                                background: '#5eba00',
                            },
                            text: a.label,
                        }
                    }
                })
            }
        }

        this.setState(chart);
    }

    render() {
        return(
            <Chart
                options={this.state.options}
                series={this.state.series}
                type="line"
                height={this.props.height || 280}
            />
        );
    }
}

export default TrafficChart;