import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import main from '../../../../hoc/main';
import moment from 'moment';
import { chartDateFormat } from '../../../../utils/format';

// Core Design
import SpinnerBody from '../../../../components/loaders/SpinnerBody';
import DropDown from '../../../../components/forms/Dropdown';

// Service Design
import ActivityTable from '../../components/Activity/ActivityTable';
import TrafficChart from '../../components/Analytics/TrafficChart';
import StatChart from '../../components/Analytics/StatChart';

class ActivityDash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            range: 'hour',
        }
        this.selectRange = this.selectRange.bind(this);
        this.getData = this.getData.bind(this);
    }

    async componentWillMount() {
        await this.getData();
        await this.props.policys_user_logs_get_all({}) 
    }

    getData() {
        return this.props.policys_analytics_get({
            id: 'users',
            period: this.state.range,
        });
    }

    selectRange ({ range = '' }) {
        this.setState({
            range: range.toLowerCase(),
        }, () => {
            this.getData();
        })
    }

    render() {
        const {
            isFetching:isFetchingAnalytics = true,
            selected:analytics = {},
        } = this.props.policys_analytics;

        const {
            isFetching:isFetchingUserLogs = true,
            data:user_logs = [],
        } = this.props.policys_user_logs;

        const isFetching = (isFetchingUserLogs && isFetchingAnalytics);
        
        const { range = '' } = this.state;
        const { segments = [], total = {} } = analytics

        const chart_format = chartDateFormat(range);
        const lang = getLangSource(this.props.lang);

        return (
            <div className="my-3 my-md-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-sm-12 col-lg-6">
                            <div className="page-pretitle">{lang.t('nav.activity')}</div>
                            <h2 className="page-title">{lang.t('nav.dashboard')}</h2>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            <div className="form-label">{lang.t('policy.policies.range')}</div>
                            <DropDown
                                ref='range'
                                label='range'
                                value={range}
                                update={(range) => {
                                    this.selectRange(range);
                                }}
                                items={['hour', 'day', 'week', 'month']}
                                labelMap={{
                                    'hour': lang.t('time.hour'),
                                    'day': lang.t('time.day'),
                                    'week': lang.t('time.week'), 
                                    'month': lang.t('time.month'),
                                }}
                            />
                        </div>
                    </div>
                    
                    {/* Loader */}
                    {isFetching && <SpinnerBody />}

                    {!isFetching && segments.length > 0 &&
                        <div className="row">
                            <div className="col-sm-9 col-lg-9">
                                <div className="card">
                                    <div className="ml-3 mr-3 mt-3">
                                        <TrafficChart 
                                            series={[
                                                {
                                                    name: lang.t('policy.activity.users'),
                                                    data: segments.map(d => d.users || 0),
                                                },
                                                {
                                                    name: lang.t('policy.activity.requests'),
                                                    data: segments.map(d => d.requests || 0),
                                                },
                                            ]}
                                            annotations={[]}
                                            labels={segments.map(t => {
                                                return moment(t.timestamp, 'X').toISOString();
                                            })}
                                            format={chart_format}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-lg-3">
                                <StatChart 
                                    series={[
                                        {
                                            name: lang.t('policy.activity.users'),
                                            data: segments.map(d => d.users || 0),
                                        },
                                    ]}
                                    name={lang.t('policy.activity.users')}
                                    labels={segments.map(t => {
                                        return moment(t.timestamp, 'X').toISOString();
                                    })}
                                    format={chart_format}
                                />
                                <StatChart 
                                    series={[
                                        {
                                            name: lang.t('policy.activity.requests'),
                                            data: segments.map(d => d.requests || 0),
                                        },
                                    ]}
                                    name={lang.t('policy.activity.requests')}
                                    labels={segments.map(t => {
                                        return moment(t.timestamp, 'X').toISOString();
                                    })}
                                    format={chart_format}
                                />
                            </div>
                        </div>
                    }

                    {!isFetching &&
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <ActivityTable 
                                    data={user_logs}
                                    lang={lang}
                                />
                            </div>
                        </div>
                    }
                 
              </div>
          </div>
        );
    }
}

export default main(ActivityDash);