import React, { Component } from 'react';
import Pagination from '../../../../components/tables/Pagination';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { random } from 'lodash';

function toUpper(str = '') {
    return str?.toUpperCase();
}

class TestsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        }
        this.selectPage = this.selectPage.bind(this);
    }

    selectPage(page) {
        this.setState({
            page,
        });
    }

    render() {
        const { data = [], pages, lang } = this.props;
        const { page } = this.state;
        const no_of_pages = (data.length === 0 ? 0 : Math.ceil(data.length / pages));

        let pageData = data;
        if (pages !== 0) pageData = data.slice((page * pages) - pages, (page * pages));
        return (
            <div className="card">
                <Pagination
                    page={page}
                    pages={pages}
                    no_of_pages={no_of_pages}
                    data={data}
                    selectPage={this.selectPage}
                    lang={lang}
                />
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>  
                            <tr>
                                <th className="w-1">{lang.t('tableHeaders.scenarios')}</th>
                                <th>{lang.t('tableHeaders.name')}</th>
                                <th>{lang.t('tableHeaders.date')}</th>
                                <th className="w-1"></th>
                                <th className="w-1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {pageData.map((d, i) => (
                                <tr key={`tests-${i}`}>
                                    <td data-label={lang.t('tableHeaders.value')}>  
                                        <div className="text-h5"><div className='badge bg-blue'>{random(100, 499)}</div></div>
                                    </td>
                                    <td data-label={lang.t('tableHeaders.name')}>
                                        <div className="d-flex lh-sm py-1 align-items-center">
                                            <div className="flex-fill">
                                                <div className="strong">{toUpper(d.name)}</div>
                                                <div className="text-muted text-h5">{d.description}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-label={lang.t('tableHeaders.date')}>
                                        <div className="text-muted text-h5">{moment(d.date).format('DD/MM/YY - HH:ss')}</div>
                                    </td>
                                    <td>
                                        <Link
                                            to={`/rating/tests/${d.id}`}
                                            className="btn btn-primary btn-sm"
                                        >{lang.t('buttons.edit')}</Link>
                                    </td>
                                    <td>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            this.props.deleteTest(d.id);
                                        }} className="btn btn-red btn-sm" data-toggle="modal" data-target="#modal-edit-tests">
                                            {lang.t('buttons.delete')}
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    page={page}
                    pages={pages}
                    no_of_pages={no_of_pages}
                    data={data}
                    selectPage={this.selectPage}
                    lang={lang}
                />
            </div>
        );
    }
}

export default TestsTable;
