import React from 'react';
import styled from 'styled-components';

const navItemShared = `border: 0;
    border-radius: 3px;
    cursor: pointer !important;
    align-items: center !important;
    display: flex !important;
    position: relative;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
`;

const PolicyNavItemStyle = styled.div`
    .nav-item {
        background: 0 0;
        width: 100%;
        text-align: inherit;
        ${navItemShared}
    }
    .nav-item-active {
        font-weight: 600;
        color: inherit;
        background: rgba(32, 107, 196, 0.06);
        z-index: 2;
        text-decoration: none;
        ${navItemShared}
    }
`;

const PolicyNavItem = (props) => {
    const { item, active, page } = props;
    const { name, section } = item;

    return (
        <PolicyNavItemStyle>
            <a
                onClick={() => {
                    page(section);
                }}
                className={`${section === active ? 'nav-item-active' : 'nav-item'}`}
            >
                {name}
            </a>
        </PolicyNavItemStyle>
    );
};

export default PolicyNavItem;
