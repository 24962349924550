import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import db from '../../db';
import moment from 'moment';
import { round } from 'lodash';
import {
    defaultRisks,
    defaultCohorts,
    riskCombos,
    cohortCombos,
} from '../../workers/utils/config';

import main from '../../../../hoc/main';
import PageHeader from '../../../../components/layout/PageHeader';

import RisksWorker from '!../../workers/risks'; // eslint-disable-line import/no-webpack-loader-syntax
import CohortsWorker from '!../../workers/cohorts'; // eslint-disable-line import/no-webpack-loader-syntax
import AnalysisWorker from '!../../workers/analysis'; // eslint-disable-line import/no-webpack-loader-syntax

let riskCache = [];

class Settings extends Component {
    constructor(props) {
        super(props);

        // Policys
        this.processFile = this.processFile.bind(this);
        
        //  Risk
        this.riskFactor = this.riskFactor.bind(this);
        this.processRisks = this.processRisks.bind(this);

        // Cohorts
        this.cohortFactor = this.cohortFactor.bind(this);
        this.processCohorts = this.processCohorts.bind(this);

        // Analysis
        this.processAnalysis = this.processAnalysis.bind(this);

        // Progress
        this.renderProgress = this.renderProgress.bind(this);

        this.state = {
            processMsg: null,
            downloadFile: `${moment().format('DDMMYYYY')}-analysis.json`,
            downloadData: '',

            // Policys
            uploading: false,

            //Risk
            riskCount: 0,
            riskIsDone: false,
            riskFactors: defaultRisks,
            riskCombos: 0,

            //Cohorts
            cohortCount: 0,
            cohortIsDone: false,
            cohortFactors: defaultCohorts,
            cohortCombos: 0,

            //Analysis
            analysisCount:0,
            analysisIsDone: false,
        }
    }

    componentWillMount() {
        const risks = {};
        Object.keys(defaultRisks).map(k => {
            risks[k] = {
                ...defaultRisks[k],
                active: true,
            };
        });
        this.setState({
            riskFactors: risks,
            riskCombos: (riskCombos(risks) || []).length,
        });
    }

    processFile([file]) {
        const reader = new FileReader()
        this.setState({ uploading: true });
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
            const binaryStr = reader.result;
            this.setState({ uploading: false, uploadCount: 0 });
            // Or process Policys to DB
            this.processRisks(binaryStr.toString())
        }
        reader.readAsText(file);
    }

    // Risk

    riskFactor(factor) {
        const { riskFactors } = this.state;
        const risks = {
            ...riskFactors,
            [factor]: {
                ...riskFactors[factor],
                active: !riskFactors[factor].active,
            }
        };
        this.setState({
            riskFactors: risks,
            riskCombos: (riskCombos(risks) || []).length,
        });
    }

    processRisks(data) {
        const worker = RisksWorker();
        const { riskFactors, cohortFactors } = this.state;
        let count = 0;
        worker.addEventListener('message', ({ data }) => {
            if (data.version) riskCache.push(data);
            if (data.isDone) {
                // this.props.addRisks(riskCache);
                this.setState({
                    processMsg: 'DONE',
                    riskIsDone: true,
                    cohortCombos: cohortCombos(riskFactors, this.props.risks, cohortFactors),
                });
                worker.terminate();
                // this.processCohorts();
            } else {
                count++;
                this.setState({
                    riskCount: count,
                });
                if (count % 100 === 0) {
                    this.setState({
                        processMsg: `${data.version}`,
                    });
                }
            }
        });
        worker.build(riskFactors, data);
    }

    // Cohorts
    cohortFactor(value, factor) {
        const { cohortFactors } = this.state;
        this.setState({
            cohortFactors: {
                ...cohortFactors,
                [factor]: round(value, 2)
            }
        })
    }

    processCohorts() {
        const worker = CohortsWorker();
        const { cohortFactors, riskFactors } = this.state;
        const risks = db.get('risks').value();
        let count = 0;
        worker.addEventListener('message', ({ data }) => {
            if (data.isDone) {
                this.setState({
                    processMsg: 'DONE',
                    cohortIsDone: true,
                });
                worker.terminate();
                this.processAnalysis();
            } else {
                if (data.cohortArr) {
                    count = count + data.cohortCount;
                    data.cohortArr.forEach(c => db.get('cohorts').push(c).write());
                    this.setState({
                        cohortCount: count,
                        processMsg: `${data.iterables} - ${data.currentCount} / ${data.totalCount}`,
                    });
                }
            }
        });
        worker.build(cohortFactors, riskFactors, risks);
    }

    processAnalysis() {
        const worker = AnalysisWorker();
        const { cohortFactors, riskFactors } = this.state;
        const cohorts = db.get('cohorts').value();
        const risks = db.get('risks').value();
        worker.addEventListener('message', ({ data }) => {
            if (data.charts) {
                db
                    .get('analysis')
                    .assign(data)
                    .write();
                this.setState({
                    processMsg: 'DONE',
                    analysisIsDone: true,
                    analysisCount: 1,
                    analysis: data,
                    // Build download file
                    downloadData: `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify({
                        risks: db.get('risks').value(),
                        analysis:  db.get('analysis').value()
                    }))}`
                });
                worker.terminate();
            };
        });
        worker.build({
            risks,
            cohorts,
            selected_cohorts: cohortFactors,
            selected_risks: riskFactors,
        });
    }

    // Progress
    renderProgress({
        title,
        count,
        isStarted,
        isDone,
        isWhile,
        estimate,
    }) {
        return (
            <tr>
                <td>{title}</td>
                <td>{count}</td>
                <td class="w-50">
                    {isStarted && !isDone &&
                        <div class="text-green spinner-border spinner-border-sm" role="status"></div>
                    }
                    {isStarted && !isDone && isWhile && <span style={{ marginLeft: 10 }} class="badge bg-green-lt">This will take a while. (~{estimate})</span> }
                    {isDone &&
                        <div class="progress progress-xs">
                            <div class="progress-bar bg-green" style={{width: isDone ? '100%' : 0}}></div>
                        </div>
                    }
                </td>
            </tr>
        )
    }
    

    render() {
        const {
            downloadFile,
            downloadData,
            //Policys
            uploading,
            processMsg,
            //Risk
            riskFactors,
            riskCount,
            riskIsDone,
            riskCombos,
            //Cohorts
            cohortCount,
            cohortIsDone,
            cohortFactors,
            cohortCombos,
            //Analysis
            analysisCount,
            analysisIsDone,
            analysis
        } = this.state;

        const { risks } = this.props;
        return (
            <div class="my-3 my-md-5">
                <div class="container">
                    <PageHeader
                        section={'Claims'}
                        page={'Create Reports'}
                    />
                    <div class="row">
                        <div class="col-lg-6">

                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Select Risk Factors <span style={{ marginLeft: 10 }} class="badge bg-blue">{riskCombos} Combos</span></h3>
                                </div>
                                <div class="card-body">
                                    <div class="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} class="fe fe-info"></i>
                                        The more risk factors, the bigger but slower the analysis
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="hr-text">Policy</div>
                                            {Object.keys(riskFactors).filter(k => riskFactors[k].category === 'policy').map(r => {
                                                const risk = defaultRisks[r];
                                                return (
                                                    <label key={r} class="form-check form-switch">
                                                        <input class="form-check-input" onClick={() => this.riskFactor(r)} type="checkbox" checked={riskFactors[r].active} />
                                                        <span class="form-check-label">{risk.desc}</span>
                                                        <span style={{ marginLeft: 10 }} class="badge bg-blue-lt">{riskFactors[r].type}</span>
                                                    </label>
                                                )
                                            })}
                                            <div class="hr-text">Drivers</div>
                                            {Object.keys(riskFactors).filter(k => riskFactors[k].category === 'drivers').map(r => {
                                                const risk = defaultRisks[r];
                                                return (
                                                    <label key={r} class="form-check form-switch">
                                                        <input class="form-check-input" onClick={() => this.riskFactor(r)} type="checkbox" checked={riskFactors[r].active} />
                                                        <span class="form-check-label">{risk.desc}</span>
                                                        <span style={{ marginLeft: 10 }} class="badge bg-blue-lt">{riskFactors[r].type}</span>
                                                    </label>
                                                )
                                            })}
                                            <div class="hr-text">Vehicle</div>
                                            {Object.keys(riskFactors).filter(k => riskFactors[k].category === 'vehicle').map(r => {
                                                const risk = defaultRisks[r];
                                                return (
                                                    <label key={r} class="form-check form-switch">
                                                        <input class="form-check-input" onClick={() => this.riskFactor(r)} type="checkbox" checked={riskFactors[r].active} />
                                                        <span class="form-check-label">{risk.desc}</span>
                                                        <span style={{ marginLeft: 10 }} class="badge bg-blue-lt">{riskFactors[r].type}</span>
                                                    </label>
                                                )
                                            })}
                                            <div class="hr-text">Marketing</div>
                                            {Object.keys(riskFactors).filter(k => riskFactors[k].category === 'marketing').map(r => {
                                                const risk = defaultRisks[r];
                                                return (
                                                    <label key={r} class="form-check form-switch">
                                                        <input class="form-check-input" onClick={() => this.riskFactor(r)} type="checkbox" checked={riskFactors[r].active} />
                                                        <span class="form-check-label">{risk.desc}</span>
                                                        <span style={{ marginLeft: 10 }} class="badge bg-blue-lt">{riskFactors[r].type}</span>
                                                    </label>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">
                                        Select Cohort Factors
                                    </h3>
                                </div>
                                <div class="card-body">
                                    <div class="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} class="fe fe-info"></i>
                                        These factors determine how significant a cohort is deemed to be
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-11">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" style={{ width: 140 }}>
                                                        Confidence (%)
                                                    </span>
                                                </div>
                                                <input onBlur={(e) => { this.cohortFactor(e.target.value, 'confidence') }} step="1" type="number" class="form-control" placeholder="95" defaultValue={cohortFactors.confidence}/>
                                            </div>
                                        </div>
                                        <div class="col-lg-1 text-blue">
                                            <i style={{ lineHeight: '35px' }} class="fe fe-info" data-toggle="tooltip" data-placement="right" title="Tooltip on right"></i>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-11">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" style={{ width: 140 }}>
                                                        Step Factor (%)
                                                    </span>
                                                </div>
                                                <input onBlur={(e) => { this.cohortFactor(e.target.value, 'step_factor') }} step="1" type="number" class="form-control" placeholder="10" defaultValue={cohortFactors.step_factor}/>
                                            </div>
                                        </div>
                                        <div class="col-lg-1 text-blue">
                                            <i style={{ lineHeight: '35px' }} class="fe fe-info" data-toggle="tooltip" data-placement="right" title="Tooltip on right"></i>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-11">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" style={{ width: 140 }}>
                                                        Ave. Freq. (%)
                                                    </span>
                                                </div>
                                                <input onBlur={(e) => { this.cohortFactor(e.target.value, 'ave_freq') }} step="0.1" type="number" class="form-control" placeholder="5.5" defaultValue={cohortFactors.ave_freq}/>
                                            </div>
                                        </div>
                                        <div class="col-lg-1 text-blue">
                                            <i style={{ lineHeight: '35px' }} class="fe fe-info" data-toggle="tooltip" data-placement="right" title="Tooltip on right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">
                                        Select Analysis Factors
                                    </h3>
                                    <div class="card-options">
                                        <a href="#" onClick={this.processAnalysis} class="btn btn-primary btn-sm">Re-Run</a>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} class="fe fe-info"></i>
                                        These factors selected the best and worst performing policies
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <label class="form-label">Best Cohort (less than)</label>
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" style={{ width: 140 }}>
                                                        Claims Freq. (%)
                                                    </span>
                                                </div>
                                                <input onBlur={(e) => { this.cohortFactor(e.target.value, 'min_freq') }} step="0.1" type="number" class="form-control" placeholder="4" defaultValue={cohortFactors.min_freq}/>
                                            </div>
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" style={{ width: 140 }}>
                                                        Loss Ratio (%)
                                                    </span>
                                                </div>
                                                <input onBlur={(e) => { this.cohortFactor(e.target.value, 'min_lr') }} step="0.1" type="number" class="form-control" placeholder="5" defaultValue={cohortFactors.min_lr}/>
                                            </div>
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" style={{ width: 140 }}>
                                                        Exposure (years)
                                                    </span>
                                                </div>
                                                <input onBlur={(e) => { this.cohortFactor(e.target.value, 'min_exposure') }} step="1" type="number" class="form-control" placeholder="1000" defaultValue={cohortFactors.min_exposure}/>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <label class="form-label">Worst Cohort (greater than)</label>
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" style={{ width: 140 }}>
                                                        Claims Freq. (%)
                                                    </span>
                                                </div>
                                                <input onBlur={(e) => { this.cohortFactor(e.target.value, 'max_freq') }} step="0.1" type="number" class="form-control" placeholder="8" defaultValue={cohortFactors.max_freq}/>
                                            </div>
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" style={{ width: 140 }}>
                                                        Loss Ratio (%)
                                                    </span>
                                                </div>
                                                <input onBlur={(e) => { this.cohortFactor(e.target.value, 'max_lr') }} step="0.1" type="number" class="form-control" placeholder="100" defaultValue={cohortFactors.max_lr}/>
                                            </div>
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" style={{ width: 140 }}>
                                                        Exposure (years)
                                                    </span>
                                                </div>
                                                <input onBlur={(e) => { this.cohortFactor(e.target.value, 'max_exposure') }} step="1" type="number" class="form-control" placeholder="1000" defaultValue={cohortFactors.max_exposure}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <Dropzone onDrop={acceptedFiles => this.processFile(acceptedFiles)}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <div class="card">
                                            <div class="card-status-left bg-green"></div>
                                            <div class="card-body">
                                                <h3 class="card-title">Drop Versions (.csv) here to upload</h3>
                                                <div style={{ width: '100%', textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                                                    {!uploading && <i style={{ fontSize: 70, opacity: 0.6 }} class="fe fe-share"></i>}
                                                    {uploading &&
                                                        <div style={{ width: 70, height: 70 }} class="spinner-border text-green spinner-border-sm" role="status"></div>
                                                    }
                                                    <input {...getInputProps()} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </Dropzone>
                            
                            {processMsg &&
                                <div class="card">
                                    <div class="card-status-left bg-green"></div>
                                    <div class="card-body bg-green-lt">
                                        <p><b>PROGRESS:</b> {processMsg}</p>
                                    </div>
                                </div>
                            }

                            <div class="card">
                                <div class="card-status-left bg-green"></div>
                                <div class="card-header">
                                    <h4 class="card-title">Processing</h4>
                                </div>
                                <table class="table card-table table-vcenter">
                                    <thead>
                                        <tr>
                                            <th>Task</th>
                                            <th colspan="2">Progress</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderProgress({
                                            title: 'Risk',
                                            count: riskCount,
                                            isStarted: riskCount > 0,
                                            isDone: riskIsDone,
                                            isWhile: false,
                                        })}
                                        {this.renderProgress({
                                            title: 'Cohorts',
                                            count: cohortCount,
                                            isStarted: cohortCount > 0,
                                            isDone: cohortIsDone,
                                            isWhile: true,
                                            estimate: cohortCombos,
                                        })}
                                        {this.renderProgress({
                                            title: 'Analysis',
                                            count: analysisCount,
                                            isStarted: analysisCount > 0,
                                            isDone: analysisIsDone,
                                            isWhile: false,
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {analysisIsDone && 
                                <p>
                                    <span class="badge bg-green-lt"><b>ALL</b> : {analysis.all.risks.length}</span>
                                    <span class="badge bg-blue-lt" style={{ marginLeft: 10 }}><b>BEST</b> : {analysis.best.risks.length}</span>
                                    <span class="badge bg-pink-lt" style={{ marginLeft: 10 }}><b>WORST</b> : {analysis.worst.risks.length}</span>
                                </p>
                            }
                            {analysisIsDone &&
                                <div class="card">
                                    <div class="card-body">
                                        <label class="form-label">Download Analysis ({round(downloadData.length / 1000, 0)}KB)</label>
                                        <div class="input-group mb-2">
                                            <input type="text" class="form-control" defaultValue={downloadFile} />
                                            <span class="input-group-append">
                                                <a class="btn btn-green" href={downloadData} download={downloadFile} type="button">
                                                    <b><i style={{margin: 5}} class="text-white fe fe-download"></i></b>
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default main(Settings);