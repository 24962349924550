import React, { Component } from 'react';
import SuperTable from '../../../../../../components/tables/Super';

class ModalLinkedTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], lang } = this.props;

        const headers = Object.keys(data[0] || {}).map(key => {
            return {
                label: key,
                name: key,
            }
        });

        const Cells = (index, d) => {
            return (
                <tr key={`linked-${index}`}>
                    {Object.keys(d).map(key => {
                        return(<td data-label={{key}}>
                            <div className="text-muted text-h5">{d[key]}</div>
                        </td>)
                    })}
                </tr>
            );
        }

        return (
            <SuperTable 
                data={data}
                headers={headers}
                Cells={Cells}
                auto_sort={true}
                lang={lang}
            />
        );
    }
}

export default ModalLinkedTable;
