import React, { Component } from 'react'
import FormClaimVersionUpload from './FormClaimVersionUpload';
import AddSuccess from '../../../../components/modal/AddSuccess';
import CloseButton from '../../../../components/buttons/CloseButton';

export default class ModalAddClaimVersion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stage: 'addClaimVersionForm'
        };
        this.closeModal = this.closeModal.bind(this);
        this.updateStage = this.updateStage.bind(this);
    }
    closeModal() {
        this.props.toggleModal()
        this.updateStage('addClaimVersionForm')
    }
    updateStage(stage) {
        this.setState({
            stage
        })
    }
    render() {
        const { 
            open, 
            policy_reference, 
            policys_claims_put, 
            refreshClaimData, 
            latestClaimVersion,
            claim_reference,
            isError,
            setIsError,
            lang,
        } = this.props;
        const { stage } = this.state;

        return (
            <div className={`modal modal-blur fade ${open ? 'show' : ''}`} style={{ display: open ? 'block' : '' }} tabIndex="-1" role="dialog" aria-modal="true">
                <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header theme-dark'>
                            <h5 className='modal-title'>{lang.t('policy.claims.addClaimVersion')}</h5>
                            <CloseButton onClick={this.closeModal} />
                        </div>
                        {stage === 'addClaimVersionForm' && 
                            <FormClaimVersionUpload
                                updateStage={this.updateStage}
                                policy_reference={policy_reference}
                                policys_claims_put={policys_claims_put}
                                claim_reference={claim_reference}
                                refreshClaimData={refreshClaimData}
                                latestClaimVersion={latestClaimVersion}
                                isError={isError}
                                setIsError={setIsError}
                                lang={lang}
                            />
                        }
                        {stage === 'addClaimVersionSuccess' && 
                            <AddSuccess 
                                close={this.closeModal} 
                                name={lang.t('policy.claims.claimVersion')}
                                lang={lang}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}
