import React, { Component } from 'react'
import Chart from 'react-apexcharts'
import { sortBy } from 'lodash'
import calculateBalance from '../../../../utils/calculateBalance'
import { 
        getXaxisCategories, 
        createTransactionHistory 
        } from '../../../../utils/transactions'
import moment from 'moment'

export class TransactionChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            series: null,
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                    stacked: false,
                },                
                plotOptions: {
                    bar: {
                        columnWidth: '50%',
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    opacity: 1,
                },
                grid: {
                    padding: {
                        top: -20,
                        right: 0,
                        left: -4,
                        bottom: -8
                    },
                    strokeDashArray: 4,
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                },
                xaxis: {
                },
                yaxis: {
                    labels: {
                        padding: 4
                    },
                },
                colors: ["#206bc4", "#79a6dc", "#bfe399"],
                legend: {
                    show: true,
                    position: 'bottom',
                    height: 32,
                    offsetY: 8,
                    markers: {
                        width: 8,
                        height: 8,
                        radius: 100,
                    },
                    itemMargin: {
                        horizontal: 8,
                    },
                },
            },
        }
    }

    componentWillReceiveProps(nextprops) {
        if (JSON.stringify(nextprops.data) !== JSON.stringify(this.props.data)) {
            this.updateGraph();
        }
        return true;
    }

    componentWillMount () {
        this.updateGraph()
    }
    
    updateGraph() {
        const transactions = sortBy(this.props.data, d => -moment(d.date))
        const xaxisCategories = getXaxisCategories(transactions)
        let transactionHistory = createTransactionHistory(transactions)
        const balance = [parseInt(this.props.balance[0].balance)]
        calculateBalance(balance, transactionHistory)
        transactionHistory.reverse()

        this.setState({
            series: [
                { name: "Balance", data: balance},
                { name: 'Transactions', data: transactionHistory }  
            ],
            options: {
                ...this.state.options,
                xaxis: {
                    labels: {
                        padding: 0
                    },
                    tooltip: {
                        enabled: false
                    },
                    axisBorder: {
                        show: false,
                    },
                    tickAmount: 10,
                    categories: xaxisCategories,
                }
            },
        })
    }

    render() {
        const key = JSON.stringify(this.props.data);

        return (
            <div key={key}>
                <Chart 
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    width="100%"
                    height="300px"
                    />
            </div>
            )
        }
    }

    export default TransactionChart