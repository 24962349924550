import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLangSource } from '../lang';
import actions from '../actions';
import { get, capitalize } from 'lodash';

import { getRemoteAutocrat } from './autocrat';

import Form_Password from './components/Form_Password';
import Form_New_Password from './components/Form_New_Password';
import Form_Token_MFA from './components/Form_Token_MFA';
import Form_Set_Token_MFA from './components/Form_Set_Token_MFA';
import Form_Forgot from './components/Form_Forgot';

import TopBar from '../components/layout/TopBar';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLang: localStorage.getItem('lang') || 'en',
            autocrat: {
                type: 'USER_PASSWORD_AUTH'
            },
            autocratError: '',
        }
        this.setAutocratType = this.setAutocratType.bind(this);
        this.submitAutocrat = this.submitAutocrat.bind(this);
        this.setAutocratError = this.setAutocratError.bind(this);
        this.selectLang = this.selectLang.bind(this);
    }

    async submitAutocrat(data) {
        this.setState({ isAutocratFetching: true, autocratError: '' });
        try {
            this.setState({ isFetching: true });
            let autocrat = await getRemoteAutocrat(data);

            // If type is 'RESEND PASSWORD', the logic within this function will be executed
            autocrat = await this.sendTempPassword(data, autocrat);

            // TODO Enforcing MFA - make optional in UI
            autocrat = await this.enforceMFA(autocrat);

            this.setState({isFetching: false, autocrat: {
                ...this.state.autocrat,
                ...autocrat,
            }}, async () => {
                if (autocrat.type === 'DONE') {
                    window.location.reload();
                }
            });
        } catch(e) {
            const { message = ''} = e;
            this.setState({
                autocratError: capitalize(message),
                isFetching: false,
            });
        }
    }

    async enforceMFA(autocrat) {
        // If MFA set up then SOFTWARE_TOKEN_MFA would follow USER_PASSWORD_AUTH, not DONE
        if (this.state.autocrat.type === 'USER_PASSWORD_AUTH' && autocrat.type === 'DONE') {
            const result = await getRemoteAutocrat({
                type: 'SET_SOFTWARE_TOKEN_MFA',
                username: autocrat.username,
                access_token: autocrat.access_token,
            });
            return result;
        }
        return autocrat;
    }

    async sendTempPassword(data, autocrat) {
        if (autocrat.type === 'RESEND_PASSWORD') {
            const result = await getRemoteAutocrat({
                type: 'RESEND_PASSWORD',
                username: data.username,
            });

            return result;
        }

        return autocrat;
    }

    setAutocratType(type) {
        this.setState({
            autocrat : {
                ...this.state.autocrat,
                type,
            }
        })
    }

    setAutocratError(errorMessage) {
        this.setState({
            autocratError: errorMessage,
        })
    }

    selectLang(selectedLang) {
        localStorage.setItem('lang', selectedLang);
        this.setState({
            selectedLang,
        }, () => {
            this.props.set_lang(selectedLang);
        });
    }

    render() {
        const {
            selectedLang,
            autocratError,
            autocrat,
            isFetching,
        } = this.state;

        const lang = getLangSource(selectedLang);
        const networkError = get(this.props, 'auth.error', {});
        const { type = '' } = autocrat;

        return (
            <div className="page login">
                <TopBar
                    lang={lang}
                    selectLang={this.selectLang}
                    selectedLang={selectedLang}
                    isLogin
                />
                <div className="flex-fill d-flex flex-column justify-content-center">
                    <div className="container-tight py-6">
                        <a 
                            className="bb_logo" 
                            href="https://www.bybits.co.uk"
                            style={{ margin: '0px auto 40px', border: 'none'}}
                        >
                        </a>

                        {autocratError !== '' &&
                            <div className="alert alert-danger" role="alert">
                                <i className="fe fe-info" /> {autocratError}
                            </div>
                        }
                        
                        {(networkError.status === 500) &&
                            <div className="alert alert-danger" role="alert">
                                <i className="fe fe-info" /> {networkError.message} ({networkError.status})
                            </div>
                        }

                        {type === 'PASSWORD_RESET' &&
                            <div className="alert alert-success" role="alert">
                                <i className="fe fe-info" /> {lang.t('login.passwordReset')}
                            </div>
                        }

                        {/** Initial Login **/}
                        {(type === '' || type === 'USER_PASSWORD_AUTH' || type === 'PASSWORD_RESET') &&
                            <Form_Password 
                                lang={lang}
                                submitAutocrat={this.submitAutocrat}
                                setAutocratType={this.setAutocratType}
                                autocrat={autocrat}
                                isFetching={isFetching}
                            />
                        }

                        {/** First Login needs to set new password **/}
                        {(type === 'NEW_PASSWORD_REQUIRED') &&
                            <Form_New_Password 
                                lang={lang}
                                submitAutocrat={this.submitAutocrat}
                                autocrat={autocrat}
                                isFetching={isFetching}
                                setAutocratError={this.setAutocratError}
                            />
                        }

                        {/** First Login needs to set MFA **/}
                        {/** MFA is enforced in the code**/}
                        {(type === 'VERIFY_SOFTWARE_TOKEN_MFA') &&
                            <Form_Set_Token_MFA 
                                lang={lang}
                                submitAutocrat={this.submitAutocrat}
                                autocrat={autocrat}
                                isFetching={isFetching}
                            />
                        }

                        {/** MFA Code **/}
                        {type === 'SOFTWARE_TOKEN_MFA' &&
                            <Form_Token_MFA 
                                lang={lang}
                                submitAutocrat={this.submitAutocrat}
                                autocrat={autocrat}
                                isFetching={isFetching}
                            />
                        }

                        {/** Forgot Password **/}
                        {(type === 'FORGOT_PASSWORD' || type === 'FORGOT_PASSWORD_CODE' || type === 'RESEND_PASSWORD') &&
                            <Form_Forgot 
                                lang={lang}
                                isFetching={isFetching}
                                autocrat={autocrat}
                                setAutocratType={this.setAutocratType}
                                submitAutocrat={this.submitAutocrat}
                            />
                        }

                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ...actions,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
