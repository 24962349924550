import { get } from 'lodash';
import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class JourneyChart extends Component {
    constructor(props) {
        super(props);
        this.updateChart = this.updateChart.bind(this);
    }

    componentWillMount() {
        this.updateChart(this.props);
    }

    updateChart() {
        const {
            series,
            labels,
            name,
        } = this.props;

        let chart = {
            series,
            options: {
                chart: {
                    sparkline: {
                        enabled: true
                    },
                    animations: {
                        enabled: false
                    },
                },
                fill: {
                    opacity: 1,
                },
                stroke: {
                    width: [2],
                    lineCap: "round",
                    curve: "smooth",
                },

                grid: {
                    strokeDashArray: 4,
                },
                xaxis: {
                    labels: {
                        padding: 0
                    },
                },
                yaxis: {
                    labels: {
                        padding: 4
                    },
                },
                labels,
                colors: ["#206bc4"],
                legend: {
                    show: false,
                },
            }
        };

        this.setState({ chart });
    }

    render() {
        const { chart = {} } = this.state;
        const { label, value, unit, height = 50 } = this.props;
        return(
            <table 
                className="table table-vcenter table-mobile-md card-table mb-2" 
                style={{ background: 'white', border: '1px solid #868c973d', position: 'relative' }}
            >
                <thead>
                    <tr>
                        <th>{label} <div class="badge bg-primary" style={{
                            position: 'absolute',
                            right: 16,
                            top: 8,
                        }}>{value} {unit}</div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Chart
                                options={chart.options}
                                series={chart.series}
                                type="line"
                                height={height}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default JourneyChart;