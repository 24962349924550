import React, { Component } from 'react';
import { saveAs } from 'file-saver';
import Bar from '../../../../../components/loaders/Bar';

// Service Design
import PolicyVersionsTable from '../../Versions/Table';
import { getCurrentVersion } from '../../../../../utils/getCurrentVersion.js';

class LogSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            format: 'csv',
            is_downloading: false,
        }
        this.export = this.export.bind(this);
        this.getData = this.getData.bind(this);
        this.setFormat = this.setFormat.bind(this);
    }

    async componentDidMount() {
        await this.getData(this.state.format)
    }

    export() {
        const { data } = this.props.policys_reporting;
        const { policy_reference } = this.props;
        const { format } = this.state;

        this.setState({
            is_downloading: true,
        });

        let content;
        let file;

        if (format === 'json') {
            file = `${policy_reference}.json`;
            content = new Blob([JSON.stringify(data, null, 4)], {
                type: 'application/json',
                name: file
            });
        }

        if (format === 'csv') {
            file = `${policy_reference}.csv`;
            content = new Blob([data], {
                type: 'text/csv',
                name: file
            });
        }

        if (format === 'edi') {
            file = `${policy_reference}.edi`;
            content = new Blob([data], {
                type: 'text',
                name: file
            });
        }

        // Save the file
        saveAs(content, file);

        this.setState({
            is_downloading: false,
        });
    }

    async getData(format) {
        const { policy_reference } = this.props;
        this.props.policys_reporting_get_all({
            format,
            policy_reference,
        })
    }

    setFormat(format) {
        this.getData(format);
    }

    render() {
        const { policy_reference, versions, lang } = this.props;
        const { format, is_downloading } = this.state;
        const { data, isFetching } = this.props.policys_reporting;

        return (
            <div>
                <div class="row mb-3">
                    <div class="col-sm-12 col-lg-6">
                        <div className="col-auto">
                            <div className="strong" style={{ textTransform: 'normal'}}>Policy Reference: {policy_reference}</div>
                            <div className="text-muted text-h5 mt-2"><div className="badge bg-green">Versions: {versions.length}</div></div>
                        </div>
                    </div>
                    <div class="col-auto ml-auto d-print-none">
                        <button onClick={() => {
                                if(is_downloading) return;
                                return this.export('json');
                            }} type="button" className={`btn btn-blue mr-3 ${is_downloading ? 'disabled' : ''}`}>
                            Download CSV
                        </button>
                    </div>
                </div>

                {is_downloading &&
                    <div className="row mt-3 mb-3"> 
                        <Bar />
                    </div>
                }

                {versions.length ? (
                    <div class="row mb-3">
                        <div className="col-sm-12 col-lg-12">
                            <PolicyVersionsTable 
                                data={versions}
                                currentVersion={getCurrentVersion(versions)}
                                lang={lang}
                            />
                        </div>
                    </div>
                ) : <div>Sorry, no policy versions found!</div>}

                {/* format === 'json' && !isFetching &&
                    <div class="row mb-3">
                        <div class="col-sm-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Policy Versions</h3>
                                    <button onClick={() => {
                                        return this.export('json');
                                    }} type="button" className="btn btn-blue ml-auto">
                                        Download JSON
                                    </button>
                                </div>
                                <div className="card-body" style={{ maxHeight: 300, overflow: 'scroll'}}>
                                    <ReactJson
                                        src={data}
                                        theme={'grayscale:inverted'}
                                        enableClipboard={false}
                                        displayDataTypes={false}
                                        name={false}
                                        style={{
                                            background: 'transparent',
                                            fontFamily: 'monaco',
                                            fontSize: '12px',
                                        }}
                                    />
                                </div>
                            </div>   
                        </div>
                    </div>
                */}

                {/* format === 'csv' && !isFetching &&
                    <div class="row mb-3">
                        <div class="col-sm-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Policy Versions</h3>
                                    <button onClick={() => {
                                        return this.export('csv');
                                    }} type="button" className="btn btn-sm btn-blue ml-auto">
                                        Download CSV
                                    </button>
                                </div>
                                <div className="card-body" style={{ maxHeight: 300, overflow: 'scroll'}}>
                                    <pre style={{ padding: 0, margin: 0 }}>
                                        {data}
                                    </pre>
                                </div>
                            </div>   
                        </div>
                    </div>
                */}

                {/* format === 'edi' && !isFetching &&
                    <div class="row mb-3">
                        <div class="col-sm-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Policy Versions</h3>
                                    <button onClick={() => {
                                        return this.export('edi');
                                    }} type="button" className="btn btn-sm btn-blue ml-auto">
                                        Download EDI
                                    </button>
                                </div>
                                <div className="card-body" style={{ maxHeight: 300, overflow: 'scroll'}}>
                                    <pre style={{ padding: 0, margin: 0 }}>
                                        {data}
                                    </pre>
                                </div>
                            </div>   
                        </div>
                    </div>
                */}

            </div>
        )
    }
}

export default LogSection;