import React, { Component } from 'react';
import { round } from 'lodash';
import moment from 'moment';

import { extendedDateFormat } from '../../../../utils/format';
import TinySpinner from '../../../../components/loaders/TinySpinner';
import { versionBadge } from '../Shared/Badges';

function toUpper(str = '') {
    return str?.toUpperCase();
}

function quotability({
    all_quotes = 0,
    valid_quotes = 0,
}) {
    return (all_quotes === 0 ? 0 : round((valid_quotes / all_quotes ) * 100, 2)) + '%'
}

class CompareTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        }
    }

    render() {
        let { data = [], compare, lang } = this.props;
        data = data.filter(d => d.status === 'published');

        return (
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>  
                            <tr>
                                <th>{lang.t('tableHeaders.version')}</th>
                                <th>{lang.t('tableHeaders.name')}</th>
                                <th>{lang.t('tableHeaders.quotes')}</th>
                                <th>{lang.t('tableHeaders.quotability')}</th>
                                <th>{lang.t('tableHeaders.upfront')}</th>
                                <th>{lang.t('tableHeaders.subs')}</th>
                                <th>{lang.t('tableHeaders.usage')}</th>
                                <th>{lang.t('tableHeaders.yearly')}</th>
                                <th>{lang.t('tableHeaders.mileage')}</th>
                                <th className="w-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((d, i) => {
                                return (
                                    <tr key={`models-${i}`}>
                                        <td data-label="Version">
                                            <div className="text-muted text-h5">{versionBadge(d)}</div>
                                        </td>
                                        <td data-label="Name">
                                            <div className="d-flex lh-sm py-1 align-items-center">
                                                <div className="flex-fill">
                                                    <div className="strong">{d.version_reference}</div>
                                                    <div className="text-muted text-h5">{`${lang.t('rating.compare.published')} ${extendedDateFormat(d.published_at)} ${lang.t('rating.compare.to')} ${data[i - 1] ? extendedDateFormat(data[i - 1].published_at) : lang.t('rating.compare.current')}`}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-label="Quotes">
                                            <div className="strong">{d.analytics ? d.analytics.all_quotes : ''}</div>
                                        </td>
                                        <td data-label="Quotability">
                                            <div className="strong">{d.analytics ? quotability(d.analytics) : ''}</div>
                                        </td>
                                        <td data-label="Upfront">
                                            <div className="strong">{d.analytics ? d.analytics.avg_upfront_rate : ''}</div>
                                        </td>
                                        <td data-label="Subscription">
                                            <div className="strong">{d.analytics ? d.analytics.avg_subscription_rate : ''}</div>
                                        </td>
                                        <td data-label="Usage">
                                            <div className="strong">{d.analytics ? d.analytics.avg_usage_rate : ''}</div>
                                        </td>
                                        <td data-label="Yearly">
                                            <div className="strong">{d.analytics ? d.analytics.avg_yearly_premium_estimate : ''}</div>
                                        </td>
                                        <td data-label="Mileage">
                                            <div className="strong">{d.analytics ? d.analytics.avg_estimated_distance : ''}</div>
                                        </td>
                                        <td>
                                            {d.is_fetching &&
                                                <div className="btn-list flex-nowrap">
                                                    <TinySpinner is_alt={true} />
                                                </div>
                                            }
                                            {!d.is_fetching &&
                                                <div className="btn-list flex-nowrap">
                                                    <a
                                                        className="btn btn-blue btn-sm"
                                                        style={{ width: 100 }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.compare(d);
                                                        }}
                                                    >{lang.t('rating.compare.compare')}</a>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default CompareTable;
