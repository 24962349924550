const base = {
    // By Bits - used for testing
    "098ae4f2-3af8-4740-8760-5d312e46742c": {
        mta_quote: {
            by_bits_usage: {
                url: 'https://ultra-bronze.dev.cloud.kobainsurance.com/api/public/bybits/quote',
            },
            koba_usage: {
                url: 'https://ultra-bronze.dev.cloud.kobainsurance.com/api/public/bybits/quote',
            },
            koba_prod_usage_product: {
                url: 'https://ultra-bronze.prod.cloud.kobainsurance.com/api/public/bybits/quote',
            },
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            error_message_path: 'response.data.error', // Object path to extract the error (eg err.response.data.message)
            error_status_path: 'response.status',
            authorisation: require('../services/policys/components/Detail/ClientAuthorisation/Koba/index'),
        },
        codelist: {
            enabled: false,
        }
    },
    // Koba
    "1366b84c-9a44-45ad-ba21-02ce1e25a2c5": {
        mta_quote: {
            // Koba dev API
            koba_usage: {
                url: 'https://ultra-bronze.dev.cloud.kobainsurance.com/api/public/bybits/quote',
            },
            // Koba prod API
            koba_prod_usage_product: {
                url: 'https://ultra-bronze.prod.cloud.kobainsurance.com/api/public/bybits/quote',
            },
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            error_message_path: 'response.data.error',
            error_status_path: 'response.status',
            authorisation: require('../services/policys/components/Detail/ClientAuthorisation/Koba/index'),
        },
        codelist: {
            enabled: false,
        }
    }
}

export default base;