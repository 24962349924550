import BaseActions from '../../../actions/base';

const claims_jobs = new BaseActions({
    path: '/claims/jobs',
    type: 'CLAIMS_JOBS',
    version: 'application/vnd.claims.v1+json',
    notify: {
        post: {
            type: 'info',
            message: 'You will be notified when the job has finished',
            title: 'Job has started',
        },
        getSome: {
            type: 'success',
            message: 'The following job has finished',
            title: 'Job Finished (20.0 secs)',
        }
    }
});

export default claims_jobs;