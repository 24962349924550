import React, { Component } from 'react';
import Input from './Input';
import SelectOnly from './SelectOnly';
import DatePicker from './DatePicker';
import moment from 'moment-timezone';
import { parse_date } from '../Utils';

class FormUsage extends Component {
    constructor(props) {
        super(props);
        this.addUsage = this.addUsage.bind(this);
    }

    async addUsage() {
        const { usage, detail, policys_usages_post, timezone } = this.props;
        const { type, description, distance_in_metres, activated_at } = usage;

        const localNowWithTimezone = moment.tz(timezone).format('YYYY-MM-DD'); // eg dateTimeLocal could be 2021-12-18 +11 GMT
        const localNowWithoutTimezone = moment.utc(localNowWithTimezone).unix(); // Force it to be 2021-12-18 00:00 at UTC and convert to unix

        const dateToUse = activated_at ? activated_at : localNowWithoutTimezone; // If activated_at is blank then use fixDateToUtc

        const dateWithNoTimeZone = moment.unix(dateToUse).format('YYYY-MM-DD'); //Convert unix timestamp to YYYY-MM-DD format

        const dateWithTimezone = moment.tz(dateWithNoTimeZone, timezone); //Apply timezone
    
        const converted_date_unix = moment(dateWithTimezone).unix();

        let data = {
            type,
            description,
            distance_in_metres: Number(distance_in_metres),
            policy_reference: detail.policy_reference,
            activated_at: converted_date_unix,
        };

        if(type === 'odometer') {
            data = {
                ...data,
                distance_in_metres: 0,
                odometer: Number(distance_in_metres),
            };
        };

        await policys_usages_post({ data });
        this.props.updateStage('add_usage_success');
    }

    render() {
        const { usage, can_submit, update, timezone, lang } = this.props;
        const { type, description, distance_in_metres, activated_at } = usage;
        const localDateTimeNow = parse_date(new Date(), 'YYYY-MM-DD', timezone)
        const minDate = new Date(localDateTimeNow);
        
        return(
            <>
                <div className="modal-body">
                    <SelectOnly
                        label={`${lang.t('policy.policies.type')} *`}
                        property='type'
                        options={[
                            { label: 'Estimated', value: 'estimated' },
                            { label: 'Odometer', value: 'odometer' },
                            { label: 'Penalty', value: 'penalty' },
                        ]}
                        value={type}
                        update={update}
                        placeholder='Select a type'
                    />
                    <Input 
                        label={`${lang.t('policy.policies.description')} *`}
                        property='description'
                        value={description}
                        update={update}
                        type='text'
                    />
                    <Input 
                        label={`${lang.t('policy.policies.distanceInMetres')} *`}
                        property='distance_in_metres'
                        value={distance_in_metres}
                        update={update}
                        type='number'
                    />
                    <DatePicker 
                        label={`${lang.t('policy.policies.date')} *`}
                        property='activated_at'
                        type='unix'
                        update={update}
                        value={activated_at}
                        minDate={minDate}
                    />
                    <h5 style={{ marginTop: '10px' }}>* {lang.t('policy.policies.required')}</h5>
                </div>
                <div className="modal-footer">
                    <button 
                        type="button" 
                        className="btn btn-primary" 
                        data-dismiss="modal" 
                        onClick={this.addUsage} 
                        disabled={!can_submit}
                    >
                        {lang.t('buttons.add')}
                    </button>
                </div>
            </>        
        )
    }
}

export default FormUsage;
