import React, { Component } from 'react'

function HelpButton (props) {
    const {
        size = '', // btn-sm or btn-lg for different size to default
        url = '',
        className = '',
        lang,
    } = props

    return (
        <a 
            className={`btn btn-dark text-white ${size} ${className}`} 
            onClick={(e) => {
                e.preventDefault();
                window.open(url)
            }}
        >{lang.t('buttons.help')}</a>
    )
}

export default HelpButton