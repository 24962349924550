import React, { Component } from 'react';
import { Link } from 'react-router-dom';

function toUpper(str = '') {
    return str?.toUpperCase();
}

class ProductsTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data = [], lang } = this.props;

        return (
            <div className="card">

                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>{lang.t('tableHeaders.name')}</th>
                                <th>{lang.t('tableHeaders.type')}</th>
                                <th>{lang.t('tableHeaders.email')}</th>
                                <th>{lang.t('tableHeaders.units')}</th>
                                <th>{lang.t('tableHeaders.currency')}</th>
                                <th>{lang.t('tableHeaders.stripeKeys')}</th>
                                <th>{lang.t('tableHeaders.webhook')}</th>
                                <th className="w-1"/>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((d, i) => (
                                <tr key={`statements-${i}`}>
                                    <td data-label="reference">
                                        <div className="d-flex lh-sm py-1 align-items-center">
                                            <div className="flex-fill">
                                                <div className="strong">{toUpper(d.product_name)}</div>
                                                <div className="text-muted text-h5">{d.product_reference}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-label="Subscription">
                                        <div className="text-h5">{lang.t('settings.products.motor')}</div>
                                    </td>
                                    <td data-label="Total">
                                        <div className="text-h5">{d.sender_email_address}</div>
                                    </td>
                                    <td data-label="Billed">
                                        <div className="text-h5"><b>{d.distance_units}</b></div>
                                    </td>
                                    <td data-label="Count">
                                        <div className="text-muted text-h5"><b>{d.currency}</b></div>
                                    </td>
                                    <td data-label="Stripe Keys">
                                        { d.payment_enabled ?
                                            <div class={`badge bg-success`}>{lang.t('buttons.set')}</div>
                                            :
                                            <div class={`badge bg-danger disabled`}>{lang.t('buttons.disabled')}</div>
                                        }
                                    </td>
                                    <td data-label="Webhook">
                                        { d.webhooks_enabled ?
                                            <div class={`badge bg-success`}>{lang.t('buttons.set')}</div>
                                            :
                                            <div class={`badge bg-danger disabled`}>{lang.t('buttons.disabled')}</div>
                                        }
                                    </td>
                                    <td>
                                        <div className="btn-list flex-nowrap">
                                            <Link
                                                to={`/settings/products/${d.product_reference}`}
                                                className="btn btn-sm btn-primary"
                                            >{lang.t('buttons.view')}</Link>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default ProductsTable;
