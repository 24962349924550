import React, { Component } from 'react';
import Input from './Input';
import Select from './Select';
import TableClaims from '../Tables/TableClaims';
import TableConvictions from '../Tables/TableConvictions';
import DatePicker from '../Forms/DatePicker';
import { get_codelist } from '../Utils';

class FormDriver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updated_info: {}
        }
        this.update = this.update.bind(this);
        this.open_claims_modal = this.open_claims_modal.bind(this);
        this.open_convictions_modal = this.open_convictions_modal.bind(this);
    }

    update(item) {
        const { driver } = this.props;
        const updated_info = {
            ...this.state.updated_info,
            ...item,
        }
        
        this.props.update({
            ...driver,
            ...updated_info,
        });

        this.setState({ updated_info });
    }

    open_claims_modal() {
        const { modal_open, set_driver_index, driver_index } = this.props;

        modal_open({
            add_claim: {
                open: true,
            }
        });

        set_driver_index(driver_index);
    }

    open_convictions_modal() {
        const { modal_open, set_driver_index, driver_index } = this.props;

        modal_open({
            add_conviction: {
                open: true,
            }
        });

        set_driver_index(driver_index);
    }

    render() {
        const { driver, disabled, driver_index, is_mta = false, update_page, lang } = this.props

        return (
        <div className="mb-1">
            {driver_index !== 'proposer' &&
                <Select
                    label='Relationship *'
                    property={'relationship'}
                    options={get_codelist('additional_drivers.map(relationship)')}
                    value={driver.relationship}
                    update={this.update}
                    disabled={disabled}
                />
            }
            <Select
                label='Title *'
                property={'title'}
                options={get_codelist('proposer.title')}
                value={driver.title}
                update={this.update}
                disabled={disabled}
            />
            <Input 
                label='First names *'
                property={'first_names'}
                value={driver.first_names}
                update={this.update}
                type = 'text'
                disabled={disabled}
            />
            <Input 
                label='Last names *'
                property={'last_names'}
                value={driver.last_names}
                update={this.update}
                type = 'text'
                disabled={disabled}
            />
            <Input 
                property={'email'}
                value={driver.email}
                update={this.update}
                type = 'text'
                disabled={disabled || driver_index === 'proposer'}
            />
            <Input 
                property={'phone_number'}
                value={driver.phone_number}
                update={this.update}
                type = 'text'
                disabled={disabled || driver_index === 'proposer'}
            />
            {is_mta &&
                <h6 style={{ marginLeft: '5px' }}>
                    <i style={{ marginRight: '5px' }} className="fe fe-info text-primary" />
                    {lang.t('policy.policies.emailPhoneAmend')}  
                    <a className="text-primary" href="" onClick={(e) => {
                        e.preventDefault();
                        update_page('contact')
                    }}> {lang.t('policy.nav.policyContactDetails')}</a> {lang.t('policy.policies.section')}
                </h6>
            }
            <Input
                label='Children *'
                property={'children'}
                value={driver.children}
                update={this.update}
                type = 'number'
                disabled={disabled}
            />
            <Input 
                label='NCD *'
                property={'ncd'}
                value={driver.ncd}
                update={this.update}
                type = 'number'
                disabled={disabled}
            />
            <DatePicker 
                label={'Date of birth *'}
                property={'dob'}
                value={driver.dob}
                update={this.update}
                disabled={disabled}
            />
            <Select
                label='Licence type *'
                property={'licence_type'}
                options={get_codelist('proposer.licence_type')}
                value={driver.licence_type}
                update={this.update}
                disabled={disabled}
            />
            <Select
                label='Employment *'
                property={'employment'}
                options={get_codelist('proposer.employment')}
                value={driver.employment}
                update={this.update}
                disabled={disabled}
            />
            <Select
                label='Occupation'
                property={'occupation'}
                options={get_codelist('proposer.occupation')}
                value={driver.occupation}
                update={this.update}
                disabled={disabled}
            />
            <Select
                label='Business'
                property={'business'}
                options={get_codelist('proposer.business')}
                value={driver.business}
                update={this.update}
                disabled={disabled}
            />
            {driver_index === "proposer" &&
                <Select
                    label='Homeowner'
                    property={'own_home'}
                    options={[{ label: true }, { label: false }]}
                    value={driver.own_home}
                    update={this.update}
                    disabled={disabled}
                />
            }
            <DatePicker 
                label={'Licence issue *'}
                property={'licence_issued_date'}
                value={driver.licence_issued_date}
                update={this.update}
                disabled={disabled}
            />
            <DatePicker 
                label={'Resident since'}
                property={'residency_date'}
                value={driver.residency_date}
                update={this.update}
                disabled={disabled}
            />
            <Select 
                label='Criminal convs *'
                property='has_criminal_conviction'
                options={[{ label: 'true' }, { label: 'false' }]}
                value={driver.has_criminal_conviction}
                update={this.update}
                disabled={disabled}
            />
            <Select 
                label='Med. conditions *'
                property='has_medical_conditions'
                options={[{ label: 'true' }, { label: 'false' }]}
                value={driver.has_medical_conditions}
                update={this.update}
                disabled={disabled}
            />
            <Select 
                label='DVLA informed *'
                property='has_informed_dvla_medical_conditions'
                options={[{ label: 'true' }, { label: 'false' }]}
                value={driver.has_informed_dvla_medical_conditions}
                update={this.update}
                disabled={disabled}
            />
            <hr />
            <div>
                <div style={{marginTop: '25px', marginBottom: '25px' }}>
                    <b>{lang.t('policy.policies.claims')}</b>
                    {!disabled && 
                    <a className="btn btn-primary btn-sm float-right text-white" onClick={this.open_claims_modal}>+ {lang.t('policy.policies.addClaim')}</a>
                    }
                </div>
                <TableClaims 
                    data={driver.claims}
                    disabled={disabled}
                    update={this.update}
                    lang={lang}
                />
            </div>
            <div style={{marginTop: '30px', marginBottom: '25px' }}>
                <b>{lang.t('policy.policies.convictions')}</b>
                {!disabled && 
                <a className="btn btn-primary btn-sm float-right text-white" onClick={this.open_convictions_modal}>+ {lang.t('policy.policies.addConviction')}</a>}
            </div>
            
            <TableConvictions 
                data={driver.convictions}
                disabled={disabled}
                update={this.update}
                lang={lang}
            />
            
        </div>
        );
    }
}

export default FormDriver;