import { saveAs } from 'file-saver';
import axios from 'axios';

export async function deleteFile(file, policy_reference, policys_kyc_delete, policys_kyc_get) {
    const { file_name, file_type } = file;
    const id = policy_reference;
    const query = {
        file_name,
        file_type
    }
    await policys_kyc_delete({ 
        id, 
        query, 
    });
    await policys_kyc_get({ id });
}

export async function downloadFile (file, policy_reference, policys_kyc_get_all) {
    const { file_name, file_type } = file;
    const query = {
        policy_reference,
        file_name,
        file_type
    }
    const res = await policys_kyc_get_all(query);
    const content = res.payload.data.signed_url;
    saveAs(content, file_name);
}

export async function uploadFile ({
    policys_kyc_post, 
    policy_reference, 
    selectedCategory, 
    fileExtension,
    selectedFile
    },
    progress = () => null
) {
    const res = await policys_kyc_post({
        data: {
            category: selectedCategory,
            policy_reference,
            file_type: fileExtension
        }
    });
    const signedURL = res.payload.data.signed_url;
    const content_type = fileExtension === ('mp4' || 'avi') ? 'video' : 'image';
    const headers = {
        'x-amz-acl': 'public-read',
        'Content-Type': `${content_type}/${fileExtension}`,
        "Content-Disposition": "attachment;" 
    }
    await axios({
        method: 'put',
        url: signedURL,
        headers: headers,
        data: selectedFile,
        onUploadProgress: e => progress(e)
    });
}