import React, { Component } from 'react';
import { get } from 'lodash';

import { getLangSource } from '../../../../lang';
import main from '../../../../hoc/main';

//Core Design
import OverlayLoader from '../../../../components/loaders/OverlayLoader';
import PageHeader from '../../../../components/layout/PageHeader';

import FactorsTable from '../../components/tables/FactorsTable';
import DropDownPivot from '../../components/lossratio/DropDownPivot';
import DropDownFormat from '../../components/lossratio/DropDownFormat';
import DropDownReports from '../../components/lossratio/DropDownReports';
import DropDownRisks from '../../components/lossratio/DropDownRisks';

const factorSelected = require('./factors.json').factors;

class Factors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            risks: 'factors',
            pivot: 'all',
            format: 'accrued',
            period: 'normal',
        };
        this.selectPivot = this.selectPivot.bind(this);
        this.selectFormat = this.selectFormat.bind(this);
        this.selectPeriod = this.selectPeriod.bind(this);
        this.selectReport = this.selectReport.bind(this);
        this.selectRisks = this.selectRisks.bind(this);
        this.postReport = this.postReport.bind(this);
    }

    selectPivot(pivot) {
        this.setState({
            pivot,
        })
    }

    selectFormat(format) {
        this.setState({
            format,
        })
    }

    selectPeriod(period) {
        this.setState({
            period,
        })
    }

    selectRisks(risks) {
        this.setState({
            risks,
        })
    }

    selectReport(id) {
        this.props.claims_factors_get({ id })
        this.setState({
            pivot: 'all',
            format: 'accrued',
            period: 'normal',
        });        
    }

    postReport(e) {
        e.preventDefault();
        this.props.claims_jobs_post({
            data : {
                process: 'factors',
            },
        });      
    }

    render() {
        const lang = getLangSource(this.props.lang);
        const isFetching = false;

        return (
            <div class="my-3 my-md-5">
                {isFetching && <OverlayLoader />}
                {!isFetching &&
                    <div class="container">
                        <PageHeader
                            section={'Claims'}
                            page={'Factors'}
                        />
                        <div class="row">
                            <div class="col-sm-12 col-lg-12">
                                {Object.keys(factorSelected).map(f => {
                                    
                                    return (<FactorsTable 
                                        title={f} 
                                        data={factorSelected[f]}
                                        lang={lang}
                                    />)
                                })}
                            </div>  
                        </div>
                    </div>  
                }
            </div>
        );
    }
}

export default main(Factors);