import React, { Component } from 'react';
import { round } from 'lodash';

class BaseFormInput extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { 
            value, 
            label, 
            update,
            description = '',
            step = 1,
            placeholder,
            type = 'number',
            disabled = false,
            property = ''
        } = this.props;

        return [
            <div key={`input-group-${property}`} className="col-lg-11">
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <span className="input-group-text" style={{ width: 140 }}>
                            {label}
                        </span>
                    </div>
                    <input key={`input-field-${property}-${value}`} disabled={disabled} onBlur={(e) => {update({
                        [property]: round(e.target.value, 4),
                    })}} step={step} type={type} className="form-control" placeholder={placeholder} defaultValue={value} />
                </div>
            </div>,
            <div key={`input-info-${property}`} className="col-lg-1 text-blue">
                <i style={{ lineHeight: '35px' }} className="fe fe-info" data-toggle="tooltip" data-placement="right" title={description}></i>
            </div>
        ];
    }
}

export default BaseFormInput;
