import React, { Component } from 'react';
import { getLangSource } from '../../../lang';

import { saveAs } from 'file-saver';

import main from '../../../hoc/main';

import { indexCollection } from '../../../utils/manipulate';

import ProgressBar from '../../../components/loaders/ProgressBar';

import { validate } from '../utils/model';

import Base from '../components/ModelsDetail/Base/Index';
import Factors from '../components/ModelsDetail/Factors/Index';
import Linked from '../components/ModelsDetail/Linked/Index';
import Exclusions from '../components/ModelsDetail/Exclusions/Index';
import Formulas from '../components/ModelsDetail/Formulas/Index';
import Endorsements from '../components/ModelsDetail/Endorsements/Index';
import Tests from '../components/ModelsDetail/Tests/Index';
import Versions from '../components/ModelsDetail/Versions/Index';
import Publish from '../components/ModelsDetail/Publish/Index';
import Schema from '../components/ModelsDetail/Schema/Index';
import MachineLearning from '../components/ModelsDetail/MachineLearning/Index';

import ModalFactor from '../components/ModelsDetail/Factors/Modal/ModalFactor';
import ModalLinked from '../components/ModelsDetail/Linked/Modal/ModalLinked';
import ModalExclusion from '../components/ModelsDetail/Exclusions/ModalExclusion';
import ModalEndorsement from '../components/ModelsDetail/Endorsements/ModalEndorsement';
import ModalFormula from '../components/ModelsDetail/Formulas/ModalFormula';
import ModalImport from '../components/ModelsDetail/ModalImport';
import ModalTests from '../components/ModelsDetail/Tests/ModalTests';
import ModalTestDetails from '../components/ModelsDetail/Tests/ModalTestDetails';
import ModalMachineLearning from '../components/ModelsDetail/MachineLearning/ModalMachineLearning';

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { store as notify } from 'react-notifications-component';

import { versionBadge } from '../components/Shared/Badges';

import { getId } from '../../../utils/url';
import { getS3SignedURL, putS3SignedURL } from '../../../utils/S3SignedURL';

import { isEmpty, get, round, each } from 'lodash';

function getSizeMB(data) {
    const str = JSON.stringify(data);
    const size = round(str.length / 1000000, 2);
    return size;
}

function is_draft (props) {
    return get(props, 'pricing_models.selected.status', null) === 'draft';
}

class ModelDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: {
                factors: {
                    open: false,
                    name: '',
                },
                linked: {
                    open: false,
                    name: '',
                },
                exclusions: {
                    open: false,
                    name: '',
                },
                endorsements: {
                    open: false,
                    name: '',
                },
                formulas: {
                    open: false,
                    name: '',
                },
                import: {
                    open: false,
                },
                tests: {
                    open: false,
                    name: '',
                },
                test_detail: {
                    open: false,
                    quote: {},
                    pricing: {},
                },
                machine_learning: {
                    open: false,
                    model: {},
                }
            },
            showNotDraft: false,
            showPublish: false,
            showDescription: false,
            isFetching: false,
            interval: {},
            progress: 0,
            isSaving: false,
        };
        this.updateModel = this.updateModel.bind(this);
        this.updateMeta = this.updateMeta.bind(this);
        this.updateBase = this.updateBase.bind(this);
        this.updateFactors = this.updateFactors.bind(this);
        this.deleteFactor = this.deleteFactor.bind(this);
        this.updateLinked = this.updateLinked.bind(this);
        this.updateSchema = this.updateSchema.bind(this);
        this.updateExclusions = this.updateExclusions.bind(this);
        this.updateEndorsements = this.updateEndorsements.bind(this);
        this.updateFormulas = this.updateFormulas.bind(this);
        this.export = this.export.bind(this);
        this.openModal = this.openModal.bind(this);
        this.selectVersion = this.selectVersion.bind(this);
        this.provisionModel = this.provisionModel.bind(this);
        this.saveVersion = this.saveVersion.bind(this);
        this.publishVersion = this.publishVersion.bind(this);
        this.createVersion = this.createVersion.bind(this);
        this.cloneVersion = this.cloneVersion.bind(this);
        this.getVersion = this.getVersion.bind(this);
        this.createAutosave = this.createAutosave.bind(this);
        this.draftAlert = this.draftAlert.bind(this);
        this.updateTests = this.updateTests.bind(this);
        this.updateMLModels = this.updateMLModels.bind(this);
    }

    async componentWillMount() {
        const { history } = this.props;
        const id = getId(history);
        this.getVersion({
            version_reference: id,
        }) 
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    shouldComponentUpdate(nextProps) {
        const new_model = get(nextProps ,'pricing_models.selected', {});
        const old_model = get(this.props ,'pricing_models.selected', {});
        if (new_model.version_reference !== old_model.version_reference) {
            this.provisionModel(new_model.model);
        }
        return true;
    }

    createAutosave({
        version_reference
    }) {
        if (is_draft(this.props)) {
            if (this.state.interval) clearInterval(this.state.interval);
            this.state.interval = setInterval(() => {
                let is_modal_open = false;
                each(this.state.modal, (modal) => {
                    if (modal.open) is_modal_open = true;
                });
                // Don't autosave if modal is open
                if (is_modal_open) return;
                // Don't autosave if already saving
                if (this.state.isSaving) return;
                this.saveVersion({
                    version_reference,
                    reload: false,
                });
            }, 60000);
            console.log('Autosaved activated');
        }  
    }

    /** Main Buttons **/

    export() {
        const { meta } = this.state.model;
        const file = `${meta.name}-${meta.version}.json`;
        
        // Create a blob of the data
        const content = new Blob([JSON.stringify(this.state.model)], {
            type: 'application/json',
            name: file
        });
        
        // Save the file
        saveAs(content, file);
    }

    updateProgress(e) {
        const progress = e.loaded / e.total;
        this.setState({
            progress,
        });
    }

    getVersion({ version_reference }) {
        this.setState({ isFetching: true });
        //Get Signed URL
        return this.props.pricing_upload_post({
            data: {
                version_reference,
                method: 'get',
            },
        }).then((data) => {
            // This allows 10MB+ fetches
            const signed_url = get(data, 'payload.data.signed_url');
            return getS3SignedURL({
                signed_url,
            }, 
            e => this.updateProgress(e)
            ).then((model) =>{
                // This updates to the latest model
                this.props.pricing_models_get({
                    id: version_reference,
                }).then((data) => {
                    this.createAutosave({ version_reference });
                    this.provisionModel(model);
                    this.setState({ isFetching: false, progress: 0 });
                });
            });
        });
    }

    saveVersion({ version_reference, reload = true }) {
        if (reload) this.setState({ isFetching: true });
        const { is_valid, errors } = validate(this.state.model);
        if (!is_valid) {
            return this.saveAlert({
                is_valid: false,
                errors,
            })
        }

        this.setState({ isSaving: true });

        //Get Signed URL
        this.props.pricing_upload_post({
            data: {
                version_reference,
                name: get(this.state, 'model.meta.name', ''),
                description: get(this.state, 'model.meta.description', ''),
                size: getSizeMB(this.state.model),
                method: 'put',
            },
        }).then((data) => {
            // This allows 10MB+ uploads
            const signed_url = get(data, 'payload.data.signed_url');
            putS3SignedURL({
                signed_url,
                data: this.state.model,
            },
            e => this.updateProgress(e))
            .then(() =>{
                if (reload) {
                    // This updates to the latest model
                    this.props.pricing_models_get({
                        id: version_reference,
                    }).then((data) => {
                        const model = get(data, 'payload.data.model');
                        this.provisionModel(model);
                        this.setState({ isFetching: false });
                        this.saveAlert({ is_auto: false });
                    });
                } else {
                    this.saveAlert({ is_auto: true });
                }
                this.setState({
                    progress: 0,
                    isSaving: false,
                })
            });
        });
    }

    publishVersion({ id, status, published_at, description }) {
        this.setState({ isFetching: true });
        this.props.pricing_publish_put({
            id,
            data: {
                status,
                published_at,
            },
        }).then((data) => {
            this.props.pricing_models_get({
                id,
             }).then((data) => {
                 const model = get(data, 'payload.data.model');
                 this.provisionModel(model);
                 this.setState({ isFetching: false, showPublish: false });
             });
        });
    }

    createVersion() {
        this.setState({ isFetching: true });
        const model_reference = get(this.props, 'pricing_models.selected.model_reference');
        this.props.pricing_versions_post({
            data: {
                model_reference,
            },
        }).then((data) => {
            const version_reference = get(data, 'payload.data.version_reference');
            this.props.history.push('/rating/models/' + version_reference);
            window.location.reload();
        });
    }

    cloneVersion(model_reference, version_reference){
        this.setState({ isFetching: true });
        this.props.pricing_versions_post({
            data: {
                model_reference,
                version_reference
            },
        }).then((data) => {
            const version_reference = get(data, 'payload.data.version_reference');
            this.props.history.push('/rating/models/' + version_reference);
            window.location.reload();
        });
    }

    openModal(modal) {
        this.setState({
            modal: {
                ...this.state.modal,
                ...modal,
            },
        });
    }

    provisionModel(model) {
        if(model) {
            this.setState({
                model: {
                    ...model,
                    formulas: indexCollection(model.formulas),
                    exclusions: indexCollection(model.exclusions),
                },
            });
        }
    }

    draftAlert(){
        if (!is_draft(this.props)) {
            notify.addNotification({
                title: "Not a draft version!",
                message: "You can either create a new version or select a draft at the bottom of the page",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 1000,
                    onScreen: true
                }
            });
        }
    }

    saveAlert({ is_auto = false, is_valid = true, errors = [] }){
        if (is_valid === false) {
            notify.addNotification({
                title: "Model schema issue",
                message: JSON.stringify(errors, null, 4),
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        } else {
            notify.addNotification({
                title: is_auto ? "Autosaved" : "Saved",
                message: "Model has been validated and persisted",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 1000,
                    onScreen: true
                }
            });
        }
    }

    selectVersion(version_reference) {
        this.props.pricing_models_get({
            id: version_reference,
         });
    }

    updateModel(model){
        this.draftAlert();
        this.setState({
            model,
        })
    }

    updateMeta(data) {
        this.draftAlert();
        this.setState({
            model: {
                ...this.state.model,
                meta: {
                    ...this.state.model.meta,
                    ...data,
                }
            }
        });
    }

    updateBase(data) {
        this.draftAlert();
        this.setState({
            model: {
                ...this.state.model,
                base: {
                    ...this.state.model.base,
                    ...data,
                }
            }
        });
    }

    updateFactors(data) {
        this.draftAlert();
        this.setState({
            model: {
                ...this.state.model,
                factors: {
                    ...this.state.model.factors,
                    ...data,
                }
            }
        });
    }

    deleteFactor(factors) {
        this.draftAlert();
        this.setState({
            model: {
                ...this.state.model,
                factors,
            }
        });
    }

    updateSchema(schema) {
        this.draftAlert();
        this.setState({
            model: {
                ...this.state.model,
                schema,
            }
        });
    }

    updateLinked(linked) {
        this.draftAlert();
        this.setState({
            model: {
                ...this.state.model,
                linked,
            }
        });
    }

    updateExclusions(exclusions) {
        this.draftAlert();
        this.setState({
            model: {
                ...this.state.model,
                exclusions,
            }
        });
    }

    updateEndorsements(endorsements) {
        this.draftAlert();
        this.setState({
            model: {
                ...this.state.model,
                endorsements,
            }
        });
    }

    updateFormulas(formulas) {
        this.draftAlert();
        this.setState({
            model: {
                ...this.state.model,
                formulas,
            }
        });
    }

    updateTests(tests) {
        this.draftAlert();
        this.setState({
            model: {
                ...this.state.model,
                tests,
            }
        });
    }

    updateMLModels(ml_models) {
        this.draftAlert();
        this.setState({
            model: {
                ...this.state.model,
                ml_models,
            }
        });
    }

    render() {
        const { 
            modal, 
            model = {},
            isFetching,
            showPublish,
            showDescription,
            progress,
            isSaving,
        } = this.state;

        const {
            meta = {},
            base = {},
            factors = {},
            linked = {},
            exclusions = [],
            endorsements = [],
            formulas = {},
            schema = {},
            tests = [],
            ml_models = [],
        } = model;

        const { 
            pricing_models: {
            selected = {},
            },
            pricing_quotes_get,
            pricing_quotes_post_some,
            pricing_ml_upload_post,
            pricing_ml_upload_delete,
            lang: language,
        } = this.props;

        const quote_example = schema.example;

        const lang = getLangSource(language);

        return [
            <div className="my-3 my-md-5">
                <div className="container">

                    {isFetching && 
                        <div className="row">
                            <ProgressBar
                                message={lang.t('rating.modelDetail.retrievingModel')}
                                selectedProduct={this.props.selectedProduct}
                                progress={progress}
                            />
                        </div>
                    }

                    {!isFetching && !isEmpty(model) &&
                        <div>
                            {/* Header Model Details */}
                            <div className="page-header">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">{lang.t('rating.modelDetail.versionName')}</span>
                                            </div>
                                            <input type="text" className="form-control" onBlur={(e) => {
                                                this.updateMeta({
                                                    name: e.target.value,
                                                })
                                            }} defaultValue={meta.name} />
                                            {(selected.status === 'draft') &&
                                                <button onClick={() => {
                                                    this.setState({ showDescription: !showDescription });
                                                }} type="button" className="btn btn-sm btn-white mr-3">
                                                    {lang.t('rating.modelDetail.viewVersionDesc')}
                                                </button>
                                            }
                                        </div>
                                        {showDescription &&
                                            <div className="input-group mt-3">
                                                <textarea onBlur={(e) => {
                                                        this.updateMeta({
                                                            description: e.target.value,
                                                        })
                                                    }} 
                                                    type="text" 
                                                    placeholder={'Add a description of changes for this version'}
                                                    style={{ height:100 }} 
                                                    className="form-control" 
                                                    defaultValue={meta.description} 
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="col-auto ml-auto d-print-none mt-3">


                                        <button onClick={() => {
                                                this.cloneVersion(selected.model_reference, selected.version_reference);
                                            }}
                                            type="button" className="btn btn-info text-white mr-3">
                                                {lang.t('rating.modelDetail.cloneVersion')}
                                        </button>
                                    

                                        {(selected.status === 'draft') &&
                                            <button onClick={() => {
                                                this.saveVersion({
                                                    version_reference: selected.version_reference,
                                                });
                                            }} type="button" className="btn btn-blue mr-3" disabled={isSaving}>
                                                {isSaving ? lang.t('buttons.autosaving') : lang.t('buttons.save')}
                                            </button>
                                        }

                                        {(selected.status === 'draft' || selected.status === 'unpublished' || selected.status === 'staging') &&
                                            <button onClick={() => {
                                                this.setState({
                                                    showPublish: !this.state.showPublish,
                                                })
                                            }} type="button" className="btn btn-blue mr-3" disabled={isSaving}>
                                                {lang.t('buttons.publish')}
                                                {!showPublish && <i style={{marginLeft: 4}} className="fe fe-chevron-up"></i>}
                                                {showPublish && <i style={{marginLeft: 4}} className="fe fe-chevron-down"></i>}
                                            </button>
                                        }

                                        {(selected.status === 'published' || selected.status === 'staging') &&
                                            <button onClick={() => {
                                                this.publishVersion({
                                                    id: selected.version_reference,
                                                    status: 'unpublished',
                                                });
                                                }} type="button" className="btn btn-white mr-3">
                                                {lang.t('buttons.unpublish')}
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* Hidden Publish Block */}
                            {showPublish && !isSaving &&
                                <Publish
                                    publishVersion={this.publishVersion}
                                    version_reference={selected.version_reference}
                                    status={selected.status}
                                    lang={lang}
                                />
                            }


                            {/** Import / Export Strip **/}
                            <div className="page-header theme-dark">
                                <div className="row align-items-center mb-3 ml-3 mt-3">
                                    <div className="col-auto">
                                        {versionBadge({
                                            status: selected.status,
                                            version: selected.version,
                                            published_at: selected.published_at
                                        })}
                                    </div>
                                    <div className="col-auto">
                                        <div className="strong">{lang.t('rating.modelDetail.modelRef')} {selected.model_reference}</div>
                                        <div className="text-muted text-h5">{lang.t('rating.modelDetail.versionRef')} {selected.version_reference}</div>
                                    </div>
                                    

                                    <div className="col-auto ml-auto d-print-none">
                                        <button onClick={() => {
                                            this.openModal('import');
                                        }} type="button" data-toggle="modal" data-target="#modal-import" className="btn btn-sm btn-white mr-3">
                                            {lang.t('buttons.import')}
                                        </button>

                                        <button onClick={() => {
                                            this.export('import');
                                        }} type="button" className="btn btn-sm btn-white mr-3">
                                            {lang.t('buttons.export')}
                                        </button>

                                    </div>
                                    
                                </div>
                            </div>

                            <Base
                                meta={meta}
                                base={base}
                                updateBase={this.updateBase}
                                lang={lang}
                            />

                            <Schema
                                json={schema.json}
                                example={schema.example}
                                updateSchema={this.updateSchema}
                                type={schema.type}
                                lang={lang}
                            />

                            <Factors
                                factors={factors}
                                updateFactors={this.updateFactors}
                                deleteFactor={this.deleteFactor}
                                openModal={this.openModal}
                                lang={lang}
                            />

                            <Linked
                                linked={linked}
                                updateLinked={this.updateLinked}
                                deleteLinked={this.updateLinked}
                                openModal={this.openModal}
                                lang={lang}
                            />

                            <Exclusions
                                exclusions={exclusions}
                                updateExclusions={this.updateExclusions}
                                deleteExclusion={this.updateExclusions}
                                openModal={this.openModal}
                                lang={lang}
                            />

                            <Endorsements
                                endorsements={endorsements}
                                updateEndorsements={this.updateEndorsements}
                                deleteEndorsement={this.updateEndorsements}
                                openModal={this.openModal}
                                lang={lang}
                            />

                            {/*<Categories />*/}

                            {/* <MachineLearning
                                openModal={this.openModal}
                                models={ml_models}
                                deleteModels={this.updateMLModels}
                                pricing_ml_upload_delete={pricing_ml_upload_delete}
                                saveVersion={this.saveVersion}
                                version_reference={selected.version_reference}
                                model_reference={selected.model_reference}
                                lang={lang}
                            /> */}

                            <Formulas
                                formulas={formulas}
                                updateFormulas={this.updateFormulas}
                                deleteFormulas={this.updateFormulas}
                                openModal={this.openModal}
                                lang={lang}
                            />

                            <Tests
                                tests={tests}
                                updateTests={this.updateTests}
                                deleteTests={this.updateTests}
                                openModal={this.openModal}
                                pricing_quotes_post_some={pricing_quotes_post_some}
                                pricing_quotes_get={pricing_quotes_get}
                                version_reference={selected.version_reference}
                                model_reference={selected.model_reference}
                                lang={lang}
                            />

                            <Versions
                                version_reference={selected.version_reference}
                                versions={selected.versions}
                                selectVersion={this.selectVersion}
                                lang={lang}
                            />
                        </div>
                    }
                </div>
                <ReactNotification />
            </div>,
            <ModalFactor
                factor={factors[modal.factors.name]}
                name={modal.factors.name}
                update={this.updateFactors}
                open={modal.factors.open}
                saveVersion={this.saveVersion}
                version_reference={selected.version_reference}
                openModal={this.openModal}
                lang={lang}
                status={selected.status}
            />,
            <ModalLinked
                linked={linked[modal.linked.name]}
                name={modal.linked.name}
                update={this.updateFactors}
                open={modal.linked.open}
                saveVersion={this.saveVersion}
                version_reference={selected.version_reference}
                lang={lang}
            />,
            <ModalExclusion
                exclusions={exclusions}
                name={modal.exclusions.name}
                update={this.updateExclusions}
                open={modal.exclusions.open}
                saveVersion={this.saveVersion}
                version_reference={selected.version_reference}
                example={quote_example}
                lang={lang}
                status={selected.status}
            />,
            <ModalEndorsement
                endorsements={endorsements}
                name={modal.endorsements.name}
                update={this.updateEndorsements}
                open={modal.endorsements.open}
                saveVersion={this.saveVersion}
                version_reference={selected.version_reference}
                example={quote_example}
                lang={lang}
            />,
            <ModalFormula
                formulas={formulas}
                factors={factors}
                name={modal.formulas.name}
                update={this.updateFormulas}
                open={modal.formulas.open}
                example={quote_example}
                lang={lang}
                status={selected.status}
            />,
            <ModalImport
                model={model}
                updateModel={this.updateModel}
                open={modal.import.open}
                lang={lang}
            />,
            <ModalTests
                tests={tests}
                name={modal.tests.name}
                open={modal.tests.open}
                pricing_quotes_get={pricing_quotes_get}
                update={this.updateTests}
                openModal={this.openModal}
                lang={lang}
            />,
            <ModalTestDetails
                open={modal.test_detail.open}
                openModal={this.openModal}
                quote={modal.test_detail.quote}
                pricing={modal.test_detail.pricing}
                lang={lang}
            />,
            <ModalMachineLearning
                openModal={this.openModal}
                open={modal.machine_learning.open}
                model={modal.machine_learning.model}
                updateMLModels={this.updateMLModels}
                models={ml_models}
                pricing_ml_upload_post={pricing_ml_upload_post}
                version_reference={selected.version_reference}
                saveVersion={this.saveVersion}
                model_reference={selected.model_reference}
                lang={lang}
            />,
            ];
    }
}

export default main(ModelDetail);