import React from 'react';
import grid_alt from './grid_alt.svg';
import grid from './grid.svg';

function SpinnerBody(props) {
    const { message, selectedProduct = 'policy' } = props;
    const is_alt = selectedProduct ==='rating' ? true : false;
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <div className="card" style={{ textAlign: 'center' }}>

                        {message && <h3 style={{
                            position: 'absolute',
                            width: '100%',
                            top: '50px',
                            color: is_alt ? 'white' : 'black',
                        }}>{message}</h3>}

                        {/*
                            <div className="spinner-border text-blue" style={{ marginTop: 100, marginBottom: 100, marginLeft: 'calc(50% - 30px)' }} role="status" />
                        */}

                         <img style={{
                             marginTop: 100,
                             marginBottom: 100,
                             marginLeft: 'calc(50% - 15px)',
                             width: 30,
                         }} src={is_alt ? grid_alt : grid} />

                    </div>
                </div>
            </div>
        </div>
    );
}

export default SpinnerBody;
