import React, { Component } from 'react';

import { getLangSource } from '../../../lang/index';
import main from '../../../hoc/main';

// Core Design
import SpinnerBody from '../../../components/loaders/SpinnerBody';
import Empty from '../../../components/empty/Results';
import DropDown from '../../../components/forms/Dropdown';

// Service Design
import Table from '../components/Index/QuotesTable';
import QuotesStatsChart from '../components/Index/QuotesStatsChart';
import BigChart from '../components/Analytics/BigChart';

import {
    analyticsDataFormat,
} from '../utils/format'; 

import { first, get } from 'lodash';
import moment from 'moment';

class Quotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            results: [],
        }
        this.getAnalytics = this.getAnalytics.bind(this);
    }

    componentDidMount() {
        this.props.pricing_models_get_all({})
            .then((data) => {
                const models = get(data, 'payload.data', []).map(m => m.model_reference);
                if (!first(models)) return;
                this.getAnalytics({
                    id: first(models), 
                    start_at: moment().subtract(1 ,'hour').format('X'),
                });
            });
    }

    getAnalytics({
        id,
        source = null,
        is_valid_only = 'false',
    }) {
        if (source === 'all') source = null;
        this.props.pricing_quotes_get_all({
            limit: 100,
            model_reference: id,
            source,
        }).then((data) => {
            if (!id) return;
            this.props.pricing_analytics_post({ data: {
                model_reference: id, 
                start_at: moment().subtract(1 ,'hour').format('X'),
                source,
                is_valid_only: (is_valid_only === 'false' ? false : true),
            }});
            const results = get(data, 'payload.data', []);
            this.setState({
                results,
            })
        })
    }

    render() {
        const lang = getLangSource(this.props.lang);

        const { results } = this.state;
        const selected = get(this.props, 'pricing_analytics.selected', {});
        const isFetchingAnalytics = get(this.props, 'pricing_analytics.isFetching', true);
        const isFetchingQuotes = get(this.props, 'pricing_quotes.isFetching', true);
        const isFetching = (isFetchingQuotes || isFetchingAnalytics);

        const { range, is_valid_only, period, source } = get(selected, 'analytics', {});

        const {
            analytics_sorted,
            chart_timestamps,
            analytics_sources,
            chart_annotations,
            chart_format,
            models,
            analytics_models,
        } = analyticsDataFormat({
            analytics: get(this.props, 'pricing_analytics.selected', {}), 
            models: get(this.props, 'pricing_models.data', []),
            lang: get(this.props, 'lang', '')
        });

        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    {isFetching && 
                        <div className="row">
                            <SpinnerBody 
                                message={lang.t('rating.home.isBuilding')}
                                selectedProduct={this.props.selectedProduct}
                            />
                        </div>
                    }

                    {!isFetching && 
                      <div className="row mb-3">
                        <div class="col-sm-12 col-lg-9">
                            <div class="form-label">{lang.t('rating.home.model')}</div>
                                <DropDown
                                    ref='model_reference'
                                    label='model_reference'
                                    value={selected.model_reference}
                                    update={(data) => {
                                        this.getAnalytics({
                                            id: data.model_reference,
                                            is_valid_only,
                                            range,
                                            source,
                                        })
                                    }}
                                    labelMap={analytics_models}
                                    items={[...models.map(m => m.model_reference)]}
                                />
                            </div>
                            {/*
                            <div class="col-sm-12 col-lg-3">
                                <div class="form-label">Source:</div>
                                <DropDown
                                    ref='source'
                                    label='source'
                                    value={selected.source}
                                    update={(data) => {
                                        this.getAnalytics({
                                            id: selected.model_reference,
                                            range,
                                            is_valid_only,
                                            source: data.source,
                                        })
                                    }}
                                    items={['all', ...analytics_sources]}
                                />
                            </div>
                            */}
                            <div class="col-sm-12 col-lg-3">
                                <div class="form-label">{lang.t('rating.home.isValid')}</div>
                                <DropDown
                                    ref='is_valid_only'
                                    label='is_valid_only'
                                    value={(is_valid_only).toString()}
                                    update={(data) => {
                                        this.getAnalytics({
                                            id: selected.model_reference,
                                            range,
                                            source,
                                            is_valid_only: data.is_valid_only,
                                        })
                                    }}
                                    items={['true', 'false']}
                                />
                            </div>
                    </div>  
                    }

                    {!isFetching && 
                        <div className="row">
                            <div className="col-sm-9 col-lg-9">
                                <div className="card">
                                    <div className="ml-3 mr-3 mt-3">
                                        <BigChart 
                                            series={[
                                                {
                                                    name: 'Quotes',
                                                    data: analytics_sorted.map(a => a.quotes),
                                                },
                                                {
                                                    name: 'Valid',
                                                    data: analytics_sorted.map(a => a.is_valid),
                                                }
                                            ]}
                                            annotations={chart_annotations}
                                            labels={chart_timestamps.map(t => {
                                                return moment(t, 'X').toISOString();
                                            })}
                                            format={chart_format}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-lg-3">
                                <QuotesStatsChart 
                                    keyvalue={'quotability'}
                                    data={analytics_sorted}
                                    format={chart_format}
                                    lang={lang}
                                />
                                <QuotesStatsChart 
                                    keyvalue={'yearly_premium_estimate'}
                                    data={analytics_sorted}
                                    format={chart_format}
                                    lang={lang}
                                />
                            </div>
                        </div>
                    }

                    <div className="row">

                        {/* Empty State */}
                        {!isFetching && results.length === 0
                                && <Empty label="quotes" lang={lang} />}


                        {/* Table */}
                        {!isFetching && results.length > 0
                            && (
                            <div className="col-sm-12 col-lg-12">
                                    <Table
                                        data={results}
                                        pages={10}
                                        lang={lang}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default main(Quotes);