import example from '../../../../utils/__mocks__/quote.json';

const json = {
    "type": "object",
    "properties": {
      "quote_ref": {
        "type": "string",
        "description": "Public reference for a quote. It is a UUID.",
        "example": "36c575d2-b6b9-44ed-9c75-3d705b987ec1",
        "mock": "custom.uuid"
      },
      "meta": {
        "type": "object",
        "properties": {
          "channel": {
            "type": "string",
            "description": "The channel that the quote came through. This could be direct, or a broker, or a price comparison website.",
            "example": "PCW"
          },
          "source": {
            "type": "string",
            "description": "The exact channel that the quote came through. This could be the name of a broker, or a name of a price comparison website.",
            "example": "GoCompare"
          }
        },
        "required": []
      },
      "policy": {
        "type": "object",
        "properties": {
          "voluntary_excess": {
            "type": "number",
            "description": "The compulsory excess applied to a policy",
            "example": 100
          },
          "address": {
            "type": "object",
            "description": "address",
            "properties": {
              "line_1": {
                "type": "string",
                "description": "First line of address",
                "maxLength": 40,
                "minLength": 1,
                "example": "Flat 1, 11 The Street"
              },
              "line_2": {
                "type": "string",
                "description": "Second line of address",
                "example": "Little Hampton"
              },
              "line_3": {
                "type": "string",
                "description": "Third line of address",
                "example": "Burton-on-the-water"
              },
              "county": {
                "type": "string",
                "description": "County",
                "example": "Avon"
              },
              "city": {
                "type": "string",
                "description": "Town/City",
                "example": "Stroud"
              },
              "country": {
                "type": "string",
                "description": "Address country",
                "example": "GB"
              },
              "postcode": {
                "type": "string",
                "description": "Postcode or Zip Code",
                "example": "W53TR"
              }
            },
            "required": [
              "postcode",
              "line_1",
              "city",
              "country"
            ]
          },
          "inception_date": {
            "type": "number",
            "description": "The policy start timestamp",
            "example": 1599567165
          }
        },
        "required": [
          "address",
          "inception_date",
          "voluntary_excess"
        ]
      },
      "proposer": {
        "type": "object",
        "properties": {
          "title": {
            "type": "string",
            "description": "Title of driver",
            "example": "003"
          },
          "first_names": {
            "type": "string",
            "description": "First names of the driver",
            "example": "Dave",
            "mock": ""
          },
          "last_names": {
            "type": "string",
            "description": "Last names of the driver",
            "example": "Jones",
            "mock": ""
          },
          "email": {
            "type": "string",
            "description": "Email address of the user",
            "example": "dave@jones.com",
            "mock": ""
          },
          "mobile_number": {
            "type": "string",
            "description": "Mobile number of the user",
            "example": "+447999000011"
          },
          "children": {
            "type": "number",
            "minimum": 0,
            "description": "Number of children under 16",
            "example": 1
          },
          "has_medical_conditions": {
            "type": "boolean",
            "description": "Does driver have medical conditions?",
            "example": true
          },
          "has_informed_dvla_medical_conditions": {
            "type": "boolean",
            "description": "Has driver informed DVLA of medical conditions?",
            "example": false
          },
          "ncd": {
            "type": "number",
            "description": "Number of years no claims",
            "minimum": 0,
            "example": 1
          },
          "dob": {
            "type": "string",
            "description": "Date of birth of driver",
            "example": "1980-12-31"
          },
          "licence_type": {
            "type": "string",
            "description": "Licence type of driver",
            "example": "F"
          },
          "employment": {
            "type": "string",
            "description": "Employment status of driver",
            "example": "E"
          },
          "occupation": {
            "type": "string",
            "description": "Occupation code of driver",
            "example": "51D"
          },
          "business": {
            "type": "string",
            "description": "Business category code of driver",
            "example": "077"
          },
          "licence_issued_date": {
            "type": "string",
            "description": "Licence issued date for the driver",
            "example": "2000-12-31"
          },
          "residency_date": {
            "type": "string",
            "description": "Driver lived in UK since",
            "example": "2000-12-31"
          },
          "has_criminal_conviction": {
            "type": "boolean",
            "description": "Has driver got outstanding criminal convictions?",
            "example": false
          },
          "convictions": {
            "type": "array",
            "description": "Convictions by the driver",
            "items": {
              "type": "object",
              "properties": {
                "code": {
                  "type": "string",
                  "description": "Conviction code",
                  "example": "AC10"
                },
                "date": {
                  "type": "string",
                  "description": "Conviction date",
                  "example": "2018-01-01"
                },
                "points": {
                  "type": "number",
                  "description": "Conviction points received",
                  "minimum": 0,
                  "maximum": 12,
                  "example": 3
                },
                "ban": {
                  "type": "number",
                  "description": "Conviction number of months of ban",
                  "minimum": 0,
                  "maximum": 1200,
                  "example": 0
                }
              }
            },
            "required": [
              "code",
              "date",
              "points",
              "ban"
            ]
          },
          "claims": {
            "type": "array",
            "description": "Claims by the driver",
            "items": {
              "type": "object",
              "properties": {
                "code": {
                  "type": "string",
                  "description": "Claim code",
                  "example": "A"
                },
                "date": {
                  "type": "string",
                  "description": "The reported date of a claim",
                  "example": "2016-01-01"
                },
                "at_fault": {
                  "type": "boolean",
                  "description": "Whether a claim is at fault",
                  "example": false
                },
                "ncd_lost": {
                  "type": "boolean",
                  "description": "If the claim resulted in no claims discount lost",
                  "example": false
                }
              }
            },
            "required": [
              "code",
              "date",
              "at_fault",
              "ncd_lost"
            ]
          },
          "kyc": {
            "type": "object",
            "description": "Know your customer checks on the driver",
            "properties": {
              "id_score": {
                "type": "number",
                "description": "An id score for the driver",
                "example": 150
              },
              "credit_score": {
                "type": "number",
                "description": "An credit score for the driver",
                "example": 150
              }
            },
            "required": []
          }
        },
        "required": [
          "title",
          "first_names",
          "last_names",
          "dob",
          "licence_type",
          "licence_issued_date"
        ]
      },
      "vehicle": {
        "type": "object",
        "description": "vehicle",
        "properties": {
          "reg": {
            "type": "string",
            "description": "The registration of the policy vehicle",
            "example": "WO123XX",
            "mock": "custom.reg"
          },
          "type": {
            "type": "string",
            "description": "Vehicle body type",
            "example": "01"
          },
          "make": {
            "type": "string",
            "description": "Vehicle manufacturer",
            "example": "Tesla"
          },
          "model": {
            "type": "string",
            "description": "Vehicle model name",
            "example": "S"
          },
          "colour": {
            "type": "string",
            "description": "Vehicle colour",
            "example": "black"
          },
          "group_rating_50": {
            "type": "number",
            "description": "Vehicle group 50 rating",
            "example": 21
          },
          "group_rating_20": {
            "type": "number",
            "description": "Vehicle group 20 rating",
            "example": 10
          },
          "abi_code": {
            "type": "string",
            "description": "Vehicle ABI code",
            "example": "01001000"
          },
          "engine": {
            "type": "number",
            "description": "Vehicle engine capacity (cc)",
            "example": 1650
          },
          "fuel": {
            "type": "string",
            "description": "Vehicle fuel code",
            "example": "001"
          },
          "owner": {
            "type": "string",
            "description": "Owner of vehicle code",
            "example": "1"
          },
          "keeper": {
            "type": "string",
            "description": "Registered keeper of vehicle code",
            "example": "1"
          },
          "estimated_yearly_mileage": {
            "type": "number",
            "description": "Vehicle estimated mileage over the year",
            "example": 10000
          },
          "purchase_date": {
            "type": "string",
            "description": "Vehicle purchase date",
            "example": "2016-01-01"
          },
          "manufacture_date": {
            "type": "string",
            "description": "Manufacture date",
            "example": "2016-01-01"
          },
          "current_value": {
            "type": "number",
            "description": "Vehicle current value",
            "example": 1000
          },
          "is_rhd": {
            "type": "boolean",
            "description": "Is this vehicle right-hand drive?",
            "example": true
          },
          "seats": {
            "type": "number",
            "description": "Vehicle seats",
            "minimum": 1,
            "example": 5
          },
          "is_parked_home": {
            "type": "boolean",
            "description": "Is this vehicle parked at home",
            "example": true
          },
          "is_import": {
            "type": "boolean",
            "description": "Is this vehicle imported?",
            "example": true
          },
          "modifications": {
            "type": "array",
            "items": {
              "type": "string",
              "description": "Vehicle modification code",
              "example": "1"
            }
          }
        },
        "required": [
          "make",
          "model",
          "abi_code",
          "fuel",
          "owner",
          "keeper",
          "manufacture_date",
          "reg",
          "type"
        ]
      },
      "additional_drivers": {
        "type": "array",
        "items": {
          "type": "object",
          "properties": {
            "relationship": {
              "type": "string",
              "description": "Drivers relationship to proposer",
              "example": "P"
            },
            "title": {
              "type": "string",
              "description": "Title of driver",
              "example": "003"
            },
            "first_names": {
              "type": "string",
              "description": "First names of the driver",
              "example": "Dave",
              "mock": ""
            },
            "last_names": {
              "type": "string",
              "description": "Last names of the driver",
              "example": "Jones",
              "mock": ""
            },
            "email": {
              "type": "string",
              "description": "Email address of the user",
              "example": "dave@jones.com",
              "mock": ""
            },
            "mobile_number": {
              "type": "string",
              "description": "Mobile number of the user",
              "example": "+447999000011"
            },
            "children": {
              "type": "number",
              "minimum": 0,
              "description": "Number of children under 16",
              "example": 1
            },
            "has_medical_conditions": {
              "type": "boolean",
              "description": "Does driver have medical conditions?",
              "example": true
            },
            "has_informed_dvla_medical_conditions": {
              "type": "boolean",
              "description": "Has driver informed DVLA of medical conditions?",
              "example": false
            },
            "ncd": {
              "type": "number",
              "description": "Number of years no claims",
              "minimum": 0,
              "example": 1
            },
            "dob": {
              "type": "string",
              "description": "Date of birth of driver",
              "example": "1980-12-31"
            },
            "licence_type": {
              "type": "string",
              "description": "Licence type of driver",
              "example": "F"
            },
            "employment": {
              "type": "string",
              "description": "Employment status of driver",
              "example": "E"
            },
            "occupation": {
              "type": "string",
              "description": "Occupation code of driver",
              "example": "51D"
            },
            "business": {
              "type": "string",
              "description": "Business category code of driver",
              "example": "077"
            },
            "licence_issued_date": {
              "type": "string",
              "description": "Licence issued date for the driver",
              "example": "2000-12-31"
            },
            "residency_date": {
              "type": "string",
              "description": "Driver lived in UK since",
              "example": "2000-12-31"
            },
            "has_criminal_conviction": {
              "type": "boolean",
              "description": "Has driver got outstanding criminal convictions?",
              "example": false
            },
            "convictions": {
              "type": "array",
              "description": "Convictions by the driver",
              "items": {
                "type": "object",
                "properties": {
                  "code": {
                    "type": "string",
                    "description": "Conviction code",
                    "example": "AC10"
                  },
                  "date": {
                    "type": "string",
                    "description": "Conviction date",
                    "example": "2018-01-01"
                  },
                  "points": {
                    "type": "number",
                    "description": "Conviction points received",
                    "minimum": 0,
                    "maximum": 12,
                    "example": 3
                  },
                  "ban": {
                    "type": "number",
                    "description": "Conviction number of months of ban",
                    "minimum": 0,
                    "maximum": 1200,
                    "example": 0
                  }
                }
              },
              "required": [
                "code",
                "date",
                "points",
                "ban"
              ]
            },
            "claims": {
              "type": "array",
              "description": "Claims by the driver",
              "items": {
                "type": "object",
                "properties": {
                  "code": {
                    "type": "string",
                    "description": "Claim code",
                    "example": "A"
                  },
                  "date": {
                    "type": "string",
                    "description": "The reported date of a claim",
                    "example": "2016-01-01"
                  },
                  "at_fault": {
                    "type": "boolean",
                    "description": "Whether a claim is at fault",
                    "example": false
                  },
                  "ncd_lost": {
                    "type": "boolean",
                    "description": "If the claim resulted in no claims discount lost",
                    "example": false
                  }
                }
              },
              "required": [
                "code",
                "date",
                "at_fault",
                "ncd_lost"
              ]
            },
            "kyc": {
              "type": "object",
              "description": "Know your customer checks on the driver",
              "properties": {
                "id_score": {
                  "type": "number",
                  "description": "An id score for the driver",
                  "example": 150
                },
                "credit_score": {
                  "type": "number",
                  "description": "An credit score for the driver",
                  "example": 150
                }
              },
              "required": []
            }
          }
        },
        "required": [
          "title",
          "first_names",
          "last_names",
          "dob",
          "licence_type",
          "licence_issued_date"
        ]
      },
      "custom": {
        "type": "object",
        "properties": {},
        "required": []
      }
    },
    "required": [
      "proposer",
      "vehicle",
      "policy"
    ]
};

export default {
    json,
    example,
};