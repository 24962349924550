import React, { Component } from 'react';
import moment from 'moment';
import Input from '../../../../components/forms/Input';
import AlertMessage from '../../../../components/alerts/AlertMessage'
import Textarea from '../../../../components/forms/Textarea';

export default class FormClaimUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            claimType: 'Accident',
            claimLatitude: '',
            claimLongitude: '',
            claimDescription: '',
            reportedDate: '',
            lossDate: '',
            driverName: '',
            driverPhone: '',
            driverHouse: '',
            driverPostCode: '',
            driverInjuries: '',
            driverReg: '',
            driverPassengers: '',
            driverDamage: '',
            thirdPartyName: '',
            thirdPartyPhone: '',
            thirdPartyHouse: '',
            thirdPartyPostCode: '',
            thirdPartyInjuries: '',
            thirdPartyReg: '',
            thirdPartyPassengers: '',
            thirdPartyDamage: '',
            witnessName: '',
            witnessPhone: '',
            witnessHouse: '',
            witnessPostCode: ''
        };
        this.addClaim = this.addClaim.bind(this);
        this.setState = this.setState.bind(this);
    }

    async addClaim() {
        const { 
            claimType,
            claimLatitude,
            claimLongitude,
            claimDescription,
            reportedDate,
            lossDate,
            driverName,
            driverPhone,
            driverHouse,
            driverPostCode,
            driverInjuries,
            driverReg,
            driverPassengers,
            driverDamage,
            thirdPartyName,
            thirdPartyPhone,
            thirdPartyHouse,
            thirdPartyPostCode,
            thirdPartyDamage,
            thirdPartyReg,
            thirdPartyPassengers,
            thirdPartyInjuries,
            witnessName,
            witnessPhone,
            witnessHouse,
            witnessPostCode
        } = this.state;

        const {
            policy_reference,
            policys_claims_post,
            policys_claims_get_all,
            updateStage
        } = this.props;

        const driver = {
            name: driverName,
            phone_number: parseInt(driverPhone),
            house_number: driverHouse,
            post_code: driverPostCode,
            injuries: driverInjuries,
            vehicle: {
                reg: driverReg,
                no_of_passengers: driverPassengers,
                damage: driverDamage
            }
        };

        const third_parties = [{
            name: thirdPartyName,
            phone_number: parseInt(thirdPartyPhone),
            house_number: thirdPartyHouse,
            post_code: thirdPartyPostCode,
            injuries: thirdPartyInjuries,
            vehicle: {
                reg: thirdPartyReg,
                no_of_passengers: thirdPartyPassengers,
                damage: thirdPartyDamage
            }
        }];

        const witnesses = [{
            name: witnessName,
            phone_number: parseInt(witnessPhone),
            house_number: witnessHouse,
            post_code: witnessPostCode,
        }];

        const data = {
            policy_reference,
            claim_type: claimType,
            claim_latitude: parseFloat(claimLatitude),
            claim_longitude: parseFloat(claimLongitude),
            claim_description: claimDescription,
            reported_date: parseInt(reportedDate),
            loss_date: parseInt(lossDate),
            detail: {
                driver,
                third_parties,
                witnesses
            }
        };

        await policys_claims_post({
            id: policy_reference,
            data
        });

        updateStage('addClaimSuccess');

        await policys_claims_get_all({
            policy_reference
      });
    }

    isCoordinate = value => /^[0-9-.]*$/.test(value);
    isText = value => /^[A-Za-z\s]*$/.test(value);
    isNum = value => /^[0-9\s]*$/.test(value);
    isTextAndNum = value => /^[A-Za-z0-9.\s]*$/.test(value);

    render() {
        const { 
            claimType,
            claimLatitude,
            claimLongitude,
            claimDescription,
            driverName,
            driverPhone,
            driverHouse,
            driverPostCode,
            driverInjuries,
            driverReg,
            driverPassengers,
            driverDamage,
            thirdPartyName,
            thirdPartyPhone,
            thirdPartyHouse,
            thirdPartyPostCode,
            thirdPartyDamage,
            thirdPartyReg,
            thirdPartyPassengers,
            thirdPartyInjuries,
            witnessName,
            witnessPhone,
            witnessHouse,
            witnessPostCode,
            reportedDate,
            lossDate,
        } = this.state;

        const lang = this.props.lang;

        let isLossDateValid;
        if(reportedDate && lossDate) {
            isLossDateValid = lossDate <= reportedDate ? true : false;
        }

        return (
            <>
            <div className='modal-body' style={{height: "70vh"}}>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.claimType')}</span>
                    </div>
                    <select className="form-control" value={claimType} id="category" onChange={(e) => this.setState}>
                        <option value="Accident">{lang.t('policy.claims.accident')}</option>
                        <option value="Fire">{lang.t('policy.claims.fire')}</option>
                        <option value="Theft">{lang.t('policy.claims.theft')}</option>
                        <option value="Other">{lang.t('policy.claims.other')}</option>
                    </select>
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.claimLat')}</span>
                    </div>
                    <Input
                        value={claimLatitude}
                        label={'claimLatitude'}
                        update={this.setState}
                        validation={this.isCoordinate}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.claimLong')}</span>
                    </div>
                    <Input
                        value={claimLongitude}
                        label={'claimLongitude'}
                        update={this.setState}
                        validation={this.isCoordinate}
                    />
                </div>
                <Textarea 
                    title={lang.t('policy.claims.claimDesc')}
                    value={claimDescription}
                    validation={this.isTextAndNum}
                    disabled={false}
                    update={this.setState}
                    label={'claimDescription'}
                />
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.reportedDate')}</span>
                    </div>
                    <input
                        type='date'
                        className='form-control'
                        onChange={(e) => this.setState({ 
                            reportedDate: moment(e.target.value, "YYYY-MM-DD").format('X')
                        })}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.lossDate')}</span>
                    </div>
                    <input
                        type='date'
                        className='form-control'
                        onChange={(e) => this.setState({ 
                            lossDate: moment(e.target.value, "YYYY-MM-DD").format('X')
                        })}
                    />
                </div>
                {lossDate && reportedDate && !isLossDateValid && <AlertMessage icon='info' message='Loss date must be on or before reported date'/>}
                <hr></hr>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.driver.name')}</span>
                    </div>
                    <Input
                        value={driverName}
                        label={'driverName'}
                        update={this.setState}
                        validation={this.isText}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.driver.phone')}</span>
                    </div>
                    <Input
                        value={driverPhone}
                        label={'driverPhone'}
                        update={this.setState}
                        validation={this.isNum}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.driver.house')}</span>
                    </div>
                    <Input
                        value={driverHouse}
                        label={'driverHouse'}
                        update={this.setState}
                        validation={this.isTextAndNum}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.driver.postcode')}</span>
                    </div>
                    <Input
                        value={driverPostCode}
                        label={'driverPostCode'}
                        update={this.setState}
                        validation={this.isTextAndNum}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.driver.injuries')}</span>
                    </div>
                    <Input
                        value={driverInjuries}
                        label={'driverInjuries'}
                        update={this.setState}
                        validation={this.isText}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.driver.reg')}</span>
                    </div>
                    <Input
                        value={driverReg}
                        label={'driverReg'}
                        update={this.setState}
                        validation={this.isTextAndNum}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.driver.passengers')}</span>
                    </div>
                    <Input
                        value={driverPassengers}
                        label={'driverPassengers'}
                        update={this.setState}
                        validation={this.isNum}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.driver.damage')}</span>
                    </div>
                    <Input
                        value={driverDamage}
                        label={'driverDamage'}
                        update={this.setState}
                        validation={this.isText}
                    />
                </div>
                <hr></hr>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.thirdParty.name')}</span>
                    </div>
                    <Input
                        value={thirdPartyName}
                        label={'thirdPartyName'}
                        update={this.setState}
                        validation={this.isText}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.thirdParty.phone')}</span>
                    </div>
                    <Input
                        value={thirdPartyPhone}
                        label={'thirdPartyPhone'}
                        update={this.setState}
                        validation={this.isNum}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.thirdParty.house')}</span>
                    </div>
                    <Input
                        value={thirdPartyHouse}
                        label={'thirdPartyHouse'}
                        update={this.setState}
                        validation={this.isTextAndNum}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.thirdParty.postcode')}</span>
                    </div>
                    <Input
                        value={thirdPartyPostCode}
                        label={'thirdPartyPostCode'}
                        update={this.setState}
                        validation={this.isTextAndNum}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.thirdParty.injuries')}</span>
                    </div>
                    <Input
                        value={thirdPartyInjuries}
                        label={'thirdPartyInjuries'}
                        update={this.setState}
                        validation={this.isText}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.thirdParty.reg')}</span>
                    </div>
                    <Input
                        value={thirdPartyReg}
                        label={'thirdPartyReg'}
                        update={this.setState}
                        validation={this.isTextAndNum}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.thirdParty.passengers')}</span>
                    </div>
                    <Input
                        value={thirdPartyPassengers}
                        label={'thirdPartyPassengers'}
                        update={this.setState}
                        validation={this.isNum}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.thirdParty.damage')}</span>
                    </div>
                    <Input
                        value={thirdPartyDamage}
                        label={'thirdPartyDamage'}
                        update={this.setState}
                        validation={this.isText}
                    />
                </div>
                <hr></hr>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.witness.name')}</span>
                    </div>
                    <Input
                        value={witnessName}
                        label={'witnessName'}
                        update={this.setState}
                        validation={this.isText}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.witness.phone')}</span>
                    </div>
                    <Input
                        value={witnessPhone}
                        label={'witnessPhone'}
                        update={this.setState}
                        validation={this.isNum}
                    />
                </div>
                <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.witness.house')}</span>
                    </div>
                    <Input
                        value={witnessHouse}
                        label={'witnessHouse'}
                        update={this.setState}
                        validation={this.isTextAndNum}
                    />
                </div>
                <div className='input-group'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' style={{width: '140px'}}>{lang.t('policy.claims.witness.postcode')}</span>
                    </div>
                    <Input
                        value={witnessPostCode}
                        label={'witnessPostCode'}
                        update={this.setState}
                        validation={this.isTextAndNum}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addClaim}>{lang.t('buttons.add')}</button>
            </div>
        </>
        )
    }
}
