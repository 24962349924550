import React, { Component } from 'react';
import { reject } from 'lodash';

import MLModelsTable from './MLModelsTable';

class MachineLearning extends Component {
    constructor(props) {
        super(props);
        this.open = this.open.bind(this);
        this.deleteModel = this.deleteModel.bind(this);
        this.editModel = this.editModel.bind(this);
    }

    open() {
        this.props.openModal({
            machine_learning: {
                open: true,
                model: {},
            }
        })
    }

    async deleteModel(model) {
        const { library, ml_model_reference, file_type } = model;
        const { pricing_ml_upload_delete, deleteModels, saveVersion, version_reference, model_reference } = this.props;

        try {

            await pricing_ml_upload_delete({
                id: encodeURIComponent(`${model_reference}/${library}/${ml_model_reference}.${file_type}`) // Not using 'path' as 'path' can be stored with upper case
            });

            // Delete model from local state

            deleteModels(reject(this.props.models, e => e.ml_model_reference === ml_model_reference));

            saveVersion({
                version_reference,
                reload: true,
            });

            
        } catch (err) {
            console.log(err);
        }
    }

    editModel(model) {
        this.props.openModal({
            machine_learning: {
                open: true,
                model,
            }
        });
    }

    render() {
        const { models, lang } = this.props;
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">{lang.t('rating.components.machineLearning.title')}
                                <button href="#"  style={{
                                    position: 'absolute',
                                    top: 14,
                                    right: 20,
                                }} onClick={this.open}className="btn btn-primary btn-sm">{lang.t('rating.components.machineLearning.import')}</button>
                                
                            </h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        {lang.t('rating.components.machineLearning.machineLearningInfo')}
                                    </div>
                                    <MLModelsTable
                                        data={models}
                                        delete={this.deleteModel}
                                        edit={this.editModel}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MachineLearning;