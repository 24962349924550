import React, { Component } from 'react';
import moment from 'moment';
import SuperTable from '../../../../components/tables/Super';
import {
    Link
} from 'react-router-dom';

class QuotesTable extends Component {
    constructor(props) {
        super(props);
    }
    
    badge({ exclusion_count}) {
        if (exclusion_count > 0) return (<div className='badge bg-red'>INVALID</div>)
        return (
            <div className='badge bg-green'>VALID</div>
        )
    }

    render() {
        const { data = [], deleteQuote, lang } = this.props;

        const headers = [
            {
                label: lang.t('tableHeaders.ref'),
                name: 'quote_reference',
                search: true
            },
            {
                label: lang.t('tableHeaders.postcode'),
                name: 'postcode_anon',
            },
            {
                label: lang.t('tableHeaders.reg'),
                name: 'reg_anon',
            },
            {
                label: lang.t('tableHeaders.valid'),
                name: 'is_valid',
            },
            {
                label: lang.t('tableHeaders.source'),
                name: 'source',
            },
            {
                label: `${lang.t('tableHeaders.source')} (${lang.t('distanceUnit.miles')})`,
                name: 'estimated_distance',
            },
            {
                label: lang.t('tableHeaders.premium'),
                name: 'yearly_premium_estimate',
            },
            {
                label: lang.t('tableHeaders.created'),
                name: 'created_at',
            },
            {
                label: '',
                name: '',
            },
        ];

        const Cells = (index, d) => {
            const is_valid = (d.exclusion_count === 0);
            return (
                <tr key={`factors-${index}`}>
                    <td data-label="Ref">
                        <div className="text-h5">{d.quote_reference}</div>
                    </td>
                    <td data-label="Postcode">
                        <div className="text-h5">{d.postcode_anon}</div>
                    </td>
                    <td data-label="Postcode">
                        <div className="text-h5">{d.reg_anon}</div>
                    </td>
                    <td data-label="Valid">
                        <div className="text-h5">
                            {this.badge(d)}
                        </div>
                    </td>
                    <td data-label="Source">
                        <div className="text-h5">{d.source}</div>
                    </td>
                    <td data-label="Distance">
                        <div className="text-h5">{d.estimated_distance}</div>
                    </td>
                    <td data-label="Premium">
                        <div className="text-h5">{d.yearly_premium_estimate}</div>
                    </td>
                    <td data-label="Created">
                        <div className="text-h5">{moment(d.created_at).format('DD/MM/YY - HH:mm:ss')}</div>
                    </td>
                    <td>
                        <div className="btn-list flex-nowrap">
                            <Link
                                to={`/rating-logs/quotes/${d.quote_reference}`}
                                className="btn btn-sm btn-primary"
                            >{lang.t('rating.logs.view')}</Link>
                        </div>
                    </td>
                </tr>
            );
        }

        return (
            <SuperTable 
                data={data}
                headers={headers}
                Cells={Cells}
                height={600}
                search={this.props.search}
                lang={lang}
            />
        );
    }
}

export default QuotesTable;
