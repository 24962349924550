import React, { Component } from 'react';
import { get } from 'lodash';
import numeral from 'numeral';

import { getLangSource } from '../../../../lang';
import main from '../../../../hoc/main';

//Core Design
import VirtualisedTable from '../../../../components/tables/Virtualised';
import OverlayLoader from '../../../../components/loaders/OverlayLoader';
import PageHeader from '../../../../components/layout/PageHeader';

import Banner from '../../components/lossratio/Banner';
import AreaStep from '../../components/charts/AreaStep';
import BarTable from '../../components/charts/BarTable';
import DropDownPivot from '../../components/lossratio/DropDownPivot';
import DropDownFormat from '../../components/lossratio/DropDownFormat';
import DropDownReports from '../../components/lossratio/DropDownReports';
import DropDownRisks from '../../components/lossratio/DropDownRisks';

const reportSelected = require('./reports.json').reports;

function capitilise(str = '') {
    const items = str.split('.');
    function cap(str) {
        return str.charAt(0).toUpperCase() + str.substr(1, str.length);
    }
    if (items[1]) return cap(items[1]) + ' - ';
    return cap(items[0]) + ' - ';
}

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            risks: 'reports',
            pivot: 'all',
            format: 'accrued',
            period: 'normal',
        };
        this.selectPivot = this.selectPivot.bind(this);
        this.selectFormat = this.selectFormat.bind(this);
        this.selectPeriod = this.selectPeriod.bind(this);
        this.selectReport = this.selectReport.bind(this);
        this.selectRisks = this.selectRisks.bind(this);
        this.postReport = this.postReport.bind(this);
    }

    selectPivot(pivot) {
        this.setState({
            pivot,
        })
    }

    selectFormat(format) {
        this.setState({
            format,
        })
    }

    selectPeriod(period) {
        this.setState({
            period,
        })
    }

    selectRisks(risks) {
        this.setState({
            risks,
        })
    }

    selectReport(id) {
        this.props.claims_reports_get({ id })
        this.setState({
            pivot: 'all',
            format: 'accrued',
            period: 'normal',
        });        
    }

    postReport(e) {
        e.preventDefault();
        this.props.claims_jobs_post({
            data : {
                process: 'reports',
            },
        });      
    }

    render() {
        const lang = getLangSource(this.props.lang);
        const { pivot, risks, format } = this.state;

        const reports = [{
            id: 1,
            created_at: new Date().toISOString()
        }]
        const pivoted = get(reportSelected, `${pivot}`, {});
        const { normal, rolling } = get(pivoted.monthly, `${format}`, {});
        const isFetching = false;
        
        let colour = 'blue';
        if (risks === 'best_reports') colour = 'green';
        if (risks === 'worst_reports') colour = 'pink';

        return (
            <div class="my-3 my-md-5">
                {isFetching && <OverlayLoader />}
                {!isFetching && normal &&
                    <div class="container">
                        <PageHeader
                            section={'Claims'}
                            page={'Loss Ratio and Frequency'}
                        />
                        <div class="row">
                            <div class="col-sm-12 col-lg-3">
                                <div className="row">
                                    <div class="col-sm-12 col-lg-12 mb-3">
                                        <div class="form-label">{lang.tc(`claims.reports.select_report`)}:</div>
                                        <DropDownReports 
                                            reports={reports}
                                            selectReport={this.selectReport}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-3">
                                <div className="row">
                                    <div class="col-sm-12 col-lg-12 mb-3">
                                        <div class="form-label">{lang.tc(`claims.reports.select_pivot`)}:</div>
                                        <DropDownPivot 
                                            report={reportSelected}
                                            selectPivot={this.selectPivot}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-3">
                                <div className="row">
                                    <div class="col-sm-12 col-lg-12 mb-3">
                                        <div class="form-label">{lang.tc(`claims.reports.select_format`)}:</div>
                                        <DropDownFormat
                                            selectFormat={this.selectFormat}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-3">
                                <div className="row">
                                    <div class="col-sm-12 col-lg-12 mb-3">
                                        <div class="form-label">{lang.tc(`claims.reports.select_risks`)}:</div>
                                        <DropDownRisks 
                                            selectRisks={this.selectRisks}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Banner 
                            totalRiskStats={pivoted.summary}
                            colour={colour}
                            lang={lang}
                        />
                        <div class="row">
                            <div class="col-lg-6">
                                <div className="card">
                                    <AreaStep
                                        title={`${capitilise(pivot)} ${lang.tc(`claims.reports.loss_ratio`)} (${lang.t(`general.monthly`)})`}
                                        colour={colour}
                                        data={[
                                            {
                                                label: `${capitilise(pivot)} ${lang.tc(`claims.reports.loss_ratio`)}`,
                                                values: normal.map(m => m.LRInc),
                                            },
                                        ]}
                                        gridlines={[20, 40, 60, 80, 100]}
                                        suffix='%'
                                        categories={normal.map(m => m.month)}
                                        height={180}
                                    />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div className="card">
                                    <AreaStep
                                        title={`${capitilise(pivot)} ${lang.tc(`claims.reports.claims_frequency`)} (${lang.t(`general.monthly`)})`}
                                        colour={colour}
                                        data={[
                                            {
                                                label: `${capitilise(pivot)} ${lang.tc(`claims.reports.claims_frequency`)}`,
                                                values: normal.map(m => m.claimsFaultFreq),
                                            },
                                        ]}
                                        categories={normal.map(m => m.month)}
                                        gridlines={[2, 4, 6, 8]}
                                        suffix='%'
                                        height={180}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div className="card">
                                    <AreaStep
                                        title={`${capitilise(pivot)} ${lang.tc(`claims.reports.loss_ratio`)} (6 ${lang.t(`general.month_rolling`)})`}
                                        colour={colour}
                                        data={[
                                            {
                                                label: `${capitilise(pivot)} ${lang.tc(`claims.reports.loss_ratio`)}`,
                                                values: rolling.map(m => m.LRInc),
                                            }
                                        ]}
                                        gridlines={[20, 40, 60, 80, 100]}
                                        categories={rolling.map(m => m.month)}
                                        suffix='%'
                                        height={180}
                                    />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div className="card">
                                    <AreaStep
                                        title={`${capitilise(pivot)} ${lang.tc(`claims.reports.claims_frequency`)} (6 ${lang.t(`general.month_rolling`)})`}
                                        colour={colour}
                                        data={[
                                            {
                                                label: `${capitilise(pivot)} ${lang.tc(`claims.reports.claims_frequency`)}`,
                                                values: rolling.map(m => m.claimsFaultFreq),
                                            },
                                        ]}
                                        categories={rolling.map(m => m.month)}
                                        gridlines={[2, 4, 6, 8]}
                                        suffix='%'
                                        height={180}
                                    />
                                </div>
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-lg-12">
                                <div className="card"> 
                                    <VirtualisedTable 
                                        data={normal.map(m => {
                                            return {
                                                'Month': m.month,
                                                'Exposure': numeral(m.exposureSumYears).format('0,0'),
                                                'Freq': `${m.claimsFaultFreq}%`,
                                                'LR est': `${m.LREst}%`,
                                                'LR inc': `${m.LRInc}%`,
                                                'Claims F': m.faultClaimsCount,
                                                'Claims NF': m.nonFaultClaimsCount,
                                                'Policies': numeral(m.policyCount).format('0,0'),
                                                'Earned Prem': `£${numeral(m.earnedPremium,).format('0,0')}`,
                                                'Claims est':`£${numeral(m.claimEst).format('0,0')}`, 
                                                'Claims inc': `£${numeral(m.claimInc).format('0,0')}`,
                                            };
                                        })}
                                        pages={12}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                        <div class="col-lg-6">
                            <div className="card">
                                <BarTable
                                    title={`${capitilise(pivot)} ${lang.tc(`claims.reports.claim_types`)}`}
                                    colour={colour}
                                    data={Object.keys(pivoted.claims.type).map(k => {
                                        return {
                                            label: k,
                                            value: pivoted.claims.type[k],
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div className="card">
                                <BarTable
                                    title={`${capitilise(pivot)} ${lang.tc(`claims.reports.claim_journeys`)}`}
                                    colour={colour}
                                    data={Object.keys(pivoted.claims.journey).map(k => {
                                        return {
                                            label: k,
                                            value: pivoted.claims.journey[k],
                                        }
                                    })}

                                />
                            </div>
                        </div>
                    </div>
                </div>
                    
                }
            </div>
        );
    }
}

export default main(Report);