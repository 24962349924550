import React from 'react';
import grid_alt from './grid_alt.svg';
import grid from './grid.svg';
import white_bb_logo from './white_transparent.png';
import black_bb_logo from './black_transparent.png';

function OverlayLoader(props) {
    const { selectedProduct = 'policy', message } = props;
    const is_alt = selectedProduct ==='rating' ? true : false;
    
    return (
        <div className={`container ${selectedProduct === 'rating' ? 'theme-dark' : ''}`} style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            maxWidth: '100%',
        }}>
            <div className="card" style={{
                textAlign: 'center',
                height: '80vh',
                marginTop: '10vh',
                position: 'relative'
            }}>

                {/*
                    <div
                        className="spinner-border text-blue"
                        style={{
                            marginTop: '40vh',
                            marginLeft: 'calc(50% - 30px)'
                        }} role="status"
                    />
                */}

                {message && <h3 style={{
                    position: 'absolute',
                    width: '100%',
                    top: '30vh',
                    color: is_alt ? 'white' : 'black',
                }}>{message}</h3>}

                <img style={{
                    marginTop: '40vh',
                    marginLeft: 'calc(50% - 20px)',
                    width: 40,
                }} src={is_alt ? grid_alt : grid} />

                <img style={{
                    marginTop: '20px',
                    marginLeft: 'calc(50% - 20px)',
                    width: 40,
                }} src={is_alt ? white_bb_logo : black_bb_logo} />

            </div>
        </div>
    );
}

export default OverlayLoader;