import React, { Component } from 'react';

// Core Design
import Empty from '../../../../components/empty/Results';

// Service Design
import Table from './Table';

class StatementContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { statements, lang } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <div className="row">
                        <div className="col-lg-12">
                            {statements.length === 0 && <Empty label={lang.t('nav.statements').toLowerCase()} lang={lang} />}
                            {statements.length > 0 &&
                                <div className="row"> 
                                    <div className="col-lg-12">
                                        <Table 
                                            {...this.props}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StatementContainer;