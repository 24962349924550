import React, { Component } from 'react';
import { saveAs } from 'file-saver';
import DropZone from '../../DropZoneCSV'; 
import LinkedTable from './LinkedTable';
import { Parser } from 'json2csv';

import { each, get, omit, times } from 'lodash';

class Linked extends Component {
    constructor(props) {
        super(props);
        this.updateLinkedCSV = this.updateLinkedCSV.bind(this);
        this.editLinked = this.editLinked.bind(this);
        this.deleteLinked = this.deleteLinked.bind(this);
        this.exportLinkedCSV = this.exportLinkedCSV.bind(this);
    }

    editLinked(linked) {
        this.props.openModal({
            linked: {
                open: true,
                name: linked,
            }
        });
    }

    deleteLinked(linked) {
        this.props.deleteLinked(omit(this.props.linked, linked));
    }

    exportLinkedCSV(key) {
        const linked = this.props.linked[key];
        
        let mapped_linked = [];

        each(linked, (link, link_key) => {
            let base = {
                key_factor: key,
                key_value: link_key,
            }
            let count = 1;
            Object.keys(link).forEach(k => {
                base[`link_name_${count}`] = k;
                base[`link_value_${count}`] = link[k];
                count++;
            });
            mapped_linked.push(base);
        });

        const parser = new Parser({fields:[
            'key_factor',
            'key_value',
            'link_name_1',
            'link_value_1',
            'link_name_2',
            'link_value_2',
            'link_name_3',
            'link_value_3',
            'link_name_4',
            'link_value_4',
            'link_name_5',
            'link_value_5',
            'link_name_6',
            'link_value_6',
            'link_name_7',
            'link_value_7',
            'link_name_8',
            'link_value_8',
            'link_name_9',
            'link_value_9',
            'link_name_10',
            'link_value_10',
            'link_name_11',
            'link_value_11',
            'link_name_12',
            'link_value_12',
        ]})
        const mapped_csv = parser.parse(mapped_linked)
        const file_name = `${key}.csv`;
        
        // Create a blob of the data
        const content = new Blob([mapped_csv], {
            type: 'text/csv',
            name: key
        });
        
        // Save the file
        saveAs(content, file_name);
    }

    updateLinkedCSV(items = [], lang) {
        const name = get(items, '[0].key_factor', null);
        if (!name) return console.log(lang.t('rating.components.linked.notValid'));

        const linked = {
            [name]: {}
        };

        function buildLinked(item) {
            linked[name][item.key_value] = {};
            
            if (item.link_name_1) {
                linked[name][item.key_value][item.link_name_1] = item.link_value_1;
            }
            if (item.link_name_2) {
                linked[name][item.key_value][item.link_name_2] = item.link_value_2;
            }
            if (item.link_name_3) {
                linked[name][item.key_value][item.link_name_3] = item.link_value_3;
            }
            if (item.link_name_4) {
                linked[name][item.key_value][item.link_name_4] = item.link_value_4;
            }
            if (item.link_name_5) {
                linked[name][item.key_value][item.link_name_5] = item.link_value_5;
            }
            if (item.link_name_6) {
                linked[name][item.key_value][item.link_name_6] = item.link_value_6;
            }
            if (item.link_name_7) {
                linked[name][item.key_value][item.link_name_7] = item.link_value_7;
            }
            if (item.link_name_8) {
                linked[name][item.key_value][item.link_name_8] = item.link_value_8;
            }
            if (item.link_name_9) {
                linked[name][item.key_value][item.link_name_9] = item.link_value_9;
            }
            if (item.link_name_10) {
                linked[name][item.key_value][item.link_name_10] = item.link_value_10;
            }
            if (item.link_name_11) {
                linked[name][item.key_value][item.link_name_11] = item.link_value_11;
            }
            if (item.link_name_12) {
                linked[name][item.key_value][item.link_name_12] = item.link_value_12;
            }
        }

        items.forEach(item => buildLinked(item, 1));

        return this.props.updateLinked({
            ...(this.props.linked || {}),
            ...linked,
        });
    }

    render() {
        const { linked = {}, lang } = this.props;
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">{lang.t('rating.components.linked.title')}</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        {lang.t('rating.components.linked.linkedInfo')}
                                    </div>
                                    <LinkedTable 
                                        data={Object.keys(linked).map(f => {
                                            return {
                                                key: f,
                                                rows_count: Object.keys(linked[f]).length,
                                            }
                                        })}
                                        edit={this.editLinked}
                                        delete={this.deleteLinked}
                                        download={this.exportLinkedCSV}
                                        pages={10}
                                        lang={lang}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        {lang.t('rating.components.linked.upload')}
                                    </div>
                                    <DropZone
                                        title={lang.t('rating.components.linked.drop')}
                                        process={(data) => this.updateLinkedCSV(data, lang)}
                                        height={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Linked;