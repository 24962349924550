import React from 'react';

function Dropdown (props) {
    const {
        ref,
        label,
        value = '',
        update,
        items,
        validation,
        labelMap = {},
    } = props;
    const is_valid = validation ? validation(value) : '';
    
    return (
        <select onChange={(e) => update({
            [label]: e.target.value,
        })} ref={ref} className={`form-select custom-select ${is_valid ? 'is-valid' : ''}`}>
            {items.map(item => {
                const label = labelMap[item] || item;
                return (<option selected={value === item} value={item}>{label}</option>)
            })}
        </select>
    )
}

export default Dropdown;
