import React, { Component } from 'react';
import moment from 'moment';
import SuperTable from '../../../../components/tables/Super';
import { extendedDateFormat } from '../../../../utils/format';
import {
    Link
} from 'react-router-dom';

import PolicyModal from '../../../../components/modal/Policy';

class TelematicsDevicesTable extends Component {
    constructor(props) {
        super(props);
    }
    
    badge(status, msg) {
        if (status === 'active') return (<div className='badge bg-green'>{msg.toUpperCase()}</div>)
        return (
            <div className='badge bg-yellow'>{msg.toUpperCase()}</div>
        )
    }

    render() {
        const { data = [], show_button = true, lang } = this.props;

        const headers = [
            {
                label: lang.t('tableHeaders.deviceReference'),
                name: 'device_reference',
                search: true
            },
            {
                label: lang.t('tableHeaders.policy'),
                name: 'policy_reference',
                search: true
            },
            {
                label: lang.t('tableHeaders.tag'),
                name: 'tag_reference',
                search: true
            },
            {
                label: lang.t('tableHeaders.location'),
                name: 'location',
            },
            {
                label: lang.t('tableHeaders.heartbeat'),
                name: 'heartbeat_at',
            },
            {
                label: lang.t('tableHeaders.age'),
                name: 'age',
            },
            {
                label: lang.t('tableHeaders.status'),
                name: 'status',
            },
            {
                label: '',
                name: '',
            },
        ];

        const Cells = (index, d) => {
            const is_valid = (d.exclusion_count === 0);
            return (
                <tr key={`devices-${index}`}>
                    <td data-label="Ref">
                        <p class="strong mb-1">{d.device_reference}</p>
                    </td>
                    <td data-label="Policy">
                        <div className="btn-list flex-nowrap">
                            <PolicyModal 
                                policy_reference={d.policy_reference}
                                policys_get_all={this.props.policys_get_all}
                                lang={lang}
                            />
                        </div>
                    </td>
                    <td data-label="Tag">
                        <div className="text-muted text-h5">{d.tag_reference ? (d.tag_reference).slice(0, 20) + '...' : ''}</div>
                    </td>
                    <td data-label="Location">
                        <div className="text-muted text-h5">({d.latitude}, {d.longitude})</div>
                    </td>
                    <td data-label="Heartbeat">
                        <div className="text-muted text-h5">{extendedDateFormat(d.heartbeat_at)}</div>
                    </td>
                    <td data-label="Ago">
                        <div className="text-h5">{moment(d.heartbeat_at).fromNow(true)}</div>
                    </td>
                    <td data-label="Distance">
                        <div className="text-muted text-h5">{this.badge(d.status, d.status)}</div>
                    </td>
                    <td>
                        <div className="btn-list flex-nowrap">
                            <Link
                                to={`/telematics/devices/${d.device_reference}::${d.policy_reference}`}
                                className="btn btn-sm btn-primary"
                            >{lang.t('policy.table.detail')}</Link>
                        </div>
                    </td>
                </tr>
            );
        }

        return (
            <SuperTable 
                data={data.filter(d => d.heartbeat_at)}
                headers={headers}
                Cells={Cells}
                height={600}
                search={this.props.search}
                lang={lang}
            />
        );
    }
}

export default TelematicsDevicesTable;
