import { round, isEmpty, sortBy } from 'lodash';
import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';

function map_factor(key, map) {
    return map[key] || map['quotability'];
}


function chartBuilder(data = [], key, map) {
    let mapped = data;
    if (key === 'quotability') {
        mapped = data.map(d => {
            return round((d.is_valid/d.quotes) * 100, 1);
        })
    } else {
        mapped = data.map(d => {
            return round(d[key], 2);
        })
    }
    
    const series = {
        name: map_factor(key, map),
        data: mapped,
    };

    const last_item = mapped[mapped.length - 1] || null;
    const second_last_item = mapped[mapped.length - 2] || null;

    function delta() {
        if (!(last_item && second_last_item)) return 0;
        return round(((last_item - second_last_item) /second_last_item) * 100, 1);
    }

    return {
        series,
        name: map_factor(key, map),
        value: last_item || 0,
        delta: delta(),
    }
}

class QuotesStatsChart extends Component {
    constructor(props) {
        super(props);
        this.updateChart = this.updateChart.bind(this);
    }

    componentWillMount() {
        this.updateChart(this.props);
    }

    updateChart(props) {
        const {
            data,
            keyvalue,
            format,
            lang,
        } = props;

        const map = {
            'quotes': lang.t('rating.components.quoteStatsChart.quoteCount'),
            'quotability': lang.t('rating.components.quoteStatsChart.quotability'),
            'yearly_premium_estimate': lang.t('rating.components.quoteStatsChart.yearlyPremium'),
            'execution_time': lang.t('rating.components.quoteStatsChart.responseTime'),
            'estimated_distance': lang.t('rating.components.quoteStatsChart.distance'),
        };

        const sorted = sortBy(data, 'timestamp');

        let { series, value, delta, name } = chartBuilder(sorted, keyvalue, map);

        const labels = sorted.map(d => {
            return moment(d.timestamp, 'X').toISOString();
        });

        let chart = {
            series: [series],
            options: {
                chart: {
                    sparkline: {
                        enabled: true
                    },
                    animations: {
                        enabled: false
                    },
                },
                fill: {
                    opacity: 1,
                },
                stroke: {
                    width: [2, 1],
                    dashArray: [0, 3],
                    lineCap: "round",
                    curve: "smooth",
                },

                grid: {
                    strokeDashArray: 4,
                },
                xaxis: {
                    labels: {
                        padding: 0
                    },
                    type: 'datetime',
                },
                yaxis: {
                    labels: {
                        padding: 4
                    },
                },
                labels,
                colors: ["#206bc4"],
                legend: {
                    show: false,
                },
                tooltip: {
                    x: {
                        format,
                    }
                }
            }
        };

        this.setState({ chart, value, delta, name });
    }

    deltaDirection(delta = 0) {
        if (delta < -1) {
            return (
                <span class="text-red d-inline-flex align-items-center lh-1">
                    {delta}% <i className="fe fe-arrow-down-right"></i> 
                </span>
            )
        } else if (delta > 1) {
            return (
                <span class="text-green d-inline-flex align-items-center lh-1">
                    {delta}% <i className="fe fe-arrow-up-right"></i> 
                </span>
            )
        } else{
            return (
                <span class="text-yellow d-inline-flex align-items-center lh-1">
                    {delta}%
                </span>
            )
        }
    }

    render() {
        const { chart = {}, value = 0, delta = 0, name } = this.state;

        const { lang } = this.props

        const map = {
            'quotes': lang.t('rating.components.quoteStatsChart.quoteCount'),
            'quotability': lang.t('rating.components.quoteStatsChart.quotability'),
            'yearly_premium_estimate': lang.t('rating.components.quoteStatsChart.yearlyPremium'),
            'execution_time': lang.t('rating.components.quoteStatsChart.responseTime'),
            'estimated_distance': lang.t('rating.components.quoteStatsChart.distance'),
        };

        return(
            <div class="card">
                {!isEmpty(chart) &&
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="subheader">{name}</div>
                        <div class="ml-auto lh-1">
                            <div class="dropdown">
                                <a class="dropdown-toggle text-muted" href="#" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {lang.t('rating.components.quotesStats.selectFactor')}
                                    </a>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    {Object.keys(map).map(k => {
                                        return (
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                this.updateChart({
                                                    data: this.props.data,
                                                    keyvalue: k,
                                                    format: this.props.format,
                                                    lang,
                                                })
                                            }} class="dropdown-item" href="#">{map_factor(k, map)}</a>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-baseline">
                        <div class="h1 mb-3 mr-2">{value}</div>
                        <div class="mr-auto">
                            {this.deltaDirection(delta)}
                        </div>
                    </div>
                    <div class="chart-sm" >
                        <Chart
                            options={chart.options}
                            series={chart.series}
                            type="line"
                            height="40"
                        />
                    </div>
                </div>
                }
            </div>
            
        );
    }
}

export default QuotesStatsChart;


