import React from 'react';

function ChangeSuccess({
    close,
    title_text,
    alert_text,
    body_text,
}) {
    return (
        <div className="modal-content">
            <div className="modal-header theme-dark" >
                <h5 className="modal-title">{title_text}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
                    <i className="fe fe-x"></i>
                </button>
            </div>
            <div className="modal-body">
                <div className={`alert alert-success mb-2 p-1`} role="alert">
                    <i style={{ marginRight: 5 }} className="fe fe-check-circle"></i>
                    {alert_text}
                </div>
                <div className="text-success text-center mt-3">
                    <i className="fe fe-check" style={{ fontSize: '60px' }}></i>
                </div>
                <div className="text-center mt-3">
                    <h4>{body_text}</h4>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={close}>OK</button>
            </div>
        </div>
    );
}

export default ChangeSuccess;