import React, { Component } from 'react';
import { getLangSource } from '../../../../lang';
import { get } from 'lodash';

import main from '../../../../hoc/main';

// Core Design
import PageHeader from '../../../../components/layout/PageHeader';
import Search from '../../../../components/search/Simple';

// Service Design
import ActivityContainer from '../../components/Activity/Container';

class ActivitySearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchTerm: '',
            results: [],
        }
        this.search = this.search.bind(this);
    }

    componentWillMount() {
        this.search();
    }

    search(value = '') {
        let where = {};
        if (value.length > 3 && !get(this.props, 'policys_user_logs.isFetching', true)) {
            where = {
                // TODO - make API and UI fuzzy match
                username: value,
            }
        }
        this.props.policys_user_logs_get_all(where)
        .then(() => {
            this.setState({
                results: get(this.props, 'policys_user_logs.data', []),
            })
        })
    }

    render() {
        const { results } = this.state;
        const lang = getLangSource(this.props.lang);
        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    <PageHeader
                        section={lang.t('nav.policies')}
                        page={lang.t('nav.activity')}
                    />

                    {/* Search */}
                    <div className="row">
                        <div className="col-sm-12 col-lg-8" />
                        <div className="col-sm-12 col-lg-4">
                                <div className="row">
                                    <div className="mb-3">
                                    <Search
                                        searchTerm={this.state.searchTerm}
                                        searchFunction={this.search}
                                        label={lang.t('policy.activity.activity').toLowerCase()}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <ActivityContainer 
                        activity={results}
                        lang={lang}
                    />

                </div>
            </div>
        );
    }
}

export default main(ActivitySearch);