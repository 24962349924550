import React, { Component } from 'react';
import SuperTable from '../../../../../components/tables/Super';

class TableConvictions extends Component {
    constructor(props) {
        super(props)

        this.delete = this.delete.bind(this);
    }

    delete(index) {
        const { update, data } = this.props;
        
        const new_convictions = [...data];

        new_convictions.splice(index, 1)

        update({ convictions: new_convictions });
    }

    render() {
        const { data = [], disabled, lang } = this.props;

        let headers = [
            {
                label: lang.t('tableHeaders.date'),
                name: 'date',
                search: false,
            },
            {
                label: lang.t('tableHeaders.code'),
                name: 'conviction_code',
            },
            {
                label: lang.t('tableHeaders.points'),
                name: 'points',
            },
            {
                label: lang.t('tableHeaders.ban'),
                name: 'ban',
            },
        ];

        if (!disabled) {
            headers.push({
                label: '',
                name: 'delete',
            },)
        }

        const Cells = (index, d) => {
            return (
                <tr key={`convictions-${index}`}>
                    <td data-label="Date">  
                        <div className="text-h5">{d.date}</div>
                    </td>
                    <td data-label="Code">
                        <div className="text-h5">
                            {d.code.slice(0, 4)}
                        </div>
                    </td>
                    <td data-label="Points">
                        <div className="text-h5">
                            {d.points}
                        </div>
                    </td>
                    <td data-label="Ban">
                        <div className="text-h5">
                            {d.ban}
                        </div>
                    </td>
                    {!disabled && 
                        <td className="w-1">
                            <a href="#" style={{ boxShadow: 'none' }} onClick={(e) => {
                                e.preventDefault();
                                this.delete(index);
                            }} className="btn btn-sm">
                                <i className="fe fe-trash-2 text-danger pb-1" style={{ fontSize: '15px' }}></i>
                            </a>
                        </td>
                    }
                </tr>
            )
        }

        return(
            <SuperTable 
                data={data}
                headers={headers}
                Cells={Cells}
                auto_sort={true}
                lang={lang}
            />
        )
    }
}

export default TableConvictions;