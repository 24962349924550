import React, { Component } from 'react';
import { reject } from 'lodash';
import ModelEndorsementTable from './EndorsementTable';

class Endorsements extends Component {
    constructor(props) {
        super(props);
        this.editEndorsement = this.editEndorsement.bind(this);
        this.deleteEndorsement = this.deleteEndorsement.bind(this);
    }

    editEndorsement(endorsement) {
        this.props.openModal({
            endorsements: {
                open: true,
                name: endorsement,
            }
        });
    }

    deleteEndorsement(endorsement) {
        this.props.deleteEndorsement(reject(this.props.endorsements, e => e.name === endorsement));
    }

    render() {
        const { endorsements, lang } = this.props;
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">{lang.t('rating.components.endorsements.title')}
                                <a href="#"  style={{
                                    position: 'absolute',
                                    top: 14,
                                    right: 20,
                                }} onClick={(e) => { 
                                    e.preventDefault();
                                    this.editEndorsement(''); 
                                }}  data-toggle="modal" data-target="#modal-endorsement" className="btn btn-primary btn-sm">{lang.t('rating.components.endorsements.create')}</a>
                            </h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="alert alert-info" role="alert">
                                        <i style={{ marginRight: 5 }} className="fe fe-info"></i>
                                        {lang.t('rating.components.endorsements.endorsementsInfo')}
                                    </div>
                                    <ModelEndorsementTable 
                                        data={endorsements}
                                        edit={this.editEndorsement}
                                        delete={this.deleteEndorsement}
                                        pages={10}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Endorsements;