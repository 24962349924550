import { get } from 'lodash';

export function getAuth ({
    policys_products,
    endpointDetails,
    companyRef,
}) {
    // Koba uses the same Bearer token for webhooks and MTA endpoint call

    const token = get(policys_products, 'payload.data.webhooks_secret');

    return { "Authorization": `Bearer ${token}` }
}


