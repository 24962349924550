import React, { Component } from 'react';

import { get } from 'lodash';
import numeral from 'numeral';
import main from '../../../../hoc/main';

import { riskStats, monthlyStatsStartDate, lastUpdate } from '../../workers/utils/aggregate';

//Core Design
import VirtualisedTable from '../../../../components/tables/Virtualised';
import LoaderBar from '../../../../components/loaders/Bar';


import Filter from '../../components/filter';
import Banner from '../../components/lossratio/Banner';
import AreaStep from '../../components/charts/AreaStep';

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filtered: [],
        };
        this.setFiltered = this.setFiltered.bind(this);
    }

    componentDidMount() {
        const data = get(this.props, 'claims_versions.data', []);
        if (data.length === 0) this.props.claims_versions_get_all({});
    }

    setFiltered(result) {
        this.setState({
            filtered: result
        });
    }

    render() {
        const risks = get(this.props, 'claims_versions.data', []);
        const filteredRisks = this.state.filtered;

        const colour = 'blue';

        const data = filteredRisks.length > 0 ? filteredRisks : risks;

        const totalRiskStats = riskStats(data);
        const monthlyRiskStats = monthlyStatsStartDate(data);
        const mStatsRev = [...monthlyRiskStats].reverse();

        return (
            <div class="my-3 my-md-5">

                {data.length === 0 && <LoaderBar />}

                {data.length > 0 &&
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <span class={`badge bg-${colour}-lt`}>Last update : {lastUpdate(data, 'created_at')}</span>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="row">
                                <div class="mb-3">
                                    <Filter 
                                        data={risks}
                                        setFiltered={this.setFiltered}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Banner 
                        totalRiskStats={totalRiskStats}
                        monthlyRiskStats={monthlyRiskStats}
                        colour={colour}
                    />
                    <div class="row">
                        <div class="col-lg-6">
                            <div className="card">
                                <AreaStep
                                    title='Loss Ratio'
                                    colour={colour}
                                    data={[
                                        {
                                            label: 'Loss Ratio',
                                            values: mStatsRev.map(m => m.LRInc),
                                        }
                                    ]}
                                    categories={mStatsRev.map(m => m.month)}
                                    height={140}
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div className="card">
                                <AreaStep
                                    title='Frequency'
                                    colour={colour}
                                    data={[
                                        {
                                            label: 'Frequency',
                                            values: mStatsRev.map(m => m.claimsFaultFreq),
                                        },
                                    ]}
                                    categories={mStatsRev.map(m => m.month)}
                                    height={140}
                                />
                            </div>
                        </div>
                    </div>  
                    <div class="row">
                        <div class="col-lg-12">
                            <div className="card"> 
                                <VirtualisedTable 
                                    data={monthlyRiskStats.map(m => {
                                        return {
                                            'Month': m.month,
                                            'Exposure': numeral(m.exposureSumYears).format('0,0'),
                                            'Freq': `${m.claimsFaultFreq}%`,
                                            'LR est': `${m.LREst}%`,
                                            'LR inc': `${m.LRInc}%`,
                                            'Claims F': m.faultClaimsCount,
                                            'Claims NF': m.nonFaultClaimsCount,
                                            'Policies': numeral(m.policyCount).format('0,0'),
                                            'Earned Prem': `£${numeral(m.earnedPremium,).format('0,0')}`,
                                            'Claims est':`£${numeral(m.claimEst).format('0,0')}`, 
                                            'Claims inc': `£${numeral(m.claimInc).format('0,0')}`,
                                        };
                                    })}
                                    pages={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default main(Report);