const moment = require('moment');
const { first, round, sortBy, get } = require('lodash');


function vanityReg(reg = '') {

    const size = reg.length;

    function isNum (num) {
        return !isNaN(parseInt(num))
    }

    const char1 = reg.charAt(0);
    const char2 = reg.charAt(1);
    const char3 = reg.charAt(2); 
    const char4 = reg.charAt(3); 
    const char5 = reg.charAt(4);
    const char6 = reg.charAt(5);
    const char7 = reg.charAt(6);

    const arr = [
        isNum(char1),
        isNum(char2),
        isNum(char3),
        isNum(char4),
        isNum(char5),
        isNum(char6),
        isNum(char7),
    ].splice(0, size).join(',');

    //JJZ388
    if (size < 5) {
        return 'dateless';
    }
    
    //Q123RTC - Kitcar
    if (char1 === 'Q') {
        return 'kitcar';
    }

    //JJZ388
    if (char3 === 'Z') {
        return 'irish';
    }

    //JJI388
    if (['IA', 'IB', 'IG', 'IJ', 'IL', 'IW', 'JI', 'OI', 'UI', 'XI'].includes(char2 + char3)) {
        return 'irish';
    }

    //ABC123X
    if(arr === 'false,false,false,true,true,true,false') {
        return 'dated';
    };

    //ABC12X
    if(arr === 'false,false,false,true,true,false') {
        return 'dated';
    };

    //A100ABC
    if(
        arr === 'false,true,true,true,false,false,false' &&
        (round(char2 + char3 + char4) % 100 === 0) 
    ) {
        return 'dated';
    };

    //A1ABC
    if(
        arr === 'false,true,false,false,false' &&
        (round(char2) < 20) 
    ) {
        return 'dated';
    };

    //A12ABC
    if(
        arr === 'false,true,true,false,false,false' &&
        (round(char2 + char3) < 20) 
    ) {
        return 'dated';
    };

    //A12BBB
    if(
        (char4 === char5 && char5 === char6)
    ) {
        return 'dated';
    };

    //A123BBB
    if(
        (char5 === char6 && char6 === char7)
    ) {
        return 'dated';
    };

    //A1BBB
    if(
        (char3 === char4 && char4 === char5)
    ) {
        return 'dated';
    };

    //LL10UHB
    if(arr === 'false,false,true,true,false,false,false') {
        return 'normal'
    }

    //L210UHB
    if(arr === 'false,true,true,true,false,false,false') {
        return 'normal'
    }

    return 'dateless'
}


const postcode_area = (postcode = '') => {
    const letters = postcode.toUpperCase().split('');
    if (!isNaN(parseInt(letters[1]))) return letters[0];
    return letters[0] + letters[1];
};

const postcode_sector = (postcode = '') => {
    return postcode
        .toUpperCase()
        .substring(0, postcode.length - 2);
};

const insurance_relationships = (drivers) => {
    const relationships = drivers
        .filter(d => d.driver === 'ad')
        .map(m => m.relationship) || [];
    if (relationships.length === 0) return 'I';
    if (relationships.length === 1) {
        if (relationships[0] === 'S') return 'IS';
        if (relationships[0] === 'J') return 'IS';
        if (relationships[0] === 'W') return 'ICL';
        if (relationships[0] === 'M') return 'IP'; // Insured & 1 Parent
        if (relationships[0] === '1') return 'IP'; // Insured & 1 Parent/Guardian
        if (relationships[0] === 'C') return 'IB'; // Insured & Business Partner
        return 'I1';
    }
    if (relationships.length === 2) { 
        if (
            (relationships[0] === 'M' || relationships[0] === '1') && 
            (relationships[1] === 'M' || relationships[1] === '1')
        ) {
            return 'IP2'; // Insured & 2 Parents/Guardians
        }
        return 'I2';
    }
    if (relationships.length === 3) return 'I3';
    return '-1';
};

function enrichDetail (data = {}) {
    const {
        additional_drivers = [],
        proposer = {},
        vehicle = {},
        policy = {}
    } = data;

    function addAges(label, date) {
        function __normalise_age(period = 'years') {
            const today = moment(date).utc().startOf('day');
            if (period === 'days') return round((moment(policy.inception_date, 'X') - today)/86400000 , 0);
            return moment(policy.inception_date, 'X').diff(today, period, false);
        }
        return {
            [`${label}YY`]: __normalise_age('years'),
            [`${label}MM`]: __normalise_age('months'),
            [`${label}DD`]: __normalise_age('days'),
        }
    }

    function agesDriver(driver) {
        const {
            dobYY,
            dobMM,
            dobDD,
        } = addAges('dob', driver.dob);

        const {
            licenceYY,
            licenceMM,
            licenceDD,
        } = addAges('licence', driver.licence_issued_date || `${driver.licence_year}-${driver.licence_month}-01`);

        const {
            residencyYY,
            residencyMM,
            residencyDD,
        } = addAges('residency', driver.residency_date || `${driver.residency_year}-${driver.residency_month}-01`);
        
        return {
            ...driver,
            dobYY,
            dobMM,
            dobDD,
            licenceYY,
            licenceMM,
            licenceDD,
            licenceAgeYY: (dobYY - licenceYY),
            licenceAgeMM: (dobMM - licenceMM),
            licenceAgeDD: (dobDD - licenceDD),
            residencyYY,
            residencyMM,
            residencyDD,
            residencyAgeYY: (dobYY - residencyYY),
            residencyAgeMM: (dobMM - residencyMM),
            residencyAgeDD: (dobDD - residencyDD),
            claims: (driver.claims || []).map(c => {
                return {
                    ...c,
                    ...addAges('date', c.date),
                }
            }),
            convictions: (driver.convictions || []).map(c => {
                return {
                    ...c,
                    ...addAges('date', c.date),
                }
            })
        }
    }

    const drivers = [{
        ...agesDriver(proposer),
        driver: 'ph',
    }];
    
    (additional_drivers || []).forEach(a => {
        drivers.push({
            ...agesDriver(a),
            driver: 'ad',
        });
    });

    const youngest = first(sortBy(drivers, d => d.ageDD));
    const youngest_additional = first(sortBy(drivers.filter(d => d.driver === 'ad'), d => d.ageDD)) || {};
    const least_experienced = first(sortBy(drivers, d => d.licenceDD));
    const least_residency = first(sortBy(drivers, d => d.residencyDD));
    const spouse = first(drivers.filter(d => d.relationship === 'S' || d.relationship === 'J')) || {};

    const vehicle_address = vehicle.address || policy.address || {};
    // Helper information added to data 

    const quote_created = moment();

    const result = {
        ...data,
        policy:{
            ...policy,
            days_to_inception: (addAges('', policy.created_at).DD),
            insurance_relationships: insurance_relationships(drivers),
            address: {
                ...(policy.address || {}),
                postcode_area: postcode_area(get(policy, 'address.postcode', '')),
                postcode_sector: postcode_sector(get(policy, 'address.postcode', '')),
            },
            // To created a time of month weighting algorithm
            quote_created_hour: quote_created.format('H'), // 0 - 23
            quote_created_day: quote_created.format('d'), // 0 Sun - 6 Sat
            quote_created_week: quote_created.format('W'), // 1 - 53
            quote_created_date: quote_created.format('D'), // 1 - 31
            quote_created_month: quote_created.format('M'), //1 - 12
        },
        vehicle: {
            ...vehicle,
            ...addAges('manufacture', vehicle.manufacture_date),
            ...addAges('purchase', vehicle.purchase_date),
            address: {
                ...vehicle_address,
                postcode_area: postcode_area(vehicle_address.postcode),
                postcode_sector: postcode_sector(vehicle_address.postcode),
            },
            ncd: proposer.ncd,
            vanity_reg: vanityReg(vehicle.reg),
            is_kitcar: (vehicle.reg || '').charAt(0) === 'Q',
        },
        proposer: {
            ...first(drivers.filter(d => d.driver === 'ph')),
            relationship: 'P',
        },
        additional_drivers: drivers.filter(d => d.driver === 'ad'),
        spouse,
        youngest,
        youngest_additional,
        least_experienced,
        least_residency,
        drivers,
    };

    return result;
}

export default function enrichQuote(detail) {
    const enriched = enrichDetail(detail);
    return enriched;
}