import BaseActions from '../../../actions/base';
import services from '../config';

const { root } = services.apis;

const users = new BaseActions({
    path: `${root}/auth/users`,
    type: 'SETTINGS_USERS',
});

export default users;